import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Shemà israel.",
  url_video: "https://www.youtube.com/embed/aVYbiH88tHw",
  barrè: "IV",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Sol",
          text: "Shemà Israel, shemà Israel",
        },
        {
          notes: "Fa",
          text: "Adonai Elohenu",
        },
        {
          notes: "        La-",
          text: "Adonai Ehad.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Sol",
          text: "SHEMÀ ISRAEL, SHEMÀ ISRAEL",
        },
        {
          notes: "   Fa",
          text: "ADONAI ELOHENU",
        },
        {
          notes: "        La-",
          text: "ADONAI EHAD.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "ASCOLTA ISRAELE *           - SHEMÀ ISRAEL",
        },
        {
          notes: "Sol",
          text: "ASCOLTA ISRAELE             - SHEMÀ ISRAEL",
        },
        {
          notes: "   Fa",
          text: "IL SIGNORE È IL NOSTRO DIO  - ADONAI ELOHENU",
        },
        {
          notes: "             La-",
          text: "IL SIGNORE È UNO.           - ADONAI EHAD.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-",
          text: "Amerai il Signore Dio tuo",
        },
        {
          notes: "   Fa            La-",
          text: "con tutto il tuo cuore,",
        },
        {
          notes: "   Fa            La-",
          text: "con tutta la tua mente,",
        },
        {
          notes: "   Fa            Mi",
          text: "con tutte le tue forze.",
        },
        {
          notes: " La-            Re-",
          text: "Poni queste mie parole",
        },
        {
          notes: "      Sol           La-",
          text: "come segno ai tuoi polsi,",
        },
        {
          notes: "                       Re-",
          text: "come segno fra i tuoi occhi,",
        },
        {
          notes: "        Sol",
          text: "sugli stipiti della casa",
        },
        {
          notes: "         Mi",
          text: "e sulle porte.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "ASCOLTA ISRAELE *           - SHEMÀ ISRAEL",
        },
        {
          notes: "Sol",
          text: "ASCOLTA ISRAELE             - SHEMÀ ISRAEL",
        },
        {
          notes: "   Fa",
          text: "IL SIGNORE È IL NOSTRO DIO  - ADONAI ELOHENU",
        },
        {
          notes: "             La-",
          text: "IL SIGNORE È UNO.           - ADONAI EHAD.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Inculcale ai tuoi figli, Israele,",
        },
        {
          notes: "Fa          La-",
          text: "parlane in casa,",
        },
        {
          notes: "          Fa            La-",
          text: "quando cammini per la strada,",
        },
        {
          notes: "          Fa           Mi",
          text: "quando ti corichi e ti alzi.",
        },
        {
          notes: "  La-                 Re-",
          text: "Questo è il primo comando per la vita,",
        },
        {
          notes: "     Sol                 La-",
          text: "e il secondo è simile al primo:",
        },
        {
          notes: "                Re-",
          text: "ama il prossimo come te stesso",
        },
        {
          notes: "   Sol                     Mi",
          text: "fa questo e avrai la vita eterna.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "ASCOLTA ISRAELE *           - SHEMÀ ISRAEL",
        },
        {
          notes: "Sol",
          text: "ASCOLTA ISRAELE             - SHEMÀ ISRAEL",
        },
        {
          notes: "   Fa",
          text: "IL SIGNORE È IL NOSTRO DIO  - ADONAI ELOHENU",
        },
        {
          notes: "             La-",
          text: "IL SIGNORE È UNO.           - ADONAI EHAD.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Sol",
          text: "Shemà Israel, shemà Israel",
        },
        {
          notes: "Fa",
          text: "Adonai Elohenu",
        },
        {
          notes: "        La-",
          text: "Adonai Ehad.",
        },
      ],
    },
    {
      speaker: "Si canta a due cori.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "(Dt 6, 4-9)",
};
