import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alleluja pasquale.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi       Si7     Mi",
          text: "Cristo Gesù è risorto.",
        },
      ],
    },
    {
      speaker: "A1.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi       Si7     Mi",
          text: "Cristo Gesù è risorto.",
        },
      ],
    },
    {
      speaker: "A2.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi       Si7     Mi",
          text: "Cristo Gesù è il Signore.",
        },
      ],
    },
    {
      speaker: "A3.",
      mode: "normal",
      lines: [
        {
          notes: "         Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "A1",
      mode: "normal",
      lines: [
        {
          notes: "Mi            Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "A2.",
      mode: "normal",
      lines: [
        {
          notes: "Mi            Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
          msg: " (A 3 voci)",
        },
      ],
    },
    {
      speaker: "A3.",
      mode: "normal",
      lines: [
        {
          notes: "Mi            Si7       Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "",
};
