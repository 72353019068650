import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import "./ResetPasswordScreen.scss";

const ResetPasswordScreen = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  return (
    <PageLayout
      title={"Reimposta la password"}
      content={
        <div>
          Inserisci la tua nuova password:
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="reset-password-screen-input-wrap">
              <TextField
                fullWidth
                required
                label="Nuova password"
                variant="standard"
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                value={password}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="reset-password-screen-input-wrap">
              <TextField
                fullWidth
                required
                label="Conferma password"
                variant="standard"
                type={showConfirmPassword ? "text" : "password"} // <-- This is where the magic happens
                onChange={(e: any) => {
                  setConfirmPassword(e.target.value);
                }}
                value={confirmPassword}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="reset-password-screen-input-wrap">
              <StandardButton formButton label="Reimposta" onClick={() => {}} />
            </div>
          </form>
        </div>
      }
    />
  );
};

export default ResetPasswordScreen;
