import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Maria, madre del cammino ardente.",
  url_video: "https://www.youtube.com/embed/vaG1X_RNcxo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol Mi- Do                 La",
          text: "Maria, madre del cammino ardente,",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "Do                               Sol",
          text: "tu ci liberi dal fuoco delle passioni",
        },
        {
          notes: "Re                                Sol",
          text: "con la rugiada della tua intercessione",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  Do      La         Re",
          text: "MITE MARIA, TUTTA IMMACOLATA,",
        },
        {
          notes: "Do                           Sol",
          text: "ANGELO CUSTODE DEL TERZO MILLENNIO,",
        },
        {
          notes: "Re                   Sol",
          text: "LUOGO DI TUTTE LE GRAZIE,",
        },
        {
          notes: "Do                  Sol",
          text: "IMMAGINE DELLA VIRTÙ,",
        },
        {
          notes: "Re                                     Sol",
          text: "LA TUA BELLEZZA CANTA LA GERUSALEMME CELESTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi           Do         La",
          text: "Tu ci hai mostrato il cammino,",
        },
        {
          notes: "Do                          Sol",
          text: "sotto la croce hai ricevuto noi,",
        },
        {
          notes: "Re                  Sol",
          text: "madre del cammino ardente,",
        },
        {
          notes: "Do                          Sol",
          text: "sotto la croce hai ricevuto noi,",
        },
        {
          notes: "Re                           Sol",
          text: "come dire no al tuo Figlio morente.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  Do      La         Re",
          text: "MITE MARIA, TUTTA IMMACOLATA,",
        },
        {
          notes: "Do                           Sol",
          text: "ANGELO CUSTODE DEL TERZO MILLENNIO,",
        },
        {
          notes: "Re                   Sol",
          text: "LUOGO DI TUTTE LE GRAZIE,",
        },
        {
          notes: "Do                  Sol",
          text: "IMMAGINE DELLA VIRTÙ,",
        },
        {
          notes: "Re                                     Sol",
          text: "LA TUA BELLEZZA CANTA LA GERUSALEMME CELESTE.",
        },
      ],
    },
  ],
  subtitle: "",
};
