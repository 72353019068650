import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ascoltaci Signore.",
  url_video: "",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol         Mi-  Si7 Mi",
          text: "Ascoltaci Signo - o - re",
        },
        {
          notes: "Sol         Mi-  Si7 Mi",
          text: "Ascoltaci Signo - o - re",
        },
      ],
    },
  ],
  subtitle: "",
};
