import { AppName } from "../../App";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import TourIcon from "@mui/icons-material/Tour";
import "./HomeScreen.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import ModalDialog from "../../Components/ModalDialog/ModalDialog";
import SlideShow, {
  SlideShowImage,
} from "../../Components/SlideShow/SlideShow";
import ChordGraph from "../../Components/ChordGraph/ChordGraph";
import Img from "../../Components/Img/Img";

const SLIDESHOW_IMAGES: SlideShowImage[] = [
  {
    value: "./Assets/Other/showNotes.png",
    altText: "Le note interattive",
    description:
      "Negli spartiti puoi vedere come eseguire un accordo della chitarra premendo sulla sigla della relativa nota. Se esistono più modi di eseguire un accordo, questi si alterneranno automaticamente.",
  },
  {
    value: "./Assets/Other/showCommands.png",
    altText: "La barra degli strumenti",
    description:
      "In ogni spartito è possibile aprire la barra degli strumenti.",
  },
  {
    value: "./Assets/Other/showVideo.png",
    altText: "I video dei canti",
    description:
      "La maggior parte dei canti ha pronto il video del canto. Aprilo dalla barra degli strumenti.",
  },
  {
    value: "./Assets/Other/showDownloads.png",
    altText: "Scarica come PDF",
    description:
      "Tutti i canti possono essere scaricati come PDF tramite la barra degli strumenti.",
  },
  {
    value: "./Assets/Other/showCustom.png",
    altText: "L'editor dei canti",
    description:
      "Puoi arricchire il sito con spartiti da te creati andando nella pagina CREA.",
  },
  {
    value: "./Assets/Other/showTuner.png",
    altText: "Accordatore incluso nel sito",
    description:
      "È possibile usare l'accordatore incluso nel sito nella pagina ACCORDA per accordare la tua chitarra in ogni momento.",
  },
  {
    value: "./Assets/Other/showGuide.png",
    altText: "La guida del sito",
    description:
      "Nella pagina GUIDA sono presenti molte informazioni utili per aiutarti su vari argomenti.",
  },
  {
    value: "./Assets/Other/showHands.png",
    altText: "Gli accordi della chitarra",
    description:
      "Dalla guida puoi accedere agli schemi per moltissimi accordi della chitarra.",
  },
  {
    value: "./Assets/Other/showOptions.png",
    altText: "La pagina di opzioni",
    description:
      "Puoi personalizzare l'aspetto e alcune funzioni del sito tramite la pagina OPZIONI.",
  },
];

const HomeScreen = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tourPage, setTourPage] = useState<number>(0);

  return (
    <PageLayout
      title={"Bacheca"}
      content={
        <div>
          <div>
            <div className="home-app-name">{AppName}</div> è una{" "}
            <strong>Progressive Web App Italiana</strong> pensata per i cantori
            del Cammino Neocatecumenale e per chiunque si approcci a diventarlo.
          </div>
          <div className="top-space">
            <Link to={"/install-app"}>
              Come scaricare la Progressive Web App.
            </Link>
          </div>
          <TitledPageSection
            title={"Introduzione"}
            content={
              <div>
                Questa App è un servizio gratuito nato con lo scopo di aiutare
                chi pratica (o vuole imparare) i canti liturgici delle
                celebrazioni cattoliche, in particolare per le celebrazioni che
                seguono la modalità del cammino neocatecumenale. Gli spartiti
                offerti su questo sito sono a disposizione di tutti. Questo
                progetto è frutto di lavoro no-profit indipendente e non è
                affiliato in alcun modo con la chiesa cattolica o il cammino
                neocatecumenale.
                <br />
                <br />
                Se è la prima volta che usi questo sito, ti suggeriamo di
                provare il breve tour indroduttivo:
                <br />
                <br />
                <StandardButton
                  label={"Vai al tour"}
                  onClick={() => {
                    setTourPage(0);
                    setDialogOpen(true);
                  }}
                  startIcon={<TourIcon />}
                />
                <br />
                <br />
                Vuoi conoscerci? Vieni a trovarci su Facebook:
                <br />
                <br />
                <a
                  href={"https://www.facebook.com/CantiOnlineNeocatecumenali"}
                  rel={"noopener"}
                  target={"_blank"}
                >
                  <Img
                    alt={"Facebook"}
                    style={{ height: "2.5em", cursor: "pointer" }}
                    src="./Assets/Other/facebooklink.png"
                  />
                </a>
                <br />
                <br />
                Se ti piace il nostro lavoro, puoi sostenerci offrendoci una
                birra:
                <br />
                <br />
                <a href="https://www.buymeacoffee.com/cantionline">
                  <Img
                    alt={"Buy me a coffee"}
                    src="https://img.buymeacoffee.com/button-api/?text=Buy me a beer&emoji=🍺&slug=cantionline&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
                  />
                </a>
              </div>
            }
          />
          <TitledPageSection
            title={"Altre info"}
            content={
              <div className="contact-banner-section">
                <div>
                  <span>
                    {AppName} è registrato nella lista dei
                    <strong> siti cattolici in italia</strong>:
                  </span>
                </div>
                <br />
                <a
                  href="http://www.siticattolici.it/"
                  rel="noopener nofollow noreferrer"
                  target={"_blank"}
                >
                  <Img
                    alt="Siti Cattolici Italiani"
                    src="./Assets/sitica.png"
                    style={{ border: "0", width: "120px" }}
                  />
                </a>
                <br />
                <br />
                <div>
                  Contatti: <strong>canti.online.info@gmail.com</strong>
                </div>
                <div>
                  <span>
                    {" "}
                    <Link to={"/about"}>About</Link>
                  </span>
                  <span> | </span>
                  <span>
                    {" "}
                    <Link to={"/privacy"}>Privacy</Link>
                  </span>
                  <span> | </span>
                  <span>
                    {" "}
                    <a
                      rel="noopener nofollow noreferrer"
                      target={"_blank"}
                      href={
                        "https://www.facebook.com/CantiOnlineNeocatecumenali"
                      }
                    >
                      Facebook
                    </a>
                  </span>
                  <span> | </span>
                  <span>
                    {" "}
                    <a
                      rel="noopener nofollow noreferrer"
                      target={"_blank"}
                      href={"https://www.buymeacoffee.com/cantionline"}
                    >
                      Supportaci
                    </a>
                  </span>
                </div>
              </div>
            }
          />
          <ModalDialog
            actionButtons={[
              {
                label: tourPage <= 0 ? "Chiudi" : "Indietro",
                onClick: () => {
                  if (tourPage <= 0) {
                    setDialogOpen(false);
                  } else {
                    setTourPage(tourPage - 1);
                  }
                },
              },
              {
                label:
                  tourPage < SLIDESHOW_IMAGES.length - 1 ? "Avanti" : "Chiudi",
                onClick: () => {
                  if (tourPage < SLIDESHOW_IMAGES.length - 1) {
                    setTourPage(tourPage + 1);
                  } else {
                    setDialogOpen(false);
                  }
                },
              },
            ]}
            onClose={() => {
              setDialogOpen(false);
            }}
            isOpen={dialogOpen}
            title={"Impara alcune funzioni interessanti!"}
            content={<SlideShow page={tourPage} images={SLIDESHOW_IMAGES} />}
          />
        </div>
      }
    />
  );
};

export default HomeScreen;
