import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Guardate come è bello.",
  url_video: "https://www.youtube.com/embed/EvZaZ6bg80w",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi              La- Mi- Si7            Mi",
          text: "Guardate come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi              La- Mi- Si7            Mi",
          text: "Guardate come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si-      Mi-",
          text: "Come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si-      Mi-",
          text: "Come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             La- Mi- Si7        Mi-",
          text: "È come olio che scende sul capo di Aronne.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             La- Mi- Si7        Mi-",
          text: "È come olio che scende sul capo di Aronne.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol          Mi-",
          text: "Come  olio  sul capo di Aronne.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol          Mi-",
          text: "Come  olio  sul capo di Aronne.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             La- Mi- Si7        Mi-",
          text: "Come rugiada dall'Ermon sui monti di Sion.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             La- Mi- Si7        Mi-",
          text: "Come rugiada dall'Ermon sui monti di Sion.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  Do Re Sol        Si- Mi-",
          text: "Come rugiada sui monti di Sion.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  Do Re Sol        Si- Mi-",
          text: "Come rugiada sui monti di Sion.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La- Mi- Si7        Mi-",
          text: "Là Jahvè ha mandato la sua benedizione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La- Mi- Si7        Mi-",
          text: "Là Jahvè ha mandato la sua benedizione.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si- Mi-",
          text: "Ha mandato la sua benedizione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si- Mi-",
          text: "Ha mandato la sua benedizione.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La- Mi- Si7        Mi-",
          text: "Là Jahvè ha donato la vita in eterno.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La- Mi- Si7        Mi-",
          text: "Là Jahvè ha donato la vita in eterno.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si- Mi-",
          text: "Ha donato la vita in eterno.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si- Mi-",
          text: "Ha donato la vita in eterno.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi              La- Mi- Si7            Mi",
          text: "Guardate come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi              La- Mi- Si7            Mi",
          text: "Guardate come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si-      Mi-",
          text: "Come è bello stare con i fratelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do Re Sol      Si-      Mi-",
          text: "Come è bello stare con i fratelli.",
        },
      ],
    },
  ],
  subtitle: "Sal 132(133)",
};
