import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ti amo Signore.",
  url_video: "https://www.youtube.com/embed/45xxxNX5MZY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Si-",
          text: "LE ONDE DELLA MORTE MI AVVOLGEVANO,",
        },
        {
          notes: "Sol                              Re",
          text: "MI SPAVENTAVANO LE TROMBE DI BELIAL,",
        },
        {
          notes: "         La                     Sol",
          text: "MI STRINGEVANO I LACCI DELLO SHEOL,",
        },
        {
          notes: "                          Re",
          text: "ERO PRESO NEI CEPPI DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "                     VIOLINO",
      mode: "red-utility-message",
      lines: [],
    },
    {
      speaker: "ti_amo_signore_violino_1",
      mode: "assets-import-message",
      lines: [],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Si-",
          text: "LE ONDE DELLA MORTE MI AVVOLGEVANO,",
        },
        {
          notes: "Sol                              Re",
          text: "MI SPAVENTAVANO LE TROMBE DI BELIAL,",
        },
        {
          notes: "         La                     Sol",
          text: "MI STRINGEVANO I LACCI DELLO SHEOL,",
        },
        {
          notes: "                          Re",
          text: "ERO PRESO NEI CEPPI DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "                     VIOLINO",
      mode: "red-utility-message",
      lines: [],
    },
    {
      speaker: "ti_amo_signore_violino_2",
      mode: "assets-import-message",
      lines: [],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#",
          text: "Chiamai il Signore nella mia angoscia,",
        },
        {
          notes: "                             Si-",
          text: "il mio Dio, il mio Dio invocai;",
        },
        {
          notes: "       La                      Sol",
          text: "e ascoltò la mia voce dal suo tempio",
        },
        {
          notes: "                                  Fa#",
          text: "e giunse al suo orecchio il mio grido;",
        },
        {
          notes: "      Sol                    Fa#",
          text: "e la terra si scosse e vacillò,",
        },
        {
          notes: "   Sol                      Fa#",
          text: "tremarono le fondamenta dei monti,",
        },
        {
          notes: "    Sol                      Fa#",
          text: "perché abbassò i cieli e discese.",
        },
        {
          notes: "   La                   Fa#",
          text: "E apparve il fondo del mare,",
        },
        {
          notes: "        La                      Fa#",
          text: "le fondamenta del mondo si scoprirono,",
        },
        {
          notes: "   Sol",
          text: "e stese la sua mano e mi prese,",
        },
        {
          notes: "                             Fa#",
          text: "mi sollevò dal profondo delle acque.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re          Fa#             Si-",
          text: "TI AMO, SIGNORE, SEI LA MIA ROCCIA,",
        },
        {
          notes: "Sol                                  Fa#",
          text: "SEI IL MIO LIBERATORE, TU SEI IL MIO DIO,",
        },
        {
          notes: "Sol                Fa#",
          text: "TI AMO, TI AMO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Si-",
          text: "LE ONDE DELLA MORTE MI AVVOLGEVANO,",
        },
        {
          notes: "Sol                              Re",
          text: "MI SPAVENTAVANO LE TROMBE DI BELIAL,",
        },
        {
          notes: "         La                     Sol",
          text: "MI STRINGEVANO I LACCI DELLO SHEOL,",
        },
        {
          notes: "                          Re",
          text: "ERO PRESO NEI CEPPI DELLA MORTE.",
        },
      ],
    },
  ],
  subtitle: "Sal 17(18)",
};
