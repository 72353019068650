import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Tu sei il più bello.",
  url_video: "https://www.youtube.com/embed/GIOIKPzNEeg",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "TU SEI IL PIÙ BELLO, TU SEI IL PIÙ BELLO",
        },
        {
          notes: "",
          text: "DEI FIGLI DI ADAM, DEI FIGLI DI ADAM.",
        },
        {
          notes: "",
          text: "LA GRAZIA HAI SULLE LABBRA,",
        },
        {
          notes: "",
          text: "SEI BENEDETTO, BENEDETTO PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "TU ERES HERMOSO, EL MAS HERMOSO",
        },
        {
          notes: "",
          text: "DE LOS HIJOS DE ADÁN, DE LOS HIJOS DE ADÁN.",
        },
        {
          notes: "",
          text: "LA GRACIA ESTÀ EN TUS LABIOS,",
        },
        {
          notes: "",
          text: "ERES BENDITO, EL BENDITO PARA SIEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-                          Sol#",
          text: "Cingi, prode, la spada al tuo fianco",
        },
        {
          notes: "    La                              Sol#",
          text: "e cavalca pieno di grazia e di splendore,",
        },
        {
          notes: "Fa#-                                    Sol#",
          text: "avanza per la verità, la mitezza e la giustizia,",
        },
        {
          notes: "",
          text: "tendi il tuo arco",
        },
        {
          notes: "         La           Sol#",
          text: "che fa potente la tua destra;",
        },
        {
          notes: "Fa#-                Sol#",
          text: "acute sono le tue frecce,",
        },
        {
          notes: "    Si         La       Sol#",
          text: "ti sono state date le nazioni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "TU SEI IL PIÙ BELLO, TU SEI IL PIÙ BELLO",
        },
        {
          notes: "",
          text: "DEI FIGLI DI ADAM, DEI FIGLI DI ADAM.",
        },
        {
          notes: "",
          text: "LA GRAZIA HAI SULLE LABBRA,",
        },
        {
          notes: "",
          text: "SEI BENEDETTO, BENEDETTO PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-          Sol#      La             Sol#",
          text: "Dai palazzi d'avorio le cetre cantano per te,",
        },
        {
          notes: "Fa#-                        Sol#",
          text: "figlie di re sono le tue predilette",
        },
        {
          notes: "          Si           La             Sol#",
          text: "alla tua destra è la regina in oro di Ofir",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "         Si           La             Sol#",
          text: "ALLA TUA DESTRA È LA REGINA IN ORO DI OFIR.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "TU SEI IL PIÙ BELLO, TU SEI IL PIÙ BELLO",
        },
        {
          notes: "",
          text: "DEI FIGLI DI ADAM, DEI FIGLI DI ADAM.",
        },
        {
          notes: "",
          text: "LA GRAZIA HAI SULLE LABBRA,",
        },
        {
          notes: "",
          text: "SEI BENEDETTO, BENEDETTO PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-             Sol#     La         Sol#",
          text: "Ascolta, figlia, guarda, porgi l'orecchio,",
        },
        {
          notes: "Fa#-                                     Sol#",
          text: "dimentica il tuo popolo e la casa di tuo padre",
        },
        {
          notes: "    Si       La           Sol#",
          text: "e al re piacerà la tua bellezza.",
        },
        {
          notes: "  Fa#-            Sol#    La     Sol#",
          text: "Egli è il tuo Signore, donati a lui,",
        },
        {
          notes: "    Si                   La",
          text: "e invece di padri avrai figli",
        },
        {
          notes: "                               Sol#",
          text: "che tu farai principi sopra la terra.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "TU SEI IL PIÙ BELLO, TU SEI IL PIÙ BELLO",
        },
        {
          notes: "",
          text: "DEI FIGLI DI ADAM, DEI FIGLI DI ADAM.",
        },
        {
          notes: "",
          text: "LA GRAZIA HAI SULLE LABBRA,",
        },
        {
          notes: "",
          text: "SEI BENEDETTO, BENEDETTO PER SEMPRE.",
        },
      ],
    },
  ],
  subtitle: "Sal 44(45)",
};
