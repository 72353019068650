import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno alla carità.",
  url_video: "https://www.youtube.com/embed/GFq3SJbb0NA",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  altnotes: "La",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Anche se parlassi le lingue degli angeli,",
        },
        {
          notes: " Fa                 Sol",
          text: "se non ho l'amore, niente!",
        },
        {
          notes: " Do",
          text: "Anche se avessi il dono della profezia",
        },
        {
          notes: "                     La-",
          text: "e conoscessi tutti i misteri;",
        },
        {
          notes: " Do",
          text: "anche se possedessi una fede",
        },
        {
          notes: "                   La-",
          text: "da trasportare le montagne,",
        },
        {
          notes: " Fa                 Sol",
          text: "se non ho l'amore, niente!",
        },
        {
          notes: " Do",
          text: "Anche se distribuissi tutti i miei beni ai poveri",
        },
        {
          notes: "                            La-",
          text: "e dessi il mio corpo alle fiamme,",
        },
        {
          notes: " Fa                 Sol",
          text: "se non ho l'amore, niente!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Do      La-      Do",
          text: "PERCHÉ L'AMORE, L'AMORE, L'AMORE:",
        },
        {
          notes: "",
          text: "È PAZIENTE, È SERVIZIEVOLE",
        },
        {
          notes: "          La-            Do",
          text: "NON È INVIDIOSO, NON SI VANTA",
        },
        {
          notes: "",
          text: "NON SI INSUPERBISCE,",
        },
        {
          notes: "    La-",
          text: "È DECOROSO:",
        },
        {
          notes: "                         Fa",
          text: "NON CERCA IL PROPRIO INTERESSE,",
        },
        {
          notes: "       Sol",
          text: "NON SI ADIRA.",
        },
        {
          notes: "                      Do",
          text: "NON TIENE CONTO DEL MALE,",
        },
        {
          notes: "                    La-",
          text: "NON TIENE CONTO DEL MALE,",
        },
        {
          notes: "                  Fa",
          text: "NON GODE DELL'INGIUSTIZIA,",
        },
        {
          notes: "                  Sol",
          text: "SI RALLEGRA DELLA VERITÀ.",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "      Do",
          text: "TUTTO CREDE,",
        },
        {
          notes: "       La-",
          text: "TUTTO SPERA,",
        },
        {
          notes: "       Do",
          text: "TUTTO SCUSA:",
        },
        {
          notes: "",
          text: "SOPPORTA TUTTO",
        },
        {
          notes: "",
          text: "SOPPORTA TUTTO.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                   La-",
          text: "PERCHÉ L'AMORE, L'AMORE,",
        },
        {
          notes: "          Do",
          text: "PERCHÉ L'AMORE",
        },
        {
          notes: "",
          text: "È DIO, È DIO, È DIO!",
        },
      ],
    },
  ],
  subtitle: "(1Cor 13, 1-13)",
};
