import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lodate il Signore dai cieli.",
  url_video: "https://www.youtube.com/embed/xXzjSIFB3hQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La",
          text: "Lodate il Signore dai cieli:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                      Fa#-",
          text: "Lodatelo nell'alto dei cieli:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La",
          text: "Lodatelo voi tutti suoi angeli,",
        },
        {
          notes: "",
          text: "Lodatelo voi tutte sue schiere.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Lodatelo sole e luna,",
        },
        {
          notes: "",
          text: "voi tutte fulgide stelle.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Lodatelo cieli dei cieli,",
        },
        {
          notes: "",
          text: "voi acque al di sopra dei cieli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Lodino tutti il nome del Signore,",
        },
        {
          notes: "",
          text: "perché egli disse e furono creati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Li ha stabiliti nei secoli per sempre,",
        },
        {
          notes: "",
          text: "ha posto una legge che non passa.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Lodate il Signore dalla terra,",
        },
        {
          notes: "",
          text: "mostri marini e voi tutti suoi abissi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Fuoco e grandine, neve e nebbia,",
        },
        {
          notes: "",
          text: "vento di bufera che obbedisce alla sua parola.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Monti e voi tutte, colline,",
        },
        {
          notes: "",
          text: "alberi da frutta e tutti voi, cedri.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Voi fiere e tutte le bestie,",
        },
        {
          notes: "",
          text: "rettili e uccelli alati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "I re della terra e i popoli tutti,",
        },
        {
          notes: "",
          text: "i governanti e i giudici della terra.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "I giovani e le fanciulle",
        },
        {
          notes: "",
          text: "i vecchi insieme ai bambini.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Lodate il nome del Signore",
        },
        {
          notes: "",
          text: "perché solo il suo nome è sublime.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "La sua gloria risplende sulla terra e nei cieli,",
        },
        {
          notes: "",
          text: "egli ha sollevato la potenza del suo popolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "È canto di lode per tutti i suoi fedeli,",
        },
        {
          notes: "",
          text: "per i figli di Israele, popolo che egli ama.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODATELO!",
        },
      ],
    },
    {
      speaker: "    Si può cantare in Do.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "Sal 148",
};
