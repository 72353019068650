import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Giorno di riposo.",
  url_video: "https://www.youtube.com/embed/lErWvSD4uWc",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-            La-      Mi-",
          text: "Chi custodisce la mia parola",
        },
        {
          notes: "",
          text: "non gusterà la morte mai.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 La-            Mi-",
          text: "TU SEI L'UNICO, L'UNICO CON ALLEGRIA.",
        },
        {
          notes: "",
          text: "CHI È COME IL SIGNORE?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-   Mi-",
          text: "Corona di salvezza",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La- Mi-",
          text: "MEMORIALE DI ALLEGRIA.",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "                  La-",
          text: "GIORNO DI RIPOSO E DI SANTITÀ",
        },
        {
          notes: "      Mi-",
          text: "HAI DATO AL TUO POPOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-  Mi-",
          text: "Abramo vide questo giorno",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-    Mi-",
          text: "E IN ISACCO SI RALLEGRÒ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "Giacobbe e i suoi figli",
        },
        {
          notes: "   Mi-",
          text: "in esso riposarono.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "GIACOBBE E I SUOI FIGLI",
        },
        {
          notes: "   Mi-",
          text: "IN ESSO RIPOSARONO.",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "                  La-",
          text: "GIORNO DI RIPOSO E DI SANTITÀ",
        },
        {
          notes: "      Mi-",
          text: "HAI DATO AL TUO POPOLO.",
        },
      ],
    },
  ],
  subtitle: "",
};
