import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Urì, urì, urà.",
  url_video: "https://www.youtube.com/embed/nFQXr6CBrQA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-            Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "               Mi-",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "                              Mi-",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "                              Si7",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "       Mi-",
          text: "LARALALALALA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                   Si7",
          text: "Un bimbo piccino nascerà,",
        },
        {
          notes: "            Mi-",
          text: "un bimbo piccolino.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                   Si7",
          text: "UN BIMBO PICCINO NASCERÀ,",
        },
        {
          notes: "            Mi-",
          text: "UN BIMBO PICCOLINO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                     Si7",
          text: "Come si chiamerà? Emmanuel.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                     Mi-",
          text: "COME SI CHIAMERÀ? EMMANUEL.",
        },
        {
          notes: "                     Si7",
          text: "COME SI CHIAMERÀ? EMMANUEL.",
        },
        {
          notes: "                Mi-",
          text: "VIENI, BAMBINO, VIEN.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-            Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "               Mi-",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "                              Mi-",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "                              Si7",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "       Mi-",
          text: "LARALALALALA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                       Si7",
          text: "Sua madre Maria sta a Betlem,",
        },
        {
          notes: "                Mi-",
          text: "suo padre è falegname.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                       Si7",
          text: "SUA MADRE MARIA STA A BETLEM",
        },
        {
          notes: "                Mi-",
          text: "SUO PADRE È FALEGNAME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                     Si7",
          text: "Come si chiamerà? Emmanuel.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-            Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "               Mi-",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Si7",
          text: "URÌ, URÌ, URÌ, URÀ,",
        },
        {
          notes: "                              Mi-",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "                              Si7",
          text: "PAM PAM, PAM PAM, PAM PARAPAM PAM,",
        },
        {
          notes: "       Mi-",
          text: "LARALALALALA.",
        },
      ],
    },
  ],
  subtitle: "",
};
