import { useState } from "react";
import PageLayout from "../../Components/PageLayout/PageLayout";
import { renderBlock } from "../../Components/SongRender/SongRender";
import StandardButton from "../../Components/StandardButton/StandardButton";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import { noSleepTrigger } from "../../nosleep";
import { GuideNiceTable } from "../GuideScreen_GuitarIntro/GuideScreen_GuitarIntro";
import "./GuideScreen_InteractiveSong.scss";

const GuideScreen_InteractiveSong = () => {
  const [noSleep, setNoSleep] = useState<boolean>(false);
  const [delay, setDelay] = useState<boolean>(false);

  return (
    <PageLayout
      backButton={"/guide"}
      title={"Lo spartito interattivo"}
      content={
        <div>
          In questa pagina sono descritte in dettaglio le funzionalità dello
          spartito interattivo, indicando ciò che c'è da sapere per un corretto
          utilizzo di ogni risorsa disponibile. Per pagina dello sparito si
          intende qualunque pagina in cui compare lo spartito di un canto.
          <TitledPageSection
            title={"Interattività integrata"}
            content={
              <div>
                In tutte le pagine dello spartito è possibile vedere come
                eseguire un accordo cliccando sulla sigla di una nota, oppure
                passandoci sopra con il mouse. Sono presenti anche gli schemi
                per le note non standard (per intenderci, sono quelle note che
                sul Resuscitò cartaceo hanno un * accanto).
                <br />
                <br />
                Puoi provare questa funzionalità su questo piccolo pezzo di
                spartito di esempio (per nascondere lo schema, premere su di
                esso o sulla stessa nota nello spartito):
                <div className="guide-interactive-song-block-wrap">
                  {renderBlock(
                    {
                      speaker: "C.",
                      mode: "normal",
                      lines: [
                        {
                          notes: "La-           Fa",
                          text: "O Gesù, amore mio,",
                        },
                        {
                          notes: "",
                          text: "quanto mi hai amato,",
                        },
                        {
                          notes: "               Mi",
                          text: "quanto mi hai amato!",
                        },
                      ],
                    },
                    0,
                    20,
                    0,
                    ""
                  )}
                </div>
                Nei canti in cui compare un asterisco nel testo dello spartito,
                è possibile visualizzare il relativo messaggio cliccando
                sull'asterisco o passandoci sopra con il mouse. Puoi provare
                questa funzionalità su questo piccolo pezzo di spartito di
                esempio:
                <div className="guide-interactive-song-block-wrap">
                  <div id="ast-message-text" style={{ display: "none" }}>
                    L'assemblea può alternare il ritornello dopo 1, 2, 3 strofe,
                    secondo le occasioni.
                  </div>
                  {renderBlock(
                    {
                      speaker: "C.+A.",
                      mode: "bar-from-ast-message",
                      lines: [
                        {
                          notes: "Do                 Fa",
                          text: "A TE CANTANO GLI ANGELI",
                        },
                        {
                          notes: "Sol",
                          text: "E TUTTE LE POTENZE DEI CIELI",
                        },
                        {
                          notes: "La-",
                          text: "SANTO, SANTO, SANTO,",
                        },
                        {
                          notes: "Mi",
                          text: "SANTO, SANTO, SANTO,",
                        },
                        {
                          notes: "Mi7                     La",
                          text: "IL SIGNORE DIO DELL'UNIVERSO",
                        },
                      ],
                    },
                    0,
                    30,
                    0,
                    ""
                  )}
                </div>
                Il popup sparirà dopo alcuni secondi da solo.
              </div>
            }
          />
          <TitledPageSection
            title={"Schermo sempre acceso"}
            content={
              <div>
                Se la pagina ha un buon margine di scorrimento verticale
                compariranno automaticamente sulla destra i comandi per lo
                scorrimento automatico. Quando la pagina scorre automaticamente
                entrerà in uno stato di <strong>prevent-sleep</strong>, ossia
                impedirà allo schermo di spegnersi nonostante l'assenza
                prolungata di input da parte dell'utente (che starà
                presumibilmente suonando la chitarra).
                <br />
                <br />
                La funzione di <strong>prevent-sleep</strong> non ha molta
                utilità se si accede al sito tramite computer, in quanto sul PC
                lo spegnimento dello schermo avviene con tempi di attesa
                estremamente più lunghi. Su dispositivo mobile tuttavia spesso
                accade che già dopo poche decine di secondi lo schermo si oscura
                e dopo poco si spegne. Grazie alla funzione di{" "}
                <strong>prevent-sleep</strong> questo non avviene. Lo schermo
                del vostro dispositivo non si spegnerà fino a che lo scorrimento
                automatico dello spartito non viene arrestato.
                <br />
                <br />
                Usa il bottone sottostante per accendere e spegnere la funzione
                di <strong>prevent-sleep</strong>:
                <div className="guide-interactive-song-center-content">
                  <StandardButton
                    disabled={delay}
                    label={noSleep ? "Disattiva" : "Attiva"}
                    onClick={() => {
                      noSleepTrigger(!noSleep);
                      setNoSleep(!noSleep);
                      setDelay(true);
                      setTimeout(() => {
                        try {
                          setDelay(false);
                        } catch (e) {}
                      }, 3000);
                    }}
                  />
                </div>
                <br />
                <br />
                Come avrai notato se hai usato il bottone, una piccola notifica
                sul fondo dello schermo ti avvisa quando la funzione di
                <strong> prevent-sleep</strong> viene attivata o disattivata.
              </div>
            }
          />
          <TitledPageSection
            title={"Barra di comando"}
            content={
              <div>
                Passiamo ora a parlare della barra di comando presente in ogni
                spartito.
                <br />
                <br />
                La barra di comando contiene una serie di azioni che l'utente
                può eseguire sullo spartito. Tutti gli effetti delle azioni
                fatte dall'utente si resettano ogni volta che si apre un nuovo
                spartito. In ordine di apparizione, da sinistra a destra,
                abbiamo:
                <ul>
                  <li>
                    Bottone dei preferiti: <strong>Aggiunge</strong> o
                    <strong> Rimuove</strong> lo spartito attuale dai preferiti.
                  </li>
                  <li>
                    Bottoni di dimenzione del testo: sono due bottoni, il primo
                    riduce e il secondo aumenta di poco la dimenzione del testo.
                  </li>
                  <li>
                    Bottone del video: mostra e nascone il pannello del player
                    del video che apparirà in basso a destra sullo schermo.
                  </li>
                  <li>
                    Bottoni del tono: sono due bottoni, il primo abbassa e il
                    secondo alza di un semitono le note degli accordi sullo
                    spartito.
                  </li>
                  <li>
                    Bottone di download: scarica lo spartito attuale come file
                    PDF. Lo spartito scaricato sarà nella forma minimalista
                    classica dei canti liturgici, quindi PDF a sfondo bianco con
                    note in rosso.
                  </li>
                </ul>
              </div>
            }
          />
          <TitledPageSection
            title={"Scorciatoie della tastiera"}
            content={
              <div>
                L'App supporta alcune shortcut* per gli utenti che dispongono di
                tastiera.
                <br />
                <GuideNiceTable
                  rows={[
                    ["Tasti", "Effetto"],
                    [
                      "Enter",
                      "Aumenta la velocità di scroll automatico di uno spartito",
                    ],
                    [
                      "Backspace",
                      "Riduci la velocità di scroll automatico di uno spartito",
                    ],
                    ["F", "Aggiungi/Rimuovi dai preferiti"],
                    ["R", "Naviga verso uno spartito casuale"],
                    ["U", "Aumenta la dimensione del testo"],
                    ["I", "Riduci la dimensione del testo"],
                    ["P", "Scarica lo spartito come PDF"],
                    ["V", "Apri/Chiudi video del canto"],
                    ["T", "Aumenta tono accordi"],
                    ["Y", "Riduci tono accordi"],
                  ]}
                />
                <small>
                  *Questi comandi hanno effetto solo quando l'App è aperta sulla
                  pagina di uno spartito.
                </small>
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default GuideScreen_InteractiveSong;
