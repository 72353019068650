import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Come condannati a morte.",
  url_video: "https://www.youtube.com/embed/4N1ONzHRmF0",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-                       Sol",
          text: "Fino al presente soffriamo fame",
        },
        {
          notes: "                     La-",
          text: "soffriamo sete e nudità.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                       Sol",
          text: "FINO AL PRESENTE SOFFRIAMO FAME",
        },
        {
          notes: "                     La-",
          text: "SOFFRIAMO SETE E NUDITÀ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                             La-",
          text: "Siamo perseguitati e andiamo erranti.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                              La-",
          text: "SIAMO PERSEGUITATI ED ANDIAMO ERRANTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol         La-",
          text: "Insultati, benediciamo",
        },
        {
          notes: "            Sol        La-",
          text: "e schiaffeggiati, lo sopportiamo.",
        },
        {
          notes: "Sol",
          text: "Se siamo diffamati",
        },
        {
          notes: "                  La-",
          text: "rispondiamo con amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "SE SIAMO DIFFAMATI",
        },
        {
          notes: "                  La-",
          text: "RISPONDIAMO CON AMORE.",
        },
        {
          notes: "           Sol",
          text: "SIAMO DIVENTATI,",
        },
        {
          notes: "           La-",
          text: "SIAMO DIVENTATI,",
        },
        {
          notes: "           Sol",
          text: "SIAMO DIVENTATI",
        },
        {
          notes: "                   La-",
          text: "LA SPAZZATURA DEL MONDO",
        },
        {
          notes: "Sol             La-",
          text: "E IL RIFIUTO DI TUTTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Perché penso",
        },
        {
          notes: "    Sol",
          text: "che a noi,",
        },
        {
          notes: "      La-",
          text: "gli apostoli,",
        },
        {
          notes: "            Sol",
          text: "Iddio ha assegnato",
        },
        {
          notes: "          La-",
          text: "l'ultimo posto",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Sol",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " La-",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " Sol",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " La-",
          text: "COME CONDANNATI A MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Posti",
        },
        {
          notes: "         Sol",
          text: "come spettacolo",
        },
        {
          notes: "         La-",
          text: "per il mondo",
        },
        {
          notes: "         Sol",
          text: "per gli angeli",
        },
        {
          notes: "          Fa",
          text: "per gli uomini",
        },
        {
          notes: "          Mi",
          text: "per gli uomini",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Sol",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " La-",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " Sol",
          text: "COME CONDANNATI A MORTE,",
        },
        {
          notes: " La-",
          text: "COME CONDANNATI A MORTE.",
        },
        {
          notes: "  La-",
          text: "SIAMO DIVENTATI,",
        },
        {
          notes: "           Sol",
          text: "SIAMO DIVENTATI",
        },
        {
          notes: "                   La-",
          text: "LA SPAZZATURA DEL MONDO",
        },
        {
          notes: "Sol             La-",
          text: "E IL RIFIUTO DI TUTTI.",
        },
      ],
    },
  ],
  subtitle: "(1Cor 4, 9-13)",
};
