import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Gustate e vedete.",
  url_video: "https://www.youtube.com/embed/irZBjz9cqO4",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-        La- Si7",
          text: "GUSTATE E VEDETE",
        },
        {
          notes: "         Do7       Si7",
          text: "QUANT'È BUONO IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                          Si7",
          text: "Benedirò il Signore in ogni tempo,",
        },
        {
          notes: "            La-                Si7",
          text: "sulla mia bocca sempre la sua lode.",
        },
        {
          notes: "Do                   Si7",
          text: "Io mi glorio nel Signore,",
        },
        {
          notes: " Do                          Si7",
          text: "ascoltino gli umili e si rallegrino.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-        La- Si7",
          text: "GUSTATE E VEDETE",
        },
        {
          notes: "         Do7       Si7",
          text: "QUANT'È BUONO IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Si7",
          text: "Celebrate con me il Signore,",
        },
        {
          notes: "          La-            Si7",
          text: "esaltiamo insieme il suo nome.",
        },
        {
          notes: "Do                    Si7",
          text: "Ho cercato il Signore e mi ha risposto",
        },
        {
          notes: "Do                        Si7",
          text: "e da ogni timore mi ha liberato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-        La- Si7",
          text: "GUSTATE E VEDETE",
        },
        {
          notes: "         Do7       Si7",
          text: "QUANT'È BUONO IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Si7",
          text: "Guardate a lui e sarete raggianti,",
        },
        {
          notes: "          La-             Si7",
          text: "non saranno confusi i vostri volti.",
        },
        {
          notes: "Do                     Si7",
          text: "Questo povero grida e il Signore lo ascolta,",
        },
        {
          notes: "Do                        Si7",
          text: "lo libera da tutte le sue angosce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-        La- Si7",
          text: "GUSTATE E VEDETE",
        },
        {
          notes: "         Do7       Si7",
          text: "QUANT'È BUONO IL SIGNORE",
        },
      ],
    },
  ],
  subtitle: "Sal 33(34)",
};
