import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Andiamo, già pastori.",
  url_video: "https://www.youtube.com/embed/bzF_XVjKYH4",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                               Si7",
          text: "ANDIAMO GIÀ PASTORI! ANDIAMO A BETLEM!",
        },
        {
          notes: "                                     Mi-",
          text: "OGGI È NATO UN BIMBO, SI CHIAMA EMANUEL,",
        },
        {
          notes: "                                 Si7",
          text: "SI CHIAMA EMANUEL, SI CHIAMA EMANUEL:",
        },
        {
          notes: "                                 Mi-",
          text: "ANDIAMO GIÀ PASTORI! ANDIAMO A BETLEM!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             La-         Si7        Mi-",
          text: "I poveri già cantano, i piccoli già ridono!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             La-         Si7        Mi-",
          text: "I POVERI GIÀ CANTANO, I PICCOLI GIÀ RIDONO!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "             La-        Si7       Mi-",
          text: "ALLEGRIA, ALLEGRIA, ALLEGRIA, ALLEGRIA,",
        },
        {
          notes: "             La-           Si7           Mi-",
          text: "ALLEGRIA, ALLEGRIA, OGGI È NATO IL SALVATOR!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                               Si7",
          text: "ANDIAMO GIÀ PASTORI! ANDIAMO A BETLEM!",
        },
        {
          notes: "                                     Mi-",
          text: "OGGI È NATO UN BIMBO, SI CHIAMA EMANUEL,",
        },
        {
          notes: "                                 Si7",
          text: "SI CHIAMA EMANUEL, SI CHIAMA EMANUEL:",
        },
        {
          notes: "                                 Mi-",
          text: "ANDIAMO GIÀ PASTORI! ANDIAMO A BETLEM!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-            La-      Si7       Mi-",
          text: "Sua madre è Maria, suo padre è Giuseppe.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               La-      Si7       Mi-",
          text: "SUA MADRE È MARIA, SUO PADRE È GIUSEPPE.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "             La-        Si7       Mi-",
          text: "ALLEGRIA, ALLEGRIA, ALLEGRIA, ALLEGRIA,",
        },
        {
          notes: "             La-           Si7           Mi-",
          text: "ALLEGRIA, ALLEGRIA, OGGI È NATO IL SALVATOR!",
        },
      ],
    },
  ],
  subtitle: "",
};
