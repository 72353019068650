import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "La_Guerra_Di_Piero.",
  title: "La guerra di piero.",
  url_video: "https://www.youtube.com/embed/KoYw0LHEWLM",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Mi                  La- ",
          text: "Dormi sepolto in un campo di grano,",
        },
        {
          notes: "         Sol               Do",
          text: "non e' la rosa, non e' il tulipano",
        },
        {
          notes: "           Mi                   La-",
          text: "che ti fan veglia dall'ombra dei fossi",
        },
        {
          notes: "        Re-   Mi      La-",
          text: "ma sono mille papaveri rossi.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Mi               La-",
          text: "Lungo le sponde del mio torrente",
        },
        {
          notes: "           Re-                  La-",
          text: "voglio che scendano i lucci argentati,",
        },
        {
          notes: "           Sol           La-",
          text: "non più i cadaveri dei soldati",
        },
        {
          notes: "           Mi             La-",
          text: "portati in braccio dalla corrente.",
        },
        {
          notes: "       Mi             La-              Re-",
          text: "Così dicevi ed era d'Inverno e come gli altri,",
        },
        {
          notes: "          La-           Sol",
          text: "verso l'inferno te ne vai triste",
        },
        {
          notes: "        Do                 Mi                  La-",
          text: "come chi deve ed il vento ti sputa in faccia la neve.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "         Mi             La-",
          text: "Fermati Piero, fermati adesso,",
        },
        {
          notes: "              Sol                   Do",
          text: "lascia che il vento ti passi un po' addosso,",
        },
        {
          notes: "                Mi                La-",
          text: "dei morti in battaglia ti porti la voce,",
        },
        {
          notes: "             Re-         Mi          La-",
          text: "chi diede la vita ebbe in cambio una croce.",
        },
        {
          notes: "               Mi                 La-",
          text: "Ma tu non lo udisti ed il tempo passava",
        },
        {
          notes: "          Re-               La-",
          text: "con le stagioni a passo di giava",
        },
        {
          notes: "       Re-                 Do",
          text: "ed arrivasti a passar la frontiera",
        },
        {
          notes: "            Mi         La-",
          text: "in un bel giorno di Primavera.",
        },
        {
          notes: "            Mi                     La-",
          text: "E mentre marciavi con l'anima in spalle",
        },
        {
          notes: "           Re-               La-",
          text: "vedesti un uomo in fondo alla valle",
        },
        {
          notes: "                 Sol             Do",
          text: "che aveva il tuo stesso identico umore",
        },
        {
          notes: "         Mi                La-",
          text: "ma la divisa di un altro colore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Mi           La-            Sol          Do",
          text: "Sparagli Piero, sparagli ora e dopo un colpo sparagli ancora,",
        },
        {
          notes: "           Mi                La-",
          text: "fino a che tu non lo vedrai esangue",
        },
        {
          notes: "          Re-      Mi             La-",
          text: "cadere in terra a coprire il suo sangue.",
        },
        {
          notes: "         Mi                   La-",
          text: "E se gli sparo in fronte o nel cuore",
        },
        {
          notes: "            Re-             La-",
          text: "soltanto il tempo avrà per morire,",
        },
        {
          notes: "              Sol            Do",
          text: "ma il tempo a me resterà per vedere,",
        },
        {
          notes: "           Mi                 La-",
          text: "vedere gli occhi d'un uomo che muore.",
        },
        {
          notes: "             Mi            La-          Re-",
          text: "E mentre gli usi questa premura quello si volta,",
        },
        {
          notes: "            La-           Sol          Do",
          text: "ti vede, ha paura ed imbracciata l'artiglieria",
        },
        {
          notes: "         Mi           La-",
          text: "non ti ricambia la cortesia.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Mi               La-",
          text: "Cadesti a terra, senza un lamento",
        },
        {
          notes: "          Re-       Sol   Do",
          text: "e ti accorgesti in un solo momento",
        },
        {
          notes: "             Mi               La-",
          text: "che il tempo non ti sarebbe bastato",
        },
        {
          notes: "             Re-     Mi       La-",
          text: "a chieder perdono per ogni peccato.",
        },
        {
          notes: "          Mi               La-",
          text: "Cadesti a terra, senza un lamento",
        },
        {
          notes: "        Re-          Sol    Do",
          text: "e ti accorgesti in un solo momento",
        },
        {
          notes: "           Mi               La-",
          text: "che la tua vita finiva quel giorno",
        },
        {
          notes: "          Re-   Mi      La-",
          text: "e non ci sarebbe stato ritorno.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        Mi               La-",
          text: "Ninetta mia, crepare di Maggio",
        },
        {
          notes: "         Re-             La-",
          text: "ci vuole tanto, troppo coraggio.",
        },
        {
          notes: "        Sol               Do",
          text: "Ninetta bella diritto all'Inferno",
        },
        {
          notes: "          Mi                La-",
          text: "avrei preferito andarci in Inverno.",
        },
        {
          notes: "             Mi               La-",
          text: "E mentre il grano ti stava a sentire",
        },
        {
          notes: "          Re-                La-",
          text: "dentro le mani stringevi il fucile,",
        },
        {
          notes: "          Sol             Do",
          text: "dentro la bocca stringevi parole",
        },
        {
          notes: "         Mi                     La-",
          text: "troppo gelate per sciogliersi al sole.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Mi                  La- ",
          text: "Dormi sepolto in un campo di grano,",
        },
        {
          notes: "         Sol               Do",
          text: "non e' la rosa, non e' il tulipano",
        },
        {
          notes: "           Mi                   La-",
          text: "che ti fan veglia dall'ombra dei fossi",
        },
        {
          notes: "        Re-   Mi      La-",
          text: "ma sono mille papaveri rossi.",
        },
      ],
    },
  ],
  subtitle: "Fabrizio De André",
  sent_for_revision: false,
};
