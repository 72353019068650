import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Sequenza di pentecoste.",
  url_video: "https://www.youtube.com/embed/bVHI-3DgdcU",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                Fa",
          text: "Vieni, Padre dei poveri",
        },
        {
          notes: "                    Mi",
          text: "vieni, datore di doni.",
        },
        {
          notes: "  Do               Fa",
          text: "Vieni, luce dei cuori",
        },
        {
          notes: "                  Mi",
          text: "consolatore perfetto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                Fa",
          text: "Ospite dolce dell'anima",
        },
        {
          notes: "                Mi",
          text: "dolcissimo sollievo",
        },
        {
          notes: " Do             Fa",
          text: "riposo nella fatica",
        },
        {
          notes: "                Mi",
          text: "conforto nel pianto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do          Fa",
          text: "O luce beatissima",
        },
        {
          notes: "                   Mi",
          text: "invadi il nostro cuore,",
        },
        {
          notes: " Do            Fa",
          text: "senza la tua forza",
        },
        {
          notes: "                Mi",
          text: "nulla c'è nell'uomo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do               Fa",
          text: "Lava ciò che è sordido",
        },
        {
          notes: "                  Mi",
          text: "bagna ciò che è arido",
        },
        {
          notes: " Do              Fa",
          text: "sana ciò che sanguina",
        },
        {
          notes: "                  Mi",
          text: "piega ciò che è rigido.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                Fa",
          text: "Scalda ciò che è gelido",
        },
        {
          notes: "                 Mi",
          text: "drizza ciò che devia,",
        },
        {
          notes: " Do            Fa",
          text: "dona ai tuoi fedeli",
        },
        {
          notes: "              Mi",
          text: "i tuoi santi doni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do            Fa",
          text: "Dona virtù e premio",
        },
        {
          notes: "             Mi",
          text: "dona morte santa",
        },
        {
          notes: " Do          Fa",
          text: "dona eterna gioia",
        },
        {
          notes: "           Mi",
          text: "Amen. Alleluja!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do             Fa",
          text: "VIENI, SPIRITO SANTO,",
        },
        {
          notes: "                Mi",
          text: "MANDA LA TUA LUCE.",
        },
      ],
    },
  ],
  subtitle: "",
};
