import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lamenti del Signore.",
  url_video: "https://www.youtube.com/embed/iUEQJZlOj9Q",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Popolo mio, popolo mio che male ti ho fatto,",
        },
        {
          notes: "La",
          text: "che male ti ho fatto,",
        },
        {
          notes: "",
          text: "in che ti ho contristato,",
        },
        {
          notes: "",
          text: "dammi risposta,",
        },
        {
          notes: "Si7",
          text: "in che ti ho contristato?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Io ti ho guidato fuori dall’Egitto,",
        },
        {
          notes: "",
          text: "io ti ho guidato fuori dall’Egitto.",
        },
        {
          notes: "Si7",
          text: "E tu, e tu mi hai preparato la croce!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Popolo mio, popolo mio che male ti ho fatto,",
        },
        {
          notes: "La",
          text: "che male ti ho fatto,",
        },
        {
          notes: "",
          text: "in che ti ho contristato,",
        },
        {
          notes: "",
          text: "dammi risposta,",
        },
        {
          notes: "Si7",
          text: "in che ti ho contristato?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Perché ti ho guidato quarant’anni nel deserto",
        },
        {
          notes: "",
          text: "e ti ho sfamato con la manna",
        },
        {
          notes: "",
          text: "e ti ho introdotto nella terra promessa,",
        },
        {
          notes: "Si7",
          text: "e tu, e tu mi hai preparato la croce!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Popolo mio, popolo mio che male ti ho fatto,",
        },
        {
          notes: "La",
          text: "che male ti ho fatto,",
        },
        {
          notes: "",
          text: "in che ti ho contristato,",
        },
        {
          notes: "",
          text: "dammi risposta,",
        },
        {
          notes: "Si7",
          text: "in che ti ho contristato?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Che altro avrei dovuto fare e non ti ho fatto?",
        },
        {
          notes: "",
          text: "Io ti ho cantato: “mia vigna amata”",
        },
        {
          notes: "Si7",
          text: "E tu, e tu sei diventata amara!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Aghios Teòs,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Sanctus Deus,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Aghios Iskiròs,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Sanctus fortis,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Aghios Athanatos,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Eleison imàs",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Sanctus immortalis,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "miserere nobis!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Popolo mio, popolo mio che male ti ho fatto,",
        },
        {
          notes: "La",
          text: "che male ti ho fatto,",
        },
        {
          notes: "",
          text: "in che ti ho contristato,",
        },
        {
          notes: "",
          text: "dammi risposta,",
        },
        {
          notes: "Si7",
          text: "in che ti ho contristato?",
        },
      ],
    },
  ],
  subtitle: "venerdì santo - adorazione della croce",
};
