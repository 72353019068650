import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "La_canzone_del_sole.",
  title: "La canzone del sole.",
  url_video: "https://www.youtube.com/embed/Vu005eUjTMI",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La            Mi                  Re           Mi",
          text: "   Le bionde trecce gli occhi azzurri e poi",
        },
        {
          notes: "La            Mi           Re     Mi",
          text: "   le tue calzette rosse",
        },
        {
          notes: "La          Mi           Re        Mi",
          text: "   e l’innocenza sulle gote tue",
        },
        {
          notes: "La               Mi             Re     Mi",
          text: "   due arance ancor più rosse",
        },
        {
          notes: "La          Mi          Re        Mi",
          text: "   e la  cantina buia dove noi",
        },
        {
          notes: "La         Mi          Re     Mi",
          text: "   respiravamo piano",
        },
        {
          notes: "La           Mi           Re          Mi",
          text: "   e le tue corse l’eco dei tuoi no o no",
        },
        {
          notes: "La            Mi           Re     Mi",
          text: "   mi  stai facendo paura.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La           Mi             Re",
          text: "   Dove sei stata cos’hai fatto mai",
        },
        {
          notes: "Mi       La   Mi     Re     Mi",
          text: "   una donna donna donna dimmi ",
        },
        {
          notes: "La            Mi            Re",
          text: "   cosa vuol dir sono una donna ormai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi            La                 Mi            Re",
          text: "   Ma quante braccia ti hanno stretto tu lo sai",
        },
        {
          notes: "            Mi             La",
          text: "   per diventar quel che sei",
        },
        {
          notes: "               Mi            Re          Mi",
          text: "   che importa tanto tu non me lo dirai purtroppo",
        },
        {
          notes: "La          Mi              Re           Mi",
          text: "   Ma ti ricordi l’acqua verde e noi",
        },
        {
          notes: "La            Mi              Re       Mi",
          text: "   le rocce bianco il fondo",
        },
        {
          notes: "La           Mi             Re           Mi",
          text: "   di che colore sono gli occhi tuoi",
        },
        {
          notes: "La             Mi           Re      Mi",
          text: "   se me lo chiedi non rispondo",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   Oh mare nero Oh mare nero Oh mare ne...",
        },
        {
          notes: "La         Mi              Re           Mi",
          text: "   tu eri chiaro e trasparente come me",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   Oh mare nero Oh mare nero Oh mare ne...",
        },
        {
          notes: "La         Mi              Re           Mi",
          text: "   tu eri chiaro e trasparente come me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  Re      Mi",
          text: "Na Na Nanana Nanana",
        },
        {
          notes: "La  Mi  Re      Mi",
          text: "Na Na Nanana Nanana",
        },
        {
          notes: "La  Mi  Re      Mi",
          text: "Na Na Nanana Nanana",
        },
        {
          notes: "La  Mi  Re      Mi",
          text: "Na Na Nanana Nanana",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           La            Mi              Re             Mi",
          text: "   Le biciclette abbandonate sopra il prato e poi",
        },
        {
          notes: "La             Mi              Re     Mi",
          text: "   noi due distesi all’ombra",
        },
        {
          notes: "La              Mi            Re        Mi",
          text: "   un fiore in bocca può servire sai",
        },
        {
          notes: "La              Mi            Re     Mi",
          text: "   più allegro tutto sembra",
        },
        {
          notes: "La            Mi           Re              Mi",
          text: "   e d’improvviso quel silenzio tra noi",
        },
        {
          notes: "La               Mi            Re     Mi",
          text: "   e quel tuo sguardo strano",
        },
        {
          notes: "La             Mi           Re          Mi",
          text: "   ti cade il fiore dalla  bocca e poi Oh no",
        },
        {
          notes: "La           Mi         Re      Mi",
          text: "   ferma ti prego la  mano.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La           Mi             Re",
          text: "   Dove sei stata cos’hai fatto mai",
        },
        {
          notes: "Mi       La   Mi     Re     Mi",
          text: "   una donna donna donna dimmi ",
        },
        {
          notes: "La            Mi            Re",
          text: "   cosa vuol dir sono una donna ormai",
        },
        {
          notes: "Mi           La             Mi       Re       Mi",
          text: "   Io non conosco quel sorriso sicuro che hai",
        },
        {
          notes: "              La                  Mi            Re       Mi",
          text: "   non so chi sei non so più chi sei mi  fai paura oramai",
        },
        {
          notes: "            La         Mi             Re           Mi",
          text: "   purtroppo ma ti ricordi le onde grandi e noi",
        },
        {
          notes: "La                Mi           Re     Mi",
          text: "   gli spruzzi e le tue risa",
        },
        {
          notes: "La          Mi                   Re           Mi",
          text: "   cos’è rimasto in fondo agli occhi tuoi",
        },
        {
          notes: "La               Mi            Re       Mi",
          text: "   La  fiamma è spenta o è accesa?",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   Oh mare nero Oh mare nero Oh mare ne...",
        },
        {
          notes: "La         Mi              Re           Mi",
          text: "   tu eri chiaro e trasparente come me",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   Oh mare nero Oh mare nero Oh mare ne...",
        },
        {
          notes: "La         Mi              Re           Mi",
          text: "   tu eri chiaro e trasparente come me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La          Mi            Re            Mi",
          text: "   no no no no no no no no no",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   no no no no no no no no no",
        },
        {
          notes: "La          Mi            Re            Mi",
          text: "   no no no no no no no no no",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La          Mi            Re            Mi",
          text: "Noooo                Il sole quando sorge",
        },
        {
          notes: "       La      Mi      Re          Mi",
          text: "sorge piano e poi la luce si diffonde",
        },
        {
          notes: "        La       Mi      Re            Mi",
          text: "tutto intorno a noi le ombre dei fantasmi ",
        },
        {
          notes: "      La          Mi           Re                Mi",
          text: "della  notte sono alberi o cespugli ancora in fiore",
        },
        {
          notes: "         La            Mi             Re        Mi",
          text: "sono gli occhi di una donna ancora pieni d’amore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  Re Mi",
          text: "Eh eh… nanana",
        },
      ],
    },
  ],
  subtitle: "Lucio Battisti",
  sent_for_revision: false,
};
