import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo.",
  url_video: "https://www.youtube.com/embed/FE_z8pLK_7E",
  barrè: "III",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "SANTO, SANTO, SANTO",
        },
        {
          notes: "Sol           La-",
          text: "SANTO, SANTO, SANTO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol        La-",
          text: "I cieli e la terra",
        },
        {
          notes: "     Sol                  La-",
          text: "sono pieni, sono pieni di te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa      Mi      Fa      Mi",
          text: "OSANNA, OSANNA, OSANNA, OSANNA,",
        },
        {
          notes: "Fa      Sol         Fa       Mi",
          text: "OSANNA, OSANNA NELL'ALTO DEI CIELI!",
        },
        {
          notes: "Fa      Mi      Fa      Mi",
          text: "OSANNA, OSANNA, OSANNA, OSANNA,",
        },
        {
          notes: "Fa      Sol                  La-",
          text: "OSANNA, OSANNA NELL'ALTO DEI CIELI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol             La-",
          text: "Benedetto colui che viene",
        },
        {
          notes: "    Sol                   La-",
          text: "nel nome, nel nome del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa      Mi      Fa      Mi",
          text: "OSANNA, OSANNA, OSANNA, OSANNA,",
        },
        {
          notes: "Fa      Sol         Fa       Mi",
          text: "OSANNA, OSANNA NELL'ALTO DEI CIELI!",
        },
        {
          notes: "Fa      Mi      Fa      Mi",
          text: "OSANNA, OSANNA, OSANNA, OSANNA,",
        },
        {
          notes: "Fa      Sol                  La-",
          text: "OSANNA, OSANNA NELL'ALTO DEI CIELI!",
        },
      ],
    },
  ],
  subtitle: "(Tempo ordinario)",
};
