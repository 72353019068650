import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Non ti adirare.",
  url_video: "https://www.youtube.com/embed/UCUADceZoA4",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Fa",
          text: "NON TI ADIRARE PER I MALVAGI,",
        },
        {
          notes: "                           Mi7",
          text: "NON INVIDIARE CHI OPERA IL MALE.",
        },
        {
          notes: "        Re-        Mi7",
          text: "ESSI CADRANNO COME L'ERBA,",
        },
        {
          notes: "        Fa         Mi7",
          text: "COME IL PRATO SI SECCHERANNO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-                       Fa",
          text: "Confida nel Signore e fai il bene,",
        },
        {
          notes: "                         Mi7",
          text: "abita la tua terra e sii leale.",
        },
        {
          notes: "          Re-          Mi7",
          text: "Sia il Signore la tua delizia,",
        },
        {
          notes: "  Fa                                  Mi7",
          text: "lui ti darà quello che vuole il tuo cuore.",
        },
        {
          notes: "        La-                          Fa",
          text: "Affida il tuo cammino al Signore e spera in lui,",
        },
        {
          notes: "                  Mi7",
          text: "spera in lui che agirà;",
        },
        {
          notes: "       Re-               Mi7",
          text: "farà brillare la tua giustizia,",
        },
        {
          notes: "         Fa            Mi7",
          text: "come il sole la tua fedeltà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Fa",
          text: "NON TI ADIRARE PER I MALVAGI,",
        },
        {
          notes: "                           Mi7",
          text: "NON INVIDIARE CHI OPERA IL MALE.",
        },
        {
          notes: "        Re-        Mi7",
          text: "ESSI CADRANNO COME L'ERBA,",
        },
        {
          notes: "        Fa         Mi7",
          text: "COME IL PRATO SI SECCHERANNO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        La-                        Fa",
          text: "Sta' in silenzio davanti al Signore e spera in lui,",
        },
        {
          notes: "                                   Mi7",
          text: "non ti adirare per l'uomo che trionfa,",
        },
        {
          notes: "          Re                 Mi7",
          text: "che trionfa dicendo le menzogne:",
        },
        {
          notes: "         Re-                       Mi7",
          text: "tu confida nel Signore e spera in lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Fa",
          text: "NON TI ADIRARE PER I MALVAGI,",
        },
        {
          notes: "                           Mi7",
          text: "NON INVIDIARE CHI OPERA IL MALE.",
        },
        {
          notes: "        Re-        Mi7",
          text: "ESSI CADRANNO COME L'ERBA,",
        },
        {
          notes: "        Fa         Mi7",
          text: "COME IL PRATO SI SECCHERANNO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    La-                       Fa",
          text: "Desisti dall'ira, deponi lo sdegno,",
        },
        {
          notes: "                               Mi7",
          text: "non ti esasperare, non sia che operi il male,",
        },
        {
          notes: "      Re-                  Mi7",
          text: "chi opera il male sarà escluso,",
        },
        {
          notes: "     Fa                          Mi7",
          text: "ma chi spera nel Signore la terra possederà.",
        },
        {
          notes: "      La                 Fa",
          text: "Aspetta un momento è sparito il malvagio,",
        },
        {
          notes: "                            Mi7",
          text: "guarda il suo posto non c'è più.",
        },
        {
          notes: "             Re-                 Mi7",
          text: "Invece i mansueti posseggono la terra,",
        },
        {
          notes: "         Fa                  Mi7",
          text: "godono di pace, di grande pace.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Fa",
          text: "NON TI ADIRARE PER I MALVAGI,",
        },
        {
          notes: "                           Mi7",
          text: "NON INVIDIARE CHI OPERA IL MALE.",
        },
        {
          notes: "        Re-        Mi7",
          text: "ESSI CADRANNO COME L'ERBA,",
        },
        {
          notes: "        Fa         Mi7",
          text: "COME IL PRATO SI SECCHERANNO.",
        },
      ],
    },
  ],
  subtitle: "Sal 36(37)",
};
