import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Cantiamo, cantiamo.",
  url_video: "https://www.youtube.com/embed/x02OSBsdxGo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La          Mi",
          text: "Un cantico nuovo",
        },
        {
          notes: "Re           Mi",
          text: "leverò al Signore",
        },
        {
          notes: "La             Mi",
          text: "che in modo grandioso",
        },
        {
          notes: "Re           Mi",
          text: "si è glorificato.",
        },
        {
          notes: "               Re",
          text: "Cantiamo al Signore",
        },
        {
          notes: "Mi7              Re",
          text: "che ha vinto il nemico:",
        },
        {
          notes: "              Si7",
          text: "cantiamo al Signore",
        },
        {
          notes: "               Mi7",
          text: "che regna per sempre",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La           Re",
          text: "CANTIAMO, CANTIAMO",
        },
        {
          notes: "La          Mi",
          text: "AL NOSTRO SIGNORE",
        },
        {
          notes: "La               Re",
          text: "CHE HA VINTO IL NEMICO",
        },
        {
          notes: "La              Sol",
          text: "CHE REGNA PER SEMPRE",
        },
        {
          notes: "La           Re",
          text: "CANTIAMO, CANTIAMO",
        },
        {
          notes: "La               Mi",
          text: "È IL DIO DI MIO PADRE",
        },
        {
          notes: "La           Re",
          text: "CANTIAMO, CANTIAMO",
        },
        {
          notes: "La              Sol",
          text: "JAHVÈ È IL SUO NOME",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La          Mi",
          text: "Un cantico nuovo",
        },
        {
          notes: "Re           Mi",
          text: "leverò al Signore",
        },
        {
          notes: "La             Mi",
          text: "che in modo grandioso",
        },
        {
          notes: "Re           Mi",
          text: "si è glorificato.",
        },
        {
          notes: "               Re",
          text: "Cantiamo al Signore",
        },
        {
          notes: "Mi7              Re",
          text: "che ha vinto il nemico:",
        },
        {
          notes: "              Si7",
          text: "cantiamo al Signore",
        },
        {
          notes: "               Mi7",
          text: "che regna per sempre",
        },
      ],
    },
  ],
  subtitle: "(Es 15, 1-2)",
};
