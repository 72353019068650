import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Dite agli smarriti di cuore.",
  url_video: "https://www.youtube.com/embed/mVk6Hb4SPeQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Re-",
          text: "Dite agli smarriti di cuore,",
        },
        {
          notes: "                   La-",
          text: "ditelo, ditelo ai poveri:",
        },
        {
          notes: "        Fa      Mi",
          text: "coraggio, non temete,",
        },
        {
          notes: "           Fa            Mi",
          text: "il vostro Dio viene a salvarvi.",
        },
        {
          notes: "La-                                Re-",
          text: "Allora si apriranno gli occhi dei ciechi,",
        },
        {
          notes: "                                 La-",
          text: "si schiuderanno le orecchie dei sordi,",
        },
        {
          notes: "   Fa                            Mi",
          text: "allora lo zoppo salterà come un cervo,",
        },
        {
          notes: "           Fa                  Mi",
          text: "griderà di gioia la lingua del muto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do         Mi-       Do",
          text: "PERCHÉ CI SARÀ UNA STRADA,",
        },
        {
          notes: "     Mi-         La-",
          text: "UN CAMMINO DI SALVEZZA,",
        },
        {
          notes: "     Do          Mi-      La-",
          text: "UN CAMMINO, UNA VIA PER I POVERI,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Mi-                        La-",
          text: "E su di essa ritorneranno i riscattati dal Signore,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      Mi-                        La-",
          text: "E SU DI ESSA RITORNERANNO I RISCATTATI DAL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-                         La-",
          text: "Tutti i poveri, ciechi, zoppi, i lontani dal Signore,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-                         La-",
          text: "TUTTI I POVERI, CIECHI, ZOPPI, I LONTANI DAL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Mi-",
          text: "I peccatori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Fa            Mi",
          text: "E CANTERANNO FELICITÀ!",
        },
        {
          notes: "      Fa            Mi",
          text: "E CANTERANNO FELICITÀ!",
        },
        {
          notes: "     Fa       Mi",
          text: "ALLELUJA ALLELUJA!",
        },
        {
          notes: "     Fa       Mi",
          text: "ALLELUJA ALLELUJA!",
        },
      ],
    },
  ],
  subtitle: "(Is 35, 4 ss)",
};
