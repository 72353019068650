import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Siedi solitario e silenzioso.",
  url_video: "https://www.youtube.com/embed/kGnA7-hgu7E",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Dio, Dio",
        },
        {
          notes: " Fa#-                    Si-",
          text: "mi ha portato, mi ha portato",
        },
        {
          notes: "      Re",
          text: "nella tenebra.",
        },
        {
          notes: "                              Fa#-",
          text: "Ha costruito per me un giogo,",
        },
        {
          notes: "                                 Si-",
          text: "ha stretto la mia testa, la mia testa",
        },
        {
          notes: "       Re",
          text: "e la mascella.",
        },
        {
          notes: "                          Fa#-",
          text: "Dio, ha teso il suo arco",
        },
        {
          notes: "                           Si-",
          text: "e mi ha fissato come bersaglio",
        },
        {
          notes: "            Re",
          text: "delle sue frecce.",
        },
        {
          notes: "                       Fa#-",
          text: "Anche se grido e gemo",
        },
        {
          notes: "                  Si-",
          text: "egli soffoca, soffoca",
        },
        {
          notes: "            Re",
          text: "la mia preghiera.",
        },
        {
          notes: " Mi-",
          text: "Vedere come mi ha ridotto,",
        },
        {
          notes: "                  La",
          text: "nella mia vita errante:",
        },
        {
          notes: "              Re",
          text: "è assenzio e fiele.",
        },
        {
          notes: " Fa#",
          text: "Come farò",
        },
        {
          notes: "                     Si-",
          text: "se mi vien meno la speranza,",
        },
        {
          notes: "      La",
          text: "la speranza",
        },
        {
          notes: "                Sol",
          text: "che viene dal Signore",
        },
        {
          notes: "               Fa#     (La Sol Fa#)",
          text: "che viene dal Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                    Fa#-",
          text: "SIEDI SOLITARIO E SILENZIOSO",
        },
        {
          notes: "       Si-          Re",
          text: "PERCHÉ DIO TE L'HA IMPOSTO.",
        },
        {
          notes: "                            Fa#-",
          text: "METTI NELLA POLVERE LA TUA BOCCA",
        },
        {
          notes: " Si-        Re",
          text: "FORSE C'È SPERANZA.",
        },
        {
          notes: " Mi-                       La7",
          text: "OFFRI LA GUANCIA A CHI TI PERCUOTE:",
        },
        {
          notes: "       Sol                         La",
          text: "PERCHÉ NON RESPINGE PER SEMPRE IL SIGNORE,",
        },
        {
          notes: "   Sol                      La",
          text: "ANCHE SE AFFLIGGE USA POI MISERICORDIA.",
        },
        {
          notes: "       Sol                         La",
          text: "PERCHÉ NON RESPINGE PER SEMPRE IL SIGNORE,",
        },
        {
          notes: "   Sol                      La",
          text: "ANCHE SE AFFLIGGE USA POI MISERICORDIA.",
        },
        {
          notes: "     La7                 Re",
          text: "SECONDO LA SUA GRANDE CARITÀ.",
        },
      ],
    },
  ],
  subtitle: "(Lam 3, 1-33)",
};
