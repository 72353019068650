import { useState } from "react";
import Img from "../../Components/Img/Img";
import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import "./TuneScreen.scss";

const TuneScreen = () => {
  const [enabled, setEnabled] = useState<boolean>(false);

  return (
    <PageLayout
      title={"Accorda"}
      content={
        <div>
          Accorda la tua chitarra aggiustando la tensione delle corde.
          <MessageBanner
            type="info"
            message={
              "Se il tuner non si muove, vuol dire che il tuo browser non supporta questa funzionalità."
            }
          />
          <div className="tune-screen-centered-content">
            <strong>Cerca le seguenti note</strong>
            <br />
            <Img
              className="tune-screen-guitar-image"
              src={"./Assets/guitar_tuner.png"}
              alt={"Guitar map"}
            />
            <br />
            <br />
            {!enabled && (
              <StandardButton
                label={"Avvia il tuner"}
                onClick={() => {
                  setEnabled(true);
                }}
              />
            )}
          </div>
          {enabled && (
            <iframe
              width={"100%"}
              height={"350"}
              style={{ background: "white" }}
              src={"./Tuner/index.html"}
            />
          )}
        </div>
      }
    />
  );
};

export default TuneScreen;
