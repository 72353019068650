import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Non resistete al male.",
  url_video: "https://www.youtube.com/embed/-1SQcPQ5IOg",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Avete udito che vi fu detto:",
        },
        {
          notes: " Si-",
          text: "occhio per occhio, dente per dente.",
        },
        {
          notes: " La                      Si-",
          text: "Ma io vi dico, ma io vi dico:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: " La",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                            Re",
          text: "E se qualcuno ti percuote sulla guancia destra",
        },
        {
          notes: "  La             Si-",
          text: "porgigli anche l'altra.",
        },
        {
          notes: "                               Re",
          text: "E se qualcuno ti fa causa per toglierti il vestito",
        },
        {
          notes: "  La                  Si-",
          text: "lasciagli anche il mantello.",
        },
        {
          notes: "  La",
          text: "E se qualcuno ti obbliga a fare un miglio",
        },
        {
          notes: "              Si-",
          text: "fanne con lui due.",
        },
        {
          notes: "  La",
          text: "E se qualcuno ti toglie il tuo",
        },
        {
          notes: "            Si-",
          text: "non lo reclamare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: " La",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Avete udito che vi fu detto:",
        },
        {
          notes: " Si-",
          text: "amerai il tuo prossimo e odierai il tuo nemico.",
        },
        {
          notes: " La                      Si-",
          text: "Ma io vi dico, ma io vi dico:",
        },
        {
          notes: " Re",
          text: "Amate i vostri nemici,",
        },
        {
          notes: "Si-",
          text: "fate del bene a coloro che vi odiano,",
        },
        {
          notes: "  La",
          text: "pregate per i vostri persecutori",
        },
        {
          notes: "                  Si-",
          text: "benedite quelli che vi calunniano.",
        },
        {
          notes: "  Sol",
          text: "Siate perfetti",
        },
        {
          notes: " Si-",
          text: "come è perfetto il Padre vostro celeste:",
        },
        {
          notes: " Sol               La",
          text: "perché lui è buono con i peccatori,",
        },
        {
          notes: " Sol               La7",
          text: "perché lui è buono con i malvagi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: " La",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Si-             Re",
          text: "E sarete figli del Padre vostro celeste",
        },
        {
          notes: "       La                                Si-",
          text: "che fa sorgere il sole sui buoni e sui cattivi,",
        },
        {
          notes: "   La                             Si-",
          text: "e manda la sua pioggia anche sui malvagi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: " La",
          text: "NON RESISTETE AL MALE,",
        },
        {
          notes: "                  Si-",
          text: "NON RESISTETE AL MALE.",
        },
      ],
    },
  ],
  subtitle: "(Mt 5, 38 ss)",
};
