import NoSleep from "nosleep.js";

// this handler allows to disable screen sleep on mobile devices, credits: https://github.com/richtr/NoSleep.js
const noSleep = new NoSleep();
var noSleepIsPlaying = false;

export const noSleepRunning = () => {
  return noSleepIsPlaying;
};

// CREDITS: https://github.com/richtr/NoSleep.js/
export const noSleepTrigger = (toggle: boolean) => {
  // don't repeat commands to avoid multiple breadcrumbs
  if (toggle == noSleepIsPlaying) {
    return;
  }

  // no sleep will not switch off during full screen sessions
  if (toggle) {
    showBreadCrumb("Schermo sempre acceso");
    noSleepIsPlaying = true;
    noSleep.enable();
  } else {
    showBreadCrumb("Schermo libero di spegnersi");
    noSleepIsPlaying = false;
    noSleep.disable();
  }
};

export const showBreadCrumb = (msg: string) => {
  let d = document.createElement("div");
  d.className = "breadCrumb";
  d.innerHTML = msg;
  document.body.appendChild(d);
  setTimeout(() => {
    let animation_duration = 700; //ms
    d.style.transition = "opacity " + animation_duration + "ms linear";
    d.style.opacity = "0";
    setTimeout(function () {
      killBreadCrumb();
    }, animation_duration);
  }, 3000);
};

const killBreadCrumb = () => {
  while (document.getElementsByClassName("breadCrumb").length > 0) {
    let node: any = document.getElementsByClassName("breadCrumb")[0];
    node.parentElement.removeChild(node);
  }
};
