import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Gridate con gioia.",
  url_video: "https://www.youtube.com/embed/akXA_n5VJ1Q",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "GRIDATE CON GIOIA",
        },
        {
          notes: "                     Sol",
          text: "CHÉ GRANDE IN MEZZO A TE",
        },
        {
          notes: "      Fa        Mi",
          text: "È IL SANTO D'ISRAEL!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Il Signore Dio è la mia salvezza..",
        },
        {
          notes: "       La-",
          text: "confiderò e non temerò",
        },
        {
          notes: "           Fa                        Sol",
          text: "perché mia forza e mio canto è il Signore,",
        },
        {
          notes: "     Fa          Mi",
          text: "lui è il mio salvatore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "GRIDATE CON GIOIA",
        },
        {
          notes: "                     Sol",
          text: "CHÉ GRANDE IN MEZZO A TE",
        },
        {
          notes: "      Fa        Mi",
          text: "È IL SANTO D'ISRAEL!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                     La-",
          text: "Attingerete acqua con gioia",
        },
        {
          notes: "      Mi               La-",
          text: "alle sorgenti della salvezza.",
        },
        {
          notes: "            Sol                     Fa",
          text: "Lodate il Signore, invocate il suo nome",
        },
        {
          notes: "                                  Mi",
          text: "manifestate tra i popoli le sue meraviglie.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "GRIDATE CON GIOIA",
        },
        {
          notes: "                     Sol",
          text: "CHÉ GRANDE IN MEZZO A TE",
        },
        {
          notes: "      Fa        Mi",
          text: "È IL SANTO D'ISRAEL!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Cantate inni al Signore",
        },
        {
          notes: "           La-",
          text: "perché ha fatto con noi cose grandi.",
        },
        {
          notes: "           Sol",
          text: "Gridate con gioia,",
        },
        {
          notes: "                     Fa",
          text: "perché il Signore vi ama",
        },
        {
          notes: "                      Mi",
          text: "voi tutti abitanti di Sion!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "GRIDATE CON GIOIA",
        },
        {
          notes: "                     Sol",
          text: "CHÉ GRANDE IN MEZZO A TE",
        },
        {
          notes: "      Fa        Mi",
          text: "È IL SANTO D'ISRAEL!",
        },
      ],
    },
  ],
  subtitle: "(Is 12, 1 ss)",
};
