import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Dell'_aurora_tu_sorgi_più_bella.",
  title: "Dell' aurora tu sorgi più bella.",
  url_video: "https://www.youtube.com/embed/RA6HBQinOI8",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Fa      Do7       Re-   Sib",
          text: "Dell'aurora tu sorgi piu bella",
        },
        {
          notes: "         Fa          Sib      Do7 Do",
          text: "coi tuoi raggi a far lieta la terra",
        },
        {
          notes: "          Sol-         La7      Re-",
          text: "e fra gli astri che il cielo rinserra",
        },
        {
          notes: "        Sol7        Do7",
          text: "non v'e stella come te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "         Do   Sol7  Do7",
          text: "non son belle come   te.",
        },
        {
          notes: "Fa        La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "         Do  Sol7 Do7",
          text: "non son belle come   te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Fa           Do7       Re-   Sib",
          text: "Gli occhi tuoi son piu fondi del mare",
        },
        {
          notes: "       Fa              Sib      Do7 Do",
          text: "la tua fronte ha il profumo del giglio",
        },
        {
          notes: "       Sol-   La7       Re-",
          text: "il tuo viso ricorda tuo figlio",
        },
        {
          notes: "          Sol7         Do7",
          text: "suoi tuoi passi nascon fiori.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Fa     Do7      Re-    Sib",
          text: "Ti coronano tutte le stelle",
        },
        {
          notes: "       Fa       Sib        Do7 Do",
          text: "al tuo canto rispondera il vento",
        },
        {
          notes: "      Sol-    La7       Re-",
          text: "della luna si curva l'argento",
        },
        {
          notes: "     Sol7        Do7",
          text: "si rivolge verso te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Fa        Do7     Re-   Sib",
          text: "Quando tutto d'intorno rovina",
        },
        {
          notes: "     Fa       Sib        Do7 Do",
          text: "e la voce del pianto non tace",
        },
        {
          notes: "       Sol-      La7      Re-",
          text: "il tuo sguardo riporta la pace",
        },
        {
          notes: "      Sol7               Do7",
          text: "la concordia in fondo ai cuori.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
        {
          notes: "Fa          La    Sib   Sol-",
          text: "Bella tu sei qual sole",
        },
        {
          notes: "Do               Fa   Fa7",
          text: "bianca piu della luna",
        },
        {
          notes: "Sib              Sol-",
          text: "e le stelle piu belle",
        },
        {
          notes: "           Do Sol7    Do7",
          text: "non son belle come   te.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
