import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Me_so_mbriacato.",
  title: "Me so mbriacato.",
  url_video: "https://www.youtube.com/embed/moLwYtu31ZQ",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                      La-",
          text: "Quando io sono solo con te",
        },
        {
          notes: "Mi7                            La-",
          text: "   sogno immerso in una tazza di tè",
        },
        {
          notes: "Mi7                      La-",
          text: "   ma che caldo qua dentro",
        },
        {
          notes: "Mi7          La-                  Mi7",
          text: "   ma che bello il momento.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-  Mi7  La-  Mi7",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi7                 La-",
          text: "   Quando sono con te",
        },
        {
          notes: "Mi7                   La-",
          text: "   non so più chi sono perché",
        },
        {
          notes: "Mi7             La-",
          text: "   crolla il pavimento",
        },
        {
          notes: "Mi7                   La-",
          text: "   e mi sciolgo di dentro",
        },
        {
          notes: "Mi7           La-",
          text: "   quando penso a te",
        },
        {
          notes: "Mi7                 La-",
          text: "   mi sento denso perché",
        },
        {
          notes: "Mi7                           La-",
          text: "   io ti tengo qua dentro di me",
        },
        {
          notes: "Mi7        La-                    La7",
          text: "   io ti tengo qua dentro con me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Re-              La-",
          text: "Me so' mbriacato de 'na donna",
        },
        {
          notes: "         Mi7                  La-",
          text: "quanto è bono l'odore della gonna",
        },
        {
          notes: "         Re-                 La-",
          text: "quanto è bono l'odore der mare",
        },
        {
          notes: "         Mi7                 La-",
          text: "ce vado de notte a cerca’ le parol",
        },
        {
          notes: "         Re-                La-",
          text: "quanto è bono l'odore del vento",
        },
        {
          notes: "         Mi7                La-",
          text: "dentro lo sento, dentro lo sento.",
        },
        {
          notes: "        Re-                La-",
          text: "quanto è bono l'odore dell'ombra",
        },
        {
          notes: "     Mi7                        La-",
          text: "quando c'è er sole che sotto rimbomba.",
        },
        {
          notes: "      Mi7                      La-",
          text: "come rimbomba l'odore dell'ombra",
        },
        {
          notes: "     Mi7                La-",
          text: "come rimbomba, come rimbomba.",
        },
        {
          notes: "      Mi7                La-",
          text: "e come parte e come ritorna",
        },
        {
          notes: "       Mi7                 La-",
          text: "come ritorna l'odore dell'onda.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-  Mi7  La-  Mi7",
          text: "",
        },
        {
          notes: "La-  Mi7  La-  Mi7",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                            La-",
          text: "   Quando io sono solo con te",
        },
        {
          notes: "Mi7                      La-",
          text: "   io cammino meglio perché",
        },
        {
          notes: "Mi7                        La-",
          text: "   la mia schiena è più dritta",
        },
        {
          notes: "Mi7                         La-",
          text: "   la mia schiena è più dritta,",
        },
        {
          notes: " Mi7               La-",
          text: "   Quando sono con te",
        },
        {
          notes: "Mi7                  La-",
          text: "   io mangio meglio perché",
        },
        {
          notes: "Mi7              La-",
          text: "   non mi devo sfamare",
        },
        {
          notes: "Mi7         La-                La7",
          text: "   non mi devo saziare, con te",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        Re-                 La-",
          text: "Me so' mbriacato de 'na donna",
        },
        {
          notes: "          Mi7                La-",
          text: "quanto è bono l'odore della gonna",
        },
        {
          notes: "           Re-              La-",
          text: "quanto è bono l'odore der mare",
        },
        {
          notes: "           Mi7                 La-",
          text: "ce vado de notte a cerca’ le parole.",
        },
        {
          notes: "         Re-                La-",
          text: "quanto è bono l'odore del vento",
        },
        {
          notes: "          Mi7                La-",
          text: "dentro lo sento, dentro lo sento.",
        },
        {
          notes: "          Re-                 La-",
          text: "quanto è bono l'odore dell'ombra",
        },
        {
          notes: "              Mi7                 La-",
          text: "quando c'è er sole che sotto rimbomba.",
        },
        {
          notes: "        Mi7                   La-",
          text: "come rimbomba l'odore dell'ombra",
        },
        {
          notes: "        Mi7              La-",
          text: "come rimbomba, come rimbomba.",
        },
        {
          notes: "        Mi7            La-",
          text: "e come parte e come ritorna",
        },
        {
          notes: "        Mi7                 La-",
          text: "come ritorna l'odore dell'onda.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re- La- Mi La-",
          text: "",
        },
        {
          notes: "Re- La- Mi La-",
          text: "",
        },
        {
          notes: "Re- La- Mi La-",
          text: "",
        },
        {
          notes: "Re- La- Mi La-",
          text: "",
        },
        {
          notes: "La-  Mi7  La-  Mi7",
          text: "",
        },
      ],
    },
  ],
  subtitle: "Alessandro Mannarino",
  sent_for_revision: false,
};
