import Img from "../Img/Img";
import "./SlideShow.scss";

export interface SlideShowImage {
  value: string;
  altText?: string;
  description?: string;
}

export interface SlideShowProps {
  page: number;
  images: SlideShowImage[];
}

const SlideShow = (props: SlideShowProps) => {
  const renderImage = () => {
    if (!props.images || props.images.length === 0) {
      return <div></div>;
    }

    let page = props.page;
    if (page < 0) {
      page = 0;
    }
    if (page >= props.images.length) {
      page = props.images.length - 1;
    }

    let img: SlideShowImage = props.images[page];

    let image = (
      <div>
        <Img
          className="slide-show-image-img"
          alt={img.altText ?? "Image N." + page}
          src={img.value}
        />
        <div className="slide-show-image-caption">{img.description}</div>
      </div>
    );
    return image;
  };

  return <div className="slide-show-main-wrap">{renderImage()}</div>;
};

export default SlideShow;
