import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Chi è colei.",
  url_video: "https://www.youtube.com/embed/VbdZolMcRq4",
  barrè: "IV",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "    Fa                          Mi",
          text: "APPOGGIATA AL SUO DILETTO, AL SUO DILETTO,",
        },
        {
          notes: "    Fa            La-",
          text: "APPOGGIATA AL SUO DILETTO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Sol            La-",
          text: "Sotto il melo ti ho svegliata,",
        },
        {
          notes: "Sol         Fa       La-",
          text: "là dove ti concepì tua madre,",
        },
        {
          notes: "Fa                  Mi",
          text: "là dove tua madre ti partorì,",
        },
        {
          notes: "Fa                  La-",
          text: "là dove tua madre ti partorì.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "    Fa                          Mi",
          text: "APPOGGIATA AL SUO DILETTO, AL SUO DILETTO,",
        },
        {
          notes: "    Fa            La-",
          text: "APPOGGIATA AL SUO DILETTO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Fa           La-",
          text: "Mettimi come sigillo sul tuo cuore,",
        },
        {
          notes: "Sol      Fa       La-",
          text: "come tatuaggio sul braccio,",
        },
        {
          notes: "    Fa                       Mi",
          text: "perché forte come la morte è l'amore,",
        },
        {
          notes: "   Fa                    Mi",
          text: "è una fiamma che viene dal Signore,",
        },
        {
          notes: "  Fa                    Mi",
          text: "e le acque non lo spegneranno.",
        },
        {
          notes: "          Fa                                    Mi",
          text: "Ché se tu dessi tutti i beni della tua casa per l'amore,",
        },
        {
          notes: "  Fa                  Mi",
          text: "soltanto troveresti il disprezzo.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "",
          text: "CHI È COLEI CHE SALE DAL DESERTO,",
        },
        {
          notes: "    Fa                          Mi",
          text: "APPOGGIATA AL SUO DILETTO, AL SUO DILETTO,",
        },
        {
          notes: "    Fa            La-",
          text: "APPOGGIATA AL SUO DILETTO?",
        },
      ],
    },
  ],
  subtitle: "(Ct 8, 5-7)",
};
