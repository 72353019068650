import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Salve, regina dei cieli.",
  url_video: "https://www.youtube.com/embed/TxAZH1otxkU",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve Regina dei cieli,",
        },
        {
          notes: "   Sol",
          text: "Signora, Signora",
        },
        {
          notes: "      Fa",
          text: "degli Angeli.",
        },
        {
          notes: "",
          text: "Salve, Radice;",
        },
        {
          notes: "        Mi",
          text: "Salve, Porta!",
        },
        {
          notes: "Mi7",
          text: "Tu... Tu... Tu...",
        },
        {
          notes: "",
          text: "apristi la porta",
        },
        {
          notes: "            La-",
          text: "alla nostra luce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi",
          text: "RALLEGRATI, VERGINE MARIA,",
        },
        {
          notes: "                  La-",
          text: "FRA TUTTE LA PIÙ BELLA.",
        },
        {
          notes: "Re-            La-",
          text: "SALVE, ETERNA ANCELLA,",
        },
        {
          notes: "       Mi        La-",
          text: "PREGA CRISTO PER NOI.",
        },
        {
          notes: "Re-            La-",
          text: "SALVE, ETERNA ANCELLA,",
        },
        {
          notes: "      Mi         La-",
          text: "PREGA CRISTO PER NOI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-   Sol   Fa",
          text: "I ... i ... i ...",
        },
        {
          notes: "",
          text: "Salve, Radice;",
        },
        {
          notes: "        Mi",
          text: "Salve, Porta!",
        },
        {
          notes: "Mi7",
          text: "Tu... Tu... Tu...",
        },
        {
          notes: "",
          text: "apristi il cammino",
        },
        {
          notes: "            La-",
          text: "alla nostra luce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi",
          text: "RALLEGRATI, VERGINE MARIA,",
        },
        {
          notes: "                  La-",
          text: "FRA TUTTE LA PIÙ BELLA.",
        },
        {
          notes: "Re-            La-",
          text: "SALVE, ETERNA ANCELLA,",
        },
        {
          notes: "       Mi        La-",
          text: "PREGA CRISTO PER NOI.",
        },
        {
          notes: "Re-            La-",
          text: "SALVE, ETERNA ANCELLA,",
        },
        {
          notes: "      Mi         La-",
          text: "PREGA CRISTO PER NOI.",
        },
      ],
    },
  ],
  subtitle: "(INNO)",
};
