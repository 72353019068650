import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto dei tre giovani nella fornace. (II parte)",
  url_video: "https://www.youtube.com/embed/gUp-tf0MZ6M",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Opere tutte del Signore,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7          La-",
          text: "BENEDITE IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7",
          text: "Lodatelo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 La-",
          text: "ED ESALTATELO NEI SECOLI!",
        },
      ],
    },
    {
      speaker:
        "La parte seguente si canta mantenendo gli accordi iniziali e alzando il tono di una terza.",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Angeli del Signore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "e voi o cieli",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Acque tutte che siete sopra i cieli",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Potenze tutte del Signore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Sole e luna",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Stelle del cielo",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Piogge e rugiade",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "O venti tutti",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Fuoco e calore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Freddo e caldo",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Rugiada e brina",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Gelo e freddo",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Ghiacci e nevi",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Notti e giorni",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Luce e tenebre",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Folgori e nubi",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Benedica la terra il Signore",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7 ",
          text: "Lo lodi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-",
          text: "E LO ESALTI NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Monti e colline",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Creature tutte che germinate sulla terra",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "E voi, sorgenti",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Mari e fiumi",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Mostri marini e quanto si muove nell'aria",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Uccelli dell'aria",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Animali selvaggi e domestici",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Figli dell'uomo",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Benedica Israele il Signore",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7",
          text: "Lo lodi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-",
          text: "E LO ESALTI NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Sacerdoti del Signore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Servi del Signore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Spiriti ed anime dei giusti",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Pii ed umili di cuore",
        },
        {
          notes: "Mi7          La-",
          text: "Benedite il Signore",
        },
        {
          notes: "La-",
          text: "Anania, Azaria e Misaele",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7",
          text: "Lodatelo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-",
          text: "ED ESALTATELO NEI SECOLI",
        },
      ],
    },
    {
      speaker: "  (Recitato, non cantato.)",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Benediciamo il Padre e il Figlio con lo Spirito Santo!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "LODIAMOLO ED ESALTIAMOLO NEI SECOLI",
        },
      ],
    },
  ],
  subtitle: "(Dn 3, 57-88)",
};
