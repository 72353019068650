import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "O dio tu sei il mio dio.",
  url_video: "https://www.youtube.com/embed/MGBmzLVadl4",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "O DIO, TU SEI IL MIO DIO,",
        },
        {
          notes: "                La-",
          text: "TI CERCO DALL'AURORA,",
        },
        {
          notes: " Fa        Mi         La-",
          text: "HA SETE DI TE L'ANIMA MIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa         Mi         La-",
          text: "Ha sete di te l'anima mia.",
        },
        {
          notes: "  Re-               Mi",
          text: "desidera te la mia carne:",
        },
        {
          notes: "                La-",
          text: "terra arida ed arsa",
        },
        {
          notes: " Fa           Mi         La-",
          text: "terra arida ed arsa senz'acqua.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "O DIO, TU SEI IL MIO DIO,",
        },
        {
          notes: "                La-",
          text: "TI CERCO DALL'AURORA,",
        },
        {
          notes: " Fa        Mi         La-",
          text: "HA SETE DI TE L'ANIMA MIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa          Mi          La-",
          text: "È meglio il tuo amore che la vita,",
        },
        {
          notes: "  Re-               Mi",
          text: "esaltano te le mie labbra;",
        },
        {
          notes: "            La-",
          text: "così ti benedico",
        },
        {
          notes: " Fa         Mi         La-",
          text: "nel tuo nome sollevo le mani.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "O DIO, TU SEI IL MIO DIO,",
        },
        {
          notes: "                La-",
          text: "TI CERCO DALL'AURORA,",
        },
        {
          notes: " Fa        Mi         La-",
          text: "HA SETE DI TE L'ANIMA MIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Mi          La-",
          text: "L'anima mia aderisce a te",
        },
        {
          notes: "  Re-                 Mi",
          text: "mi sostiene con forza la tua destra",
        },
        {
          notes: "               La-",
          text: "quando mi ricordo di te",
        },
        {
          notes: " Fa            Mi         La-",
          text: "quando nelle mie veglie penso a te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "O DIO, TU SEI IL MIO DIO,",
        },
        {
          notes: "                La-",
          text: "TI CERCO DALL'AURORA,",
        },
        {
          notes: " Fa        Mi         La-",
          text: "HA SETE DI TE L'ANIMA MIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa          Mi          La-",
          text: "D'abbondanza si sazia l'anima mia",
        },
        {
          notes: "  Re-                 Mi",
          text: "ti loda con gioia la mia bocca,",
        },
        {
          notes: "               La-",
          text: "perché tu sei il mio aiuto",
        },
        {
          notes: " Fa            Mi         La-",
          text: "e all'ombra delle tue ali esulto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "O DIO, TU SEI IL MIO DIO,",
        },
        {
          notes: "                La-",
          text: "TI CERCO DALL'AURORA,",
        },
        {
          notes: " Fa        Mi         La-",
          text: "HA SETE DI TE L'ANIMA MIA.",
        },
      ],
    },
  ],
  subtitle: "Sal 62(63)",
};
