import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "In una notte oscura.",
  url_video: "https://www.youtube.com/embed/M7p_JcrS-rA",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "In una notte oscura",
        },
        {
          notes: "Re-                       Mi",
          text: "con ansie, in amori infiammata",
        },
        {
          notes: "            Fa",
          text: "oh beata ventura!",
        },
        {
          notes: "               Re-",
          text: "Uscii ne fui notata,",
        },
        {
          notes: "                        Mi",
          text: "essendo la mia casa già quietata.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "In una notte oscura",
        },
        {
          notes: "Re-                       Mi",
          text: "con ansie, in amori infiammata",
        },
        {
          notes: "            Fa",
          text: "oh beata ventura!",
        },
        {
          notes: "               Re-",
          text: "Uscii ne fui notata,",
        },
        {
          notes: "                        Mi",
          text: "essendo la mia casa già quietata.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Al buio e sicura",
        },
        {
          notes: "                           Mi",
          text: "per la segreta scala travestita",
        },
        {
          notes: "            Fa",
          text: "oh beata ventura!",
        },
        {
          notes: "               Re-",
          text: "Al buio ben celata,",
        },
        {
          notes: "                        Mi",
          text: "essendo la mia casa già quietata.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Mi",
          text: "Nella notte beata,",
        },
        {
          notes: "Re-                       Fa",
          text: "in segreto e nessuno mi vedeva",
        },
        {
          notes: "",
          text: "ne io guardavo cosa",
        },
        {
          notes: "                   Re-",
          text: "senza altra luce e guida",
        },
        {
          notes: "                                 Mi",
          text: "se non quella che il mio cuore ardeva.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Re-",
          text: "E questa mi guidava",
        },
        {
          notes: "                             La-",
          text: "più certa che la luce del meriggio,",
        },
        {
          notes: "               Mi",
          text: "laddove mi aspettava",
        },
        {
          notes: "               La-",
          text: "chi ben io conoscevo",
        },
        {
          notes: "   Re-                   Mi",
          text: "e dove nessun'altro si vedeva.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Re-",
          text: "O notte che guidasti",
        },
        {
          notes: "                               La-",
          text: "o notte amabil più dei primi albori!",
        },
        {
          notes: "              Mi",
          text: "o notte che legasti",
        },
        {
          notes: "           Fa",
          text: "Amato con amata",
        },
        {
          notes: "      Re-               Mi",
          text: "amata nell'Amato trasformata.",
        },
      ],
    },
  ],
  subtitle: "(S.Giovanni della Croce)",
};
