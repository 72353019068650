import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Come una cerva anela.",
  url_video: "https://www.youtube.com/embed/YWmi9adc5oI",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Come una cerva anela ai corsi delle acque,",
        },
        {
          notes: "                               Fa#-",
          text: "così l'anima mia anela a te, o Dio.",
        },
        {
          notes: "                                     Si7",
          text: "L'anima mia ha sete di Dio, del Dio vivente,",
        },
        {
          notes: "                         Mi",
          text: "quando vedrò il volto di Dio?",
        },
        {
          notes: "",
          text: "Le lacrime sono il mio pane giorno e notte,",
        },
        {
          notes: "",
          text: "mentre i miei nemici mi dicono tutto il giorno:",
        },
        {
          notes: "              Fa#-",
          text: "\"Dov'è il tuo Dio? Dov'è il tuo Dio?",
        },
        {
          notes: "",
          text: "Dov'è il tuo Dio?\".",
        },
        {
          notes: "             Si7",
          text: "Questo io ricordo",
        },
        {
          notes: "",
          text: "e l'anima mi si strugge in cuore,",
        },
        {
          notes: "",
          text: "quando avanzavo tra i primi",
        },
        {
          notes: "                      Mi     Sol#",
          text: "in mezzo al popolo in festa.",
        },
        {
          notes: "",
          text: "Perché ti abbatti anima mia?",
        },
        {
          notes: "                       Do#-",
          text: "Perché tu gemi dentro di me?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Fa#-           Si7",
          text: "SPERA IN DIO, SPERA IN DIO:",
        },
        {
          notes: "              Mi",
          text: "E TORNERAI A LODARLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Do#- Do#                       Fa#-",
          text: "Perché lui     è la salvezza del mio volto,",
        },
        {
          notes: "Si7          Do#- La           Sol#",
          text: "lui è il mio Dio, lui è il mio Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La            Sol#",
          text: "SPERA IN DIO, SPERA IN DIO,",
        },
        {
          notes: "         La            Si (Si Sib La)",
          text: "SPERA IN DIO, SPERA IN DIO,",
        },
        {
          notes: "La            Sol#",
          text: "E TORNERAI A LODARLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Quante volte ho detto alla mia rupe:",
        },
        {
          notes: "",
          text: "Perché mi hai abbandonato?",
        },
        {
          notes: "                                          Fa#-",
          text: "Perché mesto cammino, oppresso dai miei nemici?",
        },
        {
          notes: "      Si7",
          text: "Sono infrante le mie ossa nell'ascoltare tutto il giorno:",
        },
        {
          notes: "",
          text: "\"Dov'è il tuo Dio?",
        },
        {
          notes: "             Mi",
          text: "Dov'è il tuo Dio?\".",
        },
        {
          notes: "",
          text: "Signore, manda la tua luce, la tua verità:",
        },
        {
          notes: "                                Fa#-",
          text: "che esse mi guidino al tuo santo tempio,",
        },
        {
          notes: "     Si7",
          text: "e allora potrò cantare a te un canto di gioia",
        },
        {
          notes: "                                 Mi       Sol#",
          text: "un canto di allegria con la mia chitarra.",
        },
        {
          notes: "",
          text: "Perché ti abbatti anima mia?",
        },
        {
          notes: "                       Do#-",
          text: "Perché tu gemi dentro di me?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Fa#-           Si7",
          text: "SPERA IN DIO, SPERA IN DIO:",
        },
        {
          notes: "              Mi",
          text: "E TORNERAI A LODARLO.",
        },
      ],
    },
  ],
  subtitle: "Sal 41-42(42-43)",
};
