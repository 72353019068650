import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Se siete risorti con Cristo.",
  url_video: "https://www.youtube.com/embed/I3cwoZRVmVU",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                   La-",
          text: "Se siete risorti con Cristo",
        },
        {
          notes: "                    Mi",
          text: "cercate le cose di lassù,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                   La-",
          text: "SE SIETE RISORTI CON CRISTO",
        },
        {
          notes: "                    Mi",
          text: "CERCATE LE COSE DI LASSÙ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "dove si trova Cristo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-            Si7",
          text: "DOVE SI TROVA CRISTO,",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                         Si7",
          text: "DOVE SI TROVA CRISTO, ASSISO ALLA DESTRA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                     La-",
          text: "Aspirate alle cose del cielo",
        },
        {
          notes: "                     Mi-",
          text: "e non a quelle della terra;",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                     La-",
          text: "ASPIRATE ALLE COSE DEL CIELO",
        },
        {
          notes: "                     Mi-",
          text: "E NON A QUELLE DELLA TERRA;",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Si7",
          text: "voi siete morti",
        },
        {
          notes:
            "     Do                                                   Si7",
          text: "voi siete morti e la vostra vita è nascosta con Cristo in Dio.",
        },
        {
          notes: "Mi                   La-",
          text: "Se siete risorti con Cristo",
        },
        {
          notes: "                    Mi",
          text: "cercate le cose di lassù,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                   La-",
          text: "SE SIETE RISORTI CON CRISTO",
        },
        {
          notes: "                    Mi",
          text: "CERCATE LE COSE DI LASSÙ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "dove si trova Cristo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-            Si7",
          text: "DOVE SI TROVA CRISTO,",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                         Si7",
          text: "DOVE SI TROVA CRISTO, ASSISO ALLA DESTRA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                           Si7",
          text: "Quando si manifesterà Cristo, che è la vostra vita,",
        },
        {
          notes: "Do                                               Si7",
          text: "allora anche vi sarete manifestati con lui nella gloria.",
        },
        {
          notes: "Mi                   La-",
          text: "Se siete risorti con Cristo",
        },
        {
          notes: "                    Mi",
          text: "cercate le cose di lassù,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                   La-",
          text: "SE SIETE RISORTI CON CRISTO",
        },
        {
          notes: "                    Mi",
          text: "CERCATE LE COSE DI LASSÙ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "dove si trova Cristo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-            Si7",
          text: "DOVE SI TROVA CRISTO,",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                         Si7",
          text: "DOVE SI TROVA CRISTO, ASSISO ALLA DESTRA DI DIO.",
        },
      ],
    },
  ],
  subtitle: "(Col. 3,1-4)",
};
