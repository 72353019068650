import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo è santo.",
  url_video: "https://www.youtube.com/embed/28hHmFcXkqE",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "Sol",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "La-                     Mi-",
          text: "SANTO, È SANTO, JAHVÈ SABAOTH.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     La-  Mi-   La-  Mi-",
          text: "I cieli e la terra sono pieni di te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     La-  Mi-   La-  Mi-",
          text: "I CIELI E LA TERRA SONO PIENI DI TE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-     Mi-    La- Mi-",
          text: "Osanna nelle altezze, osanna!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-     Mi-    La- Mi-",
          text: "OSANNA NELLE ALTEZZE, OSANNA!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "Sol",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "La-                     Mi-",
          text: "SANTO, È SANTO, JAHVÈ SABAOTH.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-       Mi-   La- Mi-",
          text: "Benedetto colui che viene nel nome del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-       Mi-   La- Mi-",
          text: "BENEDETTO COLUI CHE VIENE NEL NOME DEL SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-     Mi-    La- Mi-",
          text: "Osanna nelle altezze, osanna!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-     Mi-    La- Mi-",
          text: "OSANNA NELLE ALTEZZE, OSANNA!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "Sol",
          text: "SANTO, È SANTO - SANTO, È SANTO;",
        },
        {
          notes: "La-                     Mi-",
          text: "SANTO, È SANTO, JAHVÈ SABAOTH.",
        },
      ],
    },
  ],
  subtitle: "(Tempo di Quaresima)",
};
