import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Andrò_a_vederla_un_di.",
  title: "Andrò a vederla un di.",
  url_video: "https://www.youtube.com/embed/G-TI7UT06iA",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Re  La7  Re    La7  Re",
          text: " ",
        },
        {
          notes: "      Re   Re     La7",
          text: " ",
        },
        {
          notes: "      Re  La Do#  Si-    Re   La7 Re",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Re               La7",
          text: "Andrò a vederla un dì",
        },
        {
          notes: "                Re",
          text: "in cielo patria mia",
        },
        {
          notes: "                La7",
          text: "andrò a veder Maria",
        },
        {
          notes: "                 Re",
          text: "mia gioia e mio amor",
        },
        {
          notes: "                   ",
          text: "Al ciel al ciel al ciel",
        },
        {
          notes: "                   La7",
          text: "andrò a vederla un dì",
        },
        {
          notes: "   Re      La Do#  Si-",
          text: "al ciel al ciel al ciel",
        },
        {
          notes: "  Re         La7   Re",
          text: "andrò a vederla un dì.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Re              La7",
          text: "Andrò a vederla un dì",
        },
        {
          notes: "                 Re",
          text: "è il grido di speranza",
        },
        {
          notes: "                 La7",
          text: "che infondemi costanza",
        },
        {
          notes: "                      Re",
          text: "nel viaggio e fra i dolor.",
        },
        {
          notes: "",
          text: "Lal ciel al ciel al ciel",
        },
        {
          notes: "                   La7",
          text: "andrò a vederla un dì",
        },
        {
          notes: "   Re      La Do#  Si-",
          text: "al ciel al ciel al ciel",
        },
        {
          notes: "  Re         La7   Re",
          text: "andrò a vederla un dì.",
        },
        {
          notes: "",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re  La7  Re    La7  Re",
          text: " ",
        },
        {
          notes: "Re   Re     La7",
          text: " ",
        },
        {
          notes: "Re  La Do#  Si-    Re   La7 Re   Si7",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Mi               Si7",
          text: "Andrò a vederla un dì",
        },
        {
          notes: "                  Mi",
          text: "lasciando questo elisio",
        },
        {
          notes: "               Si7",
          text: "le poserò qual figlio",
        },
        {
          notes: "                Mi",
          text: "il capo sul suo cuor",
        },
        {
          notes: "",
          text: "Al ciel al ciel al ciel",
        },
        {
          notes: "                   Si7",
          text: "andrò a vederla un dì",
        },
        {
          notes: "   Mi         Re#  Do#-",
          text: "al ciel al ciel al ciel",
        },
        {
          notes: "  Mi         Si7   Mi",
          text: "andrò a vederla un dì.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Mi              Si7",
          text: "Andrò a vederla un dì",
        },
        {
          notes: "                   Mi",
          text: "le andrò vicino al trono",
        },
        {
          notes: "               Si7",
          text: "ad ottenere in dono",
        },
        {
          notes: "                 Mi",
          text: "un serto di splendor.",
        },
        {
          notes: "",
          text: "Al ciel al ciel al ciel",
        },
        {
          notes: "                   Si7",
          text: "andrò a vederla un dì",
        },
        {
          notes: "   Mi        Re#  Do#-",
          text: "al ciel al ciel al ciel",
        },
        {
          notes: "  Mi         Si7   Mi",
          text: "andrò a vederla un dì.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Al ciel al ciel al ciel",
        },
        {
          notes: "                   Si7",
          text: "andrò a vederla un dì",
        },
        {
          notes: "   Mi        Re#  Do#-",
          text: "al ciel al ciel al ciel",
        },
        {
          notes: "  Mi         Si7   Mi",
          text: "andrò a vederla un dì.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
