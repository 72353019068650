import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Vergine della meraviglia.",
  url_video: "https://www.youtube.com/embed/L4vxu5bBS3I",
  barrè: "IV",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-    Mi-            Si-",
          text: "MARIA, FIGLIA DEL TUO FIGLIO,",
        },
        {
          notes: "Mi-                    Si-",
          text: "MADRE DI GESÙ E MADRE NOSTRA,",
        },
        {
          notes: "Sol               Fa#",
          text: "VERGINE DELLA MERAVIGLIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-     Mi-      Si-",
          text: "Maria, tu la creatura",
        },
        {
          notes: "Mi-                          Si-",
          text: "che hai dato alla luce il creatore,",
        },
        {
          notes: "Sol                                   Fa#",
          text: "tu sei la serva che ha concepito il Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-    Mi-            Si-",
          text: "MARIA, FIGLIA DEL TUO FIGLIO,",
        },
        {
          notes: "Mi-                    Si-",
          text: "MADRE DI GESÙ E MADRE NOSTRA,",
        },
        {
          notes: "Sol               Fa#",
          text: "VERGINE DELLA MERAVIGLIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-     Mi-      Si-",
          text: "Maria, fatta di terra,",
        },
        {
          notes: "Mi-          Si-",
          text: "tu sei la gloriosa,",
        },
        {
          notes: "Sol                    Fa#",
          text: "gloriosa immagine del cielo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-    Mi-            Si-",
          text: "MARIA, FIGLIA DEL TUO FIGLIO,",
        },
        {
          notes: "Mi-                    Si-",
          text: "MADRE DI GESÙ E MADRE NOSTRA,",
        },
        {
          notes: "Sol               Fa#",
          text: "VERGINE DELLA MERAVIGLIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-     Mi-            Si-",
          text: "Maria, essendo la più piccola",
        },
        {
          notes: "Mi-                   Si-",
          text: "tu sei la donna più grande,",
        },
        {
          notes: "Sol",
          text: "essendo la figlia di Dio",
        },
        {
          notes: "            Fa#",
          text: "sei la sua madre,",
        },
        {
          notes: "Mi-",
          text: "essendo la figlia di Dio",
        },
        {
          notes: "            Fa#",
          text: "sei la sua madre,",
        },
        {
          notes: "Sol                Fa#",
          text: "vergine della meraviglia!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-    Mi-            Si-",
          text: "MARIA, FIGLIA DEL TUO FIGLIO,",
        },
        {
          notes: "Mi-                    Si-",
          text: "MADRE DI GESÙ E MADRE NOSTRA,",
        },
        {
          notes: "Sol               Fa#",
          text: "VERGINE DELLA MERAVIGLIA.",
        },
      ],
    },
  ],
  subtitle: "",
};
