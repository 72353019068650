import PageLayout from "../../Components/PageLayout/PageLayout";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import "./AboutScreen.scss";

const AboutScreen = () => {
  return (
    <PageLayout
      backButton
      title={"About"}
      content={
        <div>
          <TitledPageSection
            title={"Copyright"}
            content={
              <div>
                Questo sito è un progetto libero no-profit basato su tecnologie
                sviluppate dal nostro staff o provenienti da progetti open
                source. Gli spartiti presentati sono privi di Copyright (canti
                liturgici) o sono di pubblico dominio (spartiti di canzoni
                famose riadattati). Il contenuto presente su questo sito è da
                intendersi esclusivamente ad uso didattico, con lo scopo
                primario di fornire un servizio gratuito a supporto di coloro
                che praticano (o puntano a praticare) in modo attivo canti per
                le celebrazioni liturgiche neocatecumenali. Questo sito è
                attualmente un progetto indipendente non direttamente affiliato
                con il Cammino Neocatecumenale. Il codice sorgente di questo
                sito non è protetto da Copyright ma si apprezza comunque la
                citazione della fonte qualora venisse usato.
              </div>
            }
          />
          <TitledPageSection
            title={"Riferimento legislativo"}
            content={
              <div>
                In base alla legge N.159 del 22-5-1993 tutto il materiale
                presente su questo sito è da intendersi per scopi esclusivamente
                didattici, di studio e di ricerca attraverso il libero scambio
                di informazioni fra diversi utenti, ed è stato prelevato da
                altri siti web in cui era offerto il libero download o segnalato
                dagli utenti. Tutti i documenti sono frutto di lavoro creativo
                personale dei singoli utenti e non sussistono pertanto atti
                lesivi di qualsivoglia diritto d’autore. Lo staff di questo sito
                web non si ritiene comunque responsabile per usi diversi di
                questo materiale rispetto a quello indicato. Ai sensi della
                Legge 7 marzo 2001 n. 62, Spartiti dei Canti Neocatecumenali
                dichiara che questo sito non rientra nella categoria
                dell’informazione periodica in quanto viene aggiornato ad
                intervalli non regolari. Ai sensi della Legge 15 aprile 2004 n.
                106, Spartiti dei Canti Neocatecumenali dichiara che questo sito
                non è oggetto di deposito legale obbligatorio in quanto i
                contenuti non sono riferibili ad atti, fatti o dati
                giuridicamente rilevanti (articolo 1 lettera A del D.P.R. 10
                novembre 1997 n. 513).
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default AboutScreen;
