import { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import "./MessageBanner.scss";

export interface MessageBannerProps {
  message: string | JSX.Element;
  type: "warning" | "error" | "info" | "success";
}

const MessageBanner = (props: MessageBannerProps) => {
  const [enabled, setEnabled] = useState<boolean>(true);

  const getIcon = () => {
    if (props.type === "warning") {
      return <WarningIcon />;
    }
    if (props.type === "error") {
      return <ErrorIcon />;
    }
    if (props.type === "info") {
      return <InfoIcon />;
    }
    if (props.type === "success") {
      return <DoneIcon />;
    }
    return <div></div>;
  };

  const getClass = () => {
    if (props.type === "warning") {
      return "message-banner-wrap-warning";
    }
    if (props.type === "error") {
      return "message-banner-wrap-error";
    }
    if (props.type === "info") {
      return "message-banner-wrap-info";
    }
    if (props.type === "success") {
      return "message-banner-wrap-success";
    }
    return "";
  };

  return (
    <div>
      {enabled && (
        <div className={"message-banner-wrap " + getClass()}>
          <div className="message-banner-flex-row">
            <div className="message-banner-icon">{getIcon()}</div>
            <div>{props.message}</div>
          </div>
          <div className="message-banner-close-button">
            <CloseIcon
              onClick={() => {
                setEnabled(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageBanner;
