import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Il_pescatore.",
  title: "Il pescatore.",
  url_video: "https://www.youtube.com/embed/9JYHr5YqN2I",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Do    Sol      Do",
          text: "All'ombra dell'ultimo sole",
        },
        {
          notes: "          Fa               Do",
          text: "si era assopito un pescatore",
        },
        {
          notes: "           Fa     Sol        La-",
          text: "e aveva un solco lungo il viso",
        },
        {
          notes: "Fa        Do      Sol      Do   Fa Do Sol",
          text: "come una specie di sorriso.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Do        Sol       Do",
          text: "Venne alla spiaggia un assassino",
        },
        {
          notes: "          Fa            Do",
          text: "due occhi grandi da bambino",
        },
        {
          notes: "          Fa      Sol    La-",
          text: "due occhi enormi di paura",
        },
        {
          notes: "Fa        Do       Sol           Do",
          text: "eran gli specchi di un'avventura.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do         Fa    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    La-",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Fa      Do  Sol    La-",
          text: "Nanana na nana nana ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "            Do         Sol         Do",
          text: "E chiese al vecchio, dammi il pane",
        },
        {
          notes: "        Fa              Do",
          text: "ho poco tempo e troppa fame",
        },
        {
          notes: "            Fa         Sol         La-",
          text: "e chiese al vecchio, versa il vino",
        },
        {
          notes: "Fa         Do    Sol         Do Fa Do Sol",
          text: "ho sete e sono un assassino.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Do            Sol          Do",
          text: "Gli occhi dischiuse il vecchio al giorno",
        },
        {
          notes: "        Fa              Do",
          text: "non si guardò neppure intorno",
        },
        {
          notes: "            Fa       Sol         La-",
          text: "ma versò il vino e spezzò il pane",
        },
        {
          notes: "Fa       Do         Sol         Do",
          text: "per chi diceva ho sete e ho fame.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do         Fa    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    La-",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Fa      Do  Sol    La-",
          text: "Nanana na nana nana",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "    Do          Sol      Do",
          text: "E fu il calore di un momento",
        },
        {
          notes: " Fa              Do",
          text: "poi via di nuovo verso il vento",
        },
        {
          notes: "          Fa         Sol         La-",
          text: "davanti agli occhi ancora il sole",
        },
        {
          notes: "Fa         Do    Sol         Do Fa Do Sol",
          text: "dietro alle spalle un pescatore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "    Do          Sol      Do",
          text: "Dietro alle spalle un pescatore",
        },
        {
          notes: "     Fa              Do",
          text: "e la memoria e' gia' dolore",
        },
        {
          notes: "       Fa         Sol         La-",
          text: "e' gia' il rimpianto di un aprile",
        },
        {
          notes: "Fa         Do    Sol         Do",
          text: "giocato all'ombra di un cortile.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do         Fa    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    La-",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Fa      Do  Sol    La-",
          text: "Nanana na nana nana",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Do          Sol      Do",
          text: "Vennero in sella due gendarmi",
        },
        {
          notes: "   Fa              Do",
          text: "vennero in sella con le armi",
        },
        {
          notes: "Fa            Sol            La-",
          text: "chiesero al vecchio se li vicino",
        },
        {
          notes: "Fa         Do     Sol      Do Fa Do Sol",
          text: "fosse passato un assassino.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do Fa Do Sol",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Do          Sol      Do",
          text: "Ma all'ombra dell'ultimo sole",
        },
        {
          notes: "   Fa              Do",
          text: "si era assopito il pescatore",
        },
        {
          notes: "       Fa         Sol         La-",
          text: "e aveva un solco lungo il viso",
        },
        {
          notes: "Fa         Do    Sol         Do",
          text: "come una specie di sorriso.",
        },
        {
          notes: "   Fa         Sol         La-",
          text: "e aveva un solco lungo il viso",
        },
        {
          notes: "Fa         Do    Sol         Do",
          text: "come una specie di sorriso.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do         Fa    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    Do",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Do         Sol    La-",
          text: "Nanana na nana nana ",
        },
        {
          notes: "Fa      Do  Sol    Do",
          text: "Nanana na nana nana",
        },
      ],
    },
  ],
  subtitle: "Fabrizio De André",
  sent_for_revision: false,
};
