import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Pietà di me o Dio.",
  url_video: "https://www.youtube.com/embed/aFoCzOu4aOQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-            La-                     Mi-",
          text: "Pietà di me o Dio secondo la tua misericordia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                La-                  Mi-",
          text: "NEL TUO GRANDE AMORE CANCELLA IL MIO PECCATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-             Mi-",
          text: "Lavami da tutte le mie colpe",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-          Mi-",
          text: "MONDAMI DAL MIO PECCATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-         Mi-",
          text: "Riconosco la mia colpa,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-                  Mi-",
          text: "IL MIO PECCATO MI STA SEMPRE DINANZI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La-             Mi-",
          text: "Contro te, contro te solo ho peccato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          La-                  Mi-",
          text: "QUELLO CHE È MALE AI TUOI OCCHI, IO L'HO FATTO;",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-            Mi-",
          text: "perciò sei giusto quando parli,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-         Mi-",
          text: "RETTO NEL TUO GIUDIZIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-              Mi-",
          text: "Ecco, nella colpa sono stato generato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-                Mi-",
          text: "NEL PECCATO MI HA CONCEPITO MIA MADRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-             Mi-",
          text: "Ma tu vuoi la sincerità del cuore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-                  Mi-",
          text: "E NELL'INTIMO MI INSEGNI LA SAPIENZA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-            Mi-",
          text: "Purificami con issòpo e sarò mondo;",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-                  Mi-",
          text: "LAVAMI, E SARÒ PIÙ BIANCO DELLA NEVE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-           Mi-",
          text: "Fammi sentire gioia e letizia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-                  Mi-",
          text: "ESULTERANNO LE OSSA CHE HAI SPEZZATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-             Mi-",
          text: "Distogli lo sguardo dai miei peccati,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-            Mi-",
          text: "CANCELLA TUTTE LE MIE COLPE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-           Mi-",
          text: "Crea in me, o Dio, un cuore puro,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-            Mi-",
          text: "RINNOVA IN ME UNO SPIRITO SALDO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-           Mi-",
          text: "Non respingermi dalla tua presenza",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La-                Mi-",
          text: "E NON PRIVARMI DEL TUO SANTO SPIRITO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-              Mi-",
          text: "Rendimi la gioia di essermi salvato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-               Mi-",
          text: "SOSTIENI IN ME UN ANIMO GENEROSO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-           Mi-",
          text: "Insegnerò agli erranti le tue vie",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-             Mi-",
          text: "E I PECCATORI A TE RITORNERANNO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-          La-                 Mi-",
          text: "Liberami dal sangue, Dio, Dio mia salvezza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "         La-                  Mi-",
          text: "LA MIA LINGUA ESALTERÀ LA TUA GIUSTIZIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     La-            Mi-",
          text: "Signore, apri le mie labbra",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                La-              Mi-",
          text: "E LA MIA BOCCA PROCLAMI LA TUA LODE;",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-               Mi-",
          text: "poiché non gradisci il sacrificio",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          La-                  Mi-",
          text: "E, SE OFFRO OLOCAUSTI, NON LI ACCETTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-                Mi-",
          text: "Uno spirito contrito è sacrificio a Dio,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               La-              Mi-",
          text: "UN CUORE AFFRANTO E UMILIATO, DIO, TU NON DISPREZZI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-             Mi-",
          text: "Nel tuo amore fa grazia a Sion,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-            Mi-",
          text: "RIALZA LE MURA DI GERUSALEMME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-                Mi-",
          text: "Allora gradirai i sacrifici prescritti,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-                  Mi-",
          text: "L'OLOCAUSTO E L'INTERA OBLAZIONE,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       La-          Mi-",
          text: "allora immoleranno vittime",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      La-        Mi-",
          text: "SOPRA IL TUO ALTARE.",
        },
      ],
    },
  ],
  subtitle: "Sal 50(51)",
};
