import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Già viene il regno.",
  url_video: "https://www.youtube.com/embed/bekUKvF8YfU",
  barrè: "",
  color: "white",
  semantic: "neocat",
  altnotes: "Do",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "     Sol        Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-            Si7",
          text: "Già viene il regno",
        },
        {
          notes: "                        Mi-",
          text: "del Signore e il suo Cristo.",
        },
        {
          notes: "               Si7",
          text: "Già viene il regno",
        },
        {
          notes: "                   Mi-",
          text: "del Signor nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "     Sol        Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        Si7",
          text: "Diamogli gloria,",
        },
        {
          notes: "                 Mi-",
          text: "sono giunte le nozze,",
        },
        {
          notes: "            Si7",
          text: "diamogli gloria,",
        },
        {
          notes: "                   Mi-",
          text: "le nozze del nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "     Sol        Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Si7",
          text: "Sono beati",
        },
        {
          notes: "                 Mi-",
          text: "gli invitati alle nozze,",
        },
        {
          notes: "       Si7",
          text: "alle nozze",
        },
        {
          notes: "      Mi-",
          text: "del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "     Sol        Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Si7",
          text: "Sono beati",
        },
        {
          notes: "                 Mi-",
          text: "gli invitati alle nozze,",
        },
        {
          notes: "       Si7",
          text: "alle nozze",
        },
        {
          notes: "      Mi-",
          text: "del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "     Sol        Si7      Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "(Ap 19, 6-9)",
};
