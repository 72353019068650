import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Dice il Signore al mio Signore.",
  url_video: "https://www.youtube.com/embed/C2rcleW_0aM",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Dice il Signore al mio Signore:",
        },
        {
          notes: "  Sol#",
          text: "“siedi alla mia destra",
        },
        {
          notes: "    La",
          text: "finché io ponga i tuoi nemici",
        },
        {
          notes: "     Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi",
          text: "Dice il Signore al mio Signore:",
        },
        {
          notes: "  Sol#",
          text: "“siedi alla mia destra",
        },
        {
          notes: "    La",
          text: "finché io ponga i tuoi nemici",
        },
        {
          notes: "     Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-                Sol#",
          text: "Lo scettro del tuo potere",
        },
        {
          notes: "Si                    Sol#",
          text: "stende il Signore da Sion:",
        },
        {
          notes: " La                        Mi",
          text: "“Domina in mezzo ai tuoi nemici,",
        },
        {
          notes: "Si7                       Mi",
          text: "domina in mezzo ai tuoi nemici.",
        },
        {
          notes: " Do#-           Sol#",
          text: "Dal seno dell'aurora",
        },
        {
          notes: "Si                        Sol#",
          text: "come rugiada io ti ho generato,",
        },
        {
          notes: "     La         Mi",
          text: "dal seno dell'aurora",
        },
        {
          notes: "        Si7               Mi",
          text: "come rugiada io ti ho generato“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi",
          text: "Dice il Signore al mio Signore:",
        },
        {
          notes: "  Sol#",
          text: "“siedi alla mia destra",
        },
        {
          notes: "    La",
          text: "finché io ponga i tuoi nemici",
        },
        {
          notes: "     Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-                           Sol#",
          text: "Il Signore ha giurato e non si pente:",
        },
        {
          notes: " La                    Mi",
          text: "“tu sei sacerdote per sempre",
        },
        {
          notes: "    Si7         Mi",
          text: "al modo di Melchisedek",
        },
        {
          notes: "    Si7         Mi",
          text: "al modo di Melchisedek“.",
        },
        {
          notes: "Do#-                   Sol#",
          text: "Il Signore è alla tua destra,",
        },
        {
          notes: "Si                      Sol#",
          text: "annienterà tutti i tuoi nemici,",
        },
        {
          notes: "La            Mi",
          text: "giudicherà i popoli,",
        },
        {
          notes: "Si7           Mi",
          text: "giudicherà i popoli.",
        },
        {
          notes: " Do#-                                    Sol#",
          text: "Perché lungo il cammino si disseta al torrente",
        },
        {
          notes: "Si                Sol#",
          text: "e solleva alta la testa",
        },
        {
          notes: "La                 Mi",
          text: "e solleva alta la testa",
        },
        {
          notes: "Si7                Mi",
          text: "e solleva alta la testa.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi",
          text: "Dice il Signore al mio Signore:",
        },
        {
          notes: "  Sol#",
          text: "“siedi alla mia destra",
        },
        {
          notes: "    La",
          text: "finché io ponga i tuoi nemici",
        },
        {
          notes: "     Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi,",
        },
        {
          notes: "     Si7              Mi",
          text: "a sgabello dei tuoi piedi“.",
        },
      ],
    },
  ],
  subtitle: "Sal 109(110)",
};
