import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo (1983).",
  url_video: "https://www.youtube.com/embed/Sm1dCrrGxkg",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "SANTO, SANTO, SANTO",
        },
        {
          notes: "Sol      La-",
          text: "È IL SIGNORE,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol          La-",
          text: "DIO DELL'UNIVERSO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa            Mi",
          text: "I cieli e la terra",
        },
        {
          notes: "     Re-               Mi",
          text: "sono pieni della tua gloria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-             Re-",
          text: "OSANNA! OSANNA! OSANNA, OSANNA!",
        },
        {
          notes: "              Mi",
          text: "NELL'ALTO DEI CIELI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Benedetto è colui che viene",
        },
        {
          notes: "    Re-         Mi",
          text: "nel nome del Signore,",
        },
        {
          notes: "    Re-         Mi",
          text: "nel nome del Signore!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-             Re-",
          text: "OSANNA! OSANNA! OSANNA, OSANNA!",
        },
        {
          notes: "              Mi",
          text: "NELL'ALTO DEI CIELI.",
        },
      ],
    },
  ],
  subtitle: "",
};
