import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Andate ed annunziate ai miei fratelli.",
  url_video: "https://www.youtube.com/embed/9SW6PKnRbGc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Andate ed annunziate ai miei fratelli",
        },
        {
          notes: "       Sol        La-",
          text: "che vadano in Galilea",
        },
        {
          notes: "Mi",
          text: "che lá mi vedranno",
        },
        {
          notes: "              La-",
          text: "che lá mi vedranno",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Re-           La-",
          text: "I discepoli andarono in Galilea",
        },
        {
          notes: "                Re-          La-",
          text: "al monte che Gesù aveva indicato.",
        },
        {
          notes: "          Mi              La-",
          text: "Quando lo videro, lo adorarono.",
        },
        {
          notes: "Fa           Mi",
          text: "E Gesù disse loro:",
        },
        {
          notes: "Do                     Sol",
          text: "Mi è stato dato ogni potere",
        },
        {
          notes: "               Do",
          text: "in cielo e in terra.",
        },
        {
          notes: "                         Sol",
          text: "Andate dunque e fate discepoli",
        },
        {
          notes: "          Do",
          text: "tutte le genti,",
        },
        {
          notes: "   La-                      Mi",
          text: "battezzandole nel nome del Padre",
        },
        {
          notes: "                            La-",
          text: "del Figlio e dello Spirito Santo,",
        },
        {
          notes: "Fa",
          text: "ed insegnando a mettere in pratica",
        },
        {
          notes: "                                Mi",
          text: "tutto quello che io vi ho comandato.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ecco io sono con voi,",
        },
        {
          notes: "                 Sol",
          text: "ecco io sono con voi",
          msg: "(L'Ass. ripete)",
        },
        {
          notes: "           La-",
          text: "tutti i giorni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Andate ed annunziate ai miei fratelli",
        },
        {
          notes: "       Sol        La-",
          text: "che vadano in Galilea",
        },
        {
          notes: "Mi",
          text: "che lá mi vedranno",
        },
        {
          notes: "              La-",
          text: "che lá mi vedranno",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ecco io sono con voi,",
        },
        {
          notes: "                 Sol",
          text: "ecco io sono con voi",
          msg: "(L'Ass. ripete)",
        },
        {
          notes: "           La-",
          text: "tutti i giorni.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                    Mi",
          text: "fino alla fine del mondo",
        },
        {
          notes: "                    La-",
          text: "fino alla fine del mondo",
        },
        {
          notes: "La-",
          text: "Andate ed annunziate ai miei fratelli",
        },
        {
          notes: "       Sol        La-",
          text: "che vadano in Galilea",
        },
        {
          notes: "Mi",
          text: "che lá mi vedranno",
        },
        {
          notes: "              La-",
          text: "che lá mi vedranno",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ecco io sono con voi,",
        },
        {
          notes: "                 Sol",
          text: "ecco io sono con voi",
          msg: "  (L'Ass. ripete)",
        },
        {
          notes: "           La-",
          text: "tutti i giorni.",
        },
      ],
    },
  ],
  subtitle: "(Mt 28, 16-20)",
};
