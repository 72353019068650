import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Abbà padre.",
  url_video: "https://www.youtube.com/embed/SrbbrNFdm0Y",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do    Sol    Fa  Do",
          text: "ABBÀ, PADRE, PADRE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Non abbiamo ricevuto",
        },
        {
          notes: "   Re-        La-",
          text: "lo spirito di servi",
        },
        {
          notes: "Fa               Mi",
          text: "per ricadere nel timore",
        },
        {
          notes: "Fa               Mi",
          text: "per ricadere nel timore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "QUESTO STESSO SPIRITO",
        },
        {
          notes: "Re-        La-",
          text: "DÀ TESTIMONIANZA",
        },
        {
          notes: "Fa        Mi",
          text: "AL NOSTRO SPIRITO",
        },
        {
          notes: "Mi7",
          text: "CHE SIAMO FIGLI DI DIO",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "    Sol7",
          text: "E SE FIGLI,",
        },
        {
          notes: " Do",
          text: "EREDI,",
        },
        {
          notes: "           Mi7",
          text: "COEREDI DI CRISTO",
        },
        {
          notes: "         La-",
          text: "EREDI DI DIO",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do    Sol    Fa  Do",
          text: "ABBÀ, PADRE, PADRE!",
        },
      ],
    },
  ],
  subtitle: "(Rom 8, 15-17)",
};
