import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Stabat mater.",
  url_video: "https://www.youtube.com/embed/xXc7OOLt91s",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "La sua anima gemente",
        },
        {
          notes: "   Do7              Sib7",
          text: "contristata e dolente",
        },
        {
          notes: "                La7",
          text: "una spada trapassò.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Oh, quanto triste e aflitta",
        },
        {
          notes: "   Do7            Sib7",
          text: "fu quella benedetta",
        },
        {
          notes: "                 La7",
          text: "madre dell'unigenito!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Qual è l'uomo che non piange",
        },
        {
          notes: "   Do7                    Sib7",
          text: "nel vedere la madre di Cristo",
        },
        {
          notes: "               La7",
          text: "in tanto supplizio!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Per i peccati della sua gente",
        },
        {
          notes: "   Do7            Sib7",
          text: "vede Gesù nei tormenti",
        },
        {
          notes: "                   La7",
          text: "e soggetto ai flagelli!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Vede il suo dolce nato",
        },
        {
          notes: "   Do7       Sib7",
          text: "morente, desolato",
        },
        {
          notes: "                    La7",
          text: "che dona il suo Spirito!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "STABAT MATER DOLOROSA",
        },
        {
          notes: "Do7               Sib7",
          text: "JUXTA CRUCEM LACRIMOSA",
        },
        {
          notes: "             La7",
          text: "DUM PENDEBAT FILIUS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Oh, Cristo, quando io debba morire,",
        },
        {
          notes: "Do7                              Sib7",
          text: "per la tua benedetta madre fammi pervenire",
        },
        {
          notes: "                     La7",
          text: "alla palma della vittoria!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-   Sib7   La7",
          text: "Amen, Amen, Amen!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re-   Sib7   La7",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
