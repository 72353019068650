import ChordGraph from "../../Components/ChordGraph/ChordGraph";
import { getSupportedSchemes } from "../../Components/ChordGraph/ChordMap";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import { printNode } from "../../Utils";
import "./GuideScreen_Chords.scss";

const GuideScreen_Chords = () => {
  let supportedSchemes: string[][] = getSupportedSchemes();

  const extrapolateNoteLabel = (rawNote: string) => {
    return rawNote.replace(/[^a-zA-Z]+/g, "");
  };

  const getSchemesBlocks = (schemes: string[][]) => {
    let baseNote = "Do";
    let results: JSX.Element[] = [];
    let schemesForNote: JSX.Element[] = [];

    for (let i = 0; i < schemes.length; i++) {
      let currentNote: string = extrapolateNoteLabel(schemes[i][0]);
      if (currentNote !== baseNote) {
        results.push(
          <TitledPageSection
            title={"Accordi per la nota " + baseNote}
            content={<div>{[...schemesForNote]}</div>}
          />
        );
        schemesForNote = [];
        baseNote = currentNote;
      }

      for (let j = 0; j < schemes[i].length; j++) {
        schemesForNote.push(
          <ChordGraph chord={schemes[i][j]} index={j} key={j} minimal={true} />
        );
      }
    }

    results.push(
      <TitledPageSection
        title={"Accordi per la nota " + baseNote}
        content={<div>{[...schemesForNote]}</div>}
      />
    );

    return results;
  };

  return (
    <PageLayout
      backButton={"/guide"}
      title={"Schemi degli accordi"}
      content={
        <div>
          In questa pagina si trovano gli schemi per tutti gli accordi noti al
          motore grafico di questo sito divisi per note.
          <br />
          <br />
          <StandardButton
            label="Stampa accordi"
            onClick={() => {
              printNode("Accordi", "chords_node_wrap");
            }}
          />
          <br />
          <br />
          <div id="chords_node_wrap">{getSchemesBlocks(supportedSchemes)}</div>
        </div>
      }
    />
  );
};

export default GuideScreen_Chords;
