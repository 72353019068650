import CloseIcon from "@mui/icons-material/Close";
import "./VideoBox.scss";

export interface VideoBoxProps {
  source: string;
  onClose: () => void;
}

const VideoBox = (props: VideoBoxProps) => {
  return (
    <div className="video-box-main-wrap box-shadow base_left_menu_background_color">
      <div className="video-box-toolbar toolbar_background_color dark_text_color">
        <div>Video YouTube</div>
        <div className="video-box-toolbar-close-icon">
          <CloseIcon onClick={props.onClose} />
        </div>
      </div>
      {props.source !== "" && (
        <iframe src={props.source} className="video-box-iframe" />
      )}
      {props.source === "" && (
        <div className="video-box-no-source">
          Link al video non disponibile.
        </div>
      )}
    </div>
  );
};

export default VideoBox;
