import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il Signore mi ha dato.",
  url_video: "https://www.youtube.com/embed/_8-QoltbHBY",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-             Do                 Si-",
          text: "Il Signore mi ha dato lingua da discepolo",
        },
        {
          notes: "                            Do",
          text: "perché possa portare allo stanco",
        },
        {
          notes: "                   Si-",
          text: "una parola di sollievo.",
        },
        {
          notes: "                 Do",
          text: "Mattina dopo mattina",
        },
        {
          notes: "                       Si-",
          text: "risvegli tu il mio orecchio",
        },
        {
          notes: "                    Do",
          text: "perché possa ascoltare",
        },
        {
          notes: "           Si-",
          text: "come un discepolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "     Re          Fa#-        Sol",
          text: "IL SIGNORE, IL SIGNORE, IL SIGNORE,",
        },
        {
          notes: "                 Fa#",
          text: "MI HA APERTO L'ORECCHIO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "Per questo io non mi sono ribellato",
        },
        {
          notes: "",
          text: "non mi sono ribellato.",
        },
        {
          notes: "   Re            Sol                Fa#",
          text: "Offrii le mie spalle a chi mi percuoteva.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                  Fa#-",
          text: "ED IO NON MI SONO RIBELLATO",
        },
        {
          notes: "",
          text: "NON MI SONO RIBELLATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re              Sol                          Fa#",
          text: "Offrii le mie guance a chi mi strappava la barba.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                  Fa#-",
          text: "ED IO NON MI SONO RIBELLATO",
        },
        {
          notes: "",
          text: "NON MI SONO RIBELLATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Re         Sol                     Fa#",
          text: "Il mio volto non schivò gli insulti né gli sputi.",
        },
        {
          notes: "   Si-          Do                     Si-",
          text: "Perché io già sapevo che tu mi avresti aiutato,",
        },
        {
          notes: "                          Do",
          text: "per questo ho reso il mio volto",
        },
        {
          notes: "              Si-",
          text: "duro come la pietra:",
        },
        {
          notes: "      Do                         Si-",
          text: "ero sicuro che tu mi avresti sostenuto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Re          Fa#-          Sol",
          text: "SE TU SEI, SE SEI VICINO, SE TU SALVI,",
        },
        {
          notes: "                   Fa#",
          text: "CHI CONTENDERÀ CON ME?",
        },
        {
          notes: " Re               Si-              Sol",
          text: "SE TU AIUTI, SE TU MI AIUTI, SE TU SALVI,",
        },
        {
          notes: "          Fa#",
          text: "CHI MI CONDANNERÀ?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                 Do",
          text: "Tutti voi che temete Dio,",
        },
        {
          notes: "                             Si-",
          text: "ascoltate la voce del suo servo:",
        },
        {
          notes: "                              Do",
          text: "se qualcuno si trova nelle tenebre",
        },
        {
          notes: "                Si-",
          text: "abbia fiducia in lui,",
        },
        {
          notes: "       Do             Si-",
          text: "e si appoggi nel suo nome.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Re          Fa#-          Sol",
          text: "SE TU SEI, SE SEI VICINO, SE TU SALVI,",
        },
        {
          notes: "                   Fa#",
          text: "CHI CONTENDERÀ CON ME?",
        },
        {
          notes: " Re               Si-              Sol",
          text: "SE TU AIUTI, SE TU MI AIUTI, SE TU SALVI,",
        },
        {
          notes: "          Fa#",
          text: "CHI MI CONDANNERÀ?",
        },
      ],
    },
  ],
  subtitle: "(Is. 50, 4-10) III CANTO DEL SERVO.",
};
