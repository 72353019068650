import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Quando dormivo.",
  url_video: "https://www.youtube.com/embed/6wlMwJ9vho0",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Quando dormivo",
        },
        {
          notes: "                   Mi",
          text: "ma il mio cuore vegliava,",
        },
        {
          notes: "                        La-",
          text: "la voce del mio diletto udii:",
        },
        {
          notes: " Re-             Mi7",
          text: "“aprimi, sorella mia,",
        },
        {
          notes: " Re-       Mi7",
          text: "aprimi colomba,",
        },
        {
          notes: "",
          text: "ché la mia testa",
        },
        {
          notes: "",
          text: "è coperta di rugiada",
        },
        {
          notes: "",
          text: "e i miei riccioli",
        },
        {
          notes: "                     La-",
          text: "del frescore della notte“.",
        },
        {
          notes: "   Do",
          text: "Mise la mano",
        },
        {
          notes: "                     Mi",
          text: "nella fessura della porta",
        },
        {
          notes: "                         La-",
          text: "e le mie viscere si commossero.",
        },
        {
          notes: "     Re-     Mi7",
          text: "Mi alzai correndo",
        },
        {
          notes: "",
          text: "e le mie mani stillarono mirra,",
        },
        {
          notes: "",
          text: "mirra fluidissima le mie dita",
        },
        {
          notes: "                        La-",
          text: "sul chiavistello della porta",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
        {
          notes: "        La-",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Aprii, aprii al mio amato",
        },
        {
          notes: "         Mi7",
          text: "ma non c'era,",
        },
        {
          notes: "              La-",
          text: "già se n'era andato.",
        },
        {
          notes: " Re-             Mi7",
          text: "E l'anima mia",
        },
        {
          notes: "",
          text: "mi venne meno",
        },
        {
          notes: "            Mi7",
          text: "per la sua fuga;",
        },
        {
          notes: "",
          text: "è coperta di rugiada",
        },
        {
          notes: "",
          text: "lo cercai",
        },
        {
          notes: "",
          text: "ma non lo trovai",
        },
        {
          notes: "",
          text: "lo chiamai, lo chiamai",
        },
        {
          notes: "           La-",
          text: "e non mi rispose.",
        },
        {
          notes: "",
          text: "M'incontraron le guardie",
        },
        {
          notes: "             Sol",
          text: "che fanno la ronda",
        },
        {
          notes: "      Fa",
          text: "mi spogliarono",
        },
        {
          notes: "",
          text: "mi percossero",
        },
        {
          notes: "                 Mi",
          text: "le guardie delle mura",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
        {
          notes: "        La-",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Sol",
          text: "Ahi! Se tu fossi mio fratello",
        },
        {
          notes: "            Fa",
          text: "ti potrei baciare",
        },
        {
          notes: "                       Mi",
          text: "senza che si scandalizzassero.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "bar-from-ast-message",
      lines: [
        {
          notes: "        La-",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
        {
          notes: "        La-",
          text: "VI SCONGIURO",
        },
        {
          notes: "                 Sol",
          text: "FIGLIE DI GERUSALEMME,",
        },
        {
          notes: "                       Fa",
          text: "SE INCONTRATE IL MIO DILETTO",
        },
        {
          notes: "                     Mi",
          text: "DITEGLI CHE MUOIO D'AMOR.",
        },
      ],
    },
    {
      speaker:
        "Mentre l'Assemblea canta l'ultimo ritornello, il cantore continua in controcanto: \"Ahi, se tu fossi mio fratello\"...",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "(Ct 5, 2 ss)",
};
