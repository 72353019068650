import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Maria, casa di benedizione.",
  url_video: "https://www.youtube.com/embed/dhZpOHk_5AQ",
  barrè: "IV",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-           La-",
          text: "MARIA, CASA DI BENEDIZIONE,",
        },
        {
          notes: "Re-                 La-",
          text: "SALUTE DEL NOSTRO SECOLO,",
        },
        {
          notes: "Mi",
          text: "DIMORA TERRESTRE DELL'UMILE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                   Mi",
          text: "Tu, come in Cana di Galilea",
        },
        {
          notes: "Re-                          Mi",
          text: "hai visto che ci mancava il vino,",
        },
        {
          notes: "Fa",
          text: "che la nostra festa non era festa,",
        },
        {
          notes: "",
          text: "che la nostra vita non era vita,",
        },
        {
          notes: "                             Mi",
          text: "perché la morte regnava su di noi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-           La-",
          text: "MARIA, CASA DI BENEDIZIONE,",
        },
        {
          notes: "Re-                 La-",
          text: "SALUTE DEL NOSTRO SECOLO,",
        },
        {
          notes: "Mi",
          text: "DIMORA TERRESTRE DELL'UMILE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                        Mi",
          text: "E tu ci hai portati al tuo Figlio",
        },
        {
          notes: "Re-",
          text: "e ci hai insegnato ad obbedire",
        },
        {
          notes: "                           Mi",
          text: "e a fare quello che ci dice lui",
        },
        {
          notes: "Fa                                       Mi",
          text: "perché trasformi la nostra acqua in vino nuovo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Fa        Sol",
          text: "VITTORIA, VITTORIA,",
        },
        {
          notes: "      Fa               Mi",
          text: "VITA ETERNA IN CRISTO RISORTO!",
        },
        {
          notes: "      La-     Sol       La-",
          text: "ALLELUJA, ALLELÙ, ALLELUJA",
        },
        {
          notes: "      La-     Sol       La- ",
          text: "ALLELUJA, ALLELÙ, ALLELUJA!",
        },
      ],
    },
  ],
  subtitle: "",
};
