import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preghiera litanica penitenziale.",
  url_video: "https://www.youtube.com/embed/PTJzV18mg4c",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                        La-",
          text: "Per i peccati commessi indurendo i nostri cuori",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7     Do       Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI! SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                        La-",
          text: "Per i peccati commessi in pubblico o in privato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-",
          text: "per imprudenza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                    La-",
          text: "per omissione e per pigrizia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-",
          text: "per ignoranza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                    La-",
          text: "con premeditazione e malizia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-",
          text: "usando violenza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                La-",
          text: "lasciandoci dominare dalle basse passioni,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "disprezzando genitori e maestri,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           La-",
          text: "nel nostro lavoro,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "volendo primeggiare sugli altri,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              La-",
          text: "con la nostra avarizia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   La-",
          text: "con l'orgoglio e l'arroganza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "insultando il nostro prossimo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7     Do       Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI! SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La-",
          text: "dicendo menzogne,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                La-",
          text: "parlando con leggerezza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "calunniando il nostro prossimo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   La-",
          text: "per mezzo della sessualità,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                       La-",
          text: "per mezzo di sguardi disonesti,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-      La-",
          text: "con l'invidia,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              La-",
          text: "per mezzo della gola,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-               La-",
          text: "essendo irresponsabili,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         La-",
          text: "seminando zizzania,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                    La-",
          text: "mormorando nel nostro cuore,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "contristando lo Spirito Santo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "resistendo alla volontà di Dio,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-        Si7     Do       Si7",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI! SIGNORE, PIETÀ!",
        },
      ],
    },
  ],
  subtitle: "",
};
