import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Come stilla il miele.",
  url_video: "https://www.youtube.com/embed/zW5SCbKeNmk",
  barrè: "IV",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                 Re-",
          text: "COME STILLA IL MIELE DAL FAVO DELLE API",
        },
        {
          notes: "Mi                                             La-",
          text: "E FLUISCE IL LATTE DALLA DONNA CHE AMA I SUOI BIMBI,",
        },
        {
          notes: "                                        Re-",
          text: "COME EFFONDE LA SORGENTE LE TRANQUILLE ACQUE",
        },
        {
          notes: "Mi                               La-",
          text: "COSÌ IL MIO CUORE EFFONDE LA SUA LODE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Re-                                 Mi",
          text: "E le mie labbra fanno scaturire una lode per lui",
        },
        {
          notes: "         Re-                                         Mi",
          text: "e la mia lingua è piena della dolcezza del suo nome",
        },
        {
          notes: "         Re-                          Mi",
          text: "e le mie membra si saziano con i suoi canti.",
        },
        {
          notes: "              Fa                    Mi",
          text: "Poiché la sua luce pacifica il mio male,",
        },
        {
          notes: "                Fa",
          text: "perché il suo sguardo semplice d'amore",
        },
        {
          notes: "                 Mi",
          text: "guarisce il mio cuore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                  Fa",
          text: "PERCHÉ LA VITA IMMORTALE",
        },
        {
          notes: "                          Mi",
          text: "HA PRESO DIMORA DENTRO DI NOI.",
        },
        {
          notes: "                         Fa               Mi",
          text: "ALLELUJA, ALLELUJA, ALLELÙ, ALLELÙ, ALLELUJA,",
        },
        {
          notes: "                         Fa               Mi",
          text: "ALLELUJA, ALLELUJA, ALLELÙ, ALLELÙ, ALLELUJA.",
        },
        {
          notes: " La-                                 Re-",
          text: "COME STILLA IL MIELE DAL FAVO DELLE API",
        },
        {
          notes: "Mi                                             La-",
          text: "E FLUISCE IL LATTE DALLA DONNA CHE AMA I SUOI BIMBI,",
        },
        {
          notes: "                                        Re-",
          text: "COME EFFONDE LA SORGENTE LE TRANQUILLE ACQUE",
        },
        {
          notes: "Mi                               La-",
          text: "COSÌ IL MIO CUORE EFFONDE LA SUA LODE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Re-                        Mi",
          text: "E si allieta il mio viso nella sua gioia",
        },
        {
          notes: "          Re-                     Mi",
          text: "e il mio spirito gioisce nel suo amore",
        },
        {
          notes: "           Re-",
          text: "e il mio cuore pieno della sua luce serena",
        },
        {
          notes: "                       Mi",
          text: "sente il suo sguardo amoroso.",
        },
        {
          notes: "           Fa                Mi",
          text: "Perché il timore in lui si affida",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Fa                Mi",
          text: "PERCHÉ IL TIMORE IN LUI SI AFFIDA,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           Fa                  Mi",
          text: "perché la salvezza in lui è sicura",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           Fa                  Mi",
          text: "PERCHÉ LA SALVEZZA IN LUI É SICURA",
        },
        {
          notes: "                  Fa",
          text: "PERCHÉ LA VITA IMMORTALE",
        },
        {
          notes: "                          Mi",
          text: "HA PRESO DIMORA DENTRO DI NOI.",
        },
        {
          notes: "                         Fa               Mi",
          text: "ALLELUJA, ALLELUJA, ALLELÙ, ALLELÙ, ALLELUJA,",
        },
        {
          notes: "                         Fa               Mi",
          text: "ALLELUJA, ALLELUJA, ALLELÙ, ALLELÙ, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "(Ode XL di Salomone)",
};
