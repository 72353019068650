import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Grazie a Jahvè.",
  url_video: "https://www.youtube.com/embed/g7OsB04RI0M",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Rendiamo grazie al nostro Dio,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Solo lui fa meraviglie.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Ha creato i cieli con sapienza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Rende ferma la terra sulle acque.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Egli ha fatto i grandi luminari,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Sole e luna per governare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Ha colpito l'Egitto nei suoi figli,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "E ha fatto uscire Israele.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Ha diviso il mare in due parti,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol    Si-         Mi-",
          text: "Ha fatto passare in mezzo il suo popolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Ha sommerso l'esercito nemico,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Ha condotto Israele nel deserto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Ci ha donato secondo la promessa,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Una terra stillante latte e miele.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Nella sconfitta non ci abbandona,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "E ci libera dai nostri oppressori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi- Do     Re             Sol",
          text: "Dona il cibo ad ogni creatura,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Do    Re        Sol",
          text: "PERCHÉ ETERNO È IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Si-       Mi-",
          text: "Rendiamo grazie al nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "Mi-    Re  Mi-   Re   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  La-   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
        {
          notes: "La-        Mi-  Si7   Mi-",
          text: "GRAZIE A JAHVÈ, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "Sal 135(136)",
};
