import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Quando il Signore.",
  url_video: "https://www.youtube.com/embed/RjdPOmV1NgQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Quando il Signore",
        },
        {
          notes: "       Mi-",
          text: "fece tornare",
        },
        {
          notes: "         Re",
          text: "i prigionieri",
        },
        {
          notes: "   Mi-",
          text: "di Sion",
        },
        {
          notes: "                Re",
          text: "ci pareva di sognare.",
        },
        {
          notes: "    Mi-",
          text: "bocca",
        },
        {
          notes: "             Re",
          text: "era piena di risa",
        },
        {
          notes: "   Mi-",
          text: "la lingua",
        },
        {
          notes: "      Re     (Re7)",
          text: "di canzoni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Sol",
          text: "PERCHÉ ALL'ANDAR",
        },
        {
          notes: "          La         Re",
          text: "SI VA PIANGENDO",
        },
        {
          notes: "   Mi-",
          text: "GETTANDO",
        },
        {
          notes: "   Re",
          text: "IL SEME.",
        },
        {
          notes: "        Sol",
          text: "MA AL RITORNO",
        },
        {
          notes: "            La       Re",
          text: "SI VIENE CANTANDO",
        },
        {
          notes: "   Mi-",
          text: "PORTANDO",
        },
        {
          notes: "    Re",
          text: "I COVONI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Grandi cose ha fatto;",
        },
        {
          notes: "    Mi-",
          text: "meraviglie",
        },
        {
          notes: "                        Re",
          text: "ha fatto il Signore per noi;",
        },
        {
          notes: "    Mi-",
          text: "per questo",
        },
        {
          notes: "            Re",
          text: "siamo nella gioia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Sol",
          text: "PERCHÉ ALL'ANDAR",
        },
        {
          notes: "          La         Re",
          text: "SI VA PIANGENDO",
        },
        {
          notes: "   Mi-",
          text: "GETTANDO",
        },
        {
          notes: "   Re",
          text: "IL SEME.",
        },
        {
          notes: "        Sol",
          text: "MA AL RITORNO",
        },
        {
          notes: "            La       Re",
          text: "SI VIENE CANTANDO",
        },
        {
          notes: "   Mi-",
          text: "PORTANDO",
        },
        {
          notes: "    Re",
          text: "I COVONI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Quelli che seminano",
        },
        {
          notes: " Mi-",
          text: "sono",
        },
        {
          notes: "          Re",
          text: "nella tristezza",
        },
        {
          notes: "          Mi-",
          text: "ma quando mietono",
        },
        {
          notes: "                Re",
          text: "danno grida di gioia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Sol",
          text: "PERCHÉ ALL'ANDAR",
        },
        {
          notes: "          La         Re",
          text: "SI VA PIANGENDO",
        },
        {
          notes: "   Mi-",
          text: "GETTANDO",
        },
        {
          notes: "   Re",
          text: "IL SEME.",
        },
        {
          notes: "        Sol",
          text: "MA AL RITORNO",
        },
        {
          notes: "            La       Re",
          text: "SI VIENE CANTANDO",
        },
        {
          notes: "   Mi-",
          text: "PORTANDO",
        },
        {
          notes: "    Re",
          text: "I COVONI",
        },
      ],
    },
  ],
  subtitle: "Sal 125(126)",
};
