import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto dei tre giovani nella fornace. (I parte)",
  url_video: "https://www.youtube.com/embed/PRDQxzhjtso",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol        Fa#-   Mi",
          text: "Benedetto sei tu Signore,",
        },
        {
          notes: "Sol     La    Si",
          text: "Dio dei padri nostri.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi-      Re        Mi-",
          text: "DEGNO DI LODE E DI GLORIA,",
        },
        {
          notes: "Re       Do         Si7       Mi-",
          text: "DEGNO DI GLORIA NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol        Fa#-   Mi",
          text: "Benedetto sia il tuo nome,",
        },
        {
          notes: "Sol         La         Si",
          text: "il tuo nome glorioso e santo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi-      Re        Mi-",
          text: "DEGNO DI LODE E DI GLORIA,",
        },
        {
          notes: "Re       Do         Si7       Mi-",
          text: "DEGNO DI GLORIA NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol        Fa#-   Mi",
          text: "Benedetto sei tu Signore,",
        },
        {
          notes: "Sol       La      Si",
          text: "sul trono del tuo regno.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi-      Re        Mi-",
          text: "DEGNO DI LODE E DI GLORIA,",
        },
        {
          notes: "Re       Do         Si7       Mi-",
          text: "DEGNO DI GLORIA NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol                 Fa#-                    Mi",
          text: "Benedetto sei tu che penetri con lo sguardo gli abissi,",
        },
        {
          notes: "Sol       La      Si",
          text: "e siedi sui cherubini.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi-      Re        Mi-",
          text: "DEGNO DI LODE E DI GLORIA,",
        },
        {
          notes: "Re       Do         Si7       Mi-",
          text: "DEGNO DI GLORIA NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol        Fa#-   Mi",
          text: "Benedetto sei tu Signore,",
        },
        {
          notes: "Sol       La    Si",
          text: "nel firmamento del cielo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi-      Re        Mi-",
          text: "DEGNO DI LODE E DI GLORIA,",
        },
        {
          notes: "Re       Do         Si7       Mi-",
          text: "DEGNO DI GLORIA NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol   Fa#-      Mi",
          text: "Opere tutte del Signore,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " Sol     La   Si",
          text: "BENEDITE IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-            Re          Mi-",
          text: "Lodatelo ed esaltatelo nei secoli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Re              Do        Si7",
          text: "LODATELO ED ESALTATELO NEI SECOLI!",
        },
      ],
    },
    {
      speaker: "  (Recitato, non cantato.)",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Benedetto sei tu, Signore, nel firmamento del cielo!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "DEGNO DI LODE E DI GLORIA NEI SECOLI!",
        },
      ],
    },
  ],
  subtitle: "(Dn 3, 52-57)",
};
