import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "La mietitura delle nazioni.",
  url_video: "https://www.youtube.com/embed/r-CvCU4xeWw",
  barrè: "IV",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "Arrivarono i discepoli e gli dissero:",
        },
        {
          notes: "  Mi-     Fa#     Mi-    Fa#",
          text: "“Rabbì, mangia, Rabbì, mangia!“",
        },
        {
          notes: "  Sol                                                   Fa#",
          text: "E lui rispose: “Ho da mangiare un cibo che voi non conoscete.",
        },
        {
          notes: "    Sol                                          Fa#",
          text: "Mio cibo è fare la volontà di colui che mi ha mandato",
        },
        {
          notes: "  Mi-            Fa#",
          text: "e compiere la sua opera“.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "       Si- Fa#",
          text: "NON DITE VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
        {
          notes: "    La Sol Fa#",
          text: "NON  DITE  VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Ebbene, io vi dico:",
        },
        {
          notes: "  La                                 Sol",
          text: "“Levate i vostri occhi e guardate i campi",
        },
        {
          notes: "                             Fa#",
          text: "che già biondeggiano per la mietitura!“",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "       Si- Fa#",
          text: "NON DITE VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
        {
          notes: "    La Sol Fa#",
          text: "NON  DITE  VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                  Fa#",
          text: "Già si radunano i mietitori",
        },
        {
          notes: "        Sol                                         Fa#",
          text: "e ricevono salario e raccolgono frutto per la vita eterna",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Sol          Fa#",
          text: "PER LA VITA ETERNA!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "       Si- Fa#",
          text: "NON DITE VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
        {
          notes: "    La Sol Fa#",
          text: "NON  DITE  VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                          Sol                     Fa#",
          text: "Io vi ho mandati a mietere ciò per cui non avete faticato,",
        },
        {
          notes: "Sol                                                    Fa#",
          text: "altri hanno faticato e voi siete subentrati nel loro lavoro",
        },
        {
          notes: " Mi-                                          Fa#",
          text: "perché si rallegrino insieme chi semina e chi miete.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "       Si- Fa#",
          text: "NON DITE VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
        {
          notes: "    La Sol Fa#",
          text: "NON  DITE  VOI:",
        },
        {
          notes: "                                     Si-",
          text: "“ANCORA QUATTRO MESI E SARÀ LA MIETITURA“?",
        },
      ],
    },
  ],
  subtitle: "(Gv 4, 31-38) ",
};
