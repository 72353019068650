import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Camminerò.",
  title: "Camminerò.",
  url_video: "",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        Re  Si-  Sol  La",
          text: " ",
        },
        {
          notes: "        Re  Sol  La",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Si-",
          text: "Camminerò, camminerò",
        },
        {
          notes: "Sol                  La",
          text: "per la Tua strada, Signor",
        },
        {
          notes: "Re             Si-",
          text: "dammi la mano, voglio restar",
        },
        {
          notes: "    Mi-      La7     Re",
          text: "per sempre insieme a Te.",
        },
        {
          notes: "Re                      Si-",
          text: "Quando ero solo, solo e stanco nel mondo",
        },
        {
          notes: "Sol                 La",
          text: "quando non c'era l'amor",
        },
        {
          notes: "Re                 Si-",
          text: "tante persone vidi intorno a me",
        },
        {
          notes: "   Mi-     La7    Re",
          text: "sentivo cantare così.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Si-",
          text: "Camminerò, camminerò",
        },
        {
          notes: "Sol                  La",
          text: "per la Tua strada, Signor",
        },
        {
          notes: "Re             Si-",
          text: "dammi la mano, voglio restar",
        },
        {
          notes: "    Mi-      La7     Re",
          text: "per sempre insieme a Te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                  Si-",
          text: "Io non capivo, ma rimasi a sentire",
        },
        {
          notes: "Sol                 La      ",
          text: "quando io vidi il Signor",
        },
        {
          notes: "Re                   Si-",
          text: "lui mi chiamava, chiamava anche me",
        },
        {
          notes: "     Mi-    La7        Re",
          text: "e la mia risposta si alzò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Si-",
          text: "Camminerò, camminerò",
        },
        {
          notes: "Sol                  La",
          text: "per la Tua strada, Signor",
        },
        {
          notes: "Re             Si-",
          text: "dammi la mano, voglio restar",
        },
        {
          notes: "    Mi-      La7     Re",
          text: "per sempre insieme a Te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                      Si-",
          text: "Or non m'importa se uno ride di me",
        },
        {
          notes: "Sol                La",
          text: "lui certamente non sa",
        },
        {
          notes: "Re                    Si-",
          text: "che gran tesoro io trovai in quel dì",
        },
        {
          notes: "    Mi-         La7    Re",
          text: "che dissi al Signore così.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Si-",
          text: "Camminerò, camminerò",
        },
        {
          notes: "Sol                  La",
          text: "per la Tua strada, Signor",
        },
        {
          notes: "Re             Si-",
          text: "dammi la mano, voglio restar",
        },
        {
          notes: "    Mi-      La7     Re",
          text: "per sempre insieme a Te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Re                  Si-",
          text: "A volte son triste ma mi guardo intorno",
        },
        {
          notes: "Sol                  La",
          text: "scopro il mondo e l'amor",
        },
        {
          notes: "Re                    Si-",
          text: "son questi i doni che Lui fa a me,",
        },
        {
          notes: "  Mi-    La7        Re",
          text: "felice ritorno a cantar.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Si-",
          text: "Camminerò, camminerò",
        },
        {
          notes: "Sol                  La",
          text: "per la Tua strada, Signor",
        },
        {
          notes: "Re             Si-",
          text: "dammi la mano, voglio restar",
        },
        {
          notes: "    Mi-      La7     Re",
          text: "per sempre insieme a Te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re  Fa#-  Sol  La  Re",
          text: "",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
