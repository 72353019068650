import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Una donna vestita di sole.",
  url_video: "https://www.youtube.com/embed/uOBUscq2jvQ",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                Sol",
          text: "NEL CIELO APPARVE POI UN SEGNO GRANDIOSO",
        },
        {
          notes: "                     La-",
          text: "UNA DONNA VESTITA DI SOLE,",
        },
        {
          notes: "",
          text: "CON LA LUNA SOTTO I SUOI PIEDI",
        },
        {
          notes: "                   Sol",
          text: "E SUL SUO CAPO UNA CORONA DI DODICI STELLE,",
        },
        {
          notes: "             Fa                                  Mi",
          text: "È INCINTA E GRIDA PER LE DOGLIE E I TRAVAGLI DEL PARTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Sol",
          text: "Allora apparve un altro segno nel cielo,",
        },
        {
          notes: "           Fa                                 Mi",
          text: "un enorme drago rosso con sette testa e dieci corna.",
        },
        {
          notes: "    La-",
          text: "Il drago si pose davanti alla donna",
        },
        {
          notes: "                  Sol",
          text: "che stava per partorire,",
        },
        {
          notes: "                   Fa           Mi",
          text: "per divorare il bambino appena nato.",
        },
        {
          notes: "La-",
          text: "E la donna partorì un figlio maschio",
        },
        {
          notes: "              Sol",
          text: "destinato a governare le nazioni della terra;",
        },
        {
          notes: "     Fa                                               Mi",
          text: "e il figlio fu subito rapito verso Dio e verso il suo trono.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                Sol",
          text: "NEL CIELO APPARVE POI UN SEGNO GRANDIOSO",
        },
        {
          notes: "                     La-",
          text: "UNA DONNA VESTITA DI SOLE,",
        },
        {
          notes: "",
          text: "CON LA LUNA SOTTO I SUOI PIEDI",
        },
        {
          notes: "                   Sol",
          text: "E SUL SUO CAPO UNA CORONA DI DODICI STELLE,",
        },
        {
          notes: "             Fa                                  Mi",
          text: "È INCINTA E GRIDA PER LE DOGLIE E I TRAVAGLI DEL PARTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol         Fa       La-",
          text: "E scoppiò una guerra nel cielo:",
        },
        {
          notes: "                  Sol                           Fa",
          text: "Michele e i suoi angeli combattevano contro il drago,",
        },
        {
          notes: "                       Mi",
          text: "combattevano contro il drago;",
        },
        {
          notes: "     La-                                 Sol",
          text: "e il drago combatteva insieme con i suoi angeli,",
        },
        {
          notes: "          Fa",
          text: "ma non prevalsero",
        },
        {
          notes: "                                   Mi",
          text: "e non ci fu più posto per essi in cielo.",
        },
        {
          notes: "    La-",
          text: "Il grande drago, il serpente antico,",
        },
        {
          notes: "                         Sol",
          text: "colui che chiamiamo il diavolo e satana,",
        },
        {
          notes: "",
          text: "il seduttore di tutta la terra,",
        },
        {
          notes: " Fa                                          Mi",
          text: "fu precipitato sulla terra e i suoi angeli con lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                Sol",
          text: "NEL CIELO APPARVE POI UN SEGNO GRANDIOSO",
        },
        {
          notes: "                     La-",
          text: "UNA DONNA VESTITA DI SOLE,",
        },
        {
          notes: "",
          text: "CON LA LUNA SOTTO I SUOI PIEDI",
        },
        {
          notes: "                   Sol",
          text: "E SUL SUO CAPO UNA CORONA DI DODICI STELLE,",
        },
        {
          notes: "             Fa                                  Mi",
          text: "È INCINTA E GRIDA PER LE DOGLIE E I TRAVAGLI DEL PARTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Sol",
          text: "Ora, quando il drago si vide precipitato sulla terra,",
        },
        {
          notes: "     Fa",
          text: "si avventò contro la donna",
        },
        {
          notes: "                               Mi",
          text: "che aveva partorito il figlio maschio.",
        },
        {
          notes: " La-",
          text: "Ma furono date alla donna",
        },
        {
          notes: "                         Sol",
          text: "le due ali dell'aquila grande per volare nel deserto,",
        },
        {
          notes: "     Fa",
          text: "per essere nutrita un tempo,",
        },
        {
          notes: "                           Mi",
          text: "due tempi e la metà di un tempo.",
        },
        {
          notes: " La-                  Sol",
          text: "Allora il drago si infuriò contro la donna",
        },
        {
          notes: "                    Fa                             Mi",
          text: "e se ne andò a far guerra contro il resto dei suoi figli;",
        },
        {
          notes: " La-                            Sol           Fa",
          text: "contro quelli che osservano i comandamenti di Dio",
        },
        {
          notes: "                                           Mi",
          text: "e sono in possesso della testimonianza di Gesù.",
        },
        {
          notes: " La-                                         Sol",
          text: "Esultate dunque o cieli e voi che abitate in essi",
        },
        {
          notes: "",
          text: "perché è stato precipitato",
        },
        {
          notes: "      Fa                   Mi",
          text: "l'accusatore dei nostri fratelli.",
        },
        {
          notes: "   La-",
          text: "Ma guai a voi terra e mare",
        },
        {
          notes: "                         Sol",
          text: "perché il diavolo è precipitato sopra di voi",
        },
        {
          notes: "   Fa                                         Mi",
          text: "è pieno di furore sapendo che gli resta poco tempo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                Sol",
          text: "NEL CIELO APPARVE POI UN SEGNO GRANDIOSO",
        },
        {
          notes: "                     La-",
          text: "UNA DONNA VESTITA DI SOLE,",
        },
        {
          notes: "",
          text: "CON LA LUNA SOTTO I SUOI PIEDI",
        },
        {
          notes: "                   Sol",
          text: "E SUL SUO CAPO UNA CORONA DI DODICI STELLE,",
        },
        {
          notes: "             Fa                                  Mi",
          text: "È INCINTA E GRIDA PER LE DOGLIE E I TRAVAGLI DEL PARTO.",
        },
      ],
    },
  ],
  subtitle: "(Ap 12)",
};
