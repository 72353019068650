import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Se il Signore non costruisce la casa.",
  url_video: "https://www.youtube.com/embed/NcMxgzXGc-E",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      Si-      Sol",
          text: "SE IL SIGNORE NON COSTRUISCE LA CASA,",
        },
        {
          notes: "  La                     Re",
          text: "INVANO LAVORANO I COSTRUTTORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Si-                             Fa#",
          text: "Se il Signore non custodisce la città,",
        },
        {
          notes: "                        Si-     Sol",
          text: "invano veglia la sentinella,",
        },
        {
          notes: "                                      Fa#",
          text: "se il Signore non custodisce la città.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      Si-      Sol",
          text: "SE IL SIGNORE NON COSTRUISCE LA CASA,",
        },
        {
          notes: "  La                     Re",
          text: "INVANO LAVORANO I COSTRUTTORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                          Fa#",
          text: "Invano vi alzate presto al mattino,",
        },
        {
          notes: "",
          text: "andate tardi a riposare",
        },
        {
          notes: "                  Si-    Sol",
          text: "per procurarvi il pane.",
        },
        {
          notes: "                              Fa#",
          text: "Dio lo dona ai suoi amici nel sonno.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "         Sol                    Fa#",
          text: "DIO LO DONA AI SUOI AMICI NEL SONNO.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      Si-      Sol",
          text: "SE IL SIGNORE NON COSTRUISCE LA CASA,",
        },
        {
          notes: "  La                     Re",
          text: "INVANO LAVORANO I COSTRUTTORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Si-                    Fa#",
          text: "Dono del Signore sono i figli,",
        },
        {
          notes: "",
          text: "sono frecce in mano a un guerriero",
        },
        {
          notes: "                   Si-     Sol",
          text: "i figli della giovinezza.",
        },
        {
          notes: "                                Fa#    Sol",
          text: "Beato l'uomo che ne ha piena la faretra:",
        },
        {
          notes: "                                         Fa#",
          text: "non tremerà in faccia ai suoi nemici!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      Si-      Sol",
          text: "SE IL SIGNORE NON COSTRUISCE LA CASA,",
        },
        {
          notes: "  La                     Re",
          text: "INVANO LAVORANO I COSTRUTTORI",
        },
      ],
    },
  ],
  subtitle: "Sal 126(127) ",
};
