import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "È paziente",
  url_video: "https://www.youtube.com/embed/S5tukIvbf1o",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Lo Spirito Santo",
        },
        {
          notes: "              Mi",
          text: "è il giogo soave,",
          msg: "  L'Ass. ripete",
        },
        {
          notes: "                       La-",
          text: "è il giogo soave e leggero.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re-            Mi",
          text: "Pieno di comprensione,",
        },
        {
          notes: "Re-                                  Mi",
          text: "pieno di misericordia con i nostri peccati,",
        },
        {
          notes: "Fa",
          text: "pieno di tenerezza e compassione,",
        },
        {
          notes: "                   Mi",
          text: "di amore senza limiti.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-",
          text: "È paziente,",
        },
        {
          notes: "",
          text: "è benigno,",
        },
        {
          notes: "            Mi",
          text: "è il sommo Bene,",
        },
        {
          notes: "              La-",
          text: "è il dono di Dio,",
        },
        {
          notes: "Re-                       Mi",
          text: "è la garanzia della Vita Eterna,",
        },
        {
          notes: "Re-        Mi",
          text: "Lui il «Paraclito».",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Abitando nell’uomo",
        },
        {
          notes: "            Mi",
          text: "ci perdona sempre,",
        },
        {
          notes: "",
          text: "abitando nell’uomo",
        },
        {
          notes: "      La-",
          text: "spera sempre,",
        },
        {
          notes: "Re-",
          text: "Comprende tutto,",
        },
        {
          notes: "      Mi",
          text: "scusa tutto.",
        },
        {
          notes: "Re-",
          text: "Ci difende sempre",
        },
        {
          notes: "                         Fa",
          text: "e ci insegna ad essere pazienti",
        },
        {
          notes: "                 Mi",
          text: "con i nostri peccati.",
        },
        {
          notes: "Re-           Mi",
          text: "Ci dice chi siamo,",
        },
        {
          notes: "Re-            Mi",
          text: "ci dice dove andiamo,",
        },
        {
          notes: "Fa                             Mi",
          text: "qual è il cammino e perché soffriamo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-",
          text: "È paziente,",
        },
        {
          notes: "",
          text: "è benigno,",
        },
        {
          notes: "            Mi",
          text: "è il sommo Bene,",
        },
        {
          notes: "              La-",
          text: "è il dono di Dio,",
        },
        {
          notes: "Re-                       Mi",
          text: "è la garanzia della Vita Eterna,",
        },
        {
          notes: "Re-        Mi",
          text: "Lui il «Paraclito».",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                     Mi",
          text: "Ci dice che nella nostra vita tutto è santo,",
        },
        {
          notes: "                        La-",
          text: "che la nostra storia è santa,",
        },
        {
          notes: "Re-",
          text: "e ci conduce",
        },
        {
          notes: "                         Mi",
          text: "all’abbandono totale in Cristo.",
        },
        {
          notes: "  Re-                 Mi",
          text: "In Lui, nulla si pretende,",
        },
        {
          notes: "           Fa",
          text: "si accetta tutto,",
        },
        {
          notes: "             Mi",
          text: "si sopporta tutto,",
        },
        {
          notes: "Re-                                  Mi",
          text: "Perché assomigliare al Signore sulla Croce",
        },
        {
          notes: "Re-",
          text: "è la nostra gloria,",
        },
        {
          notes: "                    (Fa)",
          text: "è la verità, è la santità,",
        },
        {
          notes: "                        Mi",
          text: "è il nostro essere cristiano.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-",
          text: "È paziente,",
        },
        {
          notes: "",
          text: "è benigno,",
        },
        {
          notes: "            Mi",
          text: "è il sommo Bene,",
        },
        {
          notes: "              La-",
          text: "è il dono di Dio,",
        },
        {
          notes: "Re-                       Mi",
          text: "è la garanzia della Vita Eterna,",
        },
        {
          notes: "Re-        Mi",
          text: "Lui il «Paraclito».",
        },
      ],
    },
  ],
  subtitle: "(inno allo spirito santo)",
};
