import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Amate i vostri nemici.",
  url_video: "https://www.youtube.com/embed/G-_ib51Dpr0",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Alzati gli occhi verso i suoi discepoli,",
        },
        {
          notes: "Fa        Do",
          text: "Gesù diceva:",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Do      Fa  Do",
          text: "Beati voi poveri,",
        },
        {
          notes: "Sol                     Do",
          text: "perché vostro è il regno di Dio.",
        },
        {
          notes: "Do                    Fa Do",
          text: "Beati voi che ora avete fame,",
        },
        {
          notes: "Sol            Do",
          text: "perché sarete saziati.",
        },
        {
          notes: "Do                Fa  Do",
          text: "Beati voi che ora piangete,",
        },
        {
          notes: "Sol      Do",
          text: "perché riderete.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "    La-           Sol",
          text: "Beati voi quando vi odieranno e vi",
        },
        {
          notes: "       Fa                      Mi",
          text: "insulteranno a causa del Figlio dell’Uomo;",
        },
        {
          notes: "     Fa",
          text: "rallegratevi in quel giorno, la vostra",
        },
        {
          notes: "                     Mi",
          text: "ricompensa è grande nei cieli.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Do          Fa  Do",
          text: "Ma guai a voi, ricchi,",
        },
        {
          notes: "Sol                           Do",
          text: "perché avete già la vostra consolazione.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Do                   Fa Do",
          text: "Guai a voi che ora siete sazi,",
        },
        {
          notes: " Sol         Do",
          text: "perché avrete fame.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Do               Fa Do",
          text: "Guai a voi che ora ridete,",
        },
        {
          notes: "Sol                         Mi",
          text: "perché sarete afflitti e piangerete.",
          msg: " (L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "     La-                      Sol",
          text: "Guai a voi quando tutti gli uomini",
        },
        {
          notes: "             Fa",
          text: "parlano bene di voi,",
        },
        {
          notes: "                            Mi",
          text: "così hanno fatto con i falsi profeti.",
        },
        {
          notes: "Re",
          text: "Ma a voi che ascoltate dico:",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re           Sol Re",
          text: "Amate i vostri nemici,",
        },
        {
          notes: "La                         Re",
          text: "fate del bene a coloro che vi odiano.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Re                      Sol  Re",
          text: "Benedite coloro che vi maledicono,",
        },
        {
          notes: "La                        Re",
          text: "pregate per coloro che vi maltrattano.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Re                  Sol  Re",
          text: "A chi ti perquote sulla guancia,",
        },
        {
          notes: "La            Re",
          text: "porgigli anche l’altra.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Re               Sol  Re",
          text: "A chi ti leva il mantello,",
        },
        {
          notes: "La               Re",
          text: "non rifiutare la tunica.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Mi              La Mi",
          text: "Da a chiunque ti chiede",
        },
        {
          notes: "Si7                             Mi",
          text: "e a chi ti prende del tuo non richiederlo.",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Mi     La Mi",
          text: "Non giudicate,",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Mi     La Mi",
          text: "non condannate,",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Mi     La Mi",
          text: "non condannate,",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Mi    La  Mi",
          text: "perdonate,",
          msg: " (L'Ass. ripete)",
        },
        {
          notes: "Si7         Mi",
          text: "e vi sarà perdonato.",
          msg: " (L'Ass. ripete)",
        },
      ],
    },
  ],
  subtitle: "(Lc 6,17-49) ",
};
