import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Signore ascolta la mia preghiera.",
  url_video: "https://www.youtube.com/embed/j95KGTl2kkA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Re    Do-",
          text: "Signore ascolta la mia preghiera,",
        },
        {
          notes: "Re           Do-                Re",
          text: "porgi l’orecchio alla mia supplica.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-         Do-   Sol-         Do-       Re7",
          text: "Tu che sei fedele, tu che sei fedele, rispondimi ",
        },
        {
          notes: "Do-                    Re",
          text: "per la tua giustizia rispondimi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                             Re7",
          text: "Non chiamare in giudizio il tuo servo:",
        },
        {
          notes: "                                 Sol-",
          text: "nessun vivente è giusto davanti a te.",
        },
        {
          notes: "                Re7",
          text: "Il nemico mi perseguita,",
        },
        {
          notes: "Do-                     Re7",
          text: "calpesta a terra la mia vita.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-          Do-  Sol-         Do-       Re7",
          text: "Tu che sei fedele, tu che sei fedele, rispondimi",
        },
        {
          notes: "Do-                     Re",
          text: "per la tua giustizia rispondimi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol-                Re7",
          text: "A te protendo le mie mani",
        },
        {
          notes: "                           Sol",
          text: "Sono davanti a te come terra riarsa",
        },
        {
          notes: "                   Re7",
          text: "Rispondimi presto Signore,",
        },
        {
          notes: "Do-                 Re",
          text: "Viene meno il mio spirito.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-          Do-  Sol-         Do-       Re7",
          text: "Tu che sei fedele, tu che sei fedele, rispondimi",
        },
        {
          notes: "Do-                     Re",
          text: "per la tua giustizia rispondimi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                  Re7",
          text: "Fammi conoscere il cammino",
        },
        {
          notes: "                  Sol-",
          text: "le strade da percorrere.",
        },
        {
          notes: "                   Re7",
          text: "Salvami dai miei nemici,",
        },
        {
          notes: "Do-               Re7",
          text: "Signore a te mi affido.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-         Do-  Sol-            Do-       Re7",
          text: "Tu che sei fedele, tu che sei fedele, rispondimi",
        },
        {
          notes: "Do-                     Re",
          text: "per la tua giustizia rispondimi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                          Re7",
          text: "Insegnami a compiere il tuo volere,",
        },
        {
          notes: "                     Sol-",
          text: "perché sei tu il mio Dio.",
        },
        {
          notes: "                         Re7",
          text: "Il tuo Spirito buono mi guidi,",
        },
        {
          notes: "Do-                Re7",
          text: "mi guidi in terra piana",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-         Do-  Sol-          Do-       Re7",
          text: "Tu che sei fedele, tu che sei fedele, rispondimi",
        },
        {
          notes: "Do-                     Re",
          text: "per la tua giustizia rispondimi.",
        },
      ],
    },
  ],
  subtitle: "salmo 143(142)",
};
