import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Perchè le genti congiurano?",
  url_video: "https://www.youtube.com/embed/iaHfp8xz1Rk",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi-                La-",
          text: "Perché le genti congiurano,",
        },
        {
          notes: "                            Mi-",
          text: "perché invano cospirano i popoli?",
        },
        {
          notes: "                       La-",
          text: "Insorgono i re della terra",
        },
        {
          notes: "                           Si7",
          text: "e i principi congiurano insieme",
        },
        {
          notes: "                                     Mi",
          text: "contro il Signore e contro il suo messia:",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "“Su spezziamo le loro catene,",
        },
        {
          notes: "                      La-",
          text: "gettiamo via i loro legami“.",
        },
        {
          notes: "                          Si7",
          text: "Se ne ride chi abita i cieli,",
        },
        {
          notes: "",
          text: "li schernisce dall'alto il Signore.",
        },
        {
          notes: "",
          text: "Egli parla loro con ira,",
        },
        {
          notes: "                      Mi-",
          text: "li spaventa nel suo sdegno:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Mi-",
          text: "“IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
        {
          notes: " La-                      Mi-",
          text: "“IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                          La-",
          text: "Annunzierò il decreto del Signore.",
        },
        {
          notes: "            Si7",
          text: "Egli mi ha detto: “tu sei mio figlio,",
        },
        {
          notes: "                 Mi-",
          text: "Io oggi ti ho generato.",
        },
        {
          notes: "                                    La-",
          text: "Chiedi a me, ti darò in possesso le genti",
        },
        {
          notes: "                               Si7",
          text: "e in dominio i confini della terra.",
        },
        {
          notes: "",
          text: "Le spezzerai con scettro di ferro",
        },
        {
          notes: "                               Mi-",
          text: "come vasi di argilla le frantumerai.“",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Mi-",
          text: "\"IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
        {
          notes: " La-                      Mi-",
          text: "\"IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-                 Si7",
          text: "E ora sovrani, siate saggi,",
        },
        {
          notes: "",
          text: "istruitevi giudici della terra;",
        },
        {
          notes: "",
          text: "servite Dio con timore",
        },
        {
          notes: "                Mi-",
          text: "e con tremore esultate;",
        },
        {
          notes: "                                    La-",
          text: "che non si sdegni e voi perdiate la via,",
        },
        {
          notes: "                                Si7",
          text: "perché improvvisa divampa la sua ira.",
        },
        {
          notes: "                      Mi-",
          text: "Beato chi in lui si rifugia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Mi-",
          text: "\"IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
        {
          notes: " La-                      Mi-",
          text: "\"IO L'HO COSTITUITO MIO SOVRANO",
        },
        {
          notes: "     Si7",
          text: "SUL SION MIO SANTO MONTE,",
        },
        {
          notes: "                   Mi-",
          text: "SUL SION MIO SANTO MONTE“.",
        },
      ],
    },
  ],
  subtitle: "Sal 2",
};
