import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Signore, non punirmi nel tuo sdegno.",
  url_video: "https://www.youtube.com/embed/F-BFvcIZizI",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#            Sol             Fa#",
          text: "SIGNORE, NON PUNIRMI NEL TUO SDEGNO,",
        },
        {
          notes: "         Sol             Fa#",
          text: "NON CASTIGARMI NEL TUO FURORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                           La          Fa#",
          text: "Pietà di me, pietà di me, Signore, vengo meno;",
        },
        {
          notes: "Si-              La                      Fa#",
          text: "risanami tu, Signore, ché tremano le mie ossa.",
        },
        {
          notes: "Sol                       Fa#",
          text: "Tutta sconvolta è l'anima mia,",
        },
        {
          notes: "La                       Fa#",
          text: "e tu, Signore, fino a quando?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La                       Fa#",
          text: "E TU, SIGNORE, FINO A QUANDO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                              Fa#",
          text: "Volgiti, Signore, libera la mia anima,",
        },
        {
          notes: "Sol                                Fa#",
          text: "salvami, Signore, per la tua misericordia.",
        },
        {
          notes: "        La                    Fa#",
          text: "Ché nessuno tra i morti ti ricorda",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La                    Fa#",
          text: "CHÉ NESSUNO TRA I MORTI TI RICORDA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         La                       Fa#",
          text: "né negli inferi si cantano le tue lodi.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#            Sol             Fa#",
          text: "SIGNORE, NON PUNIRMI NEL TUO SDEGNO,",
        },
        {
          notes: "         Sol             Fa#",
          text: "NON CASTIGARMI NEL TUO FURORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "Sono stremato dai lamenti,",
        },
        {
          notes: "La                                  Si-",
          text: "ogni notte inondo di lacrime il mio letto;",
        },
        {
          notes: "       Sol                       Fa#",
          text: "i miei occhi si consumano nell'angustia,",
        },
        {
          notes: "   Mi-               Fa#",
          text: "invecchio fra tanti nemici.",
        },
        {
          notes: "La             Fa#",
          text: "Via da me i malvagi,",
        },
        {
          notes: "La                               Fa#",
          text: "ché il Signore ascolta il mio lamento,",
        },
        {
          notes: "La                                  Fa#",
          text: "ché il Signore accoglie la mia preghiera.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#            Sol             Fa#",
          text: "SIGNORE, NON PUNIRMI NEL TUO SDEGNO,",
        },
        {
          notes: "         Sol             Fa#",
          text: "NON CASTIGARMI NEL TUO FURORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 6",
};
