import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Mi hai sedotto, Signore.",
  url_video: "https://www.youtube.com/embed/9g0HruGeyaI",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-               Fa#°",
          text: "Mi hai sedotto, Signore",
        },
        {
          notes: "                          Si-",
          text: "e io mi sono lasciato sedurre;",
          msg: "  (L'Ass. ripete)",
        },
        {
          notes: "               Fa#°         Si-",
          text: "hai lottato con me, e mi hai vinto;",
        },
        {
          notes: "Sol",
          text: "mi hai fatto forza e hai prevalso!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                  Fa#°                  Si-",
          text: "Quando parlo devo gridare: violenza! Oppressione!",
        },
        {
          notes: "               Fa#°                         Si-",
          text: "Così la tua parola è obbrobrio e scherno per me.",
        },
        {
          notes: "Fa#                        Fa#°",
          text: "Mi dicevo: non penserò più a Lui,",
        },
        {
          notes: "                         Si-",
          text: "non parlerò più nel suo nome.",
        },
        {
          notes: "Fa#°",
          text: "Ma nel mio cuore c'era un fuoco ardente",
        },
        {
          notes: "         Sol            Fa#",
          text: "che scorreva nelle mie ossa;",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Si-               Fa#°",
          text: "Mi hai sedotto, Signore",
        },
        {
          notes: "                          Si-",
          text: "e io mi sono lasciato sedurre;",
        },
        {
          notes: "               Fa#°         Si-",
          text: "hai lottato con me, e mi hai vinto;",
        },
        {
          notes: "Sol",
          text: "mi hai fatto forza e hai prevalso!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                           Fa#°",
          text: "Maledetto il giorno che sono nato,",
        },
        {
          notes: "",
          text: "maledetto l'uomo che portò la notizia a mio padre:",
        },
        {
          notes: "                       Si-",
          text: "“Ti è nato un figlio maschio.“",
        },
        {
          notes: "Fa#     Fa#°",
          text: "Perché sono uscito dal seno di mia madre,",
        },
        {
          notes: "                                         Fa#",
          text: "se solo devo vedere tormenti e dolore.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      La",
          text: "Ma il Signore è al mio fianco",
        },
        {
          notes: "                    Re",
          text: "come un prode valoroso.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                      La",
          text: "MA IL SIGNORE È AL MIO FIANCO",
        },
        {
          notes: "                    Re",
          text: "COME UN PRODE VALOROSO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-               Fa#                 Si-",
          text: "Cantate inni al Signore, lodate il Signore",
        },
        {
          notes: "               Sol                   Fa#",
          text: "perché ha liberato la vita di questo povero.",
        },
      ],
    },
  ],
  subtitle: "(Ger 20, 7-18)",
};
