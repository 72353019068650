import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Il_gatto_e_la_volpe.",
  title: "Il gatto e la volpe.",
  url_video: "https://www.youtube.com/embed/YoBuv7HR-Fw",
  barrè: "",
  color: "none",
  semantic: "custom",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La                Fa#-        Si-  Mi",
          text: "Quanta fretta, ma dove corri, dove vai?",
        },
        {
          notes: "La                Fa#-       Si- Mi",
          text: "Se ci ascolti per un momento capirai",
        },
        {
          notes: "La                Do#7         Fa#",
          text: "Lui è il gatto ed io La volpe stiamo in società",
        },
        {
          notes: "   Si-    Mi     La   Fa#-  La  Fa#-",
          text: "di noi ti puoi fidar",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La                Fa#-           Si-      Mi",
          text: "Puoi parLarci dei tuoi problemi, dei tuoi guai",
        },
        {
          notes: "La            Fa#-         Si-   Mi",
          text: "i migliori in questo campo siamo noi",
        },
        {
          notes: "La             Do#7        Fa#7",
          text: "è una ditta specializzata, Fa un contratto e vedrai",
        },
        {
          notes: "    Si-    Mi   La   Fa#-   La   Fa#-",
          text: "che non ti pentirai",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La              Fa#-            Si-   Mi",
          text: "Noi scopriamo talenti e non sbagliamo mai",
        },
        {
          notes: "La               Fa#-        Si-  Mi",
          text: "noi sapremo sfruttare le tue qualità",
        },
        {
          notes: "La         Do#7             Fa#7",
          text: "dacci solo quattro monete e ti iscriviamo al concorso",
        },
        {
          notes: "    Si-  Mi   La   La7",
          text: "per La celebrità!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                 Mi         La             Fa#-",
          text: " Non vedi che è un vero affare non perdere l'occasione",
        },
        {
          notes: "Si-       Mi         La    La7",
          text: "se no poi te ne pentirai,",
        },
        {
          notes: "Re         Mi",
          text: "Non capita tutti i giorni",
        },
        {
          notes: "Do#-                    Si7",
          text: "di avere due consulenti due impresari,",
        },
        {
          notes: "Mi",
          text: "che si fanno in quattro per te!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La          Fa#-          Si-   Mi",
          text: "Avanti, non perder tempo, firma qua",
        },
        {
          notes: "La              Fa#-             Si- Mi",
          text: "è un normale contratto, è una formalità",
        },
        {
          notes: "La         Do#7              Fa#7",
          text: "tu ci cedi tutti i diritti e noi Faremo di te",
        },
        {
          notes: "   Si-     Mi    La     ",
          text: "un divo da hit parade!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La  Fa#-  Si-  Mi  La  Fa#-  Si-  Mi",
          text: "",
        },
        {
          notes: "La  Do#7  Fa#7  Si- Mi  La  La7",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                Mi         La             Fa#-",
          text: "Non vedi che è un vero affare non perdere l'occasione",
        },
        {
          notes: "Si-       Mi         La    La7",
          text: "se no poi te ne pentirai,",
        },
        {
          notes: "Re         Mi",
          text: "Non capita tutti i giorni",
        },
        {
          notes: "Do#-                    Si7",
          text: "di avere due consulenti due impresari,",
        },
        {
          notes: "Mi",
          text: "che si fanno in quattro per te!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La                Fa#-        Si-  Mi",
          text: "Quanta fretta, ma dove corri, dove vai?",
        },
        {
          notes: "La              Fa#-              Si-   Mi",
          text: "Che fortuna che hai avuto ad incontrare noi!",
        },
        {
          notes: "La             Do#7        Fa#7",
          text: "Lui è il gatto io La volpe siamo in società",
        },
        {
          notes: "   Si-    Mi     La   Fa#7  ",
          text: "di noi ti puoi fidar... ",
        },
        {
          notes: "   Si-    Mi     La   Fa#7",
          text: "di noi ti puoi fidar...",
        },
        {
          notes: "   Si-    Mi     La",
          text: "di noi ti puoi fidar!",
        },
        {
          notes: "",
          text: "undefined",
        },
      ],
    },
  ],
  subtitle: "Edoardo bennato",
  sent_for_revision: false,
};
