import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Che mi baci.",
  url_video: "https://www.youtube.com/embed/0Oez-xU0AUQ",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Che mi baci",
        },
        {
          notes: "",
          text: "coi baci della sua bocca:",
        },
        {
          notes: " Sib",
          text: "più dolce del vino",
        },
        {
          notes: "           La",
          text: "è il tuo amore,",
        },
        {
          notes: "",
          text: "il tuo nome",
        },
        {
          notes: "",
          text: "è unguento che si versa:",
        },
        {
          notes: "",
          text: "per questo",
        },
        {
          notes: "              Re-",
          text: "ti aman le fanciulle.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "PORTAMI INSIEME A TE, USCIAMO:",
        },
        {
          notes: "     Sib                  La",
          text: "ATTIRAMI DIETRO A TE, CORRIAMO!",
        },
        {
          notes: "    La7",
          text: "CELEBREREMO I TUOI AMORI",
        },
        {
          notes: "",
          text: "PIÙ DEL VINO,",
        },
        {
          notes: "                   Re-",
          text: "OH QUANTO È BELLO AMARTI!",
        },
        {
          notes: "",
          text: "MOSTRAMI TU AMATO DEL MIO CUORE",
        },
        {
          notes: "                La",
          text: "DOVE TU VAI A PASCOLARE",
        },
        {
          notes: "",
          text: "AFFINCHÉ IO NON SIA VAGABONDA",
        },
        {
          notes: "                   Re-",
          text: "DIETRO AD ALTRI COMPAGNI",
        },
        {
          notes: "",
          text: "MOSTRAMI TU AMATO DEL MIO CUORE",
        },
        {
          notes: "                La",
          text: "DOVE TU VAI A PASCOLARE",
        },
        {
          notes: "",
          text: "AFFINCHÉ IO NON SIA VAGABONDA",
        },
        {
          notes: "                   Re-",
          text: "DIETRO AD ALTRI COMPAGNI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Se non lo sai",
        },
        {
          notes: "",
          text: "o bella fra le donne,",
        },
        {
          notes: " Sib",
          text: "segui il sentiero",
        },
        {
          notes: "          La",
          text: "del mio gregge",
        },
        {
          notes: "",
          text: "e porta lì",
        },
        {
          notes: "",
          text: "le tue caprette",
        },
        {
          notes: "                       Re-",
          text: "fino alla dimora dei pastori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "PORTAMI INSIEME A TE, USCIAMO:",
        },
        {
          notes: "     Sib                  La",
          text: "ATTIRAMI DIETRO A TE, CORRIAMO!",
        },
        {
          notes: "    La7",
          text: "CELEBREREMO I TUOI AMORI",
        },
        {
          notes: "",
          text: "PIÙ DEL VINO,",
        },
        {
          notes: "                   Re-",
          text: "OH QUANTO È BELLO AMARTI!",
        },
        {
          notes: "",
          text: "MOSTRAMI TU AMATO DEL MIO CUORE",
        },
        {
          notes: "                La",
          text: "DOVE TU VAI A PASCOLARE",
        },
        {
          notes: "",
          text: "AFFINCHÉ IO NON SIA VAGABONDA",
        },
        {
          notes: "                   Re-",
          text: "DIETRO AD ALTRI COMPAGNI",
        },
        {
          notes: "",
          text: "MOSTRAMI TU AMATO DEL MIO CUORE",
        },
        {
          notes: "                La",
          text: "DOVE TU VAI A PASCOLARE",
        },
        {
          notes: "",
          text: "AFFINCHÉ IO NON SIA VAGABONDA",
        },
        {
          notes: "                   Re-",
          text: "DIETRO AD ALTRI COMPAGNI.",
        },
      ],
    },
  ],
  subtitle: "(Ct 1, 2 ss)",
};
