import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ListIcon from "@mui/icons-material/List";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import { getOptions } from "../../Utils";
import { SongElement } from "../../Songs/SongElement";
import { getAppSongs } from "../../Songs/SongHandler";
import "./NavMenu.scss";
import { OPTION_ALLOW_RANDOM_SONGS } from "../../Constants";
import Img from "../Img/Img";

export interface NavMenuVoice {
  label: string;
  icon: JSX.Element;
  path: string;
  action?: () => any;
  displayTest?: () => boolean;
}

export interface NavMenuSection {
  voices: NavMenuVoice[];
  label: string;
  collapsed: boolean;
}

export const getRandomNavPath = () => {
  let songs: SongElement[] = getAppSongs();
  let randSong: SongElement = songs[Math.floor(Math.random() * songs.length)];
  return "/view?id=" + encodeURI(randSong.id ?? randSong.title);
};

const NavMenuVoices_Navigation: NavMenuVoice[] = [
  {
    label: "Bacheca",
    icon: <HomeIcon />,
    path: "home",
  },
  {
    label: "Indice",
    icon: <ListIcon />,
    path: "index",
  },
  {
    displayTest: () => {
      return getOptions()[OPTION_ALLOW_RANDOM_SONGS] ?? false;
    },
    label: "Casuale",
    icon: <ShuffleIcon />,
    path: "",
    action: getRandomNavPath,
  },
];

const NavMenuVoices_Utils: NavMenuVoice[] = [
  {
    label: "Liturgia",
    icon: <FactCheckIcon />,
    path: "liturgy",
  },
  {
    label: "Accorda",
    icon: <GraphicEqIcon />,
    path: "tune",
  },
  {
    label: "Crea un canto",
    icon: <EditIcon />,
    path: "create",
  },
  {
    label: "Guida",
    icon: <HelpIcon />,
    path: "guide",
  },
];

const NavMenuVoices_Personal: NavMenuVoice[] = [
  {
    label: "Preferiti",
    icon: <BookmarkIcon />,
    path: "favorites",
  },
  {
    label: "Profilo",
    icon: <SsidChartIcon />,
    path: "profile",
  },
  {
    label: "Opzioni",
    icon: <SettingsIcon />,
    path: "options",
  },
];

const NavMenuVoices: NavMenuSection[] = [
  {
    label: "Navigazione",
    voices: NavMenuVoices_Navigation,
    collapsed: false,
  },
  {
    label: "Utilità",
    voices: NavMenuVoices_Utils,
    collapsed: false,
  },
  {
    label: "Personale",
    voices: NavMenuVoices_Personal,
    collapsed: false,
  },
];

const NavMenu = () => {
  const [voices, setVoices] = useState<NavMenuSection[]>(NavMenuVoices);
  const navigate = useNavigate();
  return (
    <div className="nav-menu-main-wrap base_left_menu_background_color base_border_color box-shadow">
      {voices.map((x: NavMenuSection, i: number) => {
        return (
          <div key={i} className="nav-menu-section-wrap">
            <div
              className="nav-menu-section-title dark_text_color"
              onClick={() => {
                let voicesTmp: NavMenuSection[] = [...voices];
                voicesTmp[i].collapsed = !voicesTmp[i].collapsed;
                setVoices(voicesTmp);
              }}
            >
              <div
                className={
                  "nav-menu-section-title-chevron " +
                  (voices[i].collapsed
                    ? "nav-menu-section-title-chevron-rotated"
                    : "")
                }
              >
                <KeyboardArrowDownIcon />
              </div>
              <div className="nav-menu-accordion-label">{x.label}</div>
            </div>
            <div
              className="nav-menu-section-content"
              style={voices[i].collapsed ? { maxHeight: "0" } : {}}
            >
              {x.voices
                .filter((x: NavMenuVoice) => {
                  if (x.displayTest) {
                    return x.displayTest();
                  } else {
                    return true;
                  }
                })
                .map((y: NavMenuVoice, j: number) => {
                  return (
                    <ButtonBase key={j} style={{ width: "100%" }}>
                      <div
                        onClick={() => {
                          if (y.action) {
                            navigate(y.action());
                          } else {
                            navigate("/" + y.path);
                          }
                        }}
                        key={j}
                        className="nav-menu-section-voice-wrap dark_text_color button-background"
                      >
                        <div className="nav-menu-section-voice-wrap-icon">
                          {y.icon}
                        </div>
                        <div className="nav-menu-section-voice-wrap-label">
                          {y.label}
                        </div>
                      </div>
                    </ButtonBase>
                  );
                })}
            </div>
          </div>
        );
      })}
      <Img
        className="madonnina-necoat-overlay"
        src={"./Assets/madonnina-neocat.png"}
        alt="Madonnina"
      />
    </div>
  );
};

export default NavMenu;
