import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno dei vespri di pasqua (da pasqua fino all'ascensione).",
  url_video: "https://www.youtube.com/embed/DWfFAME9v_w",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "ALLA CENA DELL'AGNELLO,",
        },
        {
          notes: "                   La-",
          text: "AVVOLTI IN BIANCHE VESTI,",
        },
        {
          notes: "Fa",
          text: "ATTRAVERSATO IL MAR ROSSO,",
        },
        {
          notes: "                    Mi",
          text: "CANTIAMO A CRISTO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Sol",
          text: "Il suo corpo arso d'amore",
        },
        {
          notes: "                   La-",
          text: "sulla mensa è pane vivo;",
        },
        {
          notes: "                     Sol",
          text: "il suo sangue sull'altare",
        },
        {
          notes: "                 La-",
          text: "calice del nuovo patto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "ALLA CENA DELL'AGNELLO,",
        },
        {
          notes: "                   La-",
          text: "AVVOLTI IN BIANCHE VESTI,",
        },
        {
          notes: "Fa",
          text: "ATTRAVERSATO IL MAR ROSSO,",
        },
        {
          notes: "                    Mi",
          text: "CANTIAMO A CRISTO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "In questo vespro mirabile",
        },
        {
          notes: "                   La-",
          text: "tornan gli antichi prodigi:",
        },
        {
          notes: "                     Sol",
          text: "un braccio potente ci salva",
        },
        {
          notes: "                   La-",
          text: "dall'angelo distruttore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "ALLA CENA DELL'AGNELLO,",
        },
        {
          notes: "                   La-",
          text: "AVVOLTI IN BIANCHE VESTI,",
        },
        {
          notes: "Fa",
          text: "ATTRAVERSATO IL MAR ROSSO,",
        },
        {
          notes: "                    Mi",
          text: "CANTIAMO A CRISTO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "Mite agnello immolato",
        },
        {
          notes: "                   La-",
          text: "Cristo è la nostra Pasqua;",
        },
        {
          notes: "                   Sol",
          text: "il suo corpo adorabile",
        },
        {
          notes: "                   La-",
          text: "è il vero pane azzimo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "ALLA CENA DELL'AGNELLO,",
        },
        {
          notes: "                   La-",
          text: "AVVOLTI IN BIANCHE VESTI,",
        },
        {
          notes: "Fa",
          text: "ATTRAVERSATO IL MAR ROSSO,",
        },
        {
          notes: "                    Mi",
          text: "CANTIAMO A CRISTO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "Irradia sulla tua Chiesa",
        },
        {
          notes: "                   La-",
          text: "la gioia pasquale, o Signore;",
        },
        {
          notes: "                     Sol",
          text: "unisci alla tua vittoria",
        },
        {
          notes: "                   La-",
          text: "i rinati nel battesimo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol",
          text: "ALLA CENA DELL'AGNELLO,",
        },
        {
          notes: "                   La-",
          text: "AVVOLTI IN BIANCHE VESTI,",
        },
        {
          notes: "Fa",
          text: "ATTRAVERSATO IL MAR ROSSO,",
        },
        {
          notes: "                    Mi",
          text: "CANTIAMO A CRISTO SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "Sia lode e onore a Cristo,",
        },
        {
          notes: "                   La-",
          text: "vincitore della morte,",
        },
        {
          notes: "                     Sol",
          text: "al Padre e al Santo Spirito",
        },
        {
          notes: "                   La-",
          text: "ora e nei secoli eterni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol La-",
          text: "AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
