import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Non morirò.",
  url_video: "https://www.youtube.com/embed/qT4T89CnYEk",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do",
          text: "e annunzierò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                Si7",
          text: "LE OPERE DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Do",
          text: "Però no",
        },
        {
          notes: "                     Si7",
          text: "NON MI LASCIÒ NELLA MORTE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Celebrate il Signore perché è buono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do              Si7",
          text: "ETERNA È LA SUA MISERICORDIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Lo dica Israele che egli è buono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do              Si7",
          text: "ETERNA È LA SUA MISERICORDIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Lo dica la casa di Aronne",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do              Si7",
          text: "ETERNA È LA SUA MISERICORDIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Lo dica chi teme il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do              Si7",
          text: "ETERNA È LA SUA MISERICORDIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Nell'angoscia ho gridato al Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                                    Si7",
          text: "MI HA RISPOSTO IL SIGNORE, MI HA TRATTO IN SALVO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Il Signore è con me, non ho timore",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do           Si7",
          text: "CHE COSA PUÒ FARMI L'UOMO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Il Signore è con me, è mio aiuto",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Do          Si7",
          text: "SFIDERÒ I MIEI NEMICI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                      Si7",
          text: "È meglio rifugiarsi nel Signore",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Do          Si7",
          text: "CHE CONFIDARE NELL'UOMO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                      Si7",
          text: "È meglio rifugiarsi nel Signore",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Do           Si7",
          text: "CHE CONFIDARE NEI POTENTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Mi-",
          text: "Mi circondarono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CIRCONDARONO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Mi-",
          text: "Mi circondarono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CIRCONDARONO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Do",
          text: "Ma nel nome del Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Si7",
          text: "LI HO SCONFITTI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "Mi accerchiarono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI ACCERCHIARONO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "Mi accerchiarono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI ACCERCHIARONO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Do",
          text: "Ma nel nome del Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Si7",
          text: "LI HO SCONFITTI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Mi hanno circondato come api",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                      Si7",
          text: "COME FUOCO CHE DIVAMPA TRA LE SPINE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Mi avevano spinto con forza per farmi cadere",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                   Si7",
          text: "MA IL SIGNORE È STATO MIO AIUTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Mia forza e mio canto è il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do               Si7",
          text: "EGLI È STATO LA MIA SALVEZZA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                       Si7",
          text: "Grida di giubilo e di vittoria",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do         Si7",
          text: "NELLE TENDE DEI GIUSTI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                           Si7",
          text: "destra del Signore si è innalzata",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do                                Si7",
          text: "LA DESTRA DEL SIGNORE HA FATTO MERAVIGLIE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do",
          text: "e annunzierò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                Si7",
          text: "LE OPERE DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Do",
          text: "Però no",
        },
        {
          notes: "                     Si7",
          text: "NON MI LASCIÒ NELLA MORTE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Apritemi le porte della giustizia",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Do                          Si7",
          text: "ENTRERÒ PER RENDERE GRAZIE AL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Questa è la porta del Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Do              Si7",
          text: "PER ESSA ENTRANO I GIUSTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Ti rendo grazie perché m'hai esaudito",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Do              Si7",
          text: "PERCHÉ SEI STATO LA MIA SALVEZZA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "La pietra scartata dai costruttori",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do             Si7",
          text: "È DIVENUTA TESTATA D'ANGOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                      Si7",
          text: "Questa è l'opera del Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      Do               Si7",
          text: "UNA MERAVIGLIA AI NOSTRI OCCHI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                              Si7",
          text: "Questo è il giorno fatto dal Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Do                   Si7",
          text: "RALLEGRIAMOCI ED ESULTIAMO IN ESSO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do",
          text: "e annunzierò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                Si7",
          text: "LE OPERE DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Do",
          text: "Però no",
        },
        {
          notes: "                     Si7",
          text: "NON MI LASCIÒ NELLA MORTE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Dona, Signore, la tua salvezza",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do                     Si7",
          text: "DONA, SIGNORE, LA TUA VITTORIA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Benedetto colui che viene",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Do         Si7",
          text: "NEL NOME DEL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Vi benediciamo dalla casa del Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do                       Si7",
          text: "DIO, IL SIGNORE, È NOSTRA LUCE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Ordinate il corteo con rami frondosi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Do         Si7",
          text: "FINO AI LATI DELL'ALTARE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                              Si7",
          text: "Sei tu il mio Dio, e ti rendo grazie",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           Do         Si7",
          text: "SEI IL MIO DIO, E TI ESALTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                             Si7",
          text: "Celebrate il Signore perché è buono",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                  Si7",
          text: "ETERNA È LA SUA MISERICORDIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Non morirò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA IO VIVRÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do",
          text: "e annunzierò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                Si7",
          text: "LE OPERE DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Mi castigò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MI CASTIGÒ,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Do",
          text: "Però no",
        },
        {
          notes: "                     Si7",
          text: "NON MI LASCIÒ NELLA MORTE!",
        },
      ],
    },
  ],
  subtitle: "Sal 117(118)",
};
