import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Voglio cantare.",
  url_video: "https://www.youtube.com/embed/H4r0ScX-ZpY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                         Fa#-",
          text: "Pietà di me, pietà di me o Dio,",
        },
        {
          notes: "                           Sol",
          text: "la mia anima si rifugia in te;",
        },
        {
          notes: "                Mi-",
          text: "mi rifugio all'ombra delle tue ali",
        },
        {
          notes: "La                       Re",
          text: "finché sia passato il pericolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                    Fa#-",
          text: "Invocherò Dio, l'altissimo,",
        },
        {
          notes: "                  Sol",
          text: "Dio che mi fa il bene;",
        },
        {
          notes: "           Mi-",
          text: "mandi dal cielo a salvarmi",
        },
        {
          notes: "La                       Re",
          text: "dalla mano dei miei persecutori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Fa#-",
          text: "Io sono come in mezzo a leoni",
        },
        {
          notes: "                          Sol",
          text: "a leoni che divorano gli uomini;",
        },
        {
          notes: "              Mi-",
          text: "i loro denti sono lance e frecce,",
        },
        {
          notes: "La                       Re",
          text: "la loro lingua spada affilata.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                         Fa#-",
          text: "Pietà di me, pietà di me, o Dio,",
        },
        {
          notes: "                             Sol",
          text: "ché hanno teso una rete ai miei piedi,",
        },
        {
          notes: "                Mi-",
          text: "hanno scavato davanti a me una fossa,",
        },
        {
          notes: "La                       Re",
          text: "ma sono loro che vi sono caduti.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                         Fa#-",
          text: "Saldo è il mio cuore, saldo è il mio cuore o Dio,",
        },
        {
          notes: "               Sol",
          text: "a te voglio cantare,",
        },
        {
          notes: "           Mi-",
          text: "a te voglio inneggiare,",
        },
        {
          notes: "La                   Re",
          text: "voglio svegliare l'aurora.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                          Fa#-",
          text: "VOGLIO CANTARE, A TE VOGLIO CANTARE,",
        },
        {
          notes: "  Sol",
          text: "RISVEGLIATI, RISVEGLIATI MIO CUORE,",
        },
        {
          notes: "  La                Re",
          text: "RISVEGLIATI ARPA E CETRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                        Fa#-",
          text: "Ti loderò tra i popoli, o Dio,",
        },
        {
          notes: "                         Sol",
          text: "a te canterò inni tra le genti,",
        },
        {
          notes: "                  Mi-",
          text: "perché la tua bontà è grande fino ai cieli",
        },
        {
          notes: "La                       Re",
          text: "e la tua fedeltà fino alle nubi.",
        },
      ],
    },
  ],
  subtitle: "Sal 56(57)",
};
