import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Sono_qui_a_lodarti.",
  title: "Sono qui a lodarti.",
  url_video: "https://www.youtube.com/embed/vYKuki8ALyM",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Mi  Si  Fa#7    Mi  Si  La    ",
          text: " ",
        },
        {
          notes: "       Mi  Si  Fa#7    Mi  Si  La   ",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi       Si        Fa#7",
          text: "Luce del mondo nel buio",
        },
        {
          notes: "          Mi         Si   La    ",
          text: "del cuore vieni ed illuminami,",
        },
        {
          notes: "Mi     Si      Fa#7    ",
          text: "tu mia sola speranza di vita",
        },
        {
          notes: "Mi        Si      La    ",
          text: "resta per sempre con me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "             Mi                Re#",
          text: "Sono quì a lodarti, quì per adorarti,",
        },
        {
          notes: "              Do#7             La    ",
          text: "quì per dirti che tu sei il mio Dio",
        },
        {
          notes: "              Mi                Re#",
          text: "e solo tu sei Santo, sei meraviglioso,",
        },
        {
          notes: "           Do#7         La    ",
          text: "degno e glorioso sei per me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi       Si       Fa#7",
          text: "Re della storia e Re della gloria",
        },
        {
          notes: "Mi           Si     La    ",
          text: "sei sceso in terra fra noi,",
        },
        {
          notes: "Mi      Si        Fa#7        ",
          text: "con umiltà il tuo trono hai lasciato",
        },
        {
          notes: "Mi       Si         La    ",
          text: "per dimostrarci il tuo amor.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "             Mi                Re#",
          text: "Sono quì a lodarti, quì per adorarti,",
        },
        {
          notes: "              Do#7             La    ",
          text: "quì per dirti che tu sei il mio Dio",
        },
        {
          notes: "              Mi                Re#",
          text: "e solo tu sei Santo, sei meraviglioso,",
        },
        {
          notes: "           Do#7         La    ",
          text: "degno e glorioso sei per me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Si    Mi      La    ",
          text: "Io mai saprò quanto ti costò",
        },
        {
          notes: "   Si    Mi     La    ",
          text: "lì sulla croce morir per me.",
        },
        {
          notes: "   Si    Mi        La    ",
          text: "Io mai saprò quanto ti costò",
        },
        {
          notes: "   Si    Mi     La    ",
          text: "lì sulla croce morir per me.",
        },
        {
          notes: "   Si    Mi        La    ",
          text: "Io mai saprò quanto ti costò",
        },
        {
          notes: "   Si    Mi     La    ",
          text: "lì sulla croce morir per me.",
        },
        {
          notes: "   Si    Mi        La    ",
          text: "Io mai saprò quanto ti costò",
        },
        {
          notes: "   Si    Mi     La    ",
          text: "lì sulla croce morir per me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "             Mi                Re#",
          text: "Sono quì a lodarti, quì per adorarti,",
        },
        {
          notes: "              Do#7             La    ",
          text: "quì per dirti che tu sei il mio Dio",
        },
        {
          notes: "              Mi                Re#",
          text: "e solo tu sei Santo, sei meraviglioso,",
        },
        {
          notes: "           Do#7         La    ",
          text: "degno e glorioso sei per me",
        },
        {
          notes: "             Mi                Re#",
          text: "Sono quì a lodarti, quì per adorarti,",
        },
        {
          notes: "              Do#7             La    ",
          text: "quì per dirti che tu sei il mio Dio",
        },
        {
          notes: "              Mi                Re#",
          text: "e solo tu sei Santo, sei meraviglioso,",
        },
        {
          notes: "           Do#7         La    ",
          text: "degno e glorioso sei per me.",
        },
        {
          notes: "             Mi                Re#",
          text: "Sono quì a lodarti, quì per adorarti,",
        },
        {
          notes: "              Do#7             La    ",
          text: "quì per dirti che tu sei il mio Dio",
        },
        {
          notes: "              Mi                Re#",
          text: "e solo tu sei Santo, sei meraviglioso,",
        },
        {
          notes: "           Do#7         La       ",
          text: "degno e glorioso sei per me.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
