import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Guardate com'è bello, gustate quant'è soave.",
  url_video: "https://www.youtube.com/embed/CWlwxuC3InA",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "    Do           Si7",
          text: "L'AMORE TRA I FRATELLI.",
        },
        {
          notes: "   Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "         Do              Si7",
          text: "CHE I FRATELLI STIANO INSIEME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "È come olio che scende",
        },
        {
          notes: "    La-                  Si7",
          text: "che scende sul capo di Aronne.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                  Si7",
          text: "È COME OLIO PROFUMATO CHE SCENDE",
        },
        {
          notes: "",
          text: "SULLA BARBA DI ARONNE",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "    Do           Si7",
          text: "L'AMORE TRA I FRATELLI.",
        },
        {
          notes: "   Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "         Do              Si7",
          text: "CHE I FRATELLI STIANO INSIEME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "È come rugiada dall'Ermon",
        },
        {
          notes: "La-              Si7",
          text: "giù sui monti di Sion.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Do              Si7",
          text: "È COME RUGIADA DALL'ERMON",
        },
        {
          notes: "",
          text: "SUI MONTI DI SION",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "    Do           Si7",
          text: "L'AMORE TRA I FRATELLI.",
        },
        {
          notes: "   Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "         Do              Si7",
          text: "CHE I FRATELLI STIANO INSIEME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "Perché là il Signore ci dona",
        },
        {
          notes: " La-            Si7",
          text: "la sua benedizione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      Do                  Si7",
          text: "PERCHÉ LÀ IL SIGNORE CI DONA",
        },
        {
          notes: "",
          text: "IL SUO AMORE",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "    Do           Si7",
          text: "L'AMORE TRA I FRATELLI.",
        },
        {
          notes: "   Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "         Do              Si7",
          text: "CHE I FRATELLI STIANO INSIEME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Si7",
          text: "Perché là il Signore ci dona",
        },
        {
          notes: " La-             Si7",
          text: "la vita eternamente.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Do                  Si7",
          text: "PERCHÉ LÀ IL SIGNORE CI DONA",
        },
        {
          notes: "",
          text: "LA SUA VITA",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "    Do           Si7",
          text: "L'AMORE TRA I FRATELLI.",
        },
        {
          notes: "   Mi-",
          text: "GUARDATE COME È BELLO, GUSTATE QUANT'È SOAVE",
        },
        {
          notes: "         Do              Si7",
          text: "CHE I FRATELLI STIANO INSIEME.",
        },
      ],
    },
  ],
  subtitle: "Sal 132(133)",
};
