import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Giacobbe.",
  url_video: "https://www.youtube.com/embed/v5SWPJgpvoY",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Era già notte",
        },
        {
          notes: "",
          text: "quando Giacobbe si levò,",
        },
        {
          notes: "Fa",
          text: "prese le mogli",
        },
        {
          notes: "                 Mi7",
          text: "e tutti i suoi figli",
        },
        {
          notes: "                      La-",
          text: "e passò il guado di Jabbo",
        },
        {
          notes: "",
          text: "Dopo averli presi",
        },
        {
          notes: "",
          text: "fece loro passare il torrente",
        },
        {
          notes: "Fa",
          text: "fece passare anche",
        },
        {
          notes: "               Mi",
          text: "tutti i suoi beni:",
        },
        {
          notes: "                 La-",
          text: "e Giacobbe restò solo.",
        },
        {
          notes: "       Sol             La-",
          text: "E qualcuno lottò con lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol             La-",
          text: "E QUALCUNO LOTTÒ CON LUI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol",
          text: "E qualcuno lottò con lui",
        },
        {
          notes: "         La-",
          text: "fino all'alba.",
        },
        {
          notes: "                           Fa",
          text: "Vedendo che non lo poteva vincere",
        },
        {
          notes: "",
          text: "lo colpì alla giuntura dell'anca",
        },
        {
          notes: "                         Mi",
          text: "e l'anca di Giacobbe si slogò,",
        },
        {
          notes: "                La-",
          text: "nella lotta con lui.",
        },
        {
          notes: "   Sol                     La-",
          text: "Lasciami che già viene l'aurora",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     La-",
          text: "LASCIAMI CHE GIÀ VIENE L'AURORA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     La-",
          text: "Lasciami che già viene l'aurora",
        },
        {
          notes: " Do        Re",
          text: "Io non ti lascerò",
        },
        {
          notes: "   Fa          Mi",
          text: "se tu non mi benedici.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do        Re",
          text: "IO NON TI LASCERÒ",
        },
        {
          notes: "   Fa          Mi",
          text: "SE TU NON MI BENEDICI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Mi7       La-",
          text: "E Dio gli domanda:",
        },
        {
          notes: "",
          text: "“Qual è il tuo nome?“",
        },
        {
          notes: "",
          text: "“Qual è il tuo nome?“",
        },
        {
          notes: "  Fa",
          text: "“Giacobbe“, rispose lui,",
        },
        {
          notes: "                   Mi",
          text: "“Giacobbe“, rispose lu",
        },
        {
          notes: "   Mi7                    La-",
          text: "Non ti chiamerai più Giacobbe.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol      La-",
          text: "ISRAEL, ISRAEL",
        },
        {
          notes: " Sol                La-",
          text: "ISRAEL SARÀ IL TUO NOME.",
        },
        {
          notes: " Sol                             La-",
          text: "ISRAEL: HAI CONOSCIUTO LA TUA DEBOLEZZA.",
        },
        {
          notes: " Sol                    La-",
          text: "ISRAEL, T'APPOGGERAI IN DIO.",
        },
        {
          notes: " Sol              La-",
          text: "ISRAEL: FORTE CON DIO,",
        },
        {
          notes: " Sol            La-",
          text: "ISRAEL: TU VINCERAI",
        },
        {
          notes: " Sol                             La-",
          text: "ISRAEL: HAI CONOSCIUTO LA TUA DEBOLEZZA.",
        },
        {
          notes: " Sol                    La-",
          text: "ISRAEL, T'APPOGGERAI IN DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol     La-",
          text: "Israel, Israel,",
        },
        {
          notes: " Sol",
          text: "Israel, Israel",
        },
        {
          notes: "             La-",
          text: "sarà il tuo nome.",
        },
      ],
    },
  ],
  subtitle: "(Gen 32, 23-29)",
};
