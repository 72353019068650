import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Amo il Signore.",
  url_video: "https://www.youtube.com/embed/pcm8_WHdqwA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     Si-",
          text: "Amo il Signore perché ascolta",
        },
        {
          notes: "                       Do",
          text: "il grido della mia preghiera.",
        },
        {
          notes: "         La-             Re7",
          text: "Verso di me ha teso l'orecchio",
        },
        {
          notes: "                       Sol",
          text: "nel giorno in cui l'invocavo.",
        },
        {
          notes: "",
          text: "Mi stringevano funi di morte,",
        },
        {
          notes: "                          Si-",
          text: "ero preso nei lacci degli inferi,",
        },
        {
          notes: "Do                          La-",
          text: "mi opprimevano tristezza e angoscia,",
        },
        {
          notes: "Re7",
          text: "ho invocato il nome del Signore:",
        },
        {
          notes: "                       Sol",
          text: "“ti prego, Signore, salvami.“",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                         Si-",
          text: "RITORNA ANIMA MIA ALLA TUA PACE",
        },
        {
          notes: "   Do                La-   Re7",
          text: "PERCHÉ IL SIGNORE TI HA BENEFICATO;",
        },
        {
          notes: "Do                          Sol",
          text: "EGLI TI HA SOTTRATTO DALLA MORTE,",
        },
        {
          notes: "                      Re          Sol",
          text: "HA PRESERVATO I TUOI PIEDI DALLA CADUTA,",
        },
        {
          notes: "Do                          Sol",
          text: "EGLI TI HA SOTTRATTO DALLA MORTE,",
        },
        {
          notes: "                      Re          Sol",
          text: "HA PRESERVATO I TUOI PIEDI DALLA CADUTA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol                       Si-",
          text: "Ho creduto anche quando ho detto:",
        },
        {
          notes: "",
          text: "“sono troppo disgraziato“;",
        },
        {
          notes: " Do          La- Re7",
          text: "ho detto con sgomento:",
        },
        {
          notes: "              Sol",
          text: "“ogni uomo è falso“.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Che cosa renderò al Signore",
        },
        {
          notes: "                  Si-",
          text: "per quanto mi ha dato?",
        },
        {
          notes: "  Do                      La-",
          text: "Alzerò il calice della salvezza",
        },
        {
          notes: "    Re7",
          text: "e invocherò il nome del Signor",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                         Si-",
          text: "RITORNA ANIMA MIA ALLA TUA PACE",
        },
        {
          notes: "   Do                La-   Re7",
          text: "PERCHÉ IL SIGNORE TI HA BENEFICATO;",
        },
        {
          notes: "Do                          Sol",
          text: "EGLI TI HA SOTTRATTO DALLA MORTE,",
        },
        {
          notes: "                     Re          Sol",
          text: "HA PRESERVATO I TUOI PIEDI DALLA CADUTA,",
        },
        {
          notes: "Do                          Sol",
          text: "EGLI TI HA SOTTRATTO DALLA MORTE,",
        },
        {
          notes: "                      Re          Sol",
          text: "HA PRESERVATO I TUOI PIEDI DALLA CADUTA.",
        },
      ],
    },
  ],
  subtitle: "Sal 114-115(116)",
};
