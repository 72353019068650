import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Bailando.",
  title: "Bailando.",
  url_video: "https://www.youtube.com/embed/ma81bJxaNCI",
  barrè: "III",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    Do",
          text: "Enrique Iglesias",
        },
        {
          notes: "Sol    Re",
          text: "One love one love",
        },
        {
          notes: "Mi-    Do",
          text: "Gente De Zona",
        },
        {
          notes: "Sol    Re",
          text: "Descemer",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                 Do",
          text: "Yo te miro, se me corta la respiración",
        },
        {
          notes: "Sol                                    Re",
          text: "Cuando tu me miras se me sube el corazón (Me palpita lento el corazon)",
        },
        {
          notes: "Mi-                                  Do",
          text: "Y en silencio tu mirada dice mil palabras",
        },
        {
          notes: "Sol                                            Re",
          text: "La noche en la que te suplico que no salga el sol",
        },
        {
          notes: "La-                   Do",
          text: "(Bailando, bailando, bailando, bailando)",
        },
        {
          notes: "                Mi-",
          text: "Tu cuerpo y el mio llenando el vacío",
        },
        {
          notes: "               Re",
          text: "Subiendo y bajando (subiendo y bajando)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " La-                  Do",
          text: "(Bailando, bailando, bailando, bailando)",
        },
        {
          notes: "                   Mi-",
          text: "Ese fuego por dentro me va enloqueciendo",
        },
        {
          notes: "             Re",
          text: "Me va saturando",
        },
        {
          notes: "               Mi-",
          text: "Con tu física y tu química también tu anatomía",
        },
        {
          notes: "                     Do",
          text: "La cerveza y el tequila y tu boca con la mía",
        },
        {
          notes: "           Sol                               Re",
          text: "Ya no puedo mas (ya no puedo mas) Ya no puedo mas (ya no puedo mas)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "              Mi-",
          text: "Con esta melodía, tu color, tu fantasía",
        },
        {
          notes: "              Do",
          text: "Con tu filosofía mi cabeza esta vacía",
        },
        {
          notes: "                Sol",
          text: "Ya no puedo mas (ya no puedo mas)",
        },
        {
          notes: "              Re",
          text: "Ya no puedo mas (ya no puedo mas)",
        },
        {
          notes: "                    Mi-",
          text: "Yo quiero estar contigo, vivir contigo",
        },
        {
          notes: "            Do",
          text: "Bailar contigo, tener contigo",
        },
        {
          notes: "            Sol",
          text: "Una noche loca (una noche loca)",
        },
        {
          notes: "               Re",
          text: "ay besar tu boca (y besar tu boca)",
        },
        {
          notes: "                   Mi-",
          text: "Yo quiero estar contigo, vivir contigo",
        },
        {
          notes: "            Do                            Sol",
          text: "Bailar contigo, tener contigo una noche loca",
        },
        {
          notes: "              Re",
          text: "Con tremenda nota",
        },
        {
          notes: "Mi-          Do       Sol        Re",
          text: "(Ooooh, ooooh, ooooh, ooooh)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                   Do",
          text: "Tu me miras y me llevas a otra dimensión (Estoy en otra dimensión)",
        },
        {
          notes: "Sol                              Re",
          text: "Tus latidos aceleran a mi corazón (Tus latidos aceleran a mi corazón)",
        },
        {
          notes: "Mi-                                   Do",
          text: "Que ironía del destino no poder tocarte, abrazarte",
        },
        {
          notes: "Sol                         Re",
          text: "y sentir la magia de tu olor",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Do",
          text: "(Bailando, bailando, bailando, bailando)",
        },
        {
          notes: "                Mi-",
          text: "Tu cuerpo y el mio llenando el vacío",
        },
        {
          notes: "               Re",
          text: "Subiendo y bajando (subiendo y bajando)",
        },
        {
          notes: " La-                  Do",
          text: "(Bailando, bailando, bailando, bailando)",
        },
        {
          notes: "                   Mi-",
          text: "Ese fuego por dentro me va enloqueciendo",
        },
        {
          notes: "             Re",
          text: "Me va saturando",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Mi-",
          text: "Con tu física y tu química también tu anatomía",
        },
        {
          notes: "                     Do",
          text: "La cerveza y el tequila y tu boca con la mía",
        },
        {
          notes: "             Sol                                 Re",
          text: "Ya no puedo mas (ya no puedo mas) Ya no puedo mas (ya no puedo mas)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "              Mi-",
          text: "Con esta melodía, tu color, tu fantasía",
        },
        {
          notes: "              Do",
          text: "Con tu filosofía mi cabeza esta vacía",
        },
        {
          notes: "                Sol",
          text: "Y ya no puedo mas (ya no puedo mas)",
        },
        {
          notes: "              Re",
          text: "Ya no puedo mas (ya no puedo mas)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                    Mi-",
          text: "Yo quiero estar contigo, vivir contigo",
        },
        {
          notes: "            Do",
          text: "Bailar contigo, tener contigo",
        },
        {
          notes: "            Sol",
          text: "Una noche loca (una noche loca)",
        },
        {
          notes: "               Re",
          text: "ay besar tu boca (y besar tu boca)",
        },
        {
          notes: "                   Mi-",
          text: "Yo quiero estar contigo, vivir contigo",
        },
        {
          notes: "            Do                            Sol",
          text: "Bailar contigo, tener contigo una noche loca",
        },
        {
          notes: "               Re",
          text: "Con tremenda nota",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Mi-     Do       Sol      Re",
          text: "(Ooooh, ooooh, ooooh, ooooh",
        },
        {
          notes: "     Mi-     Do       Sol     Re",
          text: "Ooooh, ooooh, ooooh, ooooh",
        },
        {
          notes: "    Mi-             Do",
          text: "Ooooh bailando amor ooooh",
        },
        {
          notes: "             Sol                             Re",
          text: "Bailando amor ooooh es que se me va el dolor, Ooooh)",
        },
      ],
    },
  ],
  subtitle: "Enrique Iglesias",
  sent_for_revision: false,
};
