import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Vieni, figlio dell'uomo.",
  url_video: "https://www.youtube.com/embed/we4B6elB0RI",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                La-",
          text: "Vieni, figlio dell'uomo",
        },
        {
          notes: " Si7            Mi-",
          text: "vieni, Signore Gesù!",
        },
        {
          notes: "  Mi-                La-",
          text: "Vieni, * servo dei poveri, *",
        },
        {
          notes: "  Si7             Mi-",
          text: "vieni, * Signore Gesù. *",
        },
        {
          notes: "Do                Mi-",
          text: "Lo spirito dice: vieni!",
        },
        {
          notes: "     Do            Si7",
          text: "Chi ascolta dica: vieni!",
        },
        {
          notes: "  Mi-                La-",
          text: "Vieni, * amore mio, *",
        },
        {
          notes: " Si7            Mi-",
          text: "vieni, * Signore Gesù.",
        },
        {
          notes: "Do                Mi-",
          text: "La sposa dice: vieni!",
        },
        {
          notes: "     Do            Si7",
          text: "Chi ascolta dica: vieni!",
        },
        {
          notes: "  Mi-                La-",
          text: "Ven, * hijo del hombre *",
        },
        {
          notes: " Si7            Mi-",
          text: "ven, * Señor Jesus! *",
        },
        {
          notes: "  Mi-                La-",
          text: "Viens, * fils de l'homme *",
        },
        {
          notes: " Si7            Mi-",
          text: "ven, * Seigneur Jesus! *",
        },
        {
          notes: "  Mi-                La-",
          text: "Come, * son of the man *",
        },
        {
          notes: " Si7            Mi-",
          text: "come, * Lord Jésus! *",
        },
        {
          notes: "Mi-                 La-",
          text: "Vieni, figlio dell'uomo",
        },
        {
          notes: "Si7",
          text: "Vieni... * vieni... * Maranathà",
        },
        {
          notes: "         Mi-",
          text: "Signor Gesù!",
        },
      ],
    },
    {
      speaker: "  L'assemblea ripete",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "(Ap 22, 17 ss)",
};
