import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Signore aiutami, Signore.",
  url_video: "https://www.youtube.com/embed/1jqa6OaAnKE",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Si-         La       Si-",
          text: "Signore, aiutami, Signore,",
          msg: "  (2 v.)",
        },
        {
          notes: "     Fa#",
          text: "a non dubitare di Te!",
        },
        {
          notes: "   Si-         La       Si-",
          text: "Signore, aiutami, Signore",
        },
        {
          notes: "   Sol                           Fa#",
          text: "a non dubitare mai del tuo amore.",
        },
        {
          notes: "   La7              Re",
          text: "tua grazia, donando,",
        },
        {
          notes: "   Si-            Sol      Fa#",
          text: "lasciò, il mio cuore fiorito",
        },
        {
          notes: "    Mi-                Fa#",
          text: "di mille ansie d'amore",
          msg: "  (2 v.)",
        },
        {
          notes: "     La                 Sol",
          text: "Che altro più non posso",
        },
        {
          notes: "           Fa#",
          text: "che soffrire,",
        },
        {
          notes: "       Sol              Fa#",
          text: "che vivere in un gemito.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                                    La",
          text: "E IN QUESTA FIAMMA DEL TUO AMORE, OSCURA,",
        },
        {
          notes: "                                                  Re",
          text: "IN QUESTA TELA CHE AVVOLGE LA MIA ANIMA E LA SOFFOCA,",
        },
        {
          notes: "Sol                           Fa#",
          text: "SENTO IL MIO CUOR CHE SI DISLOGA,",
        },
        {
          notes: "Sol                  Fa#",
          text: "CHE SI APRE, CHE SI VERSA,",
        },
        {
          notes: "Sol                                Fa#",
          text: "COME ACQUA CHE INONDA IL TUO GIARDINO.",
        },
        {
          notes: "Si-         La       Si-",
          text: "SIGNORE, AIUTAMI, SIGNORE,",
          msg: "  (2 v.)",
        },
        {
          notes: "  Fa#",
          text: "A NON DUBITARE DI TE!",
        },
        {
          notes: "Si-         La       Si-",
          text: "SIGNORE, AIUTAMI, SIGNORE",
        },
        {
          notes: "Sol                          Fa#",
          text: "A NON DUBITARE MAI DEL TUO AMORE.",
        },
      ],
    },
  ],
  subtitle: "",
};
