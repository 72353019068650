import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedici anima mia il Signore.",
  url_video: "https://www.youtube.com/embed/8Q2_H1GEP4g",
  barrè: "",
  color: "white",
  semantic: "neocat",
  altnotes: "Mi-",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                       Sol",
          text: "Benedici anima mia il Signore,",
        },
        {
          notes: "                           La-",
          text: "benedici anima mia il gran re",
        },
        {
          notes: "                   Sol",
          text: "perché sarà ricostruita",
        },
        {
          notes: "                   La-",
          text: "Gerusalemme, Gerusalemme.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 Sol",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                    Sol",
          text: "GERUSALEMME, RICOSTRUITA",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                       Sol",
          text: "Le tue porte saranno ricostruite",
        },
        {
          notes: "                    La-",
          text: "di zaffiro e di smeraldo",
        },
        {
          notes: "                        Sol",
          text: "pietre preziose le tue mura,",
        },
        {
          notes: "                     La-",
          text: "le tue torri di oro puro.",
        },
        {
          notes: "                        Sol",
          text: "Le tue piazze sono di rubino,",
        },
        {
          notes: "                         La-",
          text: "le tue strade di oro di Ofir,",
        },
        {
          notes: "                        Sol",
          text: "nelle tue porte si esulterà,",
        },
        {
          notes: "                      La-",
          text: "nelle tue case si canterà!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 Sol",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                    Sol",
          text: "GERUSALEMME, RICOSTRUITA",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                         Sol",
          text: "La tua luce arriverà sino ai confini della terra,",
        },
        {
          notes: "                        La-",
          text: "verranno a te popoli numerosi,",
        },
        {
          notes: "                       Sol",
          text: "gli abitanti delle nazioni",
        },
        {
          notes: "                          La-",
          text: "verso la dimora del tuo nome.",
        },
        {
          notes: "                   Sol",
          text: "Le generazioni esulteranno,",
        },
        {
          notes: "             La-",
          text: "le genti canteranno",
        },
        {
          notes: "                            Sol",
          text: "e in essa il nome del tuo eletto",
        },
        {
          notes: "                            La-",
          text: "sarà per sempre, sarà per sempre.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 Sol",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                    Sol",
          text: "GERUSALEMME, RICOSTRUITA",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                    Sol",
          text: "Maledetti quelli che ti dicono parole crudeli;",
        },
        {
          notes: "                             La-",
          text: "maledetti quelli che ti distruggono,",
        },
        {
          notes: "                              Sol",
          text: "quelli che demoliscono le tue mura",
        },
        {
          notes: "                              La-",
          text: "e abbattono per terra le tue torri.",
        },
        {
          notes: "       Sol                    La-",
          text: "Ma benedetti, benedetti per sempre,",
        },
        {
          notes: "                      Sol",
          text: "coloro che ti ricostruiscono,",
        },
        {
          notes: "                    La-",
          text: "coloro che ti riedificano,",
        },
        {
          notes: "                    Sol",
          text: "beati coloro che ti amano,",
        },
        {
          notes: "                                 La-",
          text: "coloro che gioiscono per la tua pace.",
        },
        {
          notes: "                             Sol",
          text: "Perché nelle tue porte si esulterà,",
        },
        {
          notes: "                     La-",
          text: "nelle tue case si canterà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                    Sol",
          text: "NELLE TUE PORTE SI ESULTERÀ,",
        },
        {
          notes: "                     La-",
          text: "NELLE TUE CASE SI CANTERÀ.",
        },
        {
          notes: "                 Sol",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME, GERUSALEMME",
        },
        {
          notes: "                    Sol",
          text: "GERUSALEMME, RICOSTRUITA",
        },
        {
          notes: "                  La-",
          text: "GERUSALEMME PER SEMPRE.",
        },
        {
          notes: "    La-       Sol                 La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, ALLELUJA,",
        },
        {
          notes: "              Sol                 La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "(Tb 13)",
};
