import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Esultate giusti nel Signore.",
  url_video: "https://www.youtube.com/embed/FEWSs2c5aG4",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     Mi-",
          text: "ESULTATE GIUSTI NEL SIGNORE,",
        },
        {
          notes: "   Do                  Sol",
          text: "AI SANTI SI ADDICE LA LODE.",
        },
        {
          notes: "Sol                       Mi-",
          text: "LODATE IL SIGNORE CON LA CETRA,",
        },
        {
          notes: "    Do                             Sol",
          text: "CON L'ARPA A DIECI CORDE A LUI CANTATE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-                          Si7",
          text: "Cantate al Signore un canto nuovo,",
        },
        {
          notes: "   La-                          Si7",
          text: "suonate la cetra con arte e acclamate;",
        },
        {
          notes: "       Do",
          text: "poiché retta è la parola del Signore,",
        },
        {
          notes: "  Re            Si7",
          text: "verità ogni sua opera.",
        },
        {
          notes: "   Do",
          text: "In Lui sono giustizia e diritto,",
        },
        {
          notes: "        Re               Si7",
          text: "del suo amore è piena la terra.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     Mi-",
          text: "ESULTATE GIUSTI NEL SIGNORE,",
        },
        {
          notes: "   Do                  Sol",
          text: "AI SANTI SI ADDICE LA LODE.",
        },
        {
          notes: "Sol                       Mi-",
          text: "LODATE IL SIGNORE CON LA CETRA,",
        },
        {
          notes: "    Do                             Sol",
          text: "CON L'ARPA A DIECI CORDE A LUI CANTATE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-                           Si7",
          text: "La parola del Signore ha fatto i cieli,",
        },
        {
          notes: "   La-                              Si7",
          text: "il soffio della sua bocca le sue schiere.",
        },
        {
          notes: "   Do",
          text: "Il Signore annulla i disegni delle nazioni,",
        },
        {
          notes: "      Re                  Si7",
          text: "rende vani i progetti dei popoli.",
        },
        {
          notes: "       Do",
          text: "Ma il piano del Signore,",
        },
        {
          notes: "    Re",
          text: "il piano del Signore",
        },
        {
          notes: "             Si7",
          text: "sussiste per sempre.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     Mi-",
          text: "ESULTATE GIUSTI NEL SIGNORE,",
        },
        {
          notes: "   Do                  Sol",
          text: "AI SANTI SI ADDICE LA LODE.",
        },
        {
          notes: "Sol                       Mi-",
          text: "LODATE IL SIGNORE CON LA CETRA,",
        },
        {
          notes: "    Do                             Sol",
          text: "CON L'ARPA A DIECI CORDE A LUI CANTATE.",
        },
      ],
    },
  ],
  subtitle: "Sal 32(33)",
};
