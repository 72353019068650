import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il Signore annuncia una notizia.",
  url_video: "https://www.youtube.com/embed/Dw5dFICPIRo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                       Mi-",
          text: "IL SIGNORE ANNUNCIA UNA NOTIZIA,",
        },
        {
          notes: "La-          Mi-",
          text: "I MESSAGGERI SONO UN ESERCITO IMMENSO;",
        },
        {
          notes: "La-         Mi-",
          text: "I RE NEMICI FUGGONO, FUGGONO.",
        },
        {
          notes: "Sol                   Si-           Mi-",
          text: "LA BELLA DELLA CASA SPARTISCE IL BOTTINO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol",
          text: "E mentre voi dormite",
        },
        {
          notes: "Si-",
          text: "tra gli steccati dell'ovile,",
        },
        {
          notes: "   La-",
          text: "si coprono d'argento",
        },
        {
          notes: "                Mi-",
          text: "le ali della colomba,",
        },
        {
          notes: "       La-               Mi-",
          text: "le sue piume di riflessi d'oro.",
        },
        {
          notes: "      Sol",
          text: "E con lei cade la neve",
        },
        {
          notes: "         Si-",
          text: "sopra il monte,",
        },
        {
          notes: "         La        Mi-",
          text: "sopra il monte ombroso.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                       Mi-",
          text: "IL SIGNORE ANNUNCIA UNA NOTIZIA,",
        },
        {
          notes: "La-          Mi-",
          text: "I MESSAGGERI SONO UN ESERCITO IMMENSO;",
        },
        {
          notes: "La-         Mi-",
          text: "I RE NEMICI FUGGONO, FUGGONO.",
        },
        {
          notes: "Sol                   Si-           Mi-",
          text: "LA BELLA DELLA CASA SPARTISCE IL BOTTINO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol",
          text: "Cantate inni al Signore,",
        },
        {
          notes: "Si-",
          text: "cantate inni al Signore.",
        },
        {
          notes: "La-",
          text: "Egli cavalca nei cieli,",
        },
        {
          notes: "            Mi-",
          text: "nei cieli eterni.",
        },
        {
          notes: "Sol",
          text: "Ecco che tuona la sua voce,",
        },
        {
          notes: "       Si-",
          text: "la sua voce",
        },
        {
          notes: "       La      Mi-",
          text: "la sua voce potente!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                       Mi-",
          text: "IL SIGNORE ANNUNCIA UNA NOTIZIA,",
        },
        {
          notes: "La-          Mi-",
          text: "I MESSAGGERI SONO UN ESERCITO IMMENSO;",
        },
        {
          notes: "La-         Mi-",
          text: "I RE NEMICI FUGGONO, FUGGONO.",
        },
        {
          notes: "Sol                   Si-           Mi-",
          text: "LA BELLA DELLA CASA SPARTISCE IL BOTTINO.",
        },
      ],
    },
  ],
  subtitle: "Sal 67(68) vv 12-16.33.34",
};
