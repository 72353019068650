import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Verso te, o città santa.",
  url_video: "https://www.youtube.com/embed/KvmySOWtUR0",
  barrè: "",
  color: "white",
  semantic: "neocat",
  altnotes: "Mi-",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Mi",
          text: "VERSO TE, O CITTÀ SANTA,",
        },
        {
          notes: "    Mi7                 La-",
          text: "VERSO TE, TERRA DEL SALVATOR",
        },
        {
          notes: "    Sol         Fa",
          text: "PELLEGRINI, CAMMINANDO",
        },
        {
          notes: "              Mi",
          text: "NOI VENIAMO A TE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-",
          text: "Siamo il tuo santo popolo",
        },
        {
          notes: "                 Sol",
          text: "che oggi marcia unito",
        },
        {
          notes: "                     Fa",
          text: "tu vieni in mezzo a noi",
        },
        {
          notes: "                   Mi",
          text: "il tuo amor ci guiderà.",
        },
        {
          notes: "                 La-",
          text: "Tu sei la nostra via,",
        },
        {
          notes: "                Sol",
          text: "tu sei nostra speranza,",
        },
        {
          notes: "                 Fa",
          text: "fratello tu dei poveri,",
        },
        {
          notes: "            Mi",
          text: "Amen, Alleluja!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Mi",
          text: "VERSO TE, O CITTÀ SANTA,",
        },
        {
          notes: "    Mi7                 La-",
          text: "VERSO TE, TERRA DEL SALVATOR",
        },
        {
          notes: "    Sol         Fa",
          text: "PELLEGRINI, CAMMINANDO",
        },
        {
          notes: "              Mi",
          text: "NOI VENIAMO A TE.",
        },
      ],
    },
  ],
  subtitle: "",
};
