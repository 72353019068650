import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Don_Raffaé.",
  title: "Don Raffaé.",
  url_video: "https://www.youtube.com/embed/yp_CvmOvLoQ",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Io mi chiamo Pasquale Cafiero e son ",
        },
        {
          notes: "         Mi              La-",
          text: "brigadier del carcere oine'",
        },
        {
          notes: "La-",
          text: "Io mi chiamo Cafiero Pasquale, sto a ",
        },
        {
          notes: "         Mi                La-",
          text: "Poggio Reale dal cinquantatre'",
        },
        {
          notes: "        Re-          La-         Re-",
          text: "E al centesimo catenaccio alla sera ",
        },
        {
          notes: "               La-",
          text: "mi sento uno straccio",
        },
        {
          notes: "     Sib                      La-",
          text: "Per fortuna che al braccio speciale ",
        },
        {
          notes: "               Si7                  Mi",
          text: "c'e' un uomo geniale che parla con me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "         La-",
          text: "Tutto il giorno con quattro infamoni, ",
        },
        {
          notes: "             Mi                  La-",
          text: "briganti, papponi, cornuti e lacche'",
        },
        {
          notes: "       La-",
          text: "Tutte l'ore co' 'sta fetenzia che ",
        },
        {
          notes: "         Mi                     La-",
          text: "sputa minacce e s'a piglia co' me",
        },
        {
          notes: "        Re-              La-",
          text: "Ma alla fine m'assetto papale, mi ",
        },
        {
          notes: "  Re-                       La-",
          text: "sbottono e mi leggo 'o giornale",
        },
        {
          notes: "       Sib                La-",
          text: "Mi consiglio con don Raffae' mi spiega ",
        },
        {
          notes: "     Mi                    La-",
          text: "che penso e bevimmo 'o cafe'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol              Do              Sol",
          text: "A che bello 'o cafe', pure in carcere ",
        },
        {
          notes: "          Do",
          text: "'o sanno fa",
        },
        {
          notes: "       Re-              La-",
          text: "Co' a ricetta che a Ciccirinella, ",
        },
        {
          notes: "              Mi                La-",
          text: "compagno di cella, c'ha dato mamma'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "Prima pagina venti notizie, ventuno ",
        },
        {
          notes: "       Mi                   La-",
          text: "ingiustizie e lo stato che fa",
        },
        {
          notes: "       La-",
          text: "Si costerna, s'indigna, s'impegna poi ",
        },
        {
          notes: "          Mi                    La-",
          text: "getta la spugna con gran dignita'",
        },
        {
          notes: "        Re-                   La-",
          text: "Mi scervello e mi asciugo la fronte, ",
        },
        {
          notes: "       Re-                   La-",
          text: "per fortuna c'e' chi mi risponde",
        },
        {
          notes: "       Sib                   La-",
          text: "A quell'uomo sceltissimo e immenso, io ",
        },
        {
          notes: "           Si7              Mi",
          text: "chiedo consenso a don Raffae'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "Un galantuomo che tiene sei figli ha ",
        },
        {
          notes: "             Mi                 La-",
          text: "chiesto una casa e ci danno consigli",
        },
        {
          notes: "              La-",
          text: "Mentre 'o assessore che Dio lo perdoni ",
        },
        {
          notes: "                 Mi                La-",
          text: "'ndrento 'a roulotte ci tiene i visoni",
        },
        {
          notes: "        Re-                   La-",
          text: "Voi vi basta una mossa, una voce, c'a ",
        },
        {
          notes: "      Re-                   La-",
          text: "'sto cristo ci levano 'a croce",
        },
        {
          notes: "       Sib                   La-",
          text: "Con rispetto s'e' fatto le tre, ",
        },
        {
          notes: "               Mi                La-",
          text: "volite 'a spremuta o volite 'o cafe'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol              Do              Sol",
          text: "A che bello 'o cafe', pure in carcere ",
        },
        {
          notes: "          Do",
          text: "'o sanno fa",
        },
        {
          notes: "       Re-              La-",
          text: "Co' a ricetta che a Ciccirinella, ",
        },
        {
          notes: "              Mi                La-",
          text: "compagno di cella, c'ha dato mamma'",
        },
        {
          notes: "Sol              Do              Sol",
          text: "A che bello 'o cafe', pure in carcere ",
        },
        {
          notes: "          Do",
          text: "'o sanno fa",
        },
        {
          notes: "       Re-              La-",
          text: "Co' a ricetta che a Ciccirinella, ",
        },
        {
          notes: "              Mi                La-",
          text: "compagno di cella, preciso a mamma'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "Qui ci sta l'inflazione, la svalutazione ",
        },
        {
          notes: "      Mi                     La-",
          text: "e la borsa ce l'ha chi ce l'ha",
        },
        {
          notes: "       La-",
          text: "Io non tengo compendio che chillo ",
        },
        {
          notes: "                Mi                 La-",
          text: "stipendio e un ambo se sogno 'a papa'",
        },
        {
          notes: "        Re-                La-",
          text: "Aggiungete mia figlia Innocenza, co' ",
        },
        {
          notes: "    Re-                   La-",
          text: "marito non tiene pazienza",
        },
        {
          notes: "        Sib                  La-",
          text: "Io non chiedo la grazia pe' me, vi ",
        },
        {
          notes: "           Si7                Mi",
          text: "faccio la barba o la fate da se'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "Voi tenete un cappotto cammello che ",
        },
        {
          notes: "           Mi                     La-",
          text: "al maxiprocesso eravate 'o chiu' bello",
        },
        {
          notes: "       La-",
          text: "Un vestito gessato marrone, cosi' ci ",
        },
        {
          notes: "          Mi             La-",
          text: "e' sembrato alla televisione",
        },
        {
          notes: "         Re-               La-",
          text: "Pe' 'ste nozze vi prego eccellenza ",
        },
        {
          notes: "       Re-                 La-",
          text: "mi prestasse per fare presenza",
        },
        {
          notes: "        Sib                   La-",
          text: "Io gia' tengo le scarpe e 'o gilet, ",
        },
        {
          notes: "             Mi                 La-",
          text: "gradite 'o campari o volite 'o cafe'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol              Do              Sol",
          text: "A che bello 'o cafe', pure in carcere ",
        },
        {
          notes: "          Do",
          text: "'o sanno fa",
        },
        {
          notes: "       Re-              La-",
          text: "Co' a ricetta che a Ciccirinella, ",
        },
        {
          notes: "              Mi                La-",
          text: "compagno di cella, c'ha dato mamma'",
        },
        {
          notes: "Sol              Do              Sol",
          text: "A che bello 'o cafe', pure in carcere ",
        },
        {
          notes: "          Do",
          text: "'o sanno fa",
        },
        {
          notes: "       Re-              La-",
          text: "Co' a ricetta che a Ciccirinella, ",
        },
        {
          notes: "              Mi                La-",
          text: "compagno di cella, preciso a mamma'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "Qui non c'e' piu' decoro, le carceri ",
        },
        {
          notes: "           Mi                    La-",
          text: "d'oro ma chi le ha mai viste chissa'",
        },
        {
          notes: "       La-",
          text: "Chiste so' fatiscenti, pe' chisto i ",
        },
        {
          notes: "            Mi             La-",
          text: "fetenti se tengono l'immunita'",
        },
        {
          notes: "        Re-                La-",
          text: "Don Raffae' voi politicamente ve ",
        },
        {
          notes: "      Re-              La-",
          text: "lo giuro sarebbe 'no santo",
        },
        {
          notes: "        Sib                La-",
          text: "Ma ca dinto voi state a paga' e ",
        },
        {
          notes: "            Si7                 Mi",
          text: "fora chiss'atre se stanno a spassa'.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     La-",
          text: "A proposito tengo 'no frate che da ",
        },
        {
          notes: "           Mi             La-",
          text: "quindici anni sta disoccupato",
        },
        {
          notes: "         La-",
          text: "Chill'ha fatto quaranta concorsi, ",
        },
        {
          notes: "            Mi                 La-",
          text: "novanta domande e duecento ricorsi",
        },
        {
          notes: "        Re-                La-",
          text: "Voi che date conforto e lavoro, ",
        },
        {
          notes: "       Re-              La-",
          text: "eminenza vi bacio, v'imploro",
        },
        {
          notes: "        Sib                  La-",
          text: "Chillo duorme co' mamma e co' me, ",
        },
        {
          notes: "            Mi                 La-",
          text: "che crema d'Arabia ch'e' chisto cafe'.",
        },
      ],
    },
  ],
  subtitle: "Fabrizio De André",
  sent_for_revision: false,
};
