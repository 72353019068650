import { useEffect, useRef, useState } from "react";
import "./SongBlockSpeeder.scss";
import StandardButton from "../StandardButton/StandardButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export interface ISongBlockSpeederProps {
  songId: string;
  index: number;
}

const getSpeedometerSpeed = (songId: string, index: number) => {
  let speedometers: string =
    localStorage.getItem("song-custom-speedometers") ?? "{}";
  let speedometers_: any = JSON.parse(speedometers);
  if (speedometers_[songId] !== undefined) {
    return speedometers_[songId][index] ?? 0;
  }
  return 0;
};

const SongBlockSpeeder = (props: ISongBlockSpeederProps) => {
  const ref = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const [id, setId] = useState<string>("");

  const [scrollSpeed, setScroolSpeed] = useState<number>(0);

  const saveEdit = () => {
    if (index > 0) {
      let speedometers: string =
        localStorage.getItem("song-custom-speedometers") ?? "{}";
      let speedometers_: any = JSON.parse(speedometers);
      if (speedometers_[id] === undefined) {
        speedometers_[id] = {};
      }
      speedometers_[id][index] = scrollSpeed;
      localStorage.setItem(
        "song-custom-speedometers",
        JSON.stringify(speedometers_)
      );
    }
  };

  useEffect(() => {
    saveEdit();
  }, [scrollSpeed]);

  const updateSyncUp = () => {
    if (ref && ref.current) {
      let speed = ref.current.id.split("_")[1];
      let father = ref.current.parentNode;
      let rect = father.getBoundingClientRect();

      if (rect.top < 300) {
        father.parentNode.parentNode.style.background =
          "rgb(220 241 220 / 36%)";
        father.parentNode.parentNode.style.border =
          "2px solid rgb(15 209 15 / 81%)";
        (window as any)["dynamic-speed-fixer"] = +speed;
      } else {
        father.parentNode.parentNode.style.background = "";
        father.parentNode.parentNode.style.border = "";
      }
    }
  };

  useEffect(() => {
    if (index > 0) {
      setScroolSpeed(getSpeedometerSpeed(id, index));
    }
  }, [id, index]);

  useEffect(() => {
    setIndex(props.index);
    setId(props.songId);

    window.document.addEventListener(
      "dynamic-speedometers-syncup",
      updateSyncUp
    );

    return () => {
      window.document.removeEventListener(
        "dynamic-speedometers-syncup",
        updateSyncUp
      );
    };
  }, []);

  return (
    <div ref={ref} id={index + "_" + scrollSpeed}>
      {id && index > 0 && (
        <div className="block-speedometer-wrap">
          <StandardButton
            label=""
            disabled={scrollSpeed === -2}
            onClick={() => {
              setScroolSpeed(scrollSpeed - 1);
            }}
            iconButtonIcon={<ArrowUpwardIcon />}
          />
          <div className="scroll-command-speed-wrap" style={{ margin: "0" }}>
            {scrollSpeed}
          </div>
          <StandardButton
            label=""
            disabled={scrollSpeed === 2}
            onClick={() => {
              setScroolSpeed(scrollSpeed + 1);
            }}
            iconButtonIcon={<ArrowDownwardIcon />}
          />
        </div>
      )}
    </div>
  );
};

export default SongBlockSpeeder;
