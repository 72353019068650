import PageLayout from "../../Components/PageLayout/PageLayout";
import "./NotFoundScreen.scss";

export const getNotFoundContent = () => {
  return (
    <div className="not-found-main-wrap">
      <div className="not-found-code">404</div>
      <div className="not-found-face-wrap">
        <div className="not-found-face">:(</div>
      </div>
      <div className="not-found-label">
        Questa pagina non esiste, oppure è stata rimossa.
      </div>
    </div>
  );
};

const NotFoundScreen = () => {
  return (
    <PageLayout
      backButton={"home"}
      title={"Non trovo la pagina"}
      content={getNotFoundContent()}
    />
  );
};

export default NotFoundScreen;
