import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preghiera eucaristica II (Parte I).",
  url_video: "",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "Il Signore sia con voi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol          La-",
          text: "E CON IL TUO SPIRITO",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Fa               Mi",
          text: "In alto i nostri cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Mi",
          text: "SONO RIVOLTI AL SIGNORE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7                               La-",
          text: "Rendiamo grazie al Signore nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol            La-",
          text: "È COSA BUONA E GIUSTA.",
        },
        {
          notes: "Sol            La-",
          text: "È COSA BUONA E GIUSTA.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Sol",
          text: "È veramente cosa buona e giusta,",
        },
        {
          notes: "                            La-",
          text: "nostro dovere e fonte di salvezza,",
        },
        {
          notes: "        Do                      La-",
          text: "rendere grazie sempre e in ogni luogo",
        },
        {
          notes: "      Sol",
          text: "a te, Padre santo,",
        },
        {
          notes: "                                  La-",
          text: "per Gesù Cristo, tuo dilettissimo Figlio.",
        },
        {
          notes: "                               Sol",
          text: "Per mezzo di lui, tua Parola vivente,",
        },
        {
          notes: "                    La-",
          text: "hai creato tutte le cose",
        },
        {
          notes: "                   Do                   La-",
          text: "e lo hai mandato a noi salvatore e redentore,",
        },
        {
          notes: "      Sol",
          text: "fatto uomo per opera dello Spirito Santo",
        },
        {
          notes: "                     La-",
          text: "e nato dalla Vergine Maria.",
        },
        {
          notes: "Fa                       Mi",
          text: "Per compiere la tua volontà",
        },
        {
          notes: "Fa                      Mi",
          text: "e acquistarti un popolo santo,",
        },
        {
          notes: "La-                         Sol",
          text: "egli stese le braccia sulla croce,",
        },
        {
          notes: "                     Fa",
          text: "morendo distrusse la morte",
        },
        {
          notes: "                     Mi",
          text: "e proclamò la risurrezione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                         Sol",
          text: "EGLI STESE LE BRACCIA SULLA CROCE,",
        },
        {
          notes: "                     Fa",
          text: "MORENDO DISTRUSSE LA MORTE",
        },
        {
          notes: "                     Mi",
          text: "E PROCLAMÒ LA RISURREZIONE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Sol",
          text: "Per questo mistero di salvezza",
        },
        {
          notes: "                       La-",
          text: "uniti agli angeli e ai santi",
        },
        {
          notes: "                    Sol         La-",
          text: "cantiamo a una sola voce la tua gloria",
        },
      ],
    },
    {
      speaker: "\n\n     Proseguire col “SANTO“ del tempo liturgico\n",
      mode: "red-utility-message",
      lines: [],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "(Recitando): Padre veramente santo",
        },
        {
          notes: "",
          text: "fonte di ogni santità",
        },
        {
          notes: "",
          text: "santifica questo doni",
        },
        {
          notes: "",
          text: "con l'effusione del tuo Spirito,",
        },
        {
          notes: "",
          text: "perché diventino per noi",
        },
        {
          notes: "",
          text: "il corpo e il sangue di Gesù Cristo",
        },
        {
          notes: "",
          text: "nostro Signore.",
        },
      ],
    },
  ],
  subtitle: "",
};
