import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Dà lode al Signore.",
  url_video: "https://www.youtube.com/embed/TaQRxqrHnFs",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re    Mi-  Si- Re",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "Sol   Re  Do     Si7",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa         Mi         La-",
          text: "Dà lode al Signore, anima mia",
        },
        {
          notes: "  Re-               Mi",
          text: "tu cuore mio al suo santo nome;",
        },
        {
          notes: "                La-",
          text: "tutta la vita loderò il Signore",
        },
        {
          notes: " Fa           Mi         La-",
          text: "inneggerò al mio Dio finché io vivrò.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re    Mi-  Si- Re",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "Sol   Re  Do     Si7",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa         Mi         La-",
          text: "non pongo fiducia nei potenti,",
        },
        {
          notes: "  Re-               Mi",
          text: "nell'uomo in cui non c'è salvezza:",
        },
        {
          notes: "                  La-",
          text: "in polvere ritorna quando muore,",
        },
        {
          notes: " Fa             Mi           La-",
          text: "svaniscono in quei giorni i suoi progetti.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re    Mi-  Si- Re",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "Sol   Re  Do     Si7",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Mi          La-",
          text: "Ma in te, Signore, che apri gli occhi ai ciechi",
        },
        {
          notes: "  Re-               Mi",
          text: "che rendi giustizia agli oppressi",
        },
        {
          notes: "                    La-",
          text: "in te che doni il pane agli affamati",
        },
        {
          notes: " Fa          Mi         La-",
          text: "i prigionieri mandi in libertà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re    Mi-  Si- Re",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "Sol   Re  Do     Si7",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Mi          La-",
          text: "Felice chi spera nel Signore,",
        },
        {
          notes: "  Re-               Mi",
          text: "nel Dio di Abramo pone fede.",
        },
        {
          notes: "                  La-",
          text: "Regna il Signore nell'eterno,",
        },
        {
          notes: " Fa         Mi         La-",
          text: "il Dio di Sion di età in età.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re    Mi-  Si- Re",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "Sol   Re  Do     Si7",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "Sal 145(146)",
};
