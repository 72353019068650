import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Mi indicherai il sentiero della vita.",
  url_video: "https://www.youtube.com/embed/50gYvsr0h7A",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                              Fa  Re-",
          text: "Mi indicherai il sentiero della vita",
        },
        {
          notes: "                                La-",
          text: "mi indicherai il sentiero della vita",
        },
        {
          notes: "     Mi",
          text: "gioia piena nella tua presenza",
        },
        {
          notes: "  Re-                         Mi",
          text: "dolcezza senza fine alla tua destra.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-          Fa      Re-     La-",
          text: "Proteggimi o Dio: in Te mi rifugio.",
        },
        {
          notes: "           Fa  Re-                La-",
          text: "Ho detto a Dio: “Sei Tu il mio Signore,",
        },
        {
          notes: "         Mi",
          text: "Senza di Te, non ho alcun bene“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                              Fa  Re-",
          text: "Mi indicherai il sentiero della vita",
        },
        {
          notes: "                                La-",
          text: "mi indicherai il sentiero della vita",
        },
        {
          notes: "     Mi",
          text: "gioia piena nella tua presenza",
        },
        {
          notes: "  Re-                         Mi",
          text: "dolcezza senza fine alla tua destra.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                      Fa",
          text: "Il Signore è mia parte di eredità e mio calice:",
        },
        {
          notes: "Re-                     La-",
          text: "nelle tue mani è la mia vita.",
        },
        {
          notes: "La-                                     Fa",
          text: "Per me la sorte è caduta su luoghi deliziosi,",
        },
        {
          notes: "Re-                  Mi",
          text: "la mia eredità, è magnifica.",
        },
        {
          notes: "La-                                    Fa",
          text: "Benedico il Signore che mi ha dato consiglio;",
        },
        {
          notes: "Re-",
          text: "Anche di notte il mio cuore istruisce.",
        },
        {
          notes: "          Mi",
          text: "Io pongo sempre innanzi a me il Signore,",
        },
        {
          notes: "Re-                                 Mi",
          text: "sta alla mia destra, non posso vacillare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                              Fa  Re-",
          text: "Mi indicherai il sentiero della vita",
        },
        {
          notes: "                                La-",
          text: "mi indicherai il sentiero della vita",
        },
        {
          notes: "     Mi",
          text: "gioia piena nella tua presenza",
        },
        {
          notes: "  Re-                         Mi",
          text: "dolcezza senza fine alla tua destra.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                        Fa",
          text: "Di questo gioisce il mio cuore,",
        },
        {
          notes: "Re-           La-     Mi",
          text: "esulta la mia anima; anche il mio corpo riposa al sicuro",
        },
        {
          notes: "Re-                                        Mi",
          text: "perché non abbandonerai la mia vita nel sepolcro,",
        },
        {
          notes: "Re-                                        Mi    Re-      Mi",
          text: "né lascerai che il tuo Santo veda la corruzione, la corruzione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                              Fa  Re-",
          text: "Mi indicherai il sentiero della vita",
        },
        {
          notes: "                                La-",
          text: "mi indicherai il sentiero della vita",
        },
        {
          notes: "     Mi",
          text: "gioia piena nella tua presenza",
        },
        {
          notes: "  Re-                         Mi",
          text: "dolcezza senza fine alla tua destra.",
        },
      ],
    },
  ],
  subtitle: "Sal 15(16)",
};
