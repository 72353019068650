import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Così parla l'amen.",
  url_video: "https://www.youtube.com/embed/N6xLoGe4rEY",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "COSÌ PARLA L'AMEN",
        },
        {
          notes: "    Do              Re7",
          text: "TESTIMONE FEDELE E VERACE",
        },
        {
          notes: "",
          text: "IL PRINCIPIO",
        },
        {
          notes: "                  Mi-",
          text: "DELLE CREATURE DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Conosco la tua condotta:",
        },
        {
          notes: "   Sol",
          text: "non sei né freddo né caldo.",
        },
        {
          notes: "                       Re",
          text: "Magari fossi freddo o caldo",
        },
        {
          notes: "    Si7",
          text: "e non tiepido,",
        },
        {
          notes: "                                   Mi-",
          text: "perché sto per vomitarti dalla mia bocca.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "COSÌ PARLA L'AMEN",
        },
        {
          notes: "    Do              Re7",
          text: "TESTIMONE FEDELE E VERACE",
        },
        {
          notes: "",
          text: "IL PRINCIPIO",
        },
        {
          notes: "                  Mi-",
          text: "DELLE CREATURE DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Dici: io sono ricco, niente mi manca.",
        },
        {
          notes: "       Sol",
          text: "Non ti rendi conto",
        },
        {
          notes: "                Re7",
          text: "che sei un disgraziato",
        },
        {
          notes: "            Si7",
          text: "degno di compassione",
        },
        {
          notes: "                 Mi-",
          text: "povero, cieco e nudo.",
        },
        {
          notes: " Sol",
          text: "Ti consiglio di comprare da me",
        },
        {
          notes: "                       Re7",
          text: "dell'oro raffinato col fuoco",
        },
        {
          notes: "              Si7",
          text: "delle vesti bianche",
        },
        {
          notes: "",
          text: "del collirio",
        },
        {
          notes: "                 Mi-",
          text: "perché tu possa vedere.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "COSÌ PARLA L'AMEN",
        },
        {
          notes: "    Do              Re7",
          text: "TESTIMONE FEDELE E VERACE",
        },
        {
          notes: "",
          text: "IL PRINCIPIO",
        },
        {
          notes: "                  Mi-",
          text: "DELLE CREATURE DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi-",
          text: "Quelli che io amo correggo",
        },
        {
          notes: "             Sol",
          text: "abbi dunque zelo",
        },
        {
          notes: "      Re",
          text: "e ravvediti",
        },
        {
          notes: "               Si7",
          text: "ascolta la mia voce",
        },
        {
          notes: "                Mi-",
          text: "ascolta la mia voce.",
        },
        {
          notes: "",
          text: "Ecco: io sto alla porta e busso",
        },
        {
          notes: "                  Sol",
          text: "se uno ode la mia voce",
        },
        {
          notes: "            Re7",
          text: "e mi apre la porta,",
        },
        {
          notes: "                  Si7",
          text: "entrerò nella sua casa",
        },
        {
          notes: "",
          text: "e cenerò con lui",
        },
        {
          notes: "           Mi-",
          text: "ed egli con me.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "COSÌ PARLA L'AMEN",
        },
        {
          notes: "    Do              Re7",
          text: "TESTIMONE FEDELE E VERACE",
        },
        {
          notes: "",
          text: "IL PRINCIPIO",
        },
        {
          notes: "                  Mi-",
          text: "DELLE CREATURE DI DIO.",
        },
      ],
    },
  ],
  subtitle: "(Ap 3, 14-22)",
};
