import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preghiera eucaristica II (Consacrazione e acclamazione).",
  url_video: "",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "(Recitando): Padre veramente santo",
        },
        {
          notes: "",
          text: "fonte di ogni santità",
        },
        {
          notes: "",
          text: "santifica questo doni",
        },
        {
          notes: "",
          text: "con l'effusione del tuo Spirito,",
        },
        {
          notes: "",
          text: "perché diventino per noi",
        },
        {
          notes: "",
          text: "il corpo e il sangue di Gesù Cristo",
        },
        {
          notes: "",
          text: "nostro Signore.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "            Re-",
          text: "(Cantando): Egli offrendosi liberamente",
        },
        {
          notes: "",
          text: "alla sua passione prese il pane,",
        },
        {
          notes: "",
          text: "elevando gli occhi a te, Padre, Padre,",
        },
        {
          notes: "Fa                    Sol-",
          text: "ti rese grazie, lo spezzò,",
        },
        {
          notes: "                             Re-",
          text: "lo diede ai suoi discepoli e disse:",
        },
        {
          notes: "                      La7",
          text: "PRENDETE E MANGIATENE TUTTI:",
        },
        {
          notes: "Re-",
          text: "QUESTO È IL MIO CORPO",
        },
        {
          notes: "La7                       Re-",
          text: "OFFERTO IN SACRIFICIO PER VOI.",
        },
        {
          notes: "  Re-",
          text: "Dopo la cena, allo stesso modo,",
        },
        {
          notes: "",
          text: "prese il calice,",
        },
        {
          notes: "",
          text: "elevando gli occhi a te, Padre, Padre",
        },
        {
          notes: "Fa",
          text: "ti rese grazie,",
        },
        {
          notes: "                              Sol-",
          text: "lo diede ai suoi discepoli e disse:",
        },
        {
          notes: "                      Re-",
          text: "PRENDETE, E BEVETENE TUTTI:",
        },
        {
          notes: "                            La7",
          text: "QUESTO È IL CALICE DEL MIO SANGUE",
        },
        {
          notes: "        Re-",
          text: "PER LA NUOVA ED ETERNA ALLEANZA,",
        },
        {
          notes: "                      La7",
          text: "VERSATO PER VOI E PER TUTTI",
        },
        {
          notes: "        Re-",
          text: "IN REMISSIONE DEI PECCATI.",
        },
        {
          notes: "         La7       Re-",
          text: "FATE QUESTO IN MEMORIA DI ME.",
        },
        {
          notes: "Sol-",
          text: "Questo è il sacramento",
        },
        {
          notes: "              La7",
          text: "della nostra fede!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "ANNUNCIAMO LA TUA MORTE, SIGNORE,",
        },
        {
          notes: "Sol-",
          text: "PROCLAMIAMO LA TUA RISURREZIONE.",
        },
        {
          notes: "       La7       Sol-        La7",
          text: "MARANATHÀ! MARANATHÀ! MARANATHÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-",
          text: "Maranathà!",
        },
      ],
    },
  ],
  subtitle: "",
};
