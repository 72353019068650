import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Padre nostro.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Abba, Abba, Padre,",
        },
        {
          notes: "",
          text: "Padre nostro, che sei nei cieli,",
        },
        {
          notes: "Fa                      La-",
          text: "santificato sia il Tuo nome,",
        },
        {
          notes: "Sol-        La",
          text: "venga il tuo regno,",
        },
        {
          notes: "    Sib               La",
          text: "sia fatta la tua volontà,",
        },
        {
          notes: "        Sib            La",
          text: "come in cielo, così in terra.",
        },
        {
          notes: "Re-",
          text: "Dacci oggi il nostro pane quotidiano",
        },
        {
          notes: "            Fa            La-",
          text: "e rimetti a noi i nostri debiti,",
        },
        {
          notes: "     Sib                              La",
          text: "come noi li rimettiamo ai nostri debitori,",
        },
        {
          notes: "           Sib           La",
          text: "e non c'indurre in tentazione,",
        },
        {
          notes: "                 Re-",
          text: "ma liberaci dal male.",
        },
      ],
    },
    {
      speaker:
        "N.B: Viene cantato solo dalle comunità che hanno vissuto la tappa finale del Padre Nostro.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: " (Mt. 6,9-13)",
};
