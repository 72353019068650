import { useNavigate } from "react-router-dom";
import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import NavigateTable from "../../Components/NavigateTable/NavigateTable";
import PageLayout from "../../Components/PageLayout/PageLayout";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import "./GuideScreen.scss";

export interface GuidePages {
  title: string;
  target: string;
}

const GUIDE_PAGES: GuidePages[] = [
  {
    title: "Introduzione alla chitarra",
    target: "/guide_guitarintro",
  },
  {
    title: "Lo spartito interattivo",
    target: "/guide_interactivesong",
  },
  {
    title: "Schemi degli accordi",
    target: "/guide_chords",
  },
];

const GuideScreen = () => {
  const navigate = useNavigate();

  return (
    <PageLayout
      title={"Guida"}
      content={
        <div>
          Nella guida puoi trovare tutto ciò che devi sapere per usare al meglio
          l'App.
          <TitledPageSection
            title="Voci della guida"
            content={
              <div>
                <NavigateTable
                  selectedLine={(index: number) => {
                    navigate(GUIDE_PAGES[index].target);
                  }}
                  lines={GUIDE_PAGES.map((x: GuidePages, i: number) => {
                    return (
                      <div
                        className="guide-screen-voice"
                        key={i}
                        onClick={() => {
                          navigate(x.target);
                        }}
                      >
                        {x.title}
                      </div>
                    );
                  })}
                />
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default GuideScreen;
