import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Dajenù.",
  url_video: "https://www.youtube.com/embed/_K7L3T0Y3Sc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-                     Mi-",
          text: "DI QUANTI BENI CI HA COLMATO IL SIGNORE,",
        },
        {
          notes: "           La-                     Mi-",
          text: "DI QUANTI BENI CI HA COLMATO IL SIGNORE,",
        },
        {
          notes: "          Re            Do         Mi-",
          text: "DI QUANTI BENI CI HA COLMATO IL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    La-                              Si7",
          text: "Se Cristo ci avesse fatto uscire dall'Egitto,",
        },
        {
          notes: "                                     Mi-",
          text: "e non avesse fatto giustizia del Faraone.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "     Re                         Do",
          text: "Se avesse fatto giustizia del Faraone,",
        },
        {
          notes: "                    Si7               Mi-",
          text: "e non ci avesse liberato da tutti gli idoli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             La-                  Si7",
          text: "Se ci avesse liberato da tutti gli idoli,",
        },
        {
          notes: "                                  Mi-",
          text: "e non ci avesse dato le loro ricchezze.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             Re              Do",
          text: "Se ci avesse dato le loro ricchezze,",
        },
        {
          notes: "              Si7               Mi-",
          text: "e non avesse aperto il mare per noi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           La-              Si7",
          text: "Se avesse aperto il mare per noi,",
        },
        {
          notes: "                                         Mi-",
          text: "e non vi avesse affondato i nostri oppressori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "              Re                  Do",
          text: "Se avesse affondato i nostri oppressori,",
        },
        {
          notes: "                 Si7                   Mi-",
          text: "e non ci avesse dato un cammino nel deserto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             La-                   Si7",
          text: "Se ci avesse dato un cammino nel deserto,",
        },
        {
          notes: "                                           Mi-",
          text: "e non ci avesse nutrito con il pane della vita",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "               Re                     Do",
          text: "Se ci avesse nutrito con il pane della vita,",
        },
        {
          notes: "                 Si7                   Mi-",
          text: "e non ci avesse dato il giorno del Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             La-                  Si7",
          text: "Se ci avesse dato il giorno del Signore,",
        },
        {
          notes: "                                          Mi-",
          text: "e non avesse stretto con noi la nuova Alleanza.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                      Re             Do",
          text: "Se avesse stretto con noi la nuova alleanza,",
        },
        {
          notes: "                        Si7           Mi-",
          text: "e non ci avesse fatto entrare nella Chiesa.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "              La-                 Si7",
          text: "Se ci avesse fatto entrare nella Chiesa,",
        },
        {
          notes: "                                        Mi-",
          text: "e non avesse costruito in noi il suo tempio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             Re                   Do",
          text: "Se avesse costruito in noi il suo tempio,",
        },
        {
          notes: "                  Si7                    Mi-",
          text: "e non l'avesse riempito del suo Spirito Santo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Do                 Mi-",
          text: "QUESTO CI SAREBBE BASTATO, CI SAREBBE BASTATO.",
        },
        {
          notes: "   Re       Do      Mi-",
          text: "DAJENÙ, DAJENÙ, DAJENÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-          La-                    Mi-",
          text: "Tanto più dobbiamo ringraziare il Signore",
        },
        {
          notes: " Mi-          La-                   Mi-",
          text: "TANTO PIÙ DOBBIAMO RINGRAZIARE IL SIGNORE",
        },
        {
          notes: " Mi-          Re           Do        Mi-",
          text: "TANTO PIÙ DOBBIAMO RINGRAZIARE IL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           La-               Mi-",
          text: "Che ci ha fatto uscire dall'Egitto.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "        La-                    Mi-",
          text: "Che ha fatto giustizia del Faraone.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "              Re      Do        Mi-",
          text: "Che ci ha liberato da tutti i nemici.",
          msg: "  \t(L'assemblea ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           La-             Mi-",
          text: "Che ci ha dato le loro ricchezze.",
          msg: "  \t\t(L'assemblea ripete)",
        },
        {
          notes: "              La-                  Mi-",
          text: "Che ha aperto il mare, il mare per noi.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "          Re       Do        Mi-",
          text: "Che vi ha affondato i nostri oppressori.",
          msg: "  \t(L'assemblea ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-                  Mi-",
          text: "Che ci ha dato un cammino nel deserto.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "          La-                       Mi-",
          text: "Che ci ha nutrito con il pane della vita.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "         Re       Do        Mi-",
          text: "Che ci ha dato il giorno del Signore.",
          msg: "  \t\t(L'assemblea ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           La-                 Mi-",
          text: "Che ci ha donato una nuova alleanza.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "          La-                 Mi-",
          text: "Che ci ha fatto entrare nella Chiesa.",
          msg: "  \t(L'assemblea ripete)",
        },
        {
          notes: "            Re       Do        Mi-",
          text: "Che ha costruito in noi il suo tempio.",
          msg: "  (L'assemblea ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "            Re           Do",
          text: "E lo ha riempito del suo Spirito Santo,",
        },
        {
          notes: "        Si7        Mi-",
          text: "nel perdono dei peccati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              Re        Do         Mi-",
          text: "CRISTO NOSTRA PASQUA È RISORTO PER NOI.   (3 v.)",
        },
        {
          notes: "             Re     Do   Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!             (3 v.)",
        },
      ],
    },
  ],
  subtitle: "",
};
