import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Viene il Signore vestito di maestà.",
  url_video: "https://www.youtube.com/embed/3JQt1TuWE8w",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re-                           Sol-",
          text: "VIENE IL SIGNORE, VESTITO DI MAESTÀ",
        },
        {
          notes: "                                  Re-",
          text: "CINTO DI FORZA, SI AMMANTA DI SPLENDORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                                   Sol-",
          text: "Rende saldo il mondo, il mondo non vacilla,",
        },
        {
          notes: "                                   Re-",
          text: "saldo è il tuo trono, da sempre tu sei;",
        },
        {
          notes: "                              Sol-",
          text: "la santità si addice alla tua casa,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                              Re-",
          text: "LA SANTITÀ SI ADDICE ALLA TUA CASA!",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "Re-                           Sol-",
          text: "VIENE IL SIGNORE, VESTITO DI MAESTÀ",
        },
        {
          notes: "                                  Re-",
          text: "CINTO DI FORZA, SI AMMANTA DI SPLENDORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                    Sol-",
          text: "A colui che ci ama, che ci libera dai peccati,",
        },
        {
          notes: "                                     Re-",
          text: "che ha fatto di noi un regno di sacerdoti,",
        },
        {
          notes: "                       Sol-                 Re-",
          text: "a lui la gloria e la potenza nei secoli dei secoli",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                      Sol-                 Re-",
          text: "A LUI LA GLORIA E LA POTENZA NEI SECOLI DEI SECOLI!",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "Re-                           Sol-",
          text: "VIENE IL SIGNORE, VESTITO DI MAESTÀ",
        },
        {
          notes: "                                  Re-",
          text: "CINTO DI FORZA, SI AMMANTA DI SPLENDORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                         Sol-",
          text: "Ecco che viene, viene sulle nubi.",
        },
        {
          notes: "                           Re-",
          text: "ecco che viene e ognuno lo vedrà,",
        },
        {
          notes: "                                     Sol-",
          text: "anche quelli, anche quelli che lo trafissero,",
        },
        {
          notes: "                       Re-",
          text: "tutte le nazioni della terra!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re-                           Sol-",
          text: "VIENE IL SIGNORE, VESTITO DI MAESTÀ",
        },
        {
          notes: "                                  Re-",
          text: "CINTO DI FORZA, SI AMMANTA DI SPLENDORE",
        },
      ],
    },
  ],
  subtitle: "",
};
