import Accordion from "../../Components/Accordion/Accordion";
import Img from "../../Components/Img/Img";
import PageLayout from "../../Components/PageLayout/PageLayout";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import "./HowToDownloadScreen.scss";

const HowToDownloadScreen = () => {
  return (
    <PageLayout
      backButton
      title={"Come istallare l'App"}
      content={
        <div>
          In questa pagina sono dettagliati gli step per istallare la Web App
          sul tuo dispositivo.
          <TitledPageSection
            title={"Android"}
            content={
              <div>
                Per istallare l'app sul tuo SmartPhone o Tablet Android, segui i
                seguenti click.
                <Accordion
                  label={"Clicca per espandere"}
                  openStateLabel={"Clicca per chiudere"}
                  content={
                    <div className="how-to-download-centered-image-wrap">
                      <Img
                        className="how-to-download-centered-image"
                        alt={"Download on Android (Smart Phone)"}
                        src={"./Assets/other/android_install.png"}
                      />
                    </div>
                  }
                />
              </div>
            }
          />
          <TitledPageSection
            title={"iOS"}
            content={
              <div>
                Per istallare l'app sul tuo SmartPhone o Tablet Apple, segui i
                seguenti click.
                <Accordion
                  label={"Clicca per espandere"}
                  openStateLabel={"Clicca per chiudere"}
                  content={
                    <div className="how-to-download-centered-image-wrap">
                      <Img
                        className="how-to-download-centered-image"
                        alt={"Download on iOS (Smart Phone))"}
                        src={"./Assets/other/iphone_install.png"}
                      />
                    </div>
                  }
                />
              </div>
            }
          />
          <TitledPageSection
            title={"Chrome (Desktop)"}
            content={
              <div>
                Per istallare l'app sul tuo computer tramite Google Chrome,
                clicca nell'apposito bottone a destra della barra di ricerca.
                <Accordion
                  label={"Clicca per espandere"}
                  openStateLabel={"Clicca per chiudere"}
                  content={
                    <div className="how-to-download-centered-image-wrap">
                      <Img
                        className="how-to-download-centered-image"
                        alt={"Download on Chrome (Desktop)"}
                        src={"./Assets/other/chrome_install.png"}
                      />
                    </div>
                  }
                />
              </div>
            }
          />
          <TitledPageSection
            title={"Edge (Desktop)"}
            content={
              <div>
                Per istallare l'app sul tuo computer tramite Microsoft Edge,
                clicca nell'apposito bottone a destra della barra di ricerca.
                <Accordion
                  label={"Clicca per espandere"}
                  openStateLabel={"Clicca per chiudere"}
                  content={
                    <div className="how-to-download-centered-image-wrap">
                      <Img
                        className="how-to-download-centered-image"
                        alt={"Download on Edge (Desktop)"}
                        src={"./Assets/other/edge_install.png"}
                      />
                    </div>
                  }
                />
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default HowToDownloadScreen;
