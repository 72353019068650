import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lo spirito del Signore è sopra di me.",
  url_video: "https://www.youtube.com/embed/-g1Nz85KCR4",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Mi              La-",
          text: "Lo Spirito del Signore è sopra di me.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Mi              La-",
          text: "LO SPIRITO DEL SIGNORE È SOPRA DI ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             Sol",
          text: "Perciò mi ha unto",
        },
        {
          notes: "         Fa",
          text: "mi ha inviato",
        },
        {
          notes: "                    Mi",
          text: "per annunciare la salvezza;",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                     La-",
          text: "PER ANNUNCIARE LA SALVEZZA",
        },
        {
          notes: "                     Sol",
          text: "PER ANNUNCIARE LA SALVEZZA",
        },
        {
          notes: "    Fa                               Mi",
          text: "AI POVERI CHE HANNO SPEZZATO IL LORO CUOR.",
        },
        {
          notes: "La-              Mi              La-",
          text: "LO SPIRITO DEL SIGNORE È SOPRA DI ME.",
        },
        {
          notes: "La-              Mi              La-",
          text: "LO SPIRITO DEL SIGNORE È SOPRA DI ME.",
        },
        {
          notes: " La-         Sol",
          text: "PERCIÒ MI HA UNTO",
        },
        {
          notes: "         Fa",
          text: "MI HA INVIATO",
        },
        {
          notes: "                    Mi",
          text: "PER ANNUNCIARE LA SALVEZZA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                La-",
          text: "Perché i ciechi vedano",
        },
        {
          notes: "            Sol",
          text: "gli zoppi camminino",
        },
        {
          notes: "                 Fa",
          text: "i lebbrosi siano mondi,",
        },
        {
          notes: "                    Mi",
          text: "per annunciare la salvezza.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Mi               La-",
          text: "LO SPIRITO DEL SIGNORE È SOPRA DI ME.",
        },
        {
          notes: "La-             Mi               La-",
          text: "LO SPIRITO DEL SIGNORE È SOPRA DI ME.",
        },
        {
          notes: " La-         Sol",
          text: "PERCIÒ MI HA UNTO",
        },
        {
          notes: "         Fa",
          text: "MI HA INVIATO",
        },
        {
          notes: "                    Mi",
          text: "PER ANNUNCIARE LA SALVEZZA",
        },
      ],
    },
  ],
  subtitle: "(Lc. 4, 18-19)(Is. 61, 1-3)",
};
