import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import StandardButton from "../StandardButton/StandardButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import "./EditorBlockItem.scss";
import MessageBanner from "../MessageBanner/MessageBanner";
import NotesInput from "../NotesInput/NotesInput";

export interface EditorSongBlockLine {
  text: string;
  notes: string;
}

export interface EditorSongBlock {
  speaker: string;
  lines: EditorSongBlockLine[];
  useAsRefrain: boolean;
  copyRefrain: boolean;
  blockMessage: string;
  containsError: boolean;
}

export interface EditorBlockItemProps {
  emitBlock: (block: EditorSongBlock | null) => void;
  baseBlock: EditorSongBlock;
  disabled: boolean;
  index: number;
}

const EditorBlockItem = (props: EditorBlockItemProps) => {
  const [block, setBlock] = useState<EditorSongBlock | null>(null);

  useEffect(() => {
    setBlock(props.baseBlock);
  }, [props.baseBlock]);

  return (
    <div className="editor-block-item-wrap">
      {block && block.copyRefrain && (
        <div>
          <div className="editor-block-number">Blocco N. {props.index + 1}</div>
          <div className="editor-block-line-items">
            <div>
              In questo punto comparirà una copia del blocco impostato come
              ritornello.
            </div>
            <StandardButton
              disabled={props.disabled}
              onClick={() => {
                props.emitBlock(null);
              }}
              label=""
              iconButtonIcon={<DeleteIcon />}
            />
          </div>
        </div>
      )}
      {block && !block.copyRefrain && (
        <div className="editor-block-item-inputs">
          <div className="editor-block-number">Blocco N. {props.index + 1}</div>

          <div className="editor-block-line-items">
            <strong>Informazioni sul blocco dello spartito</strong>
            <StandardButton
              disabled={props.disabled}
              onClick={() => {
                props.emitBlock(null);
              }}
              label=""
              iconButtonIcon={<DeleteIcon />}
            />
          </div>
          <div className="editor-block-line-items">
            <div className="editor-block-select-wrap">
              <FormControl fullWidth>
                <InputLabel id="color-select-label">Voce che canta</InputLabel>
                <Select
                  disabled={props.disabled}
                  labelId="color-select-label"
                  fullWidth={true}
                  variant="standard"
                  value={block.speaker}
                  onChange={(e: any) => {
                    let newBlock: EditorSongBlock = { ...block };
                    newBlock.speaker = e.target.value;
                    props.emitBlock(newBlock);
                  }}
                >
                  <MenuItem value="none">Non definita</MenuItem>
                  <MenuItem value="A.">Assemblea</MenuItem>
                  <MenuItem value="C.">Cantore</MenuItem>
                  <MenuItem value="C.+A.">Cantore e assemblea</MenuItem>
                  <MenuItem value="P.">Presbitero</MenuItem>
                  <MenuItem value="A1.">Assemblea (1)</MenuItem>
                  <MenuItem value="A2.">Assemblea (2)</MenuItem>
                  <MenuItem value="A3.">Assemblea (3)</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="editor-block-select-wrap">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e: any) => {
                        let newBlock: EditorSongBlock = { ...block };
                        newBlock.useAsRefrain = e.target.checked;
                        props.emitBlock(newBlock);
                      }}
                      disabled={props.disabled}
                    />
                  }
                  label="Usa come ritornello"
                />
              </FormGroup>
            </div>
          </div>
          <div className="editor-block-lines-wrap">
            Testo del blocco:
            {block.lines.length === 0 && (
              <MessageBanner
                type="warning"
                message="Devi aggiungere almeno una coppia di righe perchè il blocco sia valido."
              />
            )}
            {block.lines.map((x: EditorSongBlockLine, i: number) => {
              return (
                <div key={i} className="editor-block-line-wrap">
                  <div className="editor-block-line-notes">
                    <NotesInput
                      onChange={(value: string, invalid: boolean) => {
                        let newBlock: EditorSongBlock = { ...block };
                        newBlock.lines[i].notes = value;
                        newBlock.containsError = invalid;
                        props.emitBlock(newBlock);
                      }}
                      disabled={props.disabled}
                      value={block.lines[i].notes}
                    />
                  </div>
                  <div className="editor-block-line-text">
                    <TextField
                      fullWidth={true}
                      onChange={(e: any) => {
                        let newBlock: EditorSongBlock = { ...block };
                        newBlock.lines[i].text = e.target.value;
                        props.emitBlock(newBlock);
                      }}
                      value={block.lines[i].text}
                      disabled={props.disabled}
                      label="Testo"
                      variant="standard"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="editor-block-line-items">
            <StandardButton
              disabled={props.disabled || block.lines.length === 0}
              onClick={() => {
                let newBlock: EditorSongBlock = { ...block };
                newBlock.lines.pop();
                props.emitBlock(newBlock);
              }}
              label="2 Righe"
              startIcon={<DeleteIcon />}
            />
            <StandardButton
              disabled={props.disabled || block.lines.length >= 12}
              onClick={() => {
                let newBlock: EditorSongBlock = { ...block };
                newBlock.lines.push({ text: "", notes: "" });
                props.emitBlock(newBlock);
              }}
              label="2 Righe"
              startIcon={<AddIcon />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditorBlockItem;
