import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno di avvento.",
  url_video: "https://www.youtube.com/embed/Qr4CkF7Apgc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-",
          text: "Chiara una voce",
        },
        {
          notes: "",
          text: "dissipa con i suoi echi l'oscurità;",
        },
        {
          notes: "",
          text: "lontane le angosce,",
        },
        {
          notes: "               Mi",
          text: "Gesù brilla già!",
        },
        {
          notes: "                        La-",
          text: "desti l'anima intorpidita",
        },
        {
          notes: "                                 Mi",
          text: "e lasci di strisciare lungo il suolo:",
        },
        {
          notes: "                  La-",
          text: "sorge una nuova stella!",
        },
        {
          notes: " Re-",
          text: "Ecco",
        },
        {
          notes: "",
          text: "che ci invia un agnello,",
        },
        {
          notes: "                                       La-",
          text: "per soddisfare gratuitamente le nostre colpe",
        },
        {
          notes: "                    Mi",
          text: "andiamo da lui con lacrime,",
        },
        {
          notes: "                      La-",
          text: "chiediamogli tutti perdono.",
        },
        {
          notes: "     Re-",
          text: "Cosicché,",
        },
        {
          notes: "",
          text: "quando appaia glorioso,",
        },
        {
          notes: "                    La-",
          text: "nella sua seconda venuta,",
        },
        {
          notes: "                         Mi",
          text: "e riempia il mondo di timore,",
        },
        {
          notes: "                    La-",
          text: "lui ci protegga pietoso.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "  Re-",
          text: "PER QUESTO",
        },
        {
          notes: "                     La-",
          text: "NOI CANTIAMO QUESTA LODE",
        },
        {
          notes: "                              Mi",
          text: "AL PADRE, AL FIGLIO, E ALLO SPIRITO",
        },
        {
          notes: "",
          text: "CHE ESSI VIVANO",
        },
        {
          notes: "  La-",
          text: "AMEN!",
        },
      ],
    },
  ],
  subtitle: "Chiara una voce dissipa l'orscurità.",
};
