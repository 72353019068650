import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Agnello di dio.",
  url_video: "https://www.youtube.com/embed/Go-eQs53YM8",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Agnello di Dio,",
        },
        {
          notes: "    Sol                 La",
          text: "che togli il peccato del mondo,",
        },
        {
          notes: " Fa          Mi",
          text: "abbi pietà di noi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Agnello di Dio,",
        },
        {
          notes: "    Sol                 La",
          text: "che togli il peccato del mondo,",
        },
        {
          notes: " Fa          Mi",
          text: "abbi pietà di noi",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Agnello di Dio,",
        },
        {
          notes: "    Sol                 La",
          text: "che togli il peccato del mondo,",
        },
        {
          notes: " Fa          Mi      Fa          Mi",
          text: "dona a noi la pace, dona a noi la pace.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Agnello di Dio,",
        },
        {
          notes: "    Sol                 La",
          text: "che togli il peccato del mondo,",
        },
        {
          notes: " Fa          Mi      Fa          Mi",
          text: "dona a noi la pace, dona a noi la pace.",
        },
      ],
    },
  ],
  subtitle: "Inno agnus dei",
};
