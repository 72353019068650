import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Felice l'uomo.",
  url_video: "https://www.youtube.com/embed/zh7PhWgjEUw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Fa                                 La-",
          text: "FELICE L'UOMO CHE NON SEGUE IL CONSIGLIO DEGLI EMPI,",
        },
        {
          notes: "Fa                                La-",
          text: "E NELLA VIA DEI PECCATORI NON SI FERMA",
        },
        {
          notes: "       Fa                       La-",
          text: "E NON SIEDE IN COMPAGNIA DEI BEFFARDI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Sol                      Mi",
          text: "Ma si compiace della parola del Signore,",
        },
        {
          notes: "        Sol    Fa              Mi",
          text: "i suoi salmi sussurra giorno e notte.",
        },
        {
          notes: "  La-                Fa                    La-",
          text: "Sarà come albero piantato lungo i corsi d'acqua",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa                    La-",
          text: "SARÀ COME ALBERO PIANTATO LUNGO I CORSI D'ACQUA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa            Mi",
          text: "che dà frutto a suo tempo,",
        },
        {
          notes: "         Fa                Mi",
          text: "le sue foglie non cadranno mai,",
        },
        {
          notes: "        Sol           Fa             Mi",
          text: "tutto quello che intraprende riesce bene,",
        },
        {
          notes: "       Sol  Fa           Mi",
          text: "riusciranno tutte le sue opere.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     La-",
          text: "NON COSÌ GLI EMPI, NON COSÌ",
        },
        {
          notes: "    Fa                              La-",
          text: "CHE SONO COME PULA CHE PORTA VIA IL VENTO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Fa                   Mi",
          text: "non reggeranno gli empi nel giudizio,",
        },
        {
          notes: "           Fa                 Mi",
          text: "né i peccatori nella comunità dei santi,",
        },
        {
          notes: "             Sol  Fa                          Mi",
          text: "perché il Signore veglia sul cammino dei suoi fedeli,",
        },
        {
          notes: "      Fa                     Mi    Fa      Mi",
          text: "ma la via degli empi finisce male, finisce male.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Fa                                 La-",
          text: "FELICE L'UOMO CHE NON SEGUE IL CONSIGLIO DEGLI EMPI,",
        },
        {
          notes: "Fa                                La-",
          text: "E NELLA VIA DEI PECCATORI NON SI FERMA",
        },
        {
          notes: "       Fa                       La-",
          text: "E NON SIEDE IN COMPAGNIA DEI BEFFARDI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Sol                      Mi",
          text: "Ma si compiace della parola del Signore,",
        },
        {
          notes: "        Sol    Fa              Mi",
          text: "i suoi salmi sussurra giorno e notte.",
        },
        {
          notes: "  La-                Fa                    La-",
          text: "Sarà come albero piantato lungo i corsi d'acqua",
        },
      ],
    },
  ],
  subtitle: "Sal 1",
};
