import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Te deum.",
  url_video: "https://www.youtube.com/embed/PPFCShYH09s",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Noi ti lodiamo, Dio,",
        },
        {
          notes: "Sol",
          text: "ti proclamiamo Signore.",
        },
        {
          notes: "La-",
          text: "O eterno Padre,",
        },
        {
          notes: "Mi",
          text: "tutta la terra ti adora.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "bar-from-ast-message",
      lines: [
        {
          notes: "Do               Fa",
          text: "A TE CANTANO GLI ANGELI",
        },
        {
          notes: "Sol",
          text: "E TUTTE LE POTENZE DEI CIELI",
        },
        {
          notes: "La-",
          text: "SANTO, SANTO, SANTO,",
        },
        {
          notes: "Mi",
          text: "SANTO, SANTO, SANTO,",
        },
        {
          notes: "Mi7                    La-",
          text: "IL SIGNORE DIO DELL'UNIVERSO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "I cieli e la terra",
        },
        {
          notes: "Sol",
          text: "sono pieni della tua gloria.",
        },
        {
          notes: "La-",
          text: "Ti acclama il coro degli apostoli",
        },
        {
          notes: "Mi",
          text: "e la candida schiera dei martiri;",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "le voci dei profeti si uniscono nella tua lode;",
        },
        {
          notes: "Sol",
          text: "la santa Chiesa proclama la tua gloria,",
        },
        {
          notes: "La-",
          text: "adora il tuo unico Figlio,",
        },
        {
          notes: "Mi",
          text: "e lo Spirito Santo Paraclito.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "O Cristo, re della gloria,",
        },
        {
          notes: "Sol",
          text: "eterno Figlio del Padre,",
        },
        {
          notes: "La-",
          text: "tu nascesti dalla Vergine Madre",
        },
        {
          notes: "Mi",
          text: "per la salvezza dell'uomo.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Vincitore della morte,",
        },
        {
          notes: "Sol",
          text: "hai aperto ai credenti il regno dei Cieli.",
        },
        {
          notes: "La-",
          text: "Tu siedi alla destra di Dio, nella gloria del Padre.",
        },
        {
          notes: "Mi",
          text: "Verrai a giudicare il mondo alla fine dei tempi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Soccorri i tuoi figli, Signore,",
        },
        {
          notes: "Sol",
          text: "che hai redenti col tuo sangue prezioso.",
        },
        {
          notes: "La-",
          text: "Accoglici nella tua gloria",
        },
        {
          notes: "Mi",
          text: "nell'assemblea dei santi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Salva il tuo popolo, Signore,",
        },
        {
          notes: "Sol",
          text: "guida e proteggi i tuoi figli.",
        },
        {
          notes: "La-",
          text: "Ogni giorno ti benediciamo,",
        },
        {
          notes: "Mi",
          text: "lodiamo il tuo nome per sempre.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Degnati oggi, Signore,",
        },
        {
          notes: "Sol",
          text: "di custodirci senza peccato.",
        },
        {
          notes: "La-",
          text: "Sia sempre con noi la tua misericordia:",
        },
        {
          notes: "Mi",
          text: "in te abbiamo sperato.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Pietà di noi, Signore,",
        },
        {
          notes: "Sol",
          text: "pietà di noi.",
        },
        {
          notes: "La-",
          text: "Tu sei la nostra speranza,",
        },
        {
          notes: "Mi",
          text: "non saremo confusi in eterno.",
        },
      ],
    },
    {
      speaker:
        "L'assemblea può alternare il ritornello dopo 1, 2, 3 strofe, secondo le occasioni.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "",
};
