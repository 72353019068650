import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Come lo slancio dell'ira.",
  url_video: "https://www.youtube.com/embed/dnZq_5BWGjc",
  barrè: "III",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                  La-       Re-",
          text: "Come lo slancio dell'ira sul nemico,",
        },
        {
          notes: "            Sol                     Do",
          text: "come lo slancio dell'amore verso l'amato,",
        },
        {
          notes: "  La- La7                     Re-",
          text: "così è il mio slancio verso di te,",
        },
        {
          notes: " Sol                             Do",
          text: "tu l'immortale, la pienezza dei mondi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-              Mi",
          text: "TU SEI IL CAMMINO, COM'È MERAVIGLIOSO!",
        },
        {
          notes: "                              La-",
          text: "TU SEI IL CAMMINO, TU SEI L'AMORE.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VIA.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VITA.",
        },
        {
          notes: "            Sol               Fa",
          text: "TU SEI IL CAMMINO, TU SEI LA VERITÀ,",
        },
        {
          notes: "          Mi",
          text: "TU SEI LA VITA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                 La-                    Re-",
          text: "Ti sei fatto come me perché imparassi a conoscerti",
        },
        {
          notes: "                      Sol                   Do",
          text: "nell'aspetto pure come me, perché potessi riceverti.",
        },
        {
          notes: "  La- La7                                Re-",
          text: "Ti sei fatto peccato per me, pieno di misericordia,",
        },
        {
          notes: "    Sol                                       Do",
          text: "perché i peccati non mi allontanassero mai da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-              Mi",
          text: "TU SEI IL CAMMINO, COM'È MERAVIGLIOSO!",
        },
        {
          notes: "                              La-",
          text: "TU SEI IL CAMMINO, TU SEI L'AMORE.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VIA.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VITA.",
        },
        {
          notes: "            Sol               Fa",
          text: "TU SEI IL CAMMINO, TU SEI LA VERITÀ,",
        },
        {
          notes: "          Mi",
          text: "TU SEI LA VITA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do        La-             Re-",
          text: "Dio che mi creò quando non ero",
        },
        {
          notes: "            Sol            Do",
          text: "conosceva quanto io avrei fatto,",
        },
        {
          notes: "La-  La7                                     Re-",
          text: "per questo ebbe pietà di me e nella sua misericordia",
        },
        {
          notes: "    Sol                             Do",
          text: "ha disposto che io gli chieda la sua natura.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-              Mi",
          text: "TU SEI IL CAMMINO, COM'È MERAVIGLIOSO!",
        },
        {
          notes: "                              La-",
          text: "TU SEI IL CAMMINO, TU SEI L'AMORE.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VIA.",
        },
        {
          notes: "            Sol              La-",
          text: "TU SEI IL CAMMINO, TU SEI LA VITA.",
        },
        {
          notes: "            Sol               Fa",
          text: "TU SEI IL CAMMINO, TU SEI LA VERITÀ,",
        },
        {
          notes: "          Mi",
          text: "TU SEI LA VITA.",
        },
      ],
    },
  ],
  subtitle: "(Ode VII di Salomone)",
};
