import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno delle lodi di pentecoste.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "GIORNO DI IMMENSA GIOIA",
        },
        {
          notes: "               La-",
          text: "NELLA CITTÀ DI DIO:",
        },
        {
          notes: "                  Sol",
          text: "LA FIAMMA DELLO SPIRITO",
        },
        {
          notes: "                    La-",
          text: "RISPLENDA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "Si rinnova il prodigio",
        },
        {
          notes: "               La-",
          text: "degli antichi profeti:",
        },
        {
          notes: "",
          text: "una mistica ebbrezza",
        },
        {
          notes: "                  La-",
          text: "tocca le lingue e i cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "GIORNO DI IMMENSA GIOIA",
        },
        {
          notes: "               La-",
          text: "NELLA CITTÀ DI DIO:",
        },
        {
          notes: "                  Sol",
          text: "LA FIAMMA DELLO SPIRITO",
        },
        {
          notes: "                    La-",
          text: "RISPLENDA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Sol",
          text: "O stagione beata",
        },
        {
          notes: "               La-",
          text: "della Chiesa nascente,",
        },
        {
          notes: "                     Sol",
          text: "che accoglie nel suo grembo",
        },
        {
          notes: "               La-",
          text: "le primizie dei popoli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "GIORNO DI IMMENSA GIOIA",
        },
        {
          notes: "               La-",
          text: "NELLA CITTÀ DI DIO:",
        },
        {
          notes: "                  Sol",
          text: "LA FIAMMA DELLO SPIRITO",
        },
        {
          notes: "                    La-",
          text: "RISPLENDA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "È questo il giubileo",
        },
        {
          notes: "               La-",
          text: "dell'anno cinquantesimo,",
        },
        {
          notes: "                  Sol",
          text: "che riscatta gli schiavi",
        },
        {
          notes: "               La-",
          text: "e proclama il perdono.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "GIORNO DI IMMENSA GIOIA",
        },
        {
          notes: "               La-",
          text: "NELLA CITTÀ DI DIO:",
        },
        {
          notes: "                  Sol",
          text: "LA FIAMMA DELLO SPIRITO",
        },
        {
          notes: "                    La-",
          text: "RISPLENDA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "Manda su noi, Signore,",
        },
        {
          notes: "               La-",
          text: "il dono del tuo Spirito,",
        },
        {
          notes: "                  Sol",
          text: "concedi al mondo inquieto",
        },
        {
          notes: "               La-",
          text: "la giustizia e la pace.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "GIORNO DI IMMENSA GIOIA",
        },
        {
          notes: "               La-",
          text: "NELLA CITTÀ DI DIO:",
        },
        {
          notes: "                  Sol",
          text: "LA FIAMMA DELLO SPIRITO",
        },
        {
          notes: "                    La-",
          text: "RISPLENDA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol",
          text: "O luce di eterna sapienza,",
        },
        {
          notes: "             La-",
          text: "rivelaci il mistero",
        },
        {
          notes: "                 Sol",
          text: "del Dio trino ed unico,",
        },
        {
          notes: "               La-",
          text: "fonte d'eterno amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol La-",
          text: "AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
