import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import ModalDialog from "../../Components/ModalDialog/ModalDialog";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import {
  getFavoriteSongs,
  IFavoriteSong,
  removeFavoriteSongById,
} from "../../Utils";
import { getSemanticIcon } from "../IndexScreen/IndexScreen";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import "./FavoritedScreen.scss";
import Img from "../../Components/Img/Img";

const FavoritedScreen = () => {
  const navigate = useNavigate();

  const favoriteSongs: IFavoriteSong[] = getFavoriteSongs();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [toDeleteItem, setToDeleteItem] = useState<string>("");

  return (
    <PageLayout
      title={"Preferiti"}
      content={
        <div>
          <ModalDialog
            onClose={() => {
              setOpenDialog(false);
            }}
            actionButtons={[
              {
                label: "Annulla",
                onClick: () => {
                  setOpenDialog(false);
                },
              },
              {
                label: "Ok",
                onClick: () => {
                  removeFavoriteSongById(toDeleteItem);
                  setOpenDialog(false);
                },
              },
            ]}
            isOpen={openDialog}
            title="Rimuovere dai preferiti?"
            content={"Vuoi rimuovere questo canto dalla lista dei preferiti?"}
          />
          Accedi rapidamente ai tuoi canti Preferiti.
          <br />
          <br />
          {favoriteSongs.length === 0 && (
            <MessageBanner
              type="info"
              message={"Non hai ancora aggiunto canti ai preferiti."}
            />
          )}
          {favoriteSongs.map((x: IFavoriteSong, i: number) => {
            return (
              <div key={i} className="favorite-page-song-wrap">
                <div className="favorite-page-song-row">
                  <StandardButton
                    label=""
                    extraClassName="favorite-page-spaced-button"
                    onClick={() => {
                      navigate("/view?id=" + encodeURI(x.id ?? x.title));
                    }}
                    iconButtonIcon={<SearchIcon />}
                  />
                  <StandardButton
                    label=""
                    extraClassName="favorite-page-spaced-button"
                    onClick={() => {
                      setToDeleteItem(x.id ?? x.title);
                      setOpenDialog(true);
                    }}
                    iconButtonIcon={<DeleteIcon />}
                  />
                  <div
                    className="favorite-page-color-bar"
                    style={
                      x.color !== "none"
                        ? { background: x.color }
                        : { opacity: 0 }
                    }
                  ></div>
                  <Img
                    className="favorite-page-semantic-icon"
                    src={getSemanticIcon(x.semantic)}
                    alt={x.title}
                  />
                  <div className="favorite-page-wide-title">{x.title}</div>
                </div>
                <div className="favorite-page-short-title">{x.title}</div>
              </div>
            );
          })}
        </div>
      }
    />
  );
};

export default FavoritedScreen;
