import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Cristo gesù è il Signore.",
  url_video: "https://www.youtube.com/embed/0EWT76ul5M8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                 Si7",
          text: "Con lo stesso amore, con lo stesso spirito,",
        },
        {
          notes: "                   Mi-",
          text: "con gli stessi sentimenti.",
        },
        {
          notes: " Mi-                  Si7",
          text: "Niente per rivalità, né per vanagloria,",
        },
        {
          notes: "               Mi-",
          text: "ma tutto con umiltà.",
        },
        {
          notes: "Sol",
          text: "Guardando i desideri degli altri,",
        },
        {
          notes: "                               Si7",
          text: "stimando gli altri superiori a sé,",
        },
        {
          notes: "                        Mi-",
          text: "avendo i sentimenti di Gesù.",
        },
        {
          notes: "    Sol",
          text: "Il quale, essendo Dio,",
        },
        {
          notes: "                           Si7",
          text: "non tenne conto della sua dignità,",
        },
        {
          notes: "                          Mi-",
          text: "anzi si annientò, si fece uomo:",
        },
        {
          notes: "         Sol                         Si7",
          text: "e fatto uomo prese la condizione di servo",
        },
        {
          notes: "                     Mi-",
          text: "ubbidendo fino alla morte.",
        },
        {
          notes: "       Sol    La-           Mi-",
          text: "E che morte, morte di malfattore,",
        },
        {
          notes: " La-        Mi-                Si7",
          text: "morte di peccatore, morte di croce!",
        },
        {
          notes: "Mi-        Sol       La-",
          text: "Per questo Dio lo esaltò lo risuscitò,",
        },
        {
          notes: "             Si7",
          text: "gli diede il nome più alto che esista.",
        },
        {
          notes: "  Mi-",
          text: "Cristo Gesù è il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Cristo Gesù è il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Cristo Gesù è il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              Si7",
          text: "ALLELUJA! ALLELUJA!",
        },
        {
          notes: "  Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!",
        },
        {
          notes: "  Re",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!",
        },
        {
          notes: "  Do                               Si7",
          text: "ALLELUJA, ALLELUJA, ALLELUJA! ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Cristo Gesù è il Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Cristo Gesù è il mio Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Cristo Gesù è il tuo Signore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              Si7",
          text: "ALLELUJA! ALLELUJA!",
        },
        {
          notes: "  Mi-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!",
        },
        {
          notes: "  Re",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!",
        },
        {
          notes: "  Do                               Si7",
          text: "ALLELUJA, ALLELUJA, ALLELUJA! ALLELUJA!",
        },
      ],
    },
  ],
  subtitle: "inno della kenosis (Fil 2, 1-11)",
};
