import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ti vedranno i re.",
  url_video: "https://www.youtube.com/embed/Td4CUOf4QWA",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7",
          text: "Ascoltate isole lontane, nazioni tutte,",
        },
        {
          notes: "",
          text: "così dice il Signore, il santo d'Israele:",
        },
        {
          notes: " Re",
          text: "“a colui che ha la vita disprezzata",
        },
        {
          notes: " Fa#-                Sol",
          text: "ed è abominio delle genti",
        },
        {
          notes: "                     Fa#",
          text: "allo schiavo dei dominatori“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
        {
          notes: "              Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re",
          text: "Nel giorno di salvezza, ti ascolterò",
        },
        {
          notes: "      Fa#-                   Sol",
          text: "nel giorno favorevole, t'aiuterò",
        },
        {
          notes: "                                        Fa#",
          text: "perché ti ho posto come alleanza alle nazioni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
        {
          notes: "              Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re",
          text: "Dirai ai prigionieri: uscite;",
        },
        {
          notes: " Fa#-              Sol",
          text: "dirai ai ciechi: vedete;",
        },
        {
          notes: "                                       Fa#",
          text: "condurrai i popoli verso le acque della vita.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
        {
          notes: "              Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Si-            Mi-               Si-",
          text: "Ma Sion dice: “Jahvè mi ha abbandonata,",
        },
        {
          notes: "  Mi-               Si-",
          text: "Jahvè mi ha dimenticata“.",
        },
        {
          notes: " Sol",
          text: "Forse che una madre dimenticherà",
        },
        {
          notes: "               Fa#",
          text: "il bambino che allatta?",
        },
        {
          notes: " Sol",
          text: "Forse che una madre lascerà di amare",
        },
        {
          notes: "                  Fa#",
          text: "il figlio del suo seno?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re           (Fa#-)                Sol",
          text: "ANCHE SE UNA MADRE DIMENTICASSE IL FIGLIO",
        },
        {
          notes: "                  Fa#",
          text: "IO NON TI DIMENTICHERÒ.",
        },
        {
          notes: " Sol",
          text: "GUARDA SULLE PALME DELLE MIE MANI",
        },
        {
          notes: "          Fa#",
          text: "TI PORTO TATUATA.",
        },
        {
          notes: " Sol",
          text: "GUARDA SULLE PALME DELLE MIE MANI",
        },
        {
          notes: "          Fa#",
          text: "TI PORTO TATUATA.",
        },
        {
          notes: "             Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
        {
          notes: "              Re",
          text: "TI VEDRANNO I RE SI ALZERANNO IN PIEDI",
        },
        {
          notes: "   Fa#-",
          text: "I PRINCIPI DELLA TERRA",
        },
        {
          notes: "        Sol",
          text: "S'INCHINERANNO,",
        },
        {
          notes: "                              Fa#",
          text: "PERCHÉ TI HO ELETTO, IO TI HO ELETTO.",
        },
      ],
    },
  ],
  subtitle: "(Is. 49, 1-16) Il canto del servo.",
};
