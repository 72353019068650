import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Acclamate al Signore.",
  url_video: "https://www.youtube.com/embed/fdFTClM3KHE",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "ACCLAMATE AL SIGNORE",
        },
        {
          notes: "    La-         Mi-",
          text: "VOI TUTTI DELLA TERRA",
        },
        {
          notes: " Sol         La           Si7",
          text: "SERVITE IL SIGNORE NELLA GIOIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Mi-         Si7          Mi-",
          text: "Presentatevi a lui con esultanza.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "ACCLAMATE AL SIGNORE",
        },
        {
          notes: "    La-         Mi-",
          text: "VOI TUTTI DELLA TERRA",
        },
        {
          notes: " Sol         La           Si7",
          text: "SERVITE IL SIGNORE NELLA GIOIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Riconoscete che il Signore è Dio,",
        },
        {
          notes: "",
          text: "egli ci ha fatto e noi siamo suoi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "ACCLAMATE AL SIGNORE",
        },
        {
          notes: "    La-         Mi-",
          text: "VOI TUTTI DELLA TERRA",
        },
        {
          notes: " Sol         La           Si7",
          text: "SERVITE IL SIGNORE NELLA GIOIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Varcate le sue porte con inni di grazie,",
        },
        {
          notes: "",
          text: "lodatelo, benedite il suo nome.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "ACCLAMATE AL SIGNORE",
        },
        {
          notes: "    La-         Mi-",
          text: "VOI TUTTI DELLA TERRA",
        },
        {
          notes: " Sol         La           Si7",
          text: "SERVITE IL SIGNORE NELLA GIOIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Perché buono è il Signore,",
        },
        {
          notes: "",
          text: "eterno è il suo amore per noi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "ACCLAMATE AL SIGNORE",
        },
        {
          notes: "    La-         Mi-",
          text: "VOI TUTTI DELLA TERRA",
        },
        {
          notes: " Sol         La           Si7",
          text: "SERVITE IL SIGNORE NELLA GIOIA.",
        },
      ],
    },
  ],
  subtitle: "Sal 99(100)",
};
