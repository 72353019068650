import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Signore tu mi scruti e mi conosci.",
  url_video: "https://www.youtube.com/embed/r-wQFf-hyg4",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                       Sol-",
          text: "Signore tu mi scruti e mi conosci,",
        },
        {
          notes: "                             Re-",
          text: "tu sai quando mi corico e mi alzo.",
        },
        {
          notes: "                            Sol-",
          text: "Da lontano conosci i miei pensieri,",
        },
        {
          notes: "            (La)          Re-",
          text: "ti sono familiari i miei cammini.",
        },
        {
          notes: "  Do7                             Fa",
          text: "Non è ancora sulla mia lingua la parola",
        },
        {
          notes: " La                          Re-",
          text: "e già tu Signore, la conosci tutta.",
        },
        {
          notes: "  Do7                                    Fa",
          text: "stringi alle spalle, mi attacchi di fronte",
        },
        {
          notes: "  La",
          text: "e nello stesso tempo",
        },
        {
          notes: "                           Re-",
          text: "hai posto su di me la tua mano:",
        },
        {
          notes: "  Sol-                           La",
          text: "e questo è scienza misteriosa per me,",
        },
        {
          notes: "  Sol-                      La",
          text: "scienza che non arrivo a capire",
        },
        {
          notes: "  Sol-                          La",
          text: "scienza così alta che io non comprendo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                            La-",
          text: "MA DOVE ANDRÒ LONTANO DAL TUO SPIRITO,",
        },
        {
          notes: "                       Sib",
          text: "MA DOVE DAL TUO VOLTO FUGGIRÒ?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                           La",
          text: "Se salgo fino in cielo tu sei là,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sib                          La",
          text: "SE SCENDO NELLA MORTE, LÀ TI TROVO.",
        },
        {
          notes: " Fa                                      La-",
          text: "MI STRINGI ALLE SPALLE, MI ATTACCHI DI FRONTE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                                   Sib",
          text: "e nello stesso tempo hai posto su di me",
        },
        {
          notes: "",
          text: "la tua mano:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                                 La",
          text: "E QUESTA È SCIENZA MISTERIOSA PER ME,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sib                        La",
          text: "scienza che non arrivo a capire",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sib                            La",
          text: "SCIENZA COSÌ ALTA CHE IO NON COMPRENDO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                         Sol-",
          text: "Signore, tu mi scruti e mi conosci,",
        },
        {
          notes: "                            Re-",
          text: "tu metti alla prova il mio cuore,",
        },
        {
          notes: "                                    Sol-",
          text: "perché non percorra un cammino di doppiezza,",
        },
        {
          notes: "                    (La)         Re-",
          text: "perché non abbandoni mai il tuo sentiero.",
        },
      ],
    },
  ],
  subtitle: "Sal 138(139)",
};
