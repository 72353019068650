import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import PageLayout from "../../Components/PageLayout/PageLayout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./LiturgyScreen.scss";
import { useEffect, useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StandardButton from "../../Components/StandardButton/StandardButton";
import SaveIcon from "@mui/icons-material/Save";
import { getLiturgy, setLiturgy } from "../../Utils";
import SearchSongInput, {
  SearchSongValue,
} from "../../Components/SearchSongInput/SearchSongInput";

export interface FormVoice {
  page: number;
  name: string;
  key: string;
}

const FORM_INPUTS_NAMES: FormVoice[] = [
  {
    page: 0,
    name: "Ingresso",
    key: "ingresso_eucarestia",
  },
  {
    page: 0,
    name: "Prima lettura",
    key: "prima",
  },
  {
    page: 0,
    name: "Seconda lettura",
    key: "seconda",
  },
  {
    page: 0,
    name: "Vangelo",
    key: "vangelo",
  },
  {
    page: 0,
    name: "Finale",
    key: "finale_eucarestia",
  },
  {
    page: 1,
    name: "Ingresso",
    key: "ingresso_parola",
  },
  {
    page: 1,
    name: "Pace",
    key: "pace",
  },
  {
    page: 1,
    name: "Pane",
    key: "pane",
  },
  {
    page: 1,
    name: "Vino",
    key: "vino",
  },
  {
    page: 1,
    name: "Finale",
    key: "finale_parola",
  },
  {
    page: 2,
    name: "Bonus 1",
    key: "bonus1",
  },
  {
    page: 2,
    name: "Bonus 2",
    key: "bonus2",
  },
  {
    page: 2,
    name: "Bonus 3",
    key: "bonus3",
  },
  {
    page: 2,
    name: "Bonus 4",
    key: "bonus4",
  },
  {
    page: 2,
    name: "Bonus 5",
    key: "bonus5",
  },
];

const LiturgyScreen = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [formValue, setFormValue] = useState<{
    [key: string]: SearchSongValue;
  }>(getLiturgy());

  useEffect(() => {
    setLiturgy(formValue ?? {});
  }, [formValue]);

  const setInputValue = (link: SearchSongValue | null, name: string) => {
    let fValue = { ...formValue };
    if (link) {
      fValue[name] = link;
    } else {
      delete fValue[name];
    }
    setFormValue(fValue);
  };

  return (
    <PageLayout
      title={"Liturgia"}
      content={
        <div>
          Imposta qui i link ai canti che ti occorrono per le prossime
          celebrazioni.
          <Tabs
            value={value}
            onChange={(e, newValue) => {
              setValue(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Parola" />
            <Tab label="Eucarestia" />
            <Tab label="Bonus" />
          </Tabs>
          <div className="liturgy-screen-form-wrap">
            {loading && (
              <div className="liturgy-screen-spinner-wrap">
                <CircularProgress />
              </div>
            )}
            {!loading &&
              FORM_INPUTS_NAMES.map((x: FormVoice, i: number) => {
                return x.page === value ? (
                  <div key={i}>
                    <SearchSongInput
                      onChange={(link: SearchSongValue | null) => {
                        setInputValue(link, x.key);
                      }}
                      value={formValue[x.key]}
                      label={x.name}
                    />
                  </div>
                ) : (
                  <div key={i}></div>
                );
              })}
          </div>
          <div className="liturgy-screen-form-buttons">
            <StandardButton
              label={"Pulisci"}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  try {
                    setLoading(false);
                    setFormValue({});
                  } catch (e) {}
                }, 1000);
              }}
              startIcon={<CloseIcon />}
            />
          </div>
        </div>
      }
    />
  );
};

export default LiturgyScreen;
