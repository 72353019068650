import {
  CircularProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../Components/Accordion/Accordion";
import Img from "../../Components/Img/Img";
import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import PageLayout from "../../Components/PageLayout/PageLayout";
import { SongElement } from "../../Songs/SongElement";
import { getAppSongs } from "../../Songs/SongHandler";
import "./IndexScreen.scss";

export const getSemanticIcon = (semantic: string) => {
  if (semantic === "neocat") {
    return "./Assets/favicon.png";
  }
  if (semantic === "extra") {
    return "./Assets/guitar.png";
  }
  if (semantic === "classic_liturgy") {
    return "./Assets/classic_liturgy.png";
  }
  if (semantic === "custom") {
    return "./Assets/custom.png";
  }
  return "";
};

const semanticUserLabel = (rawSemantic: string) => {
  switch (rawSemantic) {
    case "extra":
      return "Spariti Extra";
    case "neocat":
      return "Neocatecumenali";
    case "classic_liturgy":
      return "Liturgia classica";
    case "custom":
      return "Canti custom";
    default:
      return "";
  }
};

var timeout: NodeJS.Timeout | null = null;
const IndexScreen = () => {
  const navigate = useNavigate();
  const [setsByLetter, setSetsByLetter] = useState<any>({});
  const [songsAmount, setSongsAmount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [availableTypesSelected, setAvailableTypesSelected] = useState<
    string[]
  >([]);
  const [availableTypes, setAvailableTypes] = useState<string[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [pageReady, setPageReady] = useState<boolean>(false);

  const loadIndex = () => {
    let songs: SongElement[] = getAppSongs()
      .filter((a: SongElement) => {
        return (
          a.title.toLowerCase().includes(searchText) ||
          a.conainedText?.toLowerCase().includes(searchText)
        );
      })
      .filter((x: SongElement) => availableTypesSelected.includes(x.semantic))
      .sort((a: SongElement, b: SongElement) => {
        return a.title < b.title ? -1 : 1;
      });

    let letterSets: any = {};
    let types: string[] = [...availableTypes];

    songs.forEach((song: SongElement, i: number) => {
      let letter: string = song.title.charAt(0).toLocaleUpperCase();
      let set: SongElement[] = letterSets[letter] ?? [];
      set.push(song);
      letterSets[letter] = set;

      if (types.indexOf(song.semantic) === -1) {
        types.push(song.semantic);
      }
    });
    setAvailableTypes(types);
    setSetsByLetter(letterSets);
    setSongsAmount(songs.length);
  };

  const initIndex = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setReload(true);
    loadIndex();
    timeout = setTimeout(() => {
      timeout = null;
      setReload(false);
    }, 500);
  };

  const initPage = () => {
    let types: string[] = [...availableTypes];
    let songs: SongElement[] = getAppSongs();
    songs.forEach((song: SongElement, i: number) => {
      if (types.indexOf(song.semantic) === -1) {
        types.push(song.semantic);
      }
    });
    setAvailableTypes(types);
    setAvailableTypesSelected(types);
  };

  useEffect(() => {
    initIndex();
    setTimeout(() => {
      setPageReady(true);
    }, 100);
  }, [searchText, availableTypesSelected]);

  useEffect(() => {
    initPage();
  }, []);

  return (
    <PageLayout
      title={"Indice"}
      content={
        <div>
          Cerca uno spartito nell'indice dei canti.
          <br />
          {pageReady && (
            <div>
              <div className="index-inputs-inrow">
                <span>
                  <TextField
                    fullWidth
                    variant={"standard"}
                    label={"Cerca spartito"}
                    value={searchText}
                    helperText={
                      "Sono presenti " + songsAmount.toString() + " canti."
                    }
                    onChange={(e) => {
                      setSearchText(e.target.value.toLowerCase());
                    }}
                  />
                </span>
                <span>
                  <ToggleButtonGroup
                    className="toggle-button-group-on-index"
                    style={{ marginBottom: "0.5em" }}
                    value={availableTypesSelected}
                    onChange={(e: any, values: string[]) => {
                      setAvailableTypesSelected(values);
                    }}
                    aria-label="text formatting"
                  >
                    {availableTypes.map((x: string, i: number) => {
                      return (
                        <ToggleButton
                          key={i}
                          value={x}
                          aria-label="left aligned"
                        >
                          {semanticUserLabel(x)}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </span>
              </div>

              {reload && (
                <div className="center-index-spinner">
                  <CircularProgress />
                </div>
              )}
              {!reload &&
                Object.keys(setsByLetter).map((key: string, i: number) => {
                  return (
                    <div key={i} className="index-page-letter-wrap">
                      <Accordion
                        startsOpen={searchText !== ""}
                        label={key}
                        content={
                          <div>
                            {setsByLetter[key]
                              .filter((x: SongElement) =>
                                availableTypesSelected.includes(x.semantic)
                              )
                              .map((x: SongElement, j: number) => {
                                return (
                                  <div
                                    key={j}
                                    className="index-page-song-wrap"
                                    onClick={() => {
                                      navigate(
                                        "/view?id=" + encodeURI(x.id ?? x.title)
                                      );
                                    }}
                                  >
                                    <div
                                      className="index-page-color-bar"
                                      style={
                                        x.color !== "none"
                                          ? { background: x.color }
                                          : { opacity: 0 }
                                      }
                                    ></div>
                                    <Img
                                      style={
                                        x.url_video !== ""
                                          ? {}
                                          : { opacity: "0" }
                                      }
                                      className="index-page-video-icon"
                                      src={"./Assets/video_ico.png"}
                                      alt={"Il canto ha il video"}
                                    />
                                    <Img
                                      className="index-page-semantic-icon"
                                      src={getSemanticIcon(x.semantic)}
                                      alt={x.title}
                                    />
                                    <div className="index-page-title-label">
                                      {x.title}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        }
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      }
    />
  );
};

export default IndexScreen;
