import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Su_ali_d'aquila.",
  title: "Su ali d'aquila.",
  url_video: "https://www.youtube.com/embed/M24IfMH1PhU",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Sol  Re  Sol  Re",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol               Re",
          text: "Tu che abiti al riparo del Signore",
        },
        {
          notes: "Sol                   Re",
          text: "e che dimori alla sua ombra",
        },
        {
          notes: "Fa7                 Sib7 ",
          text: "di al Signore mio Rifugio,",
        },
        {
          notes: "    Sol-             La",
          text: "mia roccia in cui confido.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Re           Re7     ",
          text: "E ti rialzerà, ti solleverà",
        },
        {
          notes: "         Mi-    La7  ",
          text: "su ali d'aquila ti reggerà",
        },
        {
          notes: "      La7     Re7       Sol     Mi-",
          text: "sulla brezza dell'alba ti farà brillar",
        },
        {
          notes: "  La     Si- Fa#7  Mi7           La  Sol Re7 ",
          text: "come il sole,    così nelle sue mani  vivrai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Re7 ",
          text: " ",
        },
        {
          notes: "    Sol                  Re",
          text: "Dal laccio del cacciatore ti libererà",
        },
        {
          notes: "  Sol                      Re",
          text: "e dalla carestia che ti distrugge.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Fa7                       Sib7 ",
          text: "poi ti coprirà con le sue ali",
        },
        {
          notes: "Sol-           La",
          text: "e rifugio troverai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Re           Re7        ",
          text: "E ti rialzerà, ti solleverà",
        },
        {
          notes: "         Mi-    La7  ",
          text: "su ali d'aquila ti reggerà",
        },
        {
          notes: "      La7        Re7       Sol     Mi-",
          text: "sulla brezza dell'alba ti farà brillar",
        },
        {
          notes: "  La     Si- Fa#7  Mi-             La  Sol Re7 ",
          text: "come il sole,    così nelle sue mani  vivrai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol  Re7 ",
          text: " ",
        },
        {
          notes: "    Sol              Re",
          text: "Non devi temere i terrori della notte",
        },
        {
          notes: "   Sol                 Re",
          text: "né freccia che vola di giorno",
        },
        {
          notes: "Fa7                   Sib7 ",
          text: "mille cadranno al tuo fianco",
        },
        {
          notes: "   Sol-          La",
          text: "ma nulla ti colpirà.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Re           Re7      ",
          text: "E ti rialzerà, ti solleverà",
        },
        {
          notes: "         Mi-    La7  ",
          text: "su ali d'aquila ti reggerà",
        },
        {
          notes: "      La7      Re7       Sol     Mi-",
          text: "sulla brezza dell'alba ti farà brillar",
        },
        {
          notes: "  La     Si- Fa#7 Mi7             La  Sol Re7 ",
          text: "come il sole,    così nelle sue mani  vivrai",
        },
        {
          notes: " ",
          text: "Sol  Re7 ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Sol                   Re",
          text: "Perché ai suoi angeli ha dato un comando",
        },
        {
          notes: "   Sol                         Re",
          text: "di preservarti in tutte le tue vie",
        },
        {
          notes: "   Fa7                   Sib7 ",
          text: "ti porteranno sulle loro mani",
        },
        {
          notes: "          Sol-               La",
          text: "contro la pietra non inciamperai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Re           Re7 ",
          text: "E ti rialzerà, ti solleverà",
        },
        {
          notes: "         Mi-    La7  ",
          text: "su ali d'aquila ti reggerà",
        },
        {
          notes: "      La7      Re7       Sol     Mi-",
          text: "sulla brezza dell'alba ti farà brillar",
        },
        {
          notes: "  La     Si- Fa#  Mi7            La  Sol Re7 ",
          text: "come il sole,    così nelle sue mani  vivrai.",
        },
        {
          notes: " ",
          text: " ",
        },
        {
          notes: "Sol  Re7    La Re Sol7   Fa#7   ",
          text: " ",
        },
        {
          notes: "Fa- Mi7  La7 ",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "     Mib          Mib7   ",
          text: "E ti rialzerà, ti solleverà",
        },
        {
          notes: "         Fa-    Sib7  ",
          text: "su ali d'aquila ti reggerà",
        },
        {
          notes: "      Sib7   Mib7             Fa-",
          text: "sulla brezza dell'alba ti farà brillar",
        },
        {
          notes: "  Sib   Do-  Sol- Fa-         La7 ",
          text: "come il sole,    così nelle sue mani  ",
        },
        {
          notes: "     Mib          Mib7 ",
          text: "E ti rialzerò, ti solleverò",
        },
        {
          notes: "         Fa-    Sib7  ",
          text: "su ali d'aquila ti reggerò",
        },
        {
          notes: "      Sib7   Mi7              Fa-",
          text: "sulla brezza dell'alba ti farò brillar",
        },
        {
          notes: "  Sib    Do-  Sol- Fa7            Sib    Do ",
          text: "come il sole,     così nelle mie mani vivrai.",
        },
        {
          notes: "Mib7      Mib",
          text: "",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
