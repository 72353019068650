import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Chi ci separerà.",
  url_video: "https://www.youtube.com/embed/3n7hwPi27o8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "             La-",
          text: "Forse Cristo Gesù?",
        },
        {
          notes: "                          Sol",
          text: "Lui che morì, lui che risuscitò,",
        },
        {
          notes: "                       Fa",
          text: "lui che alla destra di Dio",
        },
        {
          notes: "              Mi",
          text: "Intercede per noi?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi         La-",
          text: "La tribolazione,",
        },
        {
          notes: "                       Sol",
          text: "l'angoscia, la persecuzione,",
        },
        {
          notes: "                Fa",
          text: "la fame, la nudità,",
        },
        {
          notes: "",
          text: "se tutto questo vinciamo",
        },
        {
          notes: "                Mi",
          text: "in colui che ci amò?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7        La-",
          text: "Se sicuri siamo",
        },
        {
          notes: "",
          text: "che né la morte né la vita,",
        },
        {
          notes: "",
          text: "né il presente, né il futuro,",
        },
        {
          notes: "                           Sol",
          text: "né l'altezza, né la profonditá,",
        },
        {
          notes: "                  Fa",
          text: "né altra creatura alcuna",
        },
        {
          notes: "                     Mi",
          text: "ci potrà separare da Dio,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-",
          text: "CI POTRÀ SEPARAR,",
        },
        {
          notes: "            Sol",
          text: "CI POTRÀ SEPARAR",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Fa",
          text: "DALL'AMORE DI DIO",
        },
        {
          notes: "                       Mi",
          text: "MANIFESTATO IN CRISTO GESÙ",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
        {
          notes: "La-              Sol",
          text: "CHI CI SEPARERÀ, CHI CI SEPARERÀ.",
        },
        {
          notes: "      Fa       Mi",
          text: "DALL'AMORE DI DIO?",
        },
      ],
    },
  ],
  subtitle: "(Rom 8, 33-39)",
};
