import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Tu sei bella, amica mia.",
  url_video: "https://www.youtube.com/embed/MkgIVwFB62Y",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                            La7",
          text: "Tu sei bella, amica mia, come Tirza",
        },
        {
          notes: "                   Re-",
          text: "Bellissima come Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                                                 La7",
          text: "Come sono belli i tuoi piedi nei sandali, figlia di principe! ",
        },
        {
          notes: "Sol-                                        La7",
          text: "Le curve dei tuoi fianchi, opera di mani di artista",
        },
        {
          notes: "Sib                                La7",
          text: "Il tuo ombelico è come un anfora rotonda ",
        },
        {
          notes: "Sib                                  La7",
          text: "Il tuo collo come una torre d’avorio",
        },
        {
          notes: "Sol-                               La7",
          text: "I tuoi occhi, come i laghetti di Chesbòn ",
        },
        {
          notes: "Sol-                              La7",
          text: "Il tuo capo come il monte Carmelo",
        },
        {
          notes: "Sib                            La7",
          text: "I tuoi capelli sono come la porpora,",
        },
        {
          notes: "Sib                            La7",
          text: "un re è rimasto in essi prigioniero ",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                            La7",
          text: "Tu sei bella, amica mia, come Tirza",
        },
        {
          notes: "                   Re-",
          text: "Bellissima come Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
        {
          notes: "Re7                                 Sol-",
          text: "Chi è colei che sale come l’aurora?",
        },
        {
          notes: "Sib            La    Sib                La7",
          text: "bella come la luna, splendente come il sole?",
        },
        {
          notes: "Re-              La7",
          text: "Come sei bella, oh amor.",
        },
        {
          notes: "                  Re-",
          text: "Come sei bella, oh delizia!",
        },
        {
          notes: "Sol-               La7",
          text: "Bellissima come Gerusalemme,",
        },
        {
          notes: "Sol-              La7",
          text: "Gerusalemme, Gerusalemme",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                            La7",
          text: "Tu sei bella, amica mia, come Tirza",
        },
        {
          notes: "                   Re-",
          text: "Bellissima come Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
        {
          notes: "Sol-                   La7",
          text: "Gerusalemme, Gerusalemme",
        },
      ],
    },
  ],
  subtitle: "(Cantico dei Cantici, cap. 6 e 7)",
};
