import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno delle lodi di avvento (dopo il 16 dicembre).",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "LE VOCI DEI PROFETI",
        },
        {
          notes: "                 La-",
          text: "ANNUNZIANO IL SIGNORE,",
        },
        {
          notes: "                       Sol",
          text: "CHE RECA A TUTTI GLI UOMINI",
        },
        {
          notes: "               La-",
          text: "IL DONO DELLA PACE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "Ecco una luce nuova",
        },
        {
          notes: "                 La-",
          text: "s'accende nel mattino,",
        },
        {
          notes: "            Sol",
          text: "una voce risuona:",
        },
        {
          notes: "                 La-",
          text: "viene il re della gloria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "NEL SUO PRIMO AVVENTO",
        },
        {
          notes: "                 La-",
          text: "CRISTO VENNE A SALVARCI,",
        },
        {
          notes: "            Sol",
          text: "A GUARIR LE FERITE",
        },
        {
          notes: "                 La-",
          text: "DEL CORPO E DELLO SPIRITO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "Alla fine dei tempi",
        },
        {
          notes: "                 La-",
          text: "tornerà come giudice;",
        },
        {
          notes: "              Sol",
          text: "darà il regno promesso",
        },
        {
          notes: "                 La-",
          text: "ai suoi servi fedeli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "OR SUL NOSTRO CAMMINO",
        },
        {
          notes: "                 La-",
          text: "LA SUA LUCE RISPLENDE:",
        },
        {
          notes: "             Sol",
          text: "GESÙ, SOLE DI GRAZIA,",
        },
        {
          notes: "                 La-",
          text: "CI CHIAMA A VITA NUOVA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "Te, Cristo, noi cerchiamo;",
        },
        {
          notes: "                 La-",
          text: "te vogliamo conoscere,",
        },
        {
          notes: "              Sol",
          text: "per lodarti in eterno",
        },
        {
          notes: "              La-",
          text: "nella patria beata.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol",
          text: "A TE LODE, SIGNORE,",
        },
        {
          notes: "                 La-",
          text: "NATO DA MARIA VERGINE,",
        },
        {
          notes: "                   Sol",
          text: "AL PADRE E AL SANTO SPIRITO,",
        },
        {
          notes: "                 La-",
          text: "NEI SECOLI DEI SECOLI.",
        },
        {
          notes: "Sol La-",
          text: "AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
