import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "La colomba volò.",
  url_video: "",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                             Si7",
          text: "LA COLOMBA VOLÒ SUL CAPO DEL SIGNORE",
        },
        {
          notes: "                   Mi-",
          text: "E SALMEGGIÒ SOPRA DI LUI.",
        },
        {
          notes: "                                       Si7",
          text: "E IL SIGNORE MOSTRÒ I DISEGNI DEL SUO AMORE,",
        },
        {
          notes: "                                   Mi-",
          text: "DISEGNI D'ARTE INEFFABILE PER OGNI UOMO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Si7",
          text: "E gli abissi si aprirono",
        },
        {
          notes: "La-",
          text: "e si udirono terribili grida",
        },
        {
          notes: "                  Si7",
          text: "come di donne in parto",
        },
        {
          notes: "Do",
          text: "Ma il Signore distrusse le tenebre",
        },
        {
          notes: "            Si7",
          text: "con il suo sguardo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                             Si7",
          text: "LA COLOMBA VOLÒ SUL CAPO DEL SIGNORE",
        },
        {
          notes: "                   Mi-",
          text: "E SALMEGGIÒ SOPRA DI LUI.",
        },
        {
          notes: "                                       Si7",
          text: "E IL SIGNORE MOSTRÒ I DISEGNI DEL SUO AMORE,",
        },
        {
          notes: "                                   Mi-",
          text: "DISEGNI D'ARTE INEFFABILE PER OGNI UOMO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Si7",
          text: "E i disegni si persero",
        },
        {
          notes: "La-                        Si7",
          text: "e gli angeli del cielo piangevano,",
        },
        {
          notes: "La-",
          text: "i disegni di tutti quelli",
        },
        {
          notes: "                               Si7",
          text: "che non seguirono la verità di lui.",
        },
        {
          notes: "            Si7",
          text: "Perché il Signore ha mostrato la sua via",
        },
        {
          notes: "       La-",
          text: "e ha diffuso la sua grazia",
        },
        {
          notes: "                         Si7",
          text: "su tutti quelli che lo seguono;",
        },
        {
          notes: " Do                                         Si7",
          text: "e ha dato la forza di abbellirsi della sua santità.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                             Si7",
          text: "LA COLOMBA VOLÒ SUL CAPO DEL SIGNORE",
        },
        {
          notes: "                   Mi-",
          text: "E SALMEGGIÒ SOPRA DI LUI.",
        },
        {
          notes: "                                       Si7",
          text: "E IL SIGNORE MOSTRÒ I DISEGNI DEL SUO AMORE,",
        },
        {
          notes: "                                   Mi-",
          text: "DISEGNI D'ARTE INEFFABILE PER OGNI UOMO.",
        },
      ],
    },
  ],
  subtitle: "(Ode XXIV di Salomone)",
};
