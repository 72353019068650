import AboutScreen from "./Pages/AboutScreen/AboutScreen";
import EditorScreen from "./Pages/EditorScreen/EditorScreen";
import FavoritedScreen from "./Pages/FavoritedScreen/FavoritedScreen";
import GuideScreen from "./Pages/GuideScreen/GuideScreen";
import HomeScreen from "./Pages/HomeScreen/HomeScreen";
import HowToDownloadScreen from "./Pages/HowToDownloadScreen/HowToDownloadScreen";
import IndexScreen from "./Pages/IndexScreen/IndexScreen";
import IntroScreen from "./Pages/IntroScreen/IntroScreen";
import LiturgyScreen from "./Pages/LiturgyScreen/LiturgyScreen";
import NotFoundScreen from "./Pages/NotFoundScreen/NotFoundScreen";
import OptionsScreen from "./Pages/OptionsScreen/OptionsScreen";
import PrivacyScreen from "./Pages/PrivacyScreen/PrivacyScreen";
import ProfileScreen from "./Pages/ProfileScreen/ProfileScreen";
import SongViewScreen from "./Pages/SongViewScreen/SongViewScreen";
import TuneScreen from "./Pages/TuneScreen/TuneScreen";
import GuideScreen_Chords from "./Pages/GuideScreen_Chords/GuideScreen_Chords";
import GuideScreen_GuitarIntro from "./Pages/GuideScreen_GuitarIntro/GuideScreen_GuitarIntro";
import GuideScreen_InteractiveSong from "./Pages/GuideScreen_InteractiveSong/GuideScreen_InteractiveSong";
import ResetPasswordScreen from "./Pages/ResetPasswordScreen/ResetPasswordScreen";

export interface AppRoute {
  path: string;
  element: JSX.Element;
  allowLayout: boolean;
}

export const AppRoutes: AppRoute[] = [
  {
    path: "/",
    element: <HomeScreen />,
    allowLayout: true,
  },
  {
    path: "view",
    element: <SongViewScreen />,
    allowLayout: true,
  },
  {
    path: "home",
    element: <HomeScreen />,
    allowLayout: true,
  },
  {
    path: "about",
    element: <AboutScreen />,
    allowLayout: true,
  },
  {
    path: "privacy",
    element: <PrivacyScreen />,
    allowLayout: true,
  },
  {
    path: "install-app",
    element: <HowToDownloadScreen />,
    allowLayout: true,
  },
  {
    path: "index",
    element: <IndexScreen />,
    allowLayout: true,
  },
  {
    path: "liturgy",
    element: <LiturgyScreen />,
    allowLayout: true,
  },
  {
    path: "tune",
    element: <TuneScreen />,
    allowLayout: true,
  },
  {
    path: "create",
    element: <EditorScreen />,
    allowLayout: true,
  },
  {
    path: "guide",
    element: <GuideScreen />,
    allowLayout: true,
  },
  {
    path: "guide_chords",
    element: <GuideScreen_Chords />,
    allowLayout: true,
  },
  {
    path: "guide_guitarintro",
    element: <GuideScreen_GuitarIntro />,
    allowLayout: true,
  },
  {
    path: "guide_interactivesong",
    element: <GuideScreen_InteractiveSong />,
    allowLayout: true,
  },
  {
    path: "resetpassword",
    element: <ResetPasswordScreen />,
    allowLayout: false,
  },
  {
    path: "favorites",
    element: <FavoritedScreen />,
    allowLayout: true,
  },
  {
    path: "profile",
    element: <ProfileScreen />,
    allowLayout: true,
  },
  {
    path: "options",
    element: <OptionsScreen />,
    allowLayout: true,
  },
  {
    path: "*",
    element: <NotFoundScreen />,
    allowLayout: true,
  },
];
