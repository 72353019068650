import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Noli me tangere.",
  url_video: "https://www.youtube.com/embed/0RN22lEkEiw",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Donna, dimmi perché piangi,",
        },
        {
          notes: "                     Fa",
          text: "Donna, dimmi perché piangi,",
        },
        {
          notes: "     Do          Sol         Do",
          text: "chi cerchi, chi cerchi, chi cerchi?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "SIGNORE, SE L'HAI PORTATO VIA TU,",
        },
        {
          notes: "                              Fa",
          text: "SIGNORE, SE L'HAI PORTATO VIA TU,",
        },
        {
          notes: "                 Do",
          text: "DIMMI DOVE L'HAI POSTO",
        },
        {
          notes: "   Sol         Do     Sol         Do",
          text: "ED IO LO PRENDERÒ, ED IO LO PRENDERÒ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Fa",
          text: "Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do-",
          text: "RABBUNI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Fa",
          text: "Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do-   Do",
          text: "RABBUNI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa-                             Do-",
          text: "E va' ad annunciare ai miei fratelli",
        },
        {
          notes: "Fa-                             Do-",
          text: "E VA' AD ANNUNCIARE AI MIEI FRATELLI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol#                       Sol",
          text: "Che salgo al Padre mio e Padre vostro,",
        },
        {
          notes: "   Sol#                       Sol",
          text: "CHE SALGO AL PADRE MIO E PADRE VOSTRO,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol#      Sol",
          text: "Dio mio e Dio vostro",
        },
        {
          notes: "   Sol#      Sol",
          text: "DIO MIO E DIO VOSTRO.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Fa-                        Do-",
          text: "CHE SALGO AL PADRE MIO E PADRE VOSTRO,",
        },
        {
          notes: "    Fa-        Do-",
          text: "DIO MIO E DIO VOSTRO.",
        },
        {
          notes: "     Sol#                      Sol",
          text: "CHE SALGO AL PADRE MIO E PADRE VOSTRO,",
        },
        {
          notes: "    Sol#      Sol     Do-",
          text: "DIO MIO E DIO VOSTRO.",
        },
        {
          notes: "  Do",
          text: "SIGNORE, SE L'HAI PORTATO VIA TU,",
        },
        {
          notes: "                              Fa",
          text: "SIGNORE, SE L'HAI PORTATO VIA TU,",
        },
        {
          notes: "                 Do",
          text: "DIMMI DOVE L'HAI POSTO",
        },
        {
          notes: "   Sol         Do     Sol         Do",
          text: "ED IO LO PRENDERÒ, ED IO LO PRENDERÒ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Fa",
          text: "Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do-",
          text: "RABBUNI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Fa",
          text: "Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do-   Do",
          text: "RABBUNI!",
        },
      ],
    },
  ],
  subtitle: "(Gv 20, 13-17)",
};
