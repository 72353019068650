import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Tu che abiti nei giardini.",
  url_video: "https://www.youtube.com/embed/vwnrRHr9cWg",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Re-",
          text: "Tu che abiti nei giardini",
        },
        {
          notes: "   Fa                      Mi",
          text: "dove i compagni stanno in ascolto,",
        },
        {
          notes: "Fa",
          text: "fammi udire la tua voce",
        },
        {
          notes: "                   Mi",
          text: "fammi udire la tua voce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La",
          text: "FUGGI MIO DILETTO",
        },
        {
          notes: "           Mi",
          text: "SIMILE A GAZZELLA,",
        },
        {
          notes: "Fa#-",
          text: "COME UN CERBIATTO",
        },
        {
          notes: "Mi                   La",
          text: "SOPRA I MONTI DEGLI AROMI!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " La-            Re-",
          text: "IO SONO AI SUOI OCCHI",
        },
        {
          notes: "                          La-",
          text: "COME COLEI CHE HA TROVATO PACE,",
        },
        {
          notes: "              Mi             La-",
          text: "LA MIA VIGNA È QUI DAVANTI A ME,",
        },
        {
          notes: "              Mi             La-",
          text: "LA MIA VIGNA È QUI DAVANTI A ME.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La",
          text: "FUGGI MIO DILETTO",
        },
        {
          notes: "           Mi",
          text: "SIMILE A GAZZELLA,",
        },
        {
          notes: "Fa#-",
          text: "COME UN CERBIATTO",
        },
        {
          notes: "Mi                   La",
          text: "SOPRA I MONTI DEGLI AROMI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Re-",
          text: "Tu che abiti nei giardini",
        },
        {
          notes: "   Fa                      Mi",
          text: "dove i compagni stanno in ascolto,",
        },
        {
          notes: "Fa",
          text: "fammi udire la tua voce",
        },
        {
          notes: "                   Mi",
          text: "fammi udire la tua voce.",
        },
      ],
    },
    {
      speaker:
        "La Chiesa con il Battesimo è stata introdotta nei giardini del Regno “dove i compagni stanno in ascolto“. Lì, fatta madre e maestra di tutti i popoli per l'esperienza d'amore che ha avuto e per le sofferenze, le gioie, le cadute, i ritrovamenti e - possiamo dire - per la storia di salvezza che il Cantico dei Cantici esprime, viene invitata dallo sposo a far udire la sua voce in un desiderio finale. Lei risponde con un grido pasquale escatologico; quello che ha conosciuto, sperimentato dello Sposo le fa bramare di fuggire con lui in un ultimo esodo “sopra il monte degli aromi“ cioè in cielo, libera per sempre.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "(Ct 8, Appendici)",
};
