import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "C'erano due angeli.",
  url_video: "https://www.youtube.com/embed/lIRwp4Kkidc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re- La-",
          text: "C'erano due angeli,",
        },
        {
          notes: "          Re-      La-",
          text: "l'uno all'altro domanda:",
        },
        {
          notes: "    Re-    La-      Mi             La-",
          text: "\"Dov'è, dov'è il luogo della sua gloria?\"",
        },
        {
          notes: "    Re-    La-      Mi             La-",
          text: "\"DOV'È, DOV'È IL LUOGO DELLA SUA GLORIA?\"",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Re-",
          text: "Nella benedizione",
        },
        {
          notes: "             La-",
          text: "nella benedizione",
        },
        {
          notes: "             Re-",
          text: "nella benedizione",
        },
        {
          notes: "      Mi               La-",
          text: "è il luogo della sua gloria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Re-",
          text: "NELLA BENEDIZIONE",
        },
        {
          notes: "             La-",
          text: "NELLA BENEDIZIONE",
        },
        {
          notes: "             Re-",
          text: "NELLA BENEDIZIONE",
        },
        {
          notes: "      Mi               La-",
          text: "È IL LUOGO DELLA SUA GLORIA.",
        },
      ],
    },
  ],
  subtitle:
    "Nota: si ripete il medesimo testo, ogni volta mezzo tono più alto.",
};
