import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preghiera eucaristica II (Offerta, intercessioni dossologia).",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Celebrando il memoriale della morte",
        },
        {
          notes: "                        Mi-",
          text: "e risurrezione del tuo Figlio,",
        },
        {
          notes: "La-",
          text: "ti offriamo, Padre, il pane della vita",
        },
        {
          notes: "Mi-",
          text: "e il calice della salvezza",
        },
        {
          notes: "La-",
          text: "e ti rendiamo grazie per averci",
        },
        {
          notes: "",
          text: "ammessi alla tua presenza",
        },
        {
          notes: "Mi-",
          text: "a compiere il servizio sacerdotal",
        },
        {
          notes: "  Sol",
          text: "Ti preghiamo umilmente:",
        },
        {
          notes: "Si-",
          text: "per la comunione al corpo",
        },
        {
          notes: "",
          text: "e al sangue di Gesù Cristo,",
        },
        {
          notes: "La-",
          text: "lo Spirito Santo",
        },
        {
          notes: "                       Mi-",
          text: "ci riunisca in un solo corpo.",
        },
        {
          notes: "Sol",
          text: "Ricordati, Padre, della tua Chiesa",
        },
        {
          notes: "                     Mi-",
          text: "diffusa su tutta la terra:",
        },
        {
          notes: "La-",
          text: "rendila perfetta nell'amore",
        },
        {
          notes: "Mi-",
          text: "in unione al nostro Papa...",
        },
        {
          notes: "La-",
          text: "al nostro Vescovo...",
        },
        {
          notes: "Mi-",
          text: "e a tutto l'ordine sacerdotale.",
        },
        {
          notes: "Sol",
          text: "Ricordati dei nostri fratelli",
        },
        {
          notes: "Si-",
          text: "che si sono addormentati nella speranza",
        },
        {
          notes: "",
          text: "della risurrezione",
        },
        {
          notes: "La-",
          text: "e di tutti i defunti",
        },
        {
          notes: "                            Mi-",
          text: "che si affidano alla tua clemenza:",
        },
        {
          notes: "Sol                   Si-          Mi-",
          text: "ammettili a godere la luce del tuo volto.",
        },
        {
          notes: "Sol                      Mi-",
          text: "Di noi tutti abbi misericordia,",
        },
        {
          notes: "La-",
          text: "donaci di aver parte alla vita eterna",
        },
        {
          notes: "Mi-",
          text: "insieme con la Beata Maria Vergine",
        },
        {
          notes: "",
          text: "madre di Dio,",
        },
        {
          notes: "La-",
          text: "con gli Apostoli e tutti i santi",
        },
        {
          notes: "Mi-",
          text: "che in ogni tempo ti furono graditi",
        },
        {
          notes: "Sol",
          text: "e in Gesù Cristo tuo Figlio",
        },
        {
          notes: "     Si-        La           Mi-",
          text: "canteremo, canteremo la tua gloria!",
        },
        {
          notes: "Sol",
          text: "Per Cristo, con Cristo e in Cristo,",
        },
        {
          notes: "  Si-",
          text: "a te, Dio Padre onnipotente,",
        },
        {
          notes: "La-",
          text: "nell'unità dello Spirito Santo,",
        },
        {
          notes: "                    Mi-",
          text: "ogni onore ed ogni gloria,",
        },
        {
          notes: "Sol",
          text: "per tutti i secoli dei secoli,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-   La    Mi-",
          text: "AMEN, AMEN, AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
