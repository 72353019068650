import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alla vittima pasquale.",
  url_video: "https://www.youtube.com/embed/Z_GHRAF4E_c",
  barrè: "V",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    La7",
          text: "Alla vittima pasquale",
        },
        {
          notes: "                                   Re-",
          text: "si innalzi oggi il sacrificio di lode:",
        },
        {
          notes: "Fa                         Mi",
          text: "l'agnello ha redento il suo gregge",
        },
        {
          notes: "Re-        Fa                               Mi",
          text: "l'innocente ha riconciliato noi peccatori al Padre.",
        },
        {
          notes: "La-      La",
          text: "Morte e vita si sono affrontate",
        },
        {
          notes: "                 Re-",
          text: "in un prodigioso duello",
        },
        {
          notes: "Fa                    Mi",
          text: "l'autore della vita era morto",
        },
        {
          notes: "      Re-   Fa      Mi",
          text: "ma adesso è vivo e trionfa.",
        },
        {
          notes: "La7",
          text: "Raccontaci Maria cosa hai visto per la via:",
        },
        {
          notes: "    Re-               Mi",
          text: "tomba del Signore vuota,",
        },
        {
          notes: "     Re-                  Mi",
          text: "la gloria del Signore e vivo Cristo,",
        },
        {
          notes: "      Fa                      Mi",
          text: "gli angeli, le bende e il sudario.",
        },
        {
          notes: "  Re-                             Mi",
          text: "Perché Cristo mia speranza è risorto",
        },
        {
          notes: "Fa                 Mi",
          text: "e ci precede in Galilea",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                 Mi",
          text: "E CI PRECEDE IN GALILEA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Mi",
          text: "Sì ne siamo certi:",
        },
        {
          notes: " Fa                 Mi",
          text: "Cristo è davvero risorto!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Mi",
          text: "SÌ NE SIAMO CERTI",
        },
        {
          notes: " Fa                 Mi",
          text: "CRISTO È DAVVERO RISORTO!",
        },
        {
          notes: "Fa                Mi",
          text: "E CI PRECEDE IN GALILEA.",
        },
        {
          notes: "Fa                Mi",
          text: "E CI PRECEDE IN GALILEA.",
        },
      ],
    },
    {
      speaker:
        "(Alla fine, l'A. può ripetere i primi quattro versi del canto).",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                                Mi",
          text: "Tu, re immortale, donaci la tua salvezza!",
        },
      ],
    },
  ],
  subtitle: "",
};
