import { useEffect, useState } from "react";
import { Song } from "../../Songs/Classic/it/Servo_per_amore";
import "./NavigateTable.scss";

export interface NavigateTableProps {
  lines: JSX.Element[];
  selectedLine: (index: number) => void;
}

let INDEX = -1;
let MAX_SIZE = 0;

const NavigateTable = (props: NavigateTableProps) => {
  const [index, setIndex] = useState<number>(-1);
  const [lines, setLines] = useState<JSX.Element[]>([]);

  const setIndexOuter = (i: number) => {
    INDEX = i;
    setIndex(INDEX);
  };

  const downHandler = (e: any) => {
    if (e.key === "Enter" && (INDEX !== -1 || MAX_SIZE === 1)) {
      if (INDEX !== -1) {
        props.selectedLine(INDEX);
      } else {
        props.selectedLine(0);
      }
    }
    if (e.key === "ArrowDown") {
      let i = INDEX + 1;
      if (i >= MAX_SIZE) {
        i = MAX_SIZE - 1;
      }
      setIndexOuter(i);
    }
    if (e.key === "ArrowUp") {
      let i = INDEX - 1;
      if (i < 0) {
        i = 0;
      }
      setIndexOuter(i);
    }
  };

  useEffect(() => {
    MAX_SIZE = props.lines.length;
    setLines(props.lines);
    setIndexOuter(-1);
    window.document.onkeydown = downHandler;
    return () => {
      window.document.onkeydown = null;
    };
  }, [props.lines]);

  return (
    <div>
      {lines.map((x: JSX.Element, i: number) => {
        return (
          <div
            onMouseEnter={() => {
              setIndexOuter(i);
            }}
            className="navigate-table-row"
            key={i}
            style={index === -1 || i === index ? {} : { opacity: "0.2" }}
          >
            <span>{i + 1}</span>
            <span className="navigate-table-row-content">{x}</span>
          </div>
        );
      })}
    </div>
  );
};

export default NavigateTable;
