import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto di balaam.",
  url_video: "https://www.youtube.com/embed/b9Yal5w2Y7Y",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                 Re",
          text: "Da Aram mi ha fatto venire",
        },
        {
          notes: "Mi-              Re",
          text: "Balak, il re di Moab,",
        },
        {
          notes: "                    Mi-",
          text: "dalle montagne d'Oriente:",
        },
        {
          notes: " Re                  Mi-",
          text: "vieni e maledicimi Giacobbe,",
        },
        {
          notes: " Re                                Mi-",
          text: "vieni e profetizza per me contro Israele.",
        },
        {
          notes: " Sol       Re  Sol Si-         Mi-",
          text: "Come maledirò se Dio non mi lascia?",
        },
        {
          notes: " Sol          Re   Sol Si-      Mi-",
          text: "Come profetizzerò se Dio non lo vuole?",
        },
        {
          notes: "      Re                       Mi-",
          text: "Dall'alto di questa rupe io lo vedo,",
        },
        {
          notes: "      Re                       Mi-",
          text: "dall'alto di questa cima lo contemplo:",
        },
        {
          notes: " Re",
          text: "questo è un popolo diverso",
        },
        {
          notes: "              Mi-",
          text: "da tutte le nazioni!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                  Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol   Re     Sol Si-   Mi-",
          text: "Chi conterà la tua moltitudine,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Mi-",
          text: "GIACOBBE, GIACOBBE,",
        },
        {
          notes: " Fa                 Mi",
          text: "GIACOBBE, GIACOBBE?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                  Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-               Re",
          text: "Ma che cosa stai facendo?",
        },
        {
          notes: "                 Mi-",
          text: "Tu lo stai benedicendo;",
        },
        {
          notes: " Re",
          text: "vieni da un'altra parte,",
        },
        {
          notes: "                           Mi-",
          text: "forse di là Dio lo vorrà maledire.",
        },
        {
          notes: "  Sol                 Re",
          text: "Ascolta, figlio di Zippor,",
        },
        {
          notes: "        Sol    Si-   Mi-",
          text: "porgi l'orecchio, re Balak,",
        },
        {
          notes: "    Re",
          text: "che Dio non è un uomo",
        },
        {
          notes: "                  Mi-",
          text: "che dice e poi si pente:",
        },
        {
          notes: "    Re                 Mi-",
          text: "una stella sorge da Giacobbe,",
        },
        {
          notes: "Re                        Mi-",
          text: "sento acclamazioni per un re,",
        },
        {
          notes: " Re              Mi-",
          text: "Dio, Dio è suo padre:",
        },
        {
          notes: "       Re            Mi-",
          text: "si accovaccia come leone,",
        },
        {
          notes: "     Re            Mi-",
          text: "si distende come leonessa,",
        },
        {
          notes: "Re               Mi-",
          text: "nessuno lo farà rialzare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                  Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol         Re",
          text: "Sia la mia morte",
        },
        {
          notes: "        Sol Si-         Mi-",
          text: "come la morte del tuo Giusto,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                  Mi-",
          text: "SIA LA MIA VITA DOV'È LUI,",
        },
        {
          notes: "  Re                  Mi-",
          text: "SIA LA MIA VITA DOV'È LUI.",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
        {
          notes: "                      Re",
          text: "CHE BELLE SONO LE TUE TENDE",
        },
        {
          notes: "                  Mi-",
          text: "CHE BELLE SONO ISRAELE.",
        },
      ],
    },
  ],
  subtitle: "(Nm 23, 7-24)",
};
