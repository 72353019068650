import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Abramo.",
  url_video: "https://www.youtube.com/embed/ZoUrgLHxnmc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Re- ",
          text: "Faceva caldo quel giorno",
        },
        {
          notes: "          Mi ",
          text: "quando Abramo",
        },
        {
          notes: "                              La- ",
          text: "era seduto davanti alla sua tenda.",
        },
        {
          notes: "                         Re- ",
          text: "Faceva caldo quel giorno",
        },
        {
          notes: "          Mi ",
          text: "quando Abramo",
        },
        {
          notes: "                                 La- ",
          text: "era seduto sotto la quercia di Mamre.",
        },
        {
          notes: "             Sol     Fa ",
          text: "Alzati gli occhi guardò",
        },
        {
          notes: "                       Mi ",
          text: "ed ecco tre uomini in piedi,",
        },
        {
          notes: "               La- ",
          text: "gli stavano davanti.",
        },
        {
          notes: "           Mi ",
          text: "Appena li vide ",
        },
        {
          notes: "",
          text: "si inchinò fino a terra",
        },
        {
          notes: "   La- ",
          text: "e disse:",
        },
        {
          notes: "         Sol ",
          text: "O Signor mio",
        },
        {
          notes: "                Fa ",
          text: "non passare ti prego",
        },
        {
          notes: "          Mi ",
          text: "senza fermarti!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La- ",
          text: "SENZA FERMARTI!",
        },
        {
          notes: "                 Sol ",
          text: "NON PASSARE TI PREGO",
        },
        {
          notes: "         Fa ",
          text: "SENZA FERMARTI",
        },
        {
          notes: "        Mi ",
          text: "SENZA FERMARTI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Mi ",
          text: "Vi porterò un po' d'acqua, ",
        },
        {
          notes: "",
          text: "vi laverete i piedi",
        },
        {
          notes: "                    La- ",
          text: "e vi riposerete all'ombra.",
        },
        {
          notes: "                 Mi ",
          text: "Vi porterò un boccone, ",
        },
        {
          notes: "",
          text: "vi rifocillerete",
        },
        {
          notes: "               La- ",
          text: "e poi andrete oltre.",
        },
        {
          notes: "        Sol                 Fa ",
          text: "Non per caso siete passati oggi",
        },
        {
          notes: "          Mi ",
          text: "davanti a me. ",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La- ",
          text: "O SIGNOR MIO",
        },
        {
          notes: "                Sol ",
          text: "NON PASSARE TI PREGO",
        },
        {
          notes: "        Fa ",
          text: "SENZA FERMARTI",
        },
        {
          notes: "        Mi ",
          text: "SENZA FERMARTI!",
        },
        {
          notes: "        La- ",
          text: "SENZA FERMARTI",
        },
        {
          notes: "                Sol ",
          text: "NON PASSARE TI PREGO",
        },
        {
          notes: "        Fa ",
          text: "SENZA FERMARTI",
        },
        {
          notes: "        Mi ",
          text: "SENZA FERMARTI! ",
        },
      ],
    },
  ],
  subtitle: "(Gen  18, 1-5)",
};
