import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Innalzerò la coppa di salvezza.",
  url_video: "https://www.youtube.com/embed/O8Hs3zoE2gY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Fa#-",
          text: "Ho creduto anche quando ho detto:",
        },
        {
          notes: "Re7           Mi-",
          text: "sono molto disgraziato!",
        },
        {
          notes: "Sol                       La",
          text: "ho detto nella mia afflizione:",
        },
        {
          notes: "              Re",
          text: "ogni uomo è falso!",
        },
        {
          notes: "                                  Fa#-",
          text: "Ma il Signore mi ha tratto dalla morte",
        },
        {
          notes: "Re7                    Mi-",
          text: "ricordando la sua misericordia",
        },
        {
          notes: "Sol                    La",
          text: "ed ora torna al tuo riposo,",
        },
        {
          notes: "      Re",
          text: "anima mia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol              La          Si-",
          text: "CHE COSA POSSO RENDERE AL SIGNORE",
        },
        {
          notes: "        Sol             La",
          text: "PER IL BENE CHE MI HA FATTO?",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "      Re        Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "      Fa#-      Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "              Fa#-",
          text: "LA COPPA DI SALVEZZA",
        },
        {
          notes: "        Si-   Mi7       La",
          text: "E INVOCHERÒ IL NOME DEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Fa#- ",
          text: "Io ti offro di grazie un sacrificio",
        },
        {
          notes: "Re7                   Mi- ",
          text: "gridando il tuo nome, mio Signore",
        },
        {
          notes: "Sol                       La ",
          text: "perché ti sei rivolto alla mia sorte",
        },
        {
          notes: "            Re ",
          text: "e mi hai salvato.",
        },
        {
          notes: "                          Fa#- ",
          text: "La mia preghiera innalzo fino a te",
        },
        {
          notes: "Re7                    Mi- ",
          text: "insieme a tutta la tua chiesa,",
        },
        {
          notes: "Sol                    La ",
          text: "camminerò alla tua presenza",
        },
        {
          notes: "         Re",
          text: "in mezzo ai vivi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol             La           Si-",
          text: "PREZIOSA È AGLI OCCHI DEL SIGNORE",
        },
        {
          notes: "Sol               La",
          text: "LA MORTE DEI SUOI FIGLI",
        },
        {
          notes: "      Re        Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "      Fa#-      Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "              Fa#-",
          text: "LA COPPA DI SALVEZZA",
        },
        {
          notes: "        Si-   Mi7       La",
          text: "E INVOCHERÒ IL NOME DEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Fa#- ",
          text: "Mi hanno avvolto i lacci della morte",
        },
        {
          notes: "Re7                           Mi- ",
          text: "mi han sorpreso le strette del destino,",
        },
        {
          notes: "Sol                       La ",
          text: "ho invocato il nome del Signore",
        },
        {
          notes: "            Re ",
          text: "e mi ha salvato.",
        },
        {
          notes: "                          Fa#- ",
          text: "Anima mia torna al tuo riposo,",
        },
        {
          notes: "Re7                        Mi- ",
          text: "camminerai al cospetto del Signore,",
        },
        {
          notes: "Sol                    La ",
          text: "negli atrii della casa del tuo Dio,",
        },
        {
          notes: "      Re",
          text: "il Dio dei vivi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol              La          Si-",
          text: "CHE COSA POSSO RENDERE AL SIGNORE",
        },
        {
          notes: "        Sol             La",
          text: "PER IL BENE CHE MI HA FATTO?",
        },
        {
          notes: "      Re        Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "      Fa#-      Sol",
          text: "PRENDERÒ, INNALZERÒ",
        },
        {
          notes: "              Fa#-",
          text: "LA COPPA DI SALVEZZA",
        },
        {
          notes: "        Si-   Mi7       La",
          text: "E INVOCHERÒ IL NOME DEL SIGNORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 114 - 115(116)",
};
