import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Sale dio tra acclamazioni.",
  url_video: "https://www.youtube.com/embed/XOuMhgNiJDU",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA,",
        },
        {
          notes: "                      Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                         Re-",
          text: "Popoli tutti, battete le mani,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                       La-",
          text: "POPOLI TUTTI BATTETE LE MANI!",
        },
        {
          notes: "                         Re-",
          text: "POPOLI TUTTI BATTETE LE MANI,",
        },
        {
          notes: "                        La-",
          text: "POPOLI TUTTI BATTETE LE MANI!",
        },
        {
          notes: "La-                   Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA,",
        },
        {
          notes: "                      Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Re-",
          text: "Cantate a Dio, cantate,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                La-",
          text: "CANTATE A DIO, CANTATE!",
        },
        {
          notes: "                 Re-",
          text: "CANTATE A DIO, CANTATE,",
        },
        {
          notes: "                 La-",
          text: "CANTATE A DIO, CANTATE!",
        },
        {
          notes: "La-                   Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA,",
        },
        {
          notes: "                      Re-",
          text: "SALE DIO TRA ACCLAMAZIONI",
        },
        {
          notes: "                     La-",
          text: "AL CLANGORE DELLA TROMBA.",
        },
      ],
    },
  ],
  subtitle: "Sal 46(47)",
};
