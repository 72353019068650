import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preghiera eucaristica II (Prefazio).",
  url_video: "",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Sol     La-",
          text: "Il Signore sia con voi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Sol         La-",
          text: "E CON IL TUO SPIRITO",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "                Sol",
          text: "In alto i nostri cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                 La-",
          text: "SONO RIVOLTI AL SIGNORE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                               La-",
          text: "Rendiamo grazie al Signore nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol            La-",
          text: "È COSA BUONA E GIUSTA.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "           Sol",
          text: "È veramente cosa buona e giusta,",
        },
        {
          notes: "                            La-",
          text: "nostro dovere e fonte di salvezza,",
        },
        {
          notes: "Sol            La-",
          text: "rendere grazie sempre e in ogni luogo",
        },
        {
          notes: "",
          text: "a te, Padre santo,",
        },
        {
          notes: "      Sol                         La-",
          text: "per Gesù Cristo, tuo dilettissimo Figlio.",
        },
        {
          notes: "                                 Sol",
          text: "Per mezzo di lui, tua Parola vivente,",
        },
        {
          notes: "                    La-",
          text: "hai creato tutte le cose",
        },
        {
          notes: "                   Fa   La-",
          text: "e lo hai mandato a noi salvatore e redentore,",
        },
        {
          notes: "Sol",
          text: "fatto uomo per opera dello Spirito Santo",
        },
        {
          notes: "                     La-",
          text: "e nato dalla Vergine Maria.",
        },
        {
          notes: "Sol                      La-",
          text: "Per compiere la tua volontà",
        },
        {
          notes: "",
          text: "e acquistarti un popolo santo,",
        },
        {
          notes: "     Sol                    La-",
          text: "egli stese le braccia sulla croce,",
        },
        {
          notes: "Sol                  La-",
          text: "morendo distrusse la morte",
        },
        {
          notes: "           Sol       La-",
          text: "e proclamò la risurrezione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           Sol        La-",
          text: "E PROCLAMÒ LA RISURREZIONE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Per questo mistero di salvezza",
        },
        {
          notes: "",
          text: "uniti agli angeli e ai santi",
        },
        {
          notes: "Sol",
          text: "cantiamo a una sola voce",
        },
        {
          notes: "                   La-",
          text: "l'inno della tua gloria.",
        },
      ],
    },
    {
      speaker: "\n\n    Proseguire col “SANTO“ del tempo liturgico\n",
      mode: "red-utility-message",
      lines: [],
    },
  ],
  subtitle: "",
};
