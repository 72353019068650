import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Figlie di gerusalemme.",
  url_video: "https://www.youtube.com/embed/AYUY6h7vy6g",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "FIGLIE DI GERUSALEMME",
        },
        {
          notes: "        Fa                     La-",
          text: "NON PIANGETE, NON PIANGETE PER ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Fa",
          text: "Ché se così si fa con il legno verde",
        },
        {
          notes: "        Sol          La-",
          text: "con il secco che avverrà,",
        },
        {
          notes: "        Fa          Mi    La-",
          text: "con il secco che avverrà? Ahi!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "FIGLIE DI GERUSALEMME",
        },
        {
          notes: "        Fa                     La-",
          text: "NON PIANGETE, NON PIANGETE PER ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Padre, perdonali,",
        },
        {
          notes: "           Fa                La-",
          text: "perché non sanno quello che fanno;",
        },
        {
          notes: "   Fa          Mi     La-",
          text: "perdonali, perdonali. Ahi!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "FIGLIE DI GERUSALEMME",
        },
        {
          notes: "        Fa                     La-",
          text: "NON PIANGETE, NON PIANGETE PER ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Mi",
          text: "Io ti assicuro:",
        },
        {
          notes: "               Fa         Mi",
          text: "oggi sarai con me nel paradiso!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "FIGLIE DI GERUSALEMME",
        },
        {
          notes: "        Fa                     La-",
          text: "NON PIANGETE, NON PIANGETE PER ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-    Fa",
          text: "Padre, Padre",
        },
        {
          notes: "          Sol                  La-",
          text: "nelle tue mani affido il mio spirito.",
        },
        {
          notes: "Fa  Mi  La-",
          text: "Ah, ah, ah!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "FIGLIE DI GERUSALEMME",
        },
        {
          notes: "        Fa                     La-",
          text: "NON PIANGETE, NON PIANGETE PER ME.",
        },
      ],
    },
  ],
  subtitle: "(Lc. 23, 28.31.34.43.46) (Marcia funebre)",
};
