import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lodate il Signore.",
  url_video: "https://www.youtube.com/embed/fGq_hA8mwzA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "LODATE IL SIGNORE,",
        },
        {
          notes: "                             Do",
          text: "TUTTI I POPOLI DELLA TERRA,",
        },
        {
          notes: "                  Si7",
          text: "NAZIONI, DATEGLI GLORIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                   La-",
          text: "Perché forte è il suo amore per noi,",
        },
        {
          notes: "                                  Do",
          text: "e la sua fedeltà dura in eterno,",
        },
        {
          notes: "                      Si7",
          text: "nazioni, lodate il Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "LODATE IL SIGNORE,",
        },
        {
          notes: "                             Do",
          text: "TUTTI I POPOLI DELLA TERRA,",
        },
        {
          notes: "                  Si7",
          text: "NAZIONI, DATEGLI GLORIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                    La-",
          text: "Perché grande è il suo amore per noi,",
        },
        {
          notes: "                                 Do",
          text: "e la sua misericordia è eterna,",
        },
        {
          notes: "               Si7",
          text: "nazioni, dategli gloria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "LODATE IL SIGNORE,",
        },
        {
          notes: "                             Do",
          text: "TUTTI I POPOLI DELLA TERRA,",
        },
        {
          notes: "                  Si7",
          text: "NAZIONI, DATEGLI GLORIA.",
        },
      ],
    },
  ],
  subtitle: "Sal 116(117)",
};
