import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Tu_sei_la_mia_vita.",
  title: "Tu sei la mia vita.",
  url_video: "https://www.youtube.com/embed/qVUpS7-VmV0",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Do    Re           Sol",
          text: "Tu sei la mia vita, altro io non ho",
        },
        {
          notes: "Mi-           Do      Re     La- Si7",
          text: "Tu sei la mia strada, la mia verità",
        },
        {
          notes: "La-         Re7  Sol       Mi-",
          text: "Nella Tua parola io camminerò",
        },
        {
          notes: "La-            Re           Sol7         Si7",
          text: "finché avrò respiro, fino a quando Tu vorrai",
        },
        {
          notes: "La-       Re         Sol           Mi-",
          text: "Non avrò paura, sai, se Tu sei con me",
        },
        {
          notes: "La-         Re        Mi-",
          text: "io Ti prego resta con me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Do     Re        Sol",
          text: "Credo in Te Signore, nato da Maria,",
        },
        {
          notes: "Mi-             Do     Re   La-  Si7",
          text: "Figlio eterno e Santo, Uomo come noi,",
        },
        {
          notes: "La-        Re7   Sol             Mi-",
          text: "morto per Amore, vivo in mezzo a noi,",
        },
        {
          notes: "La-      Re          Sol6          Si7",
          text: "una cosa sola con il Padre e con i tuoi",
        },
        {
          notes: "La-            Re        Sol       Mi-",
          text: "fino a quando, io lo so, Tu ritornerai",
        },
        {
          notes: "La-            Re       Mi-",
          text: "per aprirci il Regno di Dio.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Do     Re7          Sol",
          text: "Tu sei la mia forza, altro io non ho",
        },
        {
          notes: "Mi-           Do    Re     La-  Si7",
          text: "Tu sei la mia pace, la mia libertà",
        },
        {
          notes: "La-          Re7  Sol      Mi-",
          text: "Niente nella vita ci separerà",
        },
        {
          notes: "La-           Re         Sol6        Si7",
          text: "So che la Tua mano forte non mi lascerà,",
        },
        {
          notes: "La-            Re      Sol      Mi-",
          text: "so che da ogni male Tu mi libererai",
        },
        {
          notes: "La-          Re      Mi-",
          text: "e nel Tuo perdono vivrò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-         Do    Re7             Sol",
          text: "Padre della vita, noi crediamo in Te",
        },
        {
          notes: "Mi-         Do    Re     La-      Si7",
          text: "Figlio Salvatore, noi speriamo in Te",
        },
        {
          notes: "La-        Re7   Sol              Mi-",
          text: "Spirito d’amore, vieni in mezzo a noi",
        },
        {
          notes: "La-         Re          Sol6       Si7",
          text: "Tu da mille strade ci raduni in unità",
        },
        {
          notes: "La-         Re           Sol        Mi-",
          text: "e per mille strade, poi, dove Tu vorrai,",
        },
        {
          notes: "La-           Re      Mi-",
          text: "noi saremo il seme di Dio.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
