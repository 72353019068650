import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Acqua_azzurra,_acqua_chiara.",
  title: "Acqua azzurra, acqua chiara.",
  url_video: "https://www.youtube.com/embed/jkRG8A9Kk_U",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               La",
          text: "Ogni notte ritornar",
        },
        {
          notes: "                        Mi",
          text: "per cercarla in qualche bar,",
        },
        {
          notes: "                   Si-",
          text: "domandare ciao che fai",
        },
        {
          notes: "         Mi             La",
          text: "e poi uscire insieme a lei.",
        },
        {
          notes: " ",
          text: "Ma da quando ci sei tu",
        },
        {
          notes: "         Mi-         Si-",
          text: "tutto questo non c'è più.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "         Re           La",
          text: "Acqua azzurra, acqua chiara",
        },
        {
          notes: "       Sol                     Mi- Sol La",
          text: "con le mani posso finalmente bere.",
        },
        {
          notes: "          Re        La",
          text: "Nei tuoi occhi innocenti",
        },
        {
          notes: "      Sol           La",
          text: "posso ancora ritrovare",
        },
        {
          notes: "      Sol                La",
          text: "il profumo di un amore puro,",
        },
        {
          notes: "     Sol            La",
          text: "puro come il tuo amor.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               La",
          text: "Ti telefono se vuoi",
        },
        {
          notes: "                        Mi",
          text: "non so ancora se c'è lui ...",
        },
        {
          notes: "                   Si-",
          text: "accidenti che farò",
        },
        {
          notes: "         Mi             La",
          text: "quattro amici troverò.",
        },
        {
          notes: "",
          text: "Ma da quando ci sei tu",
        },
        {
          notes: "         Mi-         Si-",
          text: "tutto questo non c'è più.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Da quando ci sei tu",
        },
        {
          notes: "",
          text: "tutto questo non c'è più.",
        },
        {
          notes: "         Re             La",
          text: "Acqua azzurra, acqua chiara",
        },
        {
          notes: "       Sol                     Mi- Sol La",
          text: "con le mani posso finalmente bere.",
        },
        {
          notes: "          Re       La",
          text: "Nei tuoi occhi innocenti",
        },
        {
          notes: "       Sol          La",
          text: "posso ancora ritrovare",
        },
        {
          notes: "      Sol                La",
          text: "il profumo di un amore puro,",
        },
        {
          notes: "     Sol            La",
          text: "puro come il tuo amor.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                         Si",
          text: "Sono le quattro e mezza ormai",
        },
        {
          notes: "                 Mi-",
          text: "non ho voglia di dormir",
        },
        {
          notes: "                   Do#-",
          text: "a quest'ora cosa vuoi,",
        },
        {
          notes: "      Mi-     Si",
          text: "mi va bene pure lei.",
        },
        {
          notes: "                     Si",
          text: "Ma da quando ci sei tu",
        },
        {
          notes: "      Sol#              Do#-",
          text: "tutto questo non c'è più.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Mi                Si",
          text: "Acqua azzurra, acqua chiara",
        },
        {
          notes: "       La                     Sol# La Si",
          text: "con le mani posso finalmente bere.",
        },
        {
          notes: "         Mi           Si        La Sol# La Si",
          text: "Acqua azzurra, acqua chiara",
        },
        {
          notes: "           Mi      Si          La Sol# La Si",
          text: "Nei tuoi occhi innocenti",
        },
        {
          notes: "Mi Si La Sol# La Si ",
          text: "",
        },
      ],
    },
  ],
  subtitle: "Lucio Battisti",
  sent_for_revision: false,
};
