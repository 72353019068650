import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Shlom lech mariàm.",
  url_video: "https://www.youtube.com/embed/SX5aLhPo1Rw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-   Si7    Mi-",
          text: "SHLOM LECH MARIÀM",
        },
        {
          notes: "       Si7 Mi-",
          text: "MALIAT TAIBUTÀ",
        },
        {
          notes: "Do             Si7",
          text: "MARÁN, MARÁN AMECH,",
        },
        {
          notes: "Do             Si7",
          text: "MARÁN, MARÁN AMECH.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Ave Maria, piena di grazia,",
        },
        {
          notes: " Do                         Si7",
          text: "il Signore, il Signore è con te,",
        },
        {
          notes: " Do                     Si7",
          text: "benedetta sei tu fra le donne",
        },
        {
          notes: "Do                La-                  Si7",
          text: "e benedetto è il frutto del tuo seno, Gesù.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi",
          text: "SANTA, SANTA MARIA,",
        },
        {
          notes: "",
          text: "MADRE, MADRE DI DIO,",
        },
        {
          notes: "Do               Si7",
          text: "PREGA, PREGA PER NOI,",
        },
        {
          notes: "    Do                     Si",
          text: "CHE SIAMO, CHE SIAMO PECCATORI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Si7",
          text: "Maria, non piangere!",
        },
        {
          notes: "La-                          Mi-",
          text: "Maria, Maria, Maria, a, a, a,...",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Mi",
          text: "SANTA, SANTA MARIA,",
        },
        {
          notes: "",
          text: "MADRE, MADRE DI DIO,",
        },
        {
          notes: "Do               Si7",
          text: "PREGA, PREGA PER NOI,",
        },
        {
          notes: "    Do                     Si",
          text: "CHE SIAMO, CHE SIAMO PECCATORI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-      Si7     La-    Si7",
          text: "Maria, aiutaci, Maria aiutaci!",
        },
        {
          notes: " La-             Si7",
          text: "Vergine Maria, aiutaci,",
        },
        {
          notes: " La-                                                 Si7",
          text: "Vergine Maria, a, a, a,... si compia in noi la sua parola.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-   Si7    Mi-",
          text: "SHLOM LECH MARIÀM",
        },
        {
          notes: "       Si7 Mi-",
          text: "MALIAT TAIBUTÀ",
        },
        {
          notes: "Do             Si7",
          text: "MARÁN, MARÁN AMECH,",
        },
        {
          notes: "Do             Si7",
          text: "MARÁN, MARÁN AMECH.",
        },
      ],
    },
  ],
  subtitle: "",
};
