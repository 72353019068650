import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Gloria, gloria, gloria.",
  url_video: "https://www.youtube.com/embed/oJoF0SJAzoo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi",
          text: "Io vengo a riunir",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi",
          text: "IO VENGO A RIUNIR",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La           Mi",
          text: "tutte le nazioni",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   La           Mi",
          text: "TUTTE LE NAZIONI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Fa#                           Si7",
          text: "Verranno, e vedranno la mia Gloria",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   La                            Mi",
          text: "VERRANNO, E VEDRANNO LA MIA GLORIA",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Do#-",
          text: "Metterò su di loro un segno",
        },
        {
          notes: "   La                               Mi",
          text: "e li invierò alle nazioni più lontane",
        },
        {
          notes: "   Si7                     Mi",
          text: "per annunziare la mia gloria,",
        },
        {
          notes: "   Si7                     Mi",
          text: "per annunziare la mia gloria.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol#                      La",
          text: "E raduneranno i vostri fratelli",
        },
        {
          notes: "                 Mi",
          text: "da tutte le nazioni",
        },
        {
          notes: "   Si7                  Mi",
          text: "come dono per il Signore,",
        },
        {
          notes: "   Si7                  Mi",
          text: "come dono per il Signore.",
        },
        {
          notes: "   Do#-         Sol#",
          text: "E tra di essi prenderò",
        },
        {
          notes: "   Si        Sol#",
          text: "sacerdoti per me",
        },
        {
          notes: "   La                              Mi",
          text: "sacerdoti che annunzino la mia Gloria",
        },
        {
          notes: "   Si7                             Mi",
          text: "sacerdoti che annunzino la mia Gloria.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Do#-",
          text: "Perchè così come i nuovi cieli",
        },
        {
          notes: "                         Sol#",
          text: "e la nuova terra che io faccio",
        },
        {
          notes: "   Si              Sol#",
          text: "dureranno per sempre,",
        },
        {
          notes: " La     Mi       Si7                  Mi",
          text: "così durerà la loro discendenza per sempre.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
        {
          notes: "La  Mi  La  Mi  Si7 Mi",
          text: "GLORIA, GLORIA, GLORIA",
        },
      ],
    },
  ],
  subtitle: "(Is 66, 18 ss)",
};
