import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "C'era_un_ragazzo.",
  title: "C'era un ragazzo.",
  url_video: "https://www.youtube.com/embed/ArBfa5LNixE",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi                Si",
          text: " C'era un ragazzo che come me",
        },
        {
          notes: "La                   Si",
          text: " amava i Beatles e i Rolling Stones",
        },
        {
          notes: "Mi               Si",
          text: " girava il mondo veniva da",
        },
        {
          notes: "La                   Si",
          text: " gli Stati Uniti d'America.",
        },
        {
          notes: "Mi                   Si",
          text: " Non era bello ma accanto a sé",
        },
        {
          notes: "  La          Si",
          text: " aveva mille donne se",
        },
        {
          notes: "Mi               Si",
          text: " cantava Help e Ticket to Ride",
        },
        {
          notes: "   La           Si",
          text: " o Lady Jane o Yesterday",
        },
        {
          notes: "Mi             La",
          text: " cantava ",
        },
        {
          notes: "Si                 Mi",
          text: " ma ricevette una lettera",
        },
        {
          notes: "Mi               La",
          text: " la sua chitarra mi regalò",
        },
        {
          notes: "Fa#                 Si",
          text: " fu richiamato in America.",
        },
        {
          notes: "Mi     Sol   Re  La    Si",
          text: "Stop! Coi Rolling Stones!",
        },
        {
          notes: "Mi     Sol   Re  La    Si",
          text: "Stop! Coi Beatles stop!",
        },
        {
          notes: "     La     Sol#-    Fa#- Mi",
          text: "M'ha detto va nel Vietnam",
        },
        {
          notes: "  La        Sol#- Si    Mi",
          text: "e spara ai Vietcong.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: " Rata-ta-ta-ta, tata-ta-ta-ta",
        },
        {
          notes: " La",
          text: " tata-tata-ta, tata-tata-ta,",
        },
        {
          notes: " Si",
          text: " tata-tata-ta, tata-tata-ta,",
        },
        {
          notes: " Mi             Si",
          text: " tata-tata-ta, tata-tata-ta...",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi                Si",
          text: " C'era un ragazzo che come me",
        },
        {
          notes: "La                    Si",
          text: " amava i Beatles e i Rolling Stones",
        },
        {
          notes: "Mi               Si",
          text: " girava il mondo ma poi finì",
        },
        {
          notes: "La                    Si",
          text: " a far la guerra nel Vietnam.",
        },
        {
          notes: "Mi                   Si",
          text: " Capelli lunghi non porta più",
        },
        {
          notes: "     La           Si",
          text: " non suona la chitarra ma",
        },
        {
          notes: "Mi                  Si",
          text: " uno strumento che sempre dà",
        },
        {
          notes: "      La          Si",
          text: " la stessa nota: ratatata!",
        },
        {
          notes: "Mi                La",
          text: " Non ha più amici non ha più fans",
        },
        {
          notes: "Si                Mi",
          text: " vede la gente cadere giù",
        },
        {
          notes: "Mi             La",
          text: " nel suo paese non tornerà",
        },
        {
          notes: "Fa#                  Si",
          text: " adesso è morto nel Vietnam.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi     Sol   Re  La    Si",
          text: "Stop! Coi Rolling Stones!",
        },
        {
          notes: "Mi     Sol   Re  La    Si",
          text: "Stop! Coi Beatles stop!",
        },
        {
          notes: "    La        Sol#-   Fa#-     Mi",
          text: "Nel petto un cuore più non ha",
        },
        {
          notes: "   La     Sol#-      Si   Mi",
          text: "ma due medaglie o tre.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: " Rata-ta-ta-ta, tata-ta-ta-ta",
        },
        {
          notes: " La",
          text: " tata-tata-ta, tata-tata-ta,",
        },
        {
          notes: " Si",
          text: " tata-tata-ta, tata-tata-ta,",
        },
        {
          notes: " Mi             Si",
          text: " tata-tata-ta, tata-tata-ta...",
        },
      ],
    },
  ],
  subtitle: "Gianni Morandi",
  sent_for_revision: false,
};
