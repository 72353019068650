import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Per amore dei miei fratelli.",
  url_video: "https://www.youtube.com/embed/JuNTdV1rLEU",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   La-",
          text: "PER AMORE DEI MIEI FRATELLI",
        },
        {
          notes: "                   Mi-",
          text: "PER AMORE DEI MIEI AMICI",
        },
        {
          notes: "         La-",
          text: "IO DIRÒ, IO DIRÒ:",
        },
        {
          notes: "Si7       Mi-",
          text: "PACE SU DI TE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Che allegria quando mi dissero:",
        },
        {
          notes: "   La-                   Mi-",
          text: "andiamo alla casa del Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ED ORA I NOSTRI PIEDI",
        },
        {
          notes: "Si7                  Mi-",
          text: "SI FERMANO ALLE TUE PORTE.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     La-      Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     Si7         Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   La-",
          text: "PER AMORE DEI MIEI FRATELLI",
        },
        {
          notes: "                   Mi-",
          text: "PER AMORE DEI MIEI AMICI",
        },
        {
          notes: "         La-",
          text: "IO DIRÒ, IO DIRÒ:",
        },
        {
          notes: "Si7       Mi-",
          text: "PACE SU DI TE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Là salgono insieme",
        },
        {
          notes: "   La-          Mi-",
          text: "Le tribù del Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "SECONDO L'USO DI ISRAEL",
        },
        {
          notes: " Si7             Mi-",
          text: "PER CANTARE AL SIGNORE.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     La-      Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     Si7         Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   La-",
          text: "PER AMORE DEI MIEI FRATELLI",
        },
        {
          notes: "                   Mi-",
          text: "PER AMORE DEI MIEI AMICI",
        },
        {
          notes: "         La-",
          text: "IO DIRÒ, IO DIRÒ:",
        },
        {
          notes: "Si7       Mi-",
          text: "PACE SU DI TE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Chiedete pace, chiedete pace",
        },
        {
          notes: " La-       Mi-",
          text: "per Gerusalemme.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "E SIA LA PACE, E SIA LA PACE,",
        },
        {
          notes: " Si7             Mi-",
          text: "SU QUELLI CHE TI AMANO.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     La-      Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
        {
          notes: "",
          text: "GERUSALEM, GERUSALEM",
        },
        {
          notes: "     Si7         Mi-",
          text: "DI NUOVO RIEDIFICATA",
        },
      ],
    },
  ],
  subtitle: "Sal 121(122)",
};
