import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedetto sia iddio.",
  url_video: "https://www.youtube.com/embed/0TSbjBgmJ0o",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re    Mi-",
          text: "Benedetto",
        },
        {
          notes: "  La7  Re",
          text: "sia Iddio",
        },
        {
          notes: "    Mi-",
          text: "e padre",
        },
        {
          notes: " La7        Re",
          text: "di Gesù Cristo.",
        },
        {
          notes: " Fa#",
          text: "Che ci ha eletti",
        },
        {
          notes: "                Si-",
          text: "prima della creazione,",
        },
        {
          notes: " Fa#",
          text: "che ci ha benedetti",
        },
        {
          notes: "                    Si-",
          text: "con ogni sorta di amore,",
        },
        {
          notes: "     La                  Sol",
          text: "per essere santi ed immacolati",
        },
        {
          notes: "       Fa#",
          text: "nell'amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "PER ESSERE",
        },
        {
          notes: "",
          text: "LODE DELLA SUA GLORIA,",
        },
        {
          notes: "                         La",
          text: "DELLA GLORIA DELLA SUA GRAZIA,",
        },
        {
          notes: "                     Sol",
          text: "DELLA GRAZIA DEL SUO AMORE",
        },
        {
          notes: "",
          text: "CON IL QUALE CI AMÒ",
        },
        {
          notes: "         Fa#",
          text: "IN GESÙ CRISTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re    Mi-",
          text: "Benedetto",
        },
        {
          notes: "  La7  Re",
          text: "sia Iddio",
        },
        {
          notes: "    Mi-",
          text: "e padre",
        },
        {
          notes: " La7        Re",
          text: "di Gesù Cristo.",
        },
        {
          notes: " Fa#",
          text: "Che ci ha eletti",
        },
        {
          notes: "                Si-",
          text: "prima della creazione,",
        },
        {
          notes: " Fa#",
          text: "che ci ha benedetti",
        },
        {
          notes: "                    Si-",
          text: "con ogni sorta di amore,",
        },
        {
          notes: "     La                  Sol",
          text: "per essere santi ed immacolati",
        },
        {
          notes: "       Fa#",
          text: "nell'amore.",
        },
        {
          notes: "Fa#",
          text: "In lui abbiamo",
        },
        {
          notes: "          Si-",
          text: "la redenzione,",
        },
        {
          notes: "Fa#",
          text: "il perdono dei peccati ",
        },
        {
          notes: "                               Si-",
          text: "secondo la ricchezza del suo amore",
        },
        {
          notes: "La                          Sol",
          text: "perché nella pienezza dei tempi,",
        },
        {
          notes: "                    Fa#",
          text: "Cristo sia tutto in tutti!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "PER ESSERE",
        },
        {
          notes: "",
          text: "LODE DELLA SUA GLORIA,",
        },
        {
          notes: "                         La",
          text: "DELLA GLORIA DELLA SUA GRAZIA,",
        },
        {
          notes: "                     Sol",
          text: "DELLA GRAZIA DEL SUO AMORE",
        },
        {
          notes: "",
          text: "CON IL QUALE CI AMÒ",
        },
        {
          notes: "         Fa#",
          text: "IN GESÙ CRISTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re    Mi-",
          text: "Benedetto",
        },
        {
          notes: "  La7  Re",
          text: "sia Iddio",
        },
        {
          notes: "    Mi-",
          text: "e padre",
        },
        {
          notes: " La7        Re",
          text: "di Gesù Cristo.",
        },
        {
          notes: " Fa#",
          text: "in lui anche noi",
        },
        {
          notes: "                                     Si-",
          text: "dopo aver ascoltato la Parola della verità,",
        },
        {
          notes: " Fa#",
          text: "la buona notizia",
        },
        {
          notes: "                Si-",
          text: "della nostra salvezza",
        },
        {
          notes: "                 La                  Sol",
          text: "siamo stati sigillati con lo Spirito Santo",
        },
        {
          notes: "                     Fa#",
          text: "che ci ha fatti suo popolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "PER ESSERE",
        },
        {
          notes: "",
          text: "LODE DELLA SUA GLORIA,",
        },
        {
          notes: "                         La",
          text: "DELLA GLORIA DELLA SUA GRAZIA,",
        },
        {
          notes: "                     Sol",
          text: "DELLA GRAZIA DEL SUO AMORE",
        },
        {
          notes: "",
          text: "CON IL QUALE CI AMÒ",
        },
        {
          notes: "         Fa#",
          text: "IN GESÙ CRISTO.",
        },
      ],
    },
  ],
  subtitle: "(Ef. 1, 3-13)",
};
