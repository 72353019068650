import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ti ho manifestato il mio peccato.",
  url_video: "https://www.youtube.com/embed/U3OaZgCEtkQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La               Re-",
          text: "TI HO MANIFESTATO IL MIO PECCATO,",
        },
        {
          notes: "       La-",
          text: "NON HO TENUTO NASCOSTO IL MIO ERRORE;",
        },
        {
          notes: "   Sol         La-",
          text: "HO DETTO: CONFESSERÒ AL SIGNORE LE MIE COLPE",
        },
        {
          notes: "            Sol                     La-",
          text: "E TU HAI RIMESSO LA MALIZIA DEL MIO PECCATO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "               Re-",
          text: "Per questo ti prega ogni fedele",
        },
        {
          notes: "    La-",
          text: "nel tempo dell'angoscia;",
        },
        {
          notes: "Sol                        La-",
          text: "quando irromperanno grandi acque,",
        },
        {
          notes: "Sol                  La-",
          text: "non lo potranno raggiungere.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La               Re-",
          text: "TI HO MANIFESTATO IL MIO PECCATO,",
        },
        {
          notes: "        La-",
          text: "NON HO TENUTO NASCOSTO IL MIO ERRORE;",
        },
        {
          notes: "    Sol         La-",
          text: "HO DETTO: CONFESSERÒ AL SIGNORE LE MIE COLPE",
        },
        {
          notes: "            Sol                     La-",
          text: "E TU HAI RIMESSO LA MALIZIA DEL MIO PECCATO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                      Re-",
          text: "Beato l'uomo a cui è rimessa la colpa",
        },
        {
          notes: "   La-",
          text: "e perdonato il peccato!",
        },
        {
          notes: "Sol                                     La-",
          text: "Beato l'uomo a cui Dio non imputa alcun male",
        },
        {
          notes: "Sol                        La-",
          text: "e nel cui spirito non è inganno!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La               Re-",
          text: "TI HO MANIFESTATO IL MIO PECCATO,",
        },
        {
          notes: "        La-",
          text: "NON HO TENUTO NASCOSTO IL MIO ERRORE;",
        },
        {
          notes: "    Sol         La-",
          text: "HO DETTO: CONFESSERÒ AL SIGNORE LE MIE COLPE",
        },
        {
          notes: "            Sol                     La-",
          text: "E TU HAI RIMESSO LA MALIZIA DEL MIO PECCATO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Re-",
          text: "Tacevo e si logoravano le mie ossa",
        },
        {
          notes: "         La-",
          text: "mentre gemevo tutto il giorno;",
        },
        {
          notes: "  Sol                       La-",
          text: "giorno e notte pesava su di me la tua mano,",
        },
        {
          notes: " Sol                        La-",
          text: "come per arsura d'estate inaridiva il mio vigore;",
        },
        {
          notes: "                       Re-",
          text: "ma ti ho manifestato il mio peccato,",
        },
        {
          notes: "       La-",
          text: "non ho tenuto nascosto il mio errore",
        },
        {
          notes: "     Sol          La-",
          text: "e ho detto: confesserò al Signore le mie colpe",
        },
        {
          notes: "  Sol                                 La-",
          text: "e tu hai rimesso la malizia del mio peccato.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La               Re-",
          text: "TI HO MANIFESTATO IL MIO PECCATO,",
        },
        {
          notes: "        La-",
          text: "NON HO TENUTO NASCOSTO IL MIO ERRORE;",
        },
        {
          notes: "    Sol         La-",
          text: "HO DETTO: CONFESSERÒ AL SIGNORE LE MIE COLPE",
        },
        {
          notes: "            Sol                     La-",
          text: "E TU HAI RIMESSO LA MALIZIA DEL MIO PECCATO",
        },
      ],
    },
  ],
  subtitle: "Sal 31(32)",
};
