import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Risuscitò.",
  url_video: "https://www.youtube.com/embed/YkiR5L2EAy8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol         Fa        Mi",
          text: "RISUSCITÒ, RISUSCITÒ, RISUSCITÒ, ALLELUJA!",
        },
        {
          notes: "      La-       Sol       Fa          Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, RISUSCITÒ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "La morte,",
        },
        {
          notes: "            Sol",
          text: "dove stà la morte?",
        },
        {
          notes: "             Fa",
          text: "Dov'è la mia morte?",
        },
        {
          notes: "                 Mi",
          text: "Dov'è la sua vittoria?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol         Fa        Mi",
          text: "RISUSCITÒ, RISUSCITÒ, RISUSCITÒ, ALLELUJA!",
        },
        {
          notes: "      La-       Sol       Fa          Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, RISUSCITÒ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "Allegria,",
        },
        {
          notes: "            Sol",
          text: "Allegria, fratelli,",
        },
        {
          notes: "              Fa",
          text: "che se oggi noi amiamo",
        },
        {
          notes: "              Mi",
          text: "è perché risuscitò.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol         Fa        Mi",
          text: "RISUSCITÒ, RISUSCITÒ, RISUSCITÒ, ALLELUJA!",
        },
        {
          notes: "      La-       Sol       Fa          Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, RISUSCITÒ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "Grazie",
        },
        {
          notes: "            Sol",
          text: "siano rese al Padre",
        },
        {
          notes: "                 Fa",
          text: "che ci guida al suo Regno",
        },
        {
          notes: "              Mi",
          text: "dove si vive di amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol         Fa        Mi",
          text: "RISUSCITÒ, RISUSCITÒ, RISUSCITÒ, ALLELUJA!",
        },
        {
          notes: "      La-       Sol       Fa          Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, RISUSCITÒ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "Se con lui moriamo",
        },
        {
          notes: "         Sol",
          text: "con lui viviamo",
        },
        {
          notes: "            Fa",
          text: "con lui cantiamo,",
        },
        {
          notes: "     Mi",
          text: "Alleluja!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-              Sol         Fa        Mi",
          text: "RISUSCITÒ, RISUSCITÒ, RISUSCITÒ, ALLELUJA!",
        },
        {
          notes: "      La-       Sol       Fa          Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA, RISUSCITÒ!",
        },
      ],
    },
  ],
  subtitle: "(1Cor 15)",
};
