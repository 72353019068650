import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Un germoglio spunta dal tronco di jesse.",
  url_video: "https://www.youtube.com/embed/cFXXGX2i4ew",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                      Mi-",
          text: "UN GERMOGLIO SPUNTA DAL TRONCO DI JESSE,",
        },
        {
          notes: "                        Sol",
          text: "UN VIRGULTO DALLE SUE RADICI,",
        },
        {
          notes: "        Do                          Sol",
          text: "SU DI LUI SI POSA LO SPIRITO DEL SIGNORE,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI SAPIENZA E INTELLIGENZA,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI CONSIGLIO E DI FORTEZZA,",
        },
        {
          notes: "Do                            Sol",
          text: "SPIRITO DI CONOSCENZA E DI PIETÀ,",
        },
        {
          notes: "Re                        Sol",
          text: "SPIRITO DI TIMORE DEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                          Sol",
          text: "Non giudicherà per sentito dire",
        },
        {
          notes: "        Re               Sol",
          text: "ma aiuterà tutti gli oppressi.",
        },
        {
          notes: "         Do                             Sol",
          text: "La sua parola sarà una verga per il violento",
        },
        {
          notes: "          Re                                Sol",
          text: "e con il soffio della sua bocca ucciderà l'empio.",
        },
        {
          notes: "Do                      Sol",
          text: "Il lupo dimorerà con l'agnello,",
        },
        {
          notes: "Do                                Sol",
          text: "la pantera si sdraierà con il capretto,",
        },
        {
          notes: "    Re                                 Sol",
          text: "il leone e il vitello pascoleranno insieme",
        },
        {
          notes: "         Do           Sol",
          text: "e un fanciullo li guiderà,",
        },
        {
          notes: "         Re           Sol",
          text: "e un fanciullo li guiderà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                      Mi-",
          text: "UN GERMOGLIO SPUNTA DAL TRONCO DI JESSE,",
        },
        {
          notes: "                        Sol",
          text: "UN VIRGULTO DALLE SUE RADICI,",
        },
        {
          notes: "        Do                          Sol",
          text: "SU DI LUI SI POSA LO SPIRITO DEL SIGNORE,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI SAPIENZA E INTELLIGENZA,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI CONSIGLIO E DI FORTEZZA,",
        },
        {
          notes: "Do                            Sol",
          text: "SPIRITO DI CONOSCENZA E DI PIETÀ,",
        },
        {
          notes: "Re                        Sol",
          text: "SPIRITO DI TIMORE DEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Do                        Sol",
          text: "La mucca e l'orso saranno insieme,",
        },
        {
          notes: "     Do                               Sol",
          text: "il leone si ciberà di paglia come il bue,",
        },
        {
          notes: "      Re                                  Sol",
          text: "il lattante si trastullerà sulla buca dell'aspide,",
        },
        {
          notes: "   Do",
          text: "il bambino metterà la mano",
        },
        {
          notes: "                          Sol",
          text: "nel covo del serpente velenoso.",
        },
        {
          notes: "    Re                      Sol",
          text: "Perché non si farà più del male.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "    Re                                         Sol",
          text: "PERCHÉ LA CONOSCENZA DEL SIGNORE RIEMPIRÀ LA TERRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Si7",
          text: "Perché in quel giorno la radice di Jesse",
        },
        {
          notes: "                              Mi-",
          text: "si leverà a vessilo per i popoli,",
        },
        {
          notes: "      La                     Si7",
          text: "e le genti la seguiranno con ansia.",
        },
        {
          notes: "         Do                               Si7",
          text: "In quel giorno il Signore stenderà la sua mano.",
        },
        {
          notes: "            Do           Si7",
          text: "Perché una strada si formerà,",
        },
        {
          notes: "    Do                Si7",
          text: "un cammino per le nazioni,",
        },
        {
          notes: "        La-           Si7",
          text: "che la Vergine indicherà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                      Mi-",
          text: "UN GERMOGLIO SPUNTA DAL TRONCO DI JESSE,",
        },
        {
          notes: "                        Sol",
          text: "UN VIRGULTO DALLE SUE RADICI,",
        },
        {
          notes: "        Do                          Sol",
          text: "SU DI LUI SI POSA LO SPIRITO DEL SIGNORE,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI SAPIENZA E INTELLIGENZA,",
        },
        {
          notes: "   Re                         Sol",
          text: "SPIRITO DI CONSIGLIO E DI FORTEZZA,",
        },
        {
          notes: "Do                            Sol",
          text: "SPIRITO DI CONOSCENZA E DI PIETÀ,",
        },
        {
          notes: "Re                        Sol",
          text: "SPIRITO DI TIMORE DEL SIGNORE.",
        },
      ],
    },
  ],
  subtitle: "(Is 11)",
};
