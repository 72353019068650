import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Allegria allegria.",
  url_video: "",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do                      Mi",
          text: "Allegria, allegria, allegria,",
        },
        {
          notes: "       Fa",
          text: "oggi è nato il Salvatore,",
        },
        {
          notes: "  Sol#",
          text: "riposa in un presepe,",
        },
        {
          notes: "                      Sol",
          text: "è lui il Cristo, il Signore.",
          msg: " (2 volte)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   La-",
          text: "Un angelo venne dal cielo portando una gran notizia,",
        },
        {
          notes: "Re-             La-         Mi           La-",
          text: "oggi è nato per noi, oggi è nato il Salvator,",
        },
        {
          notes: "Re-             La-         Mi           La-",
          text: "oggi è nato per noi, oggi è nato il Salvator.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do                      Mi",
          text: "Allegria, allegria, allegria,",
        },
        {
          notes: "       Fa",
          text: "oggi è nato il Salvatore,",
        },
        {
          notes: "  Sol#",
          text: "riposa in un presepe,",
        },
        {
          notes: "                      Sol",
          text: "è lui il Cristo, il Signore.",
          msg: " (2 volte)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   La-",
          text: "Avvolto in linde fasce riposa in un presepe:",
        },
        {
          notes: "Re-             La-         Mi           La-",
          text: "questo sarà il segno che lui è il Signore, ",
        },
        {
          notes: "Re-             La-         Mi           La-",
          text: "questo sarà il segno che lui è il Signore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Do                      Mi",
          text: "Allegria, allegria, allegria,",
        },
        {
          notes: "       Fa",
          text: "oggi è nato il Salvatore,",
        },
        {
          notes: "  Sol#",
          text: "riposa in un presepe,",
        },
        {
          notes: "                      Sol",
          text: "è lui il Cristo, il Signore.",
          msg: " (2 volte)",
        },
      ],
    },
  ],
  subtitle: "",
};
