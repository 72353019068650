import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AppRoute, AppRoutes } from "./routes";
import "./App.scss";
import AppLayout from "./Components/AppLayout/AppLayout";
import { getOptions } from "./Utils";
import { OPTION_THEME_COLOR } from "./Constants";
import Img from "./Components/Img/Img";

export const AppName = "Spartiti dei Canti Neocatecumenali";

function App() {
  const [reload, setReload] = useState<boolean>(false);

  const redrawApp = () => {
    setReload(!reload);
  };

  (window as any)["redrawApp"] = () => {
    redrawApp();
  };

  const emitKeyPressed = (e: any) => {
    window.document.dispatchEvent(
      new CustomEvent("key-pressed", { detail: e.code })
    );
  };

  useEffect(() => {
    window.document.body.onkeydown = emitKeyPressed;
  }, []);

  return (
    <div>
      <div
        className={
          "app-main-wrap theme-" +
          (getOptions()[OPTION_THEME_COLOR] ?? "desert")
        }
      >
        {false && (
          <Img
            className="madonnina-overlay"
            src={"./Assets/madonnina.png"}
            alt="Madonnina"
          />
        )}
        <Routes>
          {AppRoutes.map((x: AppRoute, i: number) => {
            return (
              <Route
                key={i}
                path={x.path}
                element={
                  <div className="app-page-wrap base_page_background_color">
                    {x.allowLayout && <AppLayout />}
                    {x.element}
                  </div>
                }
              />
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default App;
