import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo, santo, santo (1988).",
  url_video: "https://www.youtube.com/embed/UQKfLEIlPTo",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Sol                           La-",
          text: "I cieli e la terra sono pieni della tua gloria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Sol                           La-",
          text: "I CIELI E LA TERRA SONO PIENI DELLA TUA GLORIA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                     Mi7",
          text: "Osanna nell'alto dei cieli!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                     Mi7",
          text: "OSANNA NELL'ALTO DEI CIELI!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Sol                 La-",
          text: "Benedetto colui che viene nel nome del Signore!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Sol                 La-",
          text: "BENEDETTO COLUI CHE VIENE NEL NOME DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                     Mi7",
          text: "Osanna nell'alto dei cieli!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                     Mi7",
          text: "OSANNA NELL'ALTO DEI CIELI!",
        },
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
        {
          notes: "La-     Sol    La-",
          text: "SANTO, SANTO, SANTO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa             Mi7",
          text: "Santo, santo, santo!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Fa             Mi7",
          text: "Santo, santo, santo!",
        },
      ],
    },
  ],
  subtitle: "",
};
