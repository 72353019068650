import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Magnificat.",
  url_video: "https://www.youtube.com/embed/p-FB7-ULV78",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                                     Mi",
          text: "PROCLAMA L'ANIMA MIA LA GRANDEZZA DEL SIGNOR",
        },
        {
          notes: "                                      Re",
          text: "ESULTA IL MIO SPIRITO IN DIO MIO SALVATOR",
        },
        {
          notes: " Re                                     Mi",
          text: "PROCLAMA L'ANIMA MIA LA GRANDEZZA DEL SIGNOR",
        },
        {
          notes: "                                      Re  (La Mi La)",
          text: "ESULTA IL MIO SPIRITO IN DIO MIO SALVATOR",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "Ecco, ora mi chiameran beata",
        },
        {
          notes: "                  La",
          text: "tutte le generazioni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "ECCO, ORA MI CHIAMERAN BEATA",
        },
        {
          notes: "                  La",
          text: "TUTTE LE GENERAZIONI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La7      Re                            La",
          text: "Perché il Potente ha fatto grandi cose in me",
        },
        {
          notes: "         Mi      La",
          text: "e il suo nome è santo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   La7      Re                            La",
          text: "PERCHÉ IL POTENTE HA FATTO GRANDI COSE IN ME",
        },
        {
          notes: "         Mi      La",
          text: "E IL SUO NOME È SANTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "Egli mostra la forza del suo braccio",
        },
        {
          notes: "                  La",
          text: "e disperde i superbi di cuor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "EGLI MOSTRA LA FORZA DEL SUO BRACCIO",
        },
        {
          notes: "                  La",
          text: "E DISPERDE I SUPERBI DI CUOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "I potenti rovescia dai troni",
        },
        {
          notes: "                  La",
          text: "e innalza i più umili.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La7    Re              La",
          text: "GLI AFFAMATI RICOLMA DI BENI",
        },
        {
          notes: "    Mi                    La",
          text: "E RIMANDA I RICCHI A MANI VUOTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "Solleva il suo servo Israele,",
        },
        {
          notes: "                         La",
          text: "ricordando la sua misericordia",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La                      Mi",
          text: "SOLLEVA IL SUO SERVO ISRAELE,",
        },
        {
          notes: "                         La",
          text: "RICORDANDO LA SUA MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7           Re             La",
          text: "Secondo la promessa fatta ad Abramo",
        },
        {
          notes: "    Mi           La",
          text: "e a tutti i suoi figli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La7           Re             La",
          text: "SECONDO LA PROMESSA FATTA AD ABRAMO",
        },
        {
          notes: "    Mi           La",
          text: "E A TUTTI I SUOI FIGLI.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                                     Mi",
          text: "PROCLAMA L'ANIMA MIA LA GRANDEZZA DEL SIGNOR",
        },
        {
          notes: "                                      Re",
          text: "ESULTA IL MIO SPIRITO IN DIO MIO SALVATOR",
        },
        {
          notes: " Re                                     Mi",
          text: "PROCLAMA L'ANIMA MIA LA GRANDEZZA DEL SIGNOR",
        },
        {
          notes: "                                      Re  (La Mi La)",
          text: "ESULTA IL MIO SPIRITO IN DIO MIO SALVATOR",
        },
      ],
    },
  ],
  subtitle: "(Lc 1, 46-55)",
};
