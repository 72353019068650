import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import PageLayout from "../../Components/PageLayout/PageLayout";
import { getTrainingData, ITrainingData, ITrainingSession } from "../../Utils";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./ProfileScreen.scss";
import { MAX_DAYS_FOR_CHART_DISPLAY } from "../../Constants";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
const randomRGB = () => {
  let randR = randomNum();
  let randG = randomNum();
  let randB = randomNum();
  let randRgb = `rgb(${randR}, ${randG}, ${randB})`;
  let randRgbBackground = `rgba(${randR}, ${randG}, ${randB}, 0.6)`;
  return { color: randRgb, backgroundColor: randRgbBackground };
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChardDataSession {
  date: string;
  duration: number;
}

interface LineChartData {
  legend: string;
  values: ChardDataSession[];
}

interface BarChartData {
  legend: string;
  value: number;
}

const secondsToTimeStamp = (seconds: number | string) => {
  let _seconds: number = +seconds.toString().replaceAll(",", ".");
  return new Date(_seconds * 1000).toISOString().substr(11, 8);
};

const fillMissingData = (labels: string[], data: ChardDataSession[]) => {
  let results: number[] = [];
  for (let i = 0; i < labels.length; i++) {
    let found = false;
    for (let j = 0; j < data.length; j++) {
      if (data[j].date === labels[i]) {
        results.push(data[j].duration);
        found = true;
        break;
      }
    }
    if (!found) {
      results.push(0);
    }
  }
  return results;
};

const formatBarChartData = (data: BarChartData[], maxView: number) => {
  if (data.length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let labels: string[] = [];
  let values: number[] = [];
  for (let i = 0; i < data.length && i < maxView; i++) {
    let item: BarChartData = data[i];
    labels.push(item.legend);
    values.push(item.value);
  }

  let randColor = randomRGB();
  let formatted = {
    labels: labels,
    datasets: [
      {
        labels: "",
        data: values,
        borderColor: randColor.color,
        backgroundColor: randColor.backgroundColor,
      },
    ],
  };

  return formatted;
};

const formatEveryDayData = (data: any) => {
  if (data.length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let labels: string[] = [];
  let values: number[] = [];

  var targetDate = new Date();
  for (let i = 0; i < MAX_DAYS_FOR_CHART_DISPLAY; i++) {
    labels.push(targetDate.toISOString().split("T")[0]);
    targetDate.setDate(targetDate.getDate() - 1);
  }
  labels.reverse();

  for (let i = 0; i < labels.length; i++) {
    values.push(data[labels[i]]);
  }

  let randColor = randomRGB();
  let formatted = {
    labels: labels,
    datasets: [
      {
        labels: "",
        data: values,
        borderColor: randColor.color,
        backgroundColor: randColor.backgroundColor,
      },
    ],
  };

  return formatted;
};

const formatLineChartData = (data: LineChartData[], maxView: number) => {
  if (data.length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let labels: string[] = [];
  var targetDate = new Date();
  for (let i = 0; i < MAX_DAYS_FOR_CHART_DISPLAY; i++) {
    labels.push(targetDate.toISOString().split("T")[0]);
    targetDate.setDate(targetDate.getDate() - 1);
  }
  labels.reverse();

  let datasets: any[] = [];

  for (let i = 0; i < data.length && i < maxView; i++) {
    let dataItem: LineChartData = data[i];
    if (dataItem.values.length > 0) {
      let randColor = randomRGB();
      datasets.push({
        label: dataItem.legend,
        data: fillMissingData(labels, dataItem.values),
        borderColor: randColor.color,
        backgroundColor: randColor.backgroundColor,
      });
    }
  }

  let formatted = {
    labels: labels,
    datasets: datasets,
  };

  return formatted;
};

const ProfileScreen = () => {
  const trainingData: ITrainingData[] = getTrainingData();
  const [maxView, setMaxView] = useState<number>(10);
  (window as any)["trainingData"] = trainingData;
  const sortByGlobalDuration = (a: ITrainingData, b: ITrainingData) => {
    return b.globalDuration - a.globalDuration;
  };

  const sortByRelativeDuration = (a: ITrainingData, b: ITrainingData) => {
    let a_ = 0;
    let b_ = 0;
    for (let i = 0; i < a.trainings.length; i++) {
      a_ += a.trainings[i].duration;
    }
    for (let i = 0; i < b.trainings.length; i++) {
      b_ += b.trainings[i].duration;
    }
    return b_ - a_;
  };

  const trainingDailyChartData: LineChartData[] = trainingData
    .sort(sortByRelativeDuration)
    .map((data: ITrainingData) => {
      return {
        legend: data.title,
        values: data.trainings.map((session: ITrainingSession) => {
          return {
            duration: Math.floor(session.duration),
            date: session.date,
          };
        }),
      };
    });

  const trainingGlobalChartData: BarChartData[] = trainingData
    .sort(sortByGlobalDuration)
    .map((data: ITrainingData) => {
      return {
        legend: data.title,
        value: data.globalDuration,
      };
    });

  /*
    const trainingEveryDayChartData: BarChartData[] = trainingData
    .sort(sortByGlobalDuration)
    .map((data: ITrainingData) => {
      return {
        legend: data.title,
        values: data.trainings.map((session: ITrainingSession) => {
          return {
            duration: Math.floor(session.duration),
            date: session.date,
          };
      };
    });*/

  let everyDayTrainingData: any = {};
  trainingData.forEach((x: ITrainingData) => {
    x.trainings.forEach((y: ITrainingSession) => {
      if (!everyDayTrainingData[y.date]) {
        everyDayTrainingData[y.date] = 0;
      }
      everyDayTrainingData[y.date] += y.duration;
    });
  });

  let lineChartData = formatLineChartData(trainingDailyChartData, maxView);
  let barChartData = formatBarChartData(trainingGlobalChartData, maxView);
  let everyDayData = formatEveryDayData(everyDayTrainingData);
  return (
    <PageLayout
      title={"Profilo"}
      content={
        <div>
          Ecco i tuoi tempi di pratica per i vari spartiti.
          <br />
          <small>
            Ricorda che una singola sessione di pratica viene registrata solo se
            dura almeno 30 secondi e non più di 10 minuti.
          </small>
          <br />
          <div className="profile-screen-mui-input-wrap-select">
            I canti sono ordinati per tempo di pratica:
            <FormControl style={{ width: "100%" }}>
              <Select
                fullWidth={true}
                variant="standard"
                value={maxView}
                onChange={(e: any) => {
                  setMaxView(e.target.value);
                }}
              >
                <MenuItem value={"10"}>Visualizza 10 canti</MenuItem>
                <MenuItem value={"20"}>Visualizza 20 canti</MenuItem>
                <MenuItem value={"30"}>Visualizza 30 canti</MenuItem>
                <MenuItem value={"50"}>Visualizza 50 canti</MenuItem>
                <MenuItem value={"100"}>Visualizza 100 canti</MenuItem>
                <MenuItem value={"1000"}>Visualizza tutti i canti</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: "2em", height: "550px" }}>
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      callback: function (value, index, ticks) {
                        return secondsToTimeStamp(value);
                      },
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      title: function (tooltipItems: any[]) {
                        return lineChartData.datasets[
                          tooltipItems[0].datasetIndex
                        ].label;
                      },
                      label: function (tooltipItem) {
                        return secondsToTimeStamp(tooltipItem.formattedValue);
                      },
                    },
                  },
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                    text: "Le tue sessioni di prova nelle ultime 2 settimane.",
                  },
                },
              }}
              data={lineChartData}
            />
            <div className="profile-screen-chart-separator"></div>
            <Bar
              options={{
                responsive: true,
                scales: {
                  y: {
                    ticks: {
                      callback: function (value, index, ticks) {
                        return secondsToTimeStamp(value);
                      },
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        return secondsToTimeStamp(tooltipItem.formattedValue);
                      },
                    },
                  },
                  legend: {
                    position: "top" as const,
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Tempi di prova per ogni canto.",
                  },
                },
              }}
              data={barChartData}
            />
            <div className="profile-screen-chart-separator"></div>
            <Bar
              options={{
                responsive: true,
                scales: {
                  y: {
                    ticks: {
                      callback: function (value, index, ticks) {
                        return secondsToTimeStamp(value);
                      },
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        return secondsToTimeStamp(tooltipItem.formattedValue);
                      },
                    },
                  },
                  legend: {
                    position: "top" as const,
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Tempi di prova del giorno.",
                  },
                },
              }}
              data={everyDayData}
            />
          </div>
        </div>
      }
    />
  );
};

export default ProfileScreen;
