import "./TitledPageSection.scss";

export interface TitledPageSectionProps {
  title: string;
  content: JSX.Element;
}

const TitledPageSection = (props: TitledPageSectionProps) => {
  return (
    <div className="titled-page-section-wrap">
      <div className="titled-page-section-title page_title_color">
        {props.title}
      </div>
      <div className="titled-page-section-content">{props.content}</div>
    </div>
  );
};

export default TitledPageSection;
