import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ecco lo specchio nostro.",
  url_video: "https://www.youtube.com/embed/TYH47M6MJm8",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-",
          text: "Ecco: lo specchio nostro è il signore!",
        },
        {
          notes: " La-",
          text: "Aprite gli occhi e guardatevi in lui!",
        },
        {
          notes: " Sol-                       La7",
          text: "ed imparate com'è il vostro viso.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "   Re-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol-",
          text: "Alleluja, alleluja, alleluja.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " La7",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "   Re-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol-",
          text: "Alleluja, alleluja, alleluja.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " La7",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-",
          text: "E togliete la sporcizia dalle vostre facce.",
        },
        {
          notes: " La-",
          text: "Amate la sua santità e rivestitevene;",
        },
        {
          notes: " Sol-                             La7",
          text: "e sarete immacolati sempre davanti a lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "   Re-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol-",
          text: "Alleluja, alleluja, alleluja.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " La7",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "   Re-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol-",
          text: "Alleluja, alleluja, alleluja.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " La7",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "",
          text: "DATE GLORIA AL SUO SPIRITO.",
        },
        {
          notes: "                  Re-",
          text: "DATE GLORIA AL SUO SPIRITO!",
        },
      ],
    },
  ],
  subtitle: "(Ode XIII di Salomone)",
};
