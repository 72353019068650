import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Preconio pasquale.",
  url_video: "https://www.youtube.com/embed/_tm29zSFvhE",
  barrè: "III",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-       Sol         La-",
          text: "Esulti il coro degli angeli",
        },
        {
          notes: "Do                 La-",
          text: "esulti l'assemblea celeste",
        },
        {
          notes: "             Sol",
          text: "e un inno di gloria",
        },
        {
          notes: "                              La-",
          text: "saluti il trionfo del Signore risorto.",
        },
        {
          notes: "Fa         Mi        Fa            Mi",
          text: "Gioisca la terra inondata di nuova luce!",
        },
        {
          notes: "                 La-",
          text: "Lo splendore del re",
        },
        {
          notes: "            Sol",
          text: "ha vinto le tenebre,",
        },
        {
          notes: "   Fa          Mi",
          text: "le tenebre del mondo!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                La-",
          text: "LO SPLENDORE DEL RE",
        },
        {
          notes: "            Sol",
          text: "HA VINTO LE TENEBRE",
        },
        {
          notes: "   Fa          Mi",
          text: "LE TENEBRE DEL MONDO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Sol",
          text: "Si rallegri la madre Chiesa",
        },
        {
          notes: "           La-         Do               La-",
          text: "tutta splendente della gloria del suo Signore",
        },
        {
          notes: "            Sol",
          text: "e in questa sala risuoni unanime",
        },
        {
          notes: "                               La-",
          text: "l'acclamazione di un popolo in festa.",
        },
        {
          notes: " Sol               La-",
          text: "Il Signore sia con voi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol          La-",
          text: "E CON IL TUO SPIRITO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa               Mi",
          text: "In alto i nostri cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Mi",
          text: "SONO RIVOLTI AL SIGNORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                                 La-",
          text: "Rendiamo grazie al Signore nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Sol          La-",
          text: "È COSA BUONA E GIUSTA.",
        },
        {
          notes: " Sol          La-",
          text: "È COSA BUONA E GIUSTA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                        Sol",
          text: "È veramente cosa buona e giusta",
        },
        {
          notes: "    La-            Do                      La-",
          text: "esprimere con il canto l'esultanza dello spirito",
        },
        {
          notes: "       Sol",
          text: "e inneggiare al Padre onnipotente",
        },
        {
          notes: "                           La-",
          text: "e al Figlio, Gesù Cristo Signore.",
        },
        {
          notes: "                                    Sol",
          text: "Egli ha pagato per noi all'eterno Padre",
        },
        {
          notes: "             La-",
          text: "il debito di Adamo",
        },
        {
          notes: "         Do                 La-",
          text: "e con il sangue sparso per amore",
        },
        {
          notes: "      Sol                               La-",
          text: "ha cancellato la condanna della colpa antica.",
        },
        {
          notes: "Fa           Mi",
          text: "Questa è la Pasqua",
        },
        {
          notes: "Fa                    Mi",
          text: "in cui è immolato l'Agnello;",
        },
        {
          notes: "Fa          Mi",
          text: "questa è la notte",
        },
        {
          notes: "   Fa",
          text: "in cui hai liberato i nostri padri",
        },
        {
          notes: "                      Mi",
          text: "dalla schiavitù dell'Egitto",
        },
        {
          notes: "       Fa    Mi",
          text: "questa è la notte",
        },
        {
          notes: "       Fa                       Mi",
          text: "che ci salva dall'oscurità del male;",
        },
        {
          notes: "       Fa    Mi",
          text: "questa è la notte",
        },
        {
          notes: "            Fa                      Mi",
          text: "in cui hai vinto le tenebre del peccato!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "QUESTA È LA NOTTE",
        },
        {
          notes: "                              Sol",
          text: "IN CUI CRISTO HA DISTRUTTO LA MORTE",
        },
        {
          notes: "          Fa                  Mi",
          text: "E DAGLI INFERI RISORGE VITTORIOSO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Sol             La-",
          text: "O mirabile condiscendenza della tua grazia,",
        },
        {
          notes: "        Do                       La-",
          text: "o inestimabile tenerezza del tuo amore!",
        },
        {
          notes: "                  Sol                        La-",
          text: "Per riscattare lo schiavo hai sacrificato il Figlio!",
        },
        {
          notes: "Fa                   Mi",
          text: "Senza il peccato di Adamo",
        },
        {
          notes: "Fa                      Mi",
          text: "Cristo non ci avrebbe redenti!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "FELICE COLPA CHE MERITÒ",
        },
        {
          notes: "                    Sol",
          text: "UN COSÌ GRANDE SALVATORE",
        },
        {
          notes: "         La-",
          text: "FELICE COLPA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "O notte veramente beata",
        },
        {
          notes: "                                          La-",
          text: "che hai conosciuto l'ora in cui Cristo è risorto!",
        },
        {
          notes: "                   Sol",
          text: "O notte veramente beata",
        },
        {
          notes: "                                         La-",
          text: "che spogliò gli egiziani per arricchire Israele!",
        },
        {
          notes: "                     Sol",
          text: "O notte veramente gloriosa",
        },
        {
          notes: "         Fa                   Mi",
          text: "che ricongiunge l'uomo al suo Dio!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "QUESTA È LA NOTTE",
        },
        {
          notes: "                              Sol",
          text: "IN CUI CRISTO HA DISTRUTTO LA MORTE",
        },
        {
          notes: "          Fa                  Mi",
          text: "E DAGLI INFERI RISORGE VITTORIOSO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                           Sol",
          text: "In questa notte accogli Padre santo",
        },
        {
          notes: "                 La-",
          text: "il sacrificio di lode",
        },
        {
          notes: "       Do                                 La-",
          text: "che la Chiesa ti offre per mano dei suoi ministri,",
        },
        {
          notes: "       Sol",
          text: "nella liturgia solenne del cero,",
        },
        {
          notes: "                  La-",
          text: "segno della nuova luce.",
        },
        {
          notes: "Fa              Mi",
          text: "Ti preghiamo o Signore",
        },
        {
          notes: "La-                                      Sol",
          text: "che questo cero offerto in onore del tuo nome",
        },
        {
          notes: "             La-",
          text: "risplenda di luce.",
        },
        {
          notes: "La-                     Sol",
          text: "Salga a te come profumo soave,",
        },
        {
          notes: "                              La-",
          text: "si confonda con le stelle del cielo.",
        },
        {
          notes: "                                Sol",
          text: "Lo trovi acceso la stella del mattino,",
        },
        {
          notes: "       Fa                        Mi",
          text: "quella stella che non conosce tramonto.",
        },
        {
          notes: "La-                               Sol",
          text: "Cristo tuo Figlio risuscitato dai morti",
        },
        {
          notes: "      Fa                     Mi",
          text: "fa risplendere la sua luce serena!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-   Sol   La-",
          text: "AMEN! AMEN! AMEN!",
          msg: "  (2 v.)",
        },
      ],
    },
  ],
  subtitle: "",
};
