import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il pigiatore.",
  url_video: "https://www.youtube.com/embed/541SpuqCutE",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-",
          text: "CHI È COSTUI CHE VIENE DA EDOM?",
        },
        {
          notes: "        La-                       Mi-",
          text: "CHI È COSTUI CHE AVANZA TINTO DI ROSSO,",
        },
        {
          notes: "   Si7",
          text: "MAGNIFICO NELLA VESTE",
        },
        {
          notes: "                   Mi-",
          text: "CAMMINA PIENO DI FORZA?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Re                           Mi-",
          text: "Sono io, sono io, che parlo con giustizia",
        },
        {
          notes: "    Si7               Mi-",
          text: "sono io, un gran liberatore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "E PERCHÉ, PERCHÉ ROSSA",
        },
        {
          notes: "",
          text: "È LA TUA VESTE?",
        },
        {
          notes: "    La-",
          text: "PERCHÉ È COME QUELLA",
        },
        {
          notes: "                  Mi-",
          text: "DI CHI PIGIA NEL TINO?",
        },
        {
          notes: "   Si7     Mi-",
          text: "PERCHÉ, PERCHÉ?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Re                            Mi-",
          text: "Nel tino, nel tino ho pigiato da solo",
        },
        {
          notes: "         Si7                  Mi-",
          text: "del mio popolo nessuno era con me.",
        },
        {
          notes: "  Re                                   Mi-",
          text: "Guardai, guardai: non c'era chi mi aiutasse,",
        },
        {
          notes: "    Si7                        Mi-",
          text: "e stupito, non c'era chi mi sostenesse.",
        },
        {
          notes: " La-                    Mi-",
          text: "Allora mi salvò il mio braccio",
        },
        {
          notes: "                                Si7",
          text: "la forza mia, la forza mia mi sostenne:",
        },
        {
          notes: "                               Mi-",
          text: "il giorno della vendetta era arrivato.",
        },
        {
          notes: "      La-               Mi-",
          text: "Calpestai, calpestai i popoli",
        },
        {
          notes: "                                   Si7",
          text: "e feci scorrere per terra il loro sangue",
        },
        {
          notes: "                           Mi-",
          text: "e tutti i miei abiti ha macchiato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-",
          text: "CHI È COSTUI CHE VIENE DA EDOM?",
        },
        {
          notes: "        La-                       Mi-",
          text: "CHI È COSTUI CHE AVANZA TINTO DI ROSSO,",
        },
        {
          notes: "   Si7",
          text: "MAGNIFICO NELLA VESTE",
        },
        {
          notes: "                   Mi-",
          text: "CAMMINA PIENO DI FORZA?",
        },
      ],
    },
  ],
  subtitle: "(Is. 63, 1-6)",
};
