import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Zaccheo sei tu.",
  url_video: "https://www.youtube.com/embed/IqrkIpf8UzA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                  Sol",
          text: "Entrato Gesù in Gerico, attraversava la città,",
        },
        {
          notes: "                Fa                   Mi",
          text: "Entrato Gesù in Gerico, attraversava la città,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                  Sol",
          text: "Entrato Gesù in Gerico, attraversava la città,",
        },
        {
          notes: "                Fa                   Mi",
          text: "Entrato Gesù in Gerico, attraversava la città,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-",
          text: "Ed ecco un uomo chiamato Zaccheo",
        },
        {
          notes: "                         Sol",
          text: "capo dei pubblicani e molto ricco",
        },
        {
          notes: " Fa",
          text: "che cercava di vedere Gesù",
        },
        {
          notes: "                                 Mi",
          text: "ma non poteva poiché era piccolo di statura.",
        },
        {
          notes: " La-                                      Sol",
          text: "Allora corse in avanti, e salì sopra un sicomoro",
        },
        {
          notes: "Fa                                        Mi",
          text: "Poiché doveva passare di la, doveva passare di la.",
        },
        {
          notes: "       La-",
          text: "Quando giunse sul luogo Gesù,",
        },
        {
          notes: "                   Sol",
          text: "alzò lo sguardo e lo vide",
        },
        {
          notes: "    Fa",
          text: "E gli disse Zaccheo scendi subito,",
        },
        {
          notes: "                                     Mi",
          text: "perché oggi conviene che mi fermi a casa tua.",
        },
        {
          notes: " La-                                        Sol",
          text: "Zaccheo scese in fretta, e lo accolse pieno di gioia.",
        },
        {
          notes: " Fa",
          text: "E vedendo ciò tutti mormorarono, è entrato in casa",
        },
        {
          notes: "    Mi",
          text: "di un peccatore.",
        },
        {
          notes: " La-                          Sol",
          text: "E Zaccheo si alzò e disse al Signore,",
        },
        {
          notes: "     Fa",
          text: "ecco Signore, io do la metà dei miei beni ai poveri,",
        },
        {
          notes: "                                                 Mi",
          text: "e se ho rubato a qualcuno quattro volte io lo restituirò.",
        },
        {
          notes: "  La-",
          text: "E Gesù gli rispose,",
        },
        {
          notes: "                                  Sol",
          text: "Oggi la salvezza è entrata in questa casa",
        },
        {
          notes: "Fa                                 Mi",
          text: "Oggi la salvezza è entrata in questa casa",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                  Sol",
          text: "Oggi la salvezza è entrata in questa casa",
        },
        {
          notes: "Fa                                 Mi",
          text: "Oggi la salvezza è entrata in questa casa",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "upper",
      lines: [
        {
          notes: " Re-                          Mi",
          text: "Perché anche lui è figlio di Abramo,",
        },
        {
          notes: " Re-",
          text: "perché il figlio dell'uomo è venuto a cercare colui",
        },
        {
          notes: "        Mi",
          text: "che era smarrito.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                                  Sol",
          text: "Oggi la salvezza è entrata in questa casa",
        },
        {
          notes: "Fa                                 Mi",
          text: "Oggi la salvezza è entrata in questa casa",
        },
      ],
    },
  ],
  subtitle: "(Lc. 19,1-20)",
};
