import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Cantico di mosè.",
  url_video: "https://www.youtube.com/embed/iWQPgEp2hEQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do7            Fa",
          text: "Cantiamo al Signor.",
        },
        {
          notes: "              La7",
          text: "Cantiamo al Signore,",
        },
        {
          notes: "                  Re7",
          text: "che si coprì di gloria,",
        },
        {
          notes: "",
          text: "di gloria, di gloria.",
        },
        {
          notes: "               Sol7",
          text: "Precipitò nel mare",
        },
        {
          notes: "            Do",
          text: "cavallo e cavaliere.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do7            Fa",
          text: "Il Signore è un prode,",
        },
        {
          notes: "                La7",
          text: "Jahvè è il suo nome,",
        },
        {
          notes: "              Re7",
          text: "i carri del Faraone",
        },
        {
          notes: "",
          text: "e l'esercito nemico",
        },
        {
          notes: "              Sol7",
          text: "precipitò nel mare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do7               Fa",
          text: "La tua destra, o Signore,",
        },
        {
          notes: "             La7",
          text: "gloriosa di potenza,",
        },
        {
          notes: "                   Re7",
          text: "la tua destra, o Signore",
        },
        {
          notes: "",
          text: "distrusse il nemico,",
        },
        {
          notes: "              Sol7",
          text: "distrusse il nemico,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do7            Fa",
          text: "Il nemico aveva detto:",
        },
        {
          notes: "                  La7",
          text: "inseguirò, raggiungerò,",
        },
        {
          notes: "              Re7",
          text: "e snuderò la spada,",
        },
        {
          notes: "",
          text: "la mia mano prenderà,",
        },
        {
          notes: "              Sol7",
          text: "la mia mano spoglierà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do7              Fa",
          text: "Soffiasti col tuo alito:",
        },
        {
          notes: "           La7",
          text: "il mare li coprì.",
        },
        {
          notes: "                    Re7",
          text: "sprofondarono come piombo",
        },
        {
          notes: "",
          text: "nelle acque profonde,",
        },
        {
          notes: "                  Sol7",
          text: "nelle acque della morte.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do7                  Fa",
          text: "Chi è come te fra gli déi.",
        },
        {
          notes: "                   La7",
          text: "chi è come te, o Signore,",
        },
        {
          notes: "              Re7",
          text: "maestoso in santità,",
        },
        {
          notes: "",
          text: "tremendo nelle imprese,",
        },
        {
          notes: "               Sol7",
          text: "operatore di prodigi?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do7             Fa",
          text: "Guidasti questo popolo",
        },
        {
          notes: "              La7",
          text: "che tu hai riscattato,",
        },
        {
          notes: "                   Re7",
          text: "con forza lo hai condotto,",
        },
        {
          notes: "",
          text: "con forza e con amore",
        },
        {
          notes: "             Sol7",
          text: "fino alla tua dimora.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do7                            Fa",
          text: "Lo hai fatto entrare e lo hai piantato",
        },
        {
          notes: "                  La7",
          text: "sul monte della promessa,",
        },
        {
          notes: "                  Re7",
          text: "dove tu, Signore, regni",
        },
        {
          notes: "",
          text: "regni in eterno,",
        },
        {
          notes: "          Sol7",
          text: "regni per sempre.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
        {
          notes: "Do7              Fa",
          text: "MIA FORZA E MIA CANZONE",
        },
        {
          notes: "        La7",
          text: "È IL SIGNORE.",
        },
        {
          notes: "               Re7",
          text: "LUI È LA MIA SALVEZZA,",
        },
        {
          notes: "",
          text: "IL MIO DIO",
        },
        {
          notes: "            Sol7",
          text: "ED IO LO ESALTERÒ",
        },
        {
          notes: "              Do7",
          text: "PRECIPITÒ NEL MARE",
        },
        {
          notes: "         Sol",
          text: "CAVALLO E CAVALIERE. (2)",
        },
      ],
    },
  ],
  subtitle: "(Es 15, 1-18)",
};
