import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il popolo che camminava nelle tenebre.",
  url_video: "https://www.youtube.com/embed/t_CPwYqbEQQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Do                          La-",
          text: "Il popolo che camminava nelle tenebre",
        },
        {
          notes: "    Fa             La-",
          text: "ha visto una gran luce.",
        },
        {
          notes: "        Sol",
          text: "Sopra coloro che camminavano",
        },
        {
          notes: "             La-",
          text: "in terra tenebrosa",
        },
        {
          notes: "     Sol            La-",
          text: "una forte luce ha brillato.",
        },
        {
          notes: "   Do                     La-",
          text: "Hai moltiplicato loro la gioia",
        },
        {
          notes: "    Fa            La-",
          text: "hai accresciuto l'allegria,",
        },
        {
          notes: "     Sol                  La-",
          text: "si sono rallegrati al tuo cospetto",
        },
        {
          notes: "  Sol                         La-",
          text: "come si rallegrano durante il raccolto,",
        },
        {
          notes: "  Sol",
          text: "come si rallegrano",
        },
        {
          notes: "                  La-",
          text: "quando si dividono la preda.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-          Do          La-",
          text: "E IL DOMINIO SARÀ SULLE SUE SPALLE",
        },
        {
          notes: "              Sol#                Sol",
          text: "E IL SUO NOME SARÀ, E IL SUO NOME SARÀ:",
        },
        {
          notes: "  Do         La-",
          text: "CONSIGLIERE MERAVIGLIOSO",
        },
        {
          notes: "Fa             La-",
          text: "DIO FORTE, PADRE ETERNO",
        },
        {
          notes: "  Sol        La-",
          text: "PRINCIPE DI PACE.",
        },
        {
          notes: "  Sol        La-",
          text: "PRINCIPE DI PACE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Do                          La-",
          text: "Perché il giogo che pesava sopra il popolo,",
        },
        {
          notes: "    Fa                 La-",
          text: "il giogo che gravava le sue spalle,",
        },
        {
          notes: "    Sol           La-",
          text: "la verga dell'aguzzino",
        },
        {
          notes: "   Sol                   La-",
          text: "tu l'hai spezzata, come a Madian.",
        },
        {
          notes: "   Do                          La-",
          text: "Così come ogni piede che forte calpesta",
        },
        {
          notes: "   Fa                     La-",
          text: "ogni veste intrisa di sangue",
        },
        {
          notes: "      Sol                       La-",
          text: "sarà data alla fiamma di un fuoco divorante,",
        },
        {
          notes: "   Sol               La-",
          text: "perché ci è nato un bambino,",
        },
        {
          notes: "     Sol                La-",
          text: "perché ci è stato dato un figlio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-          Do          La-",
          text: "E IL DOMINIO SARÀ SULLE SUE SPALLE",
        },
        {
          notes: "              Sol#                Sol",
          text: "E IL SUO NOME SARÀ, E IL SUO NOME SARÀ:",
        },
        {
          notes: "  Do         La-",
          text: "CONSIGLIERE MERAVIGLIOSO",
        },
        {
          notes: "Fa             La-",
          text: "DIO FORTE, PADRE ETERNO",
        },
        {
          notes: "  Sol        La-",
          text: "PRINCIPE DI PACE.",
        },
        {
          notes: "  Sol        La-",
          text: "PRINCIPE DI PACE.",
        },
      ],
    },
  ],
  subtitle: "(Is 9, 1-5)",
};
