import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageLayout from "../../Components/PageLayout/PageLayout";
import SongRender, {
  SongOptions,
} from "../../Components/SongRender/SongRender";
import { SongElement } from "../../Songs/SongElement";
import { getAppSong } from "../../Songs/SongHandler";
import { getNotFoundContent } from "../NotFoundScreen/NotFoundScreen";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DownloadIcon from "@mui/icons-material/Download";
import "./SongViewScreen.scss";
import {
  addFavoriteSong,
  addTrainindData,
  getPrintableHTMLContent,
  ITrainingSession,
  removeFavoriteSong,
  testFavoriteSong,
} from "../../Utils";
import ScrollCommands from "../../Components/ScrollCommands/ScrollCommands";
import VideoBox from "../../Components/VideoBox/VideoBox";
import { getRandomNavPath } from "../../Components/NavMenu/NavMenu";

export interface ITrainingSessionMeta {
  song: SongElement | null;
  startDate: string;
}

const cropCanvas = (
  canvas: any,
  x: number,
  y: number,
  width: number,
  height: number
) => {
  // reached bottom
  let useHeight = height;
  if (canvas.height - y < height) {
    useHeight = canvas.height - y;
  }

  // create a temp canvas
  const newCanvas = document.createElement("canvas");
  // set its dimensions
  newCanvas.width = width;
  newCanvas.height = height;
  const context = newCanvas.getContext("2d");

  if (context) {
    // draw the canvas in the new resized temp canvas
    context.drawImage(canvas, x, y, width, useHeight, 0, 0, width, useHeight);

    // if you could not crop enough height, fill the rest with white canvas
    if (useHeight !== height) {
      context.fillStyle = "white";
      context.fillRect(0, useHeight, width, height);
    }
  }

  return newCanvas;
};

let sessionSupportReference: ITrainingSessionMeta | null = null;

const storeSessionData = (session: ITrainingSessionMeta | null) => {
  if (session && session.song) {
    let now = new Date();
    let startDate = new Date(session.startDate);
    let duration = (now.getTime() - startDate.getTime()) / 1000;
    addTrainindData(session.song, duration);
  }
};

const saveFontSize = (size: number) => {
  localStorage.setItem("requestedFontSize", size.toString());
};

const getFontSize = () => {
  let s: string | null = localStorage.getItem("requestedFontSize");
  if (s) {
    return +s;
  } else {
    return window.innerWidth < 1000 ? 0.8 : 1;
  }
};

const SongViewScreen = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [song, setSong] = useState<SongElement | null>(null);
  const [missingSong, setMissingSong] = useState<boolean>(false);
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);
  const [videoIsOn, setVideoIsOn] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [session, setSessionInner] = useState<ITrainingSessionMeta | null>(
    null
  );
  const [songOptions, setSongOptions] = useState<SongOptions | null>(null);

  const updateOption = (key: string, value: number) => {
    let options: any = { ...songOptions };
    options[key] = value;
    setSongOptions(options);
  };

  const setSession = (session: ITrainingSessionMeta | null) => {
    sessionSupportReference = session;
    setSessionInner(session);
  };

  const initPage = () => {
    let song: SongElement | null = getAppSong(params.get("id") ?? "");
    if (song) {
      setSong(song);
      setVideoIsOn(false);
      setSession({
        song: song,
        startDate: new Date().toISOString(),
      });

      setSongOptions({
        fontSize: getFontSize(),
        toneShift: 0,
      });

      setIsFavorite(testFavoriteSong(song));
    }

    setMissingSong(song === null);
  };

  useEffect(() => {
    storeSessionData(session);
    initPage();
  }, [params]);

  const handleArrowPress = (e: any) => {
    let key: string = e.detail.toLowerCase();
    if (key === "keyr") {
      navigate(getRandomNavPath());
    }
    if (key === "keyv") {
      toggleVideo();
    }
    if (key === "keyp") {
      downloadAsPDF();
    }
    if (key === "keyt") {
      shiftToneUp();
    }
    if (key === "keyy") {
      shiftToneDown();
    }
    if (key === "keyu") {
      augmentFontSize();
    }
    if (key === "keyi") {
      reduceFontSize();
    }
    if (key === "keyf") {
      toggleFavorited();
    }
  };
  (window as any)["handleArrowPress"] = handleArrowPress;

  const reduceFontSize = () => {
    if (songOptions && songOptions.fontSize > 0.6) {
      saveFontSize(songOptions.fontSize - 0.2);
      updateOption("fontSize", songOptions.fontSize - 0.2);
    }
  };
  const augmentFontSize = () => {
    if (songOptions && songOptions.fontSize < 2) {
      saveFontSize(songOptions.fontSize + 0.2);
      updateOption("fontSize", songOptions.fontSize + 0.2);
    }
  };
  const downloadAsPDF = () => {
    setDownloadingPDF(true);
    setTimeout(() => {
      if (song) {
        let id = (song?.id ?? song?.title).replaceAll(" ", "_") + "pdf";
        let path = "./pdf/" + id;
        let link: HTMLAnchorElement = document.createElement("a");
        link.download = id;
        link.href = path;
        link.click();
      }
      setDownloadingPDF(false);
    }, 1000);
  };
  const shiftToneUp = () => {
    if (songOptions) {
      updateOption("toneShift", songOptions.toneShift + 1);
    }
  };
  const shiftToneDown = () => {
    if (songOptions) {
      updateOption("toneShift", songOptions.toneShift - 1);
    }
  };
  const toggleFavorited = () => {
    if (isFavorite) {
      removeFavoriteSong(song);
      setIsFavorite(false);
    } else {
      addFavoriteSong(song);
      setIsFavorite(true);
    }
  };
  const toggleVideo = () => {
    setVideoIsOn(!videoIsOn);
  };

  const triggerKeyCatch = (e: any) => {
    setTimeout(() => {
      (window as any)["handleArrowPress"](e);
    }, 10);
  };

  useEffect(() => {
    window.document.addEventListener("key-pressed", triggerKeyCatch);

    return () => {
      window.document.removeEventListener("key-pressed", triggerKeyCatch);
      storeSessionData(sessionSupportReference);
    };
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {songOptions && (
        <PageLayout
          toolbarButtons={[
            {
              label: "Preferito",
              icon: isFavorite ? <BookmarkIcon /> : <BookmarkBorderIcon />,
              onClick: toggleFavorited,
            },
            {
              label: "",
              icon: <TextDecreaseIcon />,
              onClick: reduceFontSize,
            },
            {
              label: "",
              icon: <TextIncreaseIcon />,
              onClick: augmentFontSize,
            },
            {
              label: "Video",
              icon: <VideoLibraryIcon />,
              onClick: toggleVideo,
            },
            {
              label: "Tono",
              icon: <AddCircleOutlineIcon />,
              onClick: shiftToneUp,
            },
            {
              label: "Tono",
              icon: <RemoveCircleOutlineIcon />,
              onClick: shiftToneDown,
            },
            {
              label: "PDF",
              icon: <DownloadIcon />,
              onClick: downloadAsPDF,
            },
          ]}
          title={song !== null ? "" : "Canto non trovato"}
          content={
            <div>
              {downloadingPDF && (
                <div className="song-view-screen-spinner-wrap">
                  Generazione del PDF...
                </div>
              )}
              {missingSong && <div>{getNotFoundContent()}</div>}
              {!missingSong && song !== undefined && song !== null && (
                <div>
                  <div id="song_render_wrapper">
                    <SongRender song={song} options={songOptions} />
                  </div>
                  {videoIsOn && (
                    <VideoBox
                      onClose={() => {
                        setVideoIsOn(false);
                      }}
                      source={song.url_video}
                    />
                  )}
                  <ScrollCommands />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default SongViewScreen;
