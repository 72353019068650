import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Gloria a dio nell'alto dei cieli.",
  url_video: "https://www.youtube.com/embed/Ok-FodIVNvg",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                        La",
          text: "GLORIA A DIO NELL'ALTO DEI CIELI",
        },
        {
          notes: "   Sol                                 La",
          text: "E PACE IN TERRA AGLI UOMINI AMATI DAL SIGNORE.",
        },
        {
          notes: "     Re              La           Sol",
          text: "TI LODIAMO, TI BENEDICIAMO, TI ADORIAMO",
        },
        {
          notes: "                                       La",
          text: "TI RENDIAMO GRAZIE PER LA TUA GLORIA IMMENSA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol                               Fa#-",
          text: "Signore Dio, Re del cielo, Dio Padre Onnipotente;",
        },
        {
          notes: "                 Mi-",
          text: "Signore Figlio unigenito,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    La",
          text: "GESÙ CRISTO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol                               Fa#-",
          text: "Signore Iddio, Agnello di Dio, Figlio del Padre:",
        },
        {
          notes: "                           Mi-",
          text: "tu che togli i peccati del mondo,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            La",
          text: "ABBI PIETÀ DI NOI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol",
          text: "Tu che togli i peccati del mondo",
        },
        {
          notes: "                  Fa#-",
          text: "accogli la nostra supplica",
        },
        {
          notes: "                             Mi-",
          text: "tu che siedi alla destra del Padre",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            La",
          text: "ABBI PIETÀ DI NOI!",
        },
        {
          notes: "       Sol                           Fa#-",
          text: "PERCHÉ TU SOLO IL SANTO, TU SOLO IL SIGNORE,",
        },
        {
          notes: "           Mi-",
          text: "TU SOLO L'ALTISSIMO,",
        },
        {
          notes: "      La",
          text: "GESÙ CRISTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Con lo Spirito Santo",
        },
        {
          notes: "                    Re",
          text: "nella gloria di Dio Padre!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re  Sol Re",
          text: "AMEN, AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
