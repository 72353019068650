import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Caritas christi.",
  url_video: "https://www.youtube.com/embed/srq2iIdH7ts",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  altnotes: "Mi-;II",
  blocks: [
    {
      speaker: "T.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Do    Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "Fa                     Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "La-             Do                   Mi",
          text: "L'AMORE DI CRISTO CI SPINGE, AL PENSIERO,",
        },
        {
          notes: "Fa                                   Mi",
          text: "L'AMORE DI CRISTO CI SPINGE, AL PENSIERO,",
        },
        {
          notes: "La-                           Do",
          text: "CHE SE UNO È MORTO PER TUTTI",
        },
        {
          notes: "           Mi",
          text: "TUTTI SONO MORTI;",
        },
        {
          notes: "        Fa",
          text: "CHE SE CRISTO È MORTO PER TUTTI",
        },
        {
          notes: "           Mi",
          text: "TUTTI SONO MORTI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                       Mi",
          text: "Ed è morto per tutti, perché quelli che vivono,",
        },
        {
          notes: "Re-                    Mi",
          text: "non vivano più per sé stessi,",
        },
        {
          notes: "Fa                   Re°       Sol      Mi",
          text: "ma per colui che è morto ed è risorto per loro.",
        },
      ],
    },
    {
      speaker: "T.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Do    Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "Fa                     Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                Mi",
          text: "Se uno è in Cristo è una nuova creazione,",
        },
        {
          notes: "Re-                                            Mi",
          text: "le cose vecchie sono passate, ne sono nate di nuove,",
        },
        {
          notes: "Fa                               Re-",
          text: "colui che non aveva conosciuto il peccato",
        },
        {
          notes: "   Re°",
          text: "Dio lo trattò da peccato",
        },
        {
          notes: "Sol                                         Mi",
          text: "perché noi diventassimo giustizia di Dio in Lui.",
        },
      ],
    },
    {
      speaker: "T.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Do    Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "Fa                     Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        La7                      Re-",
          text: "Guai a me, se non annunziassi il Vangelo!",
        },
        {
          notes: "       Mi         La-",
          text: "Guai a me! Guai a me!",
        },
      ],
    },
    {
      speaker: "T.",
      mode: "normal",
      lines: [
        {
          notes: "La-        La7                      Re-",
          text: "GUAI A ME, SE NON ANNUNZIASSI IL VANGELO!",
        },
        {
          notes: "       Mi         La-",
          text: "GUAI A ME! GUAI A ME!",
        },
        {
          notes: "La-             Do    Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "Fa                     Mi",
          text: "CARITAS CHRISTI URGET NOS!",
        },
        {
          notes: "La-             Do                   Mi",
          text: "L'AMORE DI CRISTO CI SPINGE, AL PENSIERO,",
        },
        {
          notes: "Fa                                   Mi",
          text: "L'AMORE DI CRISTO CI SPINGE, AL PENSIERO,",
        },
        {
          notes: "La-                           Do",
          text: "CHE SE UNO È MORTO PER TUTTI",
        },
        {
          notes: "           Mi",
          text: "TUTTI SONO MORTI;",
        },
        {
          notes: "        Fa",
          text: "CHE SE CRISTO È MORTO PER TUTTI",
        },
        {
          notes: "           Mi",
          text: "TUTTI SONO MORTI.",
        },
      ],
    },
  ],
  subtitle: "(2Cor 5, 14-17.21; 1Cor 9, 16b)",
};
