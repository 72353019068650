import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Se nel Signore mi sono rifugiato.",
  url_video: "https://www.youtube.com/embed/9Q7IzEWjaJY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol      Si7",
          text: "SE NEL SIGNORE MI SONO RIFUGIATO",
        },
        {
          notes: "                               Mi-",
          text: "COME POTETE DIRMI, COME POTETE DIRMI,",
        },
        {
          notes: "    Do7                         Si7",
          text: "CHE FUGGA COME UN PASSERO VERSO IL MONTE.",
          msg: "  (2 v.)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                          Si7",
          text: "Io so che gli empi tendono l'arco",
        },
        {
          notes: "   Do                        Si7",
          text: "e aggiustano le frecce sulla corda",
        },
        {
          notes: "     Do7                        Si7",
          text: "per colpire nel buio i retti di cuore.",
        },
        {
          notes: "La-                           Si7",
          text: "Ma quando sono scosse le fondamenta",
        },
        {
          notes: "La-                    Si7",
          text: "il giusto che cosa può fare?",
          msg: "  (2 v.)",
        },
        {
          notes: "Sol               Si7                  Mi-",
          text: "Ma il Signore, il Signore è nel tempio santo,",
        },
        {
          notes: "Do                             Si7",
          text: "il Signore ha il suo trono nei cieli,",
        },
        {
          notes: "Do7                          Si7",
          text: "i suoi occhi sono aperti sul mondo,",
        },
        {
          notes: "Do7                          Si7",
          text: "le sue pupille scrutano ogni uomo.",
        },
        {
          notes: "Sol    Si7          Mi-",
          text: "Il Signore scruta i giusti,",
        },
        {
          notes: "Do7                            Si7",
          text: "ma il Signore scruta anche gli empi,",
        },
        {
          notes: "Do7                       Si7",
          text: "il Signore non ama la violenza.",
          msg: "  (2 v.)",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol      Si7",
          text: "SE NEL SIGNORE MI SONO RIFUGIATO",
        },
        {
          notes: "                               Mi-",
          text: "COME POTETE DIRMI, COME POTETE DIRMI,",
        },
        {
          notes: "    Do7                         Si7",
          text: "CHE FUGGA COME UN PASSERO VERSO IL MONTE.",
          msg: "  (2 v.)",
        },
      ],
    },
  ],
  subtitle: "Sal 10(11)",
};
