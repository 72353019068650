import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Prefazio dell'eucaristia della veglia pasquale.",
  url_video: "",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "Il Signore sia con voi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol          La-",
          text: "E CON IL TUO SPIRITO",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Fa               Mi",
          text: "In alto i nostri cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Mi",
          text: "SONO RIVOLTI AL SIGNORE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7                               La-",
          text: "Rendiamo grazie al Signore nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol            La-",
          text: "È COSA BUONA E GIUSTA.",
        },
        {
          notes: "Sol            La-",
          text: "È COSA BUONA E GIUSTA.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Re-          La-",
          text: "È veramente cosa buona e giusta,",
        },
        {
          notes: "         Mi                La-",
          text: "nostro dovere e fonte di salvezza,",
        },
        {
          notes: "           Re-           La-     Mi   La-",
          text: "proclamare sempre la tua gloria, o Signore",
        },
        {
          notes: "Fa                                 La-",
          text: "e soprattutto esaltarti in questa notte",
        },
        {
          notes: "Fa                                          Mi",
          text: "nella quale Cristo, nostra pasqua, si è immolato.",
        },
        {
          notes: "  La-          Sol",
          text: "È lui il vero agnello",
        },
        {
          notes: "                           La-",
          text: "che ha tolto i peccati del mondo,",
        },
        {
          notes: "            Sol                   La-",
          text: "è lui che morendo ha distrutto la morte",
        },
        {
          notes: "       Sol                   La-",
          text: "e risorgendo ci ha ridato la vita:",
        },
        {
          notes: "                                  Sol",
          text: "è lui che morendo ha distrutto la morte",
        },
        {
          notes: "       Fa                    Mi",
          text: "e risorgendo ci ha ridato la vita!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-",
          text: "È LUI CHE MORENDO",
        },
        {
          notes: "                Sol",
          text: "HA DISTRUTTO LA MORTE",
        },
        {
          notes: "       Fa                    Mi",
          text: "E RISORGENDO CI HA RIDATO LA VITA!",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "   La-                  Sol",
          text: "Per questo mistero di salvezza",
        },
        {
          notes: "                               La-",
          text: "nella pienezza della gioia pasquale,",
        },
        {
          notes: "               Sol               La-",
          text: "l'umanità intera esulta su tutta la terra",
        },
        {
          notes: "                                     Sol",
          text: "e con l'assemblea degli angeli e dei santi",
        },
        {
          notes: "La-    Fa                     La-",
          text: "canta, canta l'inno della tua gloria:",
        },
      ],
    },
    {
      speaker: "\n\n     Proseguire col “SANTO“ del tempo liturgico",
      mode: "red-utility-message",
      lines: [],
    },
  ],
  subtitle: "",
};
