import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Molto mi hanno perseguitato.",
  url_video: "https://www.youtube.com/embed/F43_w7ci2ek",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                                   La7",
          text: "Molto mi hanno perseguitato dalla giovinezza;",
        },
        {
          notes: "                                            Re-",
          text: "molto mi hanno perseguitato, lo dica Israele.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "                                     La7",
          text: "MOLTO MI HANNO PERSEGUITATO, DALLA GIOVINEZZA;",
        },
        {
          notes: "                                             Re-",
          text: "MOLTO MI HANNO PERSEGUITATO, MA NON HANNO PREVALSO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                            La7",
          text: "Sul mio dorso ararono aratori,",
        },
        {
          notes: "                               Re-",
          text: "sul mio dorso allungarono i solchi.",
        },
        {
          notes: "                                     La7",
          text: "Sul mio dorso hanno arato gli aratori,",
        },
        {
          notes: "                               Re-",
          text: "sul mio dorso allungarono i solchi.",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "                          La7",
          text: "Ma il Signore, che è giusto,",
        },
        {
          notes: "                                Re-",
          text: "ha spezzato il giogo dei malvagi.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "                           La7",
          text: "RETROCEDANO QUANTI ODIANO SION,",
        },
        {
          notes: "                            Re-",
          text: "RETROCEDANO QUANTI ODIANO SION.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                                        La7",
          text: "Che siano come l'erba che cresce sui tetti,",
        },
        {
          notes: "                                  Re-",
          text: "che prima che sia strappata dissecca.",
        },
        {
          notes: "                                             La7",
          text: "Mai riempie con essa il mietitore la sua mano,",
        },
        {
          notes: "                                 Re-",
          text: "né il grembo chi raccoglie i covoni.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "                           La7",
          text: "RETROCEDANO QUANTI ODIANO SION,",
        },
        {
          notes: "                            Re-",
          text: "RETROCEDANO QUANTI ODIANO SION.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "                                     La7",
          text: "MOLTO MI HANNO PERSEGUITATO, DALLA GIOVINEZZA;",
        },
        {
          notes: "                                             Re-",
          text: "MOLTO MI HANNO PERSEGUITATO, MA NON HANNO PREVALSO",
        },
      ],
    },
  ],
  subtitle: "Sal 128(129)",
};
