import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Nessuno può servire due padroni.",
  url_video: "https://www.youtube.com/embed/oXXF4jcCsgU",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  altnotes: "Mi-",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-  Sol                  Fa",
          text: "Nessuno può servire due padroni",
        },
        {
          notes: "Sol             Fa",
          text: "perché amando l'uno disprezza l'altro;",
        },
        {
          notes: "Re-         (Sol7)  Fa        Mi",
          text: "non potete servire Dio e il denaro!",
        },
        {
          notes: "           La-",
          text: "Perciò vi dico:",
        },
        {
          notes: "Sol           Fa",
          text: "non angustiatevi per la vostra vita,",
        },
        {
          notes: "Sol           Fa",
          text: "cosa mangerete, cosa berrete,",
        },
        {
          notes: "La-",
          text: "come vestirete;",
        },
        {
          notes: "Re-           Fa                   Mi",
          text: "forse che la vita non vale più del cibo?",
        },
        {
          notes: "Re-                   Fa",
          text: "forse che il vostro corpo",
        },
        {
          notes: "                     Mi",
          text: "non vale più del vestito?",
        },
        {
          notes: "Do      Mi-",
          text: "Guardate gli uccelli del cielo:",
        },
        {
          notes: "      Mi7         Fa7   Fa",
          text: "non seminano e non mietono",
        },
        {
          notes: "                              Mi",
          text: "eppure il Padre vostro li alimenta!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do      Mi-",
          text: "GUARDATE GLI UCCELLI DEL CIELO:",
        },
        {
          notes: "      Mi7         Fa7   Fa",
          text: "NON SEMINANO E NON MIETONO",
        },
        {
          notes: "                              Mi",
          text: "EPPURE IL PADRE VOSTRO LO ALIMENTA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-       Sol                 Fa",
          text: "E chi di voi per quanto si preoccupi",
        },
        {
          notes: "Sol                 Fa     La-",
          text: "può allungare di un'ora la sua vita?",
        },
        {
          notes: "       Re-      Fa            Mi",
          text: "E del vestito perché vi inquietate?",
        },
        {
          notes: "Do        Mi-",
          text: "Guardate i gigli del campo:",
        },
        {
          notes: "   Mi7          Fa7   Fa",
          text: "non filano e non tessono",
        },
        {
          notes: "                         Mi",
          text: "Salomone non vestiva come loro!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do        Mi-",
          text: "GUARDATE I GIGLI DEL CAMPO:",
        },
        {
          notes: "   Mi7          Fa7   Fa",
          text: "NON FILANO E NON TESSONO",
        },
        {
          notes: "                           Mi",
          text: "SALOMONE NON VESTIVA COME LORO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do            Mi-",
          text: "Cercate prima il regno di Dio",
        },
        {
          notes: "   Mi7          Fa7",
          text: "e tutto vi sarà dato",
        },
        {
          notes: "      Fa                     Mi",
          text: "non potete servire Dio e il denaro!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do    Mi-                Mi7          Fa7 Fa",
          text: "CERCATE IL REGNO DI DIO E TUTTO VI SARÀ DATO",
        },
        {
          notes: "                        Mi",
          text: "NON POTETE SERVIRE DUE PADRONI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do            Mi-",
          text: "Nessuno può servire due padroni",
        },
        {
          notes: "   Mi7          Fa7",
          text: "perché amando l'uno disprezza l'altro;",
        },
        {
          notes: "      Fa                     Mi",
          text: "non potete servire Dio e il denaro!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do      Mi-",
          text: "GUARDATE GLI UCCELLI DEL CIELO:",
        },
        {
          notes: "      Mi7         Fa7   Fa",
          text: "NON SEMINANO E NON MIETONO",
        },
        {
          notes: "                              Mi",
          text: "EPPURE IL PADRE VOSTRO LO ALIMENTA!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do        Mi-",
          text: "GUARDATE I GIGLI DEL CAMPO:",
        },
        {
          notes: "   Mi7          Fa7   Fa",
          text: "NON FILANO E NON TESSONO",
        },
        {
          notes: "                           Mi",
          text: "SALOMONE NON VESTIVA COME LORO!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do    Mi-                Mi7          Fa7 Fa",
          text: "CERCATE IL REGNO DI DIO E TUTTO VI SARÀ DATO",
        },
        {
          notes: "                        Mi",
          text: "NON POTETE SERVIRE DUE PADRONI!",
        },
      ],
    },
  ],
  subtitle: "(Mt. 6, 24-33)",
};
