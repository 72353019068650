import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Giunga la mia preghiera fino a te.",
  url_video: "https://www.youtube.com/embed/6dDhUXARSws",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-     Fa      Mi",
          text: "Signor, Signor, Gesù!",
        },
        {
          notes: "   La-",
          text: "Giunga la mia preghiera fino a te,",
        },
        {
          notes: "  Fa",
          text: "nasca dalle mie labbra un nuovo canto,",
        },
        {
          notes: " Sol",
          text: "canti la mia lingua la tua Parola,",
        },
        {
          notes: "      Fa    Mi",
          text: "anima mia, Gesù!",
        },
        {
          notes: "La-",
          text: "Sia con me la tua mano per aiutarmi,",
        },
        {
          notes: "   Fa",
          text: "aspetto da te la liberazione;",
        },
        {
          notes: "Sol",
          text: "canti la mia lingua per lodarti:",
        },
        {
          notes: " Fa            Mi",
          text: "guardami, son perduto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Sol",
          text: "VIENI IN CERCA DEL TUO SERVO,",
        },
        {
          notes: "Fa            Mi",
          text: "VIENI SIGNOR GESÙ!",
        },
        {
          notes: "La-                    Sol",
          text: "VIENI, CHE SENZA TE MI PERDO",
        },
        {
          notes: "Fa            Mi",
          text: "VIENI SIGNOR GESÙ!",
        },
      ],
    },
  ],
  subtitle: "Sal 118(119)",
};
