import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lo stolto pensa che non c'è dio.",
  url_video: "https://www.youtube.com/embed/j8ldG5mFoDY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                          La7",
          text: "Lo stolto pensa che non c'è Dio.",
        },
        {
          notes: "                                 Re-",
          text: "Sono corrotti e fanno cose abominevoli:",
        },
        {
          notes: "Sol-               La7",
          text: "nessuno più agisce bene,",
        },
        {
          notes: "Sib                La7",
          text: "nessuno più agisce bene.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                  La7",
          text: "Il Signore dal cielo si china sugli uomini",
        },
        {
          notes: "                              Re-",
          text: "per vedere se esiste un uomo saggio,",
        },
        {
          notes: "Sol-               La7",
          text: "un uomo che cerchi Dio,",
        },
        {
          notes: "Sib                La7",
          text: "un uomo che cerchi Dio.",
        },
        {
          notes: "Fa                     La7",
          text: "Tutti hanno traviato, sono tutti corrotti:",
        },
        {
          notes: "Sib                             La7",
          text: "più nessuno fa il bene, neppure uno.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                          La7",
          text: "Lo stolto pensa che non c'è Dio.",
        },
        {
          notes: "                                 Re-",
          text: "Sono corrotti e fanno cose abominevoli:",
        },
        {
          notes: "Sol-               La7",
          text: "nessuno più agisce bene,",
        },
        {
          notes: "Sib                La7",
          text: "nessuno più agisce bene.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                         La7",
          text: "Non comprendono nulla i malvagi,",
        },
        {
          notes: "Sol-                                          La7",
          text: "i malvagi che divorano il mio popolo come il pane.",
        },
        {
          notes: "Sib                                  La7",
          text: "Non invocavano Dio: ma tremano di spavento,",
        },
        {
          notes: "Fa         La",
          text: "perchè Dio è con il giusto,",
        },
        {
          notes: "Sib                   La7",
          text: "il Signore è il suo rifugio.",
        },
        {
          notes: "Re-       Do          La",
          text: "Perchè Dio è con il giusto,",
        },
        {
          notes: "Sib                   La7",
          text: "il Signore è il suo rifugio.",
          msg: "  (L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                          La7",
          text: "Lo stolto pensa che non c'è Dio.",
        },
        {
          notes: "                                 Re-",
          text: "Sono corrotti e fanno cose abominevoli:",
        },
        {
          notes: "Sol-               La7",
          text: "nessuno più agisce bene,",
        },
        {
          notes: "Sib                La7",
          text: "nessuno più agisce bene.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-       Do          La",
          text: "Perchè Dio è con il giusto,",
        },
        {
          notes: "Sib                   La7",
          text: "il Signore è il suo rifugio.",
        },
      ],
    },
  ],
  subtitle: "",
};
