import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedizione per la celebrazione penitenziale.",
  url_video: "",
  barrè: "II",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                La-",
          text: "Il Signore sia con voi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol           La-",
          text: "E CON IL TUO SPIRITO.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Mi",
          text: "In alto i nostri cuori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Mi",
          text: "SONO RIVOLTI AL SIGNORE",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi7                               La-",
          text: "Rendiamo grazie al Signore nostro Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: " Sol          La-",
          text: "È COSA BUONA E GIUSTA.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Re-          La-",
          text: "È veramente cosa buona e giusta",
        },
        {
          notes: "         Mi                 La-",
          text: "nostro dovere e fonte di salvezza",
        },
        {
          notes: "                Re-              La-",
          text: "rendere grazie sempre e in ogni luogo",
        },
        {
          notes: "Mi                               La-",
          text: "a te, Dio onnipotente e misericordioso,",
        },
        {
          notes: "Fa                                 La-",
          text: "che in modo mirabile hai creato l'uomo",
        },
        {
          notes: "Fa                             Mi",
          text: "e in modo più mirabile l'hai redento.",
        },
        {
          notes: "La-                      Sol",
          text: "Tu non abbandoni il peccatore,",
        },
        {
          notes: "                          La-",
          text: "ma lo cerchi con amore di Padre.",
        },
        {
          notes: "                           Sol",
          text: "Tu nella passione del tuo Figlio",
        },
        {
          notes: "                          La-",
          text: "hai vinto il peccato e la morte",
        },
        {
          notes: "                   Sol",
          text: "e nella sua risurrezione",
        },
        {
          notes: "                 La-",
          text: "ci hai ridato la vita.",
        },
        {
          notes: "                                          Sol",
          text: "Tu hai effuso nei nostri cuori lo Spirito Santo",
        },
        {
          notes: "                        La-",
          text: "per farci tuoi figli ed eredi.",
        },
        {
          notes: "                Sol",
          text: "Tu sempre ci rinnovi",
        },
        {
          notes: "                       La-",
          text: "con i sacramenti di salvezza,",
        },
        {
          notes: "                                        Sol",
          text: "perché, liberati dalla schiavitù del peccato,",
        },
        {
          notes: "             Fa                          Mi",
          text: "siamo trasformati nell'immagine del tuo Figlio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                     Sol",
          text: "PERCHÈ, LIBERATI DALLA SCHIAVITÙ DEL PECCATO",
        },
        {
          notes: "             Fa                          Mi",
          text: "SIAMO TRASFORMATI NELL'IMMAGINE DEL TUO FIGLIO.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                Sol",
          text: "Noi ti lodiamo e ti benediciamo, Signore,",
        },
        {
          notes: "                           La-",
          text: "in comunione con tutta la Chiesa,",
        },
        {
          notes: "                                      Sol",
          text: "per queste meraviglie della tua misericordia,",
        },
        {
          notes: "La-        Fa",
          text: "cantiamo, cantiamo",
        },
        {
          notes: "Sol              La-",
          text: "l'inno della tua gloria.",
        },
      ],
    },
  ],
  subtitle: "Nota: Proseguire col “SANTO“ del Tempo Ordinario",
};
