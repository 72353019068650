import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Il_ragazzo_della_via_gluck.",
  title: "Il ragazzo della via gluck.",
  url_video: "https://www.youtube.com/embed/LlIjTECarkg",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "            Re                La7",
          text: "Questa è la storia di uno di noi",
        },
        {
          notes: "                               Re",
          text: "Anche lui nato per caso in via Gluck",
        },
        {
          notes: "                      La7",
          text: "In una casa, fuori città",
        },
        {
          notes: "                           Re",
          text: "Gente tranquilla che lavorava",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "         Si-                       Re",
          text: "Là dove c'era l'erba, ora c'è una città",
        },
        {
          notes: "         Si-",
          text: "E quella casa in mezzo al verde",
        },
        {
          notes: "            Re La7 Re",
          text: "Ormai dove sarà?",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                         La7",
          text: "Questo ragazzo della via Gluck",
        },
        {
          notes: "                           Re",
          text: "Si divertiva a giocare con me",
        },
        {
          notes: "                               La7",
          text: "Ma un giorno disse: ?Vado in città?",
        },
        {
          notes: "                        Re",
          text: "E lo diceva mentre piangeva",
        },
        {
          notes: "                                     La7",
          text: "Io gli domando: ?Amico, non sei contento?",
        },
        {
          notes: "                             Re",
          text: "Vai finalmente a stare in città",
        },
        {
          notes: "        Si-                             Re",
          text: "Là troverai le cose che non hai avuto qui",
        },
        {
          notes: "       Si-",
          text: "Potrai lavarti in casa",
        },
        {
          notes: "                        Re",
          text: "Senza andar giù nel cortile!?",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                                 La7",
          text: "Mio caro amico disse: ?Qui sono nato",
        },
        {
          notes: "                                    Re",
          text: "In questa strada ora lascio il mio cuore",
        },
        {
          notes: "                      La7",
          text: "Ma come fai a non capire",
        },
        {
          notes: "                                Re",
          text: "È una fortuna, per voi che restate",
        },
        {
          notes: "                              La7",
          text: "A piedi nudi a giocare nei prati",
        },
        {
          notes: "                                 Re",
          text: "Mentre là in centro respiro il cemento",
        },
        {
          notes: "            Si-                           Re",
          text: "Ma verrà un giorno che ritornerò ancora qui",
        },
        {
          notes: "      Si-",
          text: "E sentirò l'amico treno",
        },
        {
          notes: "              Re",
          text: "Che fischia così, ?wa wa?! ?",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "            Re                La7",
          text: "Passano gli anni, ma otto son lunghi",
        },
        {
          notes: "                               Re",
          text: "Però quel ragazzo ne ha fatta di strada",
        },
        {
          notes: "                      La7",
          text: "Ma non si scorda la sua prima casa",
        },
        {
          notes: "                        Re",
          text: "Ora coi soldi lui può comperarla",
        },
        {
          notes: "                                     La7",
          text: "Torna e non trova gli amici che aveva",
        },
        {
          notes: "                             Re",
          text: "Solo case su case, catrame e cemento",
        },
        {
          notes: "            Si-                           Re",
          text: "Là dove c'era l'erba ora c'è una città",
        },
        {
          notes: "      Si-",
          text: "E quella casa in mezzo al verde",
        },
        {
          notes: "              Re",
          text: "Ormai dove sarà?",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "               Re",
          text: "Eh, Non so, non so",
        },
        {
          notes: "         Si-                       Re",
          text: "Perché continuano a costruire le case",
        },
        {
          notes: "               Si-",
          text: "E non lasciano l'erba",
        },
        {
          notes: " ",
          text: "Non lasciano l'erba",
        },
        {
          notes: "             Re",
          text: "Non lasciano l'erba",
        },
        {
          notes: "             Si-",
          text: "Non lasciano l'erba",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " ",
          text: "Eh, no",
        },
        {
          notes: "                  Re",
          text: "Se andiamo avanti così",
        },
        {
          notes: "    La7           Re",
          text: "Chissà, come si farà",
        },
        {
          notes: " ",
          text: "Chissà...",
        },
      ],
    },
  ],
  subtitle: "Adriano Celentano",
  sent_for_revision: false,
};
