import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Se oggi ascoltate la sua voce.",
  url_video: "https://www.youtube.com/embed/dJC58GfbdJo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                        Fa#-",
          text: "SE OGGI ASCOLTATE LA SUA VOCE",
        },
        {
          notes: "       Si7             Mi",
          text: "NON INDURITE I VOSTRI CUORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do#-                   Fa#-",
          text: "Venite, applaudiamo al Signore",
        },
        {
          notes: "     Si7                       Mi",
          text: "acclamiamo alla roccia che ci salva.",
        },
        {
          notes: "                Do#-              Sol#",
          text: "Accostiamoci a lui, per rendergli grazie,",
        },
        {
          notes: "                                  Do#-  Mi",
          text: "a lui acclamiamo con canti e con salmi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                        Fa#-",
          text: "SE OGGI ASCOLTATE LA SUA VOCE",
        },
        {
          notes: "       Si7             Mi",
          text: "NON INDURITE I VOSTRI CUORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-                     Fa#-",
          text: "Poiché grande Dio è il Signore,",
        },
        {
          notes: "       Si7                Mi",
          text: "grande re sopra tutti gli dèi.",
        },
        {
          notes: "           Do#-                      Sol#",
          text: "Nella sua mano sono gli abissi della terra,",
        },
        {
          notes: "                           Do#-  Mi",
          text: "suo è il mare, egli l'ha fatto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                        Fa#-",
          text: "SE OGGI ASCOLTATE LA SUA VOCE",
        },
        {
          notes: "       Si7             Mi",
          text: "NON INDURITE I VOSTRI CUORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-                 Sol#",
          text: "Venite, prostrati adoriamo",
        },
        {
          notes: "",
          text: "in ginocchio,",
        },
        {
          notes: "                             Do#-",
          text: "davanti al Signore che ci ha creati.",
        },
        {
          notes: "                   Fa#-   Si7             Mi",
          text: "Egli è il nostro Dio e noi siamo il suo popolo,",
        },
        {
          notes: "             Do#-              Sol#",
          text: "siamo il suo gregge, che egli conduce,",
        },
        {
          notes: "                Do#-    Mi",
          text: "popolo del suo pascolo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                        Fa#-",
          text: "SE OGGI ASCOLTATE LA SUA VOCE",
        },
        {
          notes: "       Si7             Mi",
          text: "NON INDURITE I VOSTRI CUORI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#-     Sol#",
          text: "Magari, magari",
        },
        {
          notes: "                                               Do#-",
          text: "ascoltaste oggi la sua voce e non induriste il cuore,",
        },
        {
          notes: "        Fa#-",
          text: "come a Merìba,",
        },
        {
          notes: "                     Si7        Mi",
          text: "come nel giorno di Massa, nel deserto,",
        },
        {
          notes: "              Do#-                 Sol#",
          text: "dove i vostri padri mi misero alla prova,",
        },
        {
          notes: "",
          text: "pur avendo visto le mie opere,",
        },
        {
          notes: "                               Do#-",
          text: "mi obbligarono a fare la loro volontà.",
        },
        {
          notes: "             Fa#-         Si7                    Mi",
          text: "Per quarant'anni mi disgustai di quella generazione,",
        },
        {
          notes: "   Do#-                              Sol#",
          text: "e dissi: “sono un popolo dal cuore traviato,",
        },
        {
          notes: "",
          text: "non conoscono le mie vie,",
        },
        {
          notes: "                              Do#-     Mi",
          text: "perciò non entreranno nel mio riposo“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                        Fa#-",
          text: "SE OGGI ASCOLTATE LA SUA VOCE",
        },
        {
          notes: "       Si7             Mi",
          text: "NON INDURITE I VOSTRI CUORI",
        },
      ],
    },
  ],
  subtitle: "Sal 94(95)",
};
