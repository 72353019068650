import { ButtonBase } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CommandBar.scss";
import { useEffect, useState } from "react";

export interface CommandBarButton {
  onClick: () => void;
  label?: string;
  icon?: JSX.Element;
}

export interface CommandBarProps {
  buttons: CommandBarButton[];
  emit?: (action: any) => void;
}

const CommandBar = (props: CommandBarProps) => {
  const [enabled, setEnabled] = useState<boolean>(true);

  useEffect(() => {
    setEnabled(true);
  }, [window.location.href]);

  return (
    <div>
      {enabled && (
        <div className="command-bar-wrap box-shadow command_bar_background">
          <div className="command-bar-wrap-inner dark_text_color">
            {props.buttons.map((x: CommandBarButton, i: number) => {
              return (
                <ButtonBase key={i} onClick={x.onClick}>
                  <div className="command-bar-button">
                    {x.icon && <span>{x.icon}</span>}
                    {x.icon && x.label && (
                      <span
                        className="hide-on-small-screens"
                        style={{ width: "0.5em" }}
                      ></span>
                    )}
                    {x.label && (
                      <span className="hide-on-small-screens">{x.label}</span>
                    )}
                  </div>
                </ButtonBase>
              );
            })}
          </div>
          <div style={{ opacity: "0.5", padding: "0 1em" }}>
            <ButtonBase
              onClick={() => {
                setEnabled(false);
                if (props.emit) {
                  props.emit("closed");
                }
              }}
            >
              <CloseIcon />
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommandBar;
