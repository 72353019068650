import {
  APP_STATE,
  CUSTOM_SONG_KEY,
  FAVORITED_STORAGE_KEY,
  LITURGY_STORAGE_KEY,
  MAXIMUM_SESSION_ACCEPTED,
  MAX_DAYS_FOR_CHART_DISPLAY,
  MINIMUM_SESSION_ACCEPTED,
  OPTIONS_KEY,
  TRAINING_KEY,
} from "./Constants";
import { SongElement } from "./Songs/SongElement";
import { initExposedSongs } from "./Songs/SongHandler";

const readState = () => {
  return localStorage.getItem(APP_STATE);
};

export const getLiturgy = () => {
  let data = readState();
  let state: any = {};
  if (data !== null) {
    state = JSON.parse(data);
  }

  return state[LITURGY_STORAGE_KEY] ?? {};
};

export const setLiturgy = (liturgyObj: any) => {
  if (liturgyObj === null) {
    return;
  }

  let data = readState();
  let state: any = {};
  if (data !== null) {
    state = JSON.parse(data);
  }

  state[LITURGY_STORAGE_KEY] = liturgyObj;
  localStorage.setItem(APP_STATE, JSON.stringify(state));
};

export interface IFavoriteSong {
  id: string;
  title: string;
  color: string;
  semantic: string;
}

export const addFavoriteSong = (song: SongElement | null) => {
  if (song === null) {
    return;
  }

  let data = readState();
  let state: any = {};
  let parsed = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[FAVORITED_STORAGE_KEY] ?? [];
  }

  if (
    parsed.filter((x: any) => (x.id ?? x.title) === (song.id ?? song.title))
      .length === 0
  ) {
    parsed.push({
      id: song.id ?? song.title,
      title: song.title,
      color: song.color,
      semantic: song.semantic,
    });

    state[FAVORITED_STORAGE_KEY] = parsed;
    localStorage.setItem(APP_STATE, JSON.stringify(state));
  }
};

export const getFavoriteSongs = () => {
  let data = readState();
  let state: any = {};
  let parsed: IFavoriteSong[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[FAVORITED_STORAGE_KEY] ?? [];
  }
  return parsed;
};

export const removeFavoriteSongById = (id: string) => {
  let data = readState();
  let state: any = {};
  let parsed: IFavoriteSong[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[FAVORITED_STORAGE_KEY] ?? [];
  }

  let index = -1;
  for (let i = 0; i < parsed.length; i++) {
    if (parsed[i].id === id) {
      index = i;
      break;
    }
  }

  if (index !== -1) {
    parsed.splice(index, 1);
    state[FAVORITED_STORAGE_KEY] = parsed;
    localStorage.setItem(APP_STATE, JSON.stringify(state));
  }
};

export const removeFavoriteSong = (song: SongElement | null) => {
  if (song === null) {
    return;
  }
  removeFavoriteSongById(song.id ?? song.title);
};

export const testFavoriteSong = (song: SongElement | null) => {
  if (song === null) {
    return false;
  }

  let data = readState();
  let state: any = {};
  let parsed: IFavoriteSong[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[FAVORITED_STORAGE_KEY] ?? [];
  }

  let matches: IFavoriteSong[] = parsed.filter(
    (x: IFavoriteSong) => (x.id ?? x.title) === (song.id ?? song.title)
  );
  if (matches.length > 0) {
    return true;
  }

  return false;
};

export interface ITrainingSession {
  date: string;
  duration: number;
}

export interface ITrainingData {
  title: string;
  id: string;
  views: number;
  trainings: ITrainingSession[];
  globalDuration: number;
}

export const getTrainingData = () => {
  let data = readState();
  let state: any = {};
  let parsed: ITrainingData[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[TRAINING_KEY] ?? [];
  }
  return parsed;
};

export const addTrainindData = (
  song: SongElement | null,
  sessionDuration: number
) => {
  if (song === null) {
    return;
  }

  if (
    sessionDuration < MINIMUM_SESSION_ACCEPTED ||
    sessionDuration > MAXIMUM_SESSION_ACCEPTED
  ) {
    return;
  }

  let data = readState();
  let state: any = {};
  let parsed: ITrainingData[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[TRAINING_KEY] ?? [];
  }

  let nowDate = new Date();
  let todaysDate: string = nowDate.toISOString().split("T")[0];

  let found = false;
  for (let i = 0; i < parsed.length; i++) {
    let item: ITrainingData = parsed[i];
    if (item.id === (song.id ?? song.title)) {
      found = true;
      item.views += 1;
      item.globalDuration += sessionDuration;
      let targetSession: ITrainingSession = {
        date: todaysDate,
        duration: sessionDuration,
      };

      let todaysSession: ITrainingSession[] = item.trainings.filter(
        (x: ITrainingSession) => x.date === todaysDate
      );

      if (todaysSession.length === 1) {
        todaysSession[0].duration += sessionDuration;
      } else {
        item.trainings.push(targetSession);
      }
      break;
    }
  }

  if (!found) {
    parsed.push({
      id: song.id ?? song.title,
      title: song.title,
      views: 1,
      globalDuration: sessionDuration,
      trainings: [
        {
          date: todaysDate,
          duration: sessionDuration,
        },
      ],
    });
  }

  // clean all sessions older than the max accepted
  const maxAcceptedTime = MAX_DAYS_FOR_CHART_DISPLAY * 24 * 60 * 60 * 1000;

  for (let i = 0; i < parsed.length; i++) {
    let training: ITrainingSession[] = parsed[i].trainings;
    let trainingRebuild: ITrainingSession[] = [];
    for (let j = 0; j < training.length; j++) {
      let session: ITrainingSession = training[j];
      if (
        nowDate.getTime() - new Date(session.date).getTime() <
        maxAcceptedTime
      ) {
        trainingRebuild.push({ ...session });
      }
    }
    parsed[i].trainings = [...trainingRebuild];
  }

  state[TRAINING_KEY] = parsed;
  localStorage.setItem(APP_STATE, JSON.stringify(state));
};

export const addCustomSong = (song: SongElement | null) => {
  if (song === null) {
    return;
  }

  let data = readState();
  let state: any = {};
  let parsed: SongElement[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[CUSTOM_SONG_KEY] ?? [];
  }

  parsed.push(song);
  state[CUSTOM_SONG_KEY] = parsed;
  localStorage.setItem(APP_STATE, JSON.stringify(state));
  initExposedSongs();
};

export const getCustomSongs = () => {
  let data = readState();
  let state: any = {};
  let parsed: SongElement[] = [];
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[CUSTOM_SONG_KEY] ?? [];
  }
  return parsed;
};

export const getOptions = () => {
  let data = readState();
  let state: any = {};
  let parsed: any = {};
  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[OPTIONS_KEY] ?? {};
  }
  return parsed;
};

export const setOptions = (name: string, value: any) => {
  let data = readState();
  let state: any = {};
  let parsed: any = {};

  if (data !== null) {
    state = JSON.parse(data);
    parsed = state[OPTIONS_KEY] ?? {};
  }

  parsed[name] = value;
  state[OPTIONS_KEY] = parsed;
  localStorage.setItem(APP_STATE, JSON.stringify(state));
  (window as any)["redrawApp"]();
};

export const resetOptions = () => {
  let data = readState();
  let state: any = {};
  if (data !== null) {
    state = JSON.parse(data);
  }
  delete state[OPTIONS_KEY];
  localStorage.setItem(APP_STATE, JSON.stringify(state));
  (window as any)["redrawApp"]();
};

export const getPrintableHTMLContent = (title: string, contentId: string) => {
  let content: any = document.getElementById(contentId);
  if (content) {
    let cssFromPage: any = document.head.getElementsByTagName("style");
    let cssComposer: string = "";
    for (let i = 0; i < cssFromPage.length; i++) {
      cssComposer += cssFromPage[i].innerHTML;
    }

    let cssAsString: string =
      "<style> body{overflow: auto !important; padding: 1em !important;-webkit-print-color-adjust: exact;} " +
      cssComposer +
      "</style>";

    let html: string = "<html><head><title>" + title + "</title>";
    html += cssAsString;
    html += '</head><body style="background-color: white;">';
    html += content.innerHTML;
    html += "</body></html>";
    return html;
  }

  return "";
};

export const printNode = (title: string, contentId: string) => {
  if (document.getElementById(contentId)) {
    let mywindow: any = window.open("", "PRINT", "height=400,width=600");
    let html: string = getPrintableHTMLContent(title, contentId);
    mywindow.document.write(html);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  }
};
