export const APP_STATE = "state";
export const FAVORITED_STORAGE_KEY = "favorites";
export const LITURGY_STORAGE_KEY = "liturgy";
export const TRAINING_KEY = "training";
export const CUSTOM_SONG_KEY = "customSongs";
export const OPTIONS_KEY = "options";

export const MINIMUM_SESSION_ACCEPTED = 30; // 30 seconds
export const MAXIMUM_SESSION_ACCEPTED = 60 * 10; // 10 minutes
export const MAX_DAYS_FOR_CHART_DISPLAY = 14; // 2 weeks

export const OPTION_THEME_COLOR = "themeColor";
export const OPTION_ALLOW_EXTRA_SONGS = "allowExtraSongs";
export const OPTION_ALLOW_RANDOM_SONGS = "allowRandomSongs";
export const OPTION_ALLOW_COMMAND_BAR = "allowCommandBar";
