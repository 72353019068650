import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Come è bello, come dà gioia.",
  url_video: "https://www.youtube.com/embed/t9EG-Ni6Yxc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Re-    La-     Mi",
          text: "COME È BELLO, COME DÀ GIOIA",
        },
        {
          notes: "La-      Re-   La- Mi   La-      (Sol)",
          text: "CHE I FRATELLI STIANO INSIEME!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Sol           Fa       Sol",
          text: "È come unguento che dal capo discenda",
        },
        {
          notes: "Fa                   Mi",
          text: "giù sulla barba di Aronne.*",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Re-    La-     Mi",
          text: "COME È BELLO, COME DÀ GIOIA",
        },
        {
          notes: "La-      Re-   La- Mi   La-      (Sol)",
          text: "CHE I FRATELLI STIANO INSIEME!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Sol           Fa       Sol",
          text: "È come unguento che dal capo discenda",
        },
        {
          notes: "Fa                   Mi",
          text: "giù sugli orli del manto.*",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Re-    La-     Mi",
          text: "COME È BELLO, COME DÀ GIOIA",
        },
        {
          notes: "La-      Re-   La- Mi   La-      (Sol)",
          text: "CHE I FRATELLI STIANO INSIEME!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa        Sol           Fa       Sol",
          text: "Come rugiada che dall'Ermon discenda",
        },
        {
          notes: "Fa                   Mi",
          text: "giù sui monti di Sion.*",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Re-    La-     Mi",
          text: "COME È BELLO, COME DÀ GIOIA",
        },
        {
          notes: "La-      Re-   La- Mi   La-      (Sol)",
          text: "CHE I FRATELLI STIANO INSIEME!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa      Sol         Fa      Sol",
          text: "Ci benedice il Signore dall'alto",
        },
        {
          notes: "Fa                 Mi",
          text: "la vita ci dona in eterno.*",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-     Re-    La-     Mi",
          text: "COME È BELLO, COME DÀ GIOIA",
        },
        {
          notes: "La-      Re-   La- Mi   La-      (Sol)",
          text: "CHE I FRATELLI STIANO INSIEME!",
        },
      ],
    },
    {
      speaker: " L'assemblea ripete la strofa.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "Sal 132(133)",
};
