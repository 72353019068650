import { useEffect, useRef, useState } from "react";
import ChordGraph from "../ChordGraph/ChordGraph";
import "./NoteInteractiveLabel.scss";

export interface NoteInteractiveLabelProps {
  chord: string;
  toneShift?: number;
}

const targetNotes = [
  "Do",
  "Do#",
  "Re",
  "Re#",
  "Mi",
  "Fa",
  "Fa#",
  "Sol",
  "Sol#",
  "La",
  "La#",
  "Si",
];

export const cleanVisibleNoteLabel = (note: string) => {
  if (note.lastIndexOf(".") !== -1) {
    let noteSpl: string[] = note.split(".");
    note = noteSpl[0];

    if (noteSpl.length > 1) {
      for (let i = 0; i < +noteSpl[1]; i++) {
        note += "*";
      }
    }
  }

  return note;
};

const NoteInteractiveLabel = (props: NoteInteractiveLabelProps) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [chord, setChord] = useState<string>("");
  const [shiftUp, setShiftUp] = useState<boolean>(false);

  const ref = useRef<any>();
  const applyToneShift = (chord: string, toneShift: number) => {
    let inParenthesis: boolean = false;
    if (chord.indexOf("(") !== -1 && chord.indexOf(")") !== -1) {
      inParenthesis = true;
      chord = chord.replaceAll("(", "").replaceAll(")", "");
    }

    let targetIndex: number = 0;
    let noteDecoration: string = "";
    for (let i = targetNotes.length - 1; i >= 0; i--) {
      if (chord.indexOf(targetNotes[i]) !== -1) {
        noteDecoration = chord.replaceAll(targetNotes[i], "");
        targetIndex = i;
        break;
      }
    }

    let shiftIndex: number = targetIndex + toneShift;
    while (shiftIndex < 0) {
      shiftIndex += targetNotes.length;
    }

    let finalNote =
      targetNotes[shiftIndex % targetNotes.length] + noteDecoration;
    if (inParenthesis) {
      finalNote = "(" + finalNote + ")";
    }

    // glitch fixes
    finalNote = finalNote.replaceAll("#b", "").replaceAll("b#", "");

    return finalNote;
  };

  useEffect(() => {
    setChord(applyToneShift(props.chord, props.toneShift ?? 0));
  }, [props]);

  const disableNotes = () => {
    setEnabled(false);
  };

  useEffect(() => {
    window.document.addEventListener("disable-all-notes", disableNotes);
    return () => {
      window.document.removeEventListener("disable-all-notes", disableNotes);
    };
  }, []);

  return (
    <div className="note-iteractive-label-wrap" ref={ref}>
      <div
        onClick={() => {
          if (enabled) {
            setEnabled(false);
          } else {
            if (ref.current) {
              setShiftUp(
                ref.current.getBoundingClientRect().bottom >
                  window.innerHeight * 0.67
              );
            }

            window.document.dispatchEvent(new Event("disable-all-notes"));
            setTimeout(() => {
              setEnabled(true);
            }, 100);
          }
        }}
        className="note-iteractive-label-text"
      >
        {cleanVisibleNoteLabel(chord)}
      </div>
      {enabled && (
        <div
          className="note-iteractive-label-graph-wrap-shifter"
          style={shiftUp ? { marginTop: "-250px" } : {}}
        >
          <div
            className="note-iteractive-label-graph-wrap"
            onClick={() => {
              setEnabled(false);
            }}
          >
            <ChordGraph chord={chord} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NoteInteractiveLabel;
