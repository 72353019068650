import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "A te Signore innalzo la mia anima.",
  url_video: "https://www.youtube.com/embed/T_0QfzhGXXM",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                      Mi ",
          text: "A TE, SIGNOR, INNALZO LA MIA ANIMA,",
        },
        {
          notes: "      Re- Fa Mi ",
          text: "A TE, O MIO DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-               Mi ",
          text: "In te confido, Signore,",
        },
        {
          notes: "                       Re- Fa   Mi ",
          text: "non trionfino su di me i miei nemici.",
        },
        {
          notes: "La-                            Mi ",
          text: "Mostrami, Signore, il tuo cammino,",
        },
        {
          notes: "                   Re- Fa   Mi ",
          text: "insegnami, Signor, il tuo sentiero.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                      Mi ",
          text: "A TE, SIGNOR, INNALZO LA MIA ANIMA,",
        },
        {
          notes: "      Re- Fa Mi ",
          text: "A TE, O MIO DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-                          Mi ",
          text: "Ricordati, Signore, del tuo amore,",
        },
        {
          notes: "                      Re- Fa  Mi ",
          text: "della tua compassione che è eterna.",
        },
        {
          notes: "La-                                 Mi ",
          text: "Dei miei peccati, Signore, non ti ricordare,",
        },
        {
          notes: "                    Re- Fa        Mi ",
          text: "ricordati, Signor, che sei misericordia.",
        },
        {
          notes: "Fa                            Mi ",
          text: "Donami, Signore, la tua misericordia,",
        },
        {
          notes: "Fa                        Mi ",
          text: "immergimi nella tua misericordia.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                      Mi ",
          text: "A TE, SIGNOR, INNALZO LA MIA ANIMA,",
        },
        {
          notes: "      Re- Fa Mi ",
          text: "A TE, O MIO DIO. ",
        },
      ],
    },
  ],
  subtitle: "Sal 24(25)",
};
