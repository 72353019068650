import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Signore, il mio cuore non ha più pretese.",
  url_video: "https://www.youtube.com/embed/TGkOytyQmmk",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           La-                 Mi-",
          text: "Signore il mio cuore non ha più pretese,",
        },
        {
          notes: "                La-              Si7",
          text: "né si leva con superbia il mio sguardo,",
        },
        {
          notes: "",
          text: "non vado in cerca di cose grandi",
        },
        {
          notes: "                             Mi-",
          text: "che sono superiori alle mie forze.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           La-                 Mi-",
          text: "SIGNORE, IL MIO CUORE NON HA PIÙ PRETESE,",
        },
        {
          notes: "                La-              Si7",
          text: "NÉ SI LEVA CON SUPERBIA IL MIO SGUARDO,",
        },
        {
          notes: "",
          text: "NON VADO IN CERCA DI COSE GRANDI.",
        },
        {
          notes: "                               Mi-",
          text: "CHE SONO SUPERIORI ALLE MIE FORZE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                            La-",
          text: "Ma placato e acquietato la mia anima",
        },
        {
          notes: "                             Mi-",
          text: "come un bimbo in braccio a sua madre,",
        },
        {
          notes: "                                   Si7",
          text: "come un bimbo svezzato è il mio spirito,",
        },
        {
          notes: "                      Mi-",
          text: "come un bimbo dentro di me.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           La-                 Mi-",
          text: "SIGNORE, IL MIO CUORE NON HA PIÙ PRETESE,",
        },
        {
          notes: "                La-              Si7",
          text: "NÉ SI LEVA CON SUPERBIA IL MIO SGUARDO,",
        },
        {
          notes: "",
          text: "NON VADO IN CERCA DI COSE GRANDI.",
        },
        {
          notes: "                               Mi-",
          text: "CHE SONO SUPERIORI ALLE MIE FORZE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-            Mi-",
          text: "Speri Israele nel Signore, ora e sempre,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-            Mi-",
          text: "SPERI ISRAELE NEL SIGNORE, ORA E SEMPRE.",
        },
      ],
    },
  ],
  subtitle: "Sal 130(131) ",
};
