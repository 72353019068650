import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Carmen '63.",
  url_video: "https://www.youtube.com/embed/ApQpLblARjA",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-  Mi              Fa",
          text: "Sono rotti i miei legami,",
        },
        {
          notes: "   Re-        Mi",
          text: "pagati i miei debiti.",
        },
        {
          notes: "       Fa",
          text: "Le mie porte spalancate,",
        },
        {
          notes: "                   Mi",
          text: "me ne vado da ogni parte.",
        },
        {
          notes: "Re-                         Mi",
          text: "Essi, accovacciati nel loro angolo,",
        },
        {
          notes: "Re-                                             Mi",
          text: "continuano a tessere la pallida tela delle loro ore;",
        },
        {
          notes: "Fa",
          text: "o tornano a sedersi nella polvere",
        },
        {
          notes: "                    Mi",
          text: "a contare le loro monete,",
        },
        {
          notes: "        Fa             Mi     Fa               Mi",
          text: "e mi chiamano, e mi chiamano, perché torni indietro.",
        },
        {
          notes: "La-                      Mi-",
          text: "Ma già la mia spada è forgiata,",
        },
        {
          notes: "La-                 Mi-",
          text: "già ho messo l'armatura,",
        },
        {
          notes: "Sol                        Si",
          text: "già il mio cavallo è impaziente",
        },
        {
          notes: "Re   La                Fa#",
          text: "e io guadagnerò il mio Regno,",
        },
        {
          notes: "Si-      Sol            Fa#",
          text: "e io guadagnerò il mio Regno.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re                La",
          text: "GUADAGNERÒ IL MIO REGNO,",
        },
        {
          notes: "Re                La",
          text: "GUADAGNERÒ IL MIO REGNO,",
        },
        {
          notes: "      Mi           La",
          text: "ME NE VADO DA OGNI PARTE",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-  Mi              Fa ",
          text: "SONO ROTTI I MIEI LEGAMI,",
        },
        {
          notes: "  Re-         Mi ",
          text: "PAGATI I MIEI DEBITI,",
        },
        {
          notes: "       Fa ",
          text: "LE MIE PORTE SPALANCATE,",
        },
        {
          notes: "                   Mi ",
          text: "ME NE VADO DA OGNI PARTE.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re                La",
          text: "GUADAGNERÒ IL MIO REGNO,",
        },
        {
          notes: "Re                La",
          text: "GUADAGNERÒ IL MIO REGNO,",
        },
        {
          notes: "      Mi           La",
          text: "ME NE VADO DA OGNI PARTE",
        },
      ],
    },
  ],
  subtitle: "(da Tagore)",
};
