import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ave Maria (1984).",
  url_video: "https://www.youtube.com/embed/o_R4mzGs9EQ",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do Mi Fa   Mi",
          text: "Ave     Maria",
        },
        {
          notes: "Do Mi Fa      Mi",
          text: "piena  di grazia",
        },
        {
          notes: "     Fa          Mi",
          text: "il Signore è con te,",
        },
        {
          notes: "    Fa                Mi",
          text: "Benedetta sei fra le donne",
        },
        {
          notes: "     Fa                                Mi",
          text: "e benedetto è il frutto del seno tuo, Gesù!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do Mi Fa   Mi",
          text: "SANTA   MARIA",
        },
        {
          notes: "Do Mi Fa    Mi",
          text: "MADRE   DI DIO,",
        },
        {
          notes: "  Fa      Mi",
          text: "PREGA PER NOI,",
        },
        {
          notes: "    Fa        Mi",
          text: "CHE SIAMO PECCATORI,",
        },
        {
          notes: "        Fa                           Mi Fa",
          text: "PREGA ADESSO E NELL'ORA DELLA NOSTRA MORTE!",
        },
        {
          notes: " Mi",
          text: "AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
