import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Quando israele era un bimbo.",
  url_video: "https://www.youtube.com/embed/NQ81OTmFwjw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-     Sol-         Re-",
          text: "Quando Israele era un bimbo io l'amai",
        },
        {
          notes: "        Sol-           Re-",
          text: "e dall'Egitto come un figlio lo chiamai,",
        },
        {
          notes: "             Sib                         Re-",
          text: "ma più lo chiamavo più si allontanava da me.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa",
          text: "E NON CAPIVA",
        },
        {
          notes: "           Do     Re-",
          text: "CHE AVEVO CURA DI LUI:",
        },
        {
          notes: "       Do  Re-",
          text: "ISRAEL, ISRAEL",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-     Sol-         Re-",
          text: "Ad Efraim io insegnavo a camminare",
        },
        {
          notes: "        Sol-     Re-",
          text: "li conducevo con legnami di bontà",
        },
        {
          notes: "        Sib                               Re-",
          text: "ero per loro come chi porta un bimbo alle guance.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa",
          text: "E NON CAPIVA",
        },
        {
          notes: "           Do     Re-",
          text: "CHE AVEVO CURA DI LUI:",
        },
        {
          notes: "       Do  Re-",
          text: "ISRAEL, ISRAEL",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-       Sol-           Re-",
          text: "Per questo tu tornerai nel paese d'Egitto",
        },
        {
          notes: "        Sol-                   Re-",
          text: "il re d'Assur sterminerà i tuoi figli",
        },
        {
          notes: "              Sib                   Re-",
          text: "perché il mio popolo è duro a convertirsi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa",
          text: "E NON CAPIVA",
        },
        {
          notes: "           Do     Re-",
          text: "CHE AVEVO CURA DI LUI:",
        },
        {
          notes: "       Do  Re-",
          text: "ISRAEL, ISRAEL",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-          Sol-                 Re-",
          text: "Ma come potrei abbandonarti o Efraim?",
        },
        {
          notes: "        Sol-                     Re-",
          text: "Il mio cuore si commuove dentro di me",
        },
        {
          notes: "             Sib                               Re-",
          text: "non ti distruggerò perché sono un Dio e non un uomo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa",
          text: "E NON CAPIVA",
        },
        {
          notes: "           Do     Re-",
          text: "CHE AVEVO CURA DI LUI:",
        },
        {
          notes: "       Do  Re-",
          text: "ISRAEL, ISRAEL",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-       Sol-                           Re-",
          text: "Io ruggirò e torneranno i suoi figli dal mare",
        },
        {
          notes: "        Sol-                  Re-",
          text: "accorreranno dall'Egitto come uccelli",
        },
        {
          notes: "       Sib                     Re-",
          text: "ritorneranno dall'Assiria come colombe.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa",
          text: "E NON CAPIVA",
        },
        {
          notes: "           Do     Re-",
          text: "CHE AVEVO CURA DI LUI:",
        },
        {
          notes: "       Do  Re-",
          text: "ISRAEL, ISRAEL",
        },
      ],
    },
  ],
  subtitle: "(Os 11, 1-11)",
};
