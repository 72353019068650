import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Venite a me voi tutti.",
  url_video: "https://www.youtube.com/embed/Dcq8YpglPSQ",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Venite a me, voi tutti",
        },
        {
          notes: "    Mi                    La-",
          text: "che siete affaticati ed oppressi,",
        },
        {
          notes: "   Fa           Mi",
          text: "ed io vi ristorerò.",
          msg: "  (2 v.)",
        },
        {
          notes: "  Do",
          text: "Prendete il mio giogo sopra di voi",
        },
        {
          notes: "              Mi",
          text: "e imparate da me",
        },
        {
          notes: "                          La-",
          text: "che sono mite ed umile di cuore,",
        },
        {
          notes: "   Fa                   Mi",
          text: "e troverete ristoro per voi,",
        },
        {
          notes: "   Fa                           Mi",
          text: "troverete ristoro per le vostre anime.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Do                   Mi",
          text: "PERCHÉ IL MIO GIOGO È SOAVE",
        },
        {
          notes: "                        Fa",
          text: "PERCHÉ IL MIO GIOGO È SOAVE",
        },
        {
          notes: "                   Mi",
          text: "E IL MIO CARICO LEGGERO.",
        },
        {
          notes: "                        Fa",
          text: "PERCHÉ IL MIO GIOGO È SOAVE",
        },
        {
          notes: "                   Mi",
          text: "E IL MIO CARICO LEGGERO.",
        },
      ],
    },
  ],
  subtitle: "(Mt 11, 28-30)",
};
