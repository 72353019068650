import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Consolate il mio popolo.",
  url_video: "https://www.youtube.com/embed/i-CDlHOLZt8",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Consolate il mio popolo",
        },
        {
          notes: "      La-                   Si7",
          text: "e parlate al cuore di Gerusalemme",
        },
        {
          notes: "",
          text: "e gridate che è finita",
        },
        {
          notes: "            Mi-",
          text: "la sua schiavitù.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "CONSOLATE IL MIO POPOLO",
        },
        {
          notes: "      La-                   Si7",
          text: "E PARLATE AL CUORE DI GERUSALEMME",
        },
        {
          notes: "",
          text: "E GRIDATE CHE È FINITA",
        },
        {
          notes: "            Mi-",
          text: "LA SUA SCHIAVITÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                       La Si7",
          text: "Ohè, una voce nel deserto grida:",
        },
        {
          notes: "    La-                  Si7      Mi-",
          text: "preparate il cammino, la via al Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            La-             Mi-",
          text: "PERCHÉ IL SIGNORE VIENE CON POTENZA",
        },
        {
          notes: "           Si7",
          text: "EGLI HA CON SÉ IL PREMIO.",
        },
        {
          notes: "          La-              Mi-",
          text: "COME UN PASTORE RADUNA IL GREGGE",
        },
        {
          notes: "              Si7       Mi-",
          text: "PORTA GLI AGNELLINI SUL PETTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         La-                Mi-",
          text: "Come un pastore porta piano piano",
        },
        {
          notes: "    Si7",
          text: "le pecore madri.",
        },
        {
          notes: "          La-",
          text: "Come un pastore porta sulla spalle",
        },
        {
          notes: "           Si7",
          text: "la pecora perduta.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "CONSOLATE IL MIO POPOLO",
        },
        {
          notes: "      La-                   Si7",
          text: "E PARLATE AL CUORE DI GERUSALEMME",
        },
        {
          notes: "",
          text: "E GRIDATE CHE È FINITA",
        },
        {
          notes: "            Mi-",
          text: "LA SUA SCHIAVITÙ.",
        },
      ],
    },
  ],
  subtitle: "(Is 40, 1-11)",
};
