import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import "./StandardButton.scss";

export interface StandardButtonProps {
  label: string;
  onClick: () => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  iconButtonIcon?: JSX.Element;
  disabled?: boolean;
  extraClassName?: string;
  formButton?: boolean;
}

const StandardButton = (props: StandardButtonProps) => {
  return (
    <div
      style={props.disabled === true ? { opacity: "0.5" } : {}}
      className={
        (props.extraClassName ?? "") +
        " material-button-style-enforcer base_border_color dark_text_color button-background"
      }
    >
      {props.iconButtonIcon ? (
        <div className="material-button-style-enforcer-icon-button">
          <IconButton
            type={props.formButton === true ? "submit" : "button"}
            tabIndex={10}
            onClick={props.onClick}
            disabled={props.disabled === true}
            aria-label={props.label}
          >
            {props.iconButtonIcon}
          </IconButton>
        </div>
      ) : (
        <Button
          style={{ minWidth: "15em" }}
          type={props.formButton === true ? "submit" : "button"}
          tabIndex={10}
          disabled={props.disabled === true}
          onClick={props.onClick}
          variant="outlined"
          startIcon={props.startIcon}
          endIcon={props.endIcon}
        >
          {props.label}
        </Button>
      )}
    </div>
  );
};

export default StandardButton;
