import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno “akathistos“.",
  url_video: "",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa                                                 Mi",
          text: "lampada splendente apparsa a coloro che sono nelle tenebre.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa",
          text: "Salve lampada splendente.",
        },
        {
          notes: "Sol       La-",
          text: "Salve ... Salve",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve serto della castità ",
        },
        {
          notes: "Fa",
          text: "Icona splendente della Risurrezione",
        },
        {
          notes: "Sol                          Fa",
          text: "Rivelatrice della vita degli angeli",
        },
        {
          notes: "Fa                           Mi",
          text: "Albero sotto cui molti si riparano.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa",
          text: "Salve lampada splendente.",
        },
        {
          notes: "Sol       La-",
          text: "Salve ... Salve",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Tu che in seno portasti la Guida degli erranti",
        },
        {
          notes: "Fa",
          text: "Tu che generasti il Re#entore degli schiavi",
        },
        {
          notes: "Sol                       Fa",
          text: "Perdono di tutti i peccatori",
        },
        {
          notes: "Fa                           Mi",
          text: "Stola che rivesti coloro che son privi di fiducia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa",
          text: "Salve lampada splendente.",
        },
        {
          notes: "Sol       La-",
          text: "Salve ... Salve",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve Raggio del Sole spirituale",
        },
        {
          notes: "Fa",
          text: "Dardo della Luce che mai tramonta",
        },
        {
          notes: "Sol                     Fa",
          text: "Baleno che rischiari le anime ",
        },
        {
          notes: "Fa                      Mi",
          text: "Folgore che atterisci i nemici.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa",
          text: "Salve lampada splendente.",
        },
        {
          notes: "Sol       La-",
          text: "Salve ... Salve",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Tu che fai sorgere l'Astro sfolgorante,",
        },
        {
          notes: "Fa",
          text: "Tu che fai sgorgare il Fiume ricco d'acque,",
        },
        {
          notes: "Sol                           Fa",
          text: "Tu che riproduci la simbolica piscina,",
        },
        {
          notes: "Fa                          Mi",
          text: "Tu che togli le macchie del peccato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Salve, Vergine Santa,",
        },
        {
          notes: "Fa",
          text: "Salve lampada splendente.",
        },
        {
          notes: "Sol       La-",
          text: "Salve ... Salve",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Coppa che mesci l'esultanza,",
        },
        {
          notes: "Fa",
          text: "Profumo della fragranza di Cristo,",
        },
        {
          notes: "Sol              Fa",
          text: "Vita del mistico Banchetto,",
        },
        {
          notes: "Fa                 Mi",
          text: "Salve Sposa sempre Vergine",
        },
      ],
    },
  ],
  subtitle: "",
};
