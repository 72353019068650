import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo santo santo - osanna delle palme.",
  url_video: "https://www.youtube.com/embed/UQKfLEIlPTo",
  barrè: "III",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "SANTO, SANTO, SANTO",
        },
        {
          notes: "Sol-",
          text: "SANTO, SANTO, SANTO",
        },
        {
          notes: "La",
          text: "SANTO, SANTO, SANTO!",
        },
        {
          notes: "Sol-        La",
          text: "SANTO È IL SIGNOR",
        },
        {
          notes: "Sol-    La",
          text: "JAHVÈ SABAOTH",
        },
        {
          notes: "Sol-      La",
          text: "JAHVÈ SABAOTH.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-  Sol-      Re-",
          text: "I cieli e la terra",
        },
        {
          notes: "     Sol-",
          text: "sono pieni",
        },
        {
          notes: "              La",
          text: "sono pieni di te!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re-",
          text: "OSANNA, OSANNA",
        },
        {
          notes: "La7",
          text: "OSANNA, OSANNA",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol-",
          text: "OSANNA NELL'ALTO DEI CIELI",
        },
        {
          notes: "          La",
          text: "OSANNA, OSANNA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re- Sol-",
          text: "Benedetto",
        },
        {
          notes: "             Re-",
          text: "è colui che viene",
        },
        {
          notes: "            Sol-",
          text: "è colui che viene",
        },
        {
          notes: "               La",
          text: "nel nome del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "Re-",
          text: "OSANNA, OSANNA",
        },
        {
          notes: "La7",
          text: "OSANNA, OSANNA",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol-",
          text: "OSANNA NELL'ALTO DEI CIELI",
        },
        {
          notes: "          La",
          text: "OSANNA, OSANNA!",
        },
      ],
    },
  ],
  subtitle: "(e Tempo Pasquale)",
};
