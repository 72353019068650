import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Tanto_pe_cantà.",
  title: "Tanto pe cantà.",
  url_video: "https://www.youtube.com/embed/dHQ3_-dfNwM",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re- La- Re- Mi7 La-",
          text: "",
        },
      ],
    },
    {
      speaker:
        "E' una canzone senza titolo tanto pe' cantà pe' fa quarche cosa... non è gnente de straordinario robba der paese nostro che se po cantà pure senza voce basta 'a salute quanno c'è 'a salute c'è tutto basta 'a salute e un par de scarpe nove poi girà tutto er monno e m' 'a accompagno da me...",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re- La- Re- Mi7 La-",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Re-",
          text: "Pe' fà la vita meno amara",
        },
        {
          notes: "           Mi7          La-",
          text: "me so' comprata 'sta chitara",
        },
        {
          notes: "                         Re-",
          text: "e quanno er solo scenne e more",
        },
        {
          notes: "            Mi7       La-",
          text: "me sento 'n core cantatore.",
        },
        {
          notes: "                      Re-",
          text: "La voce è poca ma intonata,",
        },
        {
          notes: "            Mi7         La-",
          text: "nun serve a fa' 'na serenata",
        },
        {
          notes: "                       Re-",
          text: "ma solamente a fà 'n maniera",
        },
        {
          notes: "                           Mi7",
          text: "de famme 'n sogno a prima sera…",
        },
        {
          notes: " La",
          text: "Tanto pe' cantà",
        },
        {
          notes: "                                Mi7",
          text: "perchè me sento un friccico ner core",
        },
        {
          notes: "",
          text: "tanto pe' sognà",
        },
        {
          notes: "                                 La",
          text: "perché ner petto me ce nasca un fiore",
        },
        {
          notes: "",
          text: "fiore de lillà",
        },
        {
          notes: "                        La7     Re",
          text: "che m'ariporti verso er primo amore",
        },
        {
          notes: "                          La",
          text: "che sospirava le canzoni mie",
        },
        {
          notes: "        Mi7            La",
          text: "e m'arintontoniva de bugie.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re-  La-  Re-   Mi7   La-",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Re-",
          text: "Canzoni belle e appassionate",
        },
        {
          notes: "         Mi7         La-",
          text: "che Roma mia m'aricordate,",
        },
        {
          notes: "                    Re-",
          text: "cantate solo pe' dispetto",
        },
        {
          notes: "           Mi7              La-",
          text: "ma co' 'na smania dentro ar petto,",
        },
        {
          notes: "                       Re-",
          text: "io nun ve canto a voce piena",
        },
        {
          notes: "           Mi7      La-",
          text: "ma tutta l'anima è serena",
        },
        {
          notes: "                        Re-",
          text: "e quanno er cielo se scolora",
        },
        {
          notes: "                     Mi7",
          text: "de me nessuno se 'nnamora…",
        },
        {
          notes: " La",
          text: "Tanto pe' cantà",
        },
        {
          notes: "                                Mi7",
          text: "perchè me sento un friccico ner core",
        },
        {
          notes: "",
          text: "tanto pe' sognà",
        },
        {
          notes: "                                 La",
          text: "perché ner petto me ce nasca un fiore",
        },
        {
          notes: "",
          text: "fiore de lillà",
        },
        {
          notes: "                         La7     Re",
          text: "che m'ariporti verso er primo amore",
        },
        {
          notes: "                         La",
          text: "che sospirava le canzoni mie",
        },
        {
          notes: "        Mi7             La",
          text: "e m'arintontoniva de bugie.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re-   La-  Re-  Mi7  La-",
          text: "",
        },
      ],
    },
  ],
  subtitle: "Nino Manfredi",
  sent_for_revision: false,
};
