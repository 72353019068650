import { useState } from "react";
import "./AstWithPopup.scss";

export interface AstWithPopupProps {
  extraClass?: string;
}

const AstWithPopup = (props: AstWithPopupProps) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const showPopup = () => {
    setText(document.getElementById("ast-message-text")?.innerHTML ?? "");
    setEnabled(true);
    setTimeout(() => {
      try {
        setEnabled(false);
      } catch (e) {}
    }, 5000);
  };

  return (
    <div className="ast-with-popup-wrap">
      <div
        className={"ast-with-popup-ast " + (props.extraClass ?? "")}
        onClick={showPopup}
        onMouseEnter={showPopup}
      >
        {"* "}
      </div>
      {enabled && <div className="ast-with-popup-popup box-shadow">{text}</div>}
    </div>
  );
};

export default AstWithPopup;
