import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lodate iddio.",
  url_video: "https://www.youtube.com/embed/J-RaTSyNzx4",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi   La   Re  La   Re  La  Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "La   Re   La  Mi   Re      Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi       La      Re         La",
          text: "Lodate Iddio nel tempio suo santo",
        },
        {
          notes: "   Re       La          Mi",
          text: "lodatelo nell'alto firmamento,",
        },
        {
          notes: "   La          Re    La    Mi",
          text: "lodatelo nei grandi suoi portenti",
        },
        {
          notes: "Re                     Mi",
          text: "lodatene l'eccelsa maestà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi   La   Re  La   Re  La  Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "La   Re   La  Mi   Re      Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi       La      Re         La",
          text: "Lodatelo al clangore delle trombe",
        },
        {
          notes: "   Re       La          Mi",
          text: "lodatelo sull'arpe e le chitarre,",
        },
        {
          notes: "   La          Re    La    Mi",
          text: "lodatelo coi timpani e coi sistri,",
        },
        {
          notes: "Re                     Mi",
          text: "lodatelo sui flauti e sulle corde.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi   La   Re  La   Re  La  Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "La   Re   La  Mi   Re      Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi    La      Re       La",
          text: "Lodatelo sui cembali sonori",
        },
        {
          notes: "   Re       La          Mi",
          text: "lodatelo sui cembali squillanti:",
        },
        {
          notes: "   La          Re    La    Mi",
          text: "ogni vivente celebri il Signore.",
        },
        {
          notes: "Re               Mi",
          text: "Lodate, lodate Iddio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi   La   Re  La   Re  La  Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "La   Re   La  Mi   Re      Mi",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "Sal 150",
};
