import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno dei vespri di pasqua (dall'ascensione a pentecoste).",
  url_video: "https://www.youtube.com/embed/ndcrFCvia54",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "VIENI O SPIRITO CREATORE",
        },
        {
          notes: "                  La-",
          text: "VISITA LE NOSTRE MENTI,",
        },
        {
          notes: "                Sol",
          text: "RIEMPI DEL TUO AMORE",
        },
        {
          notes: "                  La-",
          text: "I CUORI CHE HAI CREATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-            Sol",
          text: "O dolce consolatore",
        },
        {
          notes: "                  La-",
          text: "dono del Padre altissimo,",
        },
        {
          notes: "                Sol",
          text: "acqua viva, fuoco, amore,",
        },
        {
          notes: "                  La-",
          text: "santo crisma dell'anima.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "DITO DELLA MANO DI DIO",
        },
        {
          notes: "                  La-",
          text: "PROMESSO DAL SALVATORE,",
        },
        {
          notes: "                Sol",
          text: "IRRADIA I TUOI SETTE DONI,",
        },
        {
          notes: "                  La-",
          text: "SUSCITA IN NOI LA PAROLA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "Sii luce all'intelletto,",
        },
        {
          notes: "                  La-",
          text: "fiamma ardente nel cuore,",
        },
        {
          notes: "                Sol",
          text: "sana le nostre ferite",
        },
        {
          notes: "                  La-",
          text: "col balsamo del tuo amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "DIFENDICI DAL NEMICO",
        },
        {
          notes: "                  La-",
          text: "RECA IN DONO LA PACE,",
        },
        {
          notes: "                Sol",
          text: "LA TUA GUIDA INVINCIBILE",
        },
        {
          notes: "                  La-",
          text: "CI PRESERVI DAL MALE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "Luce d'eterna sapienza",
        },
        {
          notes: "                  La-",
          text: "svelaci il grande mistero",
        },
        {
          notes: "                Sol",
          text: "di Dio Padre e del Figlio",
        },
        {
          notes: "                  La-",
          text: "uniti in un solo amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol La-",
          text: "AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
