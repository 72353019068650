import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Osanna_eh!.",
  title: "Osanna eh!.",
  url_video: "https://www.youtube.com/embed/SKrdtCiM4Ms",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol    Do     Sol Re Sol",
          text: "Santo, santo osa-----nna  ",
        },
        {
          notes: "       Do     Sol Re Sol",
          text: "Santo, santo osa-----nna ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                  Do         Sol Re    Sol",
          text: "I cieli e la terra o Signore sono pie-ni di te",
        },
        {
          notes: "                       Do         Sol Re    Sol",
          text: "I cieli e la terra o Signore sono pie-ni di te.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "    Sol             Do        Sol  Re    Sol",
          text: "Benedetto colui che viene nel nome tuo Signor",
        },
        {
          notes: "                    Do        Sol  Re    Sol",
          text: "Benedetto colui che viene nel nome tuo Signor.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
        {
          notes: " Sol        Do    Sol",
          text: "Osanna eh! Osanna eh!",
        },
        {
          notes: " Do      Re       Sol",
          text: "Osanna a Cristo Signor!",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
