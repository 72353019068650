import "./Img.scss";

export interface IImgProps {
  src: string;
  alt: string;
  className?: string;
  style?: any;
  onClick?: () => void;
}

const Img = (props: IImgProps) => {
  const validateSrc = () => {
    if (props.src.startsWith("http")) {
      return props.src;
    }
    return window.location.origin + props.src.substring(1);
  };

  return (
    <img
      onClick={props.onClick}
      style={props.style}
      className={props.className}
      src={validateSrc()}
      alt={props.alt}
    />
  );
};

export default Img;
