import { useEffect, useState } from "react";
import {
  SongBlock,
  SongBlockLines,
  SongElement,
} from "../../Songs/SongElement";
import AstWithPopup from "../AstWithPopup/AstWithPopup";
import NoteInteractiveLabel from "../NoteInteractiveLabel/NoteInteractiveLabel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./SongRender.scss";
import SongBlockSpeeder from "../SongBlockSpeeder/SongBlockSpeeder";

const postProcessText = (text: string) => {
  let result: JSX.Element[] = [];
  let splText: string[] = text.split(" ");
  for (let i = 0; i < splText.length; i++) {
    if (splText[i] === "*") {
      result.push(
        <div style={{ display: "inline-block" }} key={i}>
          <AstWithPopup />
        </div>
      );
    } else {
      result.push(
        <div style={{ display: "inline-block" }} key={i}>
          {splText[i] + " "}
        </div>
      );
    }
  }

  return result;
};

const postProcessNotes = (text: string, toneShift: number) => {
  let result: JSX.Element[] = [];
  let splText: string[] = text.split("");
  let note = "";

  for (let i = 0; i < splText.length; i++) {
    let letter: string = splText[i];
    if (splText[i] === " ") {
      if (note !== "") {
        result.push(
          <div style={{ display: "inline-block" }} key={i * 1000}>
            <NoteInteractiveLabel chord={note} toneShift={toneShift} />
          </div>
        );
        note = "";
      }

      result.push(
        <div style={{ display: "inline-block" }} key={i}>
          {" "}
        </div>
      );
    } else {
      note += letter;
    }
  }

  if (note !== "") {
    result.push(
      <div style={{ display: "inline-block" }} key={text.length}>
        <NoteInteractiveLabel chord={note} toneShift={toneShift} />
      </div>
    );
    note = "";
  }

  return result;
};

const applyBlockMode = (block: SongBlock) => {
  let processedBlock: SongBlock = { ...block };

  /** extrapolate msg label from lines */
  let messages: string[] = processedBlock.lines
    .map((x: SongBlockLines) => {
      return x.msg ?? "";
    })
    .filter((x: string) => x !== "");

  processedBlock.processedData = {};
  processedBlock.processedData["msg"] =
    messages.length > 0 ? messages[0] : null;

  /** apply block modes */
  if (processedBlock.mode === "2bar") {
    processedBlock.processedData["msg"] = "(2 v.)";
  }
  if (processedBlock.mode === "bar-from-ast-message") {
    processedBlock.processedData["msg"] = (
      <AstWithPopup extraClass={"red-ast-popup-message"} />
    );
  }
  if (processedBlock.mode === "red-utility-message") {
    processedBlock.processedData["red_util_msg"] = processedBlock.speaker;
    processedBlock.lines = [];
  }
  if (processedBlock.mode === "utility-message") {
    processedBlock.processedData["util_msg"] = processedBlock.speaker;
    processedBlock.lines = [];
  }
  if (processedBlock.mode === "upper") {
    processedBlock.lines.forEach((x: SongBlockLines) => {
      x.text = x.text.toLocaleUpperCase();
    });
  }

  if (processedBlock.mode === "ast-message") {
    processedBlock.processedData["red_util_msg"] = (
      <div id="ast-message-text">{"* " + processedBlock.speaker}</div>
    );
  }

  if (processedBlock.mode === "assets-import-message") {
    processedBlock.processedData["assets-import-message"] =
      processedBlock.speaker + ".png";
  }

  return processedBlock;
};

export const renderBlock = (
  block: SongBlock,
  index: number,
  longerLine: number,
  toneShift: number,
  songId: string
) => {
  let processedBlock = applyBlockMode(block);

  const splitSpeaker = (speaker: string) => {
    if (speaker && speaker.length < 6) {
      let spl = speaker.split("+");
      if (spl.length === 2) {
        return (
          <div style={{ textAlign: "center" }}>
            <div>{spl[0]}</div>
            <div className="song-render-plus-align">+</div>
            <div>{spl[1]}</div>
          </div>
        );
      }
    }

    return speaker;
  };

  return (
    <div key={index} className="song-render-block-wrap-outer">
      {processedBlock.lines.length > 0 && (
        <div className="song-render-block-wrap">
          <div className="song-render-speaker-label">
            {splitSpeaker(processedBlock.speaker)}
            <SongBlockSpeeder songId={songId} index={index} />
          </div>
          <div className="song-render-lines-outer-wrap">
            {processedBlock.lines.length > 0 && (
              <div
                className="song-render-lines-wrap"
                style={{ width: (0.6 * longerLine).toString() + "em" }}
              >
                {processedBlock.lines.map((x: SongBlockLines, i: number) => {
                  return (
                    <div key={i} className="song-render-lines">
                      <div className="song-render-notes">
                        {postProcessNotes(x.notes, toneShift)}
                      </div>
                      <div className="song-render-text">
                        {postProcessText(x.text)}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {processedBlock.processedData.msg && (
            <div
              className="song-render-message"
              style={{
                height: (3 * processedBlock.lines.length).toString() + "em",
              }}
            >
              {processedBlock.processedData.msg}
            </div>
          )}
        </div>
      )}
      {processedBlock.processedData.util_msg && (
        <div className="song-util-message">
          {processedBlock.processedData.util_msg}
        </div>
      )}
      {processedBlock.processedData.red_util_msg && (
        <div className="song-red-util-message">
          {processedBlock.processedData.red_util_msg}
        </div>
      )}
      {processedBlock.processedData["assets-import-message"] && (
        <div>
          <img
            src={
              "./Assets/" +
              processedBlock.processedData["assets-import-message"]
            }
          />
        </div>
      )}
    </div>
  );
};

const getSongColorMeta = (color: string) => {
  if (color === "white") {
    return {
      label: "bianco",
      style: { color: "rgb(193, 191, 191)", backgroundColor: "white" },
    };
  }
  if (color === "lightgray") {
    return {
      label: "grigio",
      style: { color: "gray", backgroundColor: "rgba(221, 221, 221, 0.23)" },
    };
  }
  if (color === "sandybrown") {
    return {
      label: "marrone",
      style: {
        color: "rgb(144, 82, 29)",
        backgroundColor: "rgba(175, 89, 17, 0.22)",
      },
    };
  }
  if (color === "limegreen") {
    return {
      label: "verde",
      style: {
        color: "rgb(17, 142, 17)",
        backgroundColor: "rgba(111, 244, 111, 0.25)",
      },
    };
  }
  if (color === "cadetblue") {
    return {
      label: "blu",
      style: { color: "cadetblue", backgroundColor: "rgba(95, 157, 159, 0.2)" },
    };
  }
  return {};
};

export interface SongOptions {
  fontSize: number;
  toneShift: number;
}

export interface SongRenderProps {
  song: SongElement;
  options?: SongOptions;
}

const getLongestLine = (song: SongElement) => {
  let longerLine: number = 0;
  song.blocks.forEach((x: SongBlock, i: number) => {
    x.lines.forEach((y: SongBlockLines) => {
      if (y.text.length > longerLine) {
        longerLine = y.text.length;
      }
      if (y.notes.length > longerLine) {
        longerLine = y.notes.length;
      }
    });
  });
  return longerLine;
};

const SongRender = (props: SongRenderProps) => {
  const [song, setSong] = useState<SongElement | null>(null);
  const [delayLogic, setDelayLogic] = useState<boolean>(false);
  const [options, setSongOptions] = useState<SongOptions | null>(null);

  useEffect(() => {
    if (props.options) {
      setSongOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    setDelayLogic(true);
    setSong(props.song);
    setTimeout(() => {
      setDelayLogic(false);
    }, 300);
  }, [props.song]);

  const renderSong = (options: SongOptions) => {
    if (song == null) {
      return <div></div>;
    }

    let blocks: JSX.Element[] = [];
    let longerLine: number = getLongestLine(song);
    song.blocks.forEach((x: SongBlock, i: number) => {
      blocks.push(
        renderBlock(x, i, longerLine, options.toneShift, song.id ?? song.title)
      );
    });

    let colorMeta = getSongColorMeta(song.color);

    let layout = (
      <div>
        <div className="song-render-title">
          <div className="song-render-title-line">
            <ArrowBackIosIcon
              onClick={() => {
                window.history.back();
              }}
            />
            {song.title}
            <div className="song-render-subtitle">{song.subtitle}</div>
          </div>
        </div>
        {song.color !== "none" && (
          <div className="song-render-color-bar" style={colorMeta.style}>
            Canto di colore {colorMeta.label}
          </div>
        )}
        {song.altnotes && (
          <div className="song-render-altnotes">
            <div> Si può fare anche in {song.altnotes.split(";")[0]}</div>
            {song.altnotes.split(";").length > 1 && (
              <div>, Barrè al {song.altnotes.split(";")[1]}° tasto.</div>
            )}
          </div>
        )}
        {song.barrè !== "" && (
          <div className="song-render-barre">Barré al {song.barrè}° tasto</div>
        )}
        <div className="song-render-blocks-wrapper">{blocks}</div>
      </div>
    );

    return layout;
  };

  let activeOptions: SongOptions = options ?? {
    fontSize: 1,
    toneShift: 0,
  };

  return (
    <div
      className="song-render-main-wrap"
      style={{ fontSize: activeOptions.fontSize + "em" }}
    >
      {!delayLogic && renderSong(activeOptions)}
    </div>
  );
};

export default SongRender;
