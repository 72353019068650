import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Vi prendero’ dalle genti.",
  url_video: "https://www.youtube.com/embed/_TO99ay16YI",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Vi prenderò dalle genti",
        },
        {
          notes: "                    Re",
          text: "vi radunerò da ogni terra",
        },
        {
          notes: "    Mi7                 La-",
          text: "vi aspergerò con acqua pura",
        },
        {
          notes: "Fa          Mi-",
          text: "io vi purificherò.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "La-",
          text: "Vi prenderò dalle genti",
        },
        {
          notes: "                    Re",
          text: "vi radunerò da ogni terra",
        },
        {
          notes: "    Mi7                 La-",
          text: "vi aspergerò con acqua pura",
        },
        {
          notes: "Fa          Mi-",
          text: "io vi purificherò.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        La7",
          text: "Vi darò un cuore nuovo",
        },
        {
          notes: "                          Re-",
          text: "metterò dentro di voi uno spirito nuovo",
        },
        {
          notes: "   Mi7                      La-",
          text: "toglierò da voi il cuore di pietra",
        },
        {
          notes: " Fa                    Mi",
          text: "e vi darò un cuore di carne.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "  La-",
          text: "Vi prenderò dalle genti",
        },
        {
          notes: "",
          text: "vi radunerò da ogni terra",
        },
        {
          notes: " Mi7",
          text: "vi aspergerò con acqua pura",
        },
        {
          notes: "Fa             M",
          text: "io vi purificherò.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Re-",
          text: "Porrò il mio spirito dentro di voi",
        },
        {
          notes: "     Mi                           La",
          text: "e vi farò vivere secondo la mia parola",
        },
        {
          notes: "Fa",
          text: "voi sarete il mio popolo",
        },
        {
          notes: "                    Mi",
          text: "e io sarò il vostro Dio.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "   La-             La7",
          text: "Vi prenderò dalle genti",
        },
        {
          notes: "",
          text: "vi radunerò da ogni terra",
        },
        {
          notes: "Mi7",
          text: "vi aspergerò con acqua pura",
        },
        {
          notes: "Fa             M",
          text: "io vi purificherò",
        },
      ],
    },
  ],
  subtitle: "(ez. 36,24-28)",
};
