const DOTS_HIGHER_SIDE = [
  [0, 3, 1],
  [1, 1, 2],
  [1, 2, 3],
];
const DOTS_HIGHER_SIDE_MINOR = [
  [1, 1, 2],
  [1, 2, 3],
];
const DOTS_HIGHER_SIDE_7 = [
  [0, 3, 1],
  [1, 1, 2],
];

const DOTS_LOWER_SIDE = [
  [1, 2, 1],
  [1, 3, 2],
  [1, 4, 3],
];
const DOTS_LOWER_SIDE_MINOR = [
  [0, 4, 1],
  [1, 2, 2],
  [1, 3, 3],
];
const DOTS_LOWER_SIDE_7 = [
  [1, 2, 1],
  [1, 4, 3],
];

export interface NoteDictionaryItemValue {
  dots: number[][];
  barre: number;
}

export interface NoteDictionaryItemRappresentation {
  mode: string;
  values: NoteDictionaryItemValue[];
}

export interface NoteDictionaryItem {
  name: string;
  rappresentations: NoteDictionaryItemRappresentation[];
}

export const NOTES_DICTIONARY: NoteDictionaryItem[] = [
  {
    name: "Do",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 3,
          },
          {
            dots: [
              [0, 4, 1],
              [1, 2, 2],
              [2, 1, 3],
            ],
            barre: 0,
          },
          {
            dots: [
              [0, 4, 1],
              [1, 2, 2],
              [2, 0, 3],
              [2, 1, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 3,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [0, 4, 1],
              [1, 2, 2],
              [1, 3, 3],
              [2, 1, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 3,
          },
          {
            dots: [
              [0, 4, 1],
              [1, 2, 2],
              [2, 1, 3],
              [2, 3, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 4,
          },
        ],
      },
      {
        mode: "#-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 4,
          },
        ],
      },
      {
        mode: "#7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 4,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 2, 1],
              [0, 4, 2],
              [1, 3, 3],
              [1, 5, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#°",
        values: [
          {
            dots: [
              [0, 1, 1],
              [1, 2, 2],
              [1, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Re",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 5,
          },
          {
            dots: [
              [1, 3, 1],
              [1, 5, 2],
              [2, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 5,
          },
          {
            dots: [
              [0, 5, 1],
              [1, 3, 2],
              [2, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [1, 3, 1],
              [1, 5, 2],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 5,
          },
          {
            dots: [
              [0, 4, 1],
              [1, 3, 2],
              [1, 5, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 6,
          },
        ],
      },
      {
        mode: "#-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 6,
          },
        ],
      },
      {
        mode: "#7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 6,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 3, 1],
              [0, 5, 2],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#°",
        values: [
          {
            dots: [
              [0, 2, 1],
              [0, 4, 2],
              [1, 3, 3],
              [1, 5, 4],
            ],
            barre: 0,
          },
        ],
      },

      // Special: sorga Dio
      {
        mode: "-.1",
        values: [
          {
            dots: [
              [1, 3, 1],
              [2, 2, 2],
              [2, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Mi",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 0,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 0,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [0, 3, 1],
              [1, 1, 2],
              [1, 2, 3],
              [1, 4, 4],
            ],
            barre: 0,
          },
          {
            dots: [
              [1, 1, 1],
              [1, 2, 2],
              [1, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 0,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 1, 1],
              [1, 2, 2],
              [1, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
      // Special: maria madre della chiesa
      {
        mode: "7.1",
        values: [
          {
            dots: [
              [0, 4, 1],
              [1, 3, 2],
              [1, 5, 3],
            ],
            barre: -3,
          },
        ],
      },
    ],
  },
  {
    name: "Fa",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 1,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 1,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [0, 5, 1],
              [1, 3, 2],
              [2, 2, 3],
              [2, 4, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 1,
          },
        ],
      },
      {
        mode: "#",
        values: [
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 2,
          },
        ],
      },
      {
        mode: "#-",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 2,
          },
        ],
      },
      {
        mode: "#7",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 2,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 3, 1],
              [0, 5, 2],
              [1, 1, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#°",
        values: [
          {
            dots: [
              [0, 2, 1],
              [0, 4, 2],
              [1, 3, 3],
              [1, 5, 4],
            ],
            barre: 0,
          },
        ],
      },

      // Special: maria madre della chiesa
      {
        mode: ".1",
        values: [
          {
            dots: [
              [0, 4, 1],
              [1, 3, 2],
              [2, 2, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: ".2",
        values: [
          {
            dots: [
              [1, 3, 1],
              [2, 1, 2],
              [2, 2, 3],
            ],
            barre: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Sol",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 3,
          },
          {
            dots: [
              [1, 1, 1],
              [2, 0, 2],
              [2, 5, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 3,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [1, 1, 2],
              [2, 0, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 3,
          },
          {
            dots: [
              [0, 5, 1],
              [1, 1, 2],
              [2, 0, 3],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#",
        values: [
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 4,
          },
        ],
      },
      {
        mode: "#-",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 4,
          },
        ],
      },
      {
        mode: "#7",
        values: [
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 4,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [1, 2, 1],
              [1, 4, 2],
              [2, 3, 3],
              [2, 5, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#°",
        values: [
          {
            dots: [
              [0, 3, 1],
              [0, 5, 2],
              [1, 1, 3],
            ],
            barre: 0,
          },
        ],
      },

      // Special: maria madre della chiesa
      {
        mode: "7.1",
        values: [
          {
            dots: [
              [0, 4, 1],
              [1, 3, 2],
              [1, 5, 3],
            ],
            barre: -6,
          },
        ],
      },
    ],
  },
  {
    name: "La",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 0,
          },
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 5,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 0,
          },
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 5,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [1, 2, 1],
              [1, 3, 1],
              [1, 4, 1],
              [1, 5, 1],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 0,
          },
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 5,
          },
        ],
      },
      {
        mode: "#",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 1,
          },
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 6,
          },
        ],
      },
      {
        mode: "#-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 1,
          },
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 6,
          },
        ],
      },
      {
        mode: "#7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 1,
          },
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 6,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 2, 1],
              [0, 4, 2],
              [1, 3, 3],
              [1, 5, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "#°",
        values: [
          {
            dots: [
              [0, 1, 1],
              [1, 2, 2],
              [1, 4, 3],
            ],
            barre: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Si",
    rappresentations: [
      {
        mode: "",
        values: [
          {
            dots: DOTS_LOWER_SIDE,
            barre: 2,
          },
          {
            dots: DOTS_HIGHER_SIDE,
            barre: 7,
          },
        ],
      },
      {
        mode: "-",
        values: [
          {
            dots: DOTS_LOWER_SIDE_MINOR,
            barre: 2,
          },
          {
            dots: DOTS_HIGHER_SIDE_MINOR,
            barre: 7,
          },
        ],
      },
      {
        mode: "6",
        values: [
          {
            dots: [
              [0, 2, 1],
              [0, 3, 2],
              [1, 1, 3],
              [1, 4, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "7",
        values: [
          {
            dots: DOTS_LOWER_SIDE_7,
            barre: 2,
          },
          {
            dots: DOTS_HIGHER_SIDE_7,
            barre: 7,
          },
          {
            dots: [
              [0, 2, 1],
              [1, 1, 2],
              [1, 3, 3],
              [1, 5, 4],
            ],
            barre: 0,
          },
        ],
      },
      {
        mode: "°",
        values: [
          {
            dots: [
              [0, 3, 1],
              [0, 5, 2],
              [1, 1, 3],
            ],
            barre: 0,
          },
        ],
      },
    ],
  },
];

const convert_equivalent_notes = (note: string) => {
  //real note_list = ["DO", "DO#", "Reb", "RE", "RE#", "Mib", "MI", "FA", "FA#", "Solb", "SOL", "SOL#", "Lab", "LA", "LA#", "Sib", "SI"];
  // bemolle are substituted with previous diesis
  note = note.replace(/Reb/gi, "Do#");
  note = note.replace(/Mib/gi, "Re#");
  note = note.replace(/Solb/gi, "Fa#");
  note = note.replace(/Lab/gi, "Sol#");
  note = note.replace(/Sib/gi, "La#");

  return note;
};

/*

	La successione della scala discendente è: DO, SI, SIb, LA, LAb, SOL, SOLb, FA, MI, MIb, RE, REb, DO
	Da cui le note bemolle sono: Sib, Lab, Solb, Mib, Reb

	La successione della scala ascendente è: DO, DO#, RE, RE#, MI, FA, FA#, SOL, SOL#, LA, LA#, SI, DO
	Da cui le note diesis sono: DO#, RE#, FA#, SOL#, LA#
*/
const getNoteDetail = (note: string) => {
  note = convert_equivalent_notes(note);

  for (let i = 0; i < NOTES_DICTIONARY.length; i++) {
    let currentItem: NoteDictionaryItem = NOTES_DICTIONARY[i];
    let currnote: string = currentItem.name;

    for (let j = 0; j < currentItem.rappresentations.length; j++) {
      let currentItemRappresentation: NoteDictionaryItemRappresentation =
        currentItem.rappresentations[j];
      let currmode = currentItemRappresentation.mode;

      if (currnote + currmode === note) {
        return currentItemRappresentation.values;
      }
    }
  }

  return null;
};

export const getTooltipScheme = (note: string) => {
  let details: NoteDictionaryItemValue[] | null = getNoteDetail(note);

  if (details) {
    details.sort(function (a, b) {
      return a["barre"] - b["barre"];
    });
    return details;
  } else {
    return null;
  }
};

export const getSupportedNotesList = () => {
  let list: string[] = [];
  for (let i = 0; i < NOTES_DICTIONARY.length; i++) {
    let currentItem: NoteDictionaryItem = NOTES_DICTIONARY[i];
    list.push(currentItem.name);
  }
  return list;
};

export const getSupportedNotesFullList = () => {
  let list: string[] = [];
  for (let i = 0; i < NOTES_DICTIONARY.length; i++) {
    let currentItem: NoteDictionaryItem = NOTES_DICTIONARY[i];
    for (let j = 0; j < currentItem.rappresentations.length; j++) {
      list.push(currentItem.name + currentItem.rappresentations[j].mode);
    }
  }
  return list;
};

export const getSupportedSchemes = () => {
  let list: string[][] = [];
  for (let i = 0; i < NOTES_DICTIONARY.length; i++) {
    let currentItem: NoteDictionaryItem = NOTES_DICTIONARY[i];
    for (let j = 0; j < currentItem.rappresentations.length; j++) {
      let values: string[] = [];
      for (let k = 0; k < currentItem.rappresentations[j].values.length; k++) {
        values.push(currentItem.name + currentItem.rappresentations[j].mode);
      }
      list.push(values);
    }
  }
  return list;
};
