import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Eli,_eli,_lammà_sabactani",
  title: "Eli, eli, lammà sabactani?",
  url_video: "https://www.youtube.com/embed/Y5XRRwg3Aro",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       La-      Mi-",
          text: "ELI, ELI, LAMMÀ SABACTANI?",
        },
        {
          notes: "                      La-              Mi-",
          text: "DIO MIO, DIO MIO, PERCHÉ MI HAI ABBANDONATO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "      Si7",
          text: "Che lontana sento la tua salvezza dal mio lamento,",
        },
        {
          notes: "",
          text: "ti invoco di giorno e non rispondi,",
        },
        {
          notes: "                                  Mi-",
          text: "grido di notte e non c'è riposo per me.",
        },
        {
          notes: "                  La-",
          text: "Eppure tu sei il Santo,",
        },
        {
          notes: "                                  Mi-",
          text: "tu che abiti nelle lodi del mio popolo.",
        },
        {
          notes: "  Si7",
          text: "In te hanno sperato i miei padri,",
        },
        {
          notes: "",
          text: "hanno sperato e tu li hai liberati;",
        },
        {
          notes: "",
          text: "a te gridarono e furono salvati,",
        },
        {
          notes: "                                 Mi-",
          text: "sperarono in te e non rimasero delusi.",
        },
        {
          notes: "                            La-",
          text: "Allora io? Perché non mi aiuti?",
        },
        {
          notes: "                   Mi-          Si7",
          text: "Forse non sono un uomo? Ecco io sono un verme!",
        },
        {
          notes: "",
          text: "infamia degli uomini! rifiuto del popolo!",
        },
        {
          notes: "",
          text: "scherniscono quelli che mi vedono,",
        },
        {
          notes: "",
          text: "storcono le labbra e scuotono il capo e dicono:",
        },
        {
          notes: "",
          text: "“Si è affidato al Signore,",
        },
        {
          notes: "                              Mi-",
          text: "che lui lo liberi se tanto lo ama.“",
        },
        {
          notes: "                                  La-",
          text: "Sì che tu mi ami, sì che mi vuoi bene!",
        },
        {
          notes: "                                   Mi-",
          text: "Tu mi hai tratto dal grembo di mia madre.",
        },
        {
          notes: "        Si7",
          text: "Al mio nascere tu mi hai raccolto,",
        },
        {
          notes: "",
          text: "dal seno di mia madre sei tu il mio Dio,",
        },
        {
          notes: "                                Mi-",
          text: "quando nascevo già fui affidato a te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       La-      Mi-",
          text: "ELI, ELI, LAMMÀ SABACTANI?",
        },
        {
          notes: "                      La-              Mi-",
          text: "DIO MIO, DIO MIO, PERCHÉ MI HAI ABBANDONATO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si7",
          text: "Da me non stare lontano,",
        },
        {
          notes: "                                       Mi-",
          text: "perché l'angoscia è vicina e nessuno mi aiuta!",
        },
        {
          notes: "   Si7",
          text: "circondano tori numerosi,",
        },
        {
          notes: "",
          text: "mi assediano tori di Basan.",
        },
        {
          notes: "",
          text: "Spalancano contro di me la loro bocca",
        },
        {
          notes: "                          Mi-",
          text: "come leone che sbrana e ruggisce.",
        },
        {
          notes: "                           La-",
          text: "Sono acqua sporca che si butta,",
        },
        {
          notes: "                          Mi-",
          text: "sono slogate tutte le mie ossa.",
        },
        {
          notes: "        Si7",
          text: "Il mio cuore è come cera,",
        },
        {
          notes: "",
          text: "si fonde dentro, in mezzo alle mie viscere.",
        },
        {
          notes: "",
          text: "È arido come un coccio il mio palato,",
        },
        {
          notes: "",
          text: "la mia lingua si è incollata alla gola,",
        },
        {
          notes: "                            Mi-",
          text: "mi hai deposto su polvere di morte.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       La-      Mi-",
          text: "ELI, ELI, LAMMÀ SABACTANI?",
        },
        {
          notes: "                      La-              Mi-",
          text: "DIO MIO, DIO MIO, PERCHÉ MI HAI ABBANDONATO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Si7",
          text: "Un branco di cani mi circonda,",
        },
        {
          notes: "",
          text: "mi assedia una banda di malvagi;",
        },
        {
          notes: "",
          text: "hanno forato le mie mani e i miei piedi,",
        },
        {
          notes: "",
          text: "posso contare tutte le mie ossa.",
        },
        {
          notes: "",
          text: "guardano, mi osservano:",
        },
        {
          notes: "",
          text: "si dividono le mie vesti,",
        },
        {
          notes: "                           Mi-",
          text: "sul mio vestito gettano la sorte.",
        },
        {
          notes: "                                La-",
          text: "Ma tu, Signore, non restare lontano,",
        },
        {
          notes: "                                      Mi-",
          text: "forza mia, forza mia, accorri in mio aiuto.",
        },
        {
          notes: "  Si7",
          text: "Scampami dalla spada,",
        },
        {
          notes: "",
          text: "dalle unghie del cane scampa la mia vita,",
        },
        {
          notes: "",
          text: "salvami, salvami dalla bocca,",
        },
        {
          notes: "                                        Mi-",
          text: "dalla bocca del leone e dalle corna dei bufali.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       La-      Mi-",
          text: "ELI, ELI, LAMMÀ SABACTANI?",
        },
        {
          notes: "                      La-              Mi-",
          text: "DIO MIO, DIO MIO, PERCHÉ MI HAI ABBANDONATO?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do                             Si7",
          text: "Annunzierò il tuo nome ai miei fratelli,",
        },
        {
          notes: " Do                             Si7",
          text: "ti loderò in mezzo all'assemblea e dirò:",
        },
        {
          notes: "   La-                          Si7",
          text: "lodate il Signore voi che lo temete,",
        },
        {
          notes: "   La-                           Si7",
          text: "cantate al Signore, stirpe di Giacobbe,",
        },
        {
          notes: "                              Mi-",
          text: "gli dia gloria la stirpe di Israele;",
        },
        {
          notes: "                                            La-",
          text: "perché egli non ha avuto schifo di questo povero,",
        },
        {
          notes: "                                               Mi-",
          text: "è l'unico che non si è scandalizzato di me,",
        },
        {
          notes: "   Si7",
          text: "non ha disprezzato questo misero,",
        },
        {
          notes: "",
          text: "non gli ha nascosto il suo volto,",
        },
        {
          notes: "",
          text: "ma al suo grido lo ha esaudito,",
        },
        {
          notes: "                       Mi-",
          text: "al suo grido lo ha ascoltato;",
        },
        {
          notes: "                              La-",
          text: "adesso so che i poveri si sazieranno,",
        },
        {
          notes: "                            Mi-",
          text: "che chiunque ti cerca ti troverà,",
        },
        {
          notes: "             Si7",
          text: "che il loro cuore, il loro cuore",
        },
        {
          notes: "                                    Mi-",
          text: "vivrà, vivrà in eterno, vivrà per sempre.",
        },
        {
          notes: "   Do                                        Si7",
          text: "Torneranno al Signore tutti i confini della terra,",
        },
        {
          notes: "                                                    Mi-",
          text: "si prostreranno davanti a lui tutte le famiglie dei popoli.",
        },
        {
          notes: "                                   La-",
          text: "Poiché il regno, il regno è del Signore,",
        },
        {
          notes: "                           Mi-",
          text: "egli domina su tutte le nazioni.",
        },
        {
          notes: "       Si7",
          text: "A lui solo si prostreranno quanti dormono sotto terra,",
        },
        {
          notes: "                                                     Mi-",
          text: "si curveranno davanti a lui quanti discendono nella polvere.",
        },
        {
          notes: "                               La-",
          text: "Perché io, io vivrò, vivrò per lui,",
        },
        {
          notes: "                       Mi-",
          text: "lo servirà la mia discendenza.",
        },
        {
          notes: "      Si7",
          text: "parlerà del Signore alla generazione che viene;",
        },
        {
          notes: "",
          text: "annunzieranno questo che ha fatto con me;",
        },
        {
          notes: "",
          text: "al popolo che nascerà diranno:",
        },
        {
          notes: "                     Mi-",
          text: "“ecco l'opera del Signore!“",
        },
      ],
    },
  ],
  subtitle: "Sal 21(22)",
};
