import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedici anima mia Jahvè.",
  url_video: "https://www.youtube.com/embed/q8niOJ99rX8",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Mi",
          text: "BENEDICI ANIMA MIA JAHVÈ",
        },
        {
          notes: "                      La-",
          text: "INNEGGIA AL NOME SUO SANTO",
        },
        {
          notes: "                   Mi",
          text: "BENEDICI ANIMA MIA JAHVÈ",
        },
        {
          notes: "                        La-",
          text: "NON DIMENTICARE IL SUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Lui, che le tue colpe perdona,",
        },
        {
          notes: "                         La-",
          text: "che cura tutti i tuoi affanni",
        },
        {
          notes: "                        Mi",
          text: "salva la tua vita dalla morte",
        },
        {
          notes: "                       La-",
          text: "ti colma di grazie e d'amor.",
        },
        {
          notes: "Re-",
          text: "Lui, che ti rende giustizia,",
        },
        {
          notes: "                        La-",
          text: "che difende tutti i più umili,",
        },
        {
          notes: "                       Mi",
          text: "a Mosè insegnò il suo cammino",
        },
        {
          notes: "                            La-",
          text: "a Israele le sue imprese mostrò.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Mi",
          text: "BENEDICI ANIMA MIA JAHVÈ",
        },
        {
          notes: "                      La-",
          text: "INNEGGIA AL NOME SUO SANTO",
        },
        {
          notes: "                   Mi",
          text: "BENEDICI ANIMA MIA JAHVÈ",
        },
        {
          notes: "                        La-",
          text: "NON DIMENTICARE IL SUO AMORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 102(103)",
};
