import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Fratelli non diamo a nessuno motivo d'incimpo.",
  url_video: "https://www.youtube.com/embed/QXGHJHEJzx8",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La",
          text: "Fratelli!",
        },
        {
          notes: "    Re",
          text: "Non diamo a nessuno motivo d’inciampo!",
        },
        {
          notes: "Mi                               La-",
          text: "fratelli, accetando sempre le tribolazioni",
        },
        {
          notes: "Re-                   Mi",
          text: "necessità, angustie e fatiche, ",
        },
        {
          notes: "Re-                     Mi ",
          text: "con pazienza, purezza e bontà",
        },
        {
          notes: "            Fa                  Mi",
          text: "nello spirito Santo e nel potere di Dio ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                  Mi",
          text: "Usando le armi della giustizia.",
        },
        {
          notes: "Re-                                     Mi",
          text: "quelle della destra, come quelle della sinistra",
        },
        {
          notes: "Re-            Mi",
          text: "in gloria e ignominia ",
        },
        {
          notes: "Re-                          Mi",
          text: "nelle calunnie e nella buona fama",
        },
        {
          notes: "Fa",
          text: "come impostori eppure veraci,",
        },
        {
          notes: "                                   Mi",
          text: "come chi non ha nulla eppure possiede tutto",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La",
          text: "Fratelli!",
        },
        {
          notes: "    Re",
          text: "Non diamo a nessuno motivo d’inciampo!",
        },
        {
          notes: "Mi                               La-",
          text: "fratelli, accetando sempre le tribolazioni",
        },
        {
          notes: "Re-                   Mi",
          text: "necessità, angustie e fatiche, ",
        },
        {
          notes: "Re-                     Mi ",
          text: "con pazienza, purezza e bontà",
        },
        {
          notes: "            Fa                  Mi",
          text: "nello spirito Santo e nel potere di Dio ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7                         Re-",
          text: "Fratelli! Vi parliamo con franchezza",
        },
        {
          notes: "Fa                     Mi",
          text: "vi parliamo in tutta verità:",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "non legatevi al giogo disuguale",
        },
        {
          notes: "       Mi",
          text: "con i pagani",
          msg: "  (L'ass. ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                               Mi",
          text: "Quale intesa c’è tra il fedele e l’infedele?",
        },
        {
          notes: "Fa                                                  Mi",
          text: "Quale unione tra il santuario di Dio e quello degli idoli?",
        },
        {
          notes: "Re-                        Mi",
          text: "Perché siamo il santuario di Dio",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La",
          text: "Fratelli!",
        },
        {
          notes: "    Re",
          text: "Non diamo a nessuno motivo d’inciampo!",
        },
        {
          notes: "Mi                               La-",
          text: "fratelli, accetando sempre le tribolazioni",
        },
        {
          notes: "Re-                   Mi",
          text: "necessità, angustie e fatiche, ",
        },
        {
          notes: "Re-                     Mi ",
          text: "con pazienza, purezza e bontà",
        },
        {
          notes: "            Fa                  Mi",
          text: "nello spirito Santo e nel potere di Dio ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La7                              Re-",
          text: "Fratelli ! Abbiamo piena fiducia nel parlarvi",
        },
        {
          notes: "",
          text: "Perché siamo orgogliosi di voi",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "non legatevi al giogo disuguale",
        },
        {
          notes: "       Mi",
          text: "con i pagani",
          msg: "  (L'ass. ripete)",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                               Mi",
          text: "Quale intesa c’è tra il fedele e l’infedele?",
        },
        {
          notes: "Fa                                                  Mi",
          text: "Quale unione tra il santuario di Dio e quello degli idoli?",
        },
        {
          notes: "Re-                        Mi",
          text: "Perché siamo il santuario di Dio",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La",
          text: "Fratelli!",
        },
        {
          notes: "    Re",
          text: "Non diamo a nessuno motivo d’inciampo!",
        },
        {
          notes: "Mi                               La-",
          text: "fratelli, accetando sempre le tribolazioni",
        },
        {
          notes: "Re-                   Mi",
          text: "necessità, angustie e fatiche, ",
        },
        {
          notes: "Re-                     Mi ",
          text: "con pazienza, purezza e bontà",
        },
        {
          notes: "            Fa                  Mi",
          text: "nello spirito Santo e nel potere di Dio ",
        },
      ],
    },
  ],
  subtitle: "(cfr 2 cor 6, 3-16) - inizio corso 2005",
};
