import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ho steso le mie mani.",
  url_video: "https://www.youtube.com/embed/ra8xOzdat8o",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re      Si-      Sol",
          text: "HO STESO LE MIE MANI,",
        },
        {
          notes: "    Mi           La",
          text: "HO STESO LE MIE MANI",
        },
        {
          notes: "         Sol         Re",
          text: "E HO PREGATO IL SIGNORE,",
        },
        {
          notes: "      La                Re",
          text: "HO PREGATO COME IL SIGNORE:",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#               Sol        Fa#",
          text: "Abbà, Padre mio, Abbà, Padre mio,",
        },
        {
          notes: "Mi                            La",
          text: "nelle tue mani affido il mio spirito,",
        },
        {
          notes: "Sol        Re    La            Re",
          text: "nelle tue mani affido il mio spirito.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re      Si-      Sol",
          text: "HO STESO LE MIE MANI,",
        },
        {
          notes: "    Mi           La",
          text: "HO STESO LE MIE MANI",
        },
        {
          notes: "         Sol         Re",
          text: "E HO PREGATO IL SIGNORE,",
        },
        {
          notes: "      La                Re",
          text: "HO PREGATO COME IL SIGNORE:",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-      Mi                           La",
          text: "Perché stendere le mani è il segno di lui,",
        },
        {
          notes: "Sol             Re                  La",
          text: "e il mio stare eretto il legno innalzato,",
        },
        {
          notes: "              Re",
          text: "il legno innalzato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re      Si-      Sol",
          text: "HO STESO LE MIE MANI,",
        },
        {
          notes: "    Mi           La",
          text: "HO STESO LE MIE MANI",
        },
        {
          notes: "         Sol         Re",
          text: "E HO PREGATO IL SIGNORE,",
        },
        {
          notes: "      La                Re",
          text: "HO PREGATO COME IL SIGNORE:",
        },
      ],
    },
    {
      speaker: "",
      mode: "upper",
      lines: [
        {
          notes: "Fa#               Sol        Fa#",
          text: "Abbà, Padre mio, Abbà, Padre mio,",
        },
        {
          notes: "Mi                            La",
          text: "nelle tue mani affido il mio spirito,",
        },
        {
          notes: "Sol        Re    La            Re",
          text: "nelle tue mani affido il mio spirito.",
        },
      ],
    },
  ],
  subtitle: "",
};
