import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Anima_Christi.",
  title: "Anima Christi.",
  url_video: "",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " Sib-  Solb         La7      Sib-",
          text: " ANIMA CHRISTI, SANCTI---FICA ME",
        },
        {
          notes: " Mib-   Sib-     Solb7 Fa7",
          text: " CORPUS CHRISTI, SALVA ME",
        },
        {
          notes: " Sib-    Solb          Fa-  Solb",
          text: " SANGUIS CHRISTI, INE--BRIA ME",
        },
        {
          notes: " Reb7 Mib-    Sib-     Fa7  Sib-",
          text: " AQUA LATERIS CHRISTI, LAVA ME",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-   Solb        La7  Sib-  ",
          text: "Passio Christi, conforta me",
        },
        {
          notes: "Mib-   Reb7   Solb7 Fa7   Fa7",
          text: "O bone Iesu, exaudi me",
        },
        {
          notes: "Sib-  Solb        Sib-",
          text: "Intra vulnera tu--a ",
        },
        {
          notes: "Mib-  Reb7   Solb Fa- Sib-",
          text: "absconde   absco-nde  me.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-  Solb         La7      Sib-",
          text: "ANIMA CHRISTI, SANCTI---FICA ME",
        },
        {
          notes: "Mib-   Sib-     Solb7 Fa7",
          text: "CORPUS CHRISTI, SALVA ME",
        },
        {
          notes: "Sib-    Solb          Fa-  Solb",
          text: "SANGUIS CHRISTI, INE--BRIA ME",
        },
        {
          notes: "Reb7 Mib-    Sib-     Fa7  Sib-",
          text: "AQUA LaTERIS CHRISTI, LaVA ME",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-  Solb     La7      Sib-  ",
          text: "Ne permittas a te me separari",
        },
        {
          notes: "Mib-       Reb7    Solb7 Fa7 ",
          text: "Ab hoste maligno defende me",
        },
        {
          notes: "Sib-    Solb       Sib-",
          text: "In hora mortis me--æ ",
        },
        {
          notes: "Mib- Reb7 Solb Fa7 Sib-",
          text: "voca me   vo---ca   me",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-  Solb         La7      Sib-",
          text: "ANIMA CHRISTI, SANCTI---FICA ME",
        },
        {
          notes: "Mib-   Sib-     Solb7 Fa7",
          text: "CORPUS CHRISTI, SALVA ME",
        },
        {
          notes: "Sib-    Solb          Fa-  Solb",
          text: "SANGUIS CHRISTI, INE--BRIA ME",
        },
        {
          notes: "Reb7 Mib-    Sib-     Fa7  Sib-",
          text: "AQUA LaTERIS CHRISTI, LaVA ME",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-    Solb La7    Sib-     ",
          text: "Et iube me venire ad te,",
        },
        {
          notes: "Mib-           Reb7 Solb7  Fa7   Fa7",
          text: "ut cum sanctis tuis laudem te",
        },
        {
          notes: "Sib-    Solb     Sib- Mib- Reb7   Solb Fa- Sib-",
          text: "per infinita sæcula   sæcu-lorum  A--------men",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sib-  Solb         La7      Sib-",
          text: "ANIMA CHRISTI, SANCTI---FICA ME",
        },
        {
          notes: "Mib-   Sib-     Solb7 Fa7",
          text: "CORPUS CHRISTI, SALVA ME",
        },
        {
          notes: "Sib-    Solb          Fa-  Solb",
          text: "SANGUIS CHRISTI, INE--BRIA ME",
        },
        {
          notes: "Reb7 Mib-    Sib-     Fa7  Sib-",
          text: "AQUA LaTERIS CHRISTI, LaVA ME   ",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
