import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Maria, piccola Maria.",
  url_video: "https://www.youtube.com/embed/hCxFTdE01w8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re              Fa#-",
          text: "Maria, piccola Maria,",
        },
        {
          notes: "Sol                       Mi6",
          text: "tu sei la brezza soave di Elia,",
        },
        {
          notes: "La7                          Re",
          text: "il sussurro dello Spirito di Dio.",
        },
        {
          notes: "                             Fa#-",
          text: "Tu sei il roveto ardente di Mosè",
        },
        {
          notes: "    Sol",
          text: "che porta il Signore",
        },
        {
          notes: "           La7",
          text: "e non si consuma.",
        },
        {
          notes: "   Re                      Fa#-",
          text: "Tu sei: “Quel luogo vicino a me“",
        },
        {
          notes: "      Sol                Re",
          text: "che mostrò il Signore a Mosè.",
        },
        {
          notes: "   Sol                 Re",
          text: "Tu sei la cavità nella rupe",
        },
        {
          notes: "                         La",
          text: "che Dio copre con la sua mano",
        },
        {
          notes: "        Sol          Re",
          text: "mentre passa la sua gloria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                    Fa#",
          text: "VENGA IL SIGNORE CON NOI",
        },
        {
          notes: "   Sol                            Re",
          text: "SE ABBIAMO TROVATO GRAZIA AI SUOI OCCHI;",
        },
        {
          notes: "  Sol                Re",
          text: "È VERO CHE SIAMO PECCATORI",
        },
        {
          notes: "              La",
          text: "MA PREGA TU PER NOI",
        },
        {
          notes: "  Sol      La      Re",
          text: "E SAREMO LA SUA EREDITÀ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re            Fa#-",
          text: "Maria, piccola Maria,",
        },
        {
          notes: "  Sol           La7",
          text: "figlia di Gerusalemme.",
        },
        {
          notes: " Re               Fa#-",
          text: "Madre di tutti i popoli",
        },
        {
          notes: " Sol          La7",
          text: "vergine di Nazareth.",
        },
        {
          notes: "   Re                 Fa#",
          text: "Tu sei la nube che protegge Israele",
        },
        {
          notes: "   Sol             Re",
          text: "la Tenda della riunione",
        },
        {
          notes: "  Sol                 Re",
          text: "l'Arca che porta l'Alleanza",
        },
        {
          notes: "                             La",
          text: "il luogo della Dimora del Signore",
        },
        {
          notes: " Sol             La    Re",
          text: "santuario della sua Shekinà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                    Fa#",
          text: "VENGA IL SIGNORE CON NOI",
        },
        {
          notes: "   Sol                            Re",
          text: "SE ABBIAMO TROVATO GRAZIA AI SUOI OCCHI;",
        },
        {
          notes: "  Sol                Re",
          text: "È VERO CHE SIAMO PECCATORI",
        },
        {
          notes: "              La",
          text: "MA PREGA TU PER NOI",
        },
        {
          notes: "  Sol      La      Re",
          text: "E SAREMO LA SUA EREDITÀ.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "   Re                      Fa#-",
          text: "Tu sei: “Quel luogo vicino a me“",
        },
        {
          notes: "      Sol                Re",
          text: "che mostrò il Signore a Mosè.",
        },
        {
          notes: "   Sol                 Re",
          text: "Tu sei la cavità nella rupe",
        },
        {
          notes: "                         La",
          text: "che Dio copre con la sua mano",
        },
        {
          notes: "        Sol          Re",
          text: "mentre passa la sua gloria.",
        },
      ],
    },
  ],
  subtitle: "Inno alla Vergine Maria",
};
