import { useEffect, useState } from "react";
import Img from "../Img/Img";
import { cleanVisibleNoteLabel } from "../NoteInteractiveLabel/NoteInteractiveLabel";
import "./ChordGraph.scss";
import { getTooltipScheme, NoteDictionaryItemValue } from "./ChordMap";

const FRAME_VISIBILITY_DURATION = 3000; // ms

export interface ChordSchemeProps {
  scheme: NoteDictionaryItemValue;
  minimal?: boolean;
}

export interface ChordGraphProps {
  chord: string;
  index?: number;
  minimal?: boolean;
}

export const getBarreLabel = (barre: number) => {
  if (barre === 1) {
    return "I";
  }
  if (barre === 2) {
    return "II";
  }
  if (barre === 3) {
    return "III";
  }
  if (barre === 4) {
    return "IV";
  }
  if (barre === 5) {
    return "V";
  }
  if (barre === 6) {
    return "VI";
  }
  if (barre === 7) {
    return "VII";
  }
  if (barre === 8) {
    return "VIII";
  }
  if (barre === 9) {
    return "IX";
  }
  return "";
};

const ChordScheme = (props: ChordSchemeProps) => {
  const getNotesHandDots = (dots: number[][], hasBarre: boolean) => {
    let dotsStyles: any = [];
    for (let i = 0; i < dots.length; i++) {
      let dotValue = dots[i][2];
      if (hasBarre) {
        dotValue++;
      }
      if (
        (hasBarre || dotValue === 1) &&
        dotsStyles.filter((x: any) => x.dot === dotValue).length === 0
      ) {
        dotsStyles.push({
          dot: 1,
          style: { marginLeft: "8.95em", marginTop: "3.5em" },
        });
      }
      if (
        dotValue === 2 &&
        dotsStyles.filter((x: any) => x.dot === dotValue).length === 0
      ) {
        dotsStyles.push({
          dot: 2,
          style: { marginLeft: "10.55em", marginTop: "2.5em" },
        });
      }
      if (
        dotValue === 3 &&
        dotsStyles.filter((x: any) => x.dot === dotValue).length === 0
      ) {
        dotsStyles.push({
          dot: 3,
          style: { marginLeft: "12.2em", marginTop: "3.1em" },
        });
      }
      if (
        dotValue === 4 &&
        dotsStyles.filter((x: any) => x.dot === dotValue).length === 0
      ) {
        dotsStyles.push({
          dot: 4,
          style: { marginLeft: "13.8em", marginTop: "4.5em" },
        });
      }
    }
    return dotsStyles;
  };

  return (
    <div
      className={
        "chord-scheme-main-wrap " +
        (props.minimal !== true ? "" : "chord-scheme-main-wrap-minimal")
      }
    >
      <div className="chord-scheme-background">
        {props.minimal !== true && (
          <div>
            <Img
              className="chord-scheme-hand-wrap"
              src={"./Assets/hand.png"}
              alt="Notes Hand"
            />
            <div className="chord-scheme-fit-angle">
              {getNotesHandDots(
                props.scheme.dots,
                props.scheme.barre !== 0
              ).map((x: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="chord-scheme-note"
                    style={{
                      marginLeft: x.style.marginLeft,
                      marginTop: x.style.marginTop,
                    }}
                  >
                    {x.dot}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="chord-scheme-fit-angle">
          <div className="vertical-line"></div>
          <div className="vertical-line"></div>
          <div className="vertical-line"></div>
          <div className="vertical-line"></div>
          <div className="vertical-line"></div>
          <div className="vertical-line"></div>
        </div>
        <div className="chord-scheme-fit-angle">
          <div className="horiz-line"></div>
          <div className="horiz-line"></div>
          <div className="horiz-line"></div>
        </div>
        <div className="chord-scheme-fit-angle">
          <div className="chord-scheme-note-voices">
            <div className="chord-scheme-note-voice">E2</div>
            <div className="chord-scheme-note-voice">A2</div>
            <div className="chord-scheme-note-voice">D3</div>
            <div className="chord-scheme-note-voice">G3</div>
            <div className="chord-scheme-note-voice">B3</div>
            <div className="chord-scheme-note-voice">E4</div>
          </div>
        </div>
      </div>
      <div className="chord-scheme-notes-wrap">
        {props.scheme.barre !== 0 && (
          <div className="chords-scheme-barre-wrap">
            <div className="chords-scheme-barre-label">
              {getBarreLabel(props.scheme.barre)}
            </div>
            <div className="chords-scheme-barre-end"></div>
            <div className="chords-scheme-barre-bar"></div>
            <div
              className="chord-scheme-note"
              style={{
                left: "7em",
                top: "-0.1em",
              }}
            >
              1
            </div>
          </div>
        )}
        {props.scheme.dots.map((x: number[], i: number) => {
          return (
            <div
              className="chord-scheme-note"
              key={i}
              style={{
                left: (0.7 + x[1] * 1.18).toString() + "em",
                top:
                  (
                    0.9 +
                    x[0] * 3 +
                    (props.scheme.barre !== 0 ? 3 : 0)
                  ).toString() + "em",
              }}
            >
              {x[2] + (props.scheme.barre !== 0 ? 1 : 0)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getTooltipNode = (note: string, minimal: boolean) => {
  let details: NoteDictionaryItemValue[] | null = getTooltipScheme(note);
  if (details) {
    let schemeList: JSX.Element[] = [];
    for (let i = 0; i < details.length; i++) {
      schemeList.push(
        <ChordScheme scheme={details[i]} minimal={minimal} key={i} />
      );
    }

    return schemeList;
  } else {
    return [<div></div>];
  }
};

const ChordGraph = (props: ChordGraphProps) => {
  const [nodes, setNodes] = useState<JSX.Element[]>([]);
  const [slideHandler, setSlideHandler] = useState<number>(-1);
  const [timerHandler, setTimerHandler] = useState<NodeJS.Timeout | null>(null);

  const cleanChordLabel = (rawChord: string) => {
    rawChord = rawChord.replaceAll("(", "");
    rawChord = rawChord.replaceAll(")", "");
    return rawChord;
  };

  useEffect(() => {
    setNodes(
      getTooltipNode(cleanChordLabel(props.chord), props.minimal ?? false)
    );
    setSlideHandler(props.index ?? 0);

    return () => {
      if (timerHandler !== null) {
        clearTimeout(timerHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (nodes.length <= 1 || props.index !== undefined) {
      return;
    }

    let handle = slideHandler;
    if (handle < nodes.length - 1) {
      handle++;
    } else {
      handle = 0;
    }

    setTimerHandler(
      setTimeout(() => {
        setSlideHandler(handle);
      }, FRAME_VISIBILITY_DURATION)
    );
  }, [slideHandler]);

  return (
    <div
      className={
        "chord-graph-main-wrap " +
        (props.minimal === true
          ? "chord-graph-main-wrap-minimal"
          : "plate_background box-shadow")
      }
    >
      <div className="chord-graph-chord-label">
        {cleanVisibleNoteLabel(props.chord)}
      </div>
      <div className="chord-graph-chord-scheme">{nodes[slideHandler]}</div>
    </div>
  );
};

export default ChordGraph;
