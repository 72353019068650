import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Salve_Regina.",
  title: "Salve Regina.",
  url_video: "https://www.youtube.com/embed/OQjYEywIK5Q",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Sol  Do     Sol  Do   ",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol     Re    Do   Sol  La- Do    Sol",
          text: "Salve Regina, madre        di misericordia,",
        },
        {
          notes: "         Re              Do      Sol",
          text: "vita, dolcezza, speranza nostra, salve",
        },
        {
          notes: "        Re    ",
          text: "Salve Regina!",
        },
        {
          notes: "Sol     Re    Do   Sol  La- Do    Sol",
          text: "Salve Regina, madre        di misericordia,",
        },
        {
          notes: "         Re              Do      Sol",
          text: "vita, dolcezza, speranza nostra, salve",
        },
        {
          notes: "        Re    ",
          text: "Salve Regina! ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol        Do6    Re            Sol",
          text: " A te ricorriamo,  esuli figli di Eva",
        },
        {
          notes: "     La-  Si-        Do",
          text: "a te sospiriamo, piangenti",
        },
        {
          notes: "                   Re",
          text: "in questa valle di lacrime.",
        },
        {
          notes: "Sol       Do6    Re                   Sol",
          text: " Avvocata nostra, volgi a noi gli occhi tuoi",
        },
        {
          notes: "               La-    Si-",
          text: "Mostraci, dopo quest’esilio,",
        },
        {
          notes: "   Do                     Re",
          text: "il frutto del tuo seno, Gesù.",
        },
        {
          notes: "Sol     Re    Do  Sol    La-       Sol",
          text: "Salve Regina, Madre       di misericordia",
        },
        {
          notes: "              Re           Do        Sol",
          text: "O clemente, o pia, o dolce Vergine Maria.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        Re",
          text: "Salve Regina!",
        },
        {
          notes: "Sol     Re    Do Sol  La-   Sol",
          text: "Salve Regina, sa-a----a----al-ve,",
        },
        {
          notes: "Do Sol  La-  Sol",
          text: "sa-a---a---al--ve!",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
