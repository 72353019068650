import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Santo è il Signor - santo delle baracche.",
  url_video: "https://www.youtube.com/embed/L70n_34PD0M",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La            Mi",
          text: "SANTO, SANTO, SANTO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR,",
        },
        {
          notes: "            Mi",
          text: "DIO DELL'UNIVERSO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa",
          text: "Pieni sono i cieli",
        },
        {
          notes: "      Mi",
          text: "e la terra della tua gloria.",
        },
        {
          notes: " Fa",
          text: "Osanna, osanna, osanna,",
        },
        {
          notes: "              Mi",
          text: "osanna nelle altezze.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La            Mi",
          text: "SANTO, SANTO, SANTO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR,",
        },
        {
          notes: "            Mi",
          text: "DIO DELL'UNIVERSO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Benedetto colui che viene,",
        },
        {
          notes: "             Mi7",
          text: "nel nome del Signore,",
        },
        {
          notes: "",
          text: "benedetto colui che viene",
        },
        {
          notes: "               La-",
          text: "nel nome del Signor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "OSANNA NELLE ALTEZZE,",
        },
        {
          notes: "                    Mi7",
          text: "BENEDETTO COLUI CHE VIENE,",
        },
        {
          notes: "",
          text: "BENEDETTO COLUI CHE VIENE",
        },
        {
          notes: "                Mi",
          text: "NEL NOME DEL SIGNOR.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La            Mi",
          text: "SANTO, SANTO, SANTO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR,",
        },
        {
          notes: "            Mi",
          text: "DIO DELL'UNIVERSO,",
        },
        {
          notes: "              La",
          text: "SANTO È IL SIGNOR.",
        },
      ],
    },
  ],
  subtitle: "(Tempo di Avvento)",
};
