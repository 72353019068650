import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Mi rubasti il cuore.",
  url_video: "https://www.youtube.com/embed/YJ8iOn8a0lM",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Re-            Sol-",
          text: "(Sposo): Mi rubasti il cuore,",
        },
        {
          notes: "                Re-",
          text: "mi rubasti il cuore,",
        },
        {
          notes: "              Sol-",
          text: "sorella mia, sposa,",
        },
        {
          notes: "      La7                 Re-",
          text: "mi rubasti, mi rubasti il cuore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Re-           Sol-",
          text: "MI RUBASTI IL CUORE,",
        },
        {
          notes: "                Re-",
          text: "MI RUBASTI IL CUORE,",
        },
        {
          notes: "              Sol-",
          text: "SORELLA MIA, SPOSA,",
        },
        {
          notes: "     La7                   Re-",
          text: "MI RUBASTI, MI RUBASTI IL CUORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Re-              Sol-",
          text: "(Sposo): Che belli i tuoi amori,",
        },
        {
          notes: "                   Re-",
          text: "che belli i tuoi amori!",
        },
        {
          notes: "                    Sol-",
          text: "La fragranza dei profumi",
        },
        {
          notes: "       La7                 Re-",
          text: "più di tutti i baslami d'amore.",
        },
        {
          notes: "                      Sol-",
          text: "Miele stillano le tue labbra, sposa mia, sorella,",
        },
        {
          notes: "        La7                Re-",
          text: "miele e latte sotto la tua lingua.",
        },
        {
          notes: "                                                Sol-",
          text: "La fragranza dei tuoi vestti è la fragranza del libano,",
        },
        {
          notes: "",
          text: "sposa, sposa mia,",
        },
        {
          notes: "                La7               Re-",
          text: "più gustosi del vino sono i tuoi amori.",
        },
        {
          notes: "Do                           Re-",
          text: "Giardino chiuso sei, sorella mia;",
        },
        {
          notes: "Do                     Re-",
          text: "fonte sigillata, sposa mia!",
        },
        {
          notes: "       Sol-                       La7",
          text: "I tuoi frutti un paradiso di melograni,",
        },
        {
          notes: "   Sol-                          La7",
          text: "di nardo e zafferano, di mirra e aloe.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Do                         Fa",
          text: "(Sposo): Alzati, vento, Austro, e vieni!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "         Do                         Fa",
          text: "(SPOSO): ALZATI, VENTO, AUSTRO, E VIENI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La7                                            Re-",
          text: "e soffia nel mio giardino, perchè effonda i suoi aromi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "  La7                                            Re-",
          text: "E SOFFIA NEL MIO GIARDINO, PERCHÈ EFFONDA I SUOI AROMI,",
        },
        {
          notes: "Do                                  Fa",
          text: "E POSSA ENTRARE IL MIO AMATO E SI DILETTI",
        },
        {
          notes: "          La7      Re-",
          text: "DEI SUOI FRUTTI SQUISITI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Sol-                                     La7",
          text: "(Sposo): Sono già entrato nel mio giardino, sposa mia,",
        },
        {
          notes: "Sol-                         La7",
          text: "ho mangiato il miele nel suo favo,",
        },
        {
          notes: "Sol-                           La7",
          text: "ho bevuto il mio vino e il mio latte.",
        },
        {
          notes: " Re-     Do      Sib",
          text: "Amici, venite, bevete, o miei cari,",
        },
        {
          notes: "      La7",
          text: "e inebriatevi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " Re-     Do      Sib",
          text: "AMICI, VENITE, BEVETE, O MIEI CARI,",
        },
        {
          notes: "      La7",
          text: "E INEBRIATEVI.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "upper",
      lines: [
        {
          notes: "Re-            Sol-",
          text: "MI RUBASTI IL CUORE,",
        },
        {
          notes: "                Re-",
          text: "MI RUBASTI IL CUORE,",
        },
        {
          notes: "              Sol-",
          text: "SORELLA MIA, SPOSA,",
        },
        {
          notes: "      La7                 Re-",
          text: "MI RUBASTI, MI RUBASTI IL CUORE.",
        },
      ],
    },
  ],
  subtitle: "(Ct. 4,9-5,1)",
};
