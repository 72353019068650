import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il seminatore.",
  url_video: "https://www.youtube.com/embed/R7CUzmMncgY",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                     Re",
          text: "Uscì il seminatore a seminar",
        },
        {
          notes: "      Mi-",
          text: "e una parte cadde sulla strada.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                     Re",
          text: "USCÌ IL SEMINATORE A SEMINAR",
        },
        {
          notes: "      Mi-",
          text: "E UNA PARTE CADDE SULLA STRADA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Vennero gli uccelli",
        },
        {
          notes: " La",
          text: "se la mangiarono,",
        },
        {
          notes: "         Si7",
          text: "se la mangiarono,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "SE LA MANGIARONO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                     Re",
          text: "Uscì il seminatore a seminar",
        },
        {
          notes: "      Mi-",
          text: "e una parte cadde sulla pietra.",
          msg: "(L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Uscì il sole,",
        },
        {
          notes: " La",
          text: "la seccò:",
        },
        {
          notes: "         Si7",
          text: "non aveva terra.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "NON AVEVA TERRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                     Re",
          text: "Uscì il seminatore a seminar",
        },
        {
          notes: "      Mi-",
          text: "e una parte cadde sulle spine.",
          msg: "(L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Crebbe la semente,",
        },
        {
          notes: " La",
          text: "crebbero le spine,",
        },
        {
          notes: "         Si7",
          text: "la soffocarono.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "LA SOFFOCARONO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                     Re",
          text: "Uscì il seminatore a seminar",
        },
        {
          notes: "      Mi-",
          text: "e una parte cadde sulla terra.",
          msg: "(L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "E diede frutto:",
        },
        {
          notes: " La",
          text: "alcuni trenta,",
        },
        {
          notes: "         Si7",
          text: "altri sessanta,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-",
          text: "ED ALTRI CENTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Mi-",
          text: "Chi ha orecchi",
        },
        {
          notes: " La-      Si7",
          text: "e vuol capire:",
        },
        {
          notes: "  Mi-",
          text: "ascolti, ascolti:",
        },
        {
          notes: " La-        Mi-",
          text: "non sia la strada,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Mi-",
          text: "NON SIA LA STRADA,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Mi-",
          text: "non sia la pietra,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Mi-",
          text: "NON SIA LA PIETRA,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Mi-",
          text: "non sia le spine,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-        Mi-",
          text: "NON SIA LE SPINE,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-       Si7",
          text: "ma sia la terra,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-      Si7",
          text: "LA BUONA TERRA,",
        },
        {
          notes: "     Mi-",
          text: "DOVE GESÙ",
        },
        {
          notes: " Si7       Mi-",
          text: "POSSA GERMOGLIARE.",
        },
        {
          notes: "",
          text: "DOVE GESÙ",
        },
        {
          notes: " Si7       Mi-",
          text: "POSSA GERMOGLIARE.",
        },
      ],
    },
  ],
  subtitle: "(Mc. 4, 3 ss)",
};
