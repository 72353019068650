import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Sorga dio.",
  url_video: "https://www.youtube.com/embed/4vmwkSEB4LM",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "CANTATE A DIO, INNEGGIATE AL SUO NOME,",
        },
        {
          notes: "                      Re-.1",
          text: "CANTATE A DIO, SPIANATE LA STRADA",
        },
        {
          notes: "                  Mi",
          text: "A CHI CAVALCA LE NUBI.",
        },
        {
          notes: "",
          text: "“SIGNORE“ È IL SUO NOME,",
        },
        {
          notes: "Re-.1",
          text: "GIOITE DAVANTI A LUI:",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "padre degli orfani, difensore delle vedove",
        },
        {
          notes: "  Mi",
          text: "è Dio.",
        },
        {
          notes: "            Re-.1",
          text: "Quelli più poveri",
        },
        {
          notes: "                     Mi",
          text: "fa abitare nella sua casa,",
        },
        {
          notes: "         Re-.1                  Mi",
          text: "i prigionieri egli libera con gioia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "SORGA DIO,",
        },
        {
          notes: "",
          text: "I SUOI NEMICI SI DISPERDANO,",
        },
        {
          notes: "",
          text: "I GIUSTI SI RALLEGRINO",
        },
        {
          notes: "",
          text: "E CANTINO DI GIOIA!",
        },
      ],
    },
  ],
  subtitle: "Sal 67(68) vv 2-4-5-6-7",
};
