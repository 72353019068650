import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Venite applaudiamo al Signore.",
  url_video: "https://www.youtube.com/embed/qCaIZAj9lzk",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "VENITE APPLAUDIAMO AL SIGNORE",
        },
        {
          notes: "",
          text: "ACCLAMIAMO ALLA ROCCIA",
        },
        {
          notes: "                 La",
          text: "DELLA NOSTRA SALVEZZA;",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "     Sol",
          text: "ACCOSTIAMOCI A LUI",
        },
        {
          notes: "                Re",
          text: "PER RENDERGLI GRAZIE,",
        },
        {
          notes: "            La",
          text: "A LUI ACCLAMIAMO",
        },
        {
          notes: "              Re",
          text: "CON CANTI DI GIOIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Poiché grande Dio è il Signore",
        },
        {
          notes: "       Re",
          text: "grande re sopra tutti gli dèi:",
        },
        {
          notes: "           Mi-",
          text: "nella sua mano sono gli abissi della terra",
        },
        {
          notes: "     La                (Re)",
          text: "sono sue le vette dei monti,",
        },
        {
          notes: "         Mi-",
          text: "suo è il mare, egli l'ha fatto",
        },
        {
          notes: "          La         La7       Re",
          text: "e le sue mani han plasmato la terra",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "VENITE APPLAUDIAMO AL SIGNORE",
        },
        {
          notes: "",
          text: "ACCLAMIAMO ALLA ROCCIA",
        },
        {
          notes: "                 La",
          text: "DELLA NOSTRA SALVEZZA;",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "     Sol",
          text: "ACCOSTIAMOCI A LUI",
        },
        {
          notes: "                Re",
          text: "PER RENDERGLI GRAZIE,",
        },
        {
          notes: "            La",
          text: "A LUI ACCLAMIAMO",
        },
        {
          notes: "              Re",
          text: "CON CANTI DI GIOIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Venite, prostratevi adoriamo",
        },
        {
          notes: "      Re",
          text: "il Signore che ci ha creati;",
        },
        {
          notes: "       Mi-",
          text: "egli è colui che ci guida nel desert",
        },
        {
          notes: "       La              (Re)",
          text: "Ascoltiamo oggi la sua voce",
        },
        {
          notes: "      Mi-",
          text: "non induriamo i nostri cuori",
        },
        {
          notes: "       La             Re",
          text: "come fecero i nostri padri.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "VENITE APPLAUDIAMO AL SIGNORE",
        },
        {
          notes: "",
          text: "ACCLAMIAMO ALLA ROCCIA",
        },
        {
          notes: "                 La",
          text: "DELLA NOSTRA SALVEZZA;",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "     Sol",
          text: "ACCOSTIAMOCI A LUI",
        },
        {
          notes: "                Re",
          text: "PER RENDERGLI GRAZIE,",
        },
        {
          notes: "            La",
          text: "A LUI ACCLAMIAMO",
        },
        {
          notes: "              Re",
          text: "CON CANTI DI GIOIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-",
          text: "Nel deserto i vostri padri mi tentarono",
        },
        {
          notes: "      Re",
          text: "pur avendo visto le mie opere;",
        },
        {
          notes: "            Mi-",
          text: "per quarant'anni ho sopportato",
        },
        {
          notes: "La                 Re",
          text: "quella perduta generazione.",
        },
        {
          notes: "  Mi-",
          text: "Perciò nella mia ira ho giurato",
        },
        {
          notes: "      La              Re",
          text: "non verranno al mio riposo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "VENITE APPLAUDIAMO AL SIGNORE",
        },
        {
          notes: "",
          text: "ACCLAMIAMO ALLA ROCCIA",
        },
        {
          notes: "                 La",
          text: "DELLA NOSTRA SALVEZZA;",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "     Sol",
          text: "ACCOSTIAMOCI A LUI",
        },
        {
          notes: "                Re",
          text: "PER RENDERGLI GRAZIE,",
        },
        {
          notes: "            La",
          text: "A LUI ACCLAMIAMO",
        },
        {
          notes: "              Re",
          text: "CON CANTI DI GIOIA",
        },
      ],
    },
  ],
  subtitle: "Sal 94(95)",
};
