import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Gesù percorreva tutte le città.",
  url_video: "https://www.youtube.com/embed/Y9xdOdjOxoo",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-         Fa              La-",
          text: "Gesù percorreva tutte le città,",
        },
        {
          notes: "                         Fa                   La-",
          text: "Gesù di villaggio in villaggio annunciava il regno.",
        },
        {
          notes: "        Re-                     Mi7",
          text: "Quando vide le folle che lo seguivano,",
        },
        {
          notes: "      La-",
          text: "si commosse.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Mi-",
          text: "Perché erano stanche,",
        },
        {
          notes: "                       Fa",
          text: "Perché erano abbattute,",
          msg: " (L'ass. ripete)",
        },
        {
          notes: "                     Mi",
          text: "come pecore senza pastore.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-      Fa                   Mi7",
          text: "Allora Gesù disse ai suoi discepoli:",
        },
        {
          notes: "La-                             Sol",
          text: "la messe è grande, è veramente grande",
        },
        {
          notes: "        La-         Sol",
          text: "ma gli operai sono pochi.",
        },
        {
          notes: "         Do                      Sol",
          text: "Pregate dunque il padrone della messe",
        },
        {
          notes: "    La-                      Sol",
          text: "di inviare operai nella sua messe.",
        },
        {
          notes: "Do                 Sol",
          text: "Ed ecco che io vi mando",
        },
        {
          notes: "       La-              Sol",
          text: "come pecore in mezzo ai lupi:",
        },
        {
          notes: "        Do               Sol",
          text: "siate dunque astuti come serpi",
        },
        {
          notes: "   La-            Sol",
          text: "e semplici come colombe,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "COME COLOMBE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                        Sol",
          text: "Non portate né borsa né denaro,",
        },
        {
          notes: "       La-        Sol",
          text: "né bisaccia né calzari",
        },
        {
          notes: "      Do                    Sol",
          text: "ed annunziate: il regno è vicino,",
        },
        {
          notes: "          La-              Sol",
          text: "Gesù, è risorto, viene con noi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "VIENE CON NOI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "E chi ama il padre o la madre più di me,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol                La-",
          text: "NON È DEGNO DI ME, NON È DEGNO DI ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "E chi ama il figlio o la figlia più di me,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol                La-",
          text: "NON È DEGNO DI ME, NON È DEGNO DI ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "E chi non prende la sua croce e mi segue,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Sol                La-",
          text: "NON È DEGNO DI ME, NON È DEGNO DI ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "E chi cerca nel mondo la sua vita,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol         La-",
          text: "LA PERDERÀ, LA PERDERÀ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "E chi perde la sua vita per amore mio,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      Sol           La",
          text: "LA RITROVERÀ, LA RITROVERÀ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "            Sol",
          text: "Chi ascolta voi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "ASCOLTA ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Sol",
          text: "Chi riceve voi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       La-",
          text: "RICEVE ME.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           Sol        Fa",
          text: "CHI RICEVE ME, RICEVE DIO,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi",
          text: "RICEVE DIO!",
        },
      ],
    },
  ],
  subtitle: "(Cfr. Mt 9, 35 ss; 10)",
};
