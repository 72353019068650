import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Non c'è in lui bellezza.",
  url_video: "https://www.youtube.com/embed/I9MJb_74Tt4",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Non c'è in lui bellezza,",
        },
        {
          notes: "                    La-",
          text: "non c'è in lui splendore,",
        },
        {
          notes: "                      Sol7",
          text: "che attiri i nostri sguardi",
        },
        {
          notes: "                          Do",
          text: "né aspetto che possa piacere.",
        },
        {
          notes: "                             La-",
          text: "Disprezzato, rifiuto degli uomini;",
        },
        {
          notes: "            Do                          La-",
          text: "uomo dei dolori, lui che conosce ogni miseria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           La-",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Sol7",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                          La-",
          text: "Maltrattato, considerato niente,",
        },
        {
          notes: "   Do                       La-",
          text: "disprezzato, rifiuto degli uomini,",
        },
        {
          notes: "            Do                          La-",
          text: "uomo dei dolori, lui che conosce ogni miseria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           La-",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Sol7",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                                La-",
          text: "Ma prese lui sopra di sé tutti i peccati,",
        },
        {
          notes: "         Do                        La-",
          text: "Ma prese lui sopra di sé tutti i dolori.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Fa                   Sol",
          text: "TUTTI NOI CAMMINAVAMO NELL'ERRORE.",
        },
        {
          notes: "       Do                     La-",
          text: "MALTRATTATO, MA LUI SI SOTTOMISE,",
        },
        {
          notes: "            Do",
          text: "NON APRÌ BOCCA,",
        },
        {
          notes: "                             La-",
          text: "COME L'AGNELLO CONDOTTO AL MACELLO.",
        },
        {
          notes: "Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           La-",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Sol7",
          text: "DAVANTI A LUI SI COPRE IL VOLTO,",
        },
        {
          notes: "                           Do",
          text: "DAVANTI A LUI SI COPRE IL VOLTO.",
        },
        {
          notes: "    Do",
          text: "MALTRATTATO! MALTRATTATO! MALTRATTATO!",
        },
      ],
    },
  ],
  subtitle: "(Is 53, 2 ss) IV CANTO DEL SERVO.",
};
