import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OPTION_ALLOW_COMMAND_BAR } from "../../Constants";
import { getOptions } from "../../Utils";
import CommandBar, { CommandBarButton } from "../CommandBar/CommandBar";
import "./PageLayout.scss";

export interface PageLayoutProps {
  title: string;
  content: JSX.Element;
  backButton?: string | boolean;
  toolbarButtons?: CommandBarButton[];
}

const tolbarShouldBeHidden = () => {
  return getOptions()[OPTION_ALLOW_COMMAND_BAR] ?? true;
};

const PageLayout = (props: PageLayoutProps) => {
  const navigate = useNavigate();
  const [toolbarHidden, setToolbarHidden] = useState<boolean>(
    !tolbarShouldBeHidden()
  );
  const [params] = useSearchParams();

  useEffect(() => {
    setToolbarHidden(!tolbarShouldBeHidden());
  }, [params]);

  const pageStyles = {
    maxWidth: "calc(100vw - 70px)",
    height: "calc(100vh - 48px)",
  };
  if (toolbarHidden) {
    pageStyles["height"] = "100vh";
  }

  return (
    <div className="page-layout-container">
      {props.toolbarButtons && !toolbarHidden && (
        <CommandBar
          buttons={props.toolbarButtons}
          emit={() => {
            setToolbarHidden(true);
          }}
        />
      )}
      <div
        className="page-layout-wrap"
        id="page-layout-node"
        style={props.toolbarButtons ? pageStyles : {}}
      >
        {props.title !== "" && (
          <div className="page-layout-title-wrap">
            {props.backButton && (
              <div className="page-layout-back-button page_title_color">
                <ArrowBackIosIcon
                  onClick={() => {
                    if (typeof props.backButton === "string") {
                      navigate(props.backButton);
                    } else {
                      window.history.back();
                    }
                  }}
                />
              </div>
            )}
            <div className="page-layout-title page_title_color">
              {props.title}
            </div>
          </div>
        )}
        <div className="page-layout-content text_color">{props.content}</div>
        <div className="page-layout-ending-padding"></div>
      </div>
    </div>
  );
};

export default PageLayout;
