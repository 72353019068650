import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Azzurro.",
  title: "Azzurro.",
  url_video: "https://www.youtube.com/embed/HCyzGuipTd4",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-       Mi7         La-",
          text: "Cerco lestate tutto lanno",
        },
        {
          notes: "Mi7        La-    Mi7     La-",
          text: "E allimprovviso eccola quà",
        },
        {
          notes: "Re-      La7          Re-",
          text: "Lei  partita per le spiagge",
        },
        {
          notes: "La7     Re-   La7             Re-",
          text: "e sono solo quaggi in città,",
        },
        {
          notes: "La         Mi7            La7",
          text: "Sento fischiare sopra I tetti",
        },
        {
          notes: "Fa#7     Si7    Mi7        La",
          text: "Un aereoplano che se ne va.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  La",
          text: "Azzurro.",
        },
        {
          notes: "                        Si7",
          text: "Il pomeriggio  troppo azzurro,",
        },
        {
          notes: "  Mi7    Si-  Mi7",
          text: "E lungo per me,",
        },
        {
          notes: "Mi7",
          text: "Mi accorgo",
        },
        {
          notes: "       La          Mi7",
          text: "Di non avere pi risorse",
        },
        {
          notes: "La     Mi7 La",
          text: "Senza di te,",
        },
        {
          notes: "    Re",
          text: "E allora",
        },
        {
          notes: "   La",
          text: "Io quasi quasi prendo il treno",
        },
        {
          notes: "  Re      Fa#7      Si7",
          text: "E vengo, vengo da te,",
        },
        {
          notes: "Mi7    La             Fa#-",
          text: "Ma il treno dei desideri,",
        },
        {
          notes: "Re-          La             Mi7     La",
          text: "Nei miei pensieri allincontrario va.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-          Mi7        La-",
          text: "Sembra quandero alloratorio",
        },
        {
          notes: "Mi7        La-    Mi7         La-",
          text: "Con tanto sole, tanti anni fa",
        },
        {
          notes: "Re-       La7         Re-",
          text: "Quelle domeniche da solo",
        },
        {
          notes: "La7       Re-   La7      Re-",
          text: "In un cortile a passeggiar",
        },
        {
          notes: "La        Mi7           La",
          text: "Ora mi annoio pi di allora",
        },
        {
          notes: "Fa#7        Si7    Mi7           La",
          text: "Neanche un prete per chiacchierar",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  La",
          text: "Azzurro.",
        },
        {
          notes: "                        Si7",
          text: "Il pomeriggio  troppo azzurro,",
        },
        {
          notes: "  Mi7    Si-  Mi7",
          text: "E lungo per me,",
        },
        {
          notes: "Mi7",
          text: "Mi accorgo",
        },
        {
          notes: "       La          Mi7",
          text: "Di non avere pi risorse",
        },
        {
          notes: "La     Mi7 La",
          text: "Senza di te,",
        },
        {
          notes: "    Re",
          text: "E allora",
        },
        {
          notes: "   La",
          text: "Io quasi quasi prendo il treno",
        },
        {
          notes: "  Re      Fa#7      Si7",
          text: "E vengo, vengo da te,",
        },
        {
          notes: "Mi7    La             Fa#-",
          text: "Ma il treno dei desideri,",
        },
        {
          notes: "Re-          La             Mi7     La",
          text: "Nei miei pensieri allincontrario va.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-       Mi7        La-",
          text: "Cerco un podafrica in giardino",
        },
        {
          notes: "Mi7      La-    Mi7      La-",
          text: "Tra loleandro e il Baobab,",
        },
        {
          notes: "Re-      La7        Re-",
          text: "Come facevo da bambino,",
        },
        {
          notes: "La7         Re-     La7        Re-",
          text: "Ma qui c'è gente, non si pu più ",
        },
        {
          notes: "La           Mi7            La",
          text: "Stanno innaffiando le tue rose,",
        },
        {
          notes: "Fa#7        Si7     Mi7",
          text: "Non c'è il leone, chiss dov'è",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  La",
          text: "Azzurro.",
        },
        {
          notes: "                        Si7",
          text: "Il pomeriggio  troppo azzurro,",
        },
        {
          notes: "  Mi7    Si-  Mi7",
          text: "E lungo per me,",
        },
        {
          notes: "Mi7",
          text: "Mi accorgo",
        },
        {
          notes: "       La          Mi7",
          text: "Di non avere pi risorse",
        },
        {
          notes: "La     Mi7 La",
          text: "Senza di te,",
        },
        {
          notes: "    Re",
          text: "E allora",
        },
        {
          notes: "   La",
          text: "Io quasi quasi prendo il treno",
        },
        {
          notes: "  Re      Fa#7      Si7",
          text: "E vengo, vengo da te,",
        },
        {
          notes: "Mi7    La             Fa#-",
          text: "Ma il treno dei desideri,",
        },
        {
          notes: "Re-          La             Mi7     La",
          text: "Nei miei pensieri allincontrario va.",
        },
      ],
    },
  ],
  subtitle: "Adriano Celentano",
  sent_for_revision: false,
};
