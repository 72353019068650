import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ave Maria.",
  url_video: "https://www.youtube.com/embed/_diOxn9xxi8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do      Mi  La-",
          text: "Ave Maria",
        },
        {
          notes: "Do       Mi     La-",
          text: "piena di grazia",
        },
        {
          notes: "Fa                      Mi",
          text: "il Signore è con te!",
        },
        {
          notes: "                         La-",
          text: "Benedetta sei tu fra le donne",
        },
        {
          notes: "     Re-",
          text: "e benedetto il frutto",
        },
        {
          notes: "",
          text: "del seno tuo,",
        },
        {
          notes: "Mi",
          text: "Gesù!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do        Mi  La-",
          text: "SANTA MARIA",
        },
        {
          notes: "Do       Mi  La-",
          text: "MADRE DI DIO,",
        },
        {
          notes: "Fa        Mi",
          text: "PREGA PER NOI,",
        },
        {
          notes: "               La-",
          text: "CHE SIAMO PECCATORI,",
        },
        {
          notes: "        Re-",
          text: "PREGA ADESSO",
        },
        {
          notes: "                        Mi",
          text: "E NELL'ORA DELLA NOSTRA MORTE.",
        },
        {
          notes: " La- Fa Mi     La-",
          text: "AMEN, AMEN, AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
