import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Voglio andare a gerusalemme.",
  url_video: "https://www.youtube.com/embed/BhTbSXcGXvc",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi               La-",
          text: "Voglio andare, madre",
        },
        {
          notes: "Re- Mi",
          text: "a      Gerusalemme, Gerusalemme.",
        },
        {
          notes: "La-",
          text: "A mangiar le erbe,",
        },
        {
          notes: "Re- Mi",
          text: "a      saziarmi d'esse, saziarmi d'esse.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                  La-",
          text: "NEL SENTIERO MI TROVO IO,",
        },
        {
          notes: "Fa                 La-",
          text: "NEL SENTIERO CAMMINO IO,",
        },
        {
          notes: "Re- Mi",
          text: "NEL    SENTIERO DI TUTTO IL MONDO",
        },
        {
          notes: "",
          text: "DI TUTTO IL MONDO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                  La-",
          text: "Fammi mangiar di esse,",
        },
        {
          notes: "Re- Mi",
          text: "che    possa saziare le mie ansie,",
        },
        {
          notes: "",
          text: "che sono ansie di amicizia,",
        },
        {
          notes: "",
          text: "che sono ansie di mitezza,",
        },
        {
          notes: "         Re- Mi",
          text: "che sono ansie,",
        },
        {
          notes: "",
          text: "che sono ansie di obbedienza.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                  La-",
          text: "NEL SENTIERO MI TROVO IO,",
        },
        {
          notes: "Fa                 La-",
          text: "NEL SENTIERO CAMMINO IO,",
        },
        {
          notes: "Re- Mi",
          text: "NEL    SENTIERO DI TUTTO IL MONDO",
        },
        {
          notes: "",
          text: "DI TUTTO IL MONDO.",
        },
        {
          notes: "La-",
          text: "LA LA, LA LA LA...",
        },
        {
          notes: "Mi",
          text: "LA LA, LA LA LA...",
        },
      ],
    },
  ],
  subtitle: "(Canto sefardita)",
};
