import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Tu sei la mia speranza Signore.",
  url_video: "https://www.youtube.com/embed/cPw6UMXCgqM",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#°",
          text: "Tu sei la mia speranza Signore,",
        },
        {
          notes: "Sib7         La7",
          text: "e non sarò deluso.",
        },
        {
          notes: "Do#°",
          text: "Con la tua gloria tu ci hai creato,",
        },
        {
          notes: "            Sib7             La7",
          text: "nella misericordia ci hai allevato.",
        },
        {
          notes: " Re-                              La",
          text: "hai tratto dal profondo dello sheol,",
        },
        {
          notes: "                   Sib7             La",
          text: "dalla bocca della morte mi hai strappato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                              La",
          text: "MI HAI TRATTO DAL PROFONDO DELLO SHEOL,",
        },
        {
          notes: "                   Sib7             La",
          text: "DALLA BOCCA DELLA MORTE MI HAI STRAPPATO.",
        },
        {
          notes: " Re-                       La",
          text: "PERCHÉ HO CREDUTO NEL TUO MESSIA",
        },
        {
          notes: "      Sib7                      La",
          text: "E CHE LUI È IL SIGNORE HO CONFESSATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                    La",
          text: "Tu mi hai dato il segno grande,",
        },
        {
          notes: "Sol-                     La",
          text: "il mistero nascosto agli angeli,",
        },
        {
          notes: "          Sib7              La7",
          text: "per fare guerra con la tua parola,",
        },
        {
          notes: "              Sib7           La",
          text: "e riportare vittoria dalle nazioni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                              La",
          text: "MI HAI TRATTO DAL PROFONDO DELLO SHEOL,",
        },
        {
          notes: "                   Sib7             La",
          text: "DALLA BOCCA DELLA MORTE MI HAI STRAPPATO.",
        },
        {
          notes: " Re-                       La",
          text: "PERCHÉ HO CREDUTO NEL TUO MESSIA",
        },
        {
          notes: "      Sib7                      La",
          text: "E CHE LUI È IL SIGNORE HO CONFESSATO.",
        },
        {
          notes: "  Re-                     La",
          text: "TU SEI LA MIA SPERANZA, SIGNORE,",
        },
        {
          notes: "  Sib7         La",
          text: "E NON SARÒ DELUSO!",
        },
        {
          notes: "   Re-                     La",
          text: "TU SEI LA MIA SPERANZA, SIGNORE,",
        },
        {
          notes: "  Sib7         La",
          text: "E NON SARÒ DELUSO!",
        },
      ],
    },
  ],
  subtitle: "(Ode XXIX di Salomone)",
};
