import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Sola a solo.",
  url_video: "https://www.youtube.com/embed/RDLkx16c0Ds",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-     Sol-",
          text: "Sola a solo,",
        },
        {
          notes: "          La7",
          text: "sotto la croce,",
        },
        {
          notes: "                    Re-",
          text: "Maria, chi ti potrà separar.",
        },
        {
          notes: "        Sol-",
          text: "Vergine sola,",
        },
        {
          notes: "La7             Re",
          text: "Madre, torre trapassata",
        },
        {
          notes: "             Sol",
          text: "colonna di amore,",
        },
        {
          notes: "              La",
          text: "Tu sostieni il cielo",
        },
        {
          notes: "                 Re",
          text: "e la nostra poca fede.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-     Do.1",
          text: "Sola a solo,",
        },
        {
          notes: "              Fa",
          text: "donna piena di Dio,",
        },
        {
          notes: "        La",
          text: "prega per noi,",
        },
        {
          notes: "    Sib        La",
          text: "che siamo peccatori.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-     Sol-",
          text: "Sola a solo,",
        },
        {
          notes: "          La7",
          text: "sotto la croce,",
        },
        {
          notes: "                    Re-",
          text: "Maria, chi ti potrà separar.",
        },
        {
          notes: "        Sol-",
          text: "Vergine sola,",
        },
        {
          notes: "La7             Re",
          text: "Madre, torre trapassata",
        },
        {
          notes: "             Sol",
          text: "colonna di amore,",
        },
        {
          notes: "              La",
          text: "Tu sostieni il cielo",
        },
        {
          notes: "                 Re",
          text: "e la nostra poca fede.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-     Do.1",
          text: "Sola a solo,",
        },
        {
          notes: "              Fa",
          text: "donna piena di Dio,",
        },
        {
          notes: "        La",
          text: "prega per noi,",
        },
        {
          notes: "    Sib        La",
          text: "che siamo peccatori.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-     Sol-",
          text: "Sola a Solo",
        },
        {
          notes: "        La7",
          text: "bajo la Cruz",
        },
        {
          notes: "               Re-",
          text: "María, quién te podrá consolar",
        },
        {
          notes: "Sol-         La7            Re-",
          text: "Virgen sola, Madre, Torre atravesada",
        },
        {
          notes: "           Sol-",
          text: "Columna de amor,",
        },
        {
          notes: "             La",
          text: "Tu sujetas el cielo ",
        },
        {
          notes: "              Re-",
          text: "de nuestra poca fe.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-     Do.1",
          text: "Sola a solo,",
        },
        {
          notes: "              Fa",
          text: "Mujer llena de Dios,",
        },
        {
          notes: "        La",
          text: "Ruega por nosotros",
        },
        {
          notes: "    Sib        La",
          text: "que somos pecadores.",
        },
      ],
    },
  ],
  subtitle: "(amsterdam 30 apr 2005) preliminary release",
};
