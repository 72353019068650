import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Vedo i cieli aperti.",
  url_video: "https://www.youtube.com/embed/qDTxXvSNI3E",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Mi",
          text: "Vedo i cieli aperti",
        },
        {
          notes: "               Fa",
          text: "e un cavallo bianco",
        },
        {
          notes: "        Sol           Fa",
          text: "chi lo monta porta un manto",
        },
        {
          notes: "            Fa",
          text: "pieno di sangue, pieno di sangue.",
        },
        {
          notes: "  Mi",
          text: "I suoi occhi",
        },
        {
          notes: "           Fa",
          text: "fiamma di fuoco",
        },
        {
          notes: "           Sol       Fa",
          text: "nella sua bocca una spada",
        },
        {
          notes: "     Mi",
          text: "per ferire, per ferire.",
        },
        {
          notes: "Do",
          text: "Qual'è il suo nome?",
        },
        {
          notes: "",
          text: "Qual'è il suo nome?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Mi",
          text: "IL SUO NOME È",
        },
        {
          notes: "",
          text: "IL SUO NOME È",
        },
        {
          notes: "           La-",
          text: "PAROLA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Qual'è il suo nome?",
        },
        {
          notes: "",
          text: "Qual'è il suo nome?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Mi",
          text: "IL SUO NOME È",
        },
        {
          notes: "",
          text: "IL SUO NOME È",
        },
        {
          notes: "           La-",
          text: "PAROLA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Mi",
          text: "Vedo una bestia",
        },
        {
          notes: "        Fa",
          text: "e un profeta",
        },
        {
          notes: "            Sol",
          text: "che si riuniscono",
        },
        {
          notes: "               Fa",
          text: "per un combattimento",
        },
        {
          notes: "               Mi",
          text: "per un combattimento,",
        },
        {
          notes: " Mi",
          text: "contro colui che monta sul cavallo",
        },
        {
          notes: "            Sol",
          text: "e porta il manto",
        },
        {
          notes: "          Fa                Mi",
          text: "pieno di sangue, pieno di sangue.",
        },
        {
          notes: "  La-",
          text: "Lui calpesta",
        },
        {
          notes: "",
          text: "da solo nel tino",
        },
        {
          notes: "        Mi",
          text: "della furiosa",
        },
        {
          notes: "           La-",
          text: "collera di Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "LUI CALPESTA",
        },
        {
          notes: "",
          text: "DA SOLO NEL TINO",
        },
        {
          notes: "        Mi",
          text: "DELLA FURIOSA",
        },
        {
          notes: "           La-",
          text: "COLLERA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                  Mi",
          text: "Ecco la bestia è catturata",
        },
        {
          notes: "                    La-",
          text: "e con essa il suo profeta",
        },
        {
          notes: "                         Mi",
          text: "da colui che monta il cavallo",
        },
        {
          notes: "                            La-",
          text: "e porta il manto pieno di sangue.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Mi",
          text: "ALLELUJA, ALLELUJA",
        },
        {
          notes: "              La-",
          text: "ALLELUJA, ALLELUJA",
        },
        {
          notes: "              Mi        La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Qual'è il suo nome?",
        },
        {
          notes: "",
          text: "Qual'è il suo nome?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Mi",
          text: "IL SUO NOME È",
        },
        {
          notes: "",
          text: "IL SUO NOME È",
        },
        {
          notes: "           La-",
          text: "PAROLA DI DIO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "Qual'è il suo nome?",
        },
        {
          notes: "",
          text: "Qual'è il suo nome?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "            Mi",
          text: "IL SUO NOME È",
        },
        {
          notes: "",
          text: "IL SUO NOME È",
        },
        {
          notes: "           La-",
          text: "PAROLA DI DIO.",
        },
      ],
    },
  ],
  subtitle: "(Ap 19, 11-20)",
};
