import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "La voce del mio amato.",
  url_video: "https://www.youtube.com/embed/_FGYq_WaI5E",
  barrè: "IV",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                  La-           Fa",
          text: "La voce del mio amato! Eccolo che viene",
        },
        {
          notes: "                Mi       Fa               Mi",
          text: "saltando per i monti, balzando per le colline.",
        },
        {
          notes: "Do                                    La-",
          text: "Somiglia il mio amato a una gazzella,",
        },
        {
          notes: "                   Fa",
          text: "o a un giovane cerbiatto,",
        },
        {
          notes: "                               Mi",
          text: "somiglia il mio amato a una gazzella.",
        },
        {
          notes: "Do                   La-              Fa",
          text: "Eccolo che si ferma dietro il nostro muro,",
        },
        {
          notes: "                                           Mi",
          text: "guarda dalla finestra, spia attraverso la grata.",
        },
        {
          notes: "Do",
          text: "Ora parla il mio amato e mi dice:",
        },
        {
          notes: "Do             Mi   Fa                  Mi",
          text: "Alzati, amica mia, alzati, mia bella e vieni!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do             Mi   Fa                  Mi",
          text: "ALZATI, AMICA MIA, ALZATI, MIA BELLA E VIENI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                        Mi",
          text: "Perché ecco l'inverno è passato,",
        },
        {
          notes: "Re-                           Mi",
          text: "è cessata la pioggia, se n'è andata,",
        },
        {
          notes: "Fa",
          text: "i fiori sono apparsi sulla terra,",
        },
        {
          notes: "                       Mi",
          text: "il tempo del canto è tornato,",
        },
        {
          notes: "Re                         Mi",
          text: "la voce della tortora si fa sentire,",
        },
        {
          notes: "Fa",
          text: "il fico ha messo fuori i primi frutti",
        },
        {
          notes: "                                    Mi",
          text: "e la vite fiorita spande la sua fragranza!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "ALZATI, AMICA MIA, ALZATI, MIA BELLA E VIENI!",
        },
        {
          notes: "La-",
          text: "ALZATI, AMICA MIA, ALZATI, MIA BELLA E VIENI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                                         Mi",
          text: "O mia colomba che stai nelle fenditure della roccia,",
        },
        {
          notes: "Re-                   Mi",
          text: "nei nascondigli dei dirupi,",
        },
        {
          notes: "Fa                                          Mi",
          text: "fammi udire la tua voce, fammi udire la tua voce!",
        },
        {
          notes: "  Do                      Mi",
          text: "Cacciate via le volpi, le volpi piccoline,",
        },
        {
          notes: "    Fa           Mi",
          text: "che guastano la vigna,",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol               Fa         Mi",
          text: "PERCHÉ LA NOSTRA VIGNA È IN FIORE,",
        },
        {
          notes: "Sol               Fa         Mi",
          text: "PERCHÉ LA NOSTRA VIGNA È IN FIORE!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                 Mi Re-           Mi",
          text: "Il mio diletto è per me ed io sono per lui;",
        },
        {
          notes: "Re-                  Mi",
          text: "prima che soffi la brezza",
        },
        {
          notes: "Re-                Mi",
          text: "e si allunghino le ombre,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La-      Sol      La- ",
          text: "RITORNA, RITORNA, RITORNA!",
        },
        {
          notes: "  La-      Sol      La- ",
          text: "RITORNA, RITORNA, RITORNA!",
        },
      ],
    },
  ],
  subtitle: "(Ct 2, 8-17)",
};
