import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Servo_per_amore.",
  title: "Servo per amore.",
  url_video: "https://www.youtube.com/embed/6DYAaUYVYpI",
  barrè: "2",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Una notte di sudore",
        },
        {
          notes: "                    Sol",
          text: "sulla barca in mezzo al mare",
        },
        {
          notes: "        Re                Si-",
          text: "e mentre il cielo si imbianca già,",
        },
        {
          notes: "      Do          Mi-         ",
          text: "tu guardi le tue reti vuote.",
        },
        {
          notes: "  Sol",
          text: "Ma la voce che ti chiama",
        },
        {
          notes: "     Re            Sol",
          text: "un altro mare ti mostrerà",
        },
        {
          notes: "    Do           Sol",
          text: "e sulle rive di ogni cuore,",
        },
        {
          notes: "   La-  Do   Sol",
          text: "le tue reti getterai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Sol     Re",
          text: "Offri la vita tua come Maria",
        },
        {
          notes: "Si-         Do",
          text: "ai piedi della croce",
        },
        {
          notes: "Mi-     Sol           Re",
          text: "e sarai servo di ogni uomo,",
        },
        {
          notes: "Sol        Do",
          text: "servo per amore,",
        },
        {
          notes: "Sol       La-   Do  Sol   ",
          text: "sacerdote dell'umanità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Avanzavi nel silenzio",
        },
        {
          notes: "                Sol",
          text: "fra le lacrime e speravi",
        },
        {
          notes: "        Re               Si-",
          text: "che il seme sparso davanti a Te",
        },
        {
          notes: "    Do          Mi-",
          text: "cadesse sulla buona terra.",
        },
        {
          notes: "   Sol",
          text: "Ora il cuore tuo è in festa",
        },
        {
          notes: "      Re                 Sol",
          text: "perché il grano biondeggia ormai,",
        },
        {
          notes: "  Do            Sol",
          text: "è maturato sotto il sole,",
        },
        {
          notes: "   La-   Do     Sol",
          text: "puoi riporlo nei granai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Sol     Re",
          text: "Offri la vita tua come Maria",
        },
        {
          notes: "Si-         Do",
          text: "ai piedi della croce",
        },
        {
          notes: "Mi-     Sol           Re",
          text: "e sarai servo di ogni uomo,",
        },
        {
          notes: "Sol        Do",
          text: "servo per amore,",
        },
        {
          notes: "Sol       La-   Do  Sol   ",
          text: "sacerdote dell'umanità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Sol     Re",
          text: "Offri la vita tua come Maria",
        },
        {
          notes: "Si-         Do",
          text: "ai piedi della croce",
        },
        {
          notes: "Mi-     Sol           Re",
          text: "e sarai servo di ogni uomo,",
        },
        {
          notes: "Sol        Do",
          text: "servo per amore,",
        },
        {
          notes: "Sol       La-   Do  Sol   ",
          text: "sacerdote dell'umanità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Sol     Re",
          text: "Offri la vita tua come Maria",
        },
        {
          notes: "Si-         Do",
          text: "ai piedi della croce",
        },
        {
          notes: "Mi-     Sol           Re",
          text: "e sarai servo di ogni uomo,",
        },
        {
          notes: "Sol        Do",
          text: "servo per amore,",
        },
        {
          notes: "Sol       La-   Do  Sol   ",
          text: "sacerdote dell'umanità.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
