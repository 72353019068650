import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ho sperato, ho sperato nel Signore.",
  url_video: "https://www.youtube.com/embed/S7vlUj0q-QI",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                             Mi-",
          text: "Mi ha tratto dalla fossa della morte,",
        },
        {
          notes: "     La-          Mi-",
          text: "dal fango della palude,",
        },
        {
          notes: "       Fa                       Mi",
          text: "ha stabilito i miei piedi sulla roccia,",
        },
        {
          notes: "   Fa                  Mi",
          text: "ha reso sicuri i miei passi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                             Mi-",
          text: "Mi ha messo sulla bocca un canto nuovo,",
        },
        {
          notes: "     La-              Mi-",
          text: "una lode, una lode al nostro Dio;",
        },
        {
          notes: "      Fa                  Mi",
          text: "molti vedranno e avranno timore,",
        },
        {
          notes: "   Fa              Mi",
          text: "confideranno nel Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                        Mi-",
          text: "Beato l'uomo che spera nel Signore,",
        },
        {
          notes: "     La-                 Mi-",
          text: "e non si mette dalla parte dei superbi",
        },
        {
          notes: "       Fa                   Mi",
          text: "né si volge a chi segue la menzogna,",
        },
        {
          notes: "     Fa                Mi",
          text: "beato l'uomo che spera nel Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                        Mi-",
          text: "Quanti prodigi hai fatto o Signore,",
        },
        {
          notes: "     La-                 Mi-",
          text: "quanti disegni in nostro favore:",
        },
        {
          notes: "      Fa                Mi",
          text: "nessuno a te si può paragonare,",
        },
        {
          notes: "              Fa                             Mi",
          text: "se li voglio annunziare sono troppi per essere contati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Mi-",
          text: "Sacrificio ed olocausto tu non vuoi;",
        },
        {
          notes: "     La-                  Mi-",
          text: "per questo hai aperto il mio orecchio.",
        },
        {
          notes: "       Fa                      Mi",
          text: "Non hai voluto vittima per la colpa",
        },
        {
          notes: "       Fa",
          text: "per questo ho detto: “ecco che vengo",
        },
        {
          notes: "                   Mi",
          text: "per fare la tua volontà“.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                      Mi-",
          text: "SACRIFICIO ED OLOCAUSTO TU NON VUOI:",
        },
        {
          notes: "     La-                  Mi-",
          text: "PER QUESTO HAI APERTO IL MIO ORECCHIO.",
        },
        {
          notes: "       Fa                      Mi",
          text: "NON HAI VOLUTO VITTIMA PER LA COLPA",
        },
        {
          notes: "       Fa",
          text: "PER QUESTO HO DETTO: “ECCO CHE VENGO",
        },
        {
          notes: "                   Mi",
          text: "PER FARE LA TUA VOLONTÀ“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                          Mi-",
          text: "Sul rotolo del libro è scritto di me,",
        },
        {
          notes: "     La-           Mi-",
          text: "di compiere la tua volontà;",
        },
        {
          notes: "     Fa              Mi",
          text: "mio Dio, questo io desidero:",
        },
        {
          notes: "     Fa                     Mi",
          text: "la tua legge nel profondo del mio cuore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Mi-",
          text: "HO SPERATO, HO SPERATO NEL SIGNORE",
        },
        {
          notes: "   La-                    Mi-",
          text: "ED EGLI SU DI ME SI È CHINATO,",
        },
        {
          notes: "         Fa             Mi",
          text: "HA DATO ASCOLTO AL MIO GRIDO,",
        },
        {
          notes: "       Fa                        Mi",
          text: "MI HA TRATTO DALLA FOSSA DELLA MORTE.",
        },
      ],
    },
  ],
  subtitle: "Sal 39(40)",
};
