import { OPTION_ALLOW_EXTRA_SONGS } from "../Constants";
import { getCustomSongs, getOptions } from "../Utils";
import { SongBlock, SongBlockLines, SongElement } from "./SongElement";
import { AppSongs } from "./SongIndex";

const ExposedSongs: SongElement[] = [];

export const initExposedSongs = () => {
  // clear array
  ExposedSongs.splice(0, ExposedSongs.length);

  for (let i = 0; i < AppSongs.length; i++) {
    let song: SongElement = AppSongs[i];
    let processedSong: SongElement = { ...song };
    processedSong.conainedText = processedSong.blocks
      .map((x: SongBlock) => {
        return x.lines
          .map((y: SongBlockLines) => {
            return y.text;
          })
          .join(" ");
      })
      .join(" ");
    ExposedSongs.push(processedSong);
  }

  let customSongs: SongElement[] = getCustomSongs();

  for (let i = 0; i < customSongs.length; i++) {
    let song: SongElement = customSongs[i];
    let processedSong: SongElement = { ...song };
    processedSong.conainedText = processedSong.blocks
      .map((x: SongBlock) => {
        return x.lines
          .map((y: SongBlockLines) => {
            return y.text;
          })
          .join(" ");
      })
      .join(" ");
    ExposedSongs.push(processedSong);
  }
};

export const getAppSongs = () => {
  if (ExposedSongs.length === 0) {
    initExposedSongs();
  }

  if (getOptions()[OPTION_ALLOW_EXTRA_SONGS] ?? false) {
    return ExposedSongs;
  }

  return ExposedSongs.filter((x: SongElement) => x.semantic !== "extra");
};

export const getAppSong = (id: string) => {
  id = id.toLocaleLowerCase();
  id = decodeURI(id);
  let titleId = id.replaceAll("_", " ");
  let matches: SongElement[] = getAppSongs().filter(
    (x: SongElement) =>
      x.title.toLocaleLowerCase() === titleId ||
      x.id?.toLocaleLowerCase() === id
  );
  if (matches.length === 0) {
    return null;
  }
  return matches[0];
};
