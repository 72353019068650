import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Quando_Israele_uscì_dall'egitto.",
  title: "Quando Israele uscì dall'egitto.",
  url_video: "https://www.youtube.com/embed/Jny2rJEOh_0",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       Fa",
          text: "Quando Israele",
        },
        {
          notes: "            Mi-",
          text: "uscì dall'Egitto,",
        },
        {
          notes: "              Fa",
          text: "la casa di Giacobbe",
        },
        {
          notes: "               Mi-",
          text: "da un popolo barbaro,",
        },
        {
          notes: "Mi                  Fa",
          text: "Giuda fu il suo santuario,",
        },
        {
          notes: "                 Mi",
          text: "Israele il suo dominio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                  Fa",
          text: "GIUDA FU IL SUO SANTUARIO",
        },
        {
          notes: "                 Mi",
          text: "ISRAELE IL SUO DOMINIO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Lo vide il mare e fuggì,",
        },
        {
          notes: "        Sol                        Fa#   Fa",
          text: "e il Giordano si volse indietro:",
        },
        {
          notes: "",
          text: "i monti saltellaron come arieti,",
        },
        {
          notes: "                 Mi",
          text: "le colline come agnelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "      La-",
          text: "PERCHÉ MARE, TU FUGGI,",
        },
        {
          notes: "        Sol                        Fa#   Fa",
          text: "E TU GIORDANO TI VOLGI INDIETRO?",
        },
        {
          notes: "",
          text: "E VOI MONTI SALTELLATE COME ARIETI,",
        },
        {
          notes: "                 Mi",
          text: "VOI COLLINE COME AGNELLI?",
        },
        {
          notes: "La",
          text: "TREMA, TERRA, TREMA",
        },
        {
          notes: "          Fa#      Si-",
          text: "DAVANTI A DIO CHE PASSA,",
        },
        {
          notes: "Re                   La",
          text: "DAVANTI AL DIO DI GIACOBBE,",
        },
        {
          notes: "        Sol                  Fa#",
          text: "CHE FA USCIRE DALLA ROCCIA L'ACQUA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#-      Sol",
          text: "Quando Israele",
        },
        {
          notes: "            Fa#-",
          text: "uscì dall'Egitto,",
        },
        {
          notes: "              Sol",
          text: "la casa di Giacobbe",
        },
        {
          notes: "               Fa#-",
          text: "da un popolo barbaro,",
        },
        {
          notes: "Fa#                  Sol",
          text: "Giuda fu il suo santuario,",
        },
        {
          notes: "                 Fa#",
          text: "Israele il suo dominio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                  Fa",
          text: "GIUDA FU IL SUO SANTUARIO",
        },
        {
          notes: "                 Mi",
          text: "ISRAELE IL SUO DOMINIO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "Lo vide il mare e fuggì,",
        },
        {
          notes: "         La                       Sol#   Sol",
          text: "e il Giordano si volse indietro:",
        },
        {
          notes: "",
          text: "i monti saltellaron come arieti,",
        },
        {
          notes: "                  Fa#",
          text: "le colline come agnelli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "      Si-",
          text: "PERCHÉ MARE, TU FUGGI,",
        },
        {
          notes: "         La                       Sol#   Sol",
          text: "E TU GIORDANO TI VOLGI INDIETRO?",
        },
        {
          notes: "",
          text: "E VOI MONTI SALTELLATE COME ARIETI,",
        },
        {
          notes: "                   Fa#",
          text: "VOI COLLINE COME AGNELLI?",
        },
        {
          notes: "Si",
          text: "TREMA, TERRA, TREMA",
        },
        {
          notes: "          Sol#     Do#-",
          text: "DAVANTI A DIO CHE PASSA,",
        },
        {
          notes: "Mi                   Si",
          text: "DAVANTI AL DIO DI GIACOBBE,",
        },
        {
          notes: "        La                   Sol#",
          text: "CHE FA USCIRE DALLA ROCCIA L'ACQUA.",
        },
      ],
    },
  ],
  subtitle: "Sal 113A(114)",
  sent_for_revision: false,
};
