import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalDialog.scss";

export interface ModalDialogButton {
  label: string;
  onClick: () => void;
}

export interface ModalDialogProps {
  isOpen: boolean;
  content: JSX.Element | string;
  title: string;
  actionButtons: ModalDialogButton[];
  onClose: () => void;
}

const ModalDialog = (props: ModalDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="modal-dialog-title">
          <div>{props.title}</div>
          <div className="modal-dialog-close-icon" onClick={props.onClose}>
            <CloseIcon />
          </div>
        </DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>
          {props.actionButtons.map((x: ModalDialogButton, i: number) => {
            return (
              <Button key={i} onClick={x.onClick}>
                {x.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDialog;
