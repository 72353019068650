import PageLayout from "../../Components/PageLayout/PageLayout";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import "./PrivacyScreen.scss";

const PrivacyScreen = () => {
  return (
    <PageLayout
      backButton
      title={"Privacy"}
      content={
        <div>
          <TitledPageSection
            title={"Uso come utente registrato"}
            content={
              <div>
                <strong>
                  Questa funzionalità è al momento sospesa per manutenzione.
                </strong>
                <br />
                Come risultato della registrazione a questo sito, l’indirizzo
                email dell’utente viene automaticamente inserito in una lista di
                contatti a cui potranno essere trasmessi messaggi email
                contenenti informazioni strettamente inerenti al sito, ad
                esempio a seguito di richieste di pubblicazione dei contenuti
                effettuate da parte dell'utente.
              </div>
            }
          />
          <TitledPageSection
            title={"Terze parti: Facebook"}
            content={
              <div>
                Pulsante Visita di Facebook (Facebook, Inc.). Il pulsante
                “Visita” è un servizio di interazione con il social network
                Facebook, fornito da Facebook, Inc. Dati Personali raccolti:
                Cookie e Dati di utilizzo.{" "}
                <a
                  href="https://www.facebook.com/privacy/explanation"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  Privacy Policy
                </a>
              </div>
            }
          />
          <TitledPageSection
            title={"Terze parti: YouTube"}
            content={
              <div>
                I nostri servizi online utilizzano la piattaforma video di
                YouTube gestita da YouTube, LLC, 901 Cherry Ave. San Bruno, CA
                94066, USA (“YouTube”). YouTube è una piattaforma che consente
                la riproduzione di file audio e video: accedendo ad uno dei
                nostri servizi online che contiene un player YouTube, questo
                ultimo crea una connessione con YouTube, in modo che il file
                video o audio possa essere trasmesso e riprodotto. In tal modo,
                i dati vengono trasferiti su YouTube che opera in qualità di
                Titolare del trattamento. Spartiti dei Canti Neocatecumenali non
                è responsabile per il trattamento di tali dati da parte di
                YouTube. Ulteriori informazioni sull’ambito di applicazione e
                sulle finalità del trattamento dei dati raccolti, sull’ulteriore
                elaborazione e trattamento dei dati da parte di YouTube, sui
                diritti dell’Utente e sulle impostazioni privacy, sono
                disponibili nell’informativa contenente i termini di servizio di
                YouTube.{" "}
                <a
                  href="https://www.youtube.com/t/terms"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  Privacy Policy
                </a>
              </div>
            }
          />
          <TitledPageSection
            title={"Terze parti: Buy me a Coffee"}
            content={
              <div>
                Usiamo questo servizio per rendere possibile in modo semplice e
                diretto il fornire supporto finanziario al nostro team tramite
                il portale di “Buy me a Coffee”. Questa piattaforma permette di
                sostenere con piccole donazioni vari creatori di contenuti.{" "}
                <a
                  href="https://www.buymeacoffee.com/privacy-policy"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  Privacy Policy
                </a>
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default PrivacyScreen;
