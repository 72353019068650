import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alleluja per acclamazioni al vangelo.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker:
        "Si fa l'Acclamazione prevista dal Lezionario con una delle seguenti melodie: ",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Sol La7  Re ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA,    ALLELUJA",
        },
        {
          notes: "Re                       Sol La7  Re ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA,    ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "bar-from-ast-message",
      lines: [
        {
          notes: "Si-                    La                 Si-",
          text: "Vi dò un comandamento nuovo, dice il Signore: ",
        },
        {
          notes: "                 La                 Si-",
          text: "che vi amiate a vicenda come io ho amato voi,",
        },
        {
          notes: "                 La                 Si-",
          text: "che vi amiate a vicenda come io ho amato voi.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re                       Sol La7  Re ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA,    ALLELUJA",
        },
        {
          notes: "Re                       Sol La7  Re ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA,    ALLELUJA",
        },
      ],
    },
    {
      speaker: "oppure: Barrè al III tasto",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Re         Mi- ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA",
        },
        {
          notes: "Mi-           Re         Mi- ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "bar-from-ast-message",
      lines: [
        {
          notes: "Si-                    La                 Si-",
          text: "Vi dò un comandamento nuovo, dice il Signore: ",
        },
        {
          notes: "                 La                 Si-",
          text: "che vi amiate a vicenda come io ho amato voi,",
        },
        {
          notes: "                 La                 Si-",
          text: "che vi amiate a vicenda come io ho amato voi.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Re         Mi- ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA",
        },
        {
          notes: "Mi-           Re         Mi- ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker:
        "Questo è un versetto di esempio; per gli altri versetti, previsti di volta in volta dal Lezionario, seguire la stessa base musicale.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "",
};
