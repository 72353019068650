import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il Signore è mia luce e mia salvezza.",
  url_video: "https://www.youtube.com/embed/0NTfSv-cFmk",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              Sol          Re-",
          text: "Il Signore è mia luce e mia salvezza:",
        },
        {
          notes: " Sib    Do   Re-",
          text: "cosa potrò temere?",
        },
        {
          notes: "  Re-            Sol            Re-",
          text: "Il Signore è sostegno alla mia vita:",
        },
        {
          notes: " Sib     Do    Re-",
          text: "chi mi farà tremare?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "NE SONO CERTO, VEDRÒ",
        },
        {
          notes: "         Sol-",
          text: "LA TUA BONTÀ MIO SIGNOR.",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Sol          Re-",
          text: "Una cosa domando al mio Signore,",
        },
        {
          notes: " Sib    Do   Re-",
          text: "una soltanto cerco:",
        },
        {
          notes: "  Re-         Sol            Re-",
          text: "d'abitar nella casa del Signore",
        },
        {
          notes: " Sib     Do    Re-",
          text: "per tutta la mia vita.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "NE SONO CERTO, VEDRÒ",
        },
        {
          notes: "         Sol-",
          text: "LA TUA BONTÀ MIO SIGNOR.",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-          Sol          Re-",
          text: "Il Signore mi mette al suo riparo",
        },
        {
          notes: " Sib      Do   Re-",
          text: "nei giorni della lotta.",
        },
        {
          notes: "  Re-       Sol            Re-",
          text: "Il Signore mi tende la sua mano",
        },
        {
          notes: " Sib     Do    Re-",
          text: "camminerò sicuro.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "NE SONO CERTO, VEDRÒ",
        },
        {
          notes: "         Sol-",
          text: "LA TUA BONTÀ MIO SIGNOR.",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "La7",
          text: "SPERA NEL SIGNORE, CORAGGIO",
        },
        {
          notes: "Re-",
          text: "SPERA NEL SIGNORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 26(27)",
};
