import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Davanti agli angeli.",
  url_video: "https://www.youtube.com/embed/SLQkXEoOm_8",
  barrè: "V",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Mi                     Re-",
          text: "DAVANTI AGLI ANGELI A TE VOGLIO CANTARE,",
        },
        {
          notes: "Sol                               Mi",
          text: "DAVANTI AGLI ANGELI VOGLIO SALMODIARE;",
        },
        {
          notes: "                    Re-",
          text: "RENDO GRAZIE AL TUO NOME",
        },
        {
          notes: "       Fa                    Mi",
          text: "PER LA TUA FEDELTÀ E IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do              Mi                     Re-",
          text: "Ti rendo grazie, Signore, con tutto il cuore",
        },
        {
          notes: "Sol                                       Mi",
          text: "perché la tua promessa ha superato la tua fama;",
        },
        {
          notes: "                                   Re-",
          text: "nel giorno in cui gridai tu mi hai risposto,",
        },
        {
          notes: "   Fa                            Mi",
          text: "hai dato forza e vigore alla mia anima.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Mi                     Re-",
          text: "DAVANTI AGLI ANGELI A TE VOGLIO CANTARE,",
        },
        {
          notes: "Sol                               Mi",
          text: "DAVANTI AGLI ANGELI VOGLIO SALMODIARE;",
        },
        {
          notes: "                    Re-",
          text: "RENDO GRAZIE AL TUO NOME",
        },
        {
          notes: "       Fa                    Mi",
          text: "PER LA TUA FEDELTÀ E IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do            Mi                        Re-",
          text: "Ti loderanno, Signore, tutti i re della terra",
        },
        {
          notes: "Sol                                Mi",
          text: "quando udranno le parole della tua bocca",
        },
        {
          notes: "                           Re-",
          text: "e cantando nel cammino del Signore",
        },
        {
          notes: "       Fa                     Mi",
          text: "diranno quanto è grande il tuo amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Mi                     Re-",
          text: "DAVANTI AGLI ANGELI A TE VOGLIO CANTARE,",
        },
        {
          notes: "Sol                               Mi",
          text: "DAVANTI AGLI ANGELI VOGLIO SALMODIARE;",
        },
        {
          notes: "                    Re-",
          text: "RENDO GRAZIE AL TUO NOME",
        },
        {
          notes: "       Fa                    Mi",
          text: "PER LA TUA FEDELTÀ E IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do              Mi                  Re-",
          text: "Il Signore è sublime e guarda verso l'umile,",
        },
        {
          notes: "Sol                               Mi",
          text: "conosce il superbo, lo guarda da lontano;",
        },
        {
          notes: "                 Re-",
          text: "se cammino nella sventura",
        },
        {
          notes: "   Fa                            Mi",
          text: "stendi la mano, la tua destra mi salva.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Mi                     Re-",
          text: "DAVANTI AGLI ANGELI A TE VOGLIO CANTARE,",
        },
        {
          notes: "Sol                               Mi",
          text: "DAVANTI AGLI ANGELI VOGLIO SALMODIARE;",
        },
        {
          notes: "                    Re-",
          text: "RENDO GRAZIE AL TUO NOME",
        },
        {
          notes: "       Fa                    Mi",
          text: "PER LA TUA FEDELTÀ E IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do            Mi                  Re-",
          text: "Il Signore completerà in me l'opera sua",
        },
        {
          notes: "Sol                             Mi",
          text: "perché grande ed eterno è il suo amore;",
        },
        {
          notes: "                                Re-",
          text: "non abbandona l'opera delle sue mani.",
        },
        {
          notes: "    Fa                  Mi",
          text: "Signore non ci abbandonare!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do           Mi                     Re-",
          text: "DAVANTI AGLI ANGELI A TE VOGLIO CANTARE,",
        },
        {
          notes: "Sol                               Mi",
          text: "DAVANTI AGLI ANGELI VOGLIO SALMODIARE;",
        },
        {
          notes: "                    Re-",
          text: "RENDO GRAZIE AL TUO NOME",
        },
        {
          notes: "       Fa                    Mi",
          text: "PER LA TUA FEDELTÀ E IL TUO AMORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 137(138)",
};
