import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "O cieli piovete dall'alto.",
  url_video: "https://www.youtube.com/embed/0zOEl5wV2S8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "O CIELI",
        },
        {
          notes: "             Fa",
          text: "PIOVETE DALL'ALTO;",
        },
        {
          notes: "    Sol",
          text: "O NUBI,",
        },
        {
          notes: "               La-",
          text: "MANDATECI IL SANTO.",
        },
        {
          notes: "     Mi",
          text: "O TERRA,",
        },
        {
          notes: "          La-",
          text: "APRITI O TERRA",
        },
        {
          notes: "   Mi7            La-",
          text: "E GERMINA IL SALVATOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "           La-",
          text: "Siamo il deserto,",
        },
        {
          notes: "        Sol",
          text: "siamo l'arsura:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        La-",
          text: "Siamo il vento,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci ode:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
        {
          notes: "La-",
          text: "O CIELI",
        },
        {
          notes: "             Fa",
          text: "PIOVETE DALL'ALTO;",
        },
        {
          notes: "    Sol",
          text: "O NUBI,",
        },
        {
          notes: "               La-",
          text: "MANDATECI IL SANTO.",
        },
        {
          notes: "     Mi",
          text: "O TERRA,",
        },
        {
          notes: "          La-",
          text: "APRITI O TERRA",
        },
        {
          notes: "   Mi7            La-",
          text: "E GERMINA IL SALVATOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "Siamo le tenebre,",
        },
        {
          notes: "            Sol",
          text: "nessuno ci guida:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         La-",
          text: "Siam le catene,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci scioglie:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
        {
          notes: "La-",
          text: "O CIELI",
        },
        {
          notes: "             Fa",
          text: "PIOVETE DALL'ALTO;",
        },
        {
          notes: "    Sol",
          text: "O NUBI,",
        },
        {
          notes: "               La-",
          text: "MANDATECI IL SANTO.",
        },
        {
          notes: "     Mi",
          text: "O TERRA,",
        },
        {
          notes: "          La-",
          text: "APRITI O TERRA",
        },
        {
          notes: "   Mi7            La-",
          text: "E GERMINA IL SALVATOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "Siamo il freddo,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci copre:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "Siamo la fame,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci nutre:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
        {
          notes: "La-",
          text: "O CIELI",
        },
        {
          notes: "             Fa",
          text: "PIOVETE DALL'ALTO;",
        },
        {
          notes: "    Sol",
          text: "O NUBI,",
        },
        {
          notes: "               La-",
          text: "MANDATECI IL SANTO.",
        },
        {
          notes: "     Mi",
          text: "O TERRA,",
        },
        {
          notes: "          La-",
          text: "APRITI O TERRA",
        },
        {
          notes: "   Mi7            La-",
          text: "E GERMINA IL SALVATOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "Siamo le lacrime,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci asciuga:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          La-",
          text: "Siamo il dolore,",
        },
        {
          notes: "           Sol",
          text: "nessuno ci guarda:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi  Sol     Mi",
          text: "MARANATHÀ, MARANATHÀ!",
        },
        {
          notes: "La-",
          text: "O CIELI",
        },
        {
          notes: "             Fa",
          text: "PIOVETE DALL'ALTO;",
        },
        {
          notes: "    Sol",
          text: "O NUBI,",
        },
        {
          notes: "               La-",
          text: "MANDATECI IL SANTO.",
        },
        {
          notes: "     Mi",
          text: "O TERRA,",
        },
        {
          notes: "          La-",
          text: "APRITI O TERRA",
        },
        {
          notes: "   Mi7            La-",
          text: "E GERMINA IL SALVATOR.",
        },
      ],
    },
  ],
  subtitle: "(Is 45, 8)",
};
