import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "O morte dov'è la tua vittoria?",
  url_video: "https://www.youtube.com/embed/mCUS1l7qnRA",
  barrè: "V",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "In un istante.",
        },
        {
          notes: "",
          text: "in un battere d'occhio",
        },
        {
          notes: "    La-         Re7",
          text: "al suono dell'ultima tromba,",
        },
        {
          notes: "",
          text: "poiché suonerà la tromba:",
        },
        {
          notes: "     Sol",
          text: "e i morti",
        },
        {
          notes: " Si-",
          text: "risusciteranno incorruttibili",
        },
        {
          notes: " Do                 Fa#",
          text: "e noi saremo trasformati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Re",
          text: "PERCIÒ NOI CANTIAMO:",
        },
        {
          notes: "",
          text: "LA MORTE È SCONFITTA DALLA VITTORIA!",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "                        Fa#",
          text: "O MORTE DOV'È LA TUA VITTORIA?",
        },
        {
          notes: "                         Si-",
          text: "O MORTE DOV'È IL TUO PUNGIGLIONE?",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    La",
          text: "Il pungiglione della morte è il peccato",
        },
        {
          notes: "                            Sol",
          text: "e la forza del peccato è la legge;",
        },
        {
          notes: "",
          text: "ma noi abbiamo vinto",
        },
        {
          notes: "                    Fa#",
          text: "in Gesù Cristo risuscitato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Re",
          text: "PERCIÒ NOI CANTIAMO:",
        },
        {
          notes: "",
          text: "LA MORTE È SCONFITTA DALLA VITTORIA!",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "                        Fa#",
          text: "O MORTE DOV'È LA TUA VITTORIA?",
        },
        {
          notes: "                         Si-",
          text: "O MORTE DOV'È IL TUO PUNGIGLIONE?",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "  La           Sol     Fa#",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                 Re",
          text: "Io vi ricordo, fratelli,",
        },
        {
          notes: "     La",
          text: "il vangelo",
        },
        {
          notes: "                  Sol",
          text: "che io vi ho annunziato,",
        },
        {
          notes: "                    Fa#",
          text: "che io stesso ho ricevuto:",
        },
        {
          notes: "     Re",
          text: "che Cristo è morto per i peccati",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "SECONDO LE SCRITTURE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa#",
          text: "che è risuscitato",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "SECONDO LE SCRITTURE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "               Si-",
          text: "Che è apparso a Pietro",
        },
        {
          notes: "          La",
          text: "e poi ai dodici,",
        },
        {
          notes: "                          Sol",
          text: "poi a più di cinquecento fratelli in una volta",
        },
        {
          notes: "                        Fa#",
          text: "ed infine è apparso anche a me.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Re",
          text: "PERCIÒ NOI CANTIAMO:",
        },
        {
          notes: "",
          text: "LA MORTE È SCONFITTA DALLA VITTORIA!",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "                        Fa#",
          text: "O MORTE DOV'È LA TUA VITTORIA?",
        },
        {
          notes: "                         Si-",
          text: "O MORTE DOV'È IL TUO PUNGIGLIONE?",
        },
        {
          notes: "",
          text: "ALLELUJA, ALLELUJA.",
        },
        {
          notes: "  La           Sol     Fa#",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "(1Cor 15)",
};
