import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Te_al_centro_del_mio_cuore.",
  title: "Te al centro del mio cuore.",
  url_video: "https://www.youtube.com/embed/QEOyKmMbb_0",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "        Re   Sol   Re   ",
          text: " ",
        },
        {
          notes: "        Mi-",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                               Sol",
          text: "Ho bisogno di incontrarti nel mio cuore ",
        },
        {
          notes: "Si-                               Do",
          text: "di trovare te, di stare insieme a te ",
        },
        {
          notes: "La-                         Mi-",
          text: "unico riferimento del mio andare, ",
        },
        {
          notes: "Do            Re  Si-          Mi-",
          text: "unica ragione tu, unico sostegno tu ",
        },
        {
          notes: "   Do                   Re          Sol",
          text: "Al centro del mio cuore ci sei solo tu.",
        },
        {
          notes: "Mi-                                  Sol",
          text: "Anche il cielo gira intorno e non ha pace, ",
        },
        {
          notes: "Si-                                    Do",
          text: "ma c'è un punto fermo è quella stella là ",
        },
        {
          notes: "La-                              Mi-",
          text: "La stella polare è fissa ed è la sola, ",
        },
        {
          notes: "Do               Re  Si-            Mi-",
          text: "la stella polare Tu, la stella sicura Tu  ",
        },
        {
          notes: "   Do                   Re          Sol",
          text: "Al centro del mio cuore ci sei solo Tu.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Si-             Do                 Si- Mi-",
          text: "Tutto ruota intorno a Te, in funzione di Te, ",
        },
        {
          notes: "      Si-            Do                 Re",
          text: "e poi non importa il come, il dove e il se.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                     Sol",
          text: "Che tu splenda sempre al centro del mio cuore, ",
        },
        {
          notes: "Si-                         Do",
          text: "Il significato allora sarai tu, ",
        },
        {
          notes: "La-                            Mi-",
          text: "Quello che farò sarà soltanto amore ",
        },
        {
          notes: "Do             Re  Si-             Mi-",
          text: "Unico sostegno tu, la stella polare tu ",
        },
        {
          notes: "   Do                   Re          Sol",
          text: "al centro del mio cuore ci sei solo tu.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Si-             Do                 Si- Mi-",
          text: "Tutto ruota intorno a Te, in funzione di Te, ",
        },
        {
          notes: "      Si-            Do                 Re",
          text: "e poi non importa il come, il dove e il se.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                               Sol",
          text: "Ho bisogno di incontrarti nel mio cuore ",
        },
        {
          notes: "Si-                               Do",
          text: "di trovare te, di stare insieme a te ",
        },
        {
          notes: "La-                         Mi-",
          text: "unico riferimento del mio andare, ",
        },
        {
          notes: "Do            Re  Si-          Mi-",
          text: "unica ragione tu, unico sostegno tu ",
        },
        {
          notes: "   Do                   Re          Sol",
          text: "Al centro del mio cuore ci sei solo tu.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-   Sol   Si-   Mi-",
          text: "",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
