import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Quanto sono amabili le tue dimore.",
  url_video: "https://www.youtube.com/embed/ugcGzTjBgnE",
  barrè: "IV",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                           Mi-      La-",
          text: "Quanto sono amabili le tue dimore, Signore,",
        },
        {
          notes: "   Do           Mi-",
          text: "Signore degli eserciti!",
        },
        {
          notes: "Fa                    Do",
          text: "L'anima mia anela e brama",
        },
        {
          notes: "                 Sol   (Do Mi- La-)",
          text: "gli atri del Signore.",
        },
        {
          notes: "Do               Mi-      La-",
          text: "Anche il passero trova la casa,",
        },
        {
          notes: "     Do           Mi-",
          text: "la rondine il suo nido",
        },
        {
          notes: "Fa                      Do",
          text: "presso i tuoi altari, Signore,",
        },
        {
          notes: "             Sol              Do",
          text: "mio re e mio Dio, mio re e mio Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Do",
          text: "BEATO CHI ABITA LA TUA CASA,",
        },
        {
          notes: "                    Sol",
          text: "SEMPRE CANTA LE TUE LODI,",
        },
        {
          notes: "                    Do",
          text: "SEMPRE CANTA LE TUE LODI.",
        },
        {
          notes: "Fa                       Do",
          text: "BEATO CHI TROVA IN TE LA FORZA",
        },
        {
          notes: "                  Sol",
          text: "E DECIDE NEL SUO CUORE",
        },
        {
          notes: "          Do",
          text: "IL SANTO VIAGGIO.",
        },
        {
          notes: "               Mi-         La-",
          text: "PASSANDO PER LA VALLE DEL PIANTO",
        },
        {
          notes: "Do              Mi-",
          text: "LA CAMBIA IN SORGENTE;",
        },
        {
          notes: "Fa                             Do",
          text: "CRESCE LUNGO IL CAMMINO IL SUO VIGORE,",
        },
        {
          notes: "                Sol                    Do",
          text: "FINCHÉ ARRIVA A SION, FINCHÉ ARRIVA A SION.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Fa                   Do",
          text: "Per me un giorno nei tuoi atri",
        },
        {
          notes: "                   Sol",
          text: "vale più che mille altrove,",
        },
        {
          notes: "                     Do",
          text: "vale più che mille altrove.",
        },
        {
          notes: "      Fa                            Do",
          text: "Perché stare sulla soglia della tua casa",
        },
        {
          notes: "                Sol                   Do",
          text: "è meglio che abitare nei palazzi dei potenti.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               Mi-         La-",
          text: "PASSANDO PER LA VALLE DEL PIANTO",
        },
        {
          notes: "Do              Mi-",
          text: "LA CAMBIA IN SORGENTE;",
        },
        {
          notes: "Fa                             Do",
          text: "CRESCE LUNGO IL CAMMINO IL SUO VIGORE,",
        },
        {
          notes: "                Sol                    Do",
          text: "FINCHÉ ARRIVA A SION, FINCHÉ ARRIVA A SION.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Fa                   Do",
          text: "Per me un giorno nei tuoi atri",
        },
        {
          notes: "                   Sol",
          text: "vale più che mille altrove,",
        },
        {
          notes: "                     Do",
          text: "vale più che mille altrove.",
        },
        {
          notes: "      Fa                            Do",
          text: "Perché stare sulla soglia della tua casa",
        },
        {
          notes: "                Sol                   Do",
          text: "è meglio che abitare nei palazzi dei potenti.",
        },
      ],
    },
  ],
  subtitle: "Sal 83(84)",
};
