import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ButtonBase } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./Accordion.scss";

export interface AccordionProps {
  label: string;
  openStateLabel?: string;
  content: JSX.Element;
  startsOpen?: boolean;
}

const Accordion = (props: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const contentRef = useRef(null);

  const getContentChildHeight = () => {
    let contentNode: any = contentRef.current;
    if (contentNode) {
      let rect = contentNode.getBoundingClientRect();
      return rect.height + 10;
    }
    return 100;
  };

  useEffect(() => {
    if (props.startsOpen) {
      setTimeout(() => {
        setOpen(true);
      }, 50);
    }
  }, []);

  return (
    <div className="accordion-main-wrap">
      <ButtonBase style={{ width: "100%" }}>
        <div
          className="accordion-title-wrap dark_text_color"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="accordion-title">
            {open ? props.openStateLabel ?? props.label : props.label}
          </div>
          <div
            className={
              "accordion-title-chevron " +
              (open ? "accordion-title-chevron-rotated" : "")
            }
          >
            <KeyboardArrowDownIcon />
          </div>
        </div>
      </ButtonBase>

      <div
        className="accordion-content"
        style={
          open ? { maxHeight: getContentChildHeight().toString() + "px" } : {}
        }
      >
        <div className="accordion-content-inner" ref={contentRef}>
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
