import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "O gesù amore mio.",
  url_video: "https://www.youtube.com/embed/X5dAFAeaWQo",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-        Sol                La-",
          text: "O Gesù, tu hai preso i peccati da me",
        },
        {
          notes: "            Fa                 Mi",
          text: "perché io prenda il perdono da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       La-       Sol                 La-",
          text: "Tu hai preso le spine: la superbia da me",
        },
        {
          notes: "            Fa                            Mi",
          text: "perché io prenda l'umiltà e la mitezza da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-            Sol                La-",
          text: "Tu hai preso i colpi, i flagelli, la lussuria da me",
        },
        {
          notes: "               Fa                      Mi",
          text: "perché io prenda la purezza, l'amore da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-         Sol                La-",
          text: "Tu hai preso la spoliazione, l'avarizia da me",
        },
        {
          notes: "                 Fa                           Mi",
          text: "perché io prenda l'abbandono delle ricchezze da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-     Sol       La-",
          text: "Tu hai preso il fiele da me",
        },
        {
          notes: "            Fa                 Mi",
          text: "perché io prenda la dolcezza da te.",
        },
        {
          notes: "   La-        Sol                La-",
          text: "Tu hai preso la derisione, gli scherni da me",
        },
        {
          notes: "            Fa                 Mi",
          text: "perché io prenda l'obbedienza da te.",
        },
        {
          notes: "   La-     Sol         La-",
          text: "Tu hai preso i chiodi da me",
        },
        {
          notes: "            Fa                 Mi",
          text: "perché io prenda l'obbedienza da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-    Sol         La-",
          text: "Tu hai preso la croce da me",
        },
        {
          notes: "            Fa                 Mi",
          text: "perché io prenda la salvezza da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-     Sol         La-",
          text: "Tu hai preso la morte da me",
        },
        {
          notes: "        Fa                 Mi",
          text: "perché io prenda la vita da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   La-     Sol           La-",
          text: "Tu hai preso la sepoltura da me",
        },
        {
          notes: "            Fa                   Mi",
          text: "perché io prenda la resurrezione da te.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-                  Sol                   La-",
          text: "Da me, Signore, tu hai preso la morte e il peccato",
        },
        {
          notes: "           Fa                     Mi",
          text: "perché io prenda da te tutto te stesso,",
        },
        {
          notes: "           Fa                 Mi",
          text: "tutto te stesso a me ti sei donato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-           Fa",
          text: "O Gesù, amore mio,",
        },
        {
          notes: "",
          text: "quanto mi hai amato,",
        },
        {
          notes: "               Mi",
          text: "quanto mi hai amato!",
        },
      ],
    },
  ],
  subtitle: "",
};
