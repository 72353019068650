import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Cantico di zaccaria.",
  url_video: "https://www.youtube.com/embed/IvIZUbGGJwc",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "BENEDETTO SIA IL SIGNORE",
        },
        {
          notes: "La-      Mi-",
          text: "DIO D'ISRAELE,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "che ha visitato e redento il suo popolo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "SUSCITANDO",
        },
        {
          notes: "                Mi-",
          text: "UNA FORZA DI SALVEZZA",
        },
        {
          notes: "      Si7                 Mi-",
          text: "NELLA CASA DI DAVIDE SUO SERVO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-                                   Mi-",
          text: "Secondo quanto fu predetto dagli antichi tempi",
        },
        {
          notes: "   La-                   Mi-",
          text: "per bocca dei suoi santi profeti:",
        },
        {
          notes: "    Si7",
          text: "salvezza dai nostri nemici",
        },
        {
          notes: "                          Mi-",
          text: "e dalle mani di quanti ci odiano.",
        },
        {
          notes: "      La-                 Mi-",
          text: "Realizzando così la misericordia",
        },
        {
          notes: "    La-                Mi-",
          text: "che ebbe con i nostri padri",
        },
        {
          notes: "Si7",
          text: "ricordando la sua santa alleanza",
        },
        {
          notes: "                                       Mi-",
          text: "e il giuramento fatto al nostro padre Abramo,",
        },
        {
          notes: "La-                             Mi-",
          text: "di concederci che liberati dal timore",
        },
        {
          notes: "     La-                    Mi-",
          text: "e strappati dalla mano dei nemici,",
        },
        {
          notes: "      Si7",
          text: "lo serviamo in santità e giustizia",
        },
        {
          notes: "                                  Mi-",
          text: "alla sua presenza tutti i nostri giorni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "BENEDETTO SIA IL SIGNORE",
        },
        {
          notes: "La-      Mi-",
          text: "DIO D'ISRAELE,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "che ha visitato e redento il suo popolo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "SUSCITANDO",
        },
        {
          notes: "                Mi-",
          text: "UNA FORZA DI SALVEZZA",
        },
        {
          notes: "      Si7                 Mi-",
          text: "NELLA CASA DI DAVIDE SUO SERVO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "E tu, bambino, sarai chiamato",
        },
        {
          notes: "             Mi-",
          text: "profeta dell'Altissimo",
        },
        {
          notes: "        La-",
          text: "perché andrai davanti al Signore",
        },
        {
          notes: "                       Mi-",
          text: "per preparare i suoi cammini",
        },
        {
          notes: "    Si7",
          text: "annunciando al suo popolo la salvezza",
        },
        {
          notes: "                        Mi-",
          text: "e il perdono dei suoi peccati.",
        },
        {
          notes: "      La-                            Mi-",
          text: "Per la grande misericordia del nostro Dio",
        },
        {
          notes: "     La-                          Mi-",
          text: "ci visiterà il sole che nasce dall'alto",
        },
        {
          notes: "       Si7",
          text: "per illuminare quelli che vivono nelle tenebre",
        },
        {
          notes: "                    Mi-",
          text: "e nell'ombra della morte,",
        },
        {
          notes: "         Si7",
          text: "e per dirigere i nostri passi",
        },
        {
          notes: "                Mi-",
          text: "sulla via della pace.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "BENEDETTO SIA IL SIGNORE",
        },
        {
          notes: "La-      Mi-",
          text: "DIO D'ISRAELE,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "che ha visitato e redento il suo popolo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  La-",
          text: "SUSCITANDO",
        },
        {
          notes: "                Mi-",
          text: "UNA FORZA DI SALVEZZA",
        },
        {
          notes: "      Si7                 Mi-",
          text: "NELLA CASA DI DAVIDE SUO SERVO.",
        },
      ],
    },
  ],
  subtitle: "(Lc 1, 67-80)",
};
