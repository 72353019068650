import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Fino a quando.",
  url_video: "https://www.youtube.com/embed/CyN3kGqFkz8",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                      Mi",
          text: "Fino a quando, Jahvè tu mi vorrai dimenticar",
        },
        {
          notes: "         Mi7",
          text: "e il tuo volto",
        },
        {
          notes: "              La-",
          text: "tu mi nasconderai?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi7      La-",
          text: "CANTERÒ A JAHVÈ!",
        },
        {
          notes: "    Mi7",
          text: "CANTERÒ",
        },
        {
          notes: "                La-",
          text: "A LUI, MIO REDENTOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                  Mi",
          text: "Guardami, rispondimi Jahvè, Signore mio;",
        },
        {
          notes: "                Mi7",
          text: "illumina i miei occhi,",
        },
        {
          notes: "                         La-",
          text: "che io non dorma nella morte.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi7      La-",
          text: "CANTERÒ A JAHVÈ!",
        },
        {
          notes: "    Mi7",
          text: "CANTERÒ",
        },
        {
          notes: "                La-",
          text: "A LUI, MIO REDENTOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                           Mi",
          text: "Che non possano dire i miei nemici: “L'abbiam vinto“,",
        },
        {
          notes: "          Mi7",
          text: "né gli oppressori",
        },
        {
          notes: "              La-",
          text: "esultare se cadrò",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi7      La-",
          text: "CANTERÒ A JAHVÈ!",
        },
        {
          notes: "    Mi7",
          text: "CANTERÒ",
        },
        {
          notes: "                La-",
          text: "A LUI, MIO REDENTOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                         Mi",
          text: "Io peró confido nella tua pietà,",
        },
        {
          notes: "             Mi7",
          text: "nella tua salvezza,",
        },
        {
          notes: "                  La-",
          text: "il mio cuore esulterà.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Mi7      La-",
          text: "CANTERÒ A JAHVÈ!",
        },
        {
          notes: "    Mi7",
          text: "CANTERÒ",
        },
        {
          notes: "                La-",
          text: "A LUI, MIO REDENTOR.",
        },
      ],
    },
  ],
  subtitle: "Sal 12(13)",
};
