import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Come_Maria.",
  title: "Come Maria.",
  url_video: "https://www.youtube.com/embed/8qVe1zwlHvQ",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Re  Si-  La  Mi",
          text: "",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-                 Do",
          text: " Vogliamo vivere, Signore,",
        },
        {
          notes: "La-                      Mi-",
          text: " offrendo a Te la nostra vita,",
        },
        {
          notes: "Fa                        Re",
          text: " con questo pane e questo vino",
        },
        {
          notes: "La-                     Mi7   Mi",
          text: " accetta quello che noi siamo",
        },
        {
          notes: "La-                 Do",
          text: " Vogliamo vivere, Signore,",
        },
        {
          notes: "La-                   Mi-",
          text: " abbandonati alla Tua voce,",
        },
        {
          notes: "Fa                   Re",
          text: " staccati dalle cose vane,",
        },
        {
          notes: "La-                 Mi7   Mi",
          text: " fissati nella vita vera.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   La    Re    Mi      Fa#-   Re",
          text: "VOGLIAMO VIVERE COME MARIA,",
        },
        {
          notes: "           Mi      Re",
          text: "L'IRRAGGIUNGIBILE,",
        },
        {
          notes: "          Mi    Re",
          text: "La MADRE AMATA",
        },
        {
          notes: "             Mi           Do#-  Re",
          text: "CHE VINCE IL MONDO CON L'AMORE",
        },
        {
          notes: "    Re     Si-           Do#7",
          text: "E OFFRIRE  SEMPRE La TUA VITA",
        },
        {
          notes: "    Re        La",
          text: "CHE VIENE DAL CIELO.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Do",
          text: " Accetta dalle nostre mani",
        },
        {
          notes: "La-                      Mi-",
          text: " come un'offerta a Te gradita",
        },
        {
          notes: "Fa                  Re",
          text: " i desideri di ogni cuore,",
        },
        {
          notes: "La-                    Mi   Mi",
          text: " le ansie della nostra vita",
        },
        {
          notes: "La-                 Do",
          text: " Vogliamo vivere, Signore,",
        },
        {
          notes: "La-                 Mi-",
          text: " accesi dalle Tue parole",
        },
        {
          notes: "Fa                     Re",
          text: " per riportare in ogni uomo",
        },
        {
          notes: "La-                      Mi   Mi",
          text: " la fiamma viva del Tuo amore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   La    Re    Mi      Fa#-   Re",
          text: "VOGLIAMO VIVERE COME MARIA,",
        },
        {
          notes: "           Mi      Re",
          text: "L'IRRAGGIUNGIBILE,",
        },
        {
          notes: "          Mi    Re",
          text: "La MADRE AMATA",
        },
        {
          notes: "             Mi           Do#-  Re",
          text: "CHE VINCE IL MONDO CON L'AMORE",
        },
        {
          notes: "    Re     Si-           Do#7",
          text: "E OFFRIRE  SEMPRE La TUA VITA",
        },
        {
          notes: "    Re        La     Fa#-    Mi",
          text: "CHE VIENE DAL CIELO",
        },
        {
          notes: "    Re     Si-           Do#7",
          text: "E OFFRIRE  SEMPRE La TUA VITA",
        },
        {
          notes: "    Re        La     ",
          text: "CHE VIENE DAL CIELO.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
