import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SongElement } from "../../Songs/SongElement";
import { getAppSongs } from "../../Songs/SongHandler";
import CloseIcon from "@mui/icons-material/Close";
import "./SearchSongInput.scss";
import { useNavigate } from "react-router-dom";
import NavigateTable from "../NavigateTable/NavigateTable";

export interface SearchSongInputProps {
  label: string;
  value: SearchSongValue;
  onChange: (link: SearchSongValue | null) => void;
}

export interface SearchSongValue {
  title: string;
  id: string;
}

const SearchSongInput = (props: SearchSongInputProps) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  const [selectedLink, setSelectedLink] = useState<SearchSongValue | null>(
    props.value
  );
  const [matchedSongs, setMatchedSongs] = useState<SongElement[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (value && value !== "" && focused) {
      setMatchedSongs(
        getAppSongs().filter(
          (x: SongElement) =>
            x.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !==
            -1
        )
      );
    }
  }, [value]);

  useEffect(() => {
    props.onChange(selectedLink);
  }, [selectedLink]);

  return (
    <div className="search-song-input-wrap">
      {selectedLink && (
        <div className="search-song-input-selected-link">
          <div>
            <div>{props.label + ": "}</div>
            <div
              className="search-song-input-selected-link-label"
              onClick={() => {
                navigate("/view?id=" + encodeURI(selectedLink.id));
              }}
            >
              {selectedLink.title}
            </div>
          </div>
          <div className="search-song-input-selected-link-remove">
            <CloseIcon
              onClick={() => {
                setSelectedLink(null);
              }}
            />
          </div>
        </div>
      )}
      {!selectedLink && (
        <TextField
          onChange={(e: any) => {
            setValue(e.target.value);
            setEnabled(true);
          }}
          value={value}
          fullWidth={true}
          helperText={"Seleziona un canto per impostare il link."}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              try {
                setFocused(false);
                setEnabled(false);
              } catch (e) {}
            }, 100);
          }}
          label={props.label}
          variant="standard"
        />
      )}
      {enabled && matchedSongs.length > 0 && (
        <div className="search-song-input-results">
          <NavigateTable
            selectedLine={(index: number) => {
              let song: SongElement = matchedSongs[index];
              setSelectedLink({ title: song.title, id: song.id ?? song.title });
              setEnabled(false);
            }}
            lines={[...matchedSongs]
              .splice(0, 7)
              .map((x: SongElement, i: number) => {
                return (
                  <div
                    className="search-song-input-result"
                    key={i}
                    onClick={() => {
                      setSelectedLink({ title: x.title, id: x.id ?? x.title });
                    }}
                  >
                    {x.title}
                  </div>
                );
              })}
          />
        </div>
      )}
    </div>
  );
};

export default SearchSongInput;
