import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Vieni dal libano.",
  url_video: "https://www.youtube.com/embed/borz12lPiIg",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-           Re",
          text: "Vieni dal Libano, mia sposa,",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "vieni dal Libano, vieni!",
        },
        {
          notes: "Mi-                 Re",
          text: "Avrai per corona le vette dei monti,",
        },
        {
          notes: "Mi-      Re        Mi-",
          text: "le alte cime dell'Ermon.",
        },
        {
          notes: "Mi-               Re",
          text: "Tu m'hai ferito, ferito il cuore",
        },
        {
          notes: "Mi- Re         Mi-",
          text: "o sorella mia sposa.",
        },
        {
          notes: "Mi-           Re",
          text: "Vieni dal Libano, mia sposa,",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "vieni dal Libano, vieni!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       Sol             La",
          text: "CERCAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "    La-            Mi-",
          text: "LO CERCAI SENZA TROVARLO;",
        },
        {
          notes: "          Sol             La",
          text: "TROVAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "            Do           Re      Mi-",
          text: "L'HO ABBRACCIATO, NON LO LASCERÒ MAI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Re",
          text: "Io appartengo al mio diletto",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "ed egli è tutto per me;",
        },
        {
          notes: "Mi-           Re",
          text: "vieni usciamo alla campagna,",
        },
        {
          notes: "Mi-    Re       Mi-",
          text: "dimoriamo nei villaggi.",
        },
        {
          notes: "Mi-              Re",
          text: "Andremo all'alba nelle vigne,",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "vi raccoglieremo i frutti.",
        },
        {
          notes: "Mi-              Re",
          text: "Io appartengo al mio diletto",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "ed egli è tutto per me.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       Sol             La",
          text: "CERCAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "    La-            Mi-",
          text: "LO CERCAI SENZA TROVARLO;",
        },
        {
          notes: "          Sol             La",
          text: "TROVAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "            Do           Re      Mi-",
          text: "L'HO ABBRACCIATO, NON LO LASCERÒ MAI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-              Re",
          text: "Alzati in fretta, mia diletta,",
        },
        {
          notes: "Mi-     Re       Mi-",
          text: "vieni, colomba, vieni!",
        },
        {
          notes: "Mi-               Re",
          text: "L'inverno ormai è già passato",
        },
        {
          notes: "Mi-            Re       Mi-",
          text: "il canto della tortora si ode.",
        },
        {
          notes: "Mi-                 Re",
          text: "I fiori son tornati sulla terra,",
        },
        {
          notes: "Mi-       Re       Mi-",
          text: "il grande sole è venuto.",
        },
        {
          notes: "Mi-               Re",
          text: "Alzati in fretta, mia diletta,",
        },
        {
          notes: "Mi-      Re       Mi-",
          text: "vieni, colomba, vieni!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       Sol             La",
          text: "CERCAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "    La-            Mi-",
          text: "LO CERCAI SENZA TROVARLO;",
        },
        {
          notes: "          Sol             La",
          text: "TROVAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "            Do           Re      Mi-",
          text: "L'HO ABBRACCIATO, NON LO LASCERÒ MAI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-          Re",
          text: "Come sigillo sul tuo cuore",
        },
        {
          notes: "Mi-  Re              Mi-",
          text: "come sigillo sul tuo braccio,",
        },
        {
          notes: "Mi-                 Re",
          text: "ché l'amore è forte come la morte",
        },
        {
          notes: "Mi-        Re       Mi-",
          text: "e le acque non lo spegneranno.",
        },
        {
          notes: "Mi-                   Re",
          text: "Dare per esso tutti i beni della casa",
        },
        {
          notes: "Mi-     Re       Mi-",
          text: "sarebbe disprezzarlo.",
        },
        {
          notes: "Mi-           Re",
          text: "Come sigillo sul tuo cuore",
        },
        {
          notes: "Mi-   Re             Mi-",
          text: "come sigillo sul tuo braccio.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-       Sol             La",
          text: "CERCAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "    La-            Mi-",
          text: "LO CERCAI SENZA TROVARLO;",
        },
        {
          notes: "          Sol             La",
          text: "TROVAI L'AMORE DELL'ANIMA MIA,",
        },
        {
          notes: "            Do           Re      Mi-",
          text: "L'HO ABBRACCIATO, NON LO LASCERÒ MAI!",
        },
      ],
    },
  ],
  subtitle: "(Ct 4, 8 ss)",
};
