import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ecco qui io vengo presto.",
  url_video: "https://www.youtube.com/embed/NI4qDtRdKZ8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La",
          text: "Ecco qui, io vengo presto",
        },
        {
          notes: "        Mi",
          text: "ho con me la ricompensa",
        },
        {
          notes: "         Si7",
          text: "che darò ad ognuno",
        },
        {
          notes: "                Mi",
          text: "secondo le sue opere.",
        },
        {
          notes: "Mi7                  La",
          text: "Io sono l'Alfa e l'Omega",
        },
        {
          notes: "                   Mi",
          text: "sono il Primo e l'Ultimo",
        },
        {
          notes: "                   Si7",
          text: "sono il Primo e l'Ultimo",
        },
        {
          notes: "                  Mi",
          text: "il Principio e la Fin.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "      La                     Mi",
          text: "SONO BEATI QUEI CHE LAVAN LE VESTI",
        },
        {
          notes: "            Si7                    Mi",
          text: "PER AVERE DIRITTO ALL'ALBERO DELLA VITA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do#",
          text: "Io sono",
        },
        {
          notes: "                 Fa#-",
          text: "la radice e la stirpe di David,",
        },
        {
          notes: "Do#",
          text: "la stella",
        },
        {
          notes: "                   Fa#-  Mi",
          text: "splendente del mattino.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "      La                     Mi",
          text: "SONO BEATI QUEI CHE LAVAN LE VESTI",
        },
        {
          notes: "            Si7                    Mi",
          text: "PER AVERE DIRITTO ALL'ALBERO DELLA VITA",
        },
      ],
    },
  ],
  subtitle: "(Ap 22, 12-16)",
};
