import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "La mia diletta è per me.",
  url_video: "https://www.youtube.com/embed/ZtRJQ8h39q0",
  barrè: "II",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 Mi-",
          text: "La mia diletta è per me",
        },
        {
          notes: "               Fa#",
          text: "un narciso di Sàron,",
        },
        {
          notes: "     Sol       Fa#",
          text: "una rosa delle valli.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 Mi-",
          text: "IL MIO DILETTO È PER ME",
        },
        {
          notes: "               Fa#",
          text: "UN PROFUMO DI MIRRA,",
        },
        {
          notes: " Sol            Fa#",
          text: "RIPOSA SUL MIO PETTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 Mi-",
          text: "La mia diletta è per me",
        },
        {
          notes: "                     Fa#",
          text: "come un giglio fra i cardi",
        },
        {
          notes: "         Sol           Fa#",
          text: "la mia diletta tra le fanciulle.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 Mi-",
          text: "IL MIO DILETTO È PER ME",
        },
        {
          notes: "                Fa#",
          text: "UN GRAPPOLO DI CIPRO",
        },
        {
          notes: " Sol              Fa#",
          text: "DELLE VIGNE DI ENGADDI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "          Sol         Fa#",
          text: "Come sei bella, amica mia,",
        },
        {
          notes: "          Sol          Fa#",
          text: "come sei bella, come sei bella!",
        },
        {
          notes: "  Mi-                 Fa#",
          text: "I tuoi occhi sono colombe,",
        },
        {
          notes: "  Mi-                 Fa#",
          text: "i tuoi occhi sono colombe!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "          Sol          Fa#",
          text: "COME SEI BELLO, MIO DILETTO,",
        },
        {
          notes: "         Sol                 Fa#",
          text: "QUANTO GRAZIOSO, QUANTO GRAZIOSO!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "  Mi-                          Fa#",
          text: "ANCHE IL NOSTRO LETTO È VERDEGGIANTE,",
        },
        {
          notes: "  Mi-                          Fa#",
          text: "ANCHE IL NOSTRO LETTO È VERDEGGIANTE!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                             Mi-",
          text: "COME UN MELO FRA GLI ALBERI DEL BOSCO",
        },
        {
          notes: "               Fa#",
          text: "COSÌ IL MIO DILETTO,",
        },
        {
          notes: "         Sol           Fa#",
          text: "IL MIO DILETTO TRA I GIOVANI.",
        },
        {
          notes: " Si-                             Mi-",
          text: "ALLA SUA OMBRA CUI ANELAVO SONO SEDUTA",
        },
        {
          notes: "                  Fa#",
          text: "E DOLCE È IL SUO FRUTTO,",
        },
        {
          notes: "          Sol           Fa#",
          text: "QUANTO È DOLCE AL MIO PALATO!",
        },
        {
          notes: "          Sol                    Fa#",
          text: "MI HA INTRODOTTO NELLA CELLA DEL VINO",
        },
        {
          notes: "  Mi-                           Fa#",
          text: "E IL SUO VESSILLO SU DI ME È L'AMORE;",
        },
        {
          notes: "          Sol                      Fa#",
          text: "MI HA INTRODOTTO NELLA SALA DEL BANCHETTO",
        },
        {
          notes: "  Mi-                           Fa#",
          text: "E IL SUO VESSILLO SU DI ME È L'AMORE:",
        },
        {
          notes: "         Sol                 Fa#",
          text: "LA SUA SINISTRA SOTTO IL MIO CAPO",
        },
        {
          notes: "          Sol          Fa#",
          text: "E LA SUA DESTRA MI ABBRACCIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Si-                     La",
          text: "Vi scongiuro, figlie di Gerusalemme,",
        },
        {
          notes: "    Sol                        Fa#",
          text: "non destate, non risvegliate l'amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol                        Fa#",
          text: "NON DESTATE, NON RISVEGLIATE L'AMORE.",
        },
      ],
    },
  ],
  subtitle: "(Ct 1, 13 ss) ",
};
