import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "A te levo i miei occhi.",
  url_video: "https://www.youtube.com/embed/e6ssgPo38sw",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Re-              La7 ",
          text: "A te levo i miei occhi",
        },
        {
          notes: " Sol-              La7 ",
          text: "a te che abiti nei cieli",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re-              La7 ",
          text: "A TE LEVO I MIEI OCCHI",
        },
        {
          notes: " Sol-              La7 ",
          text: "A TE CHE ABITI NEI CIELI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re- ",
          text: "Come gli occhi dei servi",
        },
        {
          notes: "                                  La7 ",
          text: "sono fissi alla mano dei loro padroni,",
        },
        {
          notes: "  Sol- ",
          text: "come gli occhi della serva",
        },
        {
          notes: "                       La7 ",
          text: "alla mano della sua Signora,",
        },
        {
          notes: " Sib                            La ",
          text: "così i nostri occhi sono nel Signore,",
        },
        {
          notes: "   Sib              La ",
          text: "sperando che abbia pietà.  (2 v.)",
        },
      ],
    },
    {
      speaker: "D.",
      mode: "normal",
      lines: [
        {
          notes: " Re-            La ",
          text: "PIETÀ DI NOI SIGNORE.",
        },
      ],
    },
    {
      speaker: "U.",
      mode: "normal",
      lines: [
        {
          notes: " Sib            La ",
          text: "PIETÀ DI NOI SIGNORE. ",
        },
      ],
    },
    {
      speaker: "D.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                            La ",
          text: "LA NOSTRA ANIMA È SAZIA DI DISPREZZO.",
        },
      ],
    },
    {
      speaker: "U.",
      mode: "normal",
      lines: [
        {
          notes: " Sib                             La ",
          text: "CHE TROPPO CI HANNO COLMATO DI SCHERNI.",
        },
      ],
    },
    {
      speaker: "D.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                La ",
          text: "DEL DISPREZZO DEI SUPERBI.",
        },
      ],
    },
    {
      speaker: "U.",
      mode: "normal",
      lines: [
        {
          notes: " Sib                 La ",
          text: "DELLO SCHERNO DEI GAUDENTI.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re-            La ",
          text: "PIETÀ DI NOI SIGNORE.",
        },
        {
          notes: " Sib            La ",
          text: "PIETÀ DI NOI SIGNORE. ",
        },
      ],
    },
  ],
  subtitle: "Sal 122(123)",
};
