import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ti sto chiamando.",
  url_video: "https://www.youtube.com/embed/et1-ZDh-UgY",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "TI STO CHIAMANDO. TI STO CHIAMANDO, SIGNORE,",
        },
        {
          notes: "La-           Si7",
          text: "VIENI, AFFRETTATI,",
        },
        {
          notes: "                               Mi-",
          text: "ASCOLTA LA MIA VOCE, CHE A TE GRIDO.",
        },
        {
          notes: "                            Si7",
          text: "VALGA LA MIA PREGHIERA COME INCENSO,",
        },
        {
          notes: "                                             Mi-",
          text: "L'ALZARE LE MIE MANI COME SACRIFICIO DELLA SERA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re            Sol                     Si7",
          text: "Metti Signore alla mia bocca una sentinella,",
        },
        {
          notes: "Re-              Do                Si7",
          text: "una guardia alla porta delle mie labbra;",
        },
        {
          notes: "La-                                     Si7",
          text: "non sia che il mio cuore si inclini al male,",
        },
        {
          notes: "La-                       Si7",
          text: "a commettere azioni criminali.",
        },
        {
          notes: "Sol           Mi-               Si7          Mi-",
          text: "Che il giusto mi colpisca per amore e mi corregga,",
        },
        {
          notes: "           Do                                   Si7",
          text: "perché l'unguento del malvagio non profumi la mia testa:",
        },
        {
          notes: "                               Si7",
          text: "che io mi vizierei nei suoi peccati,",
        },
        {
          notes: "                            Si7",
          text: "che io mi vizierei nei suoi peccati.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "TI STO CHIAMANDO. TI STO CHIAMANDO, SIGNORE,",
        },
        {
          notes: "La-           Si7",
          text: "VIENI, AFFRETTATI,",
        },
        {
          notes: "                               Mi-",
          text: "ASCOLTA LA MIA VOCE, CHE A TE GRIDO.",
        },
        {
          notes: "                            Si7",
          text: "VALGA LA MIA PREGHIERA COME INCENSO,",
        },
        {
          notes: "                                             Mi-",
          text: "L'ALZARE LE MIE MANI COME SACRIFICIO DELLA SERA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re            Sol            Si7",
          text: "A te Signore guardano i miei occhi,",
        },
        {
          notes: "Re         Do                              Si7",
          text: "in te mi rifugio, non lasciare sola la mia anima!",
        },
        {
          notes: "La-                                       Si7",
          text: "Custodiscimi tu dal laccio che mi hanno teso,",
        },
        {
          notes: "La-                        Si7",
          text: "dalle trappole di tanti nemici.",
        },
        {
          notes: "      Sol   Mi-                 Si7",
          text: "Cadano loro ciascuno nella sua rete,",
        },
        {
          notes: "Do                         Si7",
          text: "che io unito a te passo indenne,",
        },
        {
          notes: "Do                         Si7",
          text: "che io unito a te passo indenne.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "TI STO CHIAMANDO. TI STO CHIAMANDO, SIGNORE,",
        },
        {
          notes: "La-           Si7",
          text: "VIENI, AFFRETTATI,",
        },
        {
          notes: "                               Mi-",
          text: "ASCOLTA LA MIA VOCE, CHE A TE GRIDO.",
        },
      ],
    },
  ],
  subtitle: "Sal 140(141) Contro le seduzioni del peccato",
};
