import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Amen amen amen.",
  url_video: "https://www.youtube.com/embed/srbUlEDp32U",
  barrè: "",
  color: "white",
  semantic: "neocat",
  altnotes: "Do",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Fa#-",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Mi          La",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "BENEDIZIONE E GLORIA",
        },
        {
          notes: "       Mi",
          text: "OGNI SAPIENZA,",
        },
        {
          notes: "",
          text: "AZIONE DI GRAZIE",
        },
        {
          notes: "           La",
          text: "RENDIAMO A DIO!",
        },
        {
          notes: "La",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Fa#-",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Mi          La",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Re            Do#-",
          text: "Onore e potere, ogni fortezza,",
        },
        {
          notes: "          Re               Do#-  Mi",
          text: "onore e potere rendiamo a Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              La",
          text: "BENEDIZIONE E GLORIA",
        },
        {
          notes: "       Mi",
          text: "OGNI SAPIENZA,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "AZIONE DI GRAZIE",
        },
        {
          notes: "           La",
          text: "RENDIAMO A DIO!",
        },
        {
          notes: "La",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Fa#-",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Mi          La",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Re             Do#-",
          text: "Chi sono, da dove vengono?",
        },
        {
          notes: "    Re             Do#-     Mi",
          text: "Chi sono, da dove vengono?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               La",
          text: "SONO QUELLI CHE VENGONO",
        },
        {
          notes: "       Mi         La      (Mi)",
          text: "DALLA GRAN TRIBOLAZIONE.",
        },
        {
          notes: "                La",
          text: "SONO QUELLI CHE VENGONO",
        },
        {
          notes: "       Mi         La",
          text: "DALLA GRAN TRIBOLAZIONE.",
        },
        {
          notes: "       Re",
          text: "E HAN LAVATO LE VESTI",
        },
        {
          notes: "              Do#-",
          text: "E LE HAN RESE CANDIDE",
        },
        {
          notes: "    Re           Do#-     (Mi)",
          text: "NEL SANGUE DELL'AGNELLO",
        },
        {
          notes: "    Re           Do#-     (Mi)",
          text: "NEL SANGUE DELL'AGNELLO.",
        },
        {
          notes: "                La",
          text: "SONO QUELLI CHE VENGONO",
        },
        {
          notes: "      Mi          La      (Mi)",
          text: "DALLA GRAN TRIBOLAZIONE.",
        },
        {
          notes: "                La",
          text: "SONO QUELLI CHE VENGONO",
        },
        {
          notes: "      Mi          La",
          text: "DALLA GRAN TRIBOLAZIONE.",
        },
        {
          notes: " La",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Fa#-",
          text: "AMEN, AMEN, AMEN!",
        },
        {
          notes: " Mi          La",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
  ],
  subtitle: "(Ap 7, 12-14)",
};
