import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedirò il Signore in ogni tempo.",
  url_video: "https://www.youtube.com/embed/Ifuj7evCGCg",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "BENEDIRÒ IL SIGNORE IN OGNI TEMPO,",
        },
        {
          notes: "          Do                  La",
          text: "SULLA MIA BOCCA SEMPRE LA SUA LODE;",
        },
        {
          notes: "      Sib           La",
          text: "IO MI GLORIO NEL SIGNORE,",
        },
        {
          notes: "  Sib                        La",
          text: "ASCOLTINO GLI UMILI E SI RALLEGRINO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Cantate con me il Signore,",
        },
        {
          notes: "      Do                    La",
          text: "cantiamo insieme il suo nome,",
        },
        {
          notes: "                Sib                         La",
          text: "perché ho cercato il Signore e mi ha risposto",
        },
        {
          notes: "        Sib                    La",
          text: "e da ogni timore mi ha liberato.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "BENEDIRÒ IL SIGNORE IN OGNI TEMPO,",
        },
        {
          notes: "          Do                  La",
          text: "SULLA MIA BOCCA SEMPRE LA SUA LODE;",
        },
        {
          notes: "      Sib           La",
          text: "IO MI GLORIO NEL SIGNORE,",
        },
        {
          notes: "  Sib                        La",
          text: "ASCOLTINO GLI UMILI E SI RALLEGRINO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "L'angelo del Signore si accampa",
        },
        {
          notes: "         Do                    La",
          text: "attorno a quelli che lo temono e li salva;",
        },
        {
          notes: "           Sib                         La",
          text: "questo povero grida e il Signore lo ascolta",
        },
        {
          notes: "  Sib                        La",
          text: "e lo libera da tutte le sue angosce.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "BENEDIRÒ IL SIGNORE IN OGNI TEMPO,",
        },
        {
          notes: "          Do                  La",
          text: "SULLA MIA BOCCA SEMPRE LA SUA LODE;",
        },
        {
          notes: "      Sib           La",
          text: "IO MI GLORIO NEL SIGNORE,",
        },
        {
          notes: "  Sib                        La",
          text: "ASCOLTINO GLI UMILI E SI RALLEGRINO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                Do               La",
          text: "Gustate e vedete quant'è buono il Signore,",
        },
        {
          notes: "           Sib                    La",
          text: "beato l'uomo che si rifugia in lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                       Do        La",
          text: "GUSTATE E VEDETE QUANT'È BUONO IL SIGNORE,",
        },
        {
          notes: "           Sib                    La",
          text: "BEATO L'UOMO CHE SI RIFUGIA IN LUI.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "BENEDIRÒ IL SIGNORE IN OGNI TEMPO,",
        },
        {
          notes: "          Do                  La",
          text: "SULLA MIA BOCCA SEMPRE LA SUA LODE;",
        },
        {
          notes: "      Sib           La",
          text: "IO MI GLORIO NEL SIGNORE,",
        },
        {
          notes: "  Sib                        La",
          text: "ASCOLTINO GLI UMILI E SI RALLEGRINO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Molte sono le prove che attendono il giusto,",
        },
        {
          notes: "         Do                    La",
          text: "ma da tutte lo libera il Signore;",
        },
        {
          notes: "      Sib           La",
          text: "gridano e il Signore li ascolta,",
        },
        {
          notes: "  Sib                        La",
          text: "li salva da tutte le loro angosce.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "BENEDIRÒ IL SIGNORE IN OGNI TEMPO,",
        },
        {
          notes: "          Do                  La",
          text: "SULLA MIA BOCCA SEMPRE LA SUA LODE;",
        },
        {
          notes: "      Sib           La",
          text: "IO MI GLORIO NEL SIGNORE,",
        },
        {
          notes: "  Sib                        La",
          text: "ASCOLTINO GLI UMILI E SI RALLEGRINO",
        },
      ],
    },
  ],
  subtitle: "Sal 33(34)",
};
