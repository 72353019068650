import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Rivestitevi dell'armatura di dio.",
  url_video: "https://www.youtube.com/embed/r8knikYMBoA",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                          Sol-",
          text: "Rivestitevi dell'armatura di Dio,",
        },
        {
          notes: "    La                  Re-",
          text: "indossando le armi della luce.",
        },
        {
          notes: "Sib                                                   La",
          text: "La nostra lotta non è contro le creature di sangue e di carne,",
        },
        {
          notes:
            "Sib                                                           La",
          text: "ma contro gli spiriti del male che abitano questo mondo di tenebra.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                       La",
          text: "CINTI I FIANCHI CON LA VERITÀ,",
        },
        {
          notes: "                                 Re-",
          text: "RIVESTITI DELLA GIUSTIZIA DELLA CROCE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sib                La",
          text: "che non resiste al male,",
        },
        {
          notes: " Sib                              La",
          text: "ma prende su di sé i peccati degli altri.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                     La",
          text: "CALZATI CON LO ZELO PER ANNUNCIARE IL VANGELO,",
        },
        {
          notes: "                                  Re-",
          text: "MA SOPRATTUTTO CON LO SCUDO DELLA FEDE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sib                 La",
          text: "che Cristo ti ama veramente,",
        },
        {
          notes: " Sib                        La",
          text: "che è morto sulla croce per te,",
        },
        {
          notes: " Sib                       La",
          text: "quando eri malvagio e peccatore.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                           La",
          text: "CON LA SPADA DELLO SPIRITO, CHE È LA PAROLA DI DIO,",
        },
        {
          notes: "                      Re-",
          text: "E CON L'ELMO DELLA SALVEZZA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sib                   La",
          text: "che Cristo Gesù risuscitò,",
        },
        {
          notes: " Sib             La",
          text: "che lui è il Signore,",
        },
        {
          notes: "      Sol-",
          text: "che ritornerà, che ritornerà,",
        },
        {
          notes: "                 La",
          text: "e ci porterà con lui.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re-                           La",
          text: "ALLELUJA, ALLELU, ALLELU, ALLELUJA",
        },
        {
          notes: " Re-                           La",
          text: "ALLELUJA, ALLELU, ALLELU, ALLELUJA",
        },
      ],
    },
  ],
  subtitle: "(Ef. 6, 11 ss)",
};
