import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Come_tu_mi_vuoi.",
  title: "Come tu mi vuoi.",
  url_video: "https://www.youtube.com/embed/rs-cOyy-5MQ",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Sol     Sol   Re7  Sol",
          text: " ",
        },
        {
          notes: "       Mi-  Do7  Re   Do   Sol",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol            Do7    ",
          text: "Eccomi signor, vengo a te mio re, ",
        },
        {
          notes: "Mi7                        Sol  Re",
          text: "che si compia in me la tua volontà",
        },
        {
          notes: "Sol           Do7    ",
          text: "eccomi Signor vengo a te mio Dio, ",
        },
        {
          notes: "Mi-                 Si7       Si-",
          text: "plasma il cuore mio e di te vivrò",
        },
        {
          notes: "Do              Re          Do",
          text: "se tu lo vuoi Signore manda me ",
        },
        {
          notes: "    Re          La7 Re7",
          text: "e il tuo nome annuncerò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Sol       Re    ",
          text: "Come tu mi vuoi io sarò, ",
        },
        {
          notes: "           Mi-       Si7",
          text: "dove tu mi vuoi io andrò, ",
        },
        {
          notes: "       Do               Si7    Mi-",
          text: "questa vita io voglio donarla a te, ",
        },
        {
          notes: "        Fa                     Re",
          text: "per dar gloria al tuo nome mio Re.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Do        Re    ",
          text: "Come tu mi vuoi io sarò, ",
        },
        {
          notes: "Si7        Mi-        Si-",
          text: "dove tu mi vuoi io andrò, ",
        },
        {
          notes: "      Do            Re     Si7     Mi-",
          text: "se mi guida il tuo amore paura non ho, ",
        },
        {
          notes: "    La-     Si Do La7     Re7",
          text: "per sempre     io  sa-----rò,",
        },
        {
          notes: "           Sol   Do      Re7      Sol",
          text: "come tu mi vuoi.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Sol            Do7    ",
          text: "Eccomi signor, vengo a te mio re, ",
        },
        {
          notes: "Mi-                        Sol  Re",
          text: "che si compia in me la tua volontà",
        },
        {
          notes: "Sol           Do7    ",
          text: "eccomi Signor vengo a te mio Dio, ",
        },
        {
          notes: "Mi-                 Si7       Si-",
          text: "plasma il cuore mio e di te vivrò",
        },
        {
          notes: "Do                  Re        Do              ",
          text: "tra le tue mani mai più vacillerò ",
        },
        {
          notes: "    Re        La7  Re7",
          text: "e strumento tuo sarò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Sol       Re    ",
          text: "Come tu mi vuoi io sarò, ",
        },
        {
          notes: "           Mi-       Si7",
          text: "dove tu mi vuoi io andrò, ",
        },
        {
          notes: "       Do               Si7    Mi-",
          text: "questa vita io voglio donarla a te, ",
        },
        {
          notes: "        Fa                     Re",
          text: "per dar gloria al tuo nome mio Re",
        },
        {
          notes: "           Do        Re    ",
          text: "Come tu mi vuoi io sarò, ",
        },
        {
          notes: "Si7        Mi-        Si-",
          text: "dove tu mi vuoi io andrò, ",
        },
        {
          notes: "      Do            Re     Si7     Mi-",
          text: "se mi guida il tuo amore paura non ho, ",
        },
        {
          notes: "    La-     Si Do La7     Re7",
          text: "per sempre     io  sa-----rò,",
        },
        {
          notes: "           Do       Re   ",
          text: "come tu mi vuoi.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "           Do       Re   ",
          text: "come tu mi vuoi...",
        },
        {
          notes: "           Do       Re   ",
          text: "come tu mi vuoi...",
        },
        {
          notes: "           Do       Re   ",
          text: "come tu mi vuoi",
        },
        {
          notes: "           Sol",
          text: "come tu mi vuoi.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
