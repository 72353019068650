import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Dal profondo a te grido.",
  url_video: "https://www.youtube.com/embed/q3te-Dqwk-c",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Fa     Mi7 La-",
          text: "Dal profondo a te grido, Signore:",
        },
        {
          notes: "  Fa                  Mi7",
          text: "Signore, ascolta la mia voce!",
        },
        {
          notes: "La-           Fa       Mi7 La-",
          text: "Siano i tuoi orecchi attenti",
        },
        {
          notes: "      Fa                Mi7",
          text: "alla voce della mia preghiera.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Fa     Mi7 La-",
          text: "DAL PROFONDO A TE GRIDO, SIGNORE:",
        },
        {
          notes: "  Fa                  Mi7",
          text: "SIGNORE, ASCOLTA LA MIA VOCE!",
        },
        {
          notes: "La-           Fa       Mi7 La-",
          text: "SIANO I TUOI ORECCHI ATTENTI",
        },
        {
          notes: "      Fa                Mi7",
          text: "ALLA VOCE DELLA MIA PREGHIERA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                        Fa La-",
          text: "Se consideri i peccati, Signore,",
        },
        {
          notes: "                             Fa  Mi7",
          text: "chi si salverà, chi si salverà?",
        },
        {
          notes: "    Fa                   Mi7",
          text: "Ma presso di te è il perdono,",
        },
        {
          notes: "          Fa              La-",
          text: "presso di te si trova l'amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Fa                   Mi7",
          text: "MA PRESSO DI TE È IL PERDONO,",
        },
        {
          notes: "          Fa              La-",
          text: "PRESSO DI TE SI TROVA L'AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol",
          text: "Io spero nel Signore,",
        },
        {
          notes: "         Fa   La-",
          text: "l'anima mia spera nella sua parola.",
        },
        {
          notes: "         Sol",
          text: "L'anima mia attende il Signore,",
        },
        {
          notes: "Fa              La-",
          text: "più che le sentinelle l'aurora.",
        },
        {
          notes: "",
          text: "Israele attende il Signore",
        },
        {
          notes: "                La-                  Mi7",
          text: "più che le sentinelle attendono l'aurora.",
        },
        {
          notes: "            Fa                  Mi7",
          text: "Perché presso il Signore è l'amore,",
        },
        {
          notes: "           Fa               La-",
          text: "presso di lui si trova il perdono.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "         Fa                  Mi7",
          text: "PERCHÉ PRESSO IL SIGNORE È L'AMORE,",
        },
        {
          notes: "            Fa              La-",
          text: "SOLTANTO IN LUI SI TROVA IL PERDONO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Il Signore redime Israele",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi7",
          text: "IL SIGNORE REDIME ISRAELE,",
        },
        {
          notes: "                   La-",
          text: "DA TUTTI I SUOI PECCATI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Il Signore redime Israele",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Mi7",
          text: "IL SIGNORE REDIME ISRAELE,",
        },
        {
          notes: "                   La-",
          text: "DA TUTTI I SUOI PECCATI",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ahi ahi ahi ahi,",
        },
        {
          notes: "                Re-",
          text: "ahi, ahi, ahi, ahi,",
        },
        {
          notes: "                La-",
          text: "ahi, ahi, ahi, ahi,",
        },
        {
          notes: "",
          text: "ahi, ahi, ahi, ahi!",
        },
      ],
    },
  ],
  subtitle: "Sal 129(130)",
};
