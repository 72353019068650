import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Felicità per l'uomo.",
  url_video: "https://www.youtube.com/embed/ZSL7JYs3GxQ",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Mi-         La-",
          text: "FELICITÀ PER L'UOMO CHE TEME IL SIGNORE",
        },
        {
          notes: "Re-                        Fa             Mi",
          text: "FELICITÀ PER L'UOMO CHE CAMMINA NELLA SUA VIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                        Mi",
          text: "Vivrà del lavoro delle sue mani,",
        },
        {
          notes: "     Re-                    Mi",
          text: "sarà felice e godrà di ogni bene:",
        },
        {
          notes: "Fa                                                   Mi",
          text: "la sua sposa come vite feconda nel centro della sua casa;",
        },
        {
          notes: "Fa                                                  Mi",
          text: "i suoi figli come virgulti d'ulivo intorno alla sua mensa.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Mi-         La-",
          text: "FELICITÀ PER L'UOMO CHE TEME IL SIGNORE",
        },
        {
          notes: "Re-                        Fa             Mi",
          text: "FELICITÀ PER L'UOMO CHE CAMMINA NELLA SUA VIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                    Mi",
          text: "Così sarà benedetto l'uomo che teme il Signore:",
        },
        {
          notes: "       Re-                Mi",
          text: "ti benedica il Signore da Sion!",
        },
        {
          notes: "Fa                                        Mi",
          text: "Che possa tu vedere la prosperità di Gerusalemme!",
        },
        {
          notes: "Fa                                   Mi",
          text: "Che possa tu vedere i figli dei tuoi figli!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Mi-         La-",
          text: "FELICITÀ PER L'UOMO CHE TEME IL SIGNORE",
        },
        {
          notes: "Re-                        Fa             Mi",
          text: "FELICITÀ PER L'UOMO CHE CAMMINA NELLA SUA VIA.",
        },
      ],
    },
  ],
  subtitle: "Sal 127(128)",
};
