import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno di pasqua.",
  url_video: "",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Do-",
          text: "L'aurora tinge di porpora il cielo",
        },
        {
          notes: "                              Sol7",
          text: "risuona nell'aria l'eco delle lodi.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Il mondo trionfante si rallegra,",
        },
        {
          notes: "                   Do-",
          text: "timoroso l'inferno freme:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "         Fa-",
          text: "MENTRE IL RE, CRISTO, LIBERA TUTTI",
        },
        {
          notes: "     Do-                     Sol7",
          text: "DALLA CELLA TENEBROSA DELLA MORTE",
        },
        {
          notes: "                   Do-",
          text: "E CI CONDUCE ALLA VITA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do-",
          text: "Una pietra chiudeva il suo sepolcro,",
        },
        {
          notes: "                    Sol7",
          text: "molte guardie lo custodivano,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ma Cristo trionfa glorioso",
        },
        {
          notes: "                     Do-",
          text: "e dalla morte si risveglia:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa-                  Do-",
          text: "NON PIÙ LUTTI, NÉ PIANTI, NÉ DOLORI,",
        },
        {
          notes: "    Sol7       Do-",
          text: "RISUSCITÒ, RISUSCITÒ!",
        },
        {
          notes: "         Fa-                  Do-",
          text: "NON PIÙ LUTTI, NÉ PIANTI, NÉ DOLORI,",
        },
        {
          notes: "    Sol7       Do-",
          text: "RISUSCITÒ, RISUSCITÒ!",
        },
      ],
    },
  ],
  subtitle: "",
};
