import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "A te Signore con la mia voce.",
  url_video: "https://www.youtube.com/embed/Qu5DOI6gkEY",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                   Re- ",
          text: "A TE, SIGNORE CON LA MIA VOCE GRIDO AIUTO,",
        },
        {
          notes: "                                La- ",
          text: "A TE, SIGNORE, NEL MIO CLAMORE SUPPLICO,",
        },
        {
          notes: "Mi7 ",
          text: "A TE EFFONDO IL MIO LAMENTO,",
        },
        {
          notes: "  Fa                    Mi ",
          text: "A TE LA MIA ANGUSTIA ESPONGO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re- ",
          text: "Sento che il mio spirito vien meno,",
        },
        {
          notes: "                        Mi7 ",
          text: "ma tu conosci il mio sentiero.",
        },
        {
          notes: "La-                         Re- ",
          text: "Guarda, Signore, che nel cammino,",
        },
        {
          notes: "                            La- ",
          text: "guarda che mi hanno teso un laccio;",
        },
        {
          notes: "Mi                                 Mi7 ",
          text: "guarda che alla mia destra non c'è nessuno,",
        },
        {
          notes: "    Fa            Mi ",
          text: "nessuno che mi conosca;",
        },
        {
          notes: "Re- ",
          text: "e fugge da me ogni conforto,",
        },
        {
          notes: "                                  Mi7 ",
          text: "ché non c'è chi si curi della mia anima.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Sol ",
          text: "A TE IO GRIDO SIGNORE,",
        },
        {
          notes: "                                      Mi7 ",
          text: "A TE IO DICO: TU SOLO SEI IL MIO RIFUGIO,",
        },
        {
          notes: "    Fa ",
          text: "TU SEI LA MIA PORZIONE IN QUESTA TERRA.",
        },
        {
          notes: "                   Mi ",
          text: "LA MIA UNICA FELICITÀ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                        Mi ",
          text: "Ascolta, Signore, sto piangendo,",
        },
        {
          notes: "   Re-             Mi ",
          text: "ché sono tanto depresso.",
        },
        {
          notes: "Fa                         Sol ",
          text: "Liberami tu da questi nemici",
        },
        {
          notes: "                      Mi ",
          text: "che sono più forti di me;",
        },
        {
          notes: "Fa                                     Sol ",
          text: "strappa la mia anima da questo carcere",
        },
        {
          notes: "                     Mi ",
          text: "e darò grazie al tuo nome;",
        },
        {
          notes: "Fa ",
          text: "allora i santi mi faranno corona,",
        },
        {
          notes: "                                           Mi ",
          text: "mi feliciteranno per il tuo amore verso di me. ",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                   Re- ",
          text: "A TE, SIGNORE CON LA MIA VOCE GRIDO AIUTO,",
        },
        {
          notes: "                                La- ",
          text: "A TE, SIGNORE, NEL MIO CLAMORE SUPPLICO,",
        },
        {
          notes: "Mi7 ",
          text: "A TE EFFONDO IL MIO LAMENTO,",
        },
        {
          notes: "  Fa                    Mi ",
          text: "A TE LA MIA ANGUSTIA ESPONGO.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Fa                Sol ",
          text: "A TE IO GRIDO SIGNORE,",
        },
        {
          notes: "                                      Mi7 ",
          text: "A TE IO DICO: TU SOLO SEI IL MIO RIFUGIO,",
        },
        {
          notes: "    Fa ",
          text: "TU SEI LA MIA PORZIONE IN QUESTA TERRA.",
        },
        {
          notes: "                   Mi ",
          text: "LA MIA UNICA FELICITÀ. ",
        },
      ],
    },
  ],
  subtitle: "Sal 141(142)",
};
