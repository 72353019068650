import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Maria madre della chiesa.",
  url_video: "https://www.youtube.com/embed/5LCG_feQIn8",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Donna, ecco il tuo figlio,",
        },
        {
          notes: "",
          text: "Donna, ecco il tuo figlio,",
        },
        {
          notes: "",
          text: "ecco il tuo figlio, ecco il tuo figlio!",
        },
        {
          notes: "La-               Re-          Mi",
          text: "Ecco la tua madre, ecco la tua madre.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol7.1       La-",
          text: "MARIA MADRE MIA, MARIA MADRE NOSTRA,",
        },
        {
          notes: "Sol               Mi",
          text: "VIENI A VIVERE CON ME,",
        },
        {
          notes: "Sol          Mi",
          text: "VIENI A CASA MIA,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Mi",
          text: "“Ho sete, ho sete,",
        },
        {
          notes: "",
          text: "tutto è compiuto,",
        },
        {
          notes: "",
          text: "tutto è compiuto,",
        },
        {
          notes: "",
          text: "tutto è compiuto“.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol7.1       La-",
          text: "MARIA MADRE MIA, MARIA MADRE NOSTRA,",
        },
        {
          notes: "Sol               Mi",
          text: "VIENI A VIVERE CON ME,",
        },
        {
          notes: "Sol          Mi",
          text: "VIENI A CASA MIA,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Vennero dunque i soldati",
        },
        {
          notes: "",
          text: "e vedendo che era morto",
        },
        {
          notes: "",
          text: "non gli spezzarono le gambe,",
        },
        {
          notes: "",
          text: "ma uno dei soldati",
        },
        {
          notes: "",
          text: "gli colpì il fianco con la lancia,",
        },
        {
          notes: "          Fa.2           Mi",
          text: "e subito ne uscì sangue ed acqua,",
        },
        {
          notes: "",
          text: "sangue ed acqua.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-             Sol7.1       La-",
          text: "MARIA MADRE MIA, MARIA MADRE NOSTRA,",
        },
        {
          notes: "Sol                 Mi",
          text: "TU SEI MADRE DELLA CHIESA,",
        },
        {
          notes: "    Mi7.1                Fa.1",
          text: "CHE SORGE DAL FIANCO DI CRISTO:",
        },
        {
          notes: "                    Mi",
          text: "LA SUA SPOSA, NUOVA EVA,",
        },
        {
          notes: "    Mi7.1                Fa.1",
          text: "CHE SORGE DAL FIANCO DI CRISTO:",
        },
        {
          notes: "                    Mi",
          text: "LA SUA SPOSA, NUOVA EVA,",
        },
        {
          notes: "La-             Sol7.1       La-",
          text: "MARIA MADRE MIA, MARIA MADRE NOSTRA,",
        },
        {
          notes: "Sol               Mi",
          text: "VIENI A VIVERE CON ME,",
        },
        {
          notes: "Sol          Mi",
          text: "VIENI A CASA MIA,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU,",
        },
        {
          notes: "Mi7.1                    Fa.1",
          text: "E INSEGNACI AD AMARE TUO FIGLIO",
        },
        {
          notes: "                 Mi",
          text: "COME L'HAI AMATO TU.",
        },
      ],
    },
  ],
  subtitle: "(Gv. 19, 26-34)",
};
