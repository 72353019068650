import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Litanie penitenziali brevi.",
  url_video: "https://www.youtube.com/embed/fQCnrUeyNnc",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che al cieco che gridava a te",
        },
        {
          notes: "              La-",
          text: "hai donato la vista,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che sei entrato in casa di Zaccheo,",
        },
        {
          notes: "              La-",
          text: "portando la salvezza,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                         La-",
          text: "Tu che dalla morte hai chiamato Lazzaro,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "Tu che hai perdonato l'adultera,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che mai hai rifiutato di mangiare",
        },
        {
          notes: "               La-",
          text: "con prostitute e peccatori,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che dalla croce hai perdonato",
        },
        {
          notes: "           La-",
          text: "al buon ladrone,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che sei l'unico che conosce",
        },
        {
          notes: "           La-",
          text: "i nostri peccati,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                    La-",
          text: "Tu che hai amato Giuda fino alla fine,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                      La-",
          text: "Tu che sei l'unico che ama i nemici,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Tu che sei morto e risorto",
        },
        {
          notes: "              La-",
          text: "per la nostra giustificazione,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si7      La-",
          text: "ABBI PIETÀ DI NOI, CHE SIAMO PECCATORI,",
        },
        {
          notes: "   Do     Si7",
          text: "SIGNORE, PIETÀ!",
        },
      ],
    },
  ],
  subtitle: "",
};
