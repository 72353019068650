import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Akedà.",
  url_video: "https://www.youtube.com/embed/bNzE8q97ONU",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                       Re-.1",
          text: "Era ancora notte quando Abramo",
        },
        {
          notes: "                               La-",
          text: "si disponeva a sacrificare suo figlio;",
        },
        {
          notes: "                          Re-.1",
          text: "si guardavano i due negli occhi,",
        },
        {
          notes: "              Mi",
          text: "quando dice Isacco:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-.1         La-",
          text: "AKEDÀ, AKEDÀ, AKEDÀ, AKEDÀ.",
        },
        {
          notes: "   La-           Re-.1",
          text: "LEGAMI, LEGAMI FORTE",
        },
        {
          notes: "                         La-",
          text: "PADRE MIO, CHE IO NON RESISTA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                         Re-.1",
          text: "Legami, legami forte, padre mio,",
        },
        {
          notes: "                          La-",
          text: "non sia che per paura io resista,",
        },
        {
          notes: "                            Re-.1",
          text: "e non sia valido il tuo sacrificio,",
        },
        {
          notes: "                     Mi",
          text: "e tutti e due siamo rifiutati!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-.1         La-",
          text: "AKEDÀ, AKEDÀ, AKEDÀ, AKEDÀ.",
        },
        {
          notes: "   La-           Re-.1",
          text: "LEGAMI, LEGAMI FORTE",
        },
        {
          notes: "                         La-",
          text: "PADRE MIO, CHE IO NON RESISTA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Venite e vedete la fede sulla terra,",
        },
        {
          notes: "                     Re-.1",
          text: "vedete la fede sulla terra:",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "un padre che sacrifica il suo unico figlio,",
        },
        {
          notes: "                                       Mi",
          text: "e il figlio carissimo che gli offre la gola!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-.1         La-",
          text: "AKEDÀ, AKEDÀ, AKEDÀ, AKEDÀ.",
        },
        {
          notes: "   La-           Re-.1",
          text: "LEGAMI, LEGAMI FORTE",
        },
        {
          notes: "                         La-",
          text: "PADRE MIO, CHE IO NON RESISTA!",
        },
      ],
    },
  ],
  subtitle: "",
};
