import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto dell'agnello.",
  url_video: "https://www.youtube.com/embed/Bj346F6eoj0",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Degno sei di prendere il Libro",
        },
        {
          notes: "      Sol-      La7",
          text: "e di aprirne i sigilli,",
        },
        {
          notes: "                       Re-",
          text: "perché tu sei stato immolato",
        },
        {
          notes: "                          La7",
          text: "e hai comprato con il tuo sangue",
        },
        {
          notes: "Re-",
          text: "uomini di ogni popolo, lingua, tribù e nazione.",
        },
        {
          notes: "      Fa       La-                Fa",
          text: "E hai fatto di loro per il nostro Dio",
        },
        {
          notes: "   La-           Fa",
          text: "un regno di sacerdoti",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           La-",
          text: "E REGNANO E REGNANO",
        },
        {
          notes: "           Re-",
          text: "SOPRA LA TERRA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Degno sei, agnello sgozzato",
        },
        {
          notes: "    Sol-       La7",
          text: "di prendere il Libro",
        },
        {
          notes: "                Re-",
          text: "e di aprirne i sigilli",
        },
        {
          notes: "                                 La7",
          text: "perché hai comprato con il tuo sangue",
        },
        {
          notes: "Re-",
          text: "uomini di ogni popolo, lingua, tribù e nazione.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "      Fa       La-                Fa",
          text: "E hai fatto di loro per il nostro Dio",
        },
        {
          notes: "   La-           Fa",
          text: "un regno di sacerdoti",
        },
      ],
    },
  ],
  subtitle: "(Ap 5, 9 ss)",
};
