import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Credo apostolico.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re- Fa",
          text: "Credo in Dio Padre Onnipotente,",
        },
        {
          notes: "    Do                      Re-",
          text: "Creatore del cielo e della terra.",
        },
        {
          notes: "   Fa",
          text: "Credo in Gesù Cristo suo unico Figlio e Nostro Signore,",
        },
        {
          notes: "     Do",
          text: "il quale fu concepito di Spirito Santo",
        },
        {
          notes: "   Re-",
          text: "e nacque dalla Vergine Maria.",
        },
        {
          notes: "  Fa",
          text: "Patì sotto Ponzio Pilato,",
        },
        {
          notes: "Do",
          text: "fu crocifisso, morì e fu sepolto,",
        },
        {
          notes: "      Re-",
          text: "e discese agli inferi.",
        },
        {
          notes: "Fa                Do",
          text: "E al terzo giorno risucitò,",
        },
        {
          notes: "    Fa La-      Re-",
          text: "risuscitò dai morti,",
        },
        {
          notes: "Sib                                    La",
          text: "salì al cielo e siede alla destra del Padre",
        },
        {
          notes: "Sib                             La",
          text: "e verrà a giudicare i vivi e i morti.",
        },
        {
          notes: "Re- Fa",
          text: "Credo nello Spirito Santo,",
        },
        {
          notes: "",
          text: "la Santa Chiesa Cattolica,",
        },
        {
          notes: "        La-",
          text: "la comunione dei Santi,",
        },
        {
          notes: "       Do",
          text: "il perdono dei peccati,",
        },
        {
          notes: "           Fa La-       Re-",
          text: "la resurrezione della carne,",
        },
        {
          notes: "Sib      La",
          text: "la Vita Eterna,",
        },
        {
          notes: " Re-",
          text: "Amen!",
        },
      ],
    },
    {
      speaker:
        "N.B: Viene cantato solo dalle comunità che hanno vissuto la tappa della Redditio symboli.",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "",
};
