import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto dei liberati.",
  url_video: "https://www.youtube.com/embed/7uroALjDauw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "    Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do         Sol         Fa          Mi7",
          text: "Lodate il Signore, invocate il suo nome,",
        },
        {
          notes: " Do                Sol       Fa          Mi7",
          text: "proclamate le sue opere fra tutte le nazioni.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "    Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do          Sol          Fa           Mi7",
          text: "Cantate al Signore perché ha fatto grandi cose:",
        },
        {
          notes: "    Do       Sol     Fa  Mi  La- ",
          text: "e questo sia noto su tutta la terra.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "    Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do       Sol       Fa        Mi7",
          text: "Esulta e gioisci, abitante di Sion,",
        },
        {
          notes: "    Do        Sol        Fa      Mi     La- ",
          text: "perché grande in mezzo a te è il Santo d'Israele.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
        {
          notes: "    Do      Sol     Fa  Mi La-",
          text: "ALLELUJA, ALLELUJA, ALLELUJA.",
        },
      ],
    },
  ],
  subtitle: "(Is 12, 4-6)",
};
