import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Misericordia dio, misericordia.",
  url_video: "https://www.youtube.com/embed/TppZWXLXMvg",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    La-",
          text: "Pietà di me, o Dio, secondo la tua misericordia;",
        },
        {
          notes: "                                       Mi-",
          text: "nella tua grande bontà cancella il mio peccato.",
        },
        {
          notes: "                        La-",
          text: "Lavami da tutte le mie colpe,",
        },
        {
          notes: "                  Mi-",
          text: "mondami dal mio peccato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                 La-",
          text: "Riconosco la mia colpa,",
        },
        {
          notes: "                                Mi-",
          text: "il mio peccato mi sta sempre dinanzi.",
        },
        {
          notes: "                           La-",
          text: "Contro te, contro te solo ho peccato,",
        },
        {
          notes: "                               Mi-",
          text: "quello che è male ai tuoi occhi io l'ho fatto",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Perciò sei giusto quando parli,",
        },
        {
          notes: "                    Mi-",
          text: "retto nel tuo giudizio.",
        },
        {
          notes: "                             La-",
          text: "Ecco, nella colpa sono stato generato",
        },
        {
          notes: "                            Mi-",
          text: "nel peccato mi ha concepito mia madre.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Ma tu vuoi la sincerità del cuore",
        },
        {
          notes: "                                Mi-",
          text: "e nell'intimo mi insegni la sapienza.",
        },
        {
          notes: "                            La-",
          text: "Purificami con issòpo e sarò mondo:",
        },
        {
          notes: "                       Mi-",
          text: "lavami e sarò più bianco della neve.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Fammi sentire gioia e allegria,",
        },
        {
          notes: "                                Mi-",
          text: "esulteranno le ossa che hai fiaccato.",
        },
        {
          notes: "                            La-",
          text: "Distogli lo sguardo dai miei peccati,",
        },
        {
          notes: "                   Mi-",
          text: "cancella tutte le mie colpe.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Crea in me, o Dio, un cuore puro,",
        },
        {
          notes: "                            Mi-",
          text: "rinnova in me uno spirito saldo.",
        },
        {
          notes: "                           La-",
          text: "Non respingermi dalla tua presenza",
        },
        {
          notes: "                       Mi-",
          text: "e non privarmi del tuo santo spirito.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Rendimi la gioia di essere salvato,",
        },
        {
          notes: "                            Mi-",
          text: "sostieni in me un animo generoso.",
        },
        {
          notes: "                          La-",
          text: "Insegnerò agli erranti le tue vie",
        },
        {
          notes: "                   Mi-",
          text: "e i peccatori a te ritorneranno.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                          La-",
          text: "Liberami dal sangue, o Dio, mia salvezza,",
        },
        {
          notes: "                               Mi-",
          text: "la mia lingua esalterà la tua giustizia.",
        },
        {
          notes: "                        La-",
          text: "Signore, apri le mie labbra",
        },
        {
          notes: "                       Mi-",
          text: "e la mia bocca proclami la tua lode.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                       La-",
          text: "Poiché non gradisci il sacrificio",
        },
        {
          notes: "                                Mi-",
          text: "e, se offro un olocausto, non lo accetti.",
        },
        {
          notes: "                               La-",
          text: "Uno spirito contrito è sacrificio a Dio,",
        },
        {
          notes: "                             Mi-",
          text: "un cuore affranto e umiliato, Dio,",
        },
        {
          notes: "",
          text: "tu non disprezzi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
        {
          notes: "                Re        Mi-",
          text: "MISERICORDIA, DIO, MISERICORDIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        La-",
          text: "Nel tuo amore fa grazia a Sion,",
        },
        {
          notes: "                        Mi-",
          text: "rialza le mura di Gerusalemme.",
        },
        {
          notes: "                              La-",
          text: "Allora gradirai i sacrifici prescritti,",
        },
        {
          notes: "                        Mi-",
          text: "l'olocausto e l'intera oblazione,",
        },
        {
          notes: "Mi-                   La-",
          text: "allora immoleranno vittime",
        },
        {
          notes: "                 Mi-",
          text: "sopra il tuo altare.",
        },
      ],
    },
  ],
  subtitle: "Sal 50(51)",
};
