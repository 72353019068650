import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alzo gli occhi verso i monti.",
  url_video: "https://www.youtube.com/embed/nPuGbpXQ0wA",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                Si-",
          text: "ALZO GLI OCCHI VERSO I MONTI:",
        },
        {
          notes: "     Do  Re     Sol",
          text: "DA DOVE MI VERRÀ L'AIUTO?",
        },
        {
          notes: "              Do               Sol",
          text: "IL MIO AIUTO VIENE DAL SIGNORE,",
        },
        {
          notes: "     Re    Do      Sol",
          text: "CHE HA FATTO CIELO E TERRA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Sol                     Si-",
          text: "Non lascerà vacillare il tuo piede,",
        },
        {
          notes: "   Do            Re               Sol",
          text: "non si addormenta il tuo custode.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "       Do                 Sol",
          text: "NON SI ADDORMENTA, NON PRENDE SONNO",
        },
        {
          notes: "    Re      Do  Sol",
          text: "IL CUSTODE D'ISRAELE.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                Si-",
          text: "ALZO GLI OCCHI VERSO I MONTI:",
        },
        {
          notes: "     Do  Re     Sol",
          text: "DA DOVE MI VERRÀ L'AIUTO?",
        },
        {
          notes: "              Do               Sol",
          text: "IL MIO AIUTO VIENE DAL SIGNORE,",
        },
        {
          notes: "     Re    Do      Sol",
          text: "CHE HA FATTO CIELO E TERRA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol                     Si",
          text: "Il Signore è il tuo custode,",
        },
        {
          notes: " Do      Re     Sol",
          text: "è come ombra che ti copre.",
        },
        {
          notes: "   Sol              Si",
          text: "Di giorno non ti colpirà il sole,",
        },
        {
          notes: " Do    Re       Sol",
          text: "né la luna di notte.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                Si-",
          text: "ALZO GLI OCCHI VERSO I MONTI:",
        },
        {
          notes: "     Do  Re     Sol",
          text: "DA DOVE MI VERRÀ L'AIUTO?",
        },
        {
          notes: "              Do               Sol",
          text: "IL MIO AIUTO VIENE DAL SIGNORE,",
        },
        {
          notes: "     Re    Do      Sol",
          text: "CHE HA FATTO CIELO E TERRA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "   Sol                     Si",
          text: "Il Signore ti protegge da ogni male,",
        },
        {
          notes: " Do    Re       Sol",
          text: "Egli protegge la tua vita.",
        },
        {
          notes: "   Sol                  Si",
          text: "Il Signore veglia su di te,",
        },
        {
          notes: " Do     Re         Sol",
          text: "quando esci e quando entri.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "     Do                 Sol",
          text: "IL SIGNORE VEGLIA SU DI TE",
        },
        {
          notes: "   Re   Do  Sol ",
          text: "DA ORA E PER SEMPRE.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                Si-",
          text: "ALZO GLI OCCHI VERSO I MONTI:",
        },
        {
          notes: "     Do  Re     Sol",
          text: "DA DOVE MI VERRÀ L'AIUTO?",
        },
        {
          notes: "              Do               Sol",
          text: "IL MIO AIUTO VIENE DAL SIGNORE,",
        },
        {
          notes: "     Re    Do      Sol",
          text: "CHE HA FATTO CIELO E TERRA",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "         Do                 Sol",
          text: "IL MIO AIUTO VIENE DAL SIGNORE,",
        },
        {
          notes: "     Re      Do  Sol ",
          text: "CHE HA FATTO CIELO E TERRA",
        },
      ],
    },
  ],
  subtitle: "Sal 120(121)",
};
