import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Portami in cielo.",
  url_video: "https://www.youtube.com/embed/aSwb2TyjE4M",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi                               Do#-   Sol#",
          text: "Portami in cielo, portami in cielo, oh Signore!",
        },
        {
          notes: "            Mi                    Do#-             Sol#",
          text: "perché il morire, perché il morire è certamente migliore!",
        },
        {
          notes: " La             Mi",
          text: "è certamente migliore",
        },
        {
          notes: "          Si7           Mi",
          text: "stare con te, stare con te!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                               Do#-   Sol#",
          text: "Portami in cielo, portami in cielo, oh Signore!",
        },
        {
          notes: "            Mi                    Do#-             Sol#",
          text: "perché il morire, perché il morire è certamente migliore!",
        },
        {
          notes: " La             Mi",
          text: "è certamente migliore",
        },
        {
          notes: "          Si7           Mi",
          text: "stare con te, stare con te!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "D'una cosa ti prego, questa soltanto ti chiedo:",
        },
        {
          notes: " Do#-                     Sol#",
          text: "non dubitare mai del tuo amore,",
        },
        {
          notes: " La                        Mi",
          text: "non dubitare mai del tuo amore,",
        },
        {
          notes: "          Si7           Mi",
          text: "stare con te, stare con te!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                               Do#-   Sol#",
          text: "Portami in cielo, portami in cielo, oh Signore!",
        },
        {
          notes: "            Mi                    Do#-             Sol#",
          text: "perché il morire, perché il morire è certamente migliore!",
        },
        {
          notes: " La             Mi",
          text: "è certamente migliore",
        },
        {
          notes: "          Si7           Mi",
          text: "stare con te, stare con te!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Oh quanto buono, oh quanto dolce",
        },
        {
          notes: " Do#-                   Sol#",
          text: "è stato il tuo amore per me!",
        },
        {
          notes: "La                       Mi",
          text: "è stato il tuo amore per me!",
        },
        {
          notes: "          Si7               Mi",
          text: "oh quanto buono, oh quanto dolce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Mi                               Do#-   Sol#",
          text: "Portami in cielo, portami in cielo, oh Signore!",
        },
        {
          notes: "            Mi                    Do#-             Sol#",
          text: "perché il morire, perché il morire è certamente migliore!",
        },
        {
          notes: " La             Mi",
          text: "è certamente migliore",
        },
        {
          notes: "          Si7           Mi",
          text: "stare con te, stare con te!",
        },
      ],
    },
  ],
  subtitle: "",
};
