import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "O Signore nostro dio.",
  url_video: "https://www.youtube.com/embed/4IzN5ATIuw0",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                           Sol",
          text: "O SIGNORE NOSTRO DIO QUANTO È GRANDE IL TUO NOME,",
        },
        {
          notes: "        Si7",
          text: "SU TUTTA LA TERRA, IL TUO NOME,",
        },
        {
          notes: "        La-                      Si7",
          text: "SOPRA I CIELI SI INNALZA IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                            Si7",
          text: "Con la bocca dei bimbi e dei lattanti",
        },
        {
          notes: " La-                       Si7",
          text: "affermi la tua potenza o Signore,",
        },
        {
          notes: "Do                                  Si7",
          text: "per ridurre al silenzio nemici e ribelli.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                           Sol",
          text: "O SIGNORE NOSTRO DIO QUANTO È GRANDE IL TUO NOME,",
        },
        {
          notes: "        Si7",
          text: "SU TUTTA LA TERRA, IL TUO NOME,",
        },
        {
          notes: "        La-                      Si7",
          text: "SOPRA I CIELI SI INNALZA IL TUO AMORE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-                                    Si7",
          text: "Se guardo il tuo cielo opera delle tue dita,",
        },
        {
          notes: "             La-                  Si7",
          text: "la luna e le stelle che tu hai fissate,",
        },
        {
          notes: "  Do                           Si7",
          text: "che cosa è l'uomo perché te ne curi,",
        },
        {
          notes: "  Do                                      Si7",
          text: "il figlio dell'uomo perché te ne dia pensiero?",
        },
        {
          notes: " Sol      Si7                              Do",
          text: "Eppure, eppure l'hai fatto poco meno degli angeli,",
        },
        {
          notes: "                               Si7",
          text: "di gloria e di onore lo hai coronato,",
        },
        {
          notes: " Do                          Si7",
          text: "tutto hai messo sotto i suoi piedi.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                           Sol",
          text: "O SIGNORE NOSTRO DIO QUANTO È GRANDE IL TUO NOME,",
        },
        {
          notes: "        Si7",
          text: "SU TUTTA LA TERRA, IL TUO NOME,",
        },
        {
          notes: "        La-                      Si7",
          text: "SOPRA I CIELI SI INNALZA IL TUO AMORE.",
        },
      ],
    },
  ],
  subtitle: "Sal 8",
};
