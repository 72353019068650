import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Il Signore è il mio pastore.",
  url_video: "https://www.youtube.com/embed/J-ZbTB_YFmo",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  La-                Fa",
          text: "Il Signore è il mio pastore",
        },
        {
          notes: "          Mi",
          text: "nulla mi manca,",
        },
        {
          notes: "Mi7",
          text: "in prati d'erba fresca",
        },
        {
          notes: "          La-",
          text: "mi fa riposare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa",
          text: "IL SIGNORE È IL MIO PASTORE",
        },
        {
          notes: "           Mi",
          text: "NULLA MI MANCA,",
        },
        {
          notes: "  Mi7",
          text: "ALLE ACQUE DELLA VITA",
        },
        {
          notes: "      La-",
          text: "MI CONDUCE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol",
          text: "Ristora le mie forze",
        },
        {
          notes: "      Fa",
          text: "e mi porta",
        },
        {
          notes: "                     Mi",
          text: "per il cammino dell'amore.",
        },
        {
          notes: "  Sol",
          text: "Ristora le mie forze",
        },
        {
          notes: "      Fa",
          text: "e mi porta",
        },
        {
          notes: "                      Mi",
          text: "per la gloria del suo Nome.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa",
          text: "IL SIGNORE È IL MIO PASTORE",
        },
        {
          notes: "           Mi",
          text: "NULLA MI MANCA,",
        },
        {
          notes: "  Mi7",
          text: "ALLE ACQUE DELLA VITA",
        },
        {
          notes: "      La-",
          text: "MI CONDUCE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Anche se cammino",
        },
        {
          notes: "            Fa",
          text: "per valli oscure",
        },
        {
          notes: "         Mi",
          text: "non ho timore",
        },
        {
          notes: " Mi7",
          text: "perché tu, perché tu,",
        },
        {
          notes: "                La-",
          text: "perché tu sei con me.",
        },
        {
          notes: " Sol",
          text: "tua verga e il tuo vincastro",
        },
        {
          notes: "      Fa                   Mi",
          text: "mi consolano, ecco, mi consolano.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa",
          text: "IL SIGNORE È IL MIO PASTORE",
        },
        {
          notes: "           Mi",
          text: "NULLA MI MANCA,",
        },
        {
          notes: "  Mi7",
          text: "ALLE ACQUE DELLA VITA",
        },
        {
          notes: "      La-",
          text: "MI CONDUCE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "Prepari una mensa",
        },
        {
          notes: "          Fa",
          text: "davanti a me",
        },
        {
          notes: "                    Mi",
          text: "in faccia ai miei nemic",
        },
        {
          notes: "    Sol                       Fa",
          text: "Cospargi la mia testa di profumo,",
        },
        {
          notes: "                       Mi",
          text: "ecco il mio calice è colmo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa",
          text: "IL SIGNORE È IL MIO PASTORE",
        },
        {
          notes: "           Mi",
          text: "NULLA MI MANCA,",
        },
        {
          notes: "  Mi7",
          text: "ALLE ACQUE DELLA VITA",
        },
        {
          notes: "      La-",
          text: "MI CONDUCE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                     Fa",
          text: "Sì, la gioia e il tuo amore",
        },
        {
          notes: "             Mi",
          text: "mi accompagneranno",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol    Fa",
          text: "SÌ, IO VIVRÒ",
        },
        {
          notes: "",
          text: "NELLA TUA CASA",
        },
        {
          notes: "      Mi",
          text: "ETERNAMENTE.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: " La-                Fa",
          text: "IL SIGNORE È IL MIO PASTORE",
        },
        {
          notes: "           Mi",
          text: "NULLA MI MANCA,",
        },
        {
          notes: "  Mi7",
          text: "ALLE ACQUE DELLA VITA",
        },
        {
          notes: "      La-",
          text: "MI CONDUCE",
        },
      ],
    },
  ],
  subtitle: "Sal 22(23)",
};
