import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Canto dei bambini nella veglia di pasqua.",
  url_video: "https://www.youtube.com/embed/NEr3M1plO9c",
  barrè: "IV",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Che cosa c'è",
        },
        {
          notes: "",
          text: "di diverso questa notte",
        },
        {
          notes: "    Re-            La-",
          text: "da tutte le altre notti?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "DA TUTTE LE ALTRE NOTTI?",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ché tutte le altre notti",
        },
        {
          notes: "",
          text: "andiamo a letto presto",
        },
        {
          notes: "  Re-            La-",
          text: "e non restiamo alzati?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-            La-",
          text: "E NON RESTIAMO ALZATI?",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "Ma questa notte, questa notte",
        },
        {
          notes: "  Mi             La-",
          text: "restiamo tutti alzati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "MA QUESTA NOTTE, QUESTA NOTTE",
        },
        {
          notes: "   Mi            La-",
          text: "RESTIAMO TUTTI ALZATI.",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Che cosa c'è",
        },
        {
          notes: "",
          text: "di diverso questa notte",
        },
        {
          notes: "    Re-            La-",
          text: "da tutte le altre notti?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "DA TUTTE LE ALTRE NOTTI?",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ché tutte le altre notti",
        },
        {
          notes: "",
          text: "andiamo a letto presto",
        },
        {
          notes: "  Re-         La-",
          text: "dopo aver cenato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-         La- ",
          text: "DOPO AVER CENATO",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "Ma questa notte, questa notte",
        },
        {
          notes: "   Mi         La- ",
          text: "abbiamo digiunato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "MA QUESTA NOTTE, QUESTA NOTTE",
        },
        {
          notes: "   Mi         La- ",
          text: "ABBIAMO DIGIUNATO.",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Che cosa c'è",
        },
        {
          notes: "",
          text: "di diverso questa notte",
        },
        {
          notes: "    Re-            La-",
          text: "da tutte le altre notti?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "DA TUTTE LE ALTRE NOTTI?",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Ché tutte le altre notti",
        },
        {
          notes: "",
          text: "andiamo a letto presto",
        },
        {
          notes: "  Re-            La- ",
          text: "e non aspettiamo niente.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "E NON ASPETTIAMO NIENTE.",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "Ma questa notte, questa notte",
        },
        {
          notes: "   Mi            La- ",
          text: "restiamo ad aspettare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re-              La-",
          text: "MA QUESTA NOTTE, QUESTA NOTTE",
        },
        {
          notes: "   Mi            La- ",
          text: "RESTIAMO AD ASPETTARE.",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "Che cosa c'è",
        },
        {
          notes: "",
          text: "di diverso questa notte",
        },
        {
          notes: "    Re-            La-",
          text: "da tutte le altre notti?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "DA TUTTE LE ALTRE NOTTI?",
        },
      ],
    },
    {
      speaker: "B.",
      mode: "normal",
      lines: [
        {
          notes: "     Re-",
          text: "Per restare alzati,",
        },
        {
          notes: "      La-",
          text: "per restare digiuni,",
        },
        {
          notes: "      Mi            La-",
          text: "per restare ad aspettare.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "     Re-",
          text: "PER RESTARE ALZATI,",
        },
        {
          notes: "      La-",
          text: "PER RESTARE DIGIUNI,",
        },
        {
          notes: "      Mi            La-",
          text: "PER RESTARE AD ASPETTARE.",
        },
      ],
    },
  ],
  subtitle: "(Melodia ebraica)",
};
