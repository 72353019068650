import ChordGraph from "../../Components/ChordGraph/ChordGraph";
import Img from "../../Components/Img/Img";
import PageLayout from "../../Components/PageLayout/PageLayout";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import "./GuideScreen_GuitarIntro.scss";

export const GuideNiceTable = (props: { rows: string[][] }) => {
  return (
    <div className="guitar-intro-screen-table-wrap">
      <div className="guitar-intro-screen-table-wrap-inner">
        {props.rows && props.rows.length > 0 && (
          <table>
            <thead>
              <tr>
                {props.rows[0].map((x: string, i: number) => {
                  return (
                    <th key={i} className="guitar-intro-screen-white-cell">
                      {x}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.rows.splice(1).map((x: string[], i: number) => {
                return (
                  <tr>
                    {x.map((y: string, j: number) => {
                      return (
                        <td key={j} className="guitar-intro-screen-white-cell">
                          {y}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const GuideScreen_GuitarIntro = () => {
  return (
    <PageLayout
      backButton={"/guide"}
      title={"Introduzione alla chitarra"}
      content={
        <div>
          In questa pagina si trovano alcuni spunti per capire meglio come
          funziona una chitarra e come suonarla. Non ha la pretesa di essere una
          guida esaustiva, il suo scopo è solo quello di raccogliere tutte le
          informazioni di carattere introduttivo che personalmente hanno aiutato
          me man mano che imparavo a suonare.
          <br />
          <br />
          Saranno coperti argomenti tra cui le varie nomenclature, gli accordi e
          infine i tempi.
          <TitledPageSection
            title={"Le note musicali e le frequenze"}
            content={
              <div>
                Il nostro sistema uditivo è in grando di percepire suoni la cui
                frequenza cada in un intervallo compreso da 20Hz fino a
                20.000Hz, il quale è detto la <strong>banda udibile</strong>.
                Anche i meno esperti sapranno che i musicisti non usano mai
                direttamente le frequenze numeriche, ma sigle che indicano
                frequenze fissate dette <strong>note musicali</strong>.
                <br />
                <br />
                Le note musicali sono 7:
                <div className="centered-evident-content">
                  <strong>Do - Re - Mi - Fa - Sol - La - Si</strong>
                </div>
                Le note "vicine" tra loro sono per convenzione separate da un
                tono, ossia tra Do e Re passa un tono, tra Re e Mi un tono, e
                così via.
                <br />
                <br />
                Questo schema ha due eccezioni, e sono relative alle coppie
                Fa|Mi e Si|Do. Tra queste coppie di note non esistono semitoni,
                e sono qundi separate esse stesse da un semitono invece che da
                un tono.
                <br />
                <br />
                La sequenza delle note si ripete all'infinito, ogni volta
                aumentando il valore di un ottava. L'ottava è l'indice con cui
                indichiamo una specifica sequenza di note (dal Do al Si)
                rispetto una scala assoluta. Ad esempio, se consideriamo la
                sequenza infinita delle note:
                <div className="centered-evident-content">
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer"></span>
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer"></span>
                  Do Re Mi Fa Sol La Si…
                </div>
                Possiamo in realtà distinguere le note con la stessa sigla
                tenendo traccia di quante volte la sequenza si è ripetuta (ossia
                con l'ottava). Indichiamo con la freccia quando l'ottava
                aumenta:
                <div className="centered-evident-content">
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowDown" src="./Assets/up.png" />
                  </span>
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowUp" src="./Assets/up.png" />
                  </span>
                  Do Re Mi Fa Sol La Si…
                </div>
                La prima ottava si conta come ottava zero. Questo indice
                identifica univocamente ogni nota dalle altre. Il Do della 1ª
                ottava è questo:
                <div className="centered-evident-content">
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowDown" src="./Assets/up.png" />
                  </span>
                  <strong style={{ color: "red" }}>Do</strong> Re Mi Fa Sol La
                  Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowUp" src="./Assets/up.png" />
                  </span>
                  Do Re Mi Fa Sol La Si…
                </div>
                Il Do della 2ª ottava è questo:
                <div className="centered-evident-content">
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowDown" src="./Assets/up.png" />
                  </span>
                  Do Re Mi Fa Sol La Si
                  <span className="guitar-intro-screen-spacer">
                    <Img alt="arrowUp" src="./Assets/up.png" />
                  </span>
                  <strong style={{ color: "red" }}>Do</strong> Re Mi Fa Sol La
                  Si…
                </div>
                Quando si vuole indicare l'ottava di una nota, si mette l'indice
                alla fine della sigla. Per esempio, il Do della prima ottava si
                scrive Do1, quello della seconda Do2, etc…
                <br />
                <br />
                Abbiamo quindi appreso che l'unità di misura con cui ci si
                sposta sulla scala è il tono (eccezion fatta per Fa|Mi e Si|Do),
                e ogni volta che la sequenza delle note si ripete aumentiamo di
                un ottava.
                <br />
                <br />
                Nello standard internazionale, la frequenza di riferimento è
                fissata a 440Hz (il La4, ossia il La della 4ª ottava). In base a
                questa convenzione, per calcolare la frequenza delle altre note
                si utilizza la seguente formula:
                <div className="guitar-intro-screen-center-image">
                  <Img
                    style={{ maxHeight: "5em" }}
                    alt="freq"
                    src="./Assets/formula_frequenze.png"
                  />
                </div>
                Dove N corrispone al n° di semitoni di distanza dalla nota di
                riferimento.
              </div>
            }
          />
          <TitledPageSection
            title={"Toni e semitoni"}
            content={
              <div>
                Molti strumenti, tra cui anche la chitarra, permettono non solo
                di suonare le note piene, ma anche le note alzate o abbassate di
                un semitono. Immaginiamo di voler suonare qualcosa con un tono
                un po più alto del Do ma che non sia proprio un Re, allora ci
                serve una nota intermedia tra Do e Re. Queste note intermedie
                sono chiamate <strong>Diesis</strong> o <strong>Bemolle</strong>
                , dove le prime alzano la nota di un semitono, mentre le seconde
                la abbassano. La notazione per indicare una nota Diesis vuole un
                # accanto alla nota (e.g. Do#), mentre quella per indicare una
                nota Bemolle vuole una b (e.g. Sib).
                <br />
                <br />
                Si tenga a mente che questo principio vale solo nel nostro
                schema musicale occidentale, che è detto
                <strong> sistema temperato</strong>.
                <br />
                <br />
                Per fare un esempio, se volessimo scrivere la nota tra Do e Re,
                potremmo farlo in due modi equivalenti, ossia indicando la nota
                dopo il Do come Do Diesis (quindi Do mezzo tono più alto) o come
                Re Bemolle (quindi Re mezzo tono più basso).
                <br />
                <br />
                In altre parole il Diesis fa fare alle note un mezzo salto in
                avanti sulla scala, mentre il Bemolle un mezzo salto indietro.
                Secondo questo esempio quindi, le due forme per indicare la nota
                tra Do e Re sono:
                <div className="centered-evident-content">
                  <strong>Do#</strong> oppure <strong>Reb</strong>
                </div>
                Da cui è chiaro che
                <strong>Do# e Reb sono la stessa nota</strong>.
              </div>
            }
          />
          <TitledPageSection
            title={"La nomenclatura"}
            content={
              <div>
                Abbiamo già introdotto la nomenclatura per indicare note Diesis
                o Bemolle, tuttavia gli accordi hanno molte varianti: tra le
                varie forme (di cui non riporteremo la lista completa) spesso si
                incontrano le note maggiori, minori, seste, settime e diminuite.
                La seguente tabella riassume le nomenclature appena citate
                usando la nota La come riferimento:
                <GuideNiceTable
                  rows={[
                    ["Maggiore", "Minore", "Sesta", "Settima", "Diminuita"],
                    ["La", "La-", "La6", "La7", "La°"],
                    ["LAd", "LAd-", "LAd6", "LAd7", "LAd°"],
                    ["Lab", "Lab-", "Lab6", "Lab7", "Lab°"],
                  ]}
                />
                Si noti che le note maggiori, per brevità, sono scritte con il
                solo nome della nota. Ad esempio quando leggiamo un "Do" in
                realtà stiamo leggendo un "Do maggiore".
                <br />
                <br />
                Un'altra utile nomenclatura che è utile conoscere è quella
                relativa alla notazione anglosassone delle note:
                <GuideNiceTable
                  rows={[
                    ["Italiano", "Anglosassone"],
                    ["Do", "C"],
                    ["Re", "D"],
                    ["Mi", "E"],
                    ["Fa", "F"],
                    ["Sol", "G"],
                    ["La", "A"],
                    ["Si", "B"],
                  ]}
                />
                In oltre, la notazione Diesis in questo caso usa una piccola
                <strong> "d" </strong>
                invece che la "#", es:
                <div className="centered-evident-content">
                  <strong>Cd</strong> = <strong>Do#</strong>
                </div>
                Per riassumere, riportiamo una tabella che indica le
                nomenclature delle note già introdotte, più le informazioni
                riguardo le frequenze e le ampiezze d'onda relative a ciascuna
                nota:
                <GuideNiceTable
                  rows={[
                    [
                      "Italiano",
                      "Anglosassone",
                      "Frequenza (Hz)",
                      "Ampiezza onda (cm)",
                    ],
                    ["Do0", "C0", "16.35", "2109.89"],
                    ["Do#0/Reb0", "Cd0/Db0", "17.32", "1991.47"],
                    ["Re0", "D0", "18.35", "1879.69"],
                    ["Re#0/Mib0", "Dd0/Eb0", "19.45", "1774.2"],
                    ["Mi0", "E0", "20.6", "1674.62"],
                    ["Fa0", "F0", "21.83", "1580.63"],
                    ["FAd0/Solb0", "Fd0/Gb0", "23.12", "1491.91"],
                    ["Sol0", "G0", "24.5", "1408.18"],
                    ["Sol#0/Lab0", "Gd0/Ab0", "25.96", "1329.14"],
                    ["La0", "A0", "27.5", "1254.55"],
                    ["LAd0/Sib0", "Ad0/Bb0", "29.14", "1184.13"],
                    ["Si0", "B0", "30.87", "1117.67"],
                    ["Do1", "C1", "32.7", "1054.94"],
                    ["Do#1/Reb1", "Cd1/Db1", "34.65", "995.73"],
                    ["Re1", "D1", "36.71", "939.85"],
                    ["Re#1/Mib1", "Dd1/Eb1", "38.89", "887.1"],
                    ["Mi1", "E1", "41.2", "837.31"],
                    ["Fa1", "F1", "43.65", "790.31"],
                    ["FAd1/Solb1", "Fd1/Gb1", "46.25", "745.96"],
                    ["Sol1", "G1", "49", "704.09"],
                    ["Sol#1/Lab1", "Gd1/Ab1", "51.91", "664.57"],
                    ["La1", "A1", "55", "627.27"],
                    ["LAd1/Sib1", "Ad1/Bb1", "58.27", "592.07"],
                    ["Si1", "B1", "61.74", "558.84"],
                    ["Do2", "C2", "65.41", "527.47"],
                    ["Do#2/Reb2", "Cd2/Db2", "69.3", "497.87"],
                    ["Re2", "D2", "73.42", "469.92"],
                    ["Re#2/Mib2", "Dd2/Eb2", "77.78", "443.55"],
                    ["Mi2", "E2", "82.41", "418.65"],
                    ["Fa2", "F2", "87.31", "395.16"],
                    ["FAd2/Solb2", "Fd2/Gb2", "92.5", "372.98"],
                    ["Sol2", "G2", "98", "352.04"],
                    ["Sol#2/Lab2", "Gd2/Ab2", "103.83", "332.29"],
                    ["La2", "A2", "110", "313.64"],
                    ["LAd2/Sib2", "Ad2/Bb2", "116.54", "296.03"],
                    ["Si2", "B2", "123.47", "279.42"],
                    ["Do3", "C3", "130.81", "263.74"],
                    ["Do#3/Reb3", "Cd3/Db3", "138.59", "248.93"],
                    ["Re3", "D3", "146.83", "234.96"],
                    ["Re#3/Mib3", "Dd3/Eb3", "155.56", "221.77"],
                    ["Mi3", "E3", "164.81", "209.33"],
                    ["Fa3", "F3", "174.61", "197.58"],
                    ["FAd3/Solb3", "Fd3/Gb3", "185", "186.49"],
                    ["Sol3", "G3", "196", "176.02"],
                    ["Sol#3/Lab3", "Gd3/Ab3", "207.65", "166.14"],
                    ["La3", "A3", "220", "156.82"],
                    ["LAd3/Sib3", "Ad3/Bb3", "233.08", "148.02"],
                    ["Si3", "B3", "246.94", "139.71"],
                    ["Do4", "C4", "261.63", "131.87"],
                    ["Do#4/Reb4", "Cd4/Db4", "277.18", "124.47"],
                    ["Re4", "D4", "293.66", "117.48"],
                    ["Re#4/Mib4", "Dd4/Eb4", "311.13", "110.89"],
                    ["Mi4", "E4", "329.63", "104.66"],
                    ["Fa4", "F4", "349.23", "98.79"],
                    ["FAd4/Solb4", "Fd4/Gb4", "369.99", "93.24"],
                    ["Sol4", "G4", "392", "88.01"],
                    ["Sol#4/Lab4", "Gd4/Ab4", "415.3", "83.07"],
                    ["La4", "A4", "440", "78.41"],
                    ["LAd4/Sib4", "Ad4/Bb4", "466.16", "74.01"],
                    ["Si4", "B4", "493.88", "69.85"],
                    ["Do5", "C5", "523.25", "65.93"],
                    ["Do#5/Reb5", "Cd5/Db5", "554.37", "62.23"],
                    ["Re5", "D5", "587.33", "58.74"],
                    ["Re#5/Mib5", "Dd5/Eb5", "622.25", "55.44"],
                    ["Mi5", "E5", "659.25", "52.33"],
                    ["Fa5", "F5", "698.46", "49.39"],
                    ["FAd5/Solb5", "Fd5/Gb5", "739.99", "46.62"],
                    ["Sol5", "G5", "783.99", "44.01"],
                    ["Sol#5/Lab5", "Gd5/Ab5", "830.61", "41.54"],
                    ["La5", "A5", "880", "39.2"],
                    ["LAd5/Sib5", "Ad5/Bb5", "932.33", "37"],
                    ["Si5", "B5", "987.77", "34.93"],
                    ["Do6", "C6", "1046.5", "32.97"],
                    ["Do#6/Reb6", "Cd6/Db6", "1108.73", "31.12"],
                    ["Re6", "D6", "1174.66", "29.37"],
                    ["Re#6/Mib6", "Dd6/Eb6", "1244.51", "27.72"],
                    ["Mi6", "E6", "1318.51", "26.17"],
                    ["Fa6", "F6", "1396.91", "24.7"],
                    ["FAd6/Solb6", "Fd6/Gb6", "1479.98", "23.31"],
                    ["Sol6", "G6", "1567.98", "22"],
                    ["Sol#6/Lab6", "Gd6/Ab6", "1661.22", "20.77"],
                    ["La6", "A6", "1760", "19.6"],
                    ["LAd6/Sib6", "Ad6/Bb6", "1864.66", "18.5"],
                    ["Si6", "B6", "1975.53", "17.46"],
                    ["Do7", "C7", "2093", "16.48"],
                    ["Do#7/Reb7", "Cd7/Db7", "2217.46", "15.56"],
                    ["Re7", "D7", "2349.32", "14.69"],
                    ["Re#7/Mib7", "Dd7/Eb7", "2489.02", "13.86"],
                    ["Mi7", "E7", "2637.02", "13.08"],
                    ["Fa7", "F7", "2793.83", "12.35"],
                    ["FAd7/Solb7", "Fd7/Gb7", "2959.96", "11.66"],
                    ["Sol7", "G7", "3135.96", "11"],
                    ["Sol#7/Lab7", "Gd7/Ab7", "3322.44", "10.38"],
                    ["La7", "A7", "3520", "9.8"],
                    ["LAd7/Sib7", "Ad7/Bb7", "3729.31", "9.25"],
                    ["Si7", "B7", "3951.07", "8.73"],
                    ["Do8", "C8", "4186.01", "8.24"],
                    ["Do#8/Reb8", "Cd8/Db8", "4434.92", "7.78"],
                    ["Re8", "D8", "4698.63", "7.34"],
                    ["Re#8/Mib8", "Dd8/Eb8", "4978.03", "6.93"],
                    ["Mi8", "E8", "5274.04", "6.54"],
                    ["Fa8", "F8", "5587.65", "6.17"],
                    ["FAd8/Solb8", "Fd8/Gb8", "5919.91", "5.83"],
                    ["Sol8", "G8", "6271.93", "5.5"],
                    ["Sol#8/Lab8", "Gd8/Ab8", "6644.88", "5.19"],
                    ["La8", "A8", "7040", "4.9"],
                    ["LAd8/Sib8", "Ad8/Bb8", "7458.62", "4.63"],
                    ["Si8", "B8", "7902.13", "4.37"],
                  ]}
                />
              </div>
            }
          />
          <TitledPageSection
            title={"Le note sulla tastiera della chitarra"}
            content={
              <div>
                Passiamo a parlare della tastiera della chitarra. Immaginiamo di
                tenere la chitarra sulle nostre gambe con le corde verso l'alto
                e il manico a sinistra. Vedremo tante sezioni rettangolari
                tagliate orizzontalmente dalle corde. Queste sezioni sono
                chiamate tasti. L'immagine seguente ne da uno schema:
                <div className="guitar-intro-screen-center-image">
                  <Img
                    style={{ maxHeight: "12em" }}
                    alt="map"
                    src="./Assets/notes_map.png"
                  />
                </div>
                Le corde sono numerate da 1ª a 6ª, dove la corda più in basso
                (quella in metallo più spesso) è la 6ª. Lo schema seguente
                indica quali note si suonano se si pizzica una corda tenendo un
                solo dito su un certo tasto. Ad esempio, suonare la 2ª corda a
                vuoto produce la nota Si, mentre suonare la 3ª corda premendo il
                dito sul 2° tasto produce la nota La.
                <br />
                <br />
                Riguardo le corde suonate a vuoto, volendo essere più precisi,
                dovremo dire che queste producono le seguenti note (partendo
                dalla 6ª a scendere):
                <div className="centered-evident-content">
                  Mi2 La2 Re3 Sol3 Si3 Mi4
                </div>
                O più tipicamente in nomenclatura anglosassone, che è preferita
                nell'indicare il tipo di accordatura di una chitarra:
                <div className="centered-evident-content">
                  E2 A2 D3 G3 B3 E4
                </div>
                I pallini in basso sono semplicemente ancore visive per
                orientarsi più facilmente sulla griglia, e compaiono in
                corrispondenza dei tasti 3°, 5°, 7°, 9° e 12°.
                <br />
                <br />
                Ovviamente la chitarra non viene mai suonata nota per nota con
                una sola corda alla volta, ma a gruppi di note chiamati accordi.
                Ogni accordo contiene una nota che è la nota dell'accordo, più
                alcune altre note che aiutano il suono principale.
                <br />
                <br />
                Ad esempio, l'accordo del Do si esegue suonando appunto il Do
                (3° tasto, 5ª corda) più un Mi (2° tasto, 4ª corda) più un altro
                Do di tonalità più alta (1° tasto, 2ª corda). L'accordo
                risultante si schematizza nel seguente modo:
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do" index={0} />
                  </div>
                </div>
                Il numero romano in alto a sinistra indica il capotasto, ossia
                il tasto più in alto da cui l'accordo parte: "I" indica il primo
                tasto disponibile, "II" indica il secondo e così via. L'immagine
                della mano indica con un numero quale dito corrispone a quale
                punto tasto-corda. La fascia in basso indica l'accordatura usata
                per la chitarra, ossia la classica E2 A2 D3 G3 B3 E4.
              </div>
            }
          />
          <TitledPageSection
            title={"Gli accordi"}
            content={
              <div>
                Esistono vari modi di suonare lo stesso accordo sulla chitarra,
                i quali cambiano la dolcezza o il tono del suono risultante. Ad
                esempio, ecco altri due modi per suonare l'accordo del Do.
                Rispetto a quello già visto in precedenza, il primo è più dolce,
                il secondo ha una tonalità più alta.
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do" index={2} />
                  </div>
                </div>
                Si noti l'ultimo accordo appena mostrato. La barra nera su tutte
                le corde legata al primo dito indica che la mano va posta in
                modo tale da premere tutte le note con un solo dito (questo si
                chiama <strong>barré</strong>). Il dito che preme tutti i tasti
                deve premere su tutto il 3° tasto, come indicato dal numero
                romano III. Inizialmente questa versione dell'accordo risulta
                più difficile, ma una volta appresa permette di suonare molti
                altri accordi semplicemente slittando su e giù la mano lungo la
                tastiera.
                <br />
                <br />
                Andiamo ad approfondire questo aspetto e cerchiamo di capire
                meglio come è strutturata la tastiera della chitarra.
                <br />
                <br />
                Suonando sulle tre corde maggiori (6ª,5ª,4ª) possiamo eseguire
                le note indicate sulla 6ª corda usando la posizione delle dita
                del Mi più l'indice a barrè quado possibile (quindi escluso il
                Mi, che avrebbe il barrè fuori dalla tastiera).
                <br />
                <br />
                Ma vediamo un esempio. Consideriamo la sequenza di note:
                <div className="centered-evident-content">
                  <strong>Mi, Fa, Fa#, Sol, Sol#, La</strong>
                </div>
                Si noti negli schemi sottostanti come le dita premono la stessa
                combinazione di punti e come cambia solo l'altezza della mano
                sulla tastiera (letta sul capotasto).
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Mi" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Fa" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Fa#" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sol" index={1} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sol#" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="La" index={1} />
                  </div>
                </div>
                Forse vi sarete accorti che nel primo accordo di questa lista
                (il Mi), a differenza degli altri accordi, lo schema indica le
                dita 1ª-2ª-3ª. Questo perchè generalmente il Mi si esegue in
                quel modo, ma nel caso in cui stiamo facendo slittare la mano
                per variare accordi come in questo esempio, lo possiamo
                considerare invece un 2ª-3ª-4ª con l'indice del barrè tenuto in
                aria.
                <br />
                <br />
                Lo stesso meccanismo è presente sulle tre corde minori
                (3ª,2ª,1ª), tuttavia cambia la posizione delle dita. Su di esse
                è possibile eseguire le note indicate sulla 5ª corda usando la
                posizione delle dita del La più il dito a barrè quado possibile
                (quindi escluso il La). Segue l'esempio per suonare:
                <div className="centered-evident-content">
                  <strong>La, Sib, Si, Do, Do#, Re</strong>
                </div>
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="La" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sib" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Si" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do" index={2} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do#" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Re" index={1} />
                  </div>
                </div>
                Il bello è che questa tecnica vale anche per le varianti
                <strong> minori</strong>o <strong>settime</strong>.
                <br />
                <br />
                Rimaniamo sulle prime tre corde. In questo caso i vari accordi
                "minori" si ottengono slittando il La- invece che il La. Segue
                l'esempio per suonare:
                <div className="centered-evident-content">
                  <strong>La-, Sib-, Si-, Do-</strong>
                </div>
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="La-" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sib-" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Si-" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do-" index={0} />
                  </div>
                </div>
                E analogamente per le "settime":
                <div className="centered-evident-content">
                  <strong>La7, Sib7, Si7, Do7</strong>
                </div>
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="La7" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sib7" index={0} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Si7" index={1} />
                  </div>
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do7" index={1} />
                  </div>
                </div>
                Tutto questo può sembrare eccessivamente complicato all'inizio,
                ma una volta capito il meccanismo non sarà difficile farne uso.
              </div>
            }
          />
          <TitledPageSection
            title={"Tempi"}
            content={
              <div>
                A prima vista potrebbe sembrare che il grosso della difficoltà
                stia nel saper usare la mano che preme sulle corde (e forse è
                vero), ma anche la mano che vibra le corde ha un ruolo
                importante. Se non si impara a gestire bene il tempo si rischia
                di produrre melodie aritmate inascoltabili e rumorose. Per
                imparare i tempi la tecnica migliore è bloccare le corde con la
                mano sinistra e iniziare a suonare le corde in sordina come
                mostrato nel video tutorial allegato.
                <br />
                <br />
                Non preoccuparti se non capisci l'inglese, nel video ci sono
                molte immagini e animazioni che ti aiutano a capire cosa devi
                fare.
                <div className="guitar-intro-iframe-wrap base_border_color">
                  <iframe
                    src="https://www.youtube.com/embed/TH4z_t3u4es"
                    style={{ width: "100%", height: "100%", border: "none" }}
                  ></iframe>
                </div>
                Quando senti di aver acquisito la tecnica di base, puoi passare
                a studiare una tecnica chiamata arpeggio. L'arpeggio consiste
                nel pizzicare all'infinito una sequenza di singole corde,
                solitamente saltando dalla corda più alta usata nell'accordo
                alle corde 3ª,2ª e 1ª.
                <br />
                <br />
                Ad esempio si consideri il seguente accordo di Sol:
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Sol" index={0} />
                  </div>
                </div>
                L'arpeggio di questo accordo si ottiene pizzicando in sequenza
                le corde:
                <div className="centered-evident-content">
                  <strong>6ª 3ª 2ª 1ª 6ª 3ª 2ª 1ª 6ª 3ª 2ª 1ª</strong>
                </div>
                Oppure facendo avanti e indietro sulle corde:
                <div className="centered-evident-content">
                  <strong>6ª 3ª 2ª 1ª 2ª 3ª 6ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
                Oppure facendo avanti e indietro solo sulle ultime tre corde:
                <div className="centered-evident-content">
                  <strong>6ª 3ª 2ª 1ª 2ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
                Se invece consideriamo il primo accordo di Do presentato:
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Do" index={0} />
                  </div>
                </div>
                L'arpeggio di questo accordo si ottiene pizzicando in sequenza
                le corde:
                <div className="centered-evident-content">
                  <strong>5ª 3ª 2ª 1ª 5ª 3ª 2ª 1ª 5ª 3ª 2ª 1ª</strong>
                </div>
                Oppure facendo avanti e indietro sulle corde:
                <div className="centered-evident-content">
                  <strong>5ª 3ª 2ª 1ª 2ª 3ª 5ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
                Oppure facendo avanti e indietro solo sulle ultime tre corde:
                <div className="centered-evident-content">
                  <strong>5ª 3ª 2ª 1ª 2ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
                Caso particolare è il Re nella seguente forma:
                <div className="guitar-intro-centered-chord-graph-wrap">
                  <div className="guitar-intro-centered-chord-graph">
                    <ChordGraph chord="Re" index={0} />
                  </div>
                </div>
                In questo caso l'accordo è già interamente sulle prime tre
                corde, pertanto la quarta corda usata è la prima più alta
                disponibile:
                <div className="centered-evident-content">
                  <strong>4ª 3ª 2ª 1ª 4ª 3ª 2ª 1ª 4ª 3ª 2ª 1ª</strong>
                </div>
                Oppure facendo avanti e indietro sulle corde:
                <div className="centered-evident-content">
                  <strong>4ª 3ª 2ª 1ª 2ª 3ª 4ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
                Oppure facendo avanti e indietro solo sulle ultime tre corde:
                <div className="centered-evident-content">
                  <strong>4ª 3ª 2ª 1ª 2ª 3ª 2ª 1ª 2ª 3ª</strong>
                </div>
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default GuideScreen_GuitarIntro;
