import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Evenu shalom alejem.",
  url_video: "https://www.youtube.com/embed/fOsDIYZHpLk",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-",
          text: "Evenu Shalom alejem",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Sol-",
          text: "EVENU SHALOM ALEJEM",
        },
        {
          notes: "         La     Re-",
          text: "EVENU SHALOM ALEJEM",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "E sia la pace con noi",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Sol-",
          text: "E SIA LA PACE CON NOI",
        },
        {
          notes: "         La     Re-",
          text: "E SIA LA PACE CON NOI",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "E la paix soit avec nous",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "        Sol-",
          text: "E la paix soit avec nous",
        },
        {
          notes: "         La     Re-",
          text: "E la paix soit avec nous",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Y sea la paz con nosotros",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "        Sol-",
          text: "Y sea la paz con nosotros",
        },
        {
          notes: "         La     Re-",
          text: "Y sea la paz con nosotros",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Und sei der friede mit uns",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "        Sol-",
          text: "Und sei der friede mit uns",
        },
        {
          notes: "         La     Re-",
          text: "Und sei der friede mit uns",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "And the peace be with us",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "        Sol-",
          text: "And the peace be with us",
        },
        {
          notes: "         La     Re-",
          text: "And the peace be with us",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Diciamo pace al mondo",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "        Sol-",
          text: "CANTIAMO PACE AL MONDO",
        },
        {
          notes: "         La     Re-",
          text: "LA NOSTRA VITA SIA GIOIOSA",
        },
        {
          notes: "         La7                  Re-",
          text: "ED IL SALUTO “PACE“ GIUNGA FINO A VOI.",
        },
        {
          notes: "         La7                  Re-",
          text: "EVENU SHALOM SHALOM SHALOM ALEJEM",
        },
      ],
    },
  ],
  subtitle: "(canto ebraico)",
};
