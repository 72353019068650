import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Abbracciamo_Gesù.",
  title: "Abbracciamo Gesù.",
  url_video: "https://www.youtube.com/embed/wSL8I5kvTu0",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-   La-   Re   Mi-",
          text: " ",
        },
        {
          notes: "       Do   La-   Si7   ",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                             La-",
          text: " Lasciai un giorno il mio pastore, ",
        },
        {
          notes: "      Re            Do           Si7",
          text: "avevo soldi e tanti sogni da comprare",
        },
        {
          notes: "       Mi-                              La-",
          text: "Ed una sera il cuore mio ha chiesto amore ",
        },
        {
          notes: "         Si7                         Mi-",
          text: "Ed ho scoperto che l’amore non si vende",
        },
        {
          notes: "  Sol                      Si7",
          text: "Così ho corso forte per la via ",
        },
        {
          notes: "                      Do  Re",
          text: "e nell’affanno son ca----to",
        },
        {
          notes: "    Sol              ",
          text: "Ed ho gridato al mondo intero ",
        },
        {
          notes: "Si7                        Do    Sol",
          text: "che cercavo amore ma l’amore non c’è",
        },
        {
          notes: "  La-                 Si7       Si7",
          text: "E stanco e solo son rimasto qui.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-          La-",
          text: "Ti prego abbracciami Gesù",
        },
        {
          notes: "           Si7          Mi-",
          text: "c’è troppo freddo senza te.",
        },
        {
          notes: "            La-         Re",
          text: "E in questo mondo di tempesta",
        },
        {
          notes: "        Sol         Do  Mi7",
          text: "puoi salvarmi solo tu.",
        },
        {
          notes: "         La-                 Re",
          text: "E questo ghiaccio che ho nel cuore",
        },
        {
          notes: "     Sol              Do  Mi7",
          text: "puoi scioglierlo solo tu.",
        },
        {
          notes: "   La-       Si7    Si7",
          text: "Riscaldami Gesù,",
        },
        {
          notes: "           Mi-          La-",
          text: "ti prego abbracciami Gesù",
        },
        {
          notes: "          Si7         Mi-",
          text: "io non ti lascerò mai più.",
        },
        {
          notes: "        La-         Re",
          text: "E nella barca della vita",
        },
        {
          notes: " Sol          Do  Mi7",
          text: "adesso ci sei tu.",
        },
        {
          notes: "            La-         Re",
          text: "Se il vento soffia così forte",
        },
        {
          notes: "       Sol          Do  Mi7",
          text: "lascio che mi guidi tu.",
        },
        {
          notes: "       La-         Si7  ",
          text: "E come posso naufragare",
        },
        {
          notes: "Si7                  Mi-  La-",
          text: " se al timone ci sei tu.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                             Re   Do  Si7",
          text: "Lascia un giorno il mio pastore, ",
        },
        {
          notes: "                                   Mi-",
          text: "avevo soldi e tanti sogni da comprare.",
        },
        {
          notes: "La-  Si7  Mi-",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Sol                     Si7",
          text: "Gesù io son venuto qui da te ",
        },
        {
          notes: "                           La7  Re    Sol  ",
          text: "perché c’è un grande vuoto den--tro di me.",
        },
        {
          notes: "                        Si7",
          text: "Io come il figliol prodigo",
        },
        {
          notes: "                    La7 Re",
          text: "volevo far tutto da so--lo.",
        },
        {
          notes: "   Sol         ",
          text: "Ma poi ho perso tutto quanto ",
        },
        {
          notes: "Si7                     Do   Sol",
          text: "e a mani vuote torno a te Gesù",
        },
        {
          notes: "  La7      Si7    Si7",
          text: "accettami così.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          Mi-          La-",
          text: "Ti prego abbracciami Gesù",
        },
        {
          notes: "           Si7          Mi-",
          text: "c’è troppo freddo senza te.",
        },
        {
          notes: "            La-         Re",
          text: "E in questo mondo di tempesta",
        },
        {
          notes: "        Sol         Do  Mi7",
          text: "puoi salvarmi solo tu.",
        },
        {
          notes: "         La-                 Re",
          text: "E questo ghiaccio che ho nel cuore",
        },
        {
          notes: "     Sol              Do  Mi7",
          text: "puoi scioglierlo solo tu.",
        },
        {
          notes: "   La-       Si7    Si7",
          text: "Riscaldami Gesù,",
        },
        {
          notes: "           Mi-          La-",
          text: "ti prego abbracciami Gesù",
        },
        {
          notes: "          Si7         Mi-",
          text: "io non ti lascerò mai più.",
        },
        {
          notes: "        La-         Re",
          text: "E nella barca della vita",
        },
        {
          notes: " Sol          Do  Mi7",
          text: "adesso ci sei tu.",
        },
        {
          notes: "            La-         Re",
          text: "Se il vento soffia così forte",
        },
        {
          notes: "       Sol          Do  Mi7",
          text: "lascio che mi guidi tu.",
        },
        {
          notes: "       La-         Si7  ",
          text: "E come posso naufragare",
        },
        {
          notes: "Si7                  Mi-  La-",
          text: " se al timone ci sei tu.",
        },
        {
          notes: "Si7   Mi-",
          text: " ",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
