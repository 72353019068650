import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title:
    "Inno dei vespri del giorno dell'ascenzione e delle lodi dall'ascenzione a pentecoste.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "È ASCESO IL BUON PASTORE",
        },
        {
          notes: "                 La-",
          text: "ALLA DESTRA DEL PADRE",
        },
        {
          notes: "                   Sol",
          text: "VEGLIA IL PICCOLO GREGGE",
        },
        {
          notes: "                 La-",
          text: "CON MARIA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "Dagli splendori eterni",
        },
        {
          notes: "                 La-",
          text: "scende il crisma profetico,",
        },
        {
          notes: "",
          text: "che consacra gli apostoli",
        },
        {
          notes: "             La-",
          text: "araldi del vangelo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "È ASCESO IL BUON PASTORE",
        },
        {
          notes: "                 La-",
          text: "ALLA DESTRA DEL PADRE",
        },
        {
          notes: "                   Sol",
          text: "VEGLIA IL PICCOLO GREGGE",
        },
        {
          notes: "                 La-",
          text: "CON MARIA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "Vieni, o divino Spirito,",
        },
        {
          notes: "                 La-",
          text: "con i tuoi santi doni,",
        },
        {
          notes: "                  Sol",
          text: "e rendi i nostri cuori",
        },
        {
          notes: "                 La-",
          text: "tempio della tua gloria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "È ASCESO IL BUON PASTORE",
        },
        {
          notes: "                 La-",
          text: "ALLA DESTRA DEL PADRE",
        },
        {
          notes: "                   Sol",
          text: "VEGLIA IL PICCOLO GREGGE",
        },
        {
          notes: "                 La-",
          text: "CON MARIA NEL CENACOLO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "O luce di eterna sapienza",
        },
        {
          notes: "              La-",
          text: "rivelaci il mistero",
        },
        {
          notes: "                  Sol",
          text: "del Dio trino ed unico,",
        },
        {
          notes: "                La-",
          text: "fonte d'eterno amore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol La-",
          text: "AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
