import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Lo stesso iddio.",
  url_video: "https://www.youtube.com/embed/Z8uen3nHSJw",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re                    Fa#",
          text: "Lo stesso Iddio che disse:",
        },
        {
          notes: "          Si-",
          text: "“dalle tenebre rifulga la luce“",
        },
        {
          notes: "Fa#",
          text: "è colui che è rifulso nei nostri cuori",
        },
        {
          notes: "             Si-",
          text: "per far risplendere la conoscenza della sua gloria,",
        },
        {
          notes: "   La      Si-",
          text: "che è sul volto di Cristo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "   La      Si-",
          text: "CHE È SUL VOLTO DI CRISTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#",
          text: "Ma noi portiamo questo tesoro in vasi di creta.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "MA NOI PORTIAMO QUESTO TESORO IN VASI DI CRETA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "Affinché sia manifesto",
        },
        {
          notes: "                            La            Si-",
          text: "che la sublimità di questo amore viene da Dio,",
        },
        {
          notes: " Sol                  Fa#",
          text: "viene da Dio e non da noi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Si-",
          text: "Affinché sia manifesto",
        },
        {
          notes: "                            La            Si-",
          text: "che la sublimità di questo amore viene da Dio,",
        },
        {
          notes: " Sol                  Fa#",
          text: "viene da Dio e non da noi.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#",
          text: "Tribolati da ogni parte ma non messi alle strette;",
        },
        {
          notes: "",
          text: "perseguitati ma non abbandonati;",
        },
        {
          notes: "",
          text: "abbattuti ma non annientati.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "2bar",
      lines: [
        {
          notes: "Si-",
          text: "PORTANDO SEMPRE NEL NOSTRO CORPO",
        },
        {
          notes: "             La",
          text: "IL MORIRE DI GESÙ;",
        },
        {
          notes: "      Si-  Sol",
          text: "AFFINCHÉ SIA MANIFESTA IN NOI",
        },
        {
          notes: "               Fa#",
          text: "LA SUA RISURREZIONE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa#",
          text: "Così che quando noi moriamo,",
        },
        {
          notes: "",
          text: "voi ricevete la vita.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "COSÌ CHE QUANDO NOI MORIAMO,",
        },
        {
          notes: "",
          text: "VOI RICEVETE LA VITA.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "2bar",
      lines: [
        {
          notes: "Si-",
          text: "PORTANDO SEMPRE NEL NOSTRO CORPO",
        },
        {
          notes: "             La",
          text: "IL MORIRE DI GESÙ;",
        },
        {
          notes: "      Si-  Sol",
          text: "AFFINCHÉ SIA MANIFESTA IN NOI",
        },
        {
          notes: "               Fa#",
          text: "LA SUA RISURREZIONE.",
        },
      ],
    },
  ],
  subtitle: "(2Cor 4, 6-12)",
};
