import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Non è qui.",
  url_video: "https://www.youtube.com/embed/H5FWWfr99ig",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do          Mi",
          text: "Passato il Sabato,",
        },
        {
          notes: "                                  Fa",
          text: "all’alba del primo giorno della settimana,",
        },
        {
          notes: "                    Re-",
          text: "Maria di Magdala e l’altra Maria",
        },
        {
          notes: "              Mi",
          text: "andarono al sepolcro.",
        },
        {
          notes: "Re-                      Mi",
          text: "Ed ecco ci fu un gran terremoto:",
        },
        {
          notes: " Fa                   Sol",
          text: "un Angelo del Signore, sceso dal cielo,",
        },
        {
          notes: "   Mi",
          text: "rotolò la pietra",
        },
        {
          notes: " Re-           Mi",
          text: "e disse alle donne:",
        },
        {
          notes: "                   Mi°",
          text: "Non abbiate paura. So che cercate Gesù",
        },
        {
          notes: "       Re7",
          text: "il crocifisso.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-    Re7       Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "Re7             Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "Do-             Re",
          text: "Non è qui. È risorto.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                             Re7",
          text: "Andate presto a dire ai suoi discepoli:",
        },
        {
          notes: "                   Sol-",
          text: "È risuscitato dai morti",
        },
        {
          notes: "Do-",
          text: "è risuscitato dai morti,",
        },
        {
          notes: "                 Re7",
          text: "e vi precede in Galilea.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-      Re7    Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "    Re7          Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "Do-           Re",
          text: "Non è qui. È risorto.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-                             Re7",
          text: "Andate presto a dire ai suoi discepoli:",
          msg: "  (L'Ass. ripete)",
        },
        {
          notes: "                   Sol-",
          text: "È risuscitato dai morti",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do-",
          text: "è risuscitato dai morti,",
        },
        {
          notes: "                 Re7",
          text: "e vi precede in Galilea.",
        },
        {
          notes: "Do-      Re",
          text: "là lo vedrete.",
          msg: "  (L'Ass. ripete)",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol-      Re7    Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "    Re7          Sol-",
          text: "Non è qui. È risorto.",
        },
        {
          notes: "Do-           Re",
          text: "Non è qui. È risorto.",
        },
      ],
    },
  ],
  subtitle: "È risorto (Mt 28, 1 ss.)",
};
