import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Omelia pasquale di melitone di sardi.",
  url_video: "https://www.youtube.com/embed/CYqDtyvu8rw",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                              La7",
          text: "Tu hai ricoperto di vergogna la morte,",
        },
        {
          notes: "                              Re-",
          text: "tu hai gettato nel lutto l'inferno,",
        },
        {
          notes: "                    La7",
          text: "hai colpito l'iniquità,",
        },
        {
          notes: "                                   Re-",
          text: "hai strappato all'ingiustizia i suoi figli,",
        },
        {
          notes: " Sib             La7",
          text: "come Mosè al Faraone,",
        },
        {
          notes: " Sib             La7",
          text: "come Mosè al Faraone.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Re-                              La7",
          text: "Tu hai ricoperto di vergogna la morte,",
        },
        {
          notes: "                              Re-",
          text: "tu hai gettato nel lutto l'inferno,",
        },
        {
          notes: "                    La7",
          text: "hai colpito l'iniquità,",
        },
        {
          notes: "                                   Re-",
          text: "hai strappato all'ingiustizia i suoi figli,",
        },
        {
          notes: " Sib             La7",
          text: "come Mosè al Faraone,",
        },
        {
          notes: " Sib             La7",
          text: "come Mosè al Faraone.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                                       La7",
          text: "Tu ci hai passati dalla schiavitù alla libertà,",
        },
        {
          notes: "                                          Re-",
          text: "dalle tenebre alla luce, dalla morte alla vita.",
        },
        {
          notes: "                                 La7",
          text: "Tu sei la Pasqua della nostra salvezza,",
        },
        {
          notes: "                          Re-",
          text: "tu sei l'agnello nato da Maria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                        La7",
          text: "MARIA, AGNELLA SENZA MACCHIA,",
        },
        {
          notes: "                      Re-",
          text: "MARIA, L'INNOCENTE AGNELLA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                       La7",
          text: "Tu sei stato ucciso in Abele,",
        },
        {
          notes: "                         Re-",
          text: "tu sei stato legato in Isacco,",
        },
        {
          notes: "               La7",
          text: "venduto in Giuseppe,",
        },
        {
          notes: "                            Re-",
          text: "abbandonato sulle acque in Mosè",
        },
        {
          notes: "                  La7",
          text: "perseguitato in Davide",
        },
        {
          notes: "                       Re-",
          text: "e deriso in tutti i profeti.",
        },
        {
          notes: "                               La7",
          text: "Tu sei l'agnello che non apre bocca,",
        },
        {
          notes: "                          Re-",
          text: "tu sei l'agnello nato da Maria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                        La7",
          text: "MARIA, AGNELLA SENZA MACCHIA,",
        },
        {
          notes: "                      Re-",
          text: "MARIA, L'INNOCENTE AGNELLA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                        La7",
          text: "Tu sei stato preso dal gregge,",
        },
        {
          notes: "                                        Re-",
          text: "condotto al sacrificio, immolato verso sera,",
        },
        {
          notes: "              La7",
          text: "nella notte sepolto,",
        },
        {
          notes: "                                             Re-",
          text: "il tuo corpo benedetto non sperimentò la corruzione,",
        },
        {
          notes: "                     La7",
          text: "Tu dalla morte sei risorto,",
        },
        {
          notes: "",
          text: "tu hai fatto risorgere l'umanità",
        },
        {
          notes: "                   Re-",
          text: "dal profondo della tomba.",
        },
        {
          notes: "                                 La7",
          text: "Tu sei l'agnello che non apre bocca,",
        },
        {
          notes: "                          Re-",
          text: "tu sei l'agnello nato da Maria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "                        La7",
          text: "MARIA, AGNELLA SENZA MACCHIA,",
        },
        {
          notes: "                      Re-",
          text: "MARIA, L'INNOCENTE AGNELLA.",
        },
      ],
    },
  ],
  subtitle: "",
};
