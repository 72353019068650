import { useEffect, useState } from "react";
import StandardButton from "../StandardButton/StandardButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./ScrollCommands.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { noSleepTrigger } from "../../nosleep";

const SPEEDMAPS: any = {
  "0": 0,
  "1": 0.05,
  "2": 0.1,
  "3": 0.2,
  "4": 0.5,
  "5": 1,
};

let SCROLL_DEGREE_TRACKER: number = 0;
let SCROLL_SPEED_GETTER: number = 0;
let SCROLL_MAX_SPEED = Object.keys(SPEEDMAPS).length - 1;

export interface ScrollCommandsProps {}
var scrollHandler: NodeJS.Timeout | null = null;
const ScrollCommands = (props: ScrollCommandsProps) => {
  const [params] = useSearchParams();
  const [scrollSpeed, setScroolSpeedInner] = useState<number>(0);

  const setScroolSpeed = (speed: number) => {
    setScroolSpeedInner(speed);
    SCROLL_SPEED_GETTER = speed;
  };

  const removeTimeout = () => {
    if (scrollHandler) {
      clearTimeout(scrollHandler);
      scrollHandler = null;
    }
  };

  const scrollContent = () => {
    if (SCROLL_SPEED_GETTER === 0) {
      removeTimeout();
      return;
    }

    let node = document.getElementById("page-layout-node");
    if (node) {
      if (SCROLL_DEGREE_TRACKER < 1) {
        let dynamicSpeed = (window as any)["dynamic-speed-fixer"] ?? 0;
        let speed = SCROLL_SPEED_GETTER + dynamicSpeed;
        if (dynamicSpeed < 0 && speed <= 0) {
          speed = 1;
        }

        SCROLL_DEGREE_TRACKER += SPEEDMAPS[speed];
      } else {
        let was = node.scrollTop;
        node.scrollTop += 1;
        SCROLL_DEGREE_TRACKER = -1;

        // ask speedometers to update their position
        window.document.dispatchEvent(new Event("dynamic-speedometers-syncup"));

        if (was === node.scrollTop) {
          setScroolSpeed(0);
          return;
        }
      }

      startScrolling();
    }
  };

  const startScrolling = () => {
    scrollHandler = setTimeout(() => {
      scrollContent();
    }, 1);
  };

  useEffect(() => {
    noSleepTrigger(scrollSpeed !== 0);

    if (!scrollHandler && scrollSpeed !== 0) {
      startScrolling();
    } else if (scrollSpeed === 0) {
      removeTimeout();
    }
  }, [scrollSpeed]);

  useEffect(() => {
    setScroolSpeed(0);
    let node = document.getElementById("page-layout-node");
    if (node) {
      node.scrollTop = 0;
    }
  }, [params]);

  const handleArrowPress = (e: any) => {
    let key: string = e.detail;
    if (key === "Enter") {
      setScroolSpeed(
        SCROLL_SPEED_GETTER < SCROLL_MAX_SPEED
          ? SCROLL_SPEED_GETTER + 1
          : SCROLL_MAX_SPEED
      );
    }
    if (key === "Backspace") {
      setScroolSpeed(SCROLL_SPEED_GETTER > 0 ? SCROLL_SPEED_GETTER - 1 : 0);
    }
  };

  useEffect(() => {
    window.document.addEventListener("key-pressed", handleArrowPress);

    setScroolSpeed(0);
    return () => {
      window.document.removeEventListener("key-pressed", handleArrowPress);
      noSleepTrigger(false);
      removeTimeout();
    };
  }, []);

  return (
    <div
      className="scroll-commands-main-wrap"
      style={scrollSpeed === 0 ? {} : { opacity: "0.3" }}
    >
      <StandardButton
        disabled={scrollSpeed === 0}
        label=""
        onClick={() => {
          setScroolSpeed(scrollSpeed > 0 ? scrollSpeed - 1 : 0);
        }}
        iconButtonIcon={<ArrowUpwardIcon />}
      />
      <div className="scroll-command-speed-wrap">{scrollSpeed}</div>
      <StandardButton
        disabled={scrollSpeed >= SCROLL_MAX_SPEED}
        label=""
        onClick={() => {
          setScroolSpeed(
            scrollSpeed < SCROLL_MAX_SPEED ? scrollSpeed + 1 : SCROLL_MAX_SPEED
          );
        }}
        iconButtonIcon={<ArrowDownwardIcon />}
      />
    </div>
  );
};

export default ScrollCommands;
