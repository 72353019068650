import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Ecco il mio servo: I canto del servo di Jahvé.",
  url_video: "https://www.youtube.com/embed/PKcqQ4iVvXM",
  barrè: "",
  color: "lightgray",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-             Re-    La-",
          text: "Ecco il mio servo che io elessi,",
        },
        {
          notes: "                 Re-    La-",
          text: "ecco il mio servo che io elessi,",
        },
        {
          notes: "     Mi     Mi7",
          text: "il mio amato",
        },
        {
          notes: "                      La-",
          text: "in lui la mia anima si compiace,",
        },
        {
          notes: "           Mi         La-",
          text: "ecco il mio amato che io elessi.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: " La-             Re-    La-",
          text: "ECCO IL MIO SERVO CHE IO ELESSI,",
        },
        {
          notes: "                 Re-    La-",
          text: "ECCO IL MIO SERVO CHE IO ELESSI,",
        },
        {
          notes: "     Mi     Mi7",
          text: "IL MIO AMATO",
        },
        {
          notes: "                      La-",
          text: "IN LUI LA MIA ANIMA SI COMPIACE,",
        },
        {
          notes: "           Mi         La-",
          text: "ECCO IL MIO AMATO CHE IO ELESSI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " La-                   La7       Re-",
          text: "Farà riposare il mio spirito sopra di lui;",
        },
        {
          notes: "        La-       Mi           La-",
          text: "e annunzierà il diritto alle nazioni,",
        },
        {
          notes: "     Mi     La-       Mi7     La-",
          text: "e annunzierà il diritto alle nazioni.",
        },
        {
          notes: "Do                 La7",
          text: "Non discuterà, nè griderà,",
        },
        {
          notes: "      Fa",
          text: "nè alcuno udrà,",
        },
        {
          notes: "       Mi        La-",
          text: "nelle piazze la sua voce,",
        },
        {
          notes: "La-                         Mi",
          text: "la canna incrinata lui non spezzerà,",
        },
        {
          notes: "                                 La-",
          text: "lo stoppino fumante, lui non spegnerà,",
        },
        {
          notes: "       Mi                 La-",
          text: "fino a che non trionfi il diritto,",
        },
        {
          notes: "       Mi                 La-",
          text: "fino a che non trionfi il diritto.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "La-                         Mi",
          text: "LA CANNA INCRINATA LUI NON SPEZZERÀ,",
        },
        {
          notes: "                                 La-",
          text: "LO STOPPINO FUMANTE, LUI NON SPEGNERÀ,",
        },
        {
          notes: "       Mi                 La-",
          text: "FINO A CHE NON TRIONFI IL DIRITTO,",
        },
        {
          notes: "       Mi                 La-",
          text: "FINO A CHE NON TRIONFI IL DIRITTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Do                 La7",
          text: "Non discuterà, nè griderà,",
        },
        {
          notes: "      Fa",
          text: "nè alcuno udrà,",
        },
        {
          notes: "       Mi        La-",
          text: "nelle piazze la sua voce.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Do                 La7",
          text: "NON DISCUTERÀ, NÈ GRIDERÀ,",
        },
        {
          notes: "      Fa",
          text: "NÈ ALCUNO UDRÀ,",
        },
        {
          notes: "       Mi        La-",
          text: "NELLE PIAZZE LA SUA VOCE.",
        },
        {
          notes: "La-                         Mi",
          text: "LA CANNA INCRINATA LUI NON SPEZZERÀ,",
        },
        {
          notes: "                                 La-",
          text: "LO STOPPINO FUMANTE, LUI NON SPEGNERÀ,",
        },
        {
          notes: "       Mi                 La-",
          text: "FINO A CHE NON TRIONFI IL DIRITTO,",
        },
        {
          notes: "       Mi                 La-",
          text: "FINO A CHE NON TRIONFI IL DIRITTO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "Nel suo nome, nel suo nome,",
        },
        {
          notes: "   Fa      Mi     Mi7     La-",
          text: "porranno le nazioni la speranza.",
        },
      ],
    },
  ],
  subtitle: "(Is. 42, 1-4)",
};
