import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Le sue fondamenta.",
  url_video: "https://www.youtube.com/embed/tEiE8651iQ0",
  barrè: "III",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                                      Fa",
          text: "Le sue fondamenta sono sui monti santi:",
        },
        {
          notes: "",
          text: "il Signore ama le porte di Sion",
        },
        {
          notes: "                            Mi      Si7",
          text: "più di tutte le dimore di Giacobbe.",
        },
        {
          notes: "",
          text: "Di te si dicono cose stupende",
        },
        {
          notes: "        Mi",
          text: "città di Dio!",
        },
        {
          notes: "La-                         Fa",
          text: "Ricorderò Raab e Babilonia,",
        },
        {
          notes: "",
          text: "Palestina, Tiro ed Etiopia,",
        },
        {
          notes: "",
          text: "quando uno è nato in esse,",
        },
        {
          notes: "                      Mi      Si7",
          text: "di dice: “costui è nato là!“",
        },
        {
          notes: "                      Mi",
          text: "Ma di Sion si dice: “madre!“",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-      Mi       La-",
          text: "PERCHÉ TUTTI IN ESSA SONO NATI.",
        },
        {
          notes: "La-     Re-      Mi       La-",
          text: "PERCHÉ TUTTI IN ESSA SONO NATI.",
        },
        {
          notes: "Sol   Fa                      Mi",
          text: "IL SIGNORE LUI STESSO L'HA FONDATA!",
        },
        {
          notes: "Sol   Fa                      Mi",
          text: "IL SIGNORE LUI STESSO L'HA FONDATA!",
        },
        {
          notes: "La-     Sol    Fa      Mi",
          text: "MADRE, MADRE, CITTÀ DI DIO!",
        },
        {
          notes: "La-     Sol    Fa      Mi",
          text: "MADRE, MADRE, CITTÀ DI DIO!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                           Fa",
          text: "Il Signore scrive nel suo libro:",
        },
        {
          notes: "            Mi    Si7",
          text: "costui è nato là;",
        },
        {
          notes: "",
          text: "e danzando canteranno",
        },
        {
          notes: "                        Mi",
          text: "sono in te tutte le mie fonti!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La-     Re-      Mi       La-",
          text: "E DANZANDO, DANZANDO CANTERANNO",
        },
        {
          notes: "La-     Re-      Mi       La-",
          text: "E DANZANDO, DANZANDO CANTERANNO",
        },
        {
          notes: "Sol      Fa             Mi",
          text: "SONO IN TE TUTTE LE MIE FONTI",
        },
        {
          notes: "Sol     Fa              Mi",
          text: "SONO IN TE TUTTE LE MIE FONTI",
        },
        {
          notes: "La-     Sol    Fa      Mi",
          text: "MADRE, MADRE, CITTÀ DI DIO!",
        },
        {
          notes: "La-     Sol    Fa      Mi",
          text: "MADRE, MADRE, CITTÀ DI DIO!",
        },
      ],
    },
  ],
  subtitle: "Sal 86(87)",
};
