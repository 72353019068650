import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Bocca_di_rosa.",
  title: "Bocca di rosa.",
  url_video: "https://www.youtube.com/embed/rYYer-5yViQ",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes:
            "       La-                                Mi               La-",
          text: "La chiamavano Bocca di Rosa, metteva l'amore, metteva l'amore",
        },
        {
          notes: "                                         Mi           La-",
          text: "la chiamavano Bocca di Rosa, metteva l'amore sopra ogni cosa",
        },
        {
          notes: "                                   Mi           La-",
          text: "appena scese alla  stazione del paesino di sant'Ilario",
        },
        {
          notes:
            "                                               Mi            La-",
          text: "tutti si accorsero con uno sguardo che non si trattava di un missionario.",
        },
        {
          notes:
            "         La7               Re-                Sol7             Do",
          text: "C'è chi l'amore lo fa per noia, chi se lo sceglie per professione,",
        },
        {
          notes:
            "          Re-               La-                  Mi         La-",
          text: "Bocca di Rosa né l'uno né l'altro, lei lo faceva per passione.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "          La-                           Mi            La-",
          text: "Ma la passione spesso conduce a soddisfare le proprie voglie",
        },
        {
          notes:
            "                                             Mi             La-",
          text: "senza indagare se il concupito ha il cuore libero oppure ha moglie.",
        },
        {
          notes:
            "                                             Mi            La-",
          text: "E fu così che da un giorno all'altro Bocca di Rosa si tirò addosso",
        },
        {
          notes: "                                   Mi               La-",
          text: "l'ira funesta delle cagnette a cui aveva sottratto l'osso.",
        },
        {
          notes:
            "        La7           Re-               Sol7               Do",
          text: "Ma le comari di un paesino non brillano certo in iniziativa,",
        },
        {
          notes:
            "            Re-               La-           Mi              La-",
          text: "le contromisure fino a quel punto si limitavano all'invettiva.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes:
            "               La-                                Mi             La-",
          text: "Si sa che la gente dà buoni consigli, sentendosi come Gesù nel tempio,",
        },
        {
          notes:
            "                                                   Mi               La-",
          text: "si sa che la gente dà buoni consigli se non può più dare cattivo esempio.",
        },
        {
          notes:
            "                                               Mi              La-",
          text: "Così una vecchia mai stata moglie, senza mai figli senza più voglie,",
        },
        {
          notes:
            "                                             Mi                    La-",
          text: "si prese la briga e di certo il gusto di dare a tutte il consiglio giusto.",
        },
        {
          notes: "        La7           Re-                Sol7            Do",
          text: "E rivolgendosi alle cornute le apostrofò con parole argute:",
        },
        {
          notes:
            "             Re-         La-             Mi                La-",
          text: "“Il furto d'amore sarà punito” disse “dall'ordine costituito”.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes:
            "              La-                              Mi            La-",
          text: "E quelle andarono dal commissario e dissero senza parafrasare:",
        },
        {
          notes:
            "                                                   Mi             La-",
          text: "“Quella schifosa ha già troppi clienti più di un consorzio alimentare”.",
        },
        {
          notes:
            "                                        Mi                La-",
          text: "Ed arrivarono quattro gendarmi con i pennacchi, con i pennacchi,",
        },
        {
          notes: "                                        Mi               La-",
          text: "ed arrivarono quattro gendarmi con i pennacchi e con le armi.",
        },
        {
          notes:
            "           La7               Re-                 Sol7           Do",
          text: "Spesso gli sbirri e i carabinieri al proprio dovere vengono meno",
        },
        {
          notes:
            "              Re-              La-                Mi              La-",
          text: "ma non quando sono in alta uniforme e l'accompagnarono al primo treno.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       La-                              Mi              La-",
          text: "Alla stazione c'erano tutti, dal commissario al sagrestano,",
        },
        {
          notes:
            "                                           Mi                      La-",
          text: "alla stazione c'erano tutti, con gli occhi rossi e il cappello in mano.",
        },
        {
          notes: "                                     Mi             La-",
          text: "A salutare chi per un poco, senza pretese, senza pretese,",
        },
        {
          notes: "                                    Mi          La-",
          text: "a salutare chi per un poco portò l'amore nel paese",
        },
        {
          notes: "          La7       Re-            Sol7        Do",
          text: "c'era un cartello giallo con una scritta nera,",
        },
        {
          notes:
            "            Re-            La-                 Mi            La-",
          text: "diceva: “Addio, Bocca di Rosa, con te se ne parte la primavera”.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes:
            "         La-                               Mi                 La-",
          text: "Ma una notizia un po' originale non ha bisogno di alcun giornale,",
        },
        {
          notes:
            "                                         Mi                 La-",
          text: "come una freccia dall'arco scocca, vola veloce di bocca in bocca.",
        },
        {
          notes: "                                      Mi                La-",
          text: "E alla stazione successiva molta più gente di quando partiva,",
        },
        {
          notes:
            "                                                    Mi          La-",
          text: "chi  manda un bacio, chi getta un fiore, chi si prenota per due ore.",
        },
        {
          notes:
            "              La7             Re-                Sol7                   Do",
          text: "Persino il parroco che non disprezza tra un Miserere e un'estrema unzione",
        },
        {
          notes:
            "         Re-               La-               Mi                La-",
          text: "il bene effimero della bellezza, la vuole accanto in processione:",
        },
        {
          notes:
            "        La-                                 Mi            La-",
          text: "e con la vergine in prima fila, e Bocca di Rosa poco lontano,",
        },
        {
          notes:
            "                                       Mi                 La-",
          text: "si porta a spasso per il paese l'amore sacro e l'amor profano.",
        },
      ],
    },
  ],
  subtitle: "Fabrizio De André",
  sent_for_revision: false,
};
