import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno alla croce gloriosa.",
  url_video: "https://www.youtube.com/embed/NekDvq2v17U",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi             Sol#            Do#-",
          text: "LA CROCE GLORIOSA DEL SIGNORE RISORTO",
        },
        {
          notes: "    Fa#-               Sol#",
          text: "È L'ALBERO DELLA MIA SALVEZZA;",
        },
        {
          notes: " Mi               Sol#       Do#-",
          text: "DI ESSO MI NUTRO, DI ESSO MI DILETTO,",
        },
        {
          notes: "      Fa#-               Si7",
          text: "NELLE SUE RADICI CRESCO,",
        },
        {
          notes: "                    Mi",
          text: "NEI SUOI RAMI MI DISTENDO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol#               La",
          text: "La sua rugiada mi rallegra,",
        },
        {
          notes: "                    Sol#",
          text: "la sua brezza mi feconda,",
        },
        {
          notes: "         Fa#-                  Sol#",
          text: "alla sua ombra ho posto la mia tenda.",
        },
        {
          notes: "      La",
          text: "Nella fame l'alimento, nella sete la fontana,",
        },
        {
          notes: "                      Sol#",
          text: "nella nudità il vestimento.",
        },
        {
          notes: " Fa#-                       Sol#",
          text: "Angusto sentiero, mia strada stretta,",
        },
        {
          notes: " Fa#-",
          text: "scala di Giacobbe, letto di amore",
        },
        {
          notes: "                        Sol#",
          text: "dove ci ha sposato il Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi             Sol#            Do#-",
          text: "LA CROCE GLORIOSA DEL SIGNORE RISORTO",
        },
        {
          notes: "    Fa#-               Sol#",
          text: "È L'ALBERO DELLA MIA SALVEZZA;",
        },
        {
          notes: " Mi               Sol#       Do#-",
          text: "DI ESSO MI NUTRO, DI ESSO MI DILETTO,",
        },
        {
          notes: "      Fa#-               Si7",
          text: "NELLE SUE RADICI CRESCO,",
        },
        {
          notes: "                    Mi",
          text: "NEI SUOI RAMI MI DISTENDO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol#           La",
          text: "Nel timore la difesa,",
        },
        {
          notes: "                     Sol#",
          text: "nell'inciampo il sostegno,",
        },
        {
          notes: "         La",
          text: "nella vittoria la corona,",
        },
        {
          notes: "                       Sol#",
          text: "nella lotta tu sei il premio.",
        },
        {
          notes: " Fa#-          Sol#",
          text: "Albero di vita eterna,",
        },
        {
          notes: " Fa#-           Sol#",
          text: "pilastro dell'universo,",
        },
        {
          notes: "    La",
          text: "ossatura della terra, la tua cima tocca il cielo,",
        },
        {
          notes: "",
          text: "e nelle tue braccia aperte",
        },
        {
          notes: "               Sol#",
          text: "brilla l'amore di Dio.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi             Sol#            Do#-",
          text: "LA CROCE GLORIOSA DEL SIGNORE RISORTO",
        },
        {
          notes: "    Fa#-               Sol#",
          text: "È L'ALBERO DELLA MIA SALVEZZA;",
        },
        {
          notes: " Mi               Sol#       Do#-",
          text: "DI ESSO MI NUTRO, DI ESSO MI DILETTO,",
        },
        {
          notes: "      Fa#-               Si7",
          text: "NELLE SUE RADICI CRESCO,",
        },
        {
          notes: "                    Mi",
          text: "NEI SUOI RAMI MI DISTENDO.",
        },
      ],
    },
  ],
  subtitle: "",
};
