import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno delle lodi di pasqua (da pasqua fino all'ascensione).",
  url_video: "https://www.youtube.com/embed/fxX2YJ1lYwk",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "DAGLI ABISSI DELLA MORTE",
        },
        {
          notes: "                      Si-",
          text: "CRISTO ASCENDE VITTORIOSO",
        },
        {
          notes: "  Sol                  Fa#",
          text: "INSIEME AGLI ANTICHI PADRI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 La",
          text: "Sfolgora il sole di Pasqua,",
        },
        {
          notes: "                      Si-",
          text: "risuona il cielo di canti,",
        },
        {
          notes: "   Sol              Fa#",
          text: "esulta di gioia la terra.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "DAGLI ABISSI DELLA MORTE",
        },
        {
          notes: "                      Si-",
          text: "CRISTO ASCENDE VITTORIOSO",
        },
        {
          notes: "  Sol                  Fa#",
          text: "INSIEME AGLI ANTICHI PADRI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "Accanto al sepolcro vuoto",
        },
        {
          notes: "                    Si-",
          text: "invano veglia il custode:",
        },
        {
          notes: "  Sol          Fa#",
          text: "il Signore è risorto!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "DAGLI ABISSI DELLA MORTE",
        },
        {
          notes: "                      Si-",
          text: "CRISTO ASCENDE VITTORIOSO",
        },
        {
          notes: "  Sol                  Fa#",
          text: "INSIEME AGLI ANTICHI PADRI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-           La",
          text: "O Gesù, re immortale,",
        },
        {
          notes: "                    Si-",
          text: "unisci alla tua vittoria",
        },
        {
          notes: "  Sol          Fa#",
          text: "i rinati nel battesimo!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "DAGLI ABISSI DELLA MORTE",
        },
        {
          notes: "                      Si-",
          text: "CRISTO ASCENDE VITTORIOSO",
        },
        {
          notes: "  Sol                  Fa#",
          text: "INSIEME AGLI ANTICHI PADRI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "Irradia sulla tua Chiesa,",
        },
        {
          notes: "                    Si-",
          text: "pegno di amore e di pace,",
        },
        {
          notes: "  Sol              Fa#",
          text: "la luce della tua Pasqua.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-               La",
          text: "DAGLI ABISSI DELLA MORTE",
        },
        {
          notes: "                      Si-",
          text: "CRISTO ASCENDE VITTORIOSO",
        },
        {
          notes: "  Sol                  Fa#",
          text: "INSIEME AGLI ANTICHI PADRI.",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-                 La",
          text: "SIA GLORIA E ONORE A CRISTO,",
        },
        {
          notes: "                    Si-",
          text: "AL PADRE E AL SANTO SPIRITO,",
        },
        {
          notes: "  Sol             Fa#",
          text: "ORA E NEI SECOLI ETRNI.",
        },
        {
          notes: " Si-   La    Si-",
          text: "AMEN! AMEN! AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
