import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedetta sei tu, Maria.",
  url_video: "https://www.youtube.com/embed/jLSzaczyYwA",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-           Sol    La-",
          text: "Benedetta sei tu, Maria,",
        },
        {
          notes: "              Sol      La-",
          text: "fra tutte le donne, Maria!",
        },
        {
          notes: "                   Sol     La-",
          text: "E benedetto è il frutto, Maria,",
        },
        {
          notes: "                   Sol     La-",
          text: "il frutto del tuo seno, Gesù.",
        },
        {
          notes: "Fa               Mi",
          text: "Maria, tu hai creduto!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "E COM'È MAI CHE LA MADRE DEL SIGNORE",
        },
        {
          notes: "       Sol                 La-",
          text: "VIENE PRESSO DI ME, E COM'È MAI?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                             Mi",
          text: "Perché appena ho sentito la tua voce",
        },
        {
          notes: "Re-                           Mi",
          text: "qualcosa si è mosso dentro di me,",
        },
        {
          notes: "        Re-                  Mi",
          text: "il mio bimbo ha esultato di gioia!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "2bar",
      lines: [
        {
          notes: "La-             Fa",
          text: "MARIA, BEATA MARIA,",
        },
        {
          notes: "                                   Mi",
          text: "TU HAI CREDUTO ALLA PAROLA DEL SIGNORE!",
        },
      ],
    },
    {
      speaker: "",
      mode: "2bar",
      lines: [
        {
          notes: "La-",
          text: "E COM'È MAI CHE LA MADRE DEL SIGNORE",
        },
        {
          notes: "       Sol                 La-",
          text: "VIENE PRESSO DI ME, E COM'È MAI?",
        },
      ],
    },
  ],
  subtitle: "(Lc 1, 42-44)",
};
