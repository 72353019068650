import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedite il Signore.",
  url_video: "https://www.youtube.com/embed/uwsi_c_n77Q",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "La                 Sib La",
          text: "ALLELU, ALLELU, ALLELUJA,",
        },
        {
          notes: "                   Sib La",
          text: "ALLELU, ALLELU, ALLELUJA!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-",
          text: "Benedite il Signore,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "benedite il Signore,",
        },
        {
          notes: "                        La",
          text: "tutti i servi del Signore.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "Benedite il Signore,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "benedite il Signore,",
        },
        {
          notes: "                                   Re-",
          text: "voi che state nella casa del Signore.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALZATE A LUI LE MANI,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "ALZATE A LUI LE MANI,",
        },
        {
          notes: "                   La",
          text: "E BENEDITE IL SIGNORE,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "VOI CHE STATE NELLA CASA DEL SIGNORE",
        },
        {
          notes: "              Re-",
          text: "DURANTE LE NOTTI!",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "          La        Sib   La ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA,",
        },
        {
          notes: "Re-       La        Sib   La ",
          text: "ALLELUJA, ALLELUJA, ALLELUJA!",
        },
        {
          notes: "                  Sib La",
          text: "ALLELU, ALLELU, ALLELUJA,",
        },
        {
          notes: "                  Sib La",
          text: "ALLELU, ALLELU, ALLELUJA!",
        },
      ],
    },
  ],
  subtitle: "Sal 133(134)",
};
