import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Giunti sui fiumi di babilonia.",
  url_video: "https://www.youtube.com/embed/U92Bo1a82Z0",
  barrè: "III",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-        La-         Mi-",
          text: "GIUNTI SUI FIUMI DI BABILONIA",
        },
        {
          notes: "                Si7             Mi-",
          text: "RICORDANDOCI DI TE CI SEDEMMO A PIANGERE;",
        },
        {
          notes: "               La-                Mi-",
          text: "AI SALICI APPENDEMMO LE NOSTRE CHITARRE",
        },
        {
          notes: "                Si7             Mi-",
          text: "RICORDANDOCI DI TE, CI SEDEMMO A PIANGERE.",
        },
        {
          notes: "                La-              Mi-",
          text: "“CANTATE“, CI DICEVANO I NOSTRI NEMICI;",
        },
        {
          notes: "                La-                   Mi-",
          text: "“CANTATE“, CI DICEVANO I NOSTRI OPPRESSORI.",
        },
        {
          notes: "                  Si7                  Mi-",
          text: "CHIEDEVAN CHE CANTASSIMO CANZONI DI ALLEGRIA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Come cantar",
        },
        {
          notes: "                                    Mi-",
          text: "un cantico del Signore in terra straniera?",
        },
        {
          notes: "               Si7",
          text: "Se di te mi dimentico,",
        },
        {
          notes: "                         Mi-",
          text: "che mi si secchi la mano destra;",
        },
        {
          notes: "",
          text: "che mi si attacchi",
        },
        {
          notes: "     La-      Mi-",
          text: "la lingua al palato",
        },
        {
          notes: "               Si7",
          text: "se di te mi dimentico, Gerusalemme,",
        },
        {
          notes: "                      Mi-",
          text: "al colmo della mia allegri",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               La-             Mi-",
          text: "SIGNOR, CHIEDI CONTO AI NOSTRI NEMICI;",
        },
        {
          notes: "                La-               Mi-",
          text: "SIGNOR, CHIEDI CONTO AI NOSTRI OPPRESSORI,",
        },
        {
          notes: "                Si7",
          text: "QUANDO ESSI RIPETEVANO:",
        },
        {
          notes: "                     Mi-",
          text: "“DISTRUGGIAMOLA DAL FONDO“.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re",
          text: "Capital",
        },
        {
          notes: "                   Mi-",
          text: "di Babilonia criminal!",
        },
        {
          notes: "Re                                    Mi-",
          text: "Chi potrà ripagare i mali che ci hai fatto?",
        },
        {
          notes: "             Si7                       Mi-",
          text: "Chi sfracellerà i tuoi figli contro la pietra?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-          La-       Mi-",
          text: "GIUNTI SUI FIUMI DI BABILONIA",
        },
        {
          notes: "              Si7                  Mi-",
          text: "RICORDANDOCI DI TE CI SEDEMMO A PIANGERE;",
        },
        {
          notes: "               La-               Mi-",
          text: "AI SALICI APPENDEMMO LE NOSTRE CHITARRE",
        },
        {
          notes: "                Si7             Mi-",
          text: "RICORDANDOCI DI TE, CI SEDEMMO A PIANGERE.",
        },
      ],
    },
  ],
  subtitle: "Sal 136(137)",
};
