import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Inno delle lodi di avvento (fino al 16 dicembre).",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                   Sol",
          text: "CHIARA UNA VOCE DAL CIELO",
        },
        {
          notes: "                   La-",
          text: "SI DIFFONDE NELLA NOTTE:",
        },
        {
          notes: "                       Sol",
          text: "FUGGANO I SOGNI E LE ANGOSCE",
        },
        {
          notes: "                     La-",
          text: "SPLENDE LA LUCE DI CRISTO",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "desti il cuore dal sonno",
        },
        {
          notes: "                   La-",
          text: "non più turbato dal male,",
        },
        {
          notes: "                Sol",
          text: "un astro nuovo rifulge,",
        },
        {
          notes: "                   La-",
          text: "fra le tenebre del mondo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Sol",
          text: "ECCO L'AGNELLO DI DIO,",
        },
        {
          notes: "                   La-",
          text: "PREZZO DEL NOSTRO RISCATTO:",
        },
        {
          notes: "                  Sol",
          text: "CON FEDE VIVA IMPLORIAMO",
        },
        {
          notes: "                   La-",
          text: "IL SUO PERDONO E LA PACE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Sol",
          text: "Quando alla fine dei tempi",
        },
        {
          notes: "                   La-",
          text: "Cristo verrà nella gloria,",
        },
        {
          notes: "                  Sol",
          text: "dal suo tremendo giudizio",
        },
        {
          notes: "                   La-",
          text: "ci liberi la sua grazia.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                Sol",
          text: "SIA LODE AL CRISTO SIGNORE,",
        },
        {
          notes: "                   La-",
          text: "AL PADRE E AL SANTO SPIRITO,",
        },
        {
          notes: "               Sol",
          text: "COM'ERA NEL PRINCIPIO,",
        },
        {
          notes: "                   La-",
          text: "ORA E NEI SECOLI ETERNI.",
        },
        {
          notes: "Sol La-",
          text: "AMEN.",
        },
      ],
    },
  ],
  subtitle: "",
};
