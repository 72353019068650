import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "È_solo_un_uomo.",
  title: "È solo un uomo.",
  url_video: "https://www.youtube.com/embed/_jUK_CdMCRw",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Re     Sol7  Re  Sol  Mi- Mi7  La7 La",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Fa#-       Sol         Re",
          text: "Io lo so Signore, che vengo da lontano",
        },
        {
          notes: "              Fa#-   Sol           La ",
          text: "prima nel pensiero e poi nella tua mano.",
        },
        {
          notes: "Re          La        Sol           Re",
          text: "Io mi rendo conto che Tu sei la mia vita",
        },
        {
          notes: "  Sol           Mi-        Mi7     La7  La",
          text: "e non mi sembra vero di pregarti così.",
        },
        {
          notes: "Re            Fa#-   Sol             Re",
          text: "Padre di ogni uomo e non ti ho visto mai.",
        },
        {
          notes: "           Fa#-   Sol           La",
          text: "Spirito di vita e nacqui da una donna.",
        },
        {
          notes: "Re            La      Sol          Re",
          text: "Figlio mio fratello e sono solo un uomo",
        },
        {
          notes: "  Sol       Mi-       Mi7        La  La7 La",
          text: "eppure io capisco che Tu sei verità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "    Re           Sol           La     Re7",
          text: "E imparerò a guardare tutto il mondo",
        },
        {
          notes: "        Sol         La             Re    Re7",
          text: "con gli occhi trasparenti di un bambino",
        },
        {
          notes: "    Sol           La          Re      Si7",
          text: "e insegnerò a chiamarti Padre nostro",
        },
        {
          notes: "        Mi7    La7         Re",
          text: "ad ogni figlio che diventa uomo.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re         Fa#-       Sol         Re",
          text: "Io lo so Signore, che Tu mi sei vicino",
        },
        {
          notes: "              Fa#-   Sol             La",
          text: "luce alla mia mente, guida al mio cammino,",
        },
        {
          notes: "Re          La     Sol            Re",
          text: "mano che sorregge, sguardo che perdona",
        },
        {
          notes: "  Sol           Mi-          Mi7     La  La7 La",
          text: "e non mi sembra vero che Tu esista così.",
        },
        {
          notes: "Re          Fa#-  Sol          Re",
          text: "Dove nasce amore, Tu sei la sorgente",
        },
        {
          notes: "             Fa#-   Sol          La",
          text: "dove c'è una croce, Tu sei la speranza",
        },
        {
          notes: "Re               La    Sol          Re",
          text: "dove il tempo ha fine, Tu sei vita eterna",
        },
        {
          notes: "  Sol          Mi-       Mi7        La  La7 La",
          text: "e so che posso sempre contare su di te!",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "    Re          Sol          La   Re7",
          text: "E accoglierò la vita come un dono",
        },
        {
          notes: "   Sol             La             Re  Re7",
          text: "e avrò il coraggio di morire anch'io.",
        },
        {
          notes: "    Sol            La            Re    Si7",
          text: "E incontro a Te verrò col mio fratello",
        },
        {
          notes: "    Mi-           Mi7        La   ",
          text: "che non si sente amato da nessuno.",
        },
        {
          notes: "    Re          Sol          La   Re7",
          text: "E accoglierò la vita come un dono",
        },
        {
          notes: "   Sol             La             Re  Re7",
          text: "e avrò il coraggio di morire anch'io.",
        },
        {
          notes: "    Sol            La            Re    Si7",
          text: "E incontro a Te verrò col mio fratello",
        },
        {
          notes: "    Mi-           La         Re",
          text: "che non si sente amato da nessuno.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
