import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Al risveglio mi sazierò del tuo volto, Signor.",
  url_video: "https://www.youtube.com/embed/QxfI1TIj-q4",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do-          Sol#               Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR,",
        },
        {
          notes: "   Fa-                                 Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "        Fa             Mi",
          text: "Signore ascolta la mia preghiera,",
        },
        {
          notes: "       Fa                        Mi",
          text: "sii attento, Signore, al mio clamore,",
        },
        {
          notes: "La-                             Mi-",
          text: "presta orecchio alla mia supplica:",
        },
        {
          notes: "     La-          Mi-",
          text: "ché le mie labbra non tradiscono Signore;",
        },
        {
          notes: "       Fa                       Mi",
          text: "esca da te la sentenza,",
        },
        {
          notes: "   Fa                  Mi",
          text: "i tuoi occhi vedano,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "i tuoi occhi vedano, la rettitudin",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do-          Sol#               Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR,",
        },
        {
          notes: "   Fa-                                 Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa-                   Do-",
          text: "Scruta Signore il mio cuore,",
        },
        {
          notes: "Fa-                  Do-",
          text: "scrutami di notte Signore",
        },
        {
          notes: "                         Sol",
          text: "provami al grogiuolo, provami al grogiuolo,",
        },
        {
          notes: "         Sol#                     Sol",
          text: "perché tu sai che non c'è malizia in me,",
        },
        {
          notes: "        Sol#                           Sol",
          text: "la mia bocca non parla come fanno gli uomini,",
        },
        {
          notes: "            Fa-",
          text: "perché ho seguito la tua parola,",
        },
        {
          notes: "                           Sol",
          text: "ho evitato la strada del violento;",
        },
        {
          notes: "        Sol#                       Sol",
          text: "al tuo cammino ho adeguato i miei passi,",
        },
        {
          notes: "  Fa-                   Sol",
          text: "e non vacillerò e non vacillerò.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do-          Sol#               Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR,",
        },
        {
          notes: "   Fa-                                 Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Fa-                   Do-",
          text: "A te grido, Signore, rispondimi",
        },
        {
          notes: "Fa-                              Do-",
          text: "porgi l'orecchio, ascolta la mia voce,",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                           Sol",
          text: "mostrami i prodigi del tuo amore,",
        },
        {
          notes: "            Sol#              Sol",
          text: "perché tu salvi chi si affida a te,",
        },
        {
          notes: "       Sol#                              Sol",
          text: "perché tu aiuti chi si appoggia alla tua destra;",
        },
        {
          notes: "         Fa-                     Sol",
          text: "come la pupilla dei tuoi occhi guardami,",
        },
        {
          notes: "    Fa-                      Sol",
          text: "all'ombra delle tue ali nascondimi,",
        },
        {
          notes: "   Sol#               Sol",
          text: "guarda che vogliono ucciderm",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do-          Sol#               Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR,",
        },
        {
          notes: "   Fa-                                 Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Fa-                         Do-",
          text: "Guardali sono chiusi nell'orgoglio,",
        },
        {
          notes: "Fa-                           Do-",
          text: "parlano con arroganza le loro bocche,",
        },
        {
          notes: "                       Sol",
          text: "avanzano contro di me, avanzano contro di me!",
        },
        {
          notes: "Sol#        Sol",
          text: "Aiutami tu, o Signore,",
        },
        {
          notes: "Sol#                             Sol",
          text: "liberami dagli uomini di questo mondo",
        },
        {
          notes: "      Fa-                              Sol",
          text: "dagli uomini cui preme soltanto questa vita;",
        },
        {
          notes: "Sol#                    Sol",
          text: "colmali dei tuoi beni, Signore,",
        },
        {
          notes: "Sol#                     Sol",
          text: "se ne sazino anche i loro figli,",
        },
        {
          notes: "Sol#                    Sol",
          text: "e ne avanzi per i loro bambini,",
        },
        {
          notes: "  Fa-",
          text: "che io al risveglio, che io al risveglio",
        },
        {
          notes: "                           Sol",
          text: "mi sazierò del tuo volto, Signore!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do-          Sol#               Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR,",
        },
        {
          notes: "   Fa-                                 Do-",
          text: "AL RISVEGLIO MI SAZIERÒ DEL TUO VOLTO, SIGNOR.",
        },
      ],
    },
  ],
  subtitle: "Sal 16(17)",
};
