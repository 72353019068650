import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Agnella di dio.",
  url_video: "https://www.youtube.com/embed/7c3jV_PxfSQ",
  barrè: "VI",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                 La-",
          text: "Era la vergine Maria promessa sposa di Giuseppe,",
        },
        {
          notes: " Do",
          text: "quando prima che fossero insieme",
        },
        {
          notes: "                                          Si7",
          text: "si trovò incinta per opera dello Spirito Santo.",
        },
        {
          notes: " Mi-                              La-",
          text: "E Giuseppe che era giusto e non voleva ripudiarla",
        },
        {
          notes: " Do                       Si7",
          text: "decise di rimandarla in segreto.",
        },
        {
          notes: "                         Mi-",
          text: "Che amare sono le acque, Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "AGNELLA DI DIO, UMILE AGNELLA,",
        },
        {
          notes: " Mi-",
          text: "CHE NON RESISTI AL MALE,",
        },
        {
          notes: " La-                                   Si7",
          text: "MADRE DI GESÙ E MADRE NOSTRA, PREGA PER NOI,",
        },
        {
          notes: "          Mi-",
          text: "PREGA PER NOI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "E Maria diede alla luce il suo figlio,",
        },
        {
          notes: "La-",
          text: "lo avvolse in fasce e lo depose in una mangiatoia",
        },
        {
          notes: "Do                                          Si7",
          text: "perché non c'era posto per loro in nessun albergo.",
        },
        {
          notes: "                         Mi-",
          text: "Che amare sono le acque, Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "AGNELLA DI DIO, UMILE AGNELLA,",
        },
        {
          notes: " Mi-",
          text: "CHE NON RESISTI AL MALE,",
        },
        {
          notes: " La-                                   Si7",
          text: "MADRE DI GESÙ E MADRE NOSTRA, PREGA PER NOI,",
        },
        {
          notes: "          Mi-",
          text: "PREGA PER NOI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "E destatosi Giuseppe",
        },
        {
          notes: "La-",
          text: "prese con sé il bambino e sua madre",
        },
        {
          notes: "Do",
          text: "e la notte fuggì in Egitto,",
        },
        {
          notes: "                                      Si7",
          text: "perché Erode cercava il bambino per ucciderlo.",
        },
        {
          notes: "                         Mi-",
          text: "Che amare sono le acque, Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "AGNELLA DI DIO, UMILE AGNELLA,",
        },
        {
          notes: " Mi-",
          text: "CHE NON RESISTI AL MALE,",
        },
        {
          notes: " La-                                   Si7",
          text: "MADRE DI GESÙ E MADRE NOSTRA, PREGA PER NOI,",
        },
        {
          notes: "          Mi-",
          text: "PREGA PER NOI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Un grido si sente in Rama, ahi ahi ahi...",
        },
        {
          notes: "La-",
          text: "un pianto e un lamento grande,",
        },
        {
          notes: "Do",
          text: "è Rachele che piange i suoi figli",
        },
        {
          notes: "                                             Si7",
          text: "e non vuole essere consolata, perché più non sono.",
        },
        {
          notes: "                           Mi-",
          text: "Che amare sono le acque, Maria!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "AGNELLA DI DIO, UMILE AGNELLA,",
        },
        {
          notes: " Mi-",
          text: "CHE NON RESISTI AL MALE,",
        },
        {
          notes: " La-                                   Si7",
          text: "MADRE DI GESÙ E MADRE NOSTRA, PREGA PER NOI,",
        },
        {
          notes: "          Mi-",
          text: "PREGA PER NOI!",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "E l'angelo del Signore apparve a Giuseppe in",
        },
        {
          notes: "",
          text: "Egitto e gli disse:",
        },
        {
          notes: " La-",
          text: "alzati e prendi con te il bambino e sua madre",
        },
        {
          notes: " Do                   Si7",
          text: "e torna nel paese d'Israele,",
        },
        {
          notes: "                                    Mi-",
          text: "perché dall'Egitto ho chiamato mio figlio\".",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Do",
          text: "AGNELLA DI DIO, UMILE AGNELLA,",
        },
        {
          notes: " Mi-",
          text: "CHE NON RESISTI AL MALE,",
        },
        {
          notes: " La-                                   Si7",
          text: "MADRE DI GESÙ E MADRE NOSTRA, PREGA PER NOI,",
        },
        {
          notes: "          Mi-",
          text: "PREGA PER NOI!",
        },
      ],
    },
  ],
  subtitle: "",
};
