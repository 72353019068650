import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import EditorBlockItem, {
  EditorSongBlock,
} from "../../Components/EditorBlockItem/EditorBlockItem";
import PageLayout from "../../Components/PageLayout/PageLayout";
import StandardButton from "../../Components/StandardButton/StandardButton";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import AddIcon from "@mui/icons-material/Add";
import "./EditorScreen.scss";
import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import SongRender from "../../Components/SongRender/SongRender";
import { SongBlockLines, SongElement } from "../../Songs/SongElement";
import ModalDialog from "../../Components/ModalDialog/ModalDialog";
import { addCustomSong } from "../../Utils";

const EditorScreen = () => {
  const [title, setTitle] = useState<string>("");
  const [subTtitle, setSubTitle] = useState<string>("");
  const [urlVideo, setUrlVideo] = useState<string>("");
  const [astMessage, setAstMessage] = useState<string>("");
  const [colorOption, setColorOption] = useState<string>("");
  const [barreOption, setBarreOption] = useState<string>("");
  const [creationStep, setCreationStep] = useState<number>(0);
  const [songBlocks, setSongBlocks] = useState<EditorSongBlock[]>([]);
  const [songErrors, setSongErrors] = useState<string[] | null>(null);
  const [producedSong, setProducedSong] = useState<SongElement | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const validateUrlVideo = (rawUrl: string) => {
    if (rawUrl.indexOf("https://www.youtube.com/watch?") !== -1) {
      return rawUrl.replace("watch?v=", "embed/");
    }
    return "";
  };

  const produceSong = () => {
    let newSongElement: SongElement = {
      id: Math.floor(Math.random() * 999999).toString(),
      title: title.charAt(0).toLocaleUpperCase() + title.substring(1),
      subtitle: subTtitle,
      url_video: validateUrlVideo(urlVideo),
      barrè: barreOption !== "none" ? barreOption : "",
      color: colorOption,
      semantic: "custom",
      blocks: [],
    };

    let refrainBlock: EditorSongBlock | null = null;
    for (let i = 0; i < songBlocks.length; i++) {
      let block: EditorSongBlock = songBlocks[i];
      if (block.useAsRefrain) {
        refrainBlock = { ...block };
      }
    }

    for (let i = 0; i < songBlocks.length; i++) {
      let block: EditorSongBlock = songBlocks[i];
      if (block.copyRefrain && refrainBlock) {
        block = { ...refrainBlock };
      }

      let mode: "upper" | "normal" =
        block.speaker.indexOf("A") !== -1 ? "upper" : "normal";

      let lines: SongBlockLines[] = [];
      for (let j = 0; j < block.lines.length; j++) {
        lines.push({
          notes: block.lines[j].notes,
          text: block.lines[j].text,
        });
      }

      newSongElement.blocks.push({
        speaker: block.speaker,
        lines: lines,
        mode: mode,
      });
    }

    if (astMessage.length > 0) {
      newSongElement.blocks.push({
        speaker: astMessage,
        mode: "ast-message",
        lines: [],
      });
    }

    setProducedSong(newSongElement as SongElement);
  };

  const tryValidateSong = () => {
    let errors: string[] = [];
    let song: EditorSongBlock[] = [...songBlocks];
    if (
      song.filter((x: EditorSongBlock) => x.copyRefrain).length > 0 &&
      song.filter((x: EditorSongBlock) => x.useAsRefrain).length === 0
    ) {
      errors.push(
        "Hai usato blocchi che copiano il ritornello, ma non hai impostato alcun blocco da usare come ritornello."
      );
    }

    song
      .filter((x: EditorSongBlock) => x.containsError)
      .forEach((item: EditorSongBlock, i: number) => {
        errors.push(
          "Il blocco N. " +
            (i + 1).toString() +
            " contiene errori di validazione."
        );
      });

    if (song.filter((x: EditorSongBlock) => x.useAsRefrain).length > 1) {
      errors.push(
        "Hai impostato più di un blocco da usare come ritornello. Il blocco da usare come ritornello deve essere unico."
      );
    }

    if (song.length === 0) {
      errors.push("Devi aggiungere almeno un blocco.");
    }

    setSongErrors(errors);
  };

  const addOneBlock = (isRefrain: boolean) => {
    let blocks: EditorSongBlock[] = [...songBlocks];

    let newBlock: EditorSongBlock = {
      speaker: "",
      lines: [
        {
          notes: "",
          text: "",
        },
      ],
      useAsRefrain: false,
      copyRefrain: isRefrain,
      blockMessage: "",
      containsError: false,
    };

    blocks.push(newBlock);
    setSongBlocks(blocks);
  };

  const updateBlock = (block: EditorSongBlock, index: number) => {
    let blocks: EditorSongBlock[] = [...songBlocks];
    blocks[index] = { ...block };
    setSongBlocks(blocks);
  };

  const removeBlock = (index: number) => {
    let blocks: EditorSongBlock[] = [];
    for (let i = 0; i < songBlocks.length; i++) {
      if (i !== index) {
        blocks.push({ ...songBlocks[i] });
      }
    }
    setSongBlocks(blocks);
  };

  const onBlockChanged = (block: EditorSongBlock | null, index: number) => {
    if (block === null) {
      removeBlock(index);
    } else {
      updateBlock(block, index);
    }
  };

  useEffect(() => {
    if (songBlocks.length === 0) {
      addOneBlock(false);
    }
  }, []);

  return (
    <PageLayout
      title={"Crea un canto"}
      content={
        <div>
          Aggiungi un canto da te creato alla lista dei canti disponibili.
          <MessageBanner
            type="info"
            message="I canti da te creati saranno salvati solo su questo browser."
          />
          <TitledPageSection
            title="Informazioni generali"
            content={
              <div style={creationStep !== 0 ? { opacity: 0.4 } : {}}>
                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    setCreationStep(1);
                  }}
                >
                  <div>
                    <div className="editor-screen-input-wrap">
                      <TextField
                        fullWidth={true}
                        onChange={(e: any) => {
                          setTitle(e.target.value);
                        }}
                        disabled={creationStep !== 0}
                        required={true}
                        value={title}
                        label="Titolo"
                        variant="standard"
                      />
                    </div>
                    <div className="editor-screen-input-wrap">
                      <TextField
                        disabled={creationStep !== 0}
                        fullWidth={true}
                        onChange={(e: any) => {
                          setSubTitle(e.target.value);
                        }}
                        value={subTtitle}
                        label="Sottotitolo"
                        variant="standard"
                      />
                    </div>
                    <div className="editor-screen-input-wrap">
                      <TextField
                        disabled={creationStep !== 0}
                        fullWidth={true}
                        onChange={(e: any) => {
                          setUrlVideo(e.target.value);
                        }}
                        value={urlVideo}
                        label="Url del video YouTube"
                        variant="standard"
                      />
                    </div>
                    <div className="editor-screen-input-wrap">
                      <TextField
                        disabled={creationStep !== 0}
                        fullWidth={true}
                        onChange={(e: any) => {
                          setAstMessage(e.target.value);
                        }}
                        value={astMessage}
                        label="Messaggio con asterisco"
                        variant="standard"
                      />
                    </div>
                    <div className="editor-screen-select-wrap">
                      <FormControl fullWidth>
                        <InputLabel id="color-select-label">
                          Imposta il colore *
                        </InputLabel>
                        <Select
                          disabled={creationStep !== 0}
                          labelId="color-select-label"
                          fullWidth={true}
                          required={true}
                          variant="standard"
                          value={colorOption}
                          onChange={(e: any) => {
                            setColorOption(e.target.value);
                          }}
                        >
                          <MenuItem value={"none"}>Nessun colore</MenuItem>
                          <MenuItem value={"white"}>Canto Bianco</MenuItem>
                          <MenuItem value={"lightgray"}>Canto Grigio</MenuItem>
                          <MenuItem value={"sandybrown"}>
                            Canto Marrone
                          </MenuItem>
                          <MenuItem value={"limegreen"}>Canto Verde</MenuItem>
                          <MenuItem value={"cadetblue"}>Canto Blu</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="editor-screen-select-wrap">
                      <FormControl fullWidth>
                        <InputLabel id="barre-select-label">
                          Imposta il barré *
                        </InputLabel>
                        <Select
                          disabled={creationStep !== 0}
                          labelId="barre-select-label"
                          fullWidth={true}
                          required={true}
                          variant="standard"
                          value={barreOption}
                          onChange={(e: any) => {
                            setBarreOption(e.target.value);
                          }}
                        >
                          <MenuItem value={"none"}>Senza barré</MenuItem>
                          <MenuItem value={"I"}>Barré al I° tasto</MenuItem>
                          <MenuItem value={"II"}>Barré al II° tasto</MenuItem>
                          <MenuItem value={"III"}>Barré al III° tasto</MenuItem>
                          <MenuItem value={"IV"}>Barré al IV° tasto</MenuItem>
                          <MenuItem value={"V"}>Barré al V° tasto</MenuItem>
                          <MenuItem value={"VI"}>Barré al VI° tasto</MenuItem>
                          <MenuItem value={"VII"}>Barré al VII° tasto</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <br />
                  <StandardButton
                    label="Avanti"
                    disabled={creationStep !== 0}
                    onClick={() => {}}
                    formButton={true}
                  />
                </form>
              </div>
            }
          />
          <TitledPageSection
            title="Aggiungi il testo dello spartito"
            content={
              <div style={creationStep !== 1 ? { opacity: 0.4 } : {}}>
                <div className="editor-page-button-on-right">
                  <StandardButton
                    label="Indietro"
                    disabled={creationStep !== 1}
                    onClick={() => {
                      setCreationStep(0);
                    }}
                  />
                </div>
                Inserisci i vari blocchi che compongono il tuo spartito.
                <br />
                <br />
                <div>
                  {songBlocks.length === 0 && (
                    <div>
                      <MessageBanner
                        type="warning"
                        message="Devi aggiungere almeno un blocco per creare un canto."
                      />
                    </div>
                  )}
                  {songBlocks.map((x: EditorSongBlock, i: number) => {
                    return (
                      <EditorBlockItem
                        baseBlock={x}
                        disabled={creationStep !== 1}
                        key={i}
                        index={i}
                        emitBlock={(block: EditorSongBlock | null) => {
                          onBlockChanged(block, i);
                        }}
                      />
                    );
                  })}
                </div>
                <br />
                <div className="editor-screen-on-row-items">
                  <StandardButton
                    label="Avanti"
                    disabled={creationStep !== 1}
                    onClick={() => {
                      tryValidateSong();
                      setCreationStep(2);
                    }}
                  />
                  <div>
                    <StandardButton
                      label="Blocco"
                      startIcon={<AddIcon />}
                      disabled={creationStep !== 1}
                      onClick={() => {
                        addOneBlock(false);
                      }}
                    />
                    <div className="editor-screen-inline-screen"></div>
                    <StandardButton
                      label="Ritornello"
                      startIcon={<AddIcon />}
                      disabled={creationStep !== 1}
                      onClick={() => {
                        addOneBlock(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          />
          <TitledPageSection
            title="Validazione"
            content={
              <div style={creationStep !== 2 ? { opacity: 0.4 } : {}}>
                <div className="editor-page-button-on-right">
                  <StandardButton
                    label="Indietro"
                    disabled={creationStep !== 2}
                    onClick={() => {
                      setCreationStep(1);
                    }}
                  />
                </div>
                {!songErrors && (
                  <MessageBanner
                    type="info"
                    message={
                      "In questa sezione vedrai i risultati della validazione."
                    }
                  />
                )}
                {songErrors && songErrors.length === 0 && (
                  <MessageBanner
                    type="success"
                    message={"Il tuo spartito non contiene errori."}
                  />
                )}
                {songErrors &&
                  songErrors.map((x: string, i: number) => {
                    return <MessageBanner key={i} type="error" message={x} />;
                  })}
                <StandardButton
                  label="Avanti"
                  disabled={
                    creationStep !== 2 ||
                    (songErrors === null ? false : songErrors.length > 0)
                  }
                  onClick={() => {
                    produceSong();
                    setCreationStep(3);
                  }}
                />
              </div>
            }
          />
          <TitledPageSection
            title="Anteprima"
            content={
              <div style={creationStep !== 3 ? { opacity: 0.4 } : {}}>
                <div className="editor-page-button-on-right">
                  <StandardButton
                    label="Indietro"
                    disabled={creationStep !== 3}
                    onClick={() => {
                      setCreationStep(2);
                    }}
                  />
                </div>
                {!producedSong && (
                  <MessageBanner
                    type="info"
                    message={
                      "In questa sezione vedrai l'anteprima del tuo spartito."
                    }
                  />
                )}
                {producedSong && (
                  <div>
                    <SongRender song={producedSong} />
                  </div>
                )}
                <StandardButton
                  label="Salva lo spartito"
                  disabled={creationStep !== 3}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                />
              </div>
            }
          />
          <TitledPageSection
            title="Risultato"
            content={
              <div style={creationStep !== 4 ? { opacity: 0.4 } : {}}>
                {creationStep !== 4 && (
                  <MessageBanner
                    type="info"
                    message={
                      "In questa sezione vedrai l'esito della creazione dello spartito."
                    }
                  />
                )}
                {creationStep === 4 && (
                  <MessageBanner
                    type="success"
                    message={
                      "Lo spartito è stato salvato. Puoi trovarlo nell'indice."
                    }
                  />
                )}
              </div>
            }
          />
          <ModalDialog
            onClose={() => {
              setOpenDialog(false);
            }}
            actionButtons={[
              {
                label: "Annulla",
                onClick: () => {
                  setOpenDialog(false);
                },
              },
              {
                label: "Salva",
                onClick: () => {
                  addCustomSong(producedSong);
                  setOpenDialog(false);
                  setCreationStep(4);
                },
              },
            ]}
            isOpen={openDialog}
            title="Salva lo spartito?"
            content={
              "Se salvi lo spartito non potrai più modificarlo. Salvare lo sparito?"
            }
          />
        </div>
      }
    />
  );
};

export default EditorScreen;
