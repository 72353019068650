import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Questo è il mio comandamento.",
  url_video: "https://www.youtube.com/embed/UJRhi4CoZDQ",
  barrè: "",
  color: "cadetblue",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-  Do                Mi",
          text: "Questo è il mio comandamento:",
        },
        {
          notes: "          La-",
          text: "che vi amiate.",
        },
        {
          notes: "     Do                Mi",
          text: "Questo è il mio comandamento:",
        },
        {
          notes: "          La-",
          text: "che vi amiate",
        },
        {
          notes: "       Mi",
          text: "gli uni gli altri,",
        },
        {
          notes: "                La-",
          text: "come io vi ho amato,",
        },
        {
          notes: "    Fa          Mi",
          text: "come io vi ho amato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "    Fa          Mi",
          text: "COME IO VI HO AMATO,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7",
          text: "Nessuno ha un amore più grande di questo:",
        },
        {
          notes: "                            Re-",
          text: "dare la vita per i propri amici.",
        },
        {
          notes: "                   Mi",
          text: "Voi siete i miei amici,",
        },
        {
          notes: "    Fa          Mi",
          text: "voi siete miei amici",
        },
        {
          notes: "     La-        Mi",
          text: "voi siete miei amici",
        },
        {
          notes: "     Fa         Mi",
          text: "voi siete miei amici",
        },
        {
          notes: "     Re-              Mi",
          text: "Non voi avete scelto me,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "     Re-              Mi",
          text: "NON VOI AVETE SCELTO ME,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-           Mi",
          text: "ma io ho scelto voi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "  Re-           Mi",
          text: "MA IO HO SCELTO VOI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7",
          text: "Se il mondo vi odia",
        },
        {
          notes: "                            Re-",
          text: "sappiate che prima ha odiato me,",
        },
        {
          notes: "              Mi",
          text: "se il mondo vi odia.",
        },
        {
          notes: "La7                                 Re-",
          text: "Padre, come Tu sei in me e io sono in Te,",
        },
        {
          notes: "                 Mi",
          text: "che siano uno in noi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                 Mi",
          text: "CHE SIANO UNO IN NOI,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                Fa                     Mi",
          text: "perchè il mondo creda che tu mi hai inviato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                Fa                     Mi",
          text: "PERCHÈ IL MONDO CREDA CHE TU MI HAI INVIATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                    Mi",
          text: "Questo è il mio comandamento:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "Re-                    Mi",
          text: "QUESTO È IL MIO COMANDAMENTO:",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "         Re-                 Mi",
          text: "che vi amiate come io vi ho amato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "         Re-                 Mi",
          text: "CHE VI AMIATE COME IO VI HO AMATO.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "     La-          Sol",
          text: "Voi siete i miei amici,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "     La-          Sol",
          text: "VOI SIETE I MIEI AMICI,",
        },
        {
          notes: "     La-           Mi",
          text: "VOI SIETE I MIEI AMICI,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "    Re-              Mi",
          text: "Non voi avete scelto me,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "    Re-              Mi",
          text: "NON VOI AVETE SCELTO ME,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-           Mi",
          text: "ma io ho scelto voi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "  Re-           Mi",
          text: "MA IO HO SCELTO VOI.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La7",
          text: "Se il mondo vi odia",
        },
        {
          notes: "                            Re-",
          text: "sappiate che prima ha odiato me,",
        },
        {
          notes: "              Mi",
          text: "se il mondo vi odia.",
        },
        {
          notes: "La7                                 Re-",
          text: "Padre, come Tu sei in me e io sono in Te,",
        },
        {
          notes: "                 Mi",
          text: "che siano uno in noi,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                 Mi",
          text: "CHE SIANO UNO IN NOI,",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                Fa                     Mi",
          text: "perchè il mondo creda che tu mi hai inviato,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "upper",
      lines: [
        {
          notes: "                Fa                     Mi",
          text: "PERCHÈ IL MONDO CREDA CHE TU MI HAI INVIATO.",
        },
      ],
    },
  ],
  subtitle: "(Gv. 15,12..13.16.18; 17,21)",
};
