import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alzate o porte.",
  url_video: "https://www.youtube.com/embed/pE--yt9rXDw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Del Signore è la terra",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "Del Signore è la terra",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "E tutto quello che contiene",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Sol",
          text: "E tutto quello che contiene",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La                 Si",
          text: "Il mondo e i suoi abitanti",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi",
          text: "ALZATE O PORTE",
        },
        {
          notes: "   Sol",
          text: "I VOSTRI FRONTONI",
        },
        {
          notes: "           La",
          text: "ALZATELI ANCORA",
        },
        {
          notes: "                 Mi",
          text: "DEVE ENTRARE IL RE DELLA GLORIA, DELLA GLORIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  La-                   Mi-",
          text: "Chi salirà al monte del Signore?",
        },
        {
          notes: "Mi-     La-                Mi-",
          text: "Chi entrerà nel suo santo luogo?",
        },
        {
          notes: "  Si-",
          text: "L'innocente di mani ed il puro di cuore",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Si-",
          text: "L'INNOCENTE DI MANI ED IL PURO DI CUORE",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                            La",
          text: "E chi non volge l'anima sua a vanità",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "    Si",
          text: "A VANITÀ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                  Si-",
          text: "E noi siamo la sua Chiesa",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                  Si-",
          text: "E noi siamo la sua Chiesa",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        Si-",
          text: "Che oggi viene alla tua presenza",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                        Si-",
          text: "Che oggi viene alla tua presenza",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol                                 La",
          text: "Che cerca il tuo volto, o Dio di Giacobbe",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "           Si",
          text: "O DIO DI GIACOBBE.",
        },
        {
          notes: "Mi",
          text: "ALZATE O PORTE",
        },
        {
          notes: "   Sol",
          text: "I VOSTRI FRONTONI",
        },
        {
          notes: "           La",
          text: "ALZATELI ANCORA",
        },
        {
          notes: "                 Mi",
          text: "DEVE ENTRARE IL RE DELLA GLORIA, DELLA GLORIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  La-               Mi-",
          text: "Chi è questo re della gloria?",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-  La-               Mi-",
          text: "Chi è questo re della gloria?",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "È il Signore, il forte, l'eroe",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Si-",
          text: "È il Signore, il forte, l'eroe",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: " Sol                           La",
          text: "È il Signore potente in battaglia",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "              Si",
          text: "POTENTE IN BATTAGLIA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-            Fa#-",
          text: "È il Signore delle schiere",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-            Fa#-",
          text: "È il Signore delle schiere",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Sol               La",
          text: "È lui il Re della gloria",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "             Si",
          text: "IL RE DELLA GLORIA!",
        },
        {
          notes: "Mi",
          text: "ALZATE O PORTE",
        },
        {
          notes: "   Sol",
          text: "I VOSTRI FRONTONI",
        },
        {
          notes: "           La",
          text: "ALZATELI ANCORA",
        },
        {
          notes: "                 Mi",
          text: "DEVE ENTRARE IL RE DELLA GLORIA, DELLA GLORIA",
        },
      ],
    },
  ],
  subtitle: "Sal 23(24)",
};
