import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Jahvè tu sei il mio dio.",
  url_video: "https://www.youtube.com/embed/7lNZWvR1YI8",
  barrè: "II",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Fa",
          text: "Benedirò il tuo nome",
        },
        {
          notes: "                  Fa",
          text: "benedirò il tuo nome",
        },
        {
          notes: "                 Mi7",
          text: "benedirò il tuo nome.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-               Fa",
          text: "Distruggerai la morte",
        },
        {
          notes: "",
          text: "per sempre,",
        },
        {
          notes: "                 Sol",
          text: "asciugherai le lacrime",
        },
        {
          notes: "            Fa",
          text: "da tutti i volti",
        },
        {
          notes: "            Mi7",
          text: "da tutti i volti",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                         Fa",
          text: "Già si sentono i passi dei poveri",
        },
        {
          notes: "               Sol",
          text: "i passi degli umili",
        },
        {
          notes: "               Fa",
          text: "i passi degli umili",
        },
        {
          notes: "               Mi7",
          text: "il resto di Giacobbe",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
        {
          notes: "La-                  Mi7",
          text: "JAHVÈ, TU SEI IL MIO DIO",
        },
        {
          notes: "           La-",
          text: "IO TI ESALTERÒ",
        },
      ],
    },
  ],
  subtitle: "(Is 25, 1-8)",
};
