import MessageBanner from "../../Components/MessageBanner/MessageBanner";
import PageLayout from "../../Components/PageLayout/PageLayout";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import "./OptionsScreen.scss";
import { getOptions, resetOptions, setOptions } from "../../Utils";
import {
  OPTION_ALLOW_COMMAND_BAR,
  OPTION_ALLOW_EXTRA_SONGS,
  OPTION_ALLOW_RANDOM_SONGS,
  OPTION_THEME_COLOR,
} from "../../Constants";
import TitledPageSection from "../../Components/TitledPageSection/TitledPageSection";
import StandardButton from "../../Components/StandardButton/StandardButton";
import { useState } from "react";

const OptionsScreen = () => {
  const [reload, setReload] = useState<boolean>(false);

  return (
    <PageLayout
      title={"Opzioni"}
      content={
        <div>
          Personalizza l'App.
          <TitledPageSection
            title={"Opzioni generali"}
            content={
              <div>
                {reload && (
                  <div className="options-screen-spinner">
                    <CircularProgress />
                  </div>
                )}
                {!reload && (
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e: any) => {
                              setOptions(
                                OPTION_ALLOW_COMMAND_BAR,
                                e.target.checked
                              );
                            }}
                            defaultChecked={
                              getOptions()[OPTION_ALLOW_COMMAND_BAR] ?? true
                            }
                          />
                        }
                        label={"Barra di comando negli spartiti."}
                      />
                    </FormGroup>
                    <div className="options-screen-hint-label">
                      La barra di comando permette di esegure una varietà di
                      azioni quando si visualizza uno spartito.
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e: any) => {
                              setOptions(
                                OPTION_ALLOW_EXTRA_SONGS,
                                e.target.checked
                              );
                            }}
                            defaultChecked={
                              getOptions()[OPTION_ALLOW_EXTRA_SONGS] ?? false
                            }
                          />
                        }
                        label={"Consenti spartiti extra."}
                      />
                    </FormGroup>
                    <div className="options-screen-hint-label">
                      Gli spartiti extra sono spartiti di canzoni non
                      liturgiche. Si tratta di spartiti di pezzi famosi, a volte
                      riadattati per la chitarra.
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e: any) => {
                              setOptions(
                                OPTION_ALLOW_RANDOM_SONGS,
                                e.target.checked
                              );
                            }}
                            defaultChecked={
                              getOptions()[OPTION_ALLOW_RANDOM_SONGS] ?? false
                            }
                          />
                        }
                        label={"Consenti funzionalità canti casuali."}
                      />
                    </FormGroup>
                    <div className="options-screen-hint-label">
                      Se attiva, un bottone per aprire canti casuali sarà
                      aggiunto nel menu di navigazione sotto la voce
                      Navigazione.
                    </div>
                    <FormControl fullWidth>
                      <InputLabel id="color-select-label">
                        Palette dei colori
                      </InputLabel>
                      <Select
                        labelId="color-select-label"
                        fullWidth={true}
                        variant="standard"
                        defaultValue={
                          getOptions()[OPTION_THEME_COLOR] ?? "desert"
                        }
                        onChange={(e: any) => {
                          setOptions(OPTION_THEME_COLOR, e.target.value);
                        }}
                      >
                        <MenuItem value="desert">Deserto</MenuItem>
                        <MenuItem value="cold">Freddo</MenuItem>
                        <MenuItem value="cinder">Cenere</MenuItem>
                        <MenuItem value="apple">Mela</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="options-screen-hint-label">
                      La palette determina i colori usati in tutta l'App.
                    </div>
                    <StandardButton
                      onClick={() => {
                        setReload(true);
                        resetOptions();
                        setTimeout(() => {
                          try {
                            setReload(false);
                          } catch (e) {}
                        }, 1000);
                      }}
                      label={"Resetta le opzioni"}
                    />
                  </div>
                )}
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default OptionsScreen;
