import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Maria di jasna góra.",
  url_video: "https://www.youtube.com/embed/pY3N7aWFCJI",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: "  Si",
          text: "MARIA DI JASNA GÓRA,",
        },
        {
          notes: "",
          text: "MATER ABSCONDITA,",
        },
        {
          notes: "",
          text: "AT SEMPER SOLLICITA,",
        },
        {
          notes: "La-",
          text: "TU, REDEMPTORIS MATER,",
        },
        {
          notes: "           Si",
          text: "ORA PRO NOBIS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Sorgi come Debora",
        },
        {
          notes: "La-",
          text: "salvaci come Giuditta,",
        },
        {
          notes: "",
          text: "canta per noi come Anna,",
        },
        {
          notes: "               Si",
          text: "intercedi come Ester.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Si",
          text: "MARIA DI JASNA GÓRA,",
        },
        {
          notes: "",
          text: "MATER ABSCONDITA,",
        },
        {
          notes: "",
          text: "AT SEMPER SOLLICITA,",
        },
        {
          notes: "La-",
          text: "TU, REDEMPTORIS MATER,",
        },
        {
          notes: "           Si",
          text: "ORA PRO NOBIS.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-",
          text: "Donna del cielo,",
        },
        {
          notes: "  La-",
          text: "insegnaci a seguire le orme di Cristo,",
        },
        {
          notes: "",
          text: "le orme del servo,",
        },
        {
          notes: "",
          text: "dell'umile figlio",
        },
        {
          notes: "",
          text: "dell'umile madre,",
        },
        {
          notes: "                      Si",
          text: "della sempre vergine Maria.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Si",
          text: "MARIA DI JASNA GÓRA,",
        },
        {
          notes: "",
          text: "MATER ABSCONDITA,",
        },
        {
          notes: "",
          text: "AT SEMPER SOLLICITA,",
        },
        {
          notes: "La-",
          text: "TU, REDEMPTORIS MATER,",
        },
        {
          notes: "           Si",
          text: "ORA PRO NOBIS.",
        },
      ],
    },
    {
      speaker: "  Si può cantare anche: Maria, Santa Maria",
      mode: "ast-message",
      lines: [],
    },
  ],
  subtitle: "Inno alla Madonna di Czestochowa",
};
