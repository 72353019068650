import NavMenu from "../NavMenu/NavMenu";
import "./AppLayout.scss";

const AppLayout = () => {
  return (
    <div className="app-layout-main-wrap">
      <NavMenu />
    </div>
  );
};

export default AppLayout;
