import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "È la pasqua del Signore.",
  url_video: "https://www.youtube.com/embed/fLg0qekn7Fw",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "È LA PASQUA DEL SIGNORE",
        },
        {
          notes: "     Sol    La-      Mi",
          text: "ESULTIAMO A LUI E CANTIAMO.",
        },
        {
          notes: "   La-       Mi",
          text: "ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                    Mi",
          text: "È Dio di Abramo e di Isacco,",
        },
        {
          notes: "               La-",
          text: "è il Dio di Giacobbe.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "È LA PASQUA DEL SIGNORE",
        },
        {
          notes: "     Sol    La-      Mi",
          text: "ESULTIAMO A LUI E CANTIAMO.",
        },
        {
          notes: "   La-       Mi",
          text: "ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                         Mi",
          text: "È lui che ha aperto il Mar Rosso",
        },
        {
          notes: "                La-",
          text: "e noi siamo passati.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "È LA PASQUA DEL SIGNORE",
        },
        {
          notes: "     Sol    La-      Mi",
          text: "ESULTIAMO A LUI E CANTIAMO.",
        },
        {
          notes: "   La-       Mi",
          text: "ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                       Mi",
          text: "È lui che ci porta nel deserto",
        },
        {
          notes: "                    La-",
          text: "e ci guida nel cammino.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "È LA PASQUA DEL SIGNORE",
        },
        {
          notes: "     Sol    La-      Mi",
          text: "ESULTIAMO A LUI E CANTIAMO.",
        },
        {
          notes: "   La-       Mi",
          text: "ALLELUJA, ALLELUJA",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-                     Mi",
          text: "È lui che Cristo ha mandato",
        },
        {
          notes: "                 La-",
          text: "e lo ha resuscitato.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La-",
          text: "È LA PASQUA DEL SIGNORE",
        },
        {
          notes: "     Sol    La-      Mi",
          text: "ESULTIAMO A LUI E CANTIAMO.",
        },
        {
          notes: "   La-       Mi",
          text: "ALLELUJA, ALLELUJA",
        },
      ],
    },
  ],
  subtitle: "",
};
