import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Alleluja per acclamazioni al vangelo nel tempo di Quaresima.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker:
        "Nel tempo di Quaresima, secondo l'anno liturgico (A - B - C) \ne secondo la settimana (dalla 1ª alla 5ª) si fa l'acclamazione \nprevista dal Lezionario con una delle seguenti melodie: ",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re           Sol                  La ",
          text: "LODE A TE O CRISTO, RE DI ETERNA GLORIA, ",
        },
        {
          notes: "Sol           Re ",
          text: "RE DI ETERNA GLORIA. ",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                    La                 Si-",
          text: "Versetto previsto dal Lezionario, con gli accordi: Si- La",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re           Sol                  La ",
          text: "LODE A TE O CRISTO, RE DI ETERNA GLORIA, ",
        },
        {
          notes: "Sol           Re ",
          text: "RE DI ETERNA GLORIA. ",
        },
      ],
    },
    {
      speaker: "oppure:",
      mode: "utility-message",
      lines: [],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                               Sol ",
          text: "LODE E ONORE, LODE E ONORE, LODE A TE, ",
        },
        {
          notes: "           La7          Re ",
          text: "SIGNORE GESÙ, SIGNORE GESÙ.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Si-                    La                 Si-",
          text: "Versetto previsto dal Lezionario, con gli accordi: Si- La",
        },
      ],
    },
    {
      speaker: "C.+A.",
      mode: "normal",
      lines: [
        {
          notes: " Re                               Sol ",
          text: "LODE E ONORE, LODE E ONORE, LODE A TE, ",
        },
        {
          notes: "           La7          Re ",
          text: "SIGNORE GESÙ, SIGNORE GESÙ.",
        },
      ],
    },
  ],
  subtitle: "",
};
