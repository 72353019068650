import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Pentecoste.",
  url_video: "https://www.youtube.com/embed/80a7niMNExo",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                      Fa",
          text: "Erano poveri uomini, come me, come te;",
        },
        {
          notes: "   Sib",
          text: "avevano gettato le reti nel lago,",
        },
        {
          notes: "      Fa                  La7",
          text: "o riscosso le tasse alle porte della città.",
        },
        {
          notes: " Re-",
          text: "Ch'io mi ricordi, tra loro,",
        },
        {
          notes: "      Fa",
          text: "non c'era neanche un dottore,",
        },
        {
          notes: "   Sib",
          text: "e quello che chiamavano maestro",
        },
        {
          notes: "    Fa                    La7",
          text: "era morto e sepolto anche lui.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                  Do",
          text: "SE SENTI UN SOFFIO NEL CIELO,",
        },
        {
          notes: "   Re-                  La",
          text: "UN VENTO CHE SCUOTE LE PORTE,",
        },
        {
          notes: "   Re-                  Do",
          text: "ASCOLTA: È UNA VOCE CHE CHIAMA,",
        },
        {
          notes: "   Re-                 La",
          text: "È L'INVITO AD ANDARE LONTANO.",
        },
        {
          notes: "         Re-       Sol",
          text: "C'È UN FUOCO CHE NASCE",
        },
        {
          notes: "   La        Re-",
          text: "IN CHI SA ASPETTARE",
        },
        {
          notes: "             Fa",
          text: "IN CHI SA NUTRIRE",
        },
        {
          notes: "   La7     Re-",
          text: "SPERANZE D'AMOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re-                             Fa",
          text: "Avevano un cuore nel petto, come me, come te",
        },
        {
          notes: "          Sib",
          text: "che una mano di gelo stringeva;",
        },
        {
          notes: "   Fa          La7",
          text: "avevano occhi nudi di pioggia",
        },
        {
          notes: "     Re-",
          text: "e un volto grigio di febbre e paura;",
        },
        {
          notes: "   Fa",
          text: "pensavano certo all'amico perduto,",
        },
        {
          notes: "      Sib",
          text: "alla donna lasciata sulla soglia di casa,",
        },
        {
          notes: "      Fa                   La7",
          text: "alla croce piantata sulla cima di un colle.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                  Do",
          text: "SE SENTI UN SOFFIO NEL CIELO,",
        },
        {
          notes: "   Re-                  La",
          text: "UN VENTO CHE SCUOTE LE PORTE,",
        },
        {
          notes: "   Re-                  Do",
          text: "ASCOLTA: È UNA VOCE CHE CHIAMA,",
        },
        {
          notes: "   Re-                 La",
          text: "È L'INVITO AD ANDARE LONTANO.",
        },
        {
          notes: "         Re-       Sol",
          text: "C'È UN FUOCO CHE NASCE",
        },
        {
          notes: "   La        Re-",
          text: "IN CHI SA ASPETTARE",
        },
        {
          notes: "             Fa",
          text: "IN CHI SA NUTRIRE",
        },
        {
          notes: "   La7     Re-",
          text: "SPERANZE D'AMOR.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "     Re-",
          text: "E il vento bussò alla porta di casa,",
        },
        {
          notes: " Fa",
          text: "entrò come un pazzo in tutta la stanza",
        },
        {
          notes: "     Sib",
          text: "ed ebbero occhi e voci di fiamma,",
        },
        {
          notes: "   Fa                    La7",
          text: "uscirono in piazza a gridare la gioia.",
        },
        {
          notes: "   Re-",
          text: "Uomo che attendi nascosto nell'ombra",
        },
        {
          notes: "    Fa",
          text: "la voce che parla è proprio per te;",
        },
        {
          notes: "    Sib",
          text: "ti porta una gioia, una buona notizia:",
        },
        {
          notes: "    Fa               La7",
          text: "il regno di Dio è arrivato già!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "  Re-                  Do",
          text: "SE SENTI UN SOFFIO NEL CIELO,",
        },
        {
          notes: "   Re-                  La",
          text: "UN VENTO CHE SCUOTE LE PORTE,",
        },
        {
          notes: "   Re-                  Do",
          text: "ASCOLTA: È UNA VOCE CHE CHIAMA,",
        },
        {
          notes: "   Re-                 La",
          text: "È L'INVITO AD ANDARE LONTANO.",
        },
        {
          notes: "         Re-       Sol",
          text: "C'È UN FUOCO CHE NASCE",
        },
        {
          notes: "   La        Re-",
          text: "IN CHI SA ASPETTARE",
        },
        {
          notes: "             Fa",
          text: "IN CHI SA NUTRIRE",
        },
        {
          notes: "   La7     Re-",
          text: "SPERANZE D'AMOR.",
        },
      ],
    },
  ],
  subtitle: "",
};
