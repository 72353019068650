import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "La marcia è dura.",
  url_video: "https://www.youtube.com/embed/xz1kQtj_X7Q",
  barrè: "",
  color: "white",
  semantic: "neocat",
  altnotes: "Mi-",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-          Mi   La-",
          text: "La marcia è dura, forte è il sol,",
        },
        {
          notes: "Mi7           La-",
          text: "lento il camminar.",
        },
        {
          notes: "        Mi    La-",
          text: "Mosè, capo di Israel",
        },
        {
          notes: "Mi          La-",
          text: "lo va a liberar.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La7      Re-",
          text: "ASCOLTA, PADRE",
        },
        {
          notes: "Mi7              La-",
          text: "IL GRIDO DEL TUO POPOLO",
        },
        {
          notes: "  Fa      La-",
          text: "ASCOLTA, PADRE",
        },
        {
          notes: "Mi7           La-",
          text: "MANDA IL SALVATOR",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "La-          Mi   La-",
          text: "Il popolo anela vita e pace,",
        },
        {
          notes: "Mi7        La-",
          text: "vuol risuscitar.",
        },
        {
          notes: "        Mi    La-",
          text: "Mosè, capo di Israel",
        },
        {
          notes: "Mi         La-",
          text: "lo va a liberar.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "La7      Re-",
          text: "ASCOLTA, PADRE",
        },
        {
          notes: "Mi7              La-",
          text: "IL GRIDO DEL TUO POPOLO",
        },
        {
          notes: "  Fa      La-",
          text: "ASCOLTA, PADRE",
        },
        {
          notes: "Mi7           La-",
          text: "MANDA IL SALVATOR",
        },
      ],
    },
  ],
  subtitle: "",
};
