import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Se_m'accogli.",
  title: "Se m'accogli.",
  url_video: "https://www.youtube.com/embed/YkAIWOfJiJM",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Do          Fa",
          text: "Tra le mani non ho niente,",
        },
        {
          notes: "      Mi-            La-",
          text: "spero che mi accoglierai",
        },
        {
          notes: "       Do        Fa              Sol7  Sol",
          text: "chiedo solo di restare accanto a Te",
        },
        {
          notes: "     Do        Fa",
          text: "Sono ricco solamente",
        },
        {
          notes: "      Mi-         La-",
          text: "dell’amore che mi dai",
        },
        {
          notes: "      Do               Fa          Sol7  Sol",
          text: "è per quelli che non l’hanno avuto mai.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Do            Mi7",
          text: "Se m’accogli, mio Signore,",
        },
        {
          notes: "      La-          Do7",
          text: "altro non Ti chiederò",
        },
        {
          notes: "      Fa            Re-",
          text: "e per sempre la Tua strada",
        },
        {
          notes: "       Mi7  Mi7    La-",
          text: "la mia strada resterà!",
        },
        {
          notes: "      Fa           Do",
          text: "Nella gioia, nel dolore,",
        },
        {
          notes: "       Fa     Re7  Do   Mi7",
          text: "fino a quando Tu vorrai,",
        },
        {
          notes: "       La-        Sol        Fa  Do",
          text: "con la mano nella Tua camminerò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Do           Fa",
          text: "Io ti prego con il cuore,",
        },
        {
          notes: "       Mi-          La-",
          text: "so che Tu mi ascolterai",
        },
        {
          notes: "      Do           Fa           Sol7 Sol",
          text: "rendi forte la mia fede più che mai",
        },
        {
          notes: "        Do          Fa",
          text: "Tieni accesa la mia luce",
        },
        {
          notes: "        Mi-           La-",
          text: "fino al giorno che Tu sai",
        },
        {
          notes: "      Do              Fa             Sol7 Sol    ",
          text: "Con i miei fratelli incontro a Te verrò.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "      Do            Mi7",
          text: "Se m’accogli, mio Signore,",
        },
        {
          notes: "      La-          Do7",
          text: "altro non Ti chiederò",
        },
        {
          notes: "      Fa            Re-",
          text: "e per sempre la Tua strada",
        },
        {
          notes: "       Mi7  Mi7    La-",
          text: "la mia strada resterà!",
        },
        {
          notes: "      Fa           Do",
          text: "Nella gioia, nel dolore,",
        },
        {
          notes: "       Fa     Re7  Do   Mi7",
          text: "fino a quando Tu vorrai,",
        },
        {
          notes: "       La-        Sol        Fa  Do",
          text: "con la mano nella Tua camminerò.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
