import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Ai_piedi_della_croce.",
  title: "Ai piedi della croce.",
  url_video: "https://www.youtube.com/embed/VF01Mdiqa38",
  barrè: "",
  color: "none",
  semantic: "classic_liturgy",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "       Re  La     Do#  Sol  La    Sol",
          text: " ",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Re                      La     Do#",
          text: "Ai piedi della croce io starò",
        },
        {
          notes: "      Si-                     La   Sol",
          text: "contemplando la grandezza del Tuo amore",
        },
        {
          notes: "    Re                          La     Do#",
          text: "prostrandomi ai tuoi piedi adorerò",
        },
        {
          notes: "    Si-                     La  Sol",
          text: "ricevendo la Tua grazia nel mio cuore",
        },
        {
          notes: "   La           Si-       La  Sol    La    Sol",
          text: "Io so che c'è potenza nel Tuo sangue",
        },
        {
          notes: "            La           Si-           Sol",
          text: "che in quel dì i miei peccati hai perdonato",
        },
        {
          notes: "           ",
          text: "ed io dichiaro.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Re          La Do# Si-",
          text: "Gesù, nome al di   sopra",
        },
        {
          notes: "              Sol  La    Sol",
          text: "di ogni altro nome",
        },
        {
          notes: "    Re             La Do# Si-",
          text: "non c'è, non c'è nessun  altro",
        },
        {
          notes: "              Sol   La    Sol",
          text: "come il mio Signore",
        },
        {
          notes: "      Re    Mi-         Do          Sol",
          text: "morto per   me, al posto mio, risuscitò",
        },
        {
          notes: "       Mi-                   Do    ",
          text: "ritornerà, la Sua chiesa rapirà",
        },
        {
          notes: "           Sol             Re7    Re   Sol La   ",
          text: "saremo con Lui per l'eternità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "   Re                      La     Do#",
          text: "Ai piedi della croce io starò",
        },
        {
          notes: "      Si-                     La   Sol",
          text: "contemplando la grandezza del Tuo amore",
        },
        {
          notes: "    Re                          La     Do#",
          text: "prostrandomi ai tuoi piedi adorerò",
        },
        {
          notes: "    Si-                     La  Sol",
          text: "ricevendo la Tua grazia nel mio cuore",
        },
        {
          notes: "   La           Si-       La  Sol    La    Sol",
          text: "Io so che c'è potenza nel Tuo sangue",
        },
        {
          notes: "            La           Si-           Sol",
          text: "che in quel dì i miei peccati hai perdonato",
        },
        {
          notes: "           ",
          text: "ed io dichiaro.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Re          La Do# Si-",
          text: "Gesù, nome al di   sopra",
        },
        {
          notes: "              Sol  La    Sol",
          text: "di ogni altro nome",
        },
        {
          notes: "    Re             La Do# Si-",
          text: "non c'è, non c'è nessun  altro",
        },
        {
          notes: "              Sol   La    Sol",
          text: "come il mio Signore",
        },
        {
          notes: "      Re     Mi-         Do          Sol",
          text: "morto per   me, al posto mio, risuscitò",
        },
        {
          notes: "       Mi-                   Do    ",
          text: "ritornerà, la Sua chiesa rapirà",
        },
        {
          notes: "           Sol             Re7  Re  Mi",
          text: "saremo con Lui per l'eternità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "  Mi          Si    Do#-",
          text: "Gesù, nome al di   sopra",
        },
        {
          notes: "              La  Si La",
          text: "di ogni altro nome",
        },
        {
          notes: "    Mi             Si   Do#-",
          text: "non c'è, non c'è nessun  altro",
        },
        {
          notes: "              La   Si    La",
          text: "come il mio Signore",
        },
        {
          notes: "      Mi     Fa#-        Re      La",
          text: "morto per    me, al posto mio, risuscitò",
        },
        {
          notes: "       Fa#-                  Re",
          text: "ritornerà, la Sua chiesa rapirà",
        },
        {
          notes: "           La              Re    Mi",
          text: "saremo con Lui per l'eternità.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "                 Fa#-        Re         La",
          text: "Lui è morto per me, al posto mio, risuscitò",
        },
        {
          notes: "       Fa#-                  Re",
          text: "ritornerà, la Sua chiesa rapirà",
        },
        {
          notes: "           La              Mi7    Mi7  La",
          text: "saremo con Lui per l'eternità.",
        },
      ],
    },
  ],
  subtitle: "",
  sent_for_revision: false,
};
