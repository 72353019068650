import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Già viene il mio dio.",
  url_video: "https://www.youtube.com/embed/HQbdVMtSJrg",
  barrè: "",
  color: "white",
  semantic: "neocat",
  blocks: [
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                       Mi-",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                 Si7",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO SALVATOR,",
        },
        {
          notes: "                            Mi-",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO RE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                           Si7",
          text: "È un operaio, è nato in Betlem,",
        },
        {
          notes: "                                Mi-",
          text: "sua madre è Maria, suo padre è Giuseppe.",
        },
        {
          notes: "                                 Si7",
          text: "Già viene, già viene il mio salvator,",
        },
        {
          notes: "                            Mi-",
          text: "già viene, già viene il mio re.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                       Mi-",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                 Si7",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO SALVATOR,",
        },
        {
          notes: "                            Mi-",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO RE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                           Si7",
          text: "Lui ha occhi che parlan d'amore,",
        },
        {
          notes: "                                Mi-",
          text: "che parlan del Padre, che parlan di Dio.",
        },
        {
          notes: "                                 Si7",
          text: "Già viene, già viene il mio salvator,",
        },
        {
          notes: "                            Mi-",
          text: "già viene, già viene il mio re.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                       Mi-",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                 Si7",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO SALVATOR,",
        },
        {
          notes: "                            Mi-",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO RE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                           Si7",
          text: "Morì sulla corce pieno di dolore,",
        },
        {
          notes: "                                Mi-",
          text: "moriì per gli uomini pieno d'amore.",
        },
        {
          notes: "                                 Si7",
          text: "Già viene, già viene il mio salvator,",
        },
        {
          notes: "                            Mi-",
          text: "già viene, già viene il mio re.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                       Mi-",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                 Si7",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO SALVATOR,",
        },
        {
          notes: "                            Mi-",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO RE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "                           Si7",
          text: "È entrato nella morte, è entrato il mio Dio,",
        },
        {
          notes: "                                Mi-",
          text: "già viene, già sorge, già arriva il Signore.",
        },
        {
          notes: "                  Si7",
          text: "Cantate, gridate: risuscitò.",
        },
        {
          notes: "                                  Mi-",
          text: "Il Signore ha vinto, ha vinto per amor.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                    Si7",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                       Mi-",
          text: "GIÀ VIENE IL MIO DIO, GIÀ VIENE IL MIO RE,",
        },
        {
          notes: "                                 Si7",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO SALVATOR,",
        },
        {
          notes: "                            Mi-",
          text: "GIÀ VIENE, GIÀ VIENE IL MIO RE.",
        },
      ],
    },
  ],
  subtitle: "",
};
