import { ButtonBase, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getSupportedNotesFullList,
  getSupportedNotesList,
} from "../ChordGraph/ChordMap";
import "./NotesInput.scss";

export interface NotesInputProps {
  onChange: (value: string, invalid: boolean) => void;
  value: string;
  disabled: boolean;
}

const spaceChar = "___";

const NotesInput = (props: NotesInputProps) => {
  const ref = useRef(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [wrongSymbol, setWrongSymbol] = useState<string>("");
  const [targetNote, setTargetNote] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  let notes: string[] = getSupportedNotesList();
  let notesFull: string[] = getSupportedNotesFullList();
  let keyboardEntries: string[] = [...notesFull];
  keyboardEntries.push(spaceChar);

  const validateInput = (notesFull: string[]) => {
    let splValue = value.split(" ").filter((x: string) => x !== "");
    for (let i = 0; i < splValue.length; i++) {
      if (
        notesFull.filter(
          (x: string) =>
            x.toLocaleLowerCase() === splValue[i].toLocaleLowerCase()
        ).length === 0
      ) {
        setWrongSymbol(splValue[i]);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    let invalidInput: boolean = validateInput(notesFull);
    if (invalidInput !== invalid) {
      setInvalid(invalidInput);
    }
    props.onChange(value, invalidInput);
  }, [value]);

  return (
    <div>
      {!showSuggestions && (
        <ButtonBase disabled={disabled}>
          <div
            className={
              "notes-input-suggestion-link base_border_color dark_text_color button-background" +
              (disabled ? " disabled-item" : "")
            }
            onClick={() => {
              if (disabled) {
                return;
              }
              setShowSuggestions(true);
            }}
          >
            Mostra tastiera delle note
          </div>
        </ButtonBase>
      )}
      {showSuggestions && (
        <div className="notes-input-suggestion-wrap">
          <div className="notes-input-suggestion-row">
            {notes.map((x: string, i: number) => {
              return (
                <div
                  className={
                    "notes-input-suggestion-button-1" +
                    (disabled ? " disabled-item" : "")
                  }
                  key={i}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    setTargetNote(x);
                  }}
                >
                  {x}
                </div>
              );
            })}
          </div>
          <div className="notes-input-separator"></div>
          <div className="notes-input-suggestion-row">
            {keyboardEntries
              .filter(
                (x: string) =>
                  targetNote !== "" &&
                  (x.indexOf(targetNote) !== -1 || x === spaceChar)
              )
              .map((x: string, i: number) => {
                return (
                  <div
                    className={
                      "notes-input-suggestion-button-2" +
                      (disabled ? " disabled-item" : "")
                    }
                    key={i}
                    onClick={(e: any) => {
                      if (disabled) {
                        return;
                      }
                      let node: any = ref.current as any;
                      let caret: number = node.selectionStart ?? value.length;
                      let caretOnEnd = caret === value.length;
                      let addValue = x === spaceChar ? " " : x + " ";
                      let spacing = value === "" || addValue === " " ? "" : " ";
                      let result =
                        value.slice(0, caret) +
                        spacing +
                        addValue +
                        value.slice(caret);
                      setValue(result);
                      if (caretOnEnd) {
                        node.focus();
                      }
                    }}
                  >
                    {x}
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <TextField
        inputRef={ref}
        fullWidth={true}
        helperText={
          invalid
            ? "Hai inserito simboli che non sono note musicali: " + wrongSymbol
            : "Inserisci solo note musicali o spazi."
        }
        error={invalid}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        value={value}
        disabled={disabled}
        label="Note"
        variant="standard"
      />
    </div>
  );
};

export default NotesInput;
