import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Benedizione dell'acqua del fonte battesimale.",
  url_video: "",
  barrè: "",
  color: "sandybrown",
  semantic: "neocat",
  blocks: [
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-    La-                          Si7",
          text: "O Dio, per mezzo dei segni sacramentali",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "tu operi con invisibile potenza le meraviglie",
        },
        {
          notes: "         Mi-",
          text: "della salvezza",
        },
        {
          notes: "                La-",
          text: "e in molti modi attraverso i tempi",
        },
        {
          notes: "Re             Do",
          text: "hai preparato l'acqua, tua creatura,",
        },
        {
          notes: "                       Si7",
          text: "ad essere segno del battesim",
        },
        {
          notes: "  Mi-",
          text: "Fin dalle origini il tuo Spirito",
        },
        {
          notes: "La-              Si7",
          text: "si librava sulle acque",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "perché contenessero in germe",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "",
          text: "la forza di santificare;",
        },
        {
          notes: "Mi-",
          text: "e anche nel diluvio",
        },
        {
          notes: "La-                   Si7",
          text: "hai prefigurato il battesimo",
        },
        {
          notes: "                   Mi-   Re",
          text: "perché oggi come allora l'acqua segnasse",
        },
        {
          notes: "               Do                         Si7",
          text: "la fine del peccato e l'inizio della vita nuov",
        },
        {
          notes: "  Mi-                                         Re",
          text: "Tu hai liberato dalla schiavitù i figli di Abramo",
        },
        {
          notes: "                                           Mi-",
          text: "facendoli passare illesi attraverso il Mar Rosso",
        },
        {
          notes: "                                   Re",
          text: "perché fossero immagine del futuro popolo",
        },
        {
          notes: "           Mi-",
          text: "dei battezzati.",
        },
        {
          notes: "                           Re           Mi-",
          text: "Infine, nella pienezza dei tempi, il tuo Figlio,",
        },
        {
          notes: "                                          Re",
          text: "battezzato da Giovanni nell'acqua del Giordano,",
        },
        {
          notes: "                            Mi-",
          text: "fu consacrato dallo Spirito Santo;",
        },
        {
          notes: "                Re",
          text: "innalzato sulla croce",
        },
        {
          notes: "                   Do              Si7",
          text: "egli versò dal suo fianco sangue e acqua.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-             Re",
          text: "INNALZATO SULLA CROCE",
        },
        {
          notes: "                   Do",
          text: "EGLI VERSÒ DAL SUO FIANCO",
        },
        {
          notes: "         Si7",
          text: "SANGUE E ACQUA.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                  Re                 Mi-",
          text: "E dopo la sua risurrezione comandò ai discepoli:",
        },
        {
          notes: "                        Re              Mi-",
          text: "andate, annunziate il Vangelo a tutti i popoli",
        },
        {
          notes: "                             Re",
          text: "e battezzateli nel nome del Padre,",
        },
        {
          notes: "     Do                     Si7",
          text: "del Figlio e dello Spirito Santo.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "       Mi-                  Re",
          text: "E BATTEZZATELI NEL NOME DEL PADRE,",
        },
        {
          notes: "     Do                     Si7",
          text: "DEL FIGLIO E DELLO SPIRITO SANTO.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                                 Re",
          text: "Ora, Padre, guarda con amore la tua Chiesa",
        },
        {
          notes: "                   Do                    Si7",
          text: "e fa scaturire per lei la sorgente del battesimo.",
        },
        {
          notes: "  Mi-           Re",
          text: "Infondi in quest'acqua,",
        },
        {
          notes: "                        Do",
          text: "per opera dello Spirito Santo,",
        },
        {
          notes: "                        Si7",
          text: "la grazia del tuo unico Figlio",
        },
        {
          notes: "Mi-                             Re",
          text: "perché con il sacramento del battesimo",
        },
        {
          notes: "                     Mi-",
          text: "l'uomo fatto a tua immagine",
        },
        {
          notes: "                             Re",
          text: "sia lavato dalla macchia del peccato",
        },
        {
          notes: "                             Do",
          text: "e dall'acqua e dallo Spirito Santo",
        },
        {
          notes: "                        Si7",
          text: "rinasca come nuova creatura.",
        },
        {
          notes: "Mi-              La-",
          text: "Discenda, Padre, in quest'acqua",
        },
        {
          notes: "                  Si7",
          text: "per opera del tuo Figlio",
        },
        {
          notes: "     La-                 Si7",
          text: "la potenza dello Spirito Santo,",
        },
        {
          notes: "                Mi-",
          text: "perché tutti coloro",
        },
        {
          notes: "                              Re",
          text: "che in essa riceveranno il battesimo,",
        },
        {
          notes: "                    Mi-",
          text: "sepolti insieme con Cristo,",
        },
        {
          notes: "                      Re         Do",
          text: "nella morte con lui risorgano, risorgano",
        },
        {
          notes: "                Si7",
          text: "alla vita immortale.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "               Mi-   Re",
          text: "NELLA MORTE CON LUI RISORGANO,",
        },
        {
          notes: "  Do                     Si7",
          text: "RISORGANO ALLA VITA IMMORTALE.",
        },
      ],
    },
    {
      speaker: "P.",
      mode: "normal",
      lines: [
        {
          notes: "                     Mi-",
          text: "Per Cristo, nostro Signore!",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Mi-   Re    Mi-",
          text: "AMEN, AMEN, AMEN!",
        },
      ],
    },
  ],
  subtitle: "",
};
