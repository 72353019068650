import { SongElement } from "./SongElement";

import * as Servo_per_amore from "./Classic/it/Servo_per_amore";
import * as E_solo_un_uomo from "./Classic/it/E_solo_un_uomo";
import * as Camminero from "./Classic/it/Camminero";
import * as Andro_a_vederla_un_di from "./Classic/it/Andro_a_vederla_un_di";
import * as Su_ali_daquila from "./Classic/it/Su_ali_d'aquila";
import * as Anima_Christi from "./Classic/it/Anima_Christi";
import * as Come_tu_mi_vuoi from "./Classic/it/Come_tu_mi_vuoi";
import * as Abbracciamo_Gesu from "./Classic/it/Abbracciamo_Gesu";
import * as Tu_sei_la_mia_vita from "./Classic/it/Tu_sei_la_mia_vita";
import * as Se_maccogli from "./Classic/it/Se_m'accogli";
import * as Ai_piedi_della_croce from "./Classic/it/Ai_piedi_della_croce";
import * as Dell_aurora_tu_sorgi_piu_bella from "./Classic/it/Dell'_aurora_tu_sorgi_piu_bella";
import * as Te_al_centro_del_mio_cuore from "./Classic/it/Te_al_centro_del_mio_cuore";
import * as Salve_Regina from "./Classic/it/Salve_Regina";
import * as Sono_qui_a_lodarti from "./Classic/it/Sono_qui_a_lodarti";
import * as Osanna_eh from "./Classic/it/Osanna_eh";
import * as Come_Maria from "./Classic/it/Come_Maria";
import * as Lodate_iddio from "./Neocatecumenal/it/Lodate_iddio";
import * as Litanie_penitenziali_brevi from "./Neocatecumenal/it/Litanie_penitenziali_brevi";
import * as Sale_dio_tra_acclamazioni from "./Neocatecumenal/it/Sale_dio_tra_acclamazioni";
import * as Risuscito from "./Neocatecumenal/it/Risuscito";
import * as Caritas_christi from "./Neocatecumenal/it/Caritas_christi";
import * as Il_signore_annuncia_una_notizia from "./Neocatecumenal/it/Il_signore_annuncia_una_notizia";
import * as Agnella_di_dio from "./Neocatecumenal/it/Agnella_di_dio";
import * as Da_lode_al_signore from "./Neocatecumenal/it/Da_lode_al_signore";
import * as Come_lo_slancio_dellira from "./Neocatecumenal/it/Come_lo_slancio_dell'ira";
import * as Maria_di_jasna_gora from "./Neocatecumenal/it/Maria_di_jasna_gora";
import * as Felice_luomo from "./Neocatecumenal/it/Felice_l'uomo";
import * as Una_donna_vestita_di_sole from "./Neocatecumenal/it/Una_donna_vestita_di_sole";
import * as Noli_me_tangere from "./Neocatecumenal/it/Noli_me_tangere";
import * as Per_amore_dei_miei_fratelli from "./Neocatecumenal/it/Per_amore_dei_miei_fratelli";
import * as Vergine_della_meraviglia from "./Neocatecumenal/it/Vergine_della_meraviglia";
import * as Non_e_qui from "./Neocatecumenal/it/Non_e_qui";
import * as Inno_alla_croce_gloriosa from "./Neocatecumenal/it/Inno_alla_croce_gloriosa";
import * as Ecco_qui_io_vengo_presto from "./Neocatecumenal/it/Ecco_qui_io_vengo_presto";
import * as Fratelli_non_diamo_a_nessuno from "./Neocatecumenal/it/Fratelli_non_diamo_a_nessuno";
import * as Abba_padre from "./Neocatecumenal/it/Abba_padre";
import * as Debora from "./Neocatecumenal/it/Debora";
import * as Quando_israele_era_un_bimbo from "./Neocatecumenal/it/Quando_israele_era_un_bimbo";
import * as Un_germoglio_spunta_dal_tronco_di_jesse from "./Neocatecumenal/it/Un_germoglio_spunta_dal_tronco_di_jesse";
import * as Inno_di_avvento from "./Neocatecumenal/it/Inno_di_avvento";
import * as Se_siete_risorti_con_Cristo from "./Neocatecumenal/it/Se_siete_risorti_con_Cristo";
import * as Sequenza_di_pentecoste from "./Neocatecumenal/it/Sequenza_di_pentecoste";
import * as O_gesu_amore_mio from "./Neocatecumenal/it/O_gesu_amore_mio";
import * as La_marcia_e_dura from "./Neocatecumenal/it/La_marcia_e_dura";
import * as Questo_e_il_mio_comandamento from "./Neocatecumenal/it/Questo_e_il_mio_comandamento";
import * as Canto_di_balaam from "./Neocatecumenal/it/Canto_di_balaam";
import * as Grazie_a_jahve from "./Neocatecumenal/it/Grazie_a_jahve";
import * as Esultate_giusti_nel_signore from "./Neocatecumenal/it/Esultate_giusti_nel_signore";
import * as Cosi_parla_lamen from "./Neocatecumenal/it/Cosi_parla_l'amen";
import * as In_una_notte_oscura from "./Neocatecumenal/it/In_una_notte_oscura";
import * as Come_una_cerva_anela from "./Neocatecumenal/it/Come_una_cerva_anela";
import * as Ho_steso_le_mie_mani from "./Neocatecumenal/it/Ho_steso_le_mie_mani";
import * as Il_popolo_che_camminava_nelle_tenebre from "./Neocatecumenal/it/Il_popolo_che_camminava_nelle_tenebre";
import * as Canto_dei_tre_giovani_nella_fornace_i from "./Neocatecumenal/it/Canto_dei_tre_giovani_nella_fornace_i";
import * as Preghiera_eucaristica_ii_consacrazione from "./Neocatecumenal/it/Preghiera_eucaristica_ii_consacrazione";
import * as Andiamo_gia_pastori from "./Neocatecumenal/it/Andiamo,_gia_pastori";
import * as Guardate_come_e_bello from "./Neocatecumenal/it/Guardate_come_e_bello";
import * as Benedetto_sia_iddio from "./Neocatecumenal/it/Benedetto_sia_iddio";
import * as Vedo_i_cieli_aperti from "./Neocatecumenal/it/Vedo_i_cieli_aperti";
import * as Canto_dellagnello from "./Neocatecumenal/it/Canto_dell'agnello";
import * as Innalzero_la_coppa_di_salvezza from "./Neocatecumenal/it/Innalzero_la_coppa_di_salvezza";
import * as Dite_agli_smarriti_di_cuore from "./Neocatecumenal/it/Dite_agli_smarriti_di_cuore";
import * as Santo_santo_santo__osanna_delle_palme from "./Neocatecumenal/it/Santo_santo_santo_-_osanna_delle_palme";
import * as Shema_israel from "./Neocatecumenal/it/Shema_israel";
import * as Ti_sto_chiamando from "./Neocatecumenal/it/Ti_sto_chiamando";
import * as Ave_maria from "./Neocatecumenal/it/Ave_maria";
import * as Che_mi_baci from "./Neocatecumenal/it/Che_mi_baci";
import * as Mi_rubasti_il_cuore from "./Neocatecumenal/it/Mi_rubasti_il_cuore";
import * as Portami_in_cielo from "./Neocatecumenal/it/Portami_in_cielo";
import * as Alleluja_per_acclamazioni_al_vangelo from "./Neocatecumenal/it/Alleluja_per_acclamazioni_al_vangelo";
import * as Eli_eli_lamma_sabactani from "./Neocatecumenal/it/Eli,_eli,_lamma_sabactani";
import * as Siedi_solitario_e_silenzioso from "./Neocatecumenal/it/Siedi_solitario_e_silenzioso";
import * as Benedici_anima_mia_jahve from "./Neocatecumenal/it/Benedici_anima_mia_jahve";
import * as O_cieli_piovete_dallalto from "./Neocatecumenal/it/O_cieli_piovete_dall'alto";
import * as Shlom_lech_mariam from "./Neocatecumenal/it/Shlom_lech_mariam";
import * as Mi_indicherai_il_sentiero_della_vita from "./Neocatecumenal/it/Mi_indicherai_il_sentiero_della_vita";
import * as Signore_il_mio_cuore_non_ha_piu_pretese from "./Neocatecumenal/it/Signore,_il_mio_cuore_non_ha_piu_pretese";
import * as Inno_delle_lodi_di_pasqua_dapasquaallascenzone from "./Neocatecumenal/it/Inno_delle_lodi_di_pasqua_(da pasqua all'ascenzone)";
import * as Signore_ascolta_la_mia_preghiera from "./Neocatecumenal/it/Signore_ascolta_la_mia_preghiera";
import * as Credo_apostolico from "./Neocatecumenal/it/Credo_apostolico";
import * as Quanto_sono_amabili_le_tue_dimore from "./Neocatecumenal/it/Quanto_sono_amabili_le_tue_dimore";
import * as Alleluja_pasquale from "./Neocatecumenal/it/Alleluja_pasquale";
import * as Inno_dei_vespri_di_pasqua_da_pasqua_fino_allascensione from "./Neocatecumenal/it/Inno_dei_vespri_di_pasqua_(da_pasqua_fino_all'ascensione)";
import * as Tu_che_abiti_nei_giardini from "./Neocatecumenal/it/Tu_che_abiti_nei_giardini";
import * as Gustate_e_vedete from "./Neocatecumenal/it/Gustate_e_vedete";
import * as Quando_il_signore from "./Neocatecumenal/it/Quando_il_signore";
import * as Voglio_andare_a_gerusalemme from "./Neocatecumenal/it/Voglio_andare_a_gerusalemme";
import * as Maria_casa_di_benedizione from "./Neocatecumenal/it/Maria,_casa_di_benedizione";
import * as Lo_stesso_iddio from "./Neocatecumenal/it/Lo_stesso_iddio";
import * as Preghiera_eucaristica_ii_offerta from "./Neocatecumenal/it/Preghiera_eucaristica_ii_offerta";
import * as Ti_vedranno_i_re from "./Neocatecumenal/it/Ti_vedranno_i_re";
import * as Amate_i_vostri_nemici from "./Neocatecumenal/it/Amate_i_vostri_nemici";
import * as Acclamate_al_signore from "./Neocatecumenal/it/Acclamate_al_signore";
import * as Inno_dei_vespri_del_giorno_dellascenzione from "./Neocatecumenal/it/Inno_dei_vespri_del_giorno_dell'ascenzione";
import * as Ho_sperato_ho_sperato_nel_signore from "./Neocatecumenal/it/Ho_sperato,_ho_sperato_nel_signore";
import * as Sola_a_solo from "./Neocatecumenal/it/Sola_a_solo";
import * as O_signore_nostro_dio from "./Neocatecumenal/it/O_signore_nostro_dio";
import * as Akeda from "./Neocatecumenal/it/Akeda";
import * as Le_sue_fondamenta from "./Neocatecumenal/it/Le_sue_fondamenta";
import * as Inno_di_pasqua from "./Neocatecumenal/it/Inno_di_pasqua";
import * as Alla_vittima_pasquale from "./Neocatecumenal/it/Alla_vittima_pasquale";
import * as Venite_applaudiamo_al_signore from "./Neocatecumenal/it/Venite_applaudiamo_al_signore";
import * as Chi_ci_separera from "./Neocatecumenal/it/Chi_ci_separera";
import * as Santo from "./Neocatecumenal/it/Santo";
import * as Stabat_mater from "./Neocatecumenal/it/Stabat_mater";
import * as Padre_nostro from "./Neocatecumenal/it/Padre_nostro";
import * as Alleluja_per_acclamazioni_al_vangelo_nel_tempo_di_quaresima from "./Neocatecumenal/it/Alleluja_per_acclamazioni_al_vangelo_nel_tempo_di_quaresima";
import * as Santo_e_santo from "./Neocatecumenal/it/Santo_e_santo";
import * as Ecco_lo_specchio_nostro from "./Neocatecumenal/it/Ecco_lo_specchio_nostro";
import * as Se_oggi_ascoltate_la_sua_voce from "./Neocatecumenal/it/Se_oggi_ascoltate_la_sua_voce";
import * as Se_il_signore_non_costruisce_la_casa from "./Neocatecumenal/it/Se_il_signore_non_costruisce_la_casa";
import * as Mi_hai_sedotto_signore from "./Neocatecumenal/it/Mi_hai_sedotto,_signore";
import * as Inno_delle_lodi_di_avvento_dopo_il_16_dicembre from "./Neocatecumenal/it/Inno_delle_lodi_di_avvento_(dopo_il_16_dicembre)";
import * as Come_stilla_il_miele from "./Neocatecumenal/it/Come_stilla_il_miele";
import * as Non_ti_adirare from "./Neocatecumenal/it/Non_ti_adirare";
import * as Amo_il_signore from "./Neocatecumenal/it/Amo_il_signore";
import * as La_voce_del_mio_amato from "./Neocatecumenal/it/La_voce_del_mio_amato";
import * as Cantico_di_mose from "./Neocatecumenal/it/Cantico_di_mose";
import * as Pentecoste from "./Neocatecumenal/it/Pentecoste";
import * as Quando_dormivo from "./Neocatecumenal/it/Quando_dormivo";
import * as Tu_sei_la_mia_speranza_signore from "./Neocatecumenal/it/Tu_sei_la_mia_speranza_signore";
import * as Giorno_di_riposo from "./Neocatecumenal/it/Giorno_di_riposo";
import * as Se_nel_signore_mi_sono_rifugiato from "./Neocatecumenal/it/Se_nel_signore_mi_sono_rifugiato";
import * as Benedetta_sei_tu_maria from "./Neocatecumenal/it/Benedetta_sei_tu,_maria";
import * as Vieni_dal_libano from "./Neocatecumenal/it/Vieni_dal_libano";
import * as Cantiamo_cantiamo from "./Neocatecumenal/it/Cantiamo,_cantiamo";
import * as Giacobbe from "./Neocatecumenal/it/Giacobbe";
import * as Inno_akathistos from "./Neocatecumenal/it/Inno_akathistos";
import * as Perche_le_genti_congiurano from "./Neocatecumenal/it/Perche_le_genti_congiurano";
import * as A_te_levo_i_miei_occhi from "./Neocatecumenal/it/A_te_levo_i_miei_occhi";
import * as Magnificat from "./Neocatecumenal/it/Magnificat";
import * as Il_seminatore from "./Neocatecumenal/it/Il_seminatore";
import * as Preconio_pasquale from "./Neocatecumenal/it/Preconio_pasquale";
import * as Gloria_gloria_gloria from "./Neocatecumenal/it/Gloria,_gloria,_gloria";
import * as Abramo from "./Neocatecumenal/it/Abramo";
import * as Il_signore_e_il_mio_pastore from "./Neocatecumenal/it/Il_signore_e_il_mio_pastore";
import * as Canto_dei_bambini_nella_veglia_di_pasqua from "./Neocatecumenal/it/Canto_dei_bambini_nella_veglia_di_pasqua";
import * as Gloria_a_dio_nellalto_dei_cieli from "./Neocatecumenal/it/Gloria_a_dio_nell'alto_dei_cieli";
import * as Come_e_bello_come_da_gioia from "./Neocatecumenal/it/Come_e_bello,_come_da_gioia";
import * as Lamenti_del_signore from "./Neocatecumenal/it/Lamenti_del_signore";
import * as Lodate_il_signore from "./Neocatecumenal/it/Lodate_il_signore";
import * as Felicita_per_luomo from "./Neocatecumenal/it/Felicita_per_l'uomo";
import * as Dice_il_signore_al_mio_signore from "./Neocatecumenal/it/Dice_il_signore_al_mio_signore";
import * as Gia_viene_il_mio_dio from "./Neocatecumenal/it/Gia_viene_il_mio_dio";
import * as Vi_prendero_dalle_genti from "./Neocatecumenal/it/Vi_prendero_dalle_genti";
import * as Uri_uri_ura from "./Neocatecumenal/it/Uri,_uri,_ura";
import * as Signore_aiutami_signore from "./Neocatecumenal/it/Signore_aiutami,_signore";
import * as Ti_amo_signore from "./Neocatecumenal/it/Ti_amo_signore";
import * as O_dio_tu_sei_il_mio_dio from "./Neocatecumenal/it/O_dio_tu_sei_il_mio_dio";
import * as Tu_sei_bella_amica_mia from "./Neocatecumenal/it/Tu_sei_bella,_amica_mia";
import * as Figlie_di_gerusalemme from "./Neocatecumenal/it/Figlie_di_gerusalemme";
import * as Inno_dei_vespri_di_pasqua_dallascensione_a_pentecoste from "./Neocatecumenal/it/Inno_dei_vespri_di_pasqua_(dall'ascensione_a_pentecoste)";
import * as Davanti_agli_angeli from "./Neocatecumenal/it/Davanti_agli_angeli";
import * as La_colomba_volo from "./Neocatecumenal/it/La_colomba_volo";
import * as Non_ce_in_lui_bellezza from "./Neocatecumenal/it/Non_c'e_in_lui_bellezza";
import * as Santo_1983 from "./Neocatecumenal/it/Santo_(1983)";
import * as Preghiera_eucaristica_ii_prefazio from "./Neocatecumenal/it/Preghiera_eucaristica_ii_prefazio";
import * as Venite_a_me_voi_tutti from "./Neocatecumenal/it/Venite_a_me_voi_tutti";
import * as Vieni_figlio_delluomo from "./Neocatecumenal/it/Vieni,_figlio_dell'uomo";
import * as E_paziente from "./Neocatecumenal/it/E_paziente";
import * as Ti_ho_manifestato_il_mio_peccato from "./Neocatecumenal/it/Ti_ho_manifestato_il_mio_peccato";
import * as Signore_non_punirmi_nel_tuo_sdegno from "./Neocatecumenal/it/Signore,_non_punirmi_nel_tuo_sdegno";
import * as Signore_tu_mi_scruti_e_mi_conosci from "./Neocatecumenal/it/Signore_tu_mi_scruti_e_mi_conosci";
import * as Non_resistete_al_male from "./Neocatecumenal/it/Non_resistete_al_male";
import * as Cantico_di_zaccaria from "./Neocatecumenal/it/Cantico_di_zaccaria";
import * as E_la_pasqua_del_signore from "./Neocatecumenal/it/E_la_pasqua_del_signore";
import * as La_mietitura_delle_nazioni from "./Neocatecumenal/it/La_mietitura_delle_nazioni";
import * as Amen_amen_amen from "./Neocatecumenal/it/Amen_amen_amen";
import * as Benediro_il_signore_in_ogni_tempo from "./Neocatecumenal/it/Benediro_il_signore_in_ogni_tempo";
import * as Santo_e_il_signor__santo_delle_baracche from "./Neocatecumenal/it/Santo_e_il_signor_-_santo_delle_baracche";
import * as Pieta_di_me_o_dio from "./Neocatecumenal/it/Pieta_di_me_o_dio";
import * as Verso_te_o_citta_santa from "./Neocatecumenal/it/Verso_te,_o_citta_santa";
import * as Nessuno_puo_servire_due_padroni from "./Neocatecumenal/it/Nessuno_puo_servire_due_padroni";
import * as Allegria_allegria from "./Neocatecumenal/it/Allegria_allegria";
import * as Lo_stolto_pensa_che_non_ce_dio from "./Neocatecumenal/it/Lo_stolto_pensa_che_non_c'e_dio";
import * as La_mia_diletta_e_per_me from "./Neocatecumenal/it/La_mia_diletta_e_per_me";
import * as Ave_maria_1984 from "./Neocatecumenal/it/Ave_maria_(1984)";
import * as Il_pigiatore from "./Neocatecumenal/it/Il_pigiatore";
import * as Il_signore_e_mia_luce_e_mia_salvezza from "./Neocatecumenal/it/Il_signore_e_mia_luce_e_mia_salvezza";
import * as Consolate_il_mio_popolo from "./Neocatecumenal/it/Consolate_il_mio_popolo";
import * as Voglio_cantare from "./Neocatecumenal/it/Voglio_cantare";
import * as Benedici_anima_mia_il_signore from "./Neocatecumenal/it/Benedici_anima_mia_il_signore";
import * as Tu_sei_il_piu_bello from "./Neocatecumenal/it/Tu_sei_il_piu_bello";
import * as Te_deum from "./Neocatecumenal/it/Te_deum";
import * as Dal_profondo_a_te_grido from "./Neocatecumenal/it/Dal_profondo_a_te_grido";
import * as Salve_regina_dei_cieli from "./Neocatecumenal/it/Salve,_regina_dei_cieli";
import * as Rivestitevi_dellarmatura_di_dio from "./Neocatecumenal/it/Rivestitevi_dell'armatura_di_dio";
import * as Alzate_o_porte from "./Neocatecumenal/it/Alzate_o_porte";
import * as Maria_madre_del_cammino_ardente from "./Neocatecumenal/it/Maria,_madre_del_cammino_ardente";
import * as Non_moriro from "./Neocatecumenal/it/Non_moriro";
import * as Viene_il_signore_vestito_di_maesta from "./Neocatecumenal/it/Viene_il_signore_vestito_di_maesta";
import * as Inno_delle_lodi_di_avvento_fino_al_16_dicembre from "./Neocatecumenal/it/Inno_delle_lodi_di_avvento_(fino_al_16_dicembre)";
import * as Benedizione_per_la_celebrazione_penitenziale from "./Neocatecumenal/it/Benedizione_per_la_celebrazione_penitenziale";
import * as Zaccheo_sei_tu from "./Neocatecumenal/it/Zaccheo_sei_tu";
import * as Chi_e_colei from "./Neocatecumenal/it/Chi_e_colei";
import * as Maria_madre_della_chiesa from "./Neocatecumenal/it/Maria_madre_della_chiesa";
import * as Sorga_dio from "./Neocatecumenal/it/Sorga_dio";
import * as Alzo_gli_occhi_verso_i_monti from "./Neocatecumenal/it/Alzo_gli_occhi_verso_i_monti";
import * as Dajenu from "./Neocatecumenal/it/Dajenu";
import * as Giunga_la_mia_preghiera_fino_a_te from "./Neocatecumenal/it/Giunga_la_mia_preghiera_fino_a_te";
import * as Lodate_il_signore_dai_cieli from "./Neocatecumenal/it/Lodate_il_signore_dai_cieli";
import * as Canto_dei_liberati from "./Neocatecumenal/it/Canto_dei_liberati";
import * as Molto_mi_hanno_perseguitato from "./Neocatecumenal/it/Molto_mi_hanno_perseguitato";
import * as Prefazio_delleucaristia_della_veglia_pasquale from "./Neocatecumenal/it/Prefazio_dell'eucaristia_della_veglia_pasquale";
import * as Ascoltaci_signore from "./Neocatecumenal/it/Ascoltaci_signore";
import * as Guardate_come_bello_gustate_quante_soave from "./Neocatecumenal/it/Guardate_com'e_bello,_gustate_quant'e_soave";
import * as Canto_dei_tre_giovani_nella_fornace_ii from "./Neocatecumenal/it/Canto_dei_tre_giovani_nella_fornace_ii";
import * as Quando_Israele_usci_dallegitto from "./Neocatecumenal/it/Quando_Israele_usci_dall'egitto";
import * as Santo_santo_santo_1988 from "./Neocatecumenal/it/Santo,_santo,_santo_(1988)";
import * as Andate_ed_annunziate_ai_miei_fratelli from "./Neocatecumenal/it/Andate_ed_annunziate_ai_miei_fratelli";
import * as Cerano_due_angeli from "./Neocatecumenal/it/C'erano_due_angeli";
import * as Benedite_il_signore from "./Neocatecumenal/it/Benedite_il_signore";
import * as Gia_viene_il_regno from "./Neocatecumenal/it/Gia_viene_il_regno";
import * as Preghiera_litanica_penitenziale from "./Neocatecumenal/it/Preghiera_litanica_penitenziale";
import * as Misericordia_dio_misericordia from "./Neocatecumenal/it/Misericordia_dio,_misericordia";
import * as Jahve_tu_sei_il_mio_dio from "./Neocatecumenal/it/Jahve_tu_sei_il_mio_dio";
import * as Omelia_pasquale_di_melitone_di_sardi from "./Neocatecumenal/it/Omelia_pasquale_di_melitone_di_sardi";
import * as A_te_signore_innalzo_la_mia_anima from "./Neocatecumenal/it/A_te_signore_innalzo_la_mia_anima";
import * as Fino_a_quando from "./Neocatecumenal/it/Fino_a_quando";
import * as Preghiera_eucaristica_ii_i from "./Neocatecumenal/it/Preghiera_eucaristica_ii_(i)";
import * as Al_risveglio_mi_saziero_del_tuo_volto_signor from "./Neocatecumenal/it/Al_risveglio_mi_saziero_del_tuo_volto,_signor";
import * as A_te_signore_con_la_mia_voce from "./Neocatecumenal/it/A_te_signore_con_la_mia_voce";
import * as Inno_alla_carita from "./Neocatecumenal/it/Inno_alla_carita";
import * as Lo_spirito_del_signore_e_sopra_di_me from "./Neocatecumenal/it/Lo_spirito_del_signore_e_sopra_di_me";
import * as Inno_delle_lodi_di_pentecoste from "./Neocatecumenal/it/Inno_delle_lodi_di_pentecoste";
import * as Ecco_il_mio_servo_I_canto_del_servo_di_Jahve from "./Neocatecumenal/it/Ecco_il_mio_servo,_I_canto_del_servo_di_Jahve";
import * as Preghiera_eucaristica_ii_ii from "./Neocatecumenal/it/Preghiera_eucaristica_ii_(ii)";
import * as Maria_piccola_maria from "./Neocatecumenal/it/Maria,_piccola_maria";
import * as Come_condannati_a_morte from "./Neocatecumenal/it/Come_condannati_a_morte";
import * as Benedizione_dellacqua_del_fonte_battesimale from "./Neocatecumenal/it/Benedizione_dell'acqua_del_fonte_battesimale";
import * as O_morte_dove_la_tua_vittoria from "./Neocatecumenal/it/O_morte_dov'e_la_tua_vittoria";
import * as Carmen_63 from "./Neocatecumenal/it/Carmen_'63";
import * as Giunti_sui_fiumi_di_babilonia from "./Neocatecumenal/it/Giunti_sui_fiumi_di_babilonia";
import * as Il_signore_mi_ha_dato from "./Neocatecumenal/it/Il_signore_mi_ha_dato";
import * as Gesu_percorreva_tutte_le_citta from "./Neocatecumenal/it/Gesu_percorreva_tutte_le_citta";
import * as Gridate_con_gioia from "./Neocatecumenal/it/Gridate_con_gioia";
import * as Cristo_gesu_e_il_signore from "./Neocatecumenal/it/Cristo_gesu_e_il_signore";
import * as Agnello_di_dio from "./Neocatecumenal/it/Agnello_di_dio";
import * as Evenu_shalom_alejem from "./Neocatecumenal/it/Evenu_shalom_alejem";
import * as La_guerra_di_piero from "./Extra/it/La_guerra_di_piero";
import * as Tanto_pe_canta from "./Extra/it/Tanto_pe_canta";
import * as Cera_un_ragazzo from "./Extra/it/C'era_un_ragazzo";
import * as La_canzone_del_sole from "./Extra/it/La_canzone_del_sole";
import * as Acqua_azzurra_acqua_chiara from "./Extra/it/Acqua_azzurra,_acqua_chiara";
import * as Il_ragazzo_della_via_gluck from "./Extra/it/Il_ragazzo_della_via_gluck";
import * as Don_raffae from "./Extra/it/Don_raffae";
import * as Bocca_di_rosa from "./Extra/it/Bocca_di_rosa";
import * as Il_pescatore from "./Extra/it/Il_pescatore";
import * as Nothing_else_matters from "./Extra/it/Nothing_else_matters";
import * as Me_so_mbriacato from "./Extra/it/Me_so_mbriacato";
import * as Bailando from "./Extra/it/Bailando";
import * as Il_gatto_e_la_volpe from "./Extra/it/Il_gatto_e_la_volpe";
import * as Azzurro from "./Extra/it/Azzurro";



export const AppSongs: SongElement[] = [
	Servo_per_amore.Song,
	E_solo_un_uomo.Song,
	Camminero.Song,
	Andro_a_vederla_un_di.Song,
	Su_ali_daquila.Song,
	Anima_Christi.Song,
	Come_tu_mi_vuoi.Song,
	Abbracciamo_Gesu.Song,
	Tu_sei_la_mia_vita.Song,
	Se_maccogli.Song,
	Ai_piedi_della_croce.Song,
	Dell_aurora_tu_sorgi_piu_bella.Song,
	Te_al_centro_del_mio_cuore.Song,
	Salve_Regina.Song,
	Sono_qui_a_lodarti.Song,
	Osanna_eh.Song,
	Come_Maria.Song,
	Lodate_iddio.Song,
	Litanie_penitenziali_brevi.Song,
	Sale_dio_tra_acclamazioni.Song,
	Risuscito.Song,
	Caritas_christi.Song,
	Il_signore_annuncia_una_notizia.Song,
	Agnella_di_dio.Song,
	Da_lode_al_signore.Song,
	Come_lo_slancio_dellira.Song,
	Maria_di_jasna_gora.Song,
	Felice_luomo.Song,
	Una_donna_vestita_di_sole.Song,
	Noli_me_tangere.Song,
	Per_amore_dei_miei_fratelli.Song,
	Vergine_della_meraviglia.Song,
	Non_e_qui.Song,
	Inno_alla_croce_gloriosa.Song,
	Ecco_qui_io_vengo_presto.Song,
	Fratelli_non_diamo_a_nessuno.Song,
	Abba_padre.Song,
	Debora.Song,
	Quando_israele_era_un_bimbo.Song,
	Un_germoglio_spunta_dal_tronco_di_jesse.Song,
	Inno_di_avvento.Song,
	Se_siete_risorti_con_Cristo.Song,
	Sequenza_di_pentecoste.Song,
	O_gesu_amore_mio.Song,
	La_marcia_e_dura.Song,
	Questo_e_il_mio_comandamento.Song,
	Canto_di_balaam.Song,
	Grazie_a_jahve.Song,
	Esultate_giusti_nel_signore.Song,
	Cosi_parla_lamen.Song,
	In_una_notte_oscura.Song,
	Come_una_cerva_anela.Song,
	Ho_steso_le_mie_mani.Song,
	Il_popolo_che_camminava_nelle_tenebre.Song,
	Canto_dei_tre_giovani_nella_fornace_i.Song,
	Preghiera_eucaristica_ii_consacrazione.Song,
	Andiamo_gia_pastori.Song,
	Guardate_come_e_bello.Song,
	Benedetto_sia_iddio.Song,
	Vedo_i_cieli_aperti.Song,
	Canto_dellagnello.Song,
	Innalzero_la_coppa_di_salvezza.Song,
	Dite_agli_smarriti_di_cuore.Song,
	Santo_santo_santo__osanna_delle_palme.Song,
	Shema_israel.Song,
	Ti_sto_chiamando.Song,
	Ave_maria.Song,
	Che_mi_baci.Song,
	Mi_rubasti_il_cuore.Song,
	Portami_in_cielo.Song,
	Alleluja_per_acclamazioni_al_vangelo.Song,
	Eli_eli_lamma_sabactani.Song,
	Siedi_solitario_e_silenzioso.Song,
	Benedici_anima_mia_jahve.Song,
	O_cieli_piovete_dallalto.Song,
	Shlom_lech_mariam.Song,
	Mi_indicherai_il_sentiero_della_vita.Song,
	Signore_il_mio_cuore_non_ha_piu_pretese.Song,
	Inno_delle_lodi_di_pasqua_dapasquaallascenzone.Song,
	Signore_ascolta_la_mia_preghiera.Song,
	Credo_apostolico.Song,
	Quanto_sono_amabili_le_tue_dimore.Song,
	Alleluja_pasquale.Song,
	Inno_dei_vespri_di_pasqua_da_pasqua_fino_allascensione.Song,
	Tu_che_abiti_nei_giardini.Song,
	Gustate_e_vedete.Song,
	Quando_il_signore.Song,
	Voglio_andare_a_gerusalemme.Song,
	Maria_casa_di_benedizione.Song,
	Lo_stesso_iddio.Song,
	Preghiera_eucaristica_ii_offerta.Song,
	Ti_vedranno_i_re.Song,
	Amate_i_vostri_nemici.Song,
	Acclamate_al_signore.Song,
	Inno_dei_vespri_del_giorno_dellascenzione.Song,
	Ho_sperato_ho_sperato_nel_signore.Song,
	Sola_a_solo.Song,
	O_signore_nostro_dio.Song,
	Akeda.Song,
	Le_sue_fondamenta.Song,
	Inno_di_pasqua.Song,
	Alla_vittima_pasquale.Song,
	Venite_applaudiamo_al_signore.Song,
	Chi_ci_separera.Song,
	Santo.Song,
	Stabat_mater.Song,
	Padre_nostro.Song,
	Alleluja_per_acclamazioni_al_vangelo_nel_tempo_di_quaresima.Song,
	Santo_e_santo.Song,
	Ecco_lo_specchio_nostro.Song,
	Se_oggi_ascoltate_la_sua_voce.Song,
	Se_il_signore_non_costruisce_la_casa.Song,
	Mi_hai_sedotto_signore.Song,
	Inno_delle_lodi_di_avvento_dopo_il_16_dicembre.Song,
	Come_stilla_il_miele.Song,
	Non_ti_adirare.Song,
	Amo_il_signore.Song,
	La_voce_del_mio_amato.Song,
	Cantico_di_mose.Song,
	Pentecoste.Song,
	Quando_dormivo.Song,
	Tu_sei_la_mia_speranza_signore.Song,
	Giorno_di_riposo.Song,
	Se_nel_signore_mi_sono_rifugiato.Song,
	Benedetta_sei_tu_maria.Song,
	Vieni_dal_libano.Song,
	Cantiamo_cantiamo.Song,
	Giacobbe.Song,
	Inno_akathistos.Song,
	Perche_le_genti_congiurano.Song,
	A_te_levo_i_miei_occhi.Song,
	Magnificat.Song,
	Il_seminatore.Song,
	Preconio_pasquale.Song,
	Gloria_gloria_gloria.Song,
	Abramo.Song,
	Il_signore_e_il_mio_pastore.Song,
	Canto_dei_bambini_nella_veglia_di_pasqua.Song,
	Gloria_a_dio_nellalto_dei_cieli.Song,
	Come_e_bello_come_da_gioia.Song,
	Lamenti_del_signore.Song,
	Lodate_il_signore.Song,
	Felicita_per_luomo.Song,
	Dice_il_signore_al_mio_signore.Song,
	Gia_viene_il_mio_dio.Song,
	Vi_prendero_dalle_genti.Song,
	Uri_uri_ura.Song,
	Signore_aiutami_signore.Song,
	Ti_amo_signore.Song,
	O_dio_tu_sei_il_mio_dio.Song,
	Tu_sei_bella_amica_mia.Song,
	Figlie_di_gerusalemme.Song,
	Inno_dei_vespri_di_pasqua_dallascensione_a_pentecoste.Song,
	Davanti_agli_angeli.Song,
	La_colomba_volo.Song,
	Non_ce_in_lui_bellezza.Song,
	Santo_1983.Song,
	Preghiera_eucaristica_ii_prefazio.Song,
	Venite_a_me_voi_tutti.Song,
	Vieni_figlio_delluomo.Song,
	E_paziente.Song,
	Ti_ho_manifestato_il_mio_peccato.Song,
	Signore_non_punirmi_nel_tuo_sdegno.Song,
	Signore_tu_mi_scruti_e_mi_conosci.Song,
	Non_resistete_al_male.Song,
	Cantico_di_zaccaria.Song,
	E_la_pasqua_del_signore.Song,
	La_mietitura_delle_nazioni.Song,
	Amen_amen_amen.Song,
	Benediro_il_signore_in_ogni_tempo.Song,
	Santo_e_il_signor__santo_delle_baracche.Song,
	Pieta_di_me_o_dio.Song,
	Verso_te_o_citta_santa.Song,
	Nessuno_puo_servire_due_padroni.Song,
	Allegria_allegria.Song,
	Lo_stolto_pensa_che_non_ce_dio.Song,
	La_mia_diletta_e_per_me.Song,
	Ave_maria_1984.Song,
	Il_pigiatore.Song,
	Il_signore_e_mia_luce_e_mia_salvezza.Song,
	Consolate_il_mio_popolo.Song,
	Voglio_cantare.Song,
	Benedici_anima_mia_il_signore.Song,
	Tu_sei_il_piu_bello.Song,
	Te_deum.Song,
	Dal_profondo_a_te_grido.Song,
	Salve_regina_dei_cieli.Song,
	Rivestitevi_dellarmatura_di_dio.Song,
	Alzate_o_porte.Song,
	Maria_madre_del_cammino_ardente.Song,
	Non_moriro.Song,
	Viene_il_signore_vestito_di_maesta.Song,
	Inno_delle_lodi_di_avvento_fino_al_16_dicembre.Song,
	Benedizione_per_la_celebrazione_penitenziale.Song,
	Zaccheo_sei_tu.Song,
	Chi_e_colei.Song,
	Maria_madre_della_chiesa.Song,
	Sorga_dio.Song,
	Alzo_gli_occhi_verso_i_monti.Song,
	Dajenu.Song,
	Giunga_la_mia_preghiera_fino_a_te.Song,
	Lodate_il_signore_dai_cieli.Song,
	Canto_dei_liberati.Song,
	Molto_mi_hanno_perseguitato.Song,
	Prefazio_delleucaristia_della_veglia_pasquale.Song,
	Ascoltaci_signore.Song,
	Guardate_come_bello_gustate_quante_soave.Song,
	Canto_dei_tre_giovani_nella_fornace_ii.Song,
	Quando_Israele_usci_dallegitto.Song,
	Santo_santo_santo_1988.Song,
	Andate_ed_annunziate_ai_miei_fratelli.Song,
	Cerano_due_angeli.Song,
	Benedite_il_signore.Song,
	Gia_viene_il_regno.Song,
	Preghiera_litanica_penitenziale.Song,
	Misericordia_dio_misericordia.Song,
	Jahve_tu_sei_il_mio_dio.Song,
	Omelia_pasquale_di_melitone_di_sardi.Song,
	A_te_signore_innalzo_la_mia_anima.Song,
	Fino_a_quando.Song,
	Preghiera_eucaristica_ii_i.Song,
	Al_risveglio_mi_saziero_del_tuo_volto_signor.Song,
	A_te_signore_con_la_mia_voce.Song,
	Inno_alla_carita.Song,
	Lo_spirito_del_signore_e_sopra_di_me.Song,
	Inno_delle_lodi_di_pentecoste.Song,
	Ecco_il_mio_servo_I_canto_del_servo_di_Jahve.Song,
	Preghiera_eucaristica_ii_ii.Song,
	Maria_piccola_maria.Song,
	Come_condannati_a_morte.Song,
	Benedizione_dellacqua_del_fonte_battesimale.Song,
	O_morte_dove_la_tua_vittoria.Song,
	Carmen_63.Song,
	Giunti_sui_fiumi_di_babilonia.Song,
	Il_signore_mi_ha_dato.Song,
	Gesu_percorreva_tutte_le_citta.Song,
	Gridate_con_gioia.Song,
	Cristo_gesu_e_il_signore.Song,
	Agnello_di_dio.Song,
	Evenu_shalom_alejem.Song,
	La_guerra_di_piero.Song,
	Tanto_pe_canta.Song,
	Cera_un_ragazzo.Song,
	La_canzone_del_sole.Song,
	Acqua_azzurra_acqua_chiara.Song,
	Il_ragazzo_della_via_gluck.Song,
	Don_raffae.Song,
	Bocca_di_rosa.Song,
	Il_pescatore.Song,
	Nothing_else_matters.Song,
	Me_so_mbriacato.Song,
	Bailando.Song,
	Il_gatto_e_la_volpe.Song,
	Azzurro.Song,

];