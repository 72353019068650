import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  title: "Debora.",
  url_video: "https://www.youtube.com/embed/xoFGC_eVSi4",
  barrè: "",
  color: "limegreen",
  semantic: "neocat",
  blocks: [
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "Quando Israele raduna l'assemblea,",
        },
        {
          notes: "",
          text: "quando il popolo si offre volontario:",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       Fa              Mi",
          text: "BENEDITE JAHVÈ, BENEDITE JAHVÈ,",
        },
        {
          notes: " Mi-       Fa                    Mi",
          text: "BENEDITE JAHVÈ, A JAHVÈ VOGLIO CANTAR.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "             Mi",
          text: "E INTONA IL CANTO.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "       Mi",
          text: "SORGI BARAC.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "Nei giorni di Samgar figlio di Anat,",
        },
        {
          notes: " Re7",
          text: "nei giorni di Jael,",
        },
        {
          notes: " Re7",
          text: "già non c'eran carovane,",
        },
        {
          notes: " Re7",
          text: "non si poteva andare per le strade,",
        },
        {
          notes: " Re7",
          text: "perché Israele si era cercato altri dèi",
        },
        {
          notes: " Re7",
          text: "e la guerra era alle porte.",
        },
        {
          notes: " Re7",
          text: "Non si trovava un salvatore in Israele,",
        },
        {
          notes: " Re7",
          text: "non si trovava una lancia tra diecimila.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       Fa              Mi",
          text: "BENEDITE JAHVÈ, BENEDITE JAHVÈ,",
        },
        {
          notes: " Mi-       Fa                    Mi",
          text: "BENEDITE JAHVÈ, A JAHVÈ VOGLIO CANTAR.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "             Mi",
          text: "E INTONA IL CANTO.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "       Mi",
          text: "SORGI BARAC.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "Quando uscivi da Seir, Jahvè mio Dio,",
        },
        {
          notes: " Re7",
          text: "quando avanzavi per i campi di Edom,",
        },
        {
          notes: " Re7",
          text: "tremò la terra, si commossero i cieli,",
        },
        {
          notes: " Re7",
          text: "le nubi in acqua si sciolsero,",
        },
        {
          notes: " Re7",
          text: "davanti a te i monti saltellarono,",
        },
        {
          notes: " Re7",
          text: "davanti a te, Dio d'Israele.",
        },
        {
          notes: " Re7",
          text: "Dall'alto dei cieli lottarono le stelle,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "DALL'ALTO DEI CIELI LOTTARONO LE STELLE.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "Dall'alto dei cieli lottaron contro Sisara,",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "DALL'ALTO DEI CIELI LOTTARON CONTRO SISARA.",
        },
      ],
    },
    {
      speaker: "C.",
      mode: "normal",
      lines: [
        {
          notes: "Re7",
          text: "E il torrente Chison,",
        },
        {
          notes: " Re7",
          text: "il sacro torrente li travolse.",
        },
      ],
    },
    {
      speaker: "A.",
      mode: "normal",
      lines: [
        {
          notes: " Mi-       Fa              Mi",
          text: "BENEDITE JAHVÈ, BENEDITE JAHVÈ,",
        },
        {
          notes: " Mi-       Fa                    Mi",
          text: "BENEDITE JAHVÈ, A JAHVÈ VOGLIO CANTAR.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "             Mi",
          text: "E INTONA IL CANTO.",
        },
        {
          notes: "   La-",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "    Fa",
          text: "RISVEGLIATI, DEBORA,",
        },
        {
          notes: "       Mi",
          text: "SORGI BARAC.",
        },
      ],
    },
  ],
  subtitle: "(Gdc. 5, 1 ss)",
};
