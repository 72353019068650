import { SongElement } from "../../SongElement";
export const Song: SongElement = {
  id: "Nothing_else_matters.",
  title: "Nothing else matters.",
  url_video: "https://www.youtube.com/embed/x7bIbVlIqEc",
  barrè: "",
  color: "none",
  semantic: "extra",
  blocks: [
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   Re      Do",
          text: "  So close no matter how far",
        },
        {
          notes: "Mi-                      Re             Do",
          text: "  couldn't be much more from the heart",
        },
        {
          notes: "Mi-                 Re       Do",
          text: "  forever trusting who we are",
        },
        {
          notes: "Sol     Si7           Mi-",
          text: "and nothing else matters.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-               Re            Do",
          text: "  Never opened myself this way",
        },
        {
          notes: "Mi-                         Re      Do",
          text: "  life is ours, we live it our way",
        },
        {
          notes: "Mi-                  Re              Do",
          text: "  all these words I don't just say",
        },
        {
          notes: "Sol     Si7           Mi-",
          text: "  and nothing else matters.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   Re          Do",
          text: "  Trust I seek and I find in you",
        },
        {
          notes: "Mi-                 Re            Do",
          text: "  every day for us something new",
        },
        {
          notes: "Mi-                Re             Do",
          text: "  open mind for a different view",
        },
        {
          notes: "Sol     Si7           Mi-      Do  La",
          text: "  and nothing else matters.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                        Do  La",
          text: "Never cared for what they do",
        },
        {
          notes: "Re                        Do  La",
          text: "never cared for what they know",
        },
        {
          notes: "Re     Mi-",
          text: "   but I know.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   Re      Do",
          text: "  So close no matter how far",
        },
        {
          notes: "Mi-                      Re             Do",
          text: "  couldn't be much more from the heart",
        },
        {
          notes: "Mi-                 Re       Do",
          text: "  forever trusting who we are",
        },
        {
          notes: "Sol     Si7           Mi-      Do  La",
          text: "and nothing else matters.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                        Do  La",
          text: "Never cared for what they do",
        },
        {
          notes: "Re                        Do  La",
          text: "Never cared for what they know",
        },
        {
          notes: "Re     Mi-",
          text: "   but I know.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-               Re            Do",
          text: "  Never opened myself this way",
        },
        {
          notes: "Mi-                         Re      Do",
          text: "  life is ours, we live it our way",
        },
        {
          notes: "Mi-                  Re              Do",
          text: "  all these words I don't just say",
        },
        {
          notes: "Sol     Si7           Mi-",
          text: "  and nothing else matters.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   Re          Do",
          text: "  Trust I seek and I find in you",
        },
        {
          notes: "Mi-                 Re            Do",
          text: "  every day for us something new",
        },
        {
          notes: "Mi-                Re             Do",
          text: "  open mind for a different view",
        },
        {
          notes: "Sol     Si7           Mi-     Do   La",
          text: "  and nothing else matterers.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Re                        Do  La",
          text: "Never cared for what they say",
        },
        {
          notes: "Re                        Do  La",
          text: "never cared for games they play",
        },
        {
          notes: "Re                        Do  La",
          text: "never cared for what they do",
        },
        {
          notes: "Re                        Do  La",
          text: "never cared for what they know",
        },
        {
          notes: "Re     Mi-",
          text: "   and I know.",
        },
      ],
    },
    {
      speaker: "",
      mode: "normal",
      lines: [
        {
          notes: "Mi-                   Re      Do",
          text: "  So close no matter how far",
        },
        {
          notes: "Mi-                      Re             Do",
          text: "  couldn't be much more from the heart",
        },
        {
          notes: "Mi-                 Re       Do",
          text: "  forever trusting who we are",
        },
        {
          notes: "Sol     Si7           Mi-",
          text: "and nothing else matters.",
        },
      ],
    },
  ],
  subtitle: "Metallica",
  sent_for_revision: false,
};
